import React, { Component } from 'react'
import { Grid, Box } from '@mui/material'
import { connect } from 'react-redux'
import { Paper } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import LinearProgress from '@mui/material/LinearProgress'
import { IconButton } from '@mui/material'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import Constants from '../../shared/constants'
import { Pickups } from '../index';
import { EnemyConstants } from '../index'
import Typography from '@mui/material/Typography'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ColorChangingButton from './ColorChangingButton.js'
import {bigScreenBorderProperties, colorPalette} from './uiStyles.js'
import ElementsData from "../../shared/elements";
import ColorTable from "../../shared/colorTable";
import {styled} from "@mui/material/styles";

function ItemRow(props) {
    let { item, totalWeight, rank, currentRank } = props

    let rankDict = {
        'starter': 1,
        'bronze': 2,
        'silver': 3,
        'gold': 4,
        'platinum': 5
    }

    let rowRankId = rankDict[rank]
    let currentRankId = rankDict[currentRank]

    let showData
    if (currentRankId >= rowRankId) { showData = true }

    let itemName
    let itemDropRate
    if (showData) {
        itemName = item.name
        itemDropRate = ((item.weight / totalWeight) * 100).toFixed(2) + '%'

    } else {
        itemName = '???'
        itemDropRate = '???'
    }

    return <Grid container xs={12}
        style={{ backgroundColor: item.backgroundColor, borderRadius: '5px', marginBottom: '1px' }}
    >
        <>
            <Grid xs={6}>
                <b> {itemName} </b>
            </Grid>
            <Grid xs={6}>
                <b> {itemDropRate}</b>
            </Grid>
        </>
    </Grid>
}

function findItemData(drop) {
    let item
    switch (drop.type) {
        case 'spell':
            item = Pickups.spells.find(spell => drop.id == spell.spellID)
            item.backgroundColor = ColorTable.rarityInfo[item.rarity]['rarityColor']
            break
        case 'mana':
            item = ElementsData.find(mana => drop.id == mana.id)
            item.backgroundColor = ColorTable.elementHex[item.refName]
            break
        case 'body':
            item = Pickups.bodyWear.find(item => drop.id == item.id)
            item.backgroundColor = ColorTable.rarityInfo[item.rarity]['rarityColor']
            break
        case 'top':
            item = Pickups.topWear.find(item => drop.id == item.id)
            item.backgroundColor = ColorTable.rarityInfo[item.rarity]['rarityColor']
            break
        case 'hand':
            item = Pickups.handWear.find(item => drop.id == item.id)
            item.backgroundColor = ColorTable.rarityInfo[item.rarity]['rarityColor']
            break
        default:
            break
    }
    item.weight = drop.weight
    return item
}

const StyledLinearProgress = styled(LinearProgress)({
    backgroundColor: '#362D44', // Color for the background
    '& .MuiLinearProgress-bar': {
        backgroundColor: '#0A83D4', // Color for the bar
    },
});

class MonsterJournal extends Component {
    constructor() {
        super()
        this.state = {
            dialog: false,
            showDrops: false,
            page: 0,
            enemyTable: [{
                id: 7,
                name: '',
                path: 'assets/gifs/woodland_idle.gif',
                type: 'air',
                weakness: 'fire',
                tip: 'Don\'t get too close to its multi attacks!'
            }],
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { accountKillStats } = this.props
        if (prevProps.accountKillStats != accountKillStats) {
            this.componentDidMount()
        }
    }


    componentDidMount() {
        let { accountKillStats } = this.props
        let enemyTable = [
            {
                id: 1,
                name: 'Slime',
                path: 'assets/gifs/slime_idle.gif',
                type: 'air',
                weakness: 'fire',
                tip: 'Kill it quickly with melee attacks to hoard a lot of mana!',
                size: 'small'
            },
            {
                id: 21,
                name: 'Flowie',
                path: 'assets/gifs/flower_creature_idle.gif',
                type: 'water',
                weakness: 'earth',
                tip: 'Take advantage that Flowie doesn\'t move and the slow attack speed to dodge the shots!',
                size: 'small',
            },
            {
                id: 17,
                name: 'Red Slime',
                path: 'assets/gifs/slime_red_idle.gif',
                type: 'air',
                weakness: 'fire',
                tip: 'Beware of his charge attack! Try using spells to take him down, and avoid him at level 1.',
                miniBoss: true,
                size: 'medium'
            },
            {
                id: 4,
                name: 'Giant Slime',
                path: 'assets/gifs/slime_boss_idle.gif',
                type: 'air',
                weakness: 'fire',
                tip: 'Take him down to get strong gear early in the game',
                miniBoss: true,
                size: 'large'
            },

            {
                id: 7,
                name: 'Woodland Critter',
                path: 'assets/gifs/woodland_idle.gif',
                type: 'air',
                weakness: 'fire',
                tip: 'Don\'t get too close to its multi attacks!',
                size: 'medium'
            },
            {
                id: 13,
                name: 'Woodland Beast',
                path: 'assets/gifs/woodland_beast_idle.gif',
                type: 'air',
                weakness: 'fire',
                tip: 'Dodge out of its deadly shots, or fight them back with fire for extra damage!',
                size: 'large'
            },
            {
                // NEEDS TYPE
                id: 6,
                name: 'Chimera',
                path: 'assets/gifs/leopard_idle.gif',
                type: 'earth',
                weakness: 'air',
                tip: 'Avoid getting clawed by slowing with ice spells or pushing with air missiles',
                size: 'medium'
            },
            {
                // NEEDS TYPE
                id: 12,
                name: 'King Chimera',
                path: 'assets/gifs/king_chimera_idle.gif',
                type: 'earth',
                weakness: 'air',
                tip: 'Avoid its deadly claw attack by using ranged attacks',
                size: 'large',
                miniBoss: true,
            },

            {
                id: 20,
                // name: 'Crab',
                name: 'Freddie',
                path: 'assets/gifs/crab_normal_idle.gif',
                type: 'water',
                weakness: 'earth',
                tip: 'Not a big threat by itself, but a group of crabs can pack a punch.',
                size: 'small'
            },
            {
                id: 31,
                // name: 'Massive Crab',
                name: 'Fred',
                path: 'assets/gifs/crab_superior_idle.gif',
                type: 'water',
                weakness: 'earth',
                tip: 'It\'s stun can be dangerous, make sure to take down smaller nearby crabs before attacking!',
                size: 'medium'
            },
            {
                id: 32,
                // name: 'King Crab',
                name: 'King Frederick',
                path: 'assets/gifs/crab_boss_idle.gif',
                type: 'water',
                weakness: 'earth',
                tip: 'Stunning you and clawing you down are his deadly combo, avoid getting near at all costs!',
                size: 'large',
                miniBoss: true
            },

            {
                id: 18,
                name: 'Starfish',
                path: 'assets/gifs/starfish_normal_idle.gif',
                type: 'water',
                weakness: 'earth',
                tip: '',
                size: 'small'
            },

            {
                // NEEDS TYPE
                id: 9,
                name: 'Cannon',
                path: 'assets/gifs/cannon_idle.gif',
                type: 'fire',
                weakness: 'water',
                tip: 'Dodge out of the way of its slow attack',
                size: 'small'
            },
            {
                id: 5,
                name: 'Charlie',
                path: 'assets/gifs/evil_creature_idle.gif',
                type: 'dark',
                weakness: 'light',
                tip: 'Using a healing spell on it will deal heavy damage!',
                size: 'medium'
            },
            {
                id: 16,
                name: 'Mini Charlie',
                path: 'assets/gifs/mini_charlie_idle.gif',
                type: 'dark',
                weakness: 'light',
                tip: 'Use a sword to quickly swipe down many of them at once',
                size: 'small'
            },
            {
                id: 14,
                name: 'Big Charlie',
                path: 'assets/gifs/big_charlie_idle.gif',
                type: 'dark',
                weakness: 'light',
                tip: 'Make sure not to get stuck in the water, or you\'ll soon take a beating from him',
                size: 'large',
                miniBoss: true
            },
            {
                id: 45,
                name: 'Spooky Charlie',
                path: 'assets/gifs/jackocharlie_idle.gif',
                type: 'dark',
                weakness: 'light',
                tip: 'Avoid his stunning spells at all costs!',
                size: 'large',
                miniBoss: true
            },
            {
                id: 2,
                name: 'Dark Wizard',
                path: 'assets/gifs/dark_wizard_idle.gif',
                type: 'dark',
                weakness: 'light',
                tip: 'If you are stuck in a gravity ball, dash to get out!',
                size: 'small'
            },
            {
                id: 15,
                name: 'Green Dragon Whelp',
                path: 'assets/gifs/dragon_whelp_idle.gif',
                type: 'fire',
                weakness: 'water',
                tip: 'Fire at a distance with a water spell to break through its fire attack!',
                size: 'medium'
            },
            {
                id: 8,
                name: 'Dragon',
                path: 'assets/gifs/dragon_idle.gif',
                type: 'fire',
                weakness: 'water',
                tip: 'Fall back if you\'re surrounded by too many dragons, or you might lose all your hp in seconds',
                size: 'medium',
            },




            {
                id: 25,
                name: 'Hellhound Pack Leader',
                path: 'assets/gifs/hellhound_boss_idle.gif',
                type: 'fire',
                weakness: 'water',
                tip: 'Watch out for its deadly charge!',
                size: 'large',
                miniBoss: true
            },
            {
                id: 28,
                name: 'Giant Lava Slime',
                path: 'assets/gifs/slime_lava_boss_idle.gif',
                type: 'fire',
                weakness: 'water',
                tip: 'Bring water spells to take down its very high health pool',
                size: 'large',
                miniBoss: true
            },
            {
                id: 30,
                name: 'Mega Cannon',
                path: 'assets/gifs/boss_cannon_idle.gif',
                type: 'fire',
                weakness: 'water',
                tip: 'The cannon boss has a crazy firerate of deadly missiles, make sure to dodge or fight back with water spells!',
                size: 'large',
                miniBoss: true
            },
            {
                id: 24,
                name: 'Head Dark Wizard',
                path: 'assets/gifs/dark_wizard_boss_idle.gif',
                type: 'dark',
                weakness: 'light',
                tip: 'Take down the smaller wizards first in order to have less to dodge!',
                size: 'large',
                miniBoss: true
            },



            {
                id: 35,
                name: 'Seahorse',
                path: 'assets/gifs/sea_horse_normal_idle.gif',
                type: 'water',
                weakness: 'earth',
                tip: 'His shots are easy to avoid, but make sure to dodge them or you\'ll be hit by several bullets.',
                size: 'medium'
            },
            {
                id: 36,
                name: 'Superior Seahorse',
                path: 'assets/gifs/sea_horse_superior_idle.gif',
                type: 'water',
                weakness: 'earth',
                tip: 'It\'s an easy fight until you take one hit, after that, you\'re slowed and vulnerable to his burst of shots!',
                size: 'medium'
            },
            {
                id: 33,
                name: 'Piranha',
                path: 'assets/gifs/piranha_idle.gif',
                type: 'water',
                weakness: 'earth',
                tip: 'Find a swimming aura or raise your speed levels, if you can\'t outrun them you\'ll be in trouble.',
                size: 'medium'
            },
            {
                id: 34,
                name: 'King Piranha',
                path: 'assets/gifs/piranha_boss_idle.gif',
                type: 'water',
                weakness: 'earth',
                tip: 'Keep your distance with a swimming aura or high speed stats.',
                size: 'large',
                miniBoss: true
            },
            {
                id: 43,
                name: 'Ice Slime',
                path: 'assets/gifs/ice_slime_idle.gif',
                type: 'water',
                weakness: 'earth',
                tip: 'Take them down first in fights to focus on the bigger enemies!',
                size: 'small'
            },
            {
                id: 44,
                name: 'Giant Ice Slime',
                path: 'assets/gifs/ice_slime_boss_idle.gif',
                type: 'water',
                weakness: 'earth',
                tip: 'Take them down first in fights to focus on the bigger enemies!',
                size: 'large',
                miniBoss: true
            },
            {
                id: 38,
                name: 'Mammoth',
                path: 'assets/gifs/mammoth_normal_idle.gif',
                type: 'earth',
                weakness: 'air',
                tip: 'Keep him slowed or knocked back to counter his charge attacks!',
                size: 'larte'
            },
            {
                id: 39,
                name: 'Superior Mammoth',
                path: 'assets/gifs/mammoth_superior_idle.gif',
                type: 'earth',
                weakness: 'air',
                tip: 'Beware of his stuns and try to fight him in an open area.',
                size: 'large'
            },
            {
                id: 40,
                name: 'King Mammoth',
                path: 'assets/gifs/mammoth_boss_idle.gif',
                type: 'earth',
                weakness: 'air',
                tip: 'Be careful of his deadly stun - use earth stunning spells to combine movement effects with additional damage.',
                size: 'large',
                miniBoss: true
            },
            {
                id: 41,
                name: 'Ice Golem',
                path: 'assets/gifs/ice_golem_normal_idle.gif',
                type: 'water',
                weakness: 'earth',
                tip: 'Beware its slowing spells, especially with mammoths nearby!',
                size: 'medium'
            },
            {
                id: 42,
                name: 'Ice Golem Boss',
                path: 'assets/gifs/ice_golem_boss_idle.gif',
                type: 'water',
                weakness: 'earth',
                tip: 'The leader of ice golems, can you survive his wrath?',
                size: 'medium',
                boss: true
            },
            {
                id: 48,
                name: 'Mummy',
                path: 'assets/gifs/mummy_idle.gif',
                type: 'water',
                weakness: 'earth',
                tip: 'If you let it reach you, you might be in trouble!',
                size: 'medium'
            },

            {
                id: 10,
                name: 'Dragon Lord',
                path: 'assets/gifs/dragon_boss_idle.gif',
                type: 'fire',
                weakness: 'water',
                // tip: "Prepare all the mana you can before the fight, it will be a long one!",
                tip: 'Keep an eye on the floor as well as the dragon, if you get stuck in lava you\'ll soon die from his meteors',
                size: 'large',
                boss: true
            },
        ]
        for (let i = 0; i < enemyTable.length; i++) {
            if (enemyTable[i].id in accountKillStats) {
                let kills = accountKillStats[enemyTable[i].id]

                // Test Env
                // if (enemyTable[i].id == 1) { kills = 260 }
                // if (enemyTable[i].id == 4) { kills = 400 }
                // if (enemyTable[i].id == 7) { kills = 260 }
                // if (enemyTable[i].id == 13) { kills = 900 }
                // if (enemyTable[i].id == 5) { kills = 120 }
                // if (enemyTable[i].id == 9) { kills = 1200 }
                // if (enemyTable[i].id == 1) { kills = 200 }

                let currentRank = 'starter'

                if (!enemyTable[i].boss && !enemyTable[i].miniBoss) {
                    if (kills == 0) {
                        enemyTable[i].nextAchievement = 1
                    }
                    if (kills >= 1) {
                        enemyTable[i].nameUnlocked = true
                        enemyTable[i].nextAchievement = 10
                    }
                    if (kills >= 10) {
                        enemyTable[i].typeUnlocked = true
                        enemyTable[i].nextAchievement = 50
                    }
                    if (kills >= 50) {
                        enemyTable[i].tipUnlocked = true
                        enemyTable[i].nextAchievement = Constants.MONSTER_JOURNAL_THRESHOLDS.normal.bronze
                    }
                    if (kills >= Constants.MONSTER_JOURNAL_THRESHOLDS.normal.bronze) {
                        enemyTable[i].borderType = 'bronze'
                        currentRank = 'bronze'
                        enemyTable[i].nextAchievement = Constants.MONSTER_JOURNAL_THRESHOLDS.normal.silver
                    }
                    if (kills >= Constants.MONSTER_JOURNAL_THRESHOLDS.normal.silver) {
                        enemyTable[i].borderType = 'silver'
                        currentRank = 'silver'
                        enemyTable[i].nextAchievement = Constants.MONSTER_JOURNAL_THRESHOLDS.normal.gold
                    }
                    if (kills >= Constants.MONSTER_JOURNAL_THRESHOLDS.normal.gold) {
                        enemyTable[i].borderType = 'gold'
                        currentRank = 'gold'
                        enemyTable[i].nextAchievement = Constants.MONSTER_JOURNAL_THRESHOLDS.normal.platinum
                    }
                    if (kills >= Constants.MONSTER_JOURNAL_THRESHOLDS.normal.platinum) {
                        enemyTable[i].borderType = 'platinum'
                        currentRank = 'platinum'
                    }
                }
                else {
                    if (kills >= 1) {
                        enemyTable[i].nameUnlocked = true
                        enemyTable[i].nextAchievement = 5
                    }
                    if (kills >= 5) {
                        enemyTable[i].typeUnlocked = true
                        enemyTable[i].nextAchievement = 10
                    }
                    if (kills >= 10) {
                        enemyTable[i].tipUnlocked = true
                        enemyTable[i].nextAchievement = 20
                    }
                    if (kills >= Constants.MONSTER_JOURNAL_THRESHOLDS.boss.bronze) {
                        enemyTable[i].borderType = 'bronze'
                        currentRank = 'bronze'
                        enemyTable[i].nextAchievement = Constants.MONSTER_JOURNAL_THRESHOLDS.boss.silver
                    }
                    if (kills >= Constants.MONSTER_JOURNAL_THRESHOLDS.boss.silver) {
                        enemyTable[i].borderType = 'silver'
                        currentRank = 'silver'
                        enemyTable[i].nextAchievement = Constants.MONSTER_JOURNAL_THRESHOLDS.boss.gold
                    }
                    if (kills >= Constants.MONSTER_JOURNAL_THRESHOLDS.boss.gold) {
                        enemyTable[i].borderType = 'gold'
                        currentRank = 'gold'
                        enemyTable[i].nextAchievement = Constants.MONSTER_JOURNAL_THRESHOLDS.boss.platinum
                    }
                    if (kills >= Constants.MONSTER_JOURNAL_THRESHOLDS.boss.platinum) {
                        enemyTable[i].borderType = 'platinum'
                        currentRank = 'platinum'
                    }
                }
                enemyTable[i].kills = kills
                enemyTable[i].progressRatio = Math.floor((enemyTable[i].kills / enemyTable[i].nextAchievement) * 100)

                let borderColor
                let fillingColor
                switch (enemyTable[i].borderType) {
                    case 'bronze':
                        borderColor = '#362D44'
                        fillingColor = '#D68A56'
                        break
                    case 'silver':
                        borderColor = '#362D44'
                        fillingColor = '#E3F5FF'
                        break
                    case 'gold':
                        borderColor = '#362D44'
                        fillingColor = '#FFD522'
                        break
                    case 'platinum':
                        borderColor = '#FFCB27'
                        fillingColor = 'linear-gradient(to bottom, #E5E4E2 0%, #CBCFD6 100%)'
                        break
                    default:
                        borderColor = 'black'
                        fillingColor = 'grey'
                        break
                }
                enemyTable[i].borderColor = borderColor
                enemyTable[i].fillingColor = fillingColor

                let size
                switch (enemyTable[i].size) {
                    case 'small':
                        size = 'scale(0.5)'
                        break
                    case 'medium':
                        size = 'scale(0.75)'
                        break
                    case 'large':
                        size = 'scale(1)'
                        break
                }
                enemyTable[i].scale = size

                // console.log("READING ENEMY CONSTANTS", EnemyConstants)



                let enemyData = EnemyConstants.find(enemy => enemyTable[i].id == enemy.enemyID)
                // console.log('mj enemy data', {
                //     id: enemyTable[i].id,
                //     data: enemyData.dropTable
                // })

                // enemyTable[i].dropData = enemyData.dropTable


                // let enemyDrops = enemyData.dropTable.map((data) => {
                //     return <Grid xs={12}>
                // <Grid xs={12}>
                //     <b> Drops </b>
                // </Grid>
                //         <Grid xs={12}>
                //             <b> Default </b>
                //         </Grid>
                //         <Grid xs={12}>
                //             {data. }
                //         </Grid>

                //     </Grid>

                // })
                // let weightDict = {
                //     starter: enemyData.dropTable.starter.reduce((n, { weight }) => n + weight, 0),
                //     bronze: enemyData.dropTable.bronze.reduce((n, { weight }) => n + weight, 0),
                //     silver: enemyData.dropTable.silver.reduce((n, { weight }) => n + weight, 0),
                //     gold: enemyData.dropTable.gold.reduce((n, { weight }) => n + weight, 0),
                //     platinum: enemyData.dropTable.platinum.reduce((n, { weight }) => n + weight, 0),
                // }
                //
                // let totalWeight
                // switch (currentRank) {
                //     case 'starter':
                //         totalWeight = weightDict.starter
                //         break
                //     case 'bronze':
                //         totalWeight = weightDict.starter + weightDict.bronze
                //         break
                //     case 'silver':
                //         totalWeight = weightDict.starter + weightDict.bronze + weightDict.silver
                //         break
                //     case 'gold':
                //         totalWeight = weightDict.starter + weightDict.bronze + weightDict.silver + weightDict.gold
                //         break
                //     case 'platinum':
                //         totalWeight = weightDict.starter + weightDict.bronze + weightDict.silver + weightDict.gold + weightDict.platinum
                //         break
                // }
                // // console.log('TOTAL WEIGHT', totalWeight)
                // // console.log("WEIGHT DICT", weightDict)
                // let enemyDrops = {
                //     starter: enemyData.dropTable.starter.map((drop) => {
                //         let item = findItemData(drop)
                //         return <ItemRow item={item} totalWeight={totalWeight} rank='starter' currentRank={currentRank} />
                //     }),
                //     bronze: enemyData.dropTable.bronze.map((drop) => {
                //         let item = findItemData(drop)
                //         return <ItemRow item={item} totalWeight={totalWeight} rank='bronze' currentRank={currentRank} />
                //     }),
                //     silver: enemyData.dropTable.silver.map((drop) => {
                //         let item = findItemData(drop)
                //         return <ItemRow item={item} totalWeight={totalWeight} rank='silver' currentRank={currentRank} />
                //     }),
                //     gold: enemyData.dropTable.gold.map((drop) => {
                //         let item = findItemData(drop)
                //         return <ItemRow item={item} totalWeight={totalWeight} rank='gold' currentRank={currentRank} />
                //     }),
                //     platinum: enemyData.dropTable.platinum.map((drop) => {
                //         let item = findItemData(drop)
                //         return <ItemRow item={item} totalWeight={totalWeight} rank='platinum' currentRank={currentRank} />
                //     }),
                // }
                //
                // enemyTable[i].enemyDrops = enemyDrops
            }
            else {
                enemyTable[i].kills = 0
                enemyTable[i].progressRatio = 0
                enemyTable[i].nextAchievement = 1
                enemyTable[i].borderColor = 'black'
                enemyTable[i].fillingColor = 'grey'
                enemyTable[i].scale = 'scale(1)'
                enemyTable[i].path = 'assets/notFound3.png'
            }
        }
        this.setState({ enemyTable: enemyTable })
    }

    increasePage() {
        let { page, enemyTable } = this.state
        if (page + 1 < enemyTable.length) {
            this.setState({
                page: page + 1
            })
        } else {
            this.setState({
                page: 0
            })
        }
    }
    decreasePage() {
        let { page, enemyTable } = this.state
        if (page - 1 >= 0) {
            this.setState({
                page: page - 1
            })
        } else {
            this.setState({
                page: enemyTable.length - 1
            })
        }
    }

    render() {
        const handleClickOpen = () => { this.setState({ 'dialog': true }) }
        const handleClose = () => { this.setState({ 'dialog': false }) }




        let manaColorTable = ColorTable.elementHex
        let gridStyle = {
            marginBottom: '5px',
            // whiteSpace: 'pre-wrap' 
        }
        let { enemyTable, page, dialog, showDrops } = this.state
        let { accountKillStats, height } = this.props

        let titlePadding
        let fontSize
        let squareSize
        let namePadding

        if (height < 560) {
            fontSize = '12px'
            titlePadding = '5px'
            namePadding = '5px'
            squareSize = '90px'

        } else if (height < 630) {
            fontSize = '14px'
            titlePadding = '5px'
            namePadding = '8px'
            squareSize = '110px'
        } else {
            fontSize = '16px'
            titlePadding = '16px'
            namePadding = '10px'
            squareSize = '130px'
        }

        let enemy = enemyTable[page]
        let typeColor = manaColorTable[enemy.type]
        let weaknessColor = manaColorTable[enemy.weakness]

        let name
        if (enemy.nameUnlocked) {
            name = enemy.name
        } else {
            name = '???'
        }
        let tip
        if (enemy.tipUnlocked) {
            tip = enemy.tip
        } else { tip = '???' }


        return <>
            <ColorChangingButton buttonFunction={handleClickOpen}
                config={
                    {
                        idle: {
                            fillColor: '#7C4E4D',
                            outerBorderColor: '#362D44',
                            innerBorderColor: '#543131',
                        },
                        hover: {
                            fillColor: '#CC7A82',
                            outerBorderColor: '#362D44',
                            innerBorderColor: '#AA5561',
                        },
                        down: {
                            fillColor: '#CC7A82',
                            outerBorderColor: '#362D44',
                            innerBorderColor: '#AA5561',
                        },
                    }

                }
                title='Monster Journal'
                buttonStyling={{
                    marginTop: '10px',
                    // marginRight: '5px',
                    color: 'white',
                    // width: '70%',
                    width: '100%',
                    padding: '1%'
                }}
            />

            <Dialog
                style={{
                    padding: '10px',
                    // maxHeight: '550px'
                }}
                onClose={handleClose} aria-labelledby="simple-dialog-title" open={dialog}>
                <IconButton
                    style={{
                        alignSelf: 'flex-end',
                        marginTop: -5,
                        position: 'absolute'
                    }}
                    onClick={handleClose}
                    size="large">
                    <CancelPresentationIcon style={{ fontSize: '3rem' }} />
                </IconButton>

                <Paper align='center' justify="center" alignItems="center" direction="row"
                    style={Object.assign({
                        background: colorPalette.primary,
                        borderColor: colorPalette.primaryBorder,
                    }, bigScreenBorderProperties)}>
                    <Grid container
                        align='center' justifyContent="center" alignItems="center" direction="row"
                        style={{
                            marginBottom: '2.5%'
                        }}>

                        {/* <Grid
                            item xs={12} style={{
                                paddingBottom: '2px'
                            }}>
                            <h1
                            // style={{
                            //     padding: titlePadding
                            // }}
                            ><b>Monster Journal </b></h1>
                        </Grid> */}

                        <Grid
                            item xs={12} style={{
                                paddingBottom: '2px'
                            }}>
                            <DialogTitle
                                style=
                                {Object.assign({
                                    textAlign: 'center',
                                    padding: titlePadding
                                },
                                )}
                                id="simple-dialog-title"> <b> Monster Journal</b></DialogTitle>
                        </Grid>
                        <Grid xs={12}>
                            {/* <ToggleButtonGroup>
                                <ToggleButton value='Main' />
                                <ToggleButton value='Drops' />
                            </ToggleButtonGroup> */}
                        </Grid>
                        <Grid xs={2} container
                            //  align='center' justify="center" 
                            justifyContent='flex-start'
                            alignItems="flex-start"
                            //  direction='column'
                            direction="row"
                        >
                            <IconButton
                                style={{
                                }}
                                onClick={this.decreasePage.bind(this)}
                                size="large">
                                <KeyboardArrowLeftIcon
                                    style={{
                                        fontSize: '3rem',
                                        // position: 'absoulute', top: '-300' 
                                    }}
                                />
                            </IconButton>
                        </Grid>
                        <Grid
                            // container align='center' justify="center" alignItems="center" direction="row"
                            xs={8}>
                            <Grid container xs={12} style={{
                                background: enemy.fillingColor, borderRadius: '8px', marginBottom: '2.5%',
                                borderWidth: '3px', borderStyle: 'solid', borderColor: enemy.borderColor,
                                boxShadow: '0 0 0 2px #FFFFFF',
                            }}>
                                <Grid xs={2} style={{
                                    background: 'rgb(255,255,255,.3)',
                                    padding: namePadding,
                                    borderBottomLeftRadius: '6px',
                                    borderTopLeftRadius: '6px'
                                }}>
                                    <b
                                        style={{ color: 'black' }}
                                    > {page + 1}/{enemyTable.length}</b>
                                </Grid>
                                <Grid xs={8} style={{ padding: namePadding }}>
                                    <b
                                    // style={{fontSize: fontSize }}
                                    >{name}</b>
                                </Grid>
                                <Grid xs={2}></Grid>
                            </Grid>

                            {(enemy.nextAchievement - enemy.kills) > 0 &&
                                <>
                                    <Grid xs={12} >
                                        <b style={{ fontSize: fontSize }}>Next unlock in {enemy.nextAchievement - enemy.kills} kills</b>
                                    </Grid>
                                    <Grid xs={12} style={{ 'textAlign': 'center', height: '100%', width: '100%' }}>
                                        <Box display="flex" alignItems="center">
                                            <Box width="100%" mr={1}>
                                                <StyledLinearProgress variant="determinate" value={enemy.progressRatio} style={{
                                                    minHeight: '20px', borderRadius: '3px',
                                                    borderWidth: '2px', borderStyle: 'solid', borderColor: 'black',
                                                }} />
                                                <Typography variant="body2" color="textSecondary" style={{
                                                    position: 'absolute',
                                                    color: 'white',
                                                    marginTop: '-21.5px',
                                                    left: '50%',
                                                    transform: 'translateX(-50%)',
                                                }}>{enemy.kills}/{enemy.nextAchievement}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </>
                            }
                            {(enemy.nextAchievement - enemy.kills) <= 0 &&
                                <>
                                    <Grid xs={12} >
                                        <b>Fully Unlocked</b>
                                    </Grid>
                                    <Grid xs={12} style={{ 'textAlign': 'center', height: '100%', width: '100%' }}>
                                        <Box display="flex" alignItems="center">
                                            <Box width="100%" mr={1}>
                                                <StyledLinearProgress variant="determinate" value={100} style={{
                                                    minHeight: '20px', borderRadius: '3px',
                                                    borderWidth: '2px', borderStyle: 'solid', borderColor: 'black',
                                                }} />
                                            </Box>
                                        </Box>
                                        <Box minWidth={35}>
                                            {/* <Typography variant="body2" color="textSecondary">{enemy.kills}/{enemy.nextAchievement}</Typography> */}
                                        </Box>
                                    </Grid>
                                </>
                            }
                            <Grid container align='center' justifyContent="center" alignItems="center" xs={12} style={{ padding: '1%', marginTop: '1%' }}>
                                <Paper style={{
                                    borderWidth: '3px', borderStyle: 'solid', borderColor: 'black',
                                    boxShadow: '0 0 0 2px #FFFFFF', padding: '1%'
                                }}>
                                    <Grid item xs={12} style={{ paddingBottom: '2.5%' }}>


                                        <Box style={{
                                            // width: '132px',
                                            // height: '128px',
                                            // width: '130px',
                                            // height: '130px',
                                            width: squareSize,
                                            height: squareSize,
                                            margin: '5px',
                                        }}>
                                            <Box style={{
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                                minWidth: '100%',
                                                minHeight: '100%',
                                                borderWidth: '3px',
                                                borderRadius: '2px',
                                                boxShadow: `0 0 0 2px ${enemy.borderColor}`,

                                                height: '100%',
                                                background: enemy.fillingColor,
                                            }}>
                                                <Grid container alignItems="center" direction="row" xs={12}
                                                    style={{ height: '100%' }}
                                                >
                                                    <img
                                                        style={{
                                                            pointerEvents: 'none',
                                                            maxWidth: '100%',
                                                            maxHeight: '100%',
                                                            minWidth: '100%',
                                                            minHeight: '100%',
                                                            transform: enemy.scale,
                                                            objectFit: 'scale-down',
                                                        }}
                                                        src={enemy.path}></img>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid container xs={12} style={{ paddingLeft: '4%' }}>
                                        <Grid container style={gridStyle} justifyContent="flex-start" xs={6}>
                                            <b style={{ fontSize: fontSize }}> Kills:</b>
                                        </Grid>
                                        <Grid container style={gridStyle} justifyContent="center" xs={6}>
                                            <b style={{ color: 'black', fontSize: fontSize }}>   {enemy.kills}</b>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} style={{ paddingLeft: '4%' }}>
                                        <Grid container style={gridStyle} justifyContent="flex-start" xs={6}>
                                            <b style={{ fontSize: fontSize, fontSize: fontSize }}> Type:</b>
                                        </Grid>
                                        {enemy.typeUnlocked &&
                                            <Grid container style={gridStyle} justifyContent="center" xs={6}>
                                                <b style={{ color: typeColor, fontSize: fontSize }}>   {enemy.type}</b>
                                            </Grid>}
                                        {!enemy.typeUnlocked &&
                                            <Grid container style={gridStyle} justifyContent="center" xs={6}>
                                                <b style={{ fontSize: fontSize }}>   ???</b>
                                            </Grid>}
                                    </Grid>
                                    <Grid container xs={12} style={{ paddingLeft: '4%' }}>
                                        <Grid container style={gridStyle} justifyContent="flex-start" xs={6}>
                                            <b style={{ fontSize: fontSize }}> Weak:</b>
                                        </Grid>
                                        {enemy.typeUnlocked &&
                                            <Grid container style={gridStyle} justifyContent="center" xs={6}>
                                                <b style={{ color: weaknessColor, fontSize: fontSize }}>   {enemy.weakness}</b>
                                            </Grid>}
                                        {!enemy.typeUnlocked &&
                                            <Grid container style={gridStyle} justifyContent="center" xs={6}>
                                                <b style={{ fontSize: fontSize }}>   ???</b>
                                            </Grid>}
                                    </Grid>
                                    {/* </Grid> */}
                                </Paper>
                            </Grid>
                            <Grid xs={12} style={{ marginTop: '5px' }}>
                                {enemy.tipUnlocked &&
                                    <Grid item xs={12} style={{ marginBottom: '3px', whiteSpace: 'pre-wrap', minHeight: '50px' }}>
                                        <b style={{ fontSize: fontSize }}> Tip: </b> <span style={{ fontWeight: 'normal', fontSize: fontSize }}> {tip} </span>
                                    </Grid>}
                                {!enemy.tipUnlocked &&

                                    <Grid container xs={12}>
                                        <Grid container style={{ marginBottom: '5px', whiteSpace: 'pre-wrap', minHeight: '50px' }} justifyContent="flex-end" xs={6}>
                                            < b style={{ fontSize: fontSize }}>Tip:</b>
                                        </Grid>

                                        <Grid container style={{ marginBottom: '5px', whiteSpace: 'pre-wrap', minHeight: '50px' }} justifyContent="flex-start" xs={6}>
                                            <b style={{ fontSize: fontSize }}>   ???</b>
                                        </Grid>
                                    </Grid>

                                }
                            </Grid>


                        </Grid>
                        <Grid
                            xs={2}>
                            <IconButton
                                style={{
                                }}
                                onClick={this.increasePage.bind(this)}
                                size="large">
                                <KeyboardArrowRightIcon style={{ fontSize: '3rem' }} />
                            </IconButton>
                        </Grid>
                    </Grid>

                    {/* <Grid container 
                        align='center' justify="center" alignItems="center" direction="row"
                    xs={12}>
                        
                            <b>Show Drops</b> 
                         </Grid> */}
                    <Grid container
                        align='center' justifyContent="center" alignItems="center" direction="row"
                        xs={12}>
                        
                        <Grid
                         container 
                           justifyContent="flex-end"  direction="row"
                        xs={6}>
                    {/*<Switch*/}
                    {/*        size='medium'*/}
                    {/*        name='Show Drops'*/}
                    {/*        label='Show Drops'*/}
                    {/*        onClick={() => {*/}
                    {/*            this.setState({*/}
                    {/*                showDrops: !this.state.showDrops*/}
                    {/*            })*/}
                    {/*        }} />*/}
                        </Grid>
                    <Grid 
                            container
                            justifyContent="flex-start" direction="row"
                    xs={6}>
                        {/*<b>Show Drops</b>*/}
                        </Grid>
                  
                    </Grid>
                    {enemy.enemyDrops && showDrops &&

                        <Grid container xs={12} style={{ maxWidth: '300px' }}>
                            {/* <Grid xs={12}>
                                <b> Drops </b>
                            </Grid> */}
                            <Grid xs={12}>
                                <b> Default </b>
                            </Grid>
                            <Grid xs={12}>
                                {enemy.enemyDrops.starter}
                            </Grid>

                            <Grid xs={12}>
                                <b> Bronze </b>
                            </Grid>
                            <Grid xs={12}>
                                {enemy.enemyDrops.bronze}
                            </Grid>

                            <Grid xs={12}>
                                <b> Silver </b>
                            </Grid>
                            <Grid xs={12}>
                                {enemy.enemyDrops.silver}
                            </Grid>

                            <Grid xs={12}>
                                <b> Gold </b>
                            </Grid>
                            <Grid xs={12}>
                                {enemy.enemyDrops.gold}
                            </Grid>

                            <Grid xs={12}>
                                <b> Platinum </b>
                            </Grid>
                            <Grid xs={12}>
                                {enemy.enemyDrops.platinum}
                            </Grid>
                        </Grid>
                    }
                </Paper>
            </Dialog>
        </>;
    }
}


function mapStateToProps(state) {
    return {
        accountKillStats: state.stuff.accountKillStats,
        height: state.stuff.height,
    }
}

export default connect(
    mapStateToProps,
)(MonsterJournal)