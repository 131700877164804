import React, { Component } from 'react'
import UI from './mainUIScreens/UI.js'
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
import './fonts/index.css'

const myTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          cursor: 'url(assets/ui/cursor_hover.png), text !important'
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          cursor: 'url(assets/ui/cursor_hover.png), text !important'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          cursor: 'url(assets/ui/cursor_hover.png), text !important'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          cursor: 'url(assets/ui/cursor_text.png), text !important'
        }
      }
    },
  }
});

class App extends Component {
  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={myTheme}>
            <div style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              cursor: 'url(assets/ui/cursor.png),pointer'
            }}>
              <UI />
            </div>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default App