"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomTypes = void 0;
const classGivers = [
    {
        id: 'priest',
        textToDisplay: 'Become a priest!'
    },
    {
        id: 'mage',
        textToDisplay: 'Become a mage!'
    },
    {
        id: 'warrior',
        textToDisplay: 'Become a warrior!'
    },
    {
        id: 'rogue',
        textToDisplay: 'Become a rogue!'
    },
    {
        id: 'ninja',
        textToDisplay: 'Become a ninja!'
    },
    {
        id: 'paladin',
        textToDisplay: 'Become a paladin!'
    },
    {
        id: 'warlock',
        textToDisplay: 'Become a warlock!'
    },
    {
        id: 'Merchant',
        textToDisplay: 'Press G to buy Gear and Slots!'
    },
    {
        id: 'Banker',
        textToDisplay: 'Press G to access your Bank.\nItems here are safe from death!'
    }
];
const portals = [
    {
        id: 'worldSpawn',
        textToDisplay: 'To adventure!'
    },
];
const npcText = [
    {
        id: 'Merchant',
        textToDisplay: 'Buy Gear!'
    },
    {
        id: 'Banker',
        textToDisplay: 'Access your Bank.\nItems here are safe from death!'
    },
    {
        id: 'Slot Seller',
        textToDisplay: 'Buy Character Slots!'
    },
    {
        id: 'Tome Master',
        textToDisplay: 'Store Tomes!'
    },
];
const deafNpcText = [
    {
        id: 'Wise Woman',
        textToDisplay: 'Press 2 to return to safety!'
    }
];
exports.CustomTypes = {
    classGiver: classGivers,
    portal: portals,
    npcText: npcText,
    deafNpcText: deafNpcText
};
