import React, { Component } from 'react'
import { connect } from 'react-redux'
import RespawnMenu from '../RespawnMenu.js'
import '../ui.css'
import { calculateScaleFactor } from '../../game'
import IconMute from '../icons/IconMute.js'
import IconFullScreen from '../icons/IconFullScreen'
import IconDiscord from '../icons/IconDiscord'

class PlayerDied extends Component {
    constructor() {
        super()
        this.state = {
        }
    }
    render() {
        let {  mute,fullscreen,  width, height, referrer, renderBottomAdBanner } = this.props
        let scaleFactor = 1
        let mainMenuScaleFactor = 1
        scaleFactor = calculateScaleFactor(window.innerWidth, window.innerHeight)
        scaleFactor += 0.13
        mainMenuScaleFactor = scaleFactor
        if (width < 600) {
            mainMenuScaleFactor += 0.2
        }
        mainMenuScaleFactor = Math.min(1, mainMenuScaleFactor)

        return (
                    <>
                        <div style={{
                            position: 'absolute',
                            left: 0,
                            opacity: 0.9,
                            top: '0%',
                        }}>

                            <IconDiscord />
                            { referrer !== 'crazygames' && <IconFullScreen fullScreen={fullscreen}/> }
                            <IconMute mute={mute} />
                        </div>

                        <div style={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                            opacity: 1,
                            width: '100%',
                        }}>
                            <div style={{
                                transform: `scale(${mainMenuScaleFactor})`,
                            }}>
                                <RespawnMenu renderBottomAdBanner={renderBottomAdBanner}></RespawnMenu>
                            </div>
                        </div>
                    </>
        )
    }
}

function mapStateToProps(state) {
    return {
        mute: state.stuff.mute,
        fullscreen: state.stuff.fullscreen,
        adblock: state.stuff.adblock,
        width: state.stuff.width,
        height: state.stuff.height,
        referrer: state.stuff.referrer,
    }
}

export default connect(
    mapStateToProps,
)(PlayerDied)