"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handWear = void 0;
exports.handWear = [{
        id: 1,
        name: 'Adventurer Gauntlets',
        refName: 'hands_starter',
        rarity: 'common',
        stats: {},
        imgPath: 'hand.png',
    },
    {
        id: 2,
        name: 'Wizard Gloves',
        refName: 'hands_wizard_uncommon',
        rarity: 'uncommon',
        stats: {
            spellDamage: 1,
            spellSpeed: 1,
            spellFirerate: 1,
        },
        imgPath: 'winter-gloves.png',
    },
    {
        id: 3,
        name: 'Rogue Gauntlets',
        refName: 'hands_rogue_uncommon',
        rarity: 'uncommon',
        stats: {
            meleeAttackSpeed: 1,
            movementSpeed: 1,
        },
        imgPath: 'rogue-gloves.png',
    },
    {
        id: 4,
        name: 'Knight Gauntlets',
        refName: 'hands_knight_uncommon',
        rarity: 'uncommon',
        stats: {
            health: 1,
            meleeDamage: 1,
        },
        imgPath: 'knight-gauntlets.png',
    },
    {
        id: 5,
        name: 'Wizard Gloves',
        refName: 'hands_wizard_rare',
        rarity: 'rare',
        stats: {
            spellDamage: 1,
            spellSpeed: 1,
            spellFirerate: 2,
        },
        imgPath: 'winter-gloves.png',
    },
    {
        id: 6,
        name: 'Rogue Gauntlets',
        refName: 'hands_rogue_rare',
        rarity: 'rare',
        stats: {
            meleeAttackSpeed: 2,
            movementSpeed: 1,
        },
        imgPath: 'rogue-gloves.png',
    },
    {
        id: 7,
        name: 'Knight Gauntlets',
        refName: 'hands_knight_rare',
        rarity: 'rare',
        stats: {
            health: 1,
            meleeDamage: 2,
        },
        imgPath: 'knight-gauntlets.png',
    },
    {
        id: 8,
        name: 'Wizard Gloves',
        refName: 'hands_wizard_epic',
        rarity: 'epic',
        stats: {
            spellDamage: 1,
            spellSpeed: 2,
            spellFirerate: 2,
        },
        imgPath: 'winter-gloves.png',
    },
    {
        id: 9,
        name: 'Rogue Gauntlets',
        refName: 'hands_rogue_epic',
        rarity: 'epic',
        stats: {
            meleeAttackSpeed: 2,
            movementSpeed: 2,
        },
        imgPath: 'rogue-gloves.png',
    },
    {
        id: 10,
        name: 'Knight Gauntlets',
        refName: 'hands_knight_epic',
        rarity: 'epic',
        stats: {
            health: 2,
            meleeDamage: 2,
        },
        imgPath: 'knight-gauntlets.png',
    },
    {
        id: 11,
        name: 'Wizard Gloves',
        refName: 'hands_wizard_legendary',
        rarity: 'legendary',
        stats: {
            spellDamage: 2,
            spellSpeed: 2,
            spellFirerate: 2,
        },
        imgPath: 'winter-gloves.png',
    },
    {
        id: 12,
        name: 'Rogue Gauntlets',
        refName: 'hands_rogue_legendary',
        rarity: 'legendary',
        stats: {
            meleeAttackSpeed: 3,
            movementSpeed: 2,
        },
        imgPath: 'rogue-gloves.png',
    },
    {
        id: 13,
        name: 'Knight Gauntlets',
        refName: 'hands_knight_legendary',
        rarity: 'legendary',
        stats: {
            health: 2,
            meleeDamage: 3,
        },
        imgPath: 'knight-gauntlets.png',
    },
    {
        id: 14,
        name: 'Slime Gauntlets',
        refName: 'hands_slime_rare',
        rarity: 'rare',
        stats: {
            health: 1,
            movementSpeed: 1,
            meleeDamage: 1,
            spellDamage: 1,
            spellSpeed: 1,
        },
        imgPath: 'slime-gloves.png',
        // broad: true,
    },
    {
        id: 16,
        name: 'Slime Gauntlets',
        refName: 'hands_slime_legendary',
        rarity: 'legendary',
        stats: {
            health: 1,
            movementSpeed: 2,
            meleeDamage: 1,
            meleeAttackSpeed: 1,
            spellDamage: 1,
            spellSpeed: 1,
            spellFirerate: 1
        },
        imgPath: 'slime-gloves.png',
    },
    {
        id: 18,
        name: 'Ice Gauntlets',
        refName: 'hands_ice_legendary',
        rarity: 'legendary',
        stats: {
            health: 5,
        },
        imgPath: 'icon_ice_armour_gloves.png',
    },
    {
        id: 19,
        name: 'Crab Gauntlets',
        refName: 'hands_crab',
        rarity: 'rare',
        stats: {
            meleeDamage: 2,
            meleeAttackSpeed: 1,
        },
        traits: {
            spiky: 0.10
        },
        imgPath: 'icon_crab_armour_gloves.png',
    },
];
