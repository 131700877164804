import React, { Component } from "react"
import { connect } from 'react-redux'
import { Grid, Paper } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import {styled} from "@mui/material/styles";

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: '#362d44',
  },
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#0a83d4',
  }
}));

class MovingAccountExpBar extends Component {
  constructor() {
    super()
    this.state = {
      timer: 0,
      levelToDisplay: 1
    }
  }

  componentDidUpdate(prevProps, prevState) {

  }

  updateInterval(targetExpRatio, targetLevel, prevLevel, prevExpRatio) {
    const passed = Date.now() - this._timerStarted
    // let nextTimer = (passed / 2000) + prevExpRatio ;
    let nextTimer = (passed / 5000) + prevExpRatio

    if (nextTimer > 1) nextTimer = 1

    if (this.state.timer >= 1 || (prevLevel == targetLevel && this.state.timer >= targetExpRatio)) { // STOP

      if (prevLevel != targetLevel) {
        prevLevel += 1
        this.setState({ 'levelToDisplay': prevLevel })
        prevExpRatio = 0
        this._timerStarted = Date.now()
        this.setState({ timer: 0 }, () => {
          window.requestAnimationFrame(() => { this.updateInterval(this.props.expRatio, this.props.level, prevLevel, prevExpRatio) })
        })
      }
    }
    else { // CONTINUE

      this.setState({ timer: nextTimer }, () => {
        window.requestAnimationFrame(() => { this.updateInterval(this.props.expRatio, this.props.level, prevLevel, prevExpRatio) })
      })
    }
  }

  setTimer() {
    this._timerStarted = Date.now()
    this.setState({ 'timer': this.props.PrevExpRatio, 'levelToDisplay': this.props.prevAccountLevel }, () => { this.updateInterval(this.props.expRatio, this.props.level, this.props.prevLevel, this.props.prevExpRatio) })
  }

  componentDidMount() {
    this.setTimer()
  }

  render() {
    const { classes } = this.props
    let { expRatio, prevLevel } = this.props
    let { levelToDisplay } = this.state

    if (!levelToDisplay || levelToDisplay == 1) { levelToDisplay = prevLevel }

    return (
      <Grid container spacing={0} justifyContent="center" style={{ width: '100%', }}>
        <Paper style={Object.assign({
          height: '100%', width: '100%',
          background: '#E7CAB2',

          borderRadius: '8px',
          borderWidth: '2px', borderStyle: 'solid',
          borderColor: '£362D44',
          boxShadow: '0 0 0 2px #FFFFFF',
          marginBottom: '2.5%'
        },
          //  bigScreenBorderProperties
        )} >


          {/* <Grid container xs={12}>
            <Grid xs={6}> */}

          <Grid xs={12} style={{ 'textAlign': 'center', width: '100%', marginBottom: '0.5%' }}>

            <div style={{
              fontSize: '18px', textAlign: 'center',
              width: '100%'
            }}><strong> Account Level {levelToDisplay} </strong></div>

          </Grid>
          <Grid xs={12} style={{ 'textAlign': 'center', height: '100%', width: '100%' }}>
            <StyledLinearProgress
              color="primary"
              style={{
                marginTop: '6px',
                height: '12px',
                borderBottomLeftRadius: '3px',
                borderBottomRightRadius: '3px',


                borderWidth: '2px', borderStyle: 'solid',
                borderColor: '#3d4578',
                boxShadow: '0 0 0 2px #000000',
              }} variant="determinate"
              value={this.state.timer * 100}
            />
          </Grid>
          {/* </Grid>s */}

          {/* <Grid xs={6}>
              <RankDialog />
            </Grid>
          </Grid> */}

        </Paper>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    prevLevel: state.stuff.prevAccountLevel,
    prevExpRatio: state.stuff.prevAccountExpRatio,
    level: state.stuff.accountLevel,
    expRatio: state.stuff.accountExpRatio,
  }
}

export default connect(mapStateToProps)(MovingAccountExpBar)