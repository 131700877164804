import React from 'react'
import DoubleColorChangingIcon from '../DoubleColorChangingIcon.js'
import { toggleMute } from '../../index.js'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import {iconStyle, iconStyleSmall} from '../uiStyles.js'

function IconMute(props) {
    let mute = props.mute

    let chosenIconStyle
    if (props.buttonSize == 'small') {
        chosenIconStyle = iconStyleSmall
    } else {
        chosenIconStyle = iconStyle
    }

    return <DoubleColorChangingIcon
        bool={!mute}
        buttonFunction={toggleMute}
        buttonFunction2={toggleMute}
        config={
            {
                idle: {
                    fillColor: '#7C4E4D',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#543131',
                },
                hover: {
                    fillColor: '#CC7A82',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#AA5561',
                },
                down: {
                    fillColor: '#CC7A82',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#AA5561',
                },
            }}
        buttonStyling={chosenIconStyle}
        textColor='#b06c66'
        IconImage={VolumeUpIcon}
        IconImage2={VolumeOffIcon}
    />
}
export default (IconMute)