"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extraDrops = exports.dropGroups = exports.secondarySpellColors = exports.primarySpellColors = exports.gloveSpellColors = exports.gearDropColors = exports.secondarySpells = exports.primarySpells = void 0;
exports.primarySpells = ['staff', 'wand', 'dagger', 'sword'];
exports.secondarySpells = ['spell', 'tome', 'cloak', 'dash'];
exports.gearDropColors = {
    't0': { 'customCircleColor': '#70767F' }, // base common
    't1': { 'customCircleColor': '#2FAD25' }, // base uncommon
    't2': { 'customCircleColor': '#2C8F24' },
    't3': { 'customCircleColor': '#0F7F06' },
    't4': { 'customCircleColor': '#0E7006' },
    't5': { 'customCircleColor': '#1A82FF' },
    't6': { 'customCircleColor': '#0B6FE7' }, // base rare
    't7': { 'customCircleColor': '#085EC6' },
    't8': { 'customCircleColor': '#0353B4' },
    't9': { 'customCircleColor': '#8948EF' }, // base epic
    't10': { 'customCircleColor': '#6E37C4' },
    't11': { 'customCircleColor': '#552C94' },
    't12': { 'customCircleColor': '#F47C40' }, // base legendary
    't13': { 'customCircleColor': '#F47C40' }, // base legendary
    't14': { 'customCircleColor': '#F47C40' }, // base legendary
    't15': { 'customCircleColor': '#F47C40' }, // base legendary
};
exports.gloveSpellColors = {
    't0': { 'customCircleColor': '#70767F' },
    't1': { 'customCircleColor': '#2C8F24' }, // base uncommon
    't2': { 'customCircleColor': '#1A82FF' },
    't3': { 'customCircleColor': '#1A82FF' }, // base rare
    't4': { 'customCircleColor': '#0353B4' },
    't5': { 'customCircleColor': '#8948EF' }, // base epic
    't6': { 'customCircleColor': '#F47C40' }, // base legendary
};
exports.primarySpellColors = {
    't0': { 'customCircleColor': '#70767F' }, // base common
    't1': { 'customCircleColor': '#2FAD25' }, // base uncommon
    't2': { 'customCircleColor': '#2C8F24' },
    't3': { 'customCircleColor': '#0F7F06' },
    't4': { 'customCircleColor': '#0E7006' },
    't5': { 'customCircleColor': '#1A82FF' }, // base rare
    't6': { 'customCircleColor': '#085EC6' },
    't7': { 'customCircleColor': '#0353B4' },
    't8': { 'customCircleColor': '#8948EF' }, // base epic
    't9': { 'customCircleColor': '#6E37C4' },
};
exports.secondarySpellColors = {
    't0': { 'customCircleColor': '#70767F' },
    't1': { 'customCircleColor': '#2C8F24' }, // base uncommon
    't2': { 'customCircleColor': '#1A82FF' },
    't3': { 'customCircleColor': '#1A82FF' }, // base rare
    't4': { 'customCircleColor': '#0353B4' },
    't5': { 'customCircleColor': '#8948EF' }, // base epic
    't6': { 'customCircleColor': '#F47C40' }, // base legendary
};
function createGearArray(tier, weight) {
    return [
        { type: 'top', id: 100 + tier, weight: weight },
        { type: 'top', id: 200 + tier, weight: weight },
        { type: 'top', id: 300 + tier, weight: weight },
        { type: 'body', id: 100 + tier, weight: weight },
        { type: 'body', id: 200 + tier, weight: weight },
        { type: 'body', id: 300 + tier, weight: weight },
    ];
}
function createGlovesArray(tier, weight) {
    return [
        { type: 'hand', id: 110 + tier, weight: weight },
        { type: 'hand', id: 120 + tier, weight: weight },
        { type: 'hand', id: 130 + tier, weight: weight },
        { type: 'hand', id: 140 + tier, weight: weight },
        { type: 'hand', id: 150 + tier, weight: weight },
        { type: 'hand', id: 160 + tier, weight: weight },
        { type: 'hand', id: 170 + tier, weight: weight },
        { type: 'hand', id: 180 + tier, weight: weight },
    ];
}
function createWeaponsArray(tier, weight) {
    const staffTier = 248 + tier;
    const wandTier = 231 + tier;
    const swordTier = 900 + tier;
    const daggerTier = 930 + tier;
    const katanaTier = 1000 + tier;
    return [
        { type: 'spell', id: staffTier, weight: weight },
        { type: 'spell', id: wandTier, weight: weight },
        { type: 'spell', id: swordTier, weight: weight },
        { type: 'spell', id: daggerTier, weight: weight },
        { type: 'spell', id: katanaTier, weight: weight },
    ];
}
function createSecondaryWeaponsArray(tier, weight) {
    const spellTier = 800 + tier;
    const tomeTier = 850 + tier;
    const stealthTier = 750 + tier;
    // const dashTier = 650 + tier
    const quakeTier = 950 + tier;
    const ninjaStarTier = 1020 + tier;
    const sealTier = 1040 + tier;
    const skullTier = 1060 + tier;
    return [
        { type: 'spell', id: spellTier, weight: weight },
        { type: 'spell', id: tomeTier, weight: weight },
        { type: 'spell', id: stealthTier, weight: weight },
        { type: 'spell', id: quakeTier, weight: weight },
        { type: 'spell', id: ninjaStarTier, weight: weight },
        { type: 'spell', id: sealTier, weight: weight },
        { type: 'spell', id: skullTier, weight: weight },
    ];
}
let nothingBaseDropRate = 20;
exports.dropGroups = [
    {
        enemies: ['tinycreature'],
        drops: [
            { type: 'nothing', id: 0, weight: 10 }
        ],
        hpMod: 0.3,
    },
    {
        enemies: ['slime', 'meleeSlime', 'flowerCreature'],
        drops: [
            { type: 'nothing', id: 0, weight: nothingBaseDropRate },
        ].concat(createGearArray(2, 8)).concat(createGearArray(3, 3)).concat(createWeaponsArray(1, 8)),
        hpMod: 0.3
    },
    {
        enemies: ['flowerBoss', 'leopard', 'woodlandWhelp', 'crabNormal', 'crabSuperior', 'starfishNormal', 'Boss Slime', 'Giant Red Slime'],
        drops: [
            { type: 'nothing', id: 0, weight: nothingBaseDropRate },
        ].concat(createGearArray(3, 8).concat(createGearArray(4, 4)).concat(createGlovesArray(1, 2))).concat(createWeaponsArray(2, 8)).concat(createSecondaryWeaponsArray(1, 4)),
        hpMod: 0.5
    },
    {
        enemies: ['creature', 'seahorse', 'cannon', 'piranha', 'seahorseSuperior', 'King Leopard', 'King Frederick', 'Woodland Beast', 'slimeIce'],
        drops: [
            { type: 'nothing', id: 0, weight: nothingBaseDropRate },
        ].concat(createGearArray(4, 8).concat(createGearArray(5, 1)).concat(createGlovesArray(2, 2))).concat(createWeaponsArray(3, 8)).concat(createSecondaryWeaponsArray(2, 4)),
        hpMod: 0.7
    },
    {
        enemies: ['iceGolem', 'babyDragonWhelp', 'darkWiz', 'King Piranha', 'Mega Cannon', 'Big Charlie',],
        drops: [
            { type: 'nothing', id: 0, weight: nothingBaseDropRate },
        ].concat(createGearArray(5, 8)).concat(createGearArray(6, 2)).concat(createGlovesArray(2, 2).concat(createWeaponsArray(4, 8)).concat(createSecondaryWeaponsArray(2, 4))),
        hpMod: 1
    },
    {
        enemies: ['Mammoth', 'MammothSuperior', 'dragonWhelp', 'hellhound', 'mummy'],
        drops: [
            { type: 'nothing', id: 0, weight: nothingBaseDropRate },
        ].concat(createGearArray(6, 8)).concat(createGearArray(7, 2)).concat(createGlovesArray(3, 2).concat(createWeaponsArray(5, 8)).concat(createSecondaryWeaponsArray(3, 4))),
        hpMod: 1.5
    },
    {
        enemies: ['Giant Ice Slime', 'Head Dark Wizard', 'Giant Lava Slime', 'Hellhound Pack Leader', 'King Mammoth'],
        drops: [
            { type: 'nothing', id: 0, weight: nothingBaseDropRate },
        ].concat(createGearArray(7, 8)).concat(createGearArray(8, 2)).concat(createGlovesArray(3, 2).concat(createWeaponsArray(6, 8)).concat(createSecondaryWeaponsArray(3, 4))),
        hpMod: 2
    },
    {
        enemies: ['Dragon Lord', 'Ice Golem Boss', 'Spooky Charlie'],
        drops: [
            { type: 'nothing', id: 0, weight: nothingBaseDropRate },
        ].concat(createGearArray(8, 8)).concat(createGearArray(9, 2)).concat(createGlovesArray(3, 3).concat(createGlovesArray(4, 2).concat(createWeaponsArray(6, 8)).concat(createWeaponsArray(7, 4)).concat(createSecondaryWeaponsArray(3, 4).concat(createSecondaryWeaponsArray(4, 2))))),
        hpMod: 3
    },
    {
        enemies: ['Grix'],
        drops: [
            { type: 'nothing', id: 0, weight: nothingBaseDropRate },
        ].concat(createGearArray(9, 8)).concat(createGearArray(10, 2)).concat(createGlovesArray(4, 3).concat(createGlovesArray(5, 2).concat(createWeaponsArray(7, 8)).concat(createWeaponsArray(8, 4)).concat(createSecondaryWeaponsArray(4, 4).concat(createSecondaryWeaponsArray(5, 2))))),
        hpMod: 1
    }
];
exports.dropGroups.forEach((group) => {
    if (group.enemies.includes('tinyCreature')) {
        group.drops.push({ type: 'manaPotion', id: 0, weight: 0.01 }, { type: 'healthPotion', id: 0, weight: 0.01 });
    }
    else {
        group.drops.push({ type: 'manaPotion', id: 0, weight: 15 }, { type: 'healthPotion', id: 0, weight: 15 });
        // {type: 'damageTome', id: 0, weight: 1000},
        //   {type: 'defenseTome', id: 0, weight: 1000},
        //   {type: 'speedTome', id: 0, weight: 1000},
        //   {type: 'agilityTome', id: 0, weight: 1000},
        //   {type: 'vitalityTome', id: 0, weight: 1000},
        //   {type: 'spiritTome', id: 0, weight: 1000},
        //   {type: 'manaTome', id: 0, weight: 1000},
        //   {type: 'healthTome', id: 0, weight: 1000},
    }
});
//health
//mana
//damage  dragonWhelp dragonBig
//agility iceGolem hellhoundBoss iceGolemBoss
//speed MammothBoss  MammothSuperior
//defense
//vitality slimeLavaBoss
//spirit slimeIceBoss
/*
starter dungeons:
Dragon valley: Dragonbig drops dmg
Ice: icegolem drops agility

Ice wave trial: mammoth+iceslime drops speed+spirit (it should be one randomly chosen not both)
 -- split into two, one with each

need:
def, hp, mana
def first
for now just do others for test?

could make hellhound boss dungeon and make hellhound drop def for now
could remove hellhound boss from overworld to make it special, and buff him up


*/
exports.extraDrops = [
    { enemy: 'dragonWhelp', drop: { type: 'damageTome', id: 0, weight: 1 } },
    { enemy: 'Dragon Lord', drop: { type: 'damageTome', id: 0, weight: 3, alwaysDrops: true } },
    // {enemy: 'slime', drop: {type: 'damageTome', id: 0, weight: 3, alwaysDrops: true}},
    // {enemy: 'slime', drop: {type: 'defenseTome', id: 0, weight: 3, alwaysDrops: true}},
    { enemy: 'Head Dark Wizard', drop: { type: 'damageTome', id: 0, weight: 3, alwaysDrops: true } },
    { enemy: 'Spooky Charlie', drop: { type: 'defenseTome', id: 0, weight: 3, alwaysDrops: true } },
    { enemy: 'iceGolem', drop: { type: 'agilityTome', id: 0, weight: 1 } },
    { enemy: 'Ice Golem Boss', drop: { type: 'agilityTome', id: 0, weight: 3, alwaysDrops: true } },
    { enemy: 'MammothSuperior', drop: { type: 'speedTome', id: 0, weight: 1 } },
    { enemy: 'King Mammoth', drop: { type: 'speedTome', id: 0, weight: 1, alwaysDrops: true } },
    { enemy: 'Hellhound Pack Leader', drop: { type: 'agilityTome', id: 0, weight: 1, alwaysDrops: true } },
    { enemy: 'Giant Ice Slime', drop: { type: 'spiritTome', id: 0, weight: 1, alwaysDrops: true } },
    { enemy: 'Giant Lava Slime', drop: { type: 'vitalityTome', id: 0, weight: 1, alwaysDrops: true } },
    { enemy: 'Grix', drop: { type: 'healthOrManaTome', id: 0, weight: 1, alwaysDrops: true } },
];
