"use strict";
const CurrencyData = [
    {
        id: 1,
        name: 'Rune',
        imgPath: 'rune.png'
    },
    {
        id: 2,
        name: 'Glyph',
        imgPath: 'glyph.png'
    }
];
module.exports = CurrencyData;
