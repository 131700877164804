"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bodyWear = void 0;
const drops_1 = require("../../../../drops");
exports.bodyWear = [
    {
        id: 1,
        name: 'Adventurer Garments',
        refName: 'body_starter.png',
        rarity: 'common',
        stats: {},
        imgPath: 'shirt.png',
        gearType: 'special',
    },
    {
        id: 2,
        name: 'Wizard Robe',
        refName: 'body_wizard_uncommon.png',
        rarity: 'uncommon',
        stats: {
        // damage: 1
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
    },
    {
        id: 3,
        name: 'Rogue Garments',
        refName: 'body_rogue_uncommon.png',
        rarity: 'uncommon',
        stats: {
        // agility: 1
        },
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
    },
    {
        id: 4,
        name: 'Knight Armor',
        refName: 'body_knight_uncommon.png',
        rarity: 'uncommon',
        stats: {
        // health: 1
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
    },
    {
        id: 5,
        name: 'Wizard Robe',
        refName: 'body_wizard_rare.png',
        rarity: 'rare',
        stats: {
        // damage: 1,
        // agility: 1,
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
    },
    {
        id: 6,
        name: 'Rogue Garments',
        refName: 'body_rogue_rare.png',
        rarity: 'rare',
        stats: {
        // agility: 2,
        // speed: 2,
        },
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
    },
    {
        id: 7,
        name: 'Knight Armor',
        refName: 'body_knight_rare.png',
        rarity: 'rare',
        stats: {
        // health: 2,
        // damage: 2,
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
    },
    {
        id: 8,
        name: 'Wizard Robe',
        refName: 'body_wizard_epic.png',
        rarity: 'epic',
        stats: {
        // damage: 2,
        // agility: 1,
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
    },
    {
        id: 9,
        name: 'Rogue Garments',
        refName: 'body_rogue_epic.png',
        rarity: 'epic',
        stats: {
        // agility: 3,
        // speed: 2,
        },
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
    },
    {
        id: 10,
        name: 'Knight Armor',
        refName: 'body_knight_epic.png',
        rarity: 'epic',
        stats: {
        // health: 3,
        // damage: 2,
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
    },
    {
        id: 11,
        name: 'Wizard Robe',
        refName: 'body_wizard_legendary.png',
        rarity: 'legendary',
        stats: {
        // damage: 2,
        // agility: 2,
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
    },
    {
        id: 12,
        name: 'Rogue Garments',
        refName: 'body_rogue_legendary.png',
        rarity: 'legendary',
        stats: {
        // agility: 3,
        // speed: 3,
        },
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
    },
    {
        id: 13,
        name: 'Knight Armor',
        refName: 'body_knight_legendary.png',
        rarity: 'legendary',
        stats: {
        // health: 3,
        // damage: 3,
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
    },
    {
        id: 14,
        name: 'Slime Armor',
        refName: 'body_slime_rare.png',
        rarity: 'rare',
        stats: {
        // health: 1,
        // speed: 1,
        // damage: 1,
        // agility: 1,
        },
        imgPath: 'slime-armor.png',
        gearType: 'special'
    },
    {
        id: 16,
        name: 'Slime Armor',
        refName: 'body_slime_legendary.png',
        rarity: 'legendary',
        stats: {
        // health: 2,
        // speed: 1,
        // damage: 2,
        // agility: 1,
        },
        imgPath: 'slime-armor.png',
        gearType: 'special'
    },
    {
        id: 17,
        name: 'Dark Armor',
        refName: 'dark_armor.png',
        rarity: 'legendary',
        stats: {
        // health: 1,
        // damage: 4,
        },
        imgPath: 'spiked-armor.png',
        broad: true,
        gearType: 'heavyArmor',
    },
    // {
    // id: 5,
    //     name: 'Fire Wizard Robe',
    //     refName: 'wizardRobe',
    //     rarity: 'Rare',
    //     stats: {
    //         fireDamage: 2,
    //         spellSpeed: 1,
    //         agility: 1,
    //     }
    // },
    {
        id: 18,
        name: 'Ice Armor',
        refName: 'body_ice_legendary.png',
        rarity: 'legendary',
        stats: {
        // health: 6,
        },
        imgPath: 'icon_ice_armour_body.png',
        broad: true,
        gearType: 'heavyArmor',
    },
    {
        id: 19,
        name: 'Crab Armor',
        refName: 'body_crab.png',
        rarity: 'rare',
        stats: {
        // damage: 2,
        // agility: 1,
        },
        traits: {
            spiky: 0.1
        },
        imgPath: 'icon_crab_armour_body.png',
        broad: true,
        gearType: 'heavyArmor',
    },
    {
        id: 101,
        name: 'T1 Leather Armor',
        rarity: 'uncommon',
        stats: {
            monsterDamage: 1
        },
        refName: 'body_rogue_uncommon.png',
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t1.customCircleColor
    },
    {
        id: 102,
        name: 'T2 Leather Armor',
        rarity: 'uncommon',
        stats: {
            monsterDamage: 2
        },
        refName: 'body_rogue_uncommon.png',
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t2.customCircleColor
    },
    {
        id: 103,
        name: 'T3 Leather Armor',
        rarity: 'uncommon',
        stats: {
            monsterDamage: 3
        },
        refName: 'body_rogue_uncommon.png',
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t3.customCircleColor
    },
    {
        id: 104,
        name: 'T4 Leather Armor',
        rarity: 'uncommon',
        stats: {
            monsterDamage: 4
        },
        refName: 'body_rogue_uncommon.png',
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t4.customCircleColor
    },
    {
        id: 105,
        name: 'T5 Leather Armor',
        rarity: 'rare',
        stats: {
            monsterDamage: 5
        },
        refName: 'body_rogue_uncommon.png',
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t5.customCircleColor
    },
    {
        id: 106,
        name: 'T6 Leather Armor',
        rarity: 'rare',
        stats: {
            monsterDamage: 6
        },
        refName: 'body_rogue_rare.png',
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t6.customCircleColor
    },
    {
        id: 107,
        name: 'T7 Leather Armor',
        rarity: 'rare',
        stats: {
            monsterDamage: 7
        },
        refName: 'body_rogue_rare.png',
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t7.customCircleColor
    },
    {
        id: 108,
        name: 'T8 Leather Armor',
        rarity: 'rare',
        stats: {
            monsterDamage: 8
        },
        refName: 'body_rogue_rare.png',
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t8.customCircleColor
    },
    {
        id: 109,
        name: 'T9 Leather Armor',
        rarity: 'epic',
        stats: {
            monsterDamage: 9
        },
        refName: 'body_rogue_epic.png',
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t9.customCircleColor
    },
    {
        id: 110,
        name: 'T10 Leather Armor',
        rarity: 'epic',
        stats: {
            monsterDamage: 10
        },
        refName: 'body_rogue_epic.png',
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t10.customCircleColor
    },
    {
        id: 111,
        name: 'T11 Leather Armor',
        rarity: 'epic',
        stats: {
            monsterDamage: 11
        },
        refName: 'body_rogue_epic.png',
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t11.customCircleColor
    },
    {
        id: 112,
        name: 'T12 Leather Armor',
        rarity: 'legendary',
        stats: {
            monsterDamage: 12
        },
        refName: 'body_rogue_epic.png',
        imgPath: 'rogue-garments.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t12.customCircleColor
    },
    {
        id: 201,
        name: 'T1 Wizard Robe',
        refName: 'body_wizard_uncommon.png',
        rarity: 'uncommon',
        stats: {
            mana: 1
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t1.customCircleColor
    },
    {
        id: 202,
        name: 'T2 Wizard Robe',
        refName: 'body_wizard_uncommon.png',
        rarity: 'uncommon',
        stats: {
            mana: 2
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t2.customCircleColor
    },
    {
        id: 203,
        name: 'T3 Wizard Robe',
        refName: 'body_wizard_uncommon.png',
        rarity: 'uncommon',
        stats: {
            mana: 3
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t3.customCircleColor
    },
    {
        id: 204,
        name: 'T4 Wizard Robe',
        refName: 'body_wizard_uncommon.png',
        rarity: 'uncommon',
        stats: {
            mana: 4
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t4.customCircleColor
    },
    {
        id: 205,
        name: 'T5 Wizard Robe',
        refName: 'body_wizard_rare.png',
        rarity: 'rare',
        stats: {
            mana: 5
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t5.customCircleColor
    },
    {
        id: 206,
        name: 'T6 Wizard Robe',
        refName: 'body_wizard_rare.png',
        rarity: 'rare',
        stats: {
            mana: 6
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t6.customCircleColor
    },
    {
        id: 207,
        name: 'T7 Wizard Robe',
        refName: 'body_wizard_rare.png',
        rarity: 'rare',
        stats: {
            mana: 7
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t7.customCircleColor
    },
    {
        id: 208,
        name: 'T8 Wizard Robe',
        refName: 'body_wizard_rare.png',
        rarity: 'rare',
        stats: {
            mana: 8
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t8.customCircleColor
    },
    {
        id: 209,
        name: 'T9 Wizard Robe',
        refName: 'body_wizard_epic.png',
        rarity: 'epic',
        stats: {
            mana: 9
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t9.customCircleColor
    },
    {
        id: 210,
        name: 'T10 Wizard Robe',
        refName: 'body_wizard_epic.png',
        rarity: 'epic',
        stats: {
            mana: 10
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t10.customCircleColor
    },
    {
        id: 211,
        name: 'T11 Wizard Robe',
        refName: 'body_wizard_epic.png',
        rarity: 'epic',
        stats: {
            mana: 11
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t11.customCircleColor
    },
    {
        id: 212,
        name: 'T12 Wizard Robe',
        refName: 'body_wizard_legendary.png',
        rarity: 'legendary',
        stats: {
            mana: 12
        },
        imgPath: 'robe.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t11.customCircleColor
    },
    {
        id: 301,
        name: 'T1 Knight Armor',
        refName: 'body_knight_uncommon.png',
        rarity: 'uncommon',
        stats: {
            monsterDefense: 1
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t1.customCircleColor
    },
    {
        id: 302,
        name: 'T2 Knight Armor',
        refName: 'body_knight_uncommon.png',
        rarity: 'uncommon',
        stats: {
            monsterDefense: 2
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t2.customCircleColor
    },
    {
        id: 303,
        name: 'T3 Knight Armor',
        refName: 'body_knight_uncommon.png',
        rarity: 'uncommon',
        stats: {
            monsterDefense: 3
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t3.customCircleColor
    },
    {
        id: 304,
        name: 'T4 Knight Armor',
        refName: 'body_knight_uncommon.png',
        rarity: 'rare',
        stats: {
            monsterDefense: 4
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t4.customCircleColor
    },
    {
        id: 305,
        name: 'T5 Knight Armor',
        refName: 'body_knight_uncommon.png',
        rarity: 'rare',
        stats: {
            monsterDefense: 5
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t5.customCircleColor
    },
    {
        id: 306,
        name: 'T6 Knight Armor',
        refName: 'body_knight_rare.png',
        rarity: 'rare',
        stats: {
            monsterDefense: 6
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t6.customCircleColor
    },
    {
        id: 307,
        name: 'T7 Knight Armor',
        refName: 'body_knight_rare.png',
        rarity: 'rare',
        stats: {
            monsterDefense: 7
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t7.customCircleColor
    },
    {
        id: 308,
        name: 'T8 Knight Armor',
        refName: 'body_knight_rare.png',
        rarity: 'rare',
        stats: {
            monsterDefense: 8
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t8.customCircleColor
    },
    {
        id: 309,
        name: 'T9 Knight Armor',
        refName: 'body_knight_epic.png',
        rarity: 'epic',
        stats: {
            monsterDefense: 9
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t9.customCircleColor
    },
    {
        id: 310,
        name: 'T10 Knight Armor',
        refName: 'body_knight_epic.png',
        rarity: 'epic',
        stats: {
            monsterDefense: 10
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t10.customCircleColor
    },
    {
        id: 311,
        name: 'T11 Knight Armor',
        refName: 'body_knight_epic.png',
        rarity: 'epic',
        stats: {
            monsterDefense: 11
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t11.customCircleColor
    },
    {
        id: 312,
        name: 'T12 Knight Armor',
        refName: 'body_knight_epic.png',
        rarity: 'epic',
        stats: {
            monsterDefense: 12
        },
        imgPath: 'knight-armor.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t11.customCircleColor
    },
];
