import React from 'react'
import DoubleColorChangingIcon from '../DoubleColorChangingIcon.js'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import { iconStyle, iconStyleSmall } from '../uiStyles.js'
import * as Actions from '../../actions/actions.js'

function toggleFullScreen(elem) {
    // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
    if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
        if (elem.requestFullScreen) {
            elem.requestFullScreen()
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullScreen) {
            elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen()
        }
    } else {
        if (document.cancelFullScreen) {
            document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen()
        }
    }
}


function IconFullScreen(props) {
    let fullScreen = props.fullScreen


    let chosenIconStyle
    if (props.buttonSize == 'small') {
        chosenIconStyle = iconStyleSmall
    } else {
        chosenIconStyle = iconStyle
    }

    return <DoubleColorChangingIcon
        bool={!fullScreen}
        buttonFunction={() => {
            toggleFullScreen(document.body)
            Actions.toggleFullScreen(true)
        }}
        buttonFunction2={() => {
            toggleFullScreen(document.body)
            Actions.toggleFullScreen(false)
        }}
        config={
            {
                idle: {
                    fillColor: '#7C4E4D',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#543131',
                },
                hover: {
                    fillColor: '#CC7A82',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#AA5561',
                },
                down: {
                    fillColor: '#CC7A82',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#AA5561',
                },
            }}
        buttonStyling={chosenIconStyle}
        textColor='#b06c66'
        IconImage={FullscreenIcon}
        IconImage2={FullscreenExitIcon}
    />
}
export default (IconFullScreen)