import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'
import IconGemsTwo from './icons/IconGemsTwo.js'
import Constants from '../../shared/constants'
import {bigScreenBorderProperties, colorPalette} from './uiStyles.js'
import IconGems from './icons/IconGems.js'


class Currency extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        let {coins, gems, referrer} = this.props
        return (
            <Grid container xs={12}
            style={{
                minWidth: '250px',
                maxWidth: '250px',

                // minWidth: '350px',
                // maxWidth: '350px',
                maxHeight: '55px',

            }}
            >
                <Grid xs={6}>
                    <Paper
                        style={Object.assign({
                            background: colorPalette.primary,
                            padding: '7px',
                            // minWidth: '243px',
                            // maxWidth: '243px',
                            marginLeft: '10px',
                            marginRight: '10px',
                            marginTop: '10px',
                            minWidth: '80px',
                            maxWidth: '80px',
                            // minWidth: '150px',
                            // maxWidth: '150px',
                            position: 'relative',
                            borderColor: colorPalette.primaryBorder,
                        }, bigScreenBorderProperties)}
                    >
                        <img
                            style={{
                                pointerEvents: 'none',
                                position: 'absolute',
                                // left: '-8.5px',
                                // bottom: '6px'

                                // left: '-50.5px',
                                left: '-54.5px',
                                top: '-48px',
                                transform: 'scale(0.40)'
                            }}
                            src="assets/ui/rune.png"
                        />
                        <Grid container align='center' justifyContent="center" alignItems="center" xs={12}>
                        <Grid xs={4}>
                        {/* Runes */}
                        </Grid>
                        <Grid xs={8}>
                        {coins}
                        </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid xs={6}>
                    <Paper
                        style={Object.assign({
                            background: colorPalette.primary,
                            padding: '7px',
                            marginLeft: '10px',
                            marginRight: '10px',
                            marginTop: '10px',
                            minWidth: '80px',
                            maxWidth: '80px',
                            // minWidth: '150px',
                            // maxWidth: '150px',
                            position: 'relative',
                            // minWidth: '243px',
                            // maxWidth: '243px',
                            borderColor: colorPalette.primaryBorder,
                        }, bigScreenBorderProperties)}
                    >
                        <Grid container align='center' justifyContent="center" alignItems="center" xs={12}>
                            <img
                                style={{
                                    pointerEvents: 'none',
                                    position: 'absolute',
                                    // left: '-8.5px',
                                    // bottom: '6px'

                                    // left: '-49.5px',
                                    left: '-55.5px',
                                    top: '-48px',
                                    transform: 'scale(0.40)'
                                }}
                                src="assets/ui/glyph.png"
                            />
                        <Grid xs={4}>
                        {/* Glyphs */}
                        </Grid>
                        <Grid xs={8}>
                        {gems}
                        </Grid>
                        </Grid>
                    </Paper>
                    <div
                        style={{
                            position: 'relative',
                            left: '83px',
                            top: '-57px',
                            transform: 'scale(0.82)',
                        }}
                    >
                    {
                        referrer === 'crazygames' ? 
                        <IconGems/>
                        :
                        <IconGemsTwo/>
                    }

                    </div>
                 
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        coins: state.stuff.coins,
        gems: state.stuff.gems,
        referrer: state.stuff.referrer,
    }
}

export default connect(
    mapStateToProps,
)(Currency)
