import React, { Component } from 'react'
import Button from '@mui/material/Button'

class ColorChangingUnlockWithChallengesButton extends Component {
    constructor() {
        super()
        this.state = {
            fillColor: '#E7CAB2',
            outerBorderColor: '#362D44',
            innerBorderColor: '#FFF1D2',
            config: {
                idle: {
                    fillColor: '#E7CAB2',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFF1D2',
                },
                hover: {
                    fillColor: '#E7CAB2',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFF1D2',
                },
                down: {
                    fillColor: '#E7CAB2',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFF1D2',
                }
            }
        }
    }

    componentDidMount() {
        let { config } = this.props
        this.setState({ config: config,
        fillColor: config.idle.fillColor,
        outerBorderColor: config.idle.outerBorderColor,
        innerBorderColor: config.idle.innerBorderColor
        })
    }

    render() {
        let { fillColor, innerBorderColor, outerBorderColor, config } = this.state
        let { buttonFunction, title, buttonStyling, textColor } = this.props

        // console.log("CHOSEN FILL COLOR", fillColor)
        if (!textColor) {textColor = 'white'}
        return (
            <Button
                style={
                    Object.assign({
                        fontSize: '1.5vh',
                        // color: textColor,
                        borderRadius: '6px',
                        borderWidth: '2px', borderStyle: 'solid',
                        background: fillColor,
                        borderColor: innerBorderColor,
                        boxShadow: `0 0 0 2px ${outerBorderColor}`,
                        textTransform: 'none'
                    },
                    buttonStyling)
                }

                variant="contained"

                onMouseOver={() => {
                    this.setState({
                        fillColor: config.hover.fillColor,
                        outerBorderColor: config.hover.outerBorderColor,
                        innerBorderColor: config.hover.innerBorderColor
                    })
                }}
                onMouseOut={() => {
                    this.setState({
                        fillColor: config.idle.fillColor,
                        outerBorderColor: config.idle.outerBorderColor,
                        innerBorderColor: config.idle.innerBorderColor
                    })
                }
                }
                onMouseDown={(e) => {
                    this.setState({
                        fillColor: config.down.fillColor,
                        outerBorderColor: config.down.outerBorderColor,
                        innerBorderColor: config.down.innerBorderColor
                    })
                }}

                onClick={(e) => {
                    e.nativeEvent.stopImmediatePropagation()
                    e.preventDefault()
                    e.stopPropagation()

                    buttonFunction()
                }}
            >
                <b  style={{
                        fontSize: 'max(1.2vh, 19px)', color: textColor,
                    }}> 
                    {title} </b> 

                <img
                    style={{
                        pointerEvents: 'none',
                        position: 'absolute',
                        // left: '-8.5px',
                        // bottom: '6px'

                        // left: '-23.5px',
                        left: '-35.5px',
                        bottom: '4px',
                        transform: 'scale(0.89)'
                    }}
                    src="assets/ui/icon_challenges.png"
                                />
            </Button >
        )
    }
}

export default (ColorChangingUnlockWithChallengesButton)