import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'
import Typography from '@mui/material/Typography'

class KillCounter extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    componentDidMount() {}

    render() {
        let { playerKills } = this.props
        return (
            <Grid container justifyContent="center" style={{ marginBottom: '5%', textAlign: 'center', height: '100%', width: '100%' }}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}  alignItems="center" justifyContent="center" style={{ textAlign: 'center', position: 'relative'}}>
                    {/* <img style={{ objectFit: 'scaleDown', width: '70%', }} src={require(`../../../public/assets/icons/kills-skull.png`).default}></img> */}
                    {/* <img style={{ objectFit: 'scaleDown', width: '70%', }} src={require(`../../../public/assets/icons/kills-skull.png`).default}></img> */}
                    <img style={{ objectFit: 'scaleDown', width: '85%', 
                position: 'absolute',
                transform: 'translateY(-50%)',
                top: '50%',
                left: '0%'
                }} src='assets/icons/kills-skull.png'></img>
                </Grid>
                <Grid item xs={4} style={{ alignItems: 'stretch', height: '100%' }}>
                        <Typography variant="h3">
                                <strong>{playerKills}</strong>
                        </Typography>
                </Grid>         
            </Grid>
        );
    }
}
function mapStateToProps(state) {
    return {
        playerKills: state.stuff.playerKills
    }
}


export default connect(
    mapStateToProps,
)(KillCounter)
