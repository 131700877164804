import React, { Component } from 'react'
import { Card, Popover, Paper, Grid} from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import * as Actions from '../actions/actions'
import './ui.css'
import { IS_TOUCH } from '../game.js'
import ColorTable from "../../shared/colorTable";
import {styled} from "@mui/material/styles";
import { phaserObject, player } from '../create'
import { equippedSpells } from '../equippedSpells'
import { fire } from '../game.js'

const StyledLinearProgress = styled(LinearProgress)({
  '& .MuiLinearProgress-bar': {
    transition: 'none',
  },
});

class SpellBox extends Component {
  constructor() {
    super()
    this.state = {
      selected: false,
      timer: 100,
      popoverOpen: false,
      reloading: false,
      timerObject: null,
      anchorEl: null,
      timerUpdating: true,
      rarityInfo: ColorTable.rarityInfo,
      manaColorTable: ColorTable.elementHex,
    }
    this.togglePopoverClose = this.togglePopoverClose.bind(this)
    this.togglePopoverOpen = this.togglePopoverOpen.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (((this.props.triggerReload != nextProps.triggerReload) && nextProps.triggerReload == true)
      || this.state.timerUpdating == true
      || (this.props.selected != nextProps.selected)
      || (this.props.spell.resourceCost != null && this.props.availableResources[this.props.spell.resourceCost.resource] != nextProps.availableResources[this.props.spell.resourceCost.resource])
      || (this.state.popoverOpen != nextState.popoverOpen)
      || (this.props.spell.name != nextProps.spell.name)
      || (this.props.spell.rarity != nextProps.spell.rarity) 
      ||  (this.props.spell.selected != nextProps.spell.selected) 
      ||  (this.props.spellPointerActive != nextProps.spellPointerActive) 
      || (this.props.gameFocusOverlay != nextProps.gameFocusOverlay)	 

    ) {
      if (this.spell) {
        if (this.spell.name != nextProps.spell.name) {
          return true
        }
        else { return false }
      }
      return true
    }
    else {
      return false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { triggerReload, spell, availableMana, availableResources} = this.props
    const triggerReloadChanged = !prevProps.triggerReload && triggerReload
    if (triggerReload) {
      let availableShots = 0
      if (spell.resourceCost != null && spell.resourceCost.resource !== 'mana') {
        availableShots = Math.floor(availableResources[spell.resourceCost.resource] / spell.resourceCost.cost)
      } else {
          availableShots = 1
      }
      if (availableShots > 0) {
        this.setTimer()
      }
      // else {
      //   console.log('triggering reload')
      //   Actions.triggerReload(this.props.assignedKey, false)
      // }
    }
  }

  updateInterval() {
    const passed = Date.now() - this._timerStarted
    let increaseInterval = this.props.spell.spellCooldown
    let nextTimer = (passed / 10) / increaseInterval

    if (nextTimer > 100) nextTimer = 100

    if (this.state.timer >= 100) { // STOP
      this.setState({ 'timerUpdating': false })
    }
    else { // CONTINUE
      this.setState({ timer: nextTimer }, () => {
        window.requestAnimationFrame(() => { this.updateInterval() })
      })
    }
  }

  setTimer() {
    this._timerStarted = Date.now()
    Actions.triggerReload(this.props.assignedKey, false)
    this.setState({ 'timerUpdating': true })
    this.setState({ 'timer': 0 }, () => { this.updateInterval() })
  }

  togglePopoverOpen = event => {
    if (!IS_TOUCH) {
      this.setState({ popoverOpen: true, anchorEl: event.currentTarget })
    }
  }

  togglePopoverClose = event => {
    if (!IS_TOUCH) {
      this.setState({ popoverOpen: false, anchorEl: null })
    }
  }

  render() {
    const { classes, spell, availableMana, assignedKey, label, availableResources, selected, spellPointerActive, gameFocusOverlay } = this.props
    let { popoverOpen, anchorEl, rarityInfo, manaColorTable, } = this.state
    // let backgroundColor = spell.selected == true ? 'orange' : 'white'
      let backgroundColor = selected == true ? 'orange' : 'white'
    let availableShots
    if (spell.resourceCost != null && spell.resourceCost.resource !== 'mana') {
      availableShots = Math.floor(availableResources[spell.resourceCost.resource] / spell.resourceCost.cost)
    }
    let spellName
    if (!spell.name) {
      spellName = 'None'
    }
    else {
      spellName = spell.name
    }

    let rarityDetails = rarityInfo[spell.rarity]

    let manaColor

    let spellType = spell.type
    if (spellType == 'multiStatic') { spellType = 'AoE' }

    if (spell.mana) {
      manaColor = manaColorTable[spell.mana]
    }
    else { manaColor = 'white' }

    let baseCooldownText = ''
    let spellCooldown
    let baseSpeedText

    let spellDamage = spell.damage === 0 ? null :  spell.damage
    let baseDamageText

    let spellSpeed = spell.spellSpeed

    let damageColor = 'black'
    let speedColor = 'black'
    let cooldownColor = 'black'
    //
    if (spell.spellCooldown != spell.baseSpellCooldown) {
      baseCooldownText = `(${spell.baseSpellCooldown})`
      cooldownColor = 'blue'
    }
    if (spell.speed != spell.baseSpellSpeed) {
        baseSpeedText = `(${spell.baseSpellSpeed})`
        speedColor = 'blue'
    }
    if (spell.damage != spell.baseDamage) {
        let baseDamage = spell.baseDamage
        if (baseDamage < 0) { baseDamage *= -1 }

        baseDamageText = `(${baseDamage})`
        damageColor = 'blue'
    }

    if (spellDamage < 0) {
      spellDamage *= -1
    }
    if (spellDamage) { spellDamage = spellDamage.toFixed(2) }

    const cardStyle =  {
      position: 'relative', height: '100%', maxHeight: '100%', width: '100%', pointerEvents: 'auto', userSelect: 'none', backgroundColor: backgroundColor, textAlign: 'center', fontWeight: 'bold', marginTop: '5%',
      borderRadius: '8px',
      boxShadow: '0 0 0 1px rgba(255,255,255, 0.25), 0 0 0 2px #000000',
    }
    if (spellPointerActive) {
      cardStyle.zIndex = 1000
    }

    let divBackground = (gameFocusOverlay && !spellPointerActive) ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.0)'
    
    if (spell.imgPath) {
      return (
        <div style={{ position: 'relative', display: 'inline-block', height: '12vh', width: '98%', zIndex: 1000 }}>
          {spellPointerActive &&
        <div className="floating-pointer">
          <img src={'assets/ui/cursor_hover-o.png'} alt="Hover pointer" />
        </div>
        }
        <Card
          onMouseEnter={this.togglePopoverOpen}
          onMouseLeave={this.togglePopoverClose}
          onClick={() => { 
            fire(phaserObject, player,assignedKey, equippedSpells)
          }
          }

          style={
           cardStyle
          }>
                    <div style={{ backgroundColor: divBackground, position: 'absolute', width: '100%', height: '100%', zIndex: '10' }}></div>

          <div style={{ height: '20%', fontSize: '2vh' }}>
            {/*{spell.assignedKey}*/}
            {label}
          </div>
          <img
            style={{
              backgroundColor: rarityDetails.rarityColor, textAlign: 'right',
              height: '65%',
              maxHeight: '65%',
            objectFit: 'scale-down',
            position: 'relative',

              outline: `2px solid ${rarityDetails.innerBorder}`,
              zIndex: '1',
              width: '100%',
            }}
            src={`assets/icons/spell-icons-with-color/${spell.imgPath}`}
          />

          <div style={{
            top: '20%',
            left: '70%',
            position: 'absolute',
            transform: 'translateX(-50%)',
            zIndex: 5,
          }}>
            <div style={{
              textAlign: 'center', color: 'white', fontWeight: 'bold', width: '100%',
              fontSize: '20px',
              textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
            }}> {spell.type != 'melee' ? availableShots : null} </div>
          </div>

          <Popover
            // https://gitmemory.com/issue/mui-org/material-ui/7680/512825747
            style={{
              pointerEvents: 'none',
              background: 'rgb(0,0,0,0)',
            }}
            open={popoverOpen}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            anchorEl={anchorEl}
          >
            <Card style={{
              userSelect: 'none', textAlign: 'center', backgroundColor: rarityDetails.rarityColor,
              margin: '2px',
              borderRadius: '2px',
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: 'rgba(0,0,0,0.25)',
              boxShadow: '0 0 0 2px #362D44',
            }}>
              <Grid container xs={12} style={{
              }}>
                <Grid xs={12}>
                  <h4 style={{ textAlign: 'center', marginBottom: 3, marginTop: 1 }}><span style={{ color: rarityDetails.textColor }} >
                    {rarityDetails.pickupRarity} {spellType} </span>  </h4>
                  <h3 style={{
                    textAlign: 'center', marginBottom: 3, marginTop: 3, fontWeight: 'bold',
                    color: manaColor
                  }}>  {spell.name} </h3>
                  {spell.effects.length > 0 ? <h4 style={{ textAlign: 'center', marginBottom: 0, marginTop: 0.1 }}> {spell.effects} effect </h4> : null}
                </Grid>
                <Grid xs={12} style={{
                }}>
                  <Paper style={{
                    background: 'rgb(255,255,255, 0.3)',
                    borderTop: '2px solid black',
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                    height: '100%', color: 'black'
                  }}>
                    <p style={{ color: 'black', opacity: '100%' }}>
                      {spellDamage &&
                        <div style={{ textAlign: 'center', marginBottom: 0, marginTop: 0.1 }}> {spell.damage >= 0 ? <span><strong>Damage:</strong></span> : <span><strong>Heal:</strong></span>}
                          <span style={{ color: damageColor }}>  {spellDamage}  </span>   {baseDamageText}
                        </div>
                      }
                      {spell.selfDamage ? <div> <strong>Self Damage: </strong>{spell.selfDamage}</div> : null}
                      {spell.speed ? <div> <strong>Speed: </strong> <span style={{ color: speedColor }}>  {spell.speed.toFixed(2)} </span>   {baseSpeedText}   </div> : null}
                      {spell.damageMultiplier ? <div> <strong>Damage Multiplier (Melee):</strong> {spell.damageMultiplier} </div> : null}
                      {spell.sprintTicks && spell.sprintTicks > 1 ? <div> <strong>Duration: </strong>{Math.round((spell.sprintTicks / 60) * 10 * 3) / 10}</div> : null}
                      {spell.speedBoost && spell.speedBoost < 1000 ? <div> <strong> Speed Boost:</strong> {spell.speedBoost} </div> : null}
                      <div><strong>Cooldown: </strong><span style={{ color: cooldownColor }}>{spell.spellCooldown.toFixed(2)}s </span>{baseCooldownText} </div>
                      {spell.manaCost ? <div><strong>Mana Cost:</strong> {spell.manaCost} </div> : null}
                    </p>
                  </Paper>
                </Grid>
              </Grid>
            </Card>
          </Popover>
          <StyledLinearProgress variant="determinate" value={this.state.timer} style={{
            width: '100%', height: '15%',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            top: '-2px'
          }}
          />
        </Card>
        </div>  
      )
    }
    else {
      return (
        <div style={{ position: 'relative', display: 'inline-block', height: '12vh', width: '98%' }}>
        <Card
          style={{
            userSelect: 'none', height: '100%', maxHeight: '100%', backgroundColor: backgroundColor, textAlign: 'center', fontWeight: 'bold', marginTop: '5%',
            position: 'relative', height: '100%', maxHeight: '100%', width: '100%',
            //  pointerEvents: 'auto',
            // pointerEvents: 'none',
              userSelect: 'none',
            textAlign: 'center', fontWeight: 'bold', marginTop: '5%',
            borderRadius: '8px',
            boxShadow: '0 0 0 1px rgba(255,255,255, 0.25), 0 0 0 2px #000000',
          }}>
            <div style={{ backgroundColor: divBackground, position: 'absolute', width: '100%', height: '100%', zIndex: '10' }}></div>

          <div style={{ height: '20%', opacity: '80%',  fontSize: '2vh'}}>
            {assignedKey}
          </div>
          <img
            style={{
              backgroundColor: 'black', textAlign: 'right', height: '65%', maxHeight: '65%',
              objectFit: 'fill',

              outline: '2px solid rgb(79,79,79,0.8)',
              zIndex: '1',
              width: '100%',
            }}
            src={'assets/icons/spell-icons-with-color/empty-icon-3.png'}
          />
          <LinearProgress variant="determinate" value={this.state.timer} style={{ width: '100%', height: '15%', top: '-2px' }} />
        </Card>
        </div>
      )
    }
  }
}

export default SpellBox