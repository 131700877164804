import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import { IconButton } from '@mui/material'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import Constants from '../../shared/constants'
import {bigScreenBorderProperties, colorPalette, mainBorderProperties} from './uiStyles.js'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import ColorChangingButton from './ColorChangingButton.js'
import LinearProgress from '@mui/material/LinearProgress'
import {updatedReferrer} from '../sdkIntegration'
import {domainName} from "../index";
import {styled} from "@mui/material/styles";
import { baseUrl } from '../index'

function handleLoginWindow(url) {
    let referrer = document.referrer
    if (typeof referrer == 'string' && (referrer.includes('localhost') || referrer.includes(domainName))) {
        referrer = ''
    }
    if (updatedReferrer.value) {
        referrer = updatedReferrer.value
    }

    try {
        // referrer = 'crazygames'
        if (typeof referrer == 'string' && referrer != '') {
            console.log('setting refferer storage', referrer)
            let dictToSave = JSON.stringify({
                referrer: referrer,
                date: new Date()
            })
            localStorage.setItem('referrer',
                dictToSave
            )
        } else {
            console.log('not setting ref storage', referrer)
        }
    } catch (err) { 
        console.log('crashed loc storage', err)
    }

    console.log('opening login window', referrer)

    if (referrer == '') {
        console.log('Opening window no referrer:', url)
        window.open(url, '_self')
    } else {
        console.log('Opening window with referrer:', url)
        let popup = window.open(url, 'popUpWindow', 'height=600, width=500, left=100, top=100, resizable=yes')
        let interval = window.setInterval(function () {
            try {
                // console.log('popup', popup)
                if (popup == null || popup.closed) {
                    window.clearInterval(interval)
                    location.reload()
                }
            }
            catch (e) {
            }
        }, 100)
    }
}

const StyledLinearProgress = styled(LinearProgress)({
    backgroundColor: '#362D44', // Color for the background
    '& .MuiLinearProgress-bar': {
        backgroundColor: '#0A83D4', // Color for the bar
    },
});

class Profile extends Component {
    constructor() {
        super()
        this.state = {
            dialog: false
        }
    }

    componentDidMount() {


    }
    secondsToDhms(seconds) {
        seconds = Number(seconds)
        var d = Math.floor(seconds / (3600 * 24))
        var h = Math.floor(seconds % (3600 * 24) / 3600)
        var m = Math.floor(seconds % 3600 / 60)
        var s = Math.floor(seconds % 60)

        var dDisplay = d > 0 ? d + (d == 1 ? ' day, ' : ' days, ') : ''
        var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : ''
        var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : ''
        var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : ''
        return dDisplay + hDisplay + mDisplay + sDisplay

    }

    parseTime(seconds) {
        // let timeType
        if (seconds < 60) {
            // if (seconds == 1) { timeType = 'second' } else { timeType = 'seconds'}
            return { parsedTimeType: 's', parsedTimeAmount: seconds }
        }
        else if (seconds / 60 < 60) {
            // if (math.floor(seconds / 60 == 1)) { timeType = 'minute' } else { timeType = 'minutes'}
            return { parsedTimeType: 'm', parsedTimeAmount: Math.floor(seconds / 60) }
        }
        else {
            // if (Math.floor((seconds / 60) / 60 == 1)) { timeType = 'hour' } else { timeType = 'hours' }
            return { parsedTimeType: 'h', parsedTimeAmount: Math.floor((seconds / 60) / 60) }
        }
    }

    render() {
        let { authenticated, accountStats, accountUsername, guestAccount, accountLevel, accountExpRatio, height, referrer } = this.props
        let { bossKills, miniBossKills, creatureKills, chestKills, playerKills, totalDeaths, totalScore, timePlayed } = accountStats
        const handleClickOpen = () => { this.setState({ 'dialog': true }) }
        const handleClose = () => { this.setState({ 'dialog': false }) }

        // console.log("PROFILE ACC EXP RATIO", accountExpRatio)

        let buttonColor
        let textColor
        let buttonConfig

        let titlePadding
        let fontSize
        if (height < 560) {
            // fontSize = '12px'
            titlePadding = '5px'
        } else if (height < 630) {
            // fontSize = '14px'
            titlePadding = '5px'
        } else {
            // fontSize = '16px'
            titlePadding = '16px'
        }


        if (guestAccount) {
            textColor = 'white'
            buttonColor = '#7c4e4d'

            buttonConfig = {
                idle: {
                    fillColor: '#7C4E4D',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#543131',
                },
                hover: {
                    fillColor: '#CC7A82',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#AA5561',
                },
                down: {
                    fillColor: '#CC7A82',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#AA5561',
                }

            }
        }
        else {
            textColor = 'black'
            buttonColor = '#f7a74f'
            buttonConfig = {
                idle: {
                    fillColor: '#F7A74F',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#CE782C',
                },
                hover: {
                    fillColor: '#FFD16C',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFB66E',
                },
                down: {
                    fillColor: '#F79B37',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#E08634',
                },
            }
        }




        // let parsedTimePlayed = this.secondsToDhms(timePlayed)

        let { parsedTimeType, parsedTimeAmount } = this.parseTime(timePlayed)
        let { dialog } = this.state

        // let statsTable = [
        //     { name: 'bossKills', value: bossKills },
        //     { name: 'miniBossKills', value: miniBossKills },
        //     { name: 'playerKills', value: playerKills },
        //     { name: 'bossKills', value: bossKills },
        //     { name: 'bossKills', value: bossKills },
        //     { name: 'bossKills', value: bossKills },
        //     { name: 'bossKills', value: bossKills },
        //     { name: 'bossKills', value: bossKills },
        //     { name: 'bossKills', value: bossKills },
        // ]
        // let statsGrid = statsTable.map((stat) => {
        //     return
        //        <Grid container justify="flex-end" xs={4} >
        //             <b>Boss Kills </b>
        //         </Grid>
        //         <Grid container justify="center" xs={2} >
        //             <b>{bossKills} </b>
        //         </Grid>
        //         })

        let gridStyle = { marginBottom: '5px' }
        return <>
            <Grid container xs={12}
                tyle={{
                    position: 'relative',
                    maxWidth: '250px',
                }}>
                <Grid container xs={12}
                    style={{
                        marginTop: '10px',
                        marginBottom: '5px',
                        maxWidth: '250px',
                    }}
                >
                    <Grid container xs={2}>
                        <div style={{
                            borderWidth: '3px', borderStyle: 'solid', borderColor: 'black',
                            borderRadius: '6px',
                            background: colorPalette.primary,
                            // maxWidth: '76px',
                            // minWidth: '76px',
                            maxWidth: '50px',
                            minWidth: '50px',
                            zIndex: 10,
                            position: 'relative',
                            marginTop: '-5px',
                            marginBottom: '-5px',
                        }}>
                            <b style={{
                                //   fontSize: '20px',
                                fontSize: '2vh',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',

                                transform: 'translate(-50%, -50%)',
                            }}
                            >
                                {accountLevel}
                            </b>
                        </div>
                    </Grid>
                    <Grid container xs={10}>
                        <ColorChangingButton buttonFunction={handleClickOpen}
                            config={buttonConfig}
                            title={accountUsername}
                            buttonStyling={{
                                width: '100%',
                                // maxWidth: '374px',
                                // minWidth: '374px',
                                maxWidth: '250px',
                                minWidth: '250px',
                                height: '30px'
                            }}
                            textColor='black'
                        />
                    </Grid>

                </Grid>
                <Grid xs={12}>
                    <StyledLinearProgress
                        color="primary"
                        style={{
                            // minWidth: '414px',
                            // maxWidth: '414px',
                            minWidth: '285px',
                            maxWidth: '285px',
                            marginTop: '6px',
                            // height: '12px',
                            height: '8px',
                            borderRadius: '3px',
                            borderWidth: '2px', borderStyle: 'solid',
                            borderColor: '#3d4578',
                            boxShadow: '0 0 0 2px #000000',
                        }} variant="determinate"
                        value={accountExpRatio * 100}
                    />
                </Grid>
            </Grid>

            <Dialog
                // maxWidth={false}
                fullWidth={true}

                style={{
                    padding: '10px',
                    width: '100%'

                }}
                onClose={handleClose} aria-labelledby="simple-dialog-title" open={dialog}>

                <IconButton
                    style={{
                        alignSelf: 'flex-end',
                        marginTop: -5,
                        position: 'absolute'
                    }}
                    onClick={handleClose}
                    size="large">
                    <CancelPresentationIcon style={{ fontSize: '3rem' }} />
                    {/* <HighlightOffIcon style={{ fontSize: '3rem' }} /> */}
                </IconButton>
                <Paper style={Object.assign({
                    background: colorPalette.primary,
                    padding: '2%',
                    // minWidth: '500px',
                    // width: '80%'
                    // minWidth: '400px'
                    borderColor: colorPalette.primaryBorder,
                }, bigScreenBorderProperties)}>

                    <DialogTitle
                        style=
                        {Object.assign({
                            textAlign: 'center',
                            // marginLeft: '5%',
                            // marginRight: '5%'

                        },
                            // mainBorderProperties
                        )}
                        id="simple-dialog-title"> <b> PROFILE </b></DialogTitle>
                    <DialogTitle
                        style=
                        {Object.assign({
                            textAlign: 'center',
                            background: colorPalette.secondary,
                            padding: titlePadding
                        },
                            mainBorderProperties
                        )}
                        id="simple-dialog-title"> <b> {accountUsername} </b></DialogTitle>
                    <Grid container justifyContent="center"
                        style={{ paddingTop: '1%' }}
                        alignItems="center" xs={12}>
                        <Paper style={Object.assign({
                            background: '#e7cab2',
                            marginTop: '2%',

                            padding: '1%',
                            width: '100%',
                            borderColor: colorPalette.primaryBorder,
                            // width: '50%'
                        }, bigScreenBorderProperties)}>
                            <Grid container 
                                justifyContent="center"
                                alignItems="center"
                                style={{
                                    borderBottom: '5px', borderColor: 'black', borderWidth: '2px',
                                }}
                                xs={12}>
                                <b style={{
                                    // borderBottom: '5px', borderColor: 'black', borderWidth: '2px',
                                    paddingBottom: '1%'

                                }}> Stats</b>
                            </Grid>
                            <TableContainer style={{
                                mindWidth: '200px'
                            }}>
                                <Table
                                // style={{padding: '50px'}}
                                >
                                    {/* <TableHead>
                                    <TableCell style={{ borderBottom: "none" }}rowSpan={2} align='center'>
                                        <b> Stats </b>
                        </TableCell>
                                </TableHead> */}
                                    <TableBody
                                        style={{ padding: '50px' }}
                                    >
                                        <TableRow style={{
                                            background: '#efd3bd'
                                        }}>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='right'>
                                                Boss Kills
                                            </TableCell>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='center'>
                                                <b>{bossKills} </b>
                                            </TableCell>
                                        </TableRow >
                                        <TableRow style={{ background: '#d8bca7' }}>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='right'>
                                                Mini Boss Kills
                                            </TableCell>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='center'>
                                                <b>{miniBossKills}</b>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow style={{
                                            background: '#efd3bd'
                                        }}>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='right'>
                                                Player Kills
                                            </TableCell>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='center'>
                                                <b>{playerKills} </b>
                                            </TableCell>
                                        </TableRow >
                                        <TableRow style={{ background: '#d8bca7' }}>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='right'>
                                                Total Deaths
                                            </TableCell>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='center'>
                                                <b>{totalDeaths} </b>
                                            </TableCell>
                                        </TableRow> <TableRow style={{
                                            background: '#efd3bd'
                                        }}>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='right'>
                                                Creature Kills
                                            </TableCell>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='center'>
                                                <b>{creatureKills}</b>
                                            </TableCell>
                                        </TableRow >
                                        <TableRow style={{ background: '#d8bca7' }}>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='right'>
                                                Chests Opened
                                            </TableCell>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='center'>
                                                <b>{chestKills} </b>
                                            </TableCell>
                                        </TableRow> <TableRow style={{
                                            background: '#efd3bd'
                                        }}>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='right'>
                                                Total Score
                                            </TableCell>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='center'>
                                                <b>{totalScore}</b>
                                            </TableCell>
                                        </TableRow >
                                        <TableRow style={{ background: '#d8bca7' }}>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='right'>
                                                Time Played
                                            </TableCell>
                                            <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='center'>
                                                <b>{parsedTimeAmount}{parsedTimeType} </b>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>

                                </Table>

                            </TableContainer>
                            <Grid container justifyContent="center"
                                alignItems="center"
                                style={{
                                    width: '100%',
                                    // padding: '10px'
                                }}>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid container justifyContent="center"
                        alignItems="center" xs={12}>
                        {!guestAccount && referrer !== 'crazygames' &&
                            <Grid>
                                <Button style={{
                                    minWidth: '200px', marginTop: '10px', background: '#ef6d5b',
                                    borderRadius: '6px',
                                    borderWidth: '2px', borderStyle: 'solid',
                                    // boxShadow: '0 0 0 2px #362D44',
                                    boxShadow: 'none'
                                }} variant="contained" onClick={
                                    () => {
                                        console.log('Logging out:', baseUrl + '/auth/logout')
                                        handleLoginWindow(baseUrl + '/auth/logout')
                                    }}> <b> Logout </b></Button>


                                {/* <ColorChangingButton buttonFunction={() => { window.open(baseUrl + '/auth/logout', "_self") }}
                                config={
                                    {
                                        idle: {
                                            fillColor: '#FF6155',
                                            outerBorderColor: '#C79B7B',
                                            innerBorderColor: '#AD4B40',
                                        },
                                        hover: {
                                            fillColor: '#FF647E',
                                            outerBorderColor: '#C79B7B',
                                            innerBorderColor: '#DB427D',
                                        },
                                        down: {
                                            fillColor: '#DD3350',
                                            outerBorderColor: '#C79B7B',
                                            innerBorderColor: '#871D3B',
                                        },
                                        // down: {
                                        //     fillColor: '#8E4D56',
                                        //     outerBorderColor: '#362D44',
                                        //     innerBorderColor: '#5B363E',
                                        // }
                                    }}
                                title='Logout'
                                buttonStyling={{
                                    minWidth: '200px', marginTop: '10px', background: '#ef6d5b',
                                }}
                            /> */}
                            </Grid>}

                    </Grid>
                </Paper>
                {/* {!guestAccount &&
                    <Grid>
                        <Button style={Object.assign({
                            minWidth: '200px', marginTop: '10px', background: '#ef6d5b',
                        }, mainBorderProperties)} variant="contained" onClick={() => { window.open(baseUrl + '/auth/logout', "_self") }}> <b> Logout </b></Button>
                    </Grid>
                } */}

            </Dialog>

        </>;
    }
}


function mapStateToProps(state) {
    return {
        authenticated: state.stuff.authenticated,
        accountStats: state.stuff.accountStats,
        accountUsername: state.stuff.accountUsername,
        guestAccount: state.stuff.guestAccount,
        accountLevel: state.stuff.accountLevel,
        accountExpRatio: state.stuff.accountExpRatio,
        height: state.stuff.height,
        referrer: state.stuff.referrer
    }
}

export default connect(
    mapStateToProps,
)(Profile)