import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import Button from '@mui/material/Button'
import * as Actions from '../../client/actions/actions.js'
import Dialog from '@mui/material/Dialog'
import { IconButton } from '@mui/material'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import { ChestsData } from '../../shared/chests'
import Constants from '../../shared/constants'
import {bigScreenBorderProperties, colorPalette} from './uiStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import CosmeticItem from './CosmeticItem.js'
import ItemReward from './ItemReward.js'
import ItemRewardFull from './ItemRewardFull.js'
import {CosmeticsData} from "../../shared/cosmetics/cosmetics";
import ColorTable from "../../shared/colorTable";
import {baseUrl} from "../index";

function findRewardData(rewardEarned) {
    let reward
    let rewardImgPath
    let itemMessage

    if (rewardEarned.type == 'head') {
        reward = CosmeticsData.heads.find(head => head.id == rewardEarned.id)
        rewardImgPath = `assets/player/heads/${reward.imgPath}`
        itemMessage = `${reward.rarity} Skin`
        // Actions.updateSelectedHead(rewardEarned.id)
    }
    else if (rewardEarned.type == 'emote') {
        reward = CosmeticsData.emotes.find(emote => emote.id == rewardEarned.id)
        rewardImgPath = `assets/emotes/${reward.imgPath}`
        itemMessage = `${reward.rarity} Emote`
    }
    else if (rewardEarned.type == 'coins' || rewardEarned.type == 'runes') {
        reward = { rarity: 'rare', quantity: rewardEarned.quantity, type: 'coins' }
        rewardImgPath = 'assets/ui/rune.png'
        itemMessage = `${reward.quantity} Runes`
    }
    else if (rewardEarned.type == 'gems' || rewardEarned.type == 'glyphs') {
        reward = { rarity: 'legendary', quantity: rewardEarned.quantity, type: 'gems' }
        rewardImgPath = 'assets/ui/glyph.png'
        itemMessage = `${reward.quantity} Glyphs`
    }

    return {
        reward: reward,
        rewardImgPath: rewardImgPath,
        itemMessage: itemMessage
    }
}

class ChestPopup extends Component {
    constructor() {
        super()
        this.state = {
            dialog: false,

            dialogNewItem: false,
            itemEarnedPath: 'assets/emotes/smile.png',
            itemEarned: {
                id: 1,
                name: 'Thumbs Up',
                imgPath: 'thumb_up.png',
                rarity: 'common',
                unlockRequirement: null,
                free: true,
            },
            itemEarnedMessage: 'Legendary Emote',

            showBuyButton: true,

            queuingFinished: true,
            rewardQueue: [],
            fullRewardQueue: [],
            fulLRewardActivated: true,
            // showLoadoutOnItem: false
        }
    }

    mapItems(items) {
        return items.map((item) => {
            return <Grid xs={3}
                style={{
                    marginBottom: '3%',
                    marginTop: '3%',
                }}
            >
                <CosmeticItem
                    borderColor={item.borderColor}
                    // locked={true}
                    locked={false}
                    imgPath={item.fullImgPath}
                    topOffset={item.topOffset}
                    imgWidth={item.imgWidth}
                    acquired={item.acquired}
                />
            </Grid>
        })
    }

    componentDidMount() {


    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dialogAction != this.props.dialogAction) {
            this.setState({ dialog: true })
        }
    }

    render() {

        const handleClickOpen = () => { this.setState({ 'dialog': true }) }
        const handleClose = () => { this.setState({ 'dialog': false }) }

        const handleCloseNewItem = () => {

            let { rewardQueue, fullRewardQueue, fullRewardActivated } = this.state

            this.setState({ 'dialogNewItem': false })


            if (rewardQueue.length > 0) {
                let rewardEarned = rewardQueue.shift(1)
                let rewardData = findRewardData(rewardEarned)
                let queuingFinished = false
                // if (rewardQueue.length == 0) { queuingFinished = true }
                // console.log("SETTING QUEUING FINISHED", [queuingFinished, fullRewardActivated])

                setTimeout(
                    () => {
                        this.setState({
                            itemEarnedPath: rewardData.rewardImgPath,
                            itemEarned: rewardData.reward,
                            itemEarnedMessage: rewardData.itemMessage,
                            dialogNewItem: true,
                            rewardQueue: rewardQueue,
                            queuingFinsihed: queuingFinished
                        })
                    },
                    500

                )
            } else if (rewardQueue.length == 0 && fullRewardQueue.length > 0) {
                if (!fullRewardActivated) {
                    let openFullQueue = true
                    if (fullRewardQueue.length == 1) {
                        openFullQueue = false
                    }
                    setTimeout(
                    () => {
                        this.setState({
                            queuingFinished: true,
                            dialogNewItem: openFullQueue,
                            fullRewardActivated: true
                        })
                    },
                        500)
                }

            }
        }

            let { dialog, dialogNewItem, itemEarnedPath, itemEarned, itemEarnedMessage, showBuyButton, fullRewardQueue, queuingFinished } = this.state
            let { ChestName, ImgSource, ChestId, token, guestAccount, accountItems } = this.props
        
            let chosenChest = ChestsData.find(chest => chest.id == ChestId)

            let chestItems = []


            chosenChest.items.emotes.forEach(item => {
                let itemData = CosmeticsData.emotes.find(emote => emote.id == item)
                itemData.fullImgPath = `assets/emotes/${itemData.imgPath}`
                itemData.borderColor = ColorTable.rarityInfo[itemData.rarity].loadoutBorder
                itemData.topOffset = 0
                itemData.imgWidth = '70px'
                itemData.itemType = 'emote'
                if (accountItems.emotes.includes(item)) {itemData.acquired = true}
                chestItems.push(itemData)
            })
            chosenChest.items.heads.forEach(item => {
                let itemData = CosmeticsData.heads.find(head => head.id == item)
                itemData.fullImgPath = `assets/player/heads/${itemData.imgPath}`
                itemData.borderColor = ColorTable.rarityInfo[itemData.rarity].loadoutBorder
                itemData.topOffset = '-10px'
                itemData.imgWidth = '88px'
                itemData.itemType = 'head'
                if (accountItems.heads.includes(item)) { itemData.acquired = true }
                chestItems.push(itemData)
            })



            let commonItems = chestItems.filter(item => item.rarity == 'common' && !item.event)
            let uncommonItems = chestItems.filter(item => item.rarity == 'uncommon' && !item.event)
            let rareItems = chestItems.filter(item => item.rarity == 'rare' && !item.event)
            let epicItems = chestItems.filter(item => item.rarity == 'epic' && !item.event)
            let legendaryItems = chestItems.filter(item => item.rarity == 'legendary' && !item.event)

            let eventSkins = chestItems.filter(item => item.event == 'halloween' && item.itemType == 'head')
            let eventEmotes = chestItems.filter(item => item.event == 'halloween' && item.itemType == 'emote')

            // let itemGrid = chestItems.map((item) => {
            //     return <Grid xs={3}
            //         style={{
            //             marginBottom: '3%',
            //             marginTop: '3%',
            //         }}
            //     >
            //         <CosmeticItem
            //             borderColor={item.borderColor}
            //             // locked={true}
            //             locked={false}
            //             imgPath={item.fullImgPath}
            //             topOffset={item.topOffset}
            //             imgWidth={item.imgWidth}
            //         />
            //     </Grid>


            // })
            let mappedItems = {
                common: this.mapItems(commonItems),
                uncommon: this.mapItems(uncommonItems),
                rare: this.mapItems(rareItems),
                epic: this.mapItems(epicItems),
                legendary: this.mapItems(legendaryItems),
                eventSkins: this.mapItems(eventSkins),
                eventEmotes: this.mapItems(eventEmotes)
            }

            let currencyPath
            if (chosenChest.currencyType == 'coins') {
                currencyPath = 'assets/ui/rune.png'
            } else if (chosenChest.currencyType == 'gems') {
                currencyPath = 'assets/ui/glyph.png'
            }


            let buttonConfig = {
                idle: {
                    fillColor: '#7C4E4D',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#543131',
                },
                hover: {
                    fillColor: '#CC7A82',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#AA5561',
                },
                down: {
                    fillColor: '#CC7A82',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#AA5561',
                },
            }
            let gridStyle = { marginBottom: '5px' }
            return <>
                <Dialog
                    // maxWidth={false}
                    fullWidth={true}
                    maxWidth={'md'}
                    style={{
                        padding: '10px',
                        width: '100%',
                        zIndex: 100001

                    }}
                    onClose={handleClose} aria-labelledby="simple-dialog-title" open={dialog}>

                    <IconButton
                        style={{
                            alignSelf: 'flex-end',
                            marginTop: -5,
                            position: 'absolute'
                        }}
                        onClick={handleClose}
                        size="large">
                        <CancelPresentationIcon style={{ fontSize: '3rem' }} />
                        {/* <HighlightOffIcon style={{ fontSize: '3rem' }} /> */}
                    </IconButton>
                    <Paper style={Object.assign({
                        background: colorPalette.primary,
                        padding: '2%',
                        borderColor: colorPalette.primaryBorder,
                    }, bigScreenBorderProperties)}>
                        <Grid container align='center' justifyContent="center" alignItems="center" direction="row" xs={12}>
                            <Grid
                                container
                                align='center' justifyContent="center" alignItems="center" direction="row"
                                xs={12} style={{
                                    paddingBottom: '2px',
                                    padding: '13px'
                                }}>
                                <b style={{ fontSize: '22px' }}>{ChestName}</b>
                            </Grid>
                            <Grid container align='center' justifyContent="center" alignItems="center" direction="row" xs={4}>
                                <Grid container align='center' justifyContent="center" alignItems="center" direction="row" xs={12}>
                                    <img
                                        style={{
                                            pointerEvents: 'none',
                                        }}
                                        src={ImgSource}
                                    />
                                </Grid>

                                <Grid xs={12}>
                                    <Grid container justifyContent="center"
                                        alignItems="center"
                                        style={{
                                            borderBottom: '5px', borderColor: 'black', borderWidth: '2px',
                                        }}
                                        xs={12}>
                                        <b style={{
                                            // borderBottom: '5px', borderColor: 'black', borderWidth: '2px',
                                            paddingBottom: '1%'

                                        }}> Chances </b>
                                    </Grid>
                                    <TableContainer style={{
                                        minWidth: '200px'
                                    }}>
                                        <Table
                                        >
                                            <TableBody
                                                style={{ padding: '50px' }}
                                            >
                                                {chosenChest.odds.eventSkins != 0 &&
                                                    <TableRow style={{
                                                        background:
                                                            '#F47C40'
                                                    }}>


                                                        <TableCell style={{
                                                            width: '50%', borderBottom: 'none', padding: '2px',

                                                        }} align='right'>
                                                            <b> Legendary Spooky Skin </b>
                                                        </TableCell>
                                                        <TableCell style={{ width: '40%', borderBottom: 'none', padding: '2px' }} align='center'>
                                                            <b>{chosenChest.odds.eventSkins * 100}%</b>
                                                        </TableCell>
                                                    </TableRow>
                                                }

                                                {chosenChest.odds.eventEmotes != 0 &&
                                                    <TableRow style={{ background: '#F47C40' }}>
                                                        <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='right'>
                                                            <b> Spooky Emote </b>
                                                        </TableCell>
                                                        <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='center'>
                                                            <b>{chosenChest.odds.eventEmotes * 100}%</b>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                                <TableRow style={{
                                                    background: '#70767F'
                                                }}>
                                                    <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='right'>
                                                        <b>  Common </b>
                                                    </TableCell>
                                                    <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='center'>
                                                        <b>{Math.round(chosenChest.odds.common * 100)}%</b>
                                                    </TableCell>
                                                </TableRow >

                                                <TableRow style={{ background: '#2FAD25' }}>
                                                    <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='right'>
                                                        <b>  Uncommon </b>
                                                    </TableCell>
                                                    <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='center'>
                                                        <b>{chosenChest.odds.uncommon * 100}%</b>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ background: '#1A82FF' }}>
                                                    <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='right'>
                                                        <b>   Rare </b>
                                                    </TableCell>
                                                    <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='center'>
                                                        <b>{chosenChest.odds.rare * 100}%</b>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ background: '#8948EF' }}>
                                                    <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='right'>
                                                        <b>  Epic </b>
                                                    </TableCell>
                                                    <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='center'>
                                                        <b>{chosenChest.odds.epic * 100}%</b>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ background: '#F47C40' }}>
                                                    <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='right'>
                                                        <b>  Legendary </b>
                                                    </TableCell>
                                                    <TableCell style={{ width: '50%', borderBottom: 'none', padding: '2px' }} align='center'>
                                                        <b>{chosenChest.odds.legendary * 100}%</b>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Grid container justifyContent="center"
                                        alignItems="center"
                                        style={{
                                            width: '100%',
                                            // padding: '10px'
                                        }}>
                                    </Grid>
                                </Grid>

                                <Grid xs={12}>
                                    Repeats give back runes and glyphs
                                </Grid>
                                <Grid xs={12}
                                    style={{ minHeight: '46px', maxHeight: '46px' }}
                                >
                                    {showBuyButton &&

                                        <Button style={{
                                            minWidth: '200px', marginTop: '10px', marginRight: '5px',
                                            // background: secondaryBackgroundColor,
                                            borderWidth: '2px', borderStyle: 'solid', borderColor: 'black',
                                            position: 'relative',
                                        }} variant="contained"



                                            onClick={() => {



                                                setTimeout(
                                                    () => { this.setState({ showBuyButton: true }) }, 2000
                                                )
                                                fetch(`${baseUrl}/account/buyChest`, {
                                                    method: 'POST',
                                                    credentials: 'include',
                                                    headers: {
                                                        Accept: 'application/json',
                                                        'Content-Type': 'application/json',
                                                        'Access-Control-Allow-Credentials': true
                                                    },
                                                    body: JSON.stringify({
                                                        token: token,
                                                        guest: guestAccount,
                                                        // itemId: clickedItem.id,
                                                        // itemType: clickedItem.type,
                                                        chestId: ChestId
                                                    })
                                                })
                                                    .then(response => {
                                                        if (response.status === 200) {
                                                            return response.json().then(user => {
                                                                let accountItems = user.accountItems

                                                                Actions.updateAccountItems(accountItems)
                                                                Actions.updateCoins(user.coins)
                                                                Actions.updateGems(user.gems)

                                                                let rewardQueue = JSON.parse(JSON.stringify(user.itemEarned))

                                                                let fullRewardQueue = []
                                                                rewardQueue.forEach(item => {
                                                                    fullRewardQueue.push(
                                                                        findRewardData(item)
                                                                    )
                                                                })

                                                                let rewardEarned = rewardQueue.shift(1)
                                                                let rewardData = findRewardData(rewardEarned)

                                                                

                                                                this.setState({
                                                                    itemEarnedPath: rewardData.rewardImgPath,
                                                                    itemEarned: rewardData.reward,
                                                                    itemEarnedMessage: rewardData.itemMessage,
                                                                    dialogNewItem: true,
                                                                    rewardQueue: rewardQueue,
                                                                    fullRewardQueue: fullRewardQueue,
                                                                    fullRewardActivated: false,
                                                                    queuingFinished: false
                                                                })
                                                            })
                                                        }
                                                    })


                                                this.setState({ showBuyButton: false })
                                            }

                                            }
                                        >
                                            <b
                                                style={{ position: 'relative' }}
                                            >
                                                Buy for {chosenChest.price}
                                                <img style={{
                                                    position: 'absolute',
                                                    width: '33px',
                                                    // left: '4.5%',
                                                    // right: '-63%',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    pointerEvents: 'none',
                                                    margin: 'auto',
                                                    zIndex: 10,
                                                }}
                                                    src={currencyPath}

                                                />
                                            </b>
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container xs={8}
                                style={{ paddingLeft: '3%', }}
                            >
                                <Grid container justifyContent="center"
                                    style={{ paddingTop: '1%' }}
                                    alignItems="center" xs={12}>
                                    <Paper style={Object.assign({
                                        background: '#e7cab2',
                                        marginTop: '2%',

                                        padding: '1%',
                                        width: '100%',
                                        borderColor: colorPalette.primaryBorder,
                                    }, bigScreenBorderProperties)}>

                                        <Grid container
                                            align='center' justifyContent="center" alignItems="center"
                                            xs={12}
                                            style={{
                                                maxHeight: '500px',
                                                overflow: 'scroll',
                                                overflowX: 'hidden',
                                            }}
                                        >
                                            {/* {itemGrid} */}
                                            {mappedItems.eventSkins.length > 0 && chosenChest.odds.eventSkins > 0 &&
                                                <>
                                                    <Grid container align='center' justifyContent="center" alignItems="center" xs={12}
                                                        style={{
                                                            fontSize: '18px',
                                                        }}
                                                    >
                                                        Spooky Skins
                                                    </Grid>
                                                    <Grid container xs={12}>
                                                        {mappedItems.eventSkins}
                                                    </Grid>
                                                </>
                                            }
                                            {mappedItems.eventEmotes.length > 0 && chosenChest.odds.eventEmotes > 0 &&
                                                <>
                                                    <Grid container align='center' justifyContent="center" alignItems="center" xs={12}
                                                        style={{ fontSize: '18px' }}
                                                    >
                                                        Spooky Emotes
                                                    </Grid>
                                                    <Grid container xs={12}>
                                                        {mappedItems.eventEmotes}
                                                    </Grid>
                                                </>
                                            }
                                            {mappedItems.common.length > 0 && chosenChest.odds.common > 0 &&
                                                <>
                                                    <Grid container lign='center' justifyContent="center" alignItems="center" xs={12}
                                                        style={{ fontSize: '18px' }}
                                                    >
                                                        Common
                                                    </Grid>
                                                    <Grid container xs={12}>
                                                        {mappedItems.common}
                                                    </Grid>
                                                </>
                                            }
                                            {mappedItems.uncommon.length > 0 && chosenChest.odds.uncommon > 0 &&
                                                <>
                                                    <Grid container align='center' justifyContent="center" alignItems="center" xs={12}
                                                        style={{ fontSize: '18px' }}
                                                    >
                                                        Uncommon
                                                    </Grid>
                                                    <Grid container xs={12}>
                                                        {mappedItems.uncommon}
                                                    </Grid>
                                                </>
                                            }
                                            {mappedItems.rare.length > 0 && chosenChest.odds.rare > 0 &&
                                                <>
                                                    <Grid container lign='center' justifyContent="center" alignItems="center" xs={12}
                                                        style={{ fontSize: '18px' }}
                                                    >
                                                        Rare
                                                    </Grid>
                                                    <Grid container xs={12}>
                                                        {mappedItems.rare}
                                                    </Grid>
                                                </>
                                            }
                                            {mappedItems.epic.length > 0 && chosenChest.odds.epic > 0 &&
                                                <>
                                                    <Grid container align='center' justifyContent="center" alignItems="center" xs={12}
                                                        style={{ fontSize: '18px' }}
                                                    >
                                                        Epic
                                                    </Grid>
                                                    <Grid container xs={12}>
                                                        {mappedItems.epic}
                                                    </Grid>
                                                </>
                                            }
                                            {mappedItems.legendary.length > 0 && chosenChest.odds.legendary > 0 &&
                                                <>
                                                    <Grid container align='center' justifyContent="center" alignItems="center" xs={12}
                                                        style={{ fontSize: '18px' }}
                                                    >
                                                        Legendary
                                                    </Grid>
                                                    <Grid container xs={12}>
                                                        {mappedItems.legendary}
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                </Dialog >

                {

                }
                {!queuingFinished &&
                    <ItemReward
                        dialogNewItem={dialogNewItem}
                        handleCloseNewItem={handleCloseNewItem}
                        itemEarned={itemEarned}
                        itemEarnedMessage={itemEarnedMessage}
                        itemEarnedPath={itemEarnedPath}
                        // hideLoadout={true}
                    hideLoadout={fullRewardQueue.length == 1 ? false : true}
                    />
                }
                {fullRewardQueue.length > 0 && queuingFinished &&
                    <ItemRewardFull
                        dialogNewItem={dialogNewItem}
                        handleCloseNewItem={handleCloseNewItem}
                        itemRewardList={fullRewardQueue}
                    />
                }

            </>;
        }
    }

export default(ChestPopup)