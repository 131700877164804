"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateUserData = exports.initiateLoginProcess = void 0;
const Actions = __importStar(require("./actions/actions"));
const _1 = require(".");
const sdkIntegration_js_1 = require("./sdkIntegration.js");
// Main function to initiate login process
function initiateLoginProcess(type, baseUrl, referrer) {
    return __awaiter(this, void 0, void 0, function* () {
        yield (0, sdkIntegration_js_1.runSDK)(referrer);
        console.log('initiating login process', type, baseUrl);
        let responseJson;
        try {
            if (type === 'oAuth') {
                let url = `${baseUrl}/auth/login/success`;
                responseJson = yield fetchData(url, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': true
                    }
                });
            }
            else if (type === 'crazygames') {
                let guestToken = null;
                try {
                    guestToken = localStorage.hasOwnProperty('guestToken') ? localStorage.getItem('guestToken') : null;
                }
                catch (_a) {
                    guestToken = null;
                }
                // @ts-ignore
                const cgToken = yield window.CrazyGames.SDK.user.getUserToken();
                let cgUrl = `${baseUrl}/auth/crazygames?token=${encodeURIComponent(guestToken)}&cgToken=${encodeURIComponent(cgToken)}`;
                // responseJson = await fetch(cgUrl);
                responseJson = yield fetchData(cgUrl, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': true
                    }
                });
            }
            handleWindowOperations();
            console.log('GOT RESPONSE', responseJson);
            if (responseJson.success) {
                console.log(' response is user acct');
                processSuccessfulLogin(responseJson);
            }
            else {
                processGuestLogin(baseUrl);
                // console.log(' response is guest acct')
                // Actions.updateGuestAccount(true);
                // let guestToken = null;
                // try {
                //   guestToken = localStorage.hasOwnProperty('guestToken') ? localStorage.getItem('guestToken') : null;
                // } catch {
                //   guestToken = null;
                // }
                // await handleGuestLogic(guestToken, baseUrl);
            }
        }
        catch (error) {
            console.log('FAILED TO AUTHENTICATE USER', error);
            // if (error && error.code === 'userNotAuthenticated') {
            processGuestLogin(baseUrl);
            // }
        }
    });
}
exports.initiateLoginProcess = initiateLoginProcess;
function processGuestLogin(baseUrl) {
    return __awaiter(this, void 0, void 0, function* () {
        console.log(' response is guest acct');
        Actions.updateGuestAccount(true);
        let guestToken = null;
        try {
            guestToken = localStorage.hasOwnProperty('guestToken') ? localStorage.getItem('guestToken') : null;
        }
        catch (_a) {
            guestToken = null;
        }
        yield handleGuestLogic(guestToken, baseUrl);
    });
}
// Modular function to fetch data
function fetchData(url, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(url, options);
        if (response.status === 200) {
            return response.json();
        }
        else {
            console.log('Response not 200', response);
            throw new Error('Failed to authenticate user');
        }
    });
}
// Function to handle window operations
function handleWindowOperations() {
    console.log('Credential window', {
        'windowOpener': window.opener,
        'menubarvisible': window.menubar.visible,
        'window': window,
    });
    if (window.opener && window.opener !== window && !window.menubar.visible) {
        window.close();
    }
    else {
        console.log("NOT CLOSING WINDOW");
    }
}
// Function to process successful logins
function processSuccessfulLogin(responseJson) {
    console.log('succesful login', responseJson);
    Actions.updateToken(responseJson.user.token);
    Actions.updateGuestAccount(false);
    // Actions.toggleAuthenticated(true);
    Actions.updateAccountUsername(responseJson.user.username);
    updateUserData(responseJson.user);
}
// Function to handle guest logic
function handleGuestLogic(guestToken, baseUrl) {
    return __awaiter(this, void 0, void 0, function* () {
        let guestUrl = `${baseUrl}/account/validateGuestToken`;
        const response = yield fetchData(guestUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true
            },
            body: JSON.stringify({ token: guestToken })
        });
        console.log('Got guest response', response);
        Actions.updateToken(response.token);
        // Actions.toggleAuthenticated(true);
        if (!guestToken || response.deleteGuest) {
            try {
                localStorage.setItem('guestToken', response.token);
            }
            catch (err) { }
        }
        _1.accountLevelG.value = response.accountLevel;
        _1.accountExpRatioG.value = response.accountExpRatio;
        updateUserData(response);
    });
}
function updateUserData(user) {
    console.log('updating user data', user);
    // let accountItems = JSON.parse(user.items)
    // let accountChallenges = JSON.parse(user.challenges)
    let accountItems = user.items;
    let accountChallenges = user.challenges;
    let accountLevel = user.accountLevel;
    let currentExp = user.currentExp;
    let expForNextLevel = _1.accountLevelUpDict[accountLevel];
    let accountExpRatio = currentExp / expForNextLevel;
    _1.accountLevelG.value = user.accountLevel;
    _1.accountExpRatioG.value = accountExpRatio;
    Actions.updateAccountEXP(accountLevel, currentExp, accountExpRatio);
    Actions.updatePrevAccountEXP(accountLevel, currentExp, accountExpRatio);
    // let dailyQuests
    // if (Array.isArray(user.dailyQuests)) {
    //   dailyQuests = user.dailyQuests
    // } else {
    //   dailyQuests = JSON.parse(user.dailyQuests)
    // }
    Actions.updateDailyQuests(user.dailyQuests);
    Actions.updateDailyQuestsTimeLeft(user.dailyQuestsTimeLeft);
    Actions.updateAccountItems(accountItems);
    Actions.updateAccountUsername(user.username);
    Actions.updateAccountId(user.id);
    Actions.updateAccountEmail(user.email);
    Actions.updateAccountStats(user.accountStats);
    Actions.updateCoins(user.coins);
    Actions.updateGems(user.gems);
    Actions.updateAccountKillStats(user.stats.creatureKills);
    Actions.updateAccountChallenges(accountChallenges);
    if (user.betaActive) {
        Actions.toggleBetaActive(true);
    }
    if (user.alphaActive) {
        Actions.toggleAlphaActive(true);
    }
    if (typeof user.rankId != undefined) {
        Actions.updateRank(user.rankId);
        Actions.updateRankScore(user.rankScore);
    }
}
exports.updateUserData = updateUserData;
;
