import React, { Component, forwardRef } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import Hidden from '@mui/material/Hidden'
import '../ui.css'
import { calculateScaleFactor } from '../../game'
import BannerAd from '../BannerAd'


class BottomBannerAd extends Component {
    constructor() {
        super()
        this.state = {}
    }
    componentDidMount() {
        console.log('mounting bottom banner', this.props.adName, this.props.fake)
    }
    componentWillUnmount() {
        console.log('unmounting bottom banner', this.props.adName, this.props.fake)
    }
    render() {
        let  {adName, width, fake } = this.props
        let scaleFactor = 1
        let mainMenuScaleFactor = 1
        scaleFactor = calculateScaleFactor(window.innerWidth, window.innerHeight)
        scaleFactor += 0.13
        mainMenuScaleFactor = scaleFactor
        if (width < 600) {
            mainMenuScaleFactor += 0.2
        }
        // let bannerWidth = small ? 300 : 728
        // let bannerHeight = small ? 250 : 90
        mainMenuScaleFactor = Math.min(1, mainMenuScaleFactor)

        let styling = {
            width: '100%', height: '100%',
              marginTop: '0.3%',
              zIndex: 999,
        }
        if (typeof window.CrazyGames === 'undefined') {
                      styling.transform = `scale(${mainMenuScaleFactor})`
                      styling.transformOrigin = 'top center'
        }
        return (
            <Grid item align='center' justifyContent="center" alignItems="center" direction="column" xs={12}
                  style={styling}>
                {fake && <div style={{width: '728px', height: '250px'}}></div> }
                {!fake &&
                    <>
                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <BannerAd size='small' adId='mageclash-io_300x250_2'  width={320} height={100} dimensions='300x250' bannerWidth='728px' bannerHeight='90px' adIdCrazyGames={adName} />
                    </Hidden>
                    <Hidden only={['xs']}>
                        <BannerAd size='large' adId='mageclash-io_970x250'  width={728} height={90} dimensions='300x250' bannerWidth='728px' bannerHeight='90px' adIdCrazyGames={adName} />
                    </Hidden>
                    </>
                }


{/* {width < 600 ?
                        <BannerAd size='small' adId='mageclash-io_300x250_2'  width={320} height={100} dimensions='300x250' bannerWidth='728px' bannerHeight='90px' adIdCrazyGames={adName} />
                            :
                        <BannerAd size='large' adId='mageclash-io_970x250'  width={728} height={90} dimensions='300x250' bannerWidth='728px' bannerHeight='90px' adIdCrazyGames={adName} />
                        } */}
             </Grid>
        );
    }
}
// function mapStateToProps(state) {
//     return {
//         width: state.stuff.width,
//         height: state.stuff.height,
//     }
// }
export default BottomBannerAd
// connect(
//     mapStateToProps,
// )(
//     BottomBannerAd)