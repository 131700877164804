import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'
import { StyledText } from './TextStyling'

export const baseMiddleLowerTextStyle = {
    color: "#333",
    fontWeight: "bold",
    fontSize: "1.875rem" 
}

class MiddleLowerText extends Component {
    constructor() {
        super()
        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        let { middleLowerText } = this.props
        if (!middleLowerText || middleLowerText == '') {
            return null
        } else {
            let parsedMiddleLowerText = middleLowerText
            if (typeof middleLowerText === 'string') {
                parsedMiddleLowerText = [{
                    text: middleLowerText,
                    style: baseMiddleLowerTextStyle
                }]
            }
            return (
                    <Grid container spacing={0} justifyContent="center" style={{ margin: 'auto',bottom:'10%' }}
                      alignItems="center">
                            <Paper style={{
                                userSelect: 'none', textAlign: 'center', backgroundColor: 'white',
                                borderRadius: '2px',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                borderColor: 'rgba(0,0,0,0.25)',
                                boxShadow: '0 0 0 2px #362D44',
                                marginTop: '20px',
                                marginBottom: '10px',
                                padding: '20px'
                            }}>
                                <StyledText data={parsedMiddleLowerText}/>
                            </Paper>
                    </Grid>
            );
        }
    }
}


function mapStateToProps(state) {
    return {
        pickupUIItem: state.stuff.pickupUIItem,
        adblock: state.stuff.adblock,
        middleLowerText: state.stuff.middleLowerText,
    }
}

export default connect(
    mapStateToProps,
)(MiddleLowerText)
