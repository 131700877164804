"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.topWear = void 0;
exports.topWear = [{
        id: 1,
        name: '',
        refName: null,
        rarity: 'common',
        stats: {},
        imgPath: 'empty-icon-3.png',
    },
    {
        id: 2,
        name: 'Wizard Hat',
        refName: 'top_wizard_uncommon.png',
        rarity: 'uncommon',
        stats: {
            spellDamage: 1,
            spellSpeed: 1,
            spellFirerate: 1,
        },
        imgPath: 'pointy-hat.png',
    },
    {
        id: 3,
        name: 'Rogue Hood',
        refName: 'top_rogue_uncommon.png',
        rarity: 'uncommon',
        stats: {
            meleeAttackSpeed: 1,
            movementSpeed: 1,
        },
        imgPath: 'rogue-hood.png',
    },
    {
        id: 4,
        name: 'Knight Helm',
        refName: 'top_knight_uncommon.png',
        rarity: 'uncommon',
        stats: {
            health: 1,
            meleeDamage: 1,
        },
        imgPath: 'knight-helm.png',
    },
    {
        id: 5,
        name: 'Wizard Hat',
        refName: 'top_wizard_rare.png',
        rarity: 'rare',
        stats: {
            spellDamage: 2,
            spellSpeed: 1,
            spellFirerate: 1,
        },
        imgPath: 'pointy-hat.png',
    },
    {
        id: 6,
        name: 'Rogue Hood',
        refName: 'top_rogue_rare.png',
        rarity: 'rare',
        stats: {
            meleeAttackSpeed: 2,
            movementSpeed: 1,
        },
        imgPath: 'rogue-hood.png',
    },
    {
        id: 7,
        name: 'Knight Helm',
        refName: 'top_knight_rare.png',
        rarity: 'rare',
        stats: {
            health: 2,
            meleeDamage: 1,
        },
        imgPath: 'knight-helm.png',
    },
    {
        id: 8,
        name: 'Wizard Hat',
        refName: 'top_wizard_epic.png',
        rarity: 'epic',
        stats: {
            spellDamage: 2,
            spellSpeed: 2,
            spellFirerate: 1,
        },
        imgPath: 'pointy-hat.png',
    },
    {
        id: 9,
        name: 'Rogue Hood',
        refName: 'top_rogue_epic.png',
        rarity: 'epic',
        stats: {
            meleeAttackSpeed: 2,
            movementSpeed: 2,
        },
        imgPath: 'rogue-hood.png',
    },
    {
        id: 10,
        name: 'Knight Helm',
        refName: 'top_knight_epic.png',
        rarity: 'epic',
        stats: {
            health: 2,
            meleeDamage: 2,
        },
        imgPath: 'knight-helm.png',
    },
    {
        id: 11,
        name: 'Wizard Hat',
        refName: 'top_wizard_legendary.png',
        rarity: 'legendary',
        stats: {
            spellDamage: 2,
            spellSpeed: 2,
            spellFirerate: 2,
        },
        imgPath: 'pointy-hat.png',
    },
    {
        id: 12,
        name: 'Rogue Hood',
        refName: 'top_rogue_legendary.png',
        rarity: 'legendary',
        stats: {
            meleeAttackSpeed: 2,
            movementSpeed: 3,
        },
        imgPath: 'rogue-hood.png',
    },
    {
        id: 13,
        name: 'Knight Helm',
        refName: 'top_knight_legendary.png',
        rarity: 'legendary',
        stats: {
            health: 2,
            meleeDamage: 3,
        },
        imgPath: 'knight-helm.png',
    },
    {
        id: 14,
        name: 'Slime Helm',
        refName: 'top_slime_rare.png',
        rarity: 'rare',
        stats: {
            health: 1,
            movementSpeed: 1,
            meleeDamage: 1,
            meleeAttackSpeed: 1,
            spellDamage: 1,
            spellFirerate: 1
        },
        imgPath: 'slime-helmet.png',
    },
    {
        id: 16,
        name: 'Slime Helm',
        refName: 'top_slime_legendary.png',
        rarity: 'legendary',
        stats: {
            health: 2,
            movementSpeed: 1,
            meleeDamage: 1,
            meleeAttackSpeed: 1,
            spellDamage: 1,
            spellSpeed: 1,
            spellFirerate: 1
        },
        imgPath: 'slime-helmet.png',
    },
    {
        id: 18,
        name: 'Ice Helm',
        refName: 'top_ice_legendary.png',
        rarity: 'legendary',
        stats: {
            health: 5,
        },
        imgPath: 'icon_ice_armour_helmet.png',
    },
    {
        id: 19,
        name: 'Crab Helmet',
        refName: 'top_crab.png',
        rarity: 'rare',
        stats: {
            meleeDamage: 2,
            meleeAttackSpeed: 1,
        },
        traits: {
            spiky: 0.1
        },
        imgPath: 'icon_crab_armour_helmet.png',
    },
    {
        id: 20,
        name: 'Piranha Helmet',
        refName: 'top_piranha.png',
        rarity: 'epic',
        stats: {
            meleeDamage: 2,
            meleeAttackSpeed: 2,
        },
        traits: {
            swimming: 0.35
        },
        imgPath: 'icon_piranha_helmet.png',
    },
];
