import {setGame, setSingleGame, setTitleLoaded, updateSelectedHighscoreGameMode} from "./actions/actions";
import { initiateLoginProcess } from "./auth"
import { loaded } from "./equippedSpells";
export function createBaseUrl() {
  const domainName = window.location.host
  return domainName.includes('localhost') ? 'http://localhost:3000' : `https://${domainName}`
}

export const domainName = window.location.host
// export let baseUrl = domainName.includes('localhost') ? 'http://localhost:3000' : `https://${domainName}`
export let baseUrl = createBaseUrl()
console.log('created base url')
fetchServerInfo()
setInterval(
  () => {
 if (!loaded) {
      fetchServerInfo()
 }
  }, 
  1000 * 60 
  )
import Constants from '../shared/constants'
export const store = configureStore()
// import ReactDOM from 'react-dom'
import React, { Component } from 'react'
// import { createRoot } from 'react-dom/client';
import ReactDOM from "react-dom";
import {connect, fetchServerInfo, play} from './networking'

import {createUI, preloadUI, updateUI, pointerManager, minimapManager} from './uiScene'

import { initState, getCurrentState } from './state'
import { createUsername } from './username'
import App from './components/App.js'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'
import * as Actions from './actions/actions'

import { calculateAccountStats } from './achievementScripts'
import { BRCircleUpdate, gameStarted, minZone, IS_TOUCH } from './game'
import { playEmote } from './playerScripts'
import { updateSpellSelection, toggleChatbox} from './game'
import { playerCurrentUIState } from './uiScene'
import { player, phaserObject, joysticks } from './create'
import { startSocketConnection } from './networking'
import {keyBinds} from "./keyBinds";

import { cmds, updatedReferrer } from './sdkIntegration'
import { getRandomItem } from "./phaserUtils"

try {
  if (localStorage.hasOwnProperty('selectedHead') && (localStorage.getItem('selectedHead') !== 'null') && (localStorage.getItem('selectedHead') !== '')) {
     console.log('found selected head', localStorage.getItem('selectedHead'))
      Actions.updateSelectedHead(parseInt(localStorage.getItem('selectedHead')))
  }
  else {
      const randomlySelectedHead = getRandomItem(Constants.INITIAL_RANDOM_HEADS)
      console.log('randomlySelectedHead', randomlySelectedHead)
      Actions.updateSelectedHead(randomlySelectedHead)
      localStorage.setItem('selectedHead', randomlySelectedHead)
  }
} catch(err) {
  console.log('error setting selected head', err)

}
try {
  // if (localStorage.hasOwnProperty('selectedHead') && (localStorage.getItem('selectedHead') !== 'null') && (localStorage.getItem('selectedHead') !== '')
  // ) {
  //     updateSelectedHead(parseInt(localStorage.getItem('selectedHead')))
  // }
  // else {
  //     localStorage.setItem('selectedHead', 1)
  // }
  if (localStorage.hasOwnProperty('emoteDict')) {
      console.log('found emote dict', localStorage.getItem('emoteDict'))
      let emoteData = JSON.parse(localStorage.getItem('emoteDict'))
      Actions.updateEmoteDict(0, emoteData[0])
      Actions.updateEmoteDict(1, emoteData[1])
      Actions.updateEmoteDict(2, emoteData[2])
      Actions.updateEmoteDict(3, emoteData[3])
  } else {
    console.log(' did not find emote sit')
      localStorage.setItem('emoteDict', JSON.stringify({
          0: 1,
          1: 2,
          2: 3,
          3: 4,
      }))
  }
} catch (err) { }

let mainDomainName = domainName
if (domainName.includes('localhost')) {
  if (Constants.SINGLE_GAME_NAME) {
    mainDomainName = Constants.GAME_MODE_MAP.find((domain) => domain.gameName === Constants.SINGLE_GAME_NAME).domain
  } else {
    mainDomainName = Constants.GAME_MODE_MAP.find((domain) => domain.gameName === 'mainportal').domain
  }
} else if (domainName.includes('beta')) {
    mainDomainName = domainName.replace('beta', 'www')
}
export const domainInfo = Constants.GAME_MODE_MAP.find((domain) => domain.domain === mainDomainName)
export let currentGame = {value: 'portal'}
if (domainInfo.gameName !== 'mainportal') {
    currentGame = {value: domainInfo.gameName}
}
export const Pickups = {
  spells: [],
  TrialsData: [],
  topWear: [],
  bodyWear: [],
  handWear: [],
}

export function updatePickups(selectedGame) {
  console.log('updating pickups', selectedGame)
  const  { spells }   = require(`../games/${selectedGame}/shared/pickups/spells`)
  const { EquipmentData } =  require(`../games/${selectedGame}/shared/pickups/equipment/equipment`)
  const { TrialsData } = require(`../games/${selectedGame}/shared/pickups/trials`)
  Object.assign(Pickups, {
    spells: spells,
    TrialsData: TrialsData,
    topWear: EquipmentData.topWear,
    bodyWear: EquipmentData.bodyWear,
    handWear: EquipmentData.handWear,
  })
}
export const EnemyConstants = []
export function updateEnemyConstants(selectedGame) {
  const { EnemyConstants: newEnemyConstants } = require(`../games/${selectedGame}/shared/enemies`)
  console.log('updating enemy constants', newEnemyConstants)
  Object.assign(EnemyConstants, newEnemyConstants)
}

export const CustomTypes = []
export function updateCustomTypes(selectedGame) {
    const { CustomTypes: newCustomTypes } = require(`../games/${selectedGame}/shared/customTypes`)
    Object.assign(CustomTypes, newCustomTypes)
}
export function updateStyles(selectedGame) {
  let gameToFind = selectedGame ? selectedGame : 'mainportal'
 let mapInfo = Constants.GAME_MODE_MAP.find((domain) => domain.gameName === gameToFind)
  console.log("UPDATING STYLE", {
    selectedGame: selectedGame,
    mapInfo: mapInfo,
  })
  console.log('going to update border', mapInfo.style.primaryBorder)
  colorPalette.primaryBorder = mapInfo.style.primaryBorder
  console.log('updated border', colorPalette.primaryBorder)
  colorPalette.secondaryBorder = mapInfo.style.secondaryBorder
  colorPalette.primary = mapInfo.style.primary
    colorPalette.secondary = mapInfo.style.secondary
    colorPalette.highscoreGrid = mapInfo.style.highscoreGrid

}

export function updateGameConstants(selectedGame) {
  updateSelectedHighscoreGameMode(selectedGame)
  console.log('update game constants called', selectedGame)
  updateStyles(selectedGame)
  updatePickups(selectedGame)
  updateEnemyConstants(selectedGame)
  updateCustomTypes(selectedGame)

}
// Load Phaser
import { update } from './game.js'
import { preload } from './preload.js'
import { create } from './create.js'

import * as SpinePlugin from '@esotericsoftware/spine-phaser/dist/iife/spine-phaser.js'


if (Constants.SINGLE_GAME_NAME) {
    updateGameConstants(Constants.SINGLE_GAME_NAME)
}

// window.onbeforeunload = function (e) {
//   return "Do you want to exit this page?";
// };

export var gameanalytics = require('gameanalytics')

export let emoteDict = {
  0: 1,
  1: 2,
  2: 3,
  3: 4,
}

export let accountLevelG = {value: 0}
export let accountExpRatioG = {value: 0}

export let accountLevelUpDict = {}
let totalEXP = Constants.ACC_LVL_INITIAL_EXP_REQ
for (let i = 1; i <= Constants.ACC_MAX_LEVEL; i++) {
  totalEXP += Constants.ACC_LVL_EXTRA_EXP_PER_LVL
  accountLevelUpDict[i] = totalEXP
}

function processAccountLevelUp(accountLevel, currentExp, accountLevelUpDict) {
  if (currentExp >= accountLevelUpDict[accountLevel]) {
    currentExp -= accountLevelUpDict[accountLevel]
    accountLevel += 1
  }

  if (currentExp >= accountLevelUpDict[accountLevel]) {
    return processAccountLevelUp(accountLevel, currentExp, accountLevelUpDict)
  } else {
    return {
      accountLevel: accountLevel,
      currentExp: currentExp
    }
  }
}

let lastVersionPlayed
try {
  if (localStorage.hasOwnProperty('lastVersionPlayed') && (localStorage.getItem('lastVersionPlayed') !== 'null') && (localStorage.getItem('lastVersionPlayed') !== '')) {
    lastVersionPlayed = localStorage.getItem('lastVersionPlayed')
  }
  // else {
  //   localStorage.setItem("lastVersionPlayed", JSON.stringify(false));
  //   joinedDiscord = false
  // }
  if (lastVersionPlayed == Constants.CURRENT_VERSION) {
  Actions.updateNewUpdateDialog(false)
  } else {
    // try {
    //   console.log('setting last v played')
    //   localStorage.setItem("lastVersionPlayed", Constants.CURRENT_VERSION);
    // } catch (err) { }
  }
} catch (err) { }



let joinedDiscord
try {
  if (localStorage.hasOwnProperty('joinedDiscord') && (localStorage.getItem('joinedDiscord') !== 'null') && (localStorage.getItem('joinedDiscord') !== '')) {
    joinedDiscord = JSON.parse(localStorage.getItem('joinedDiscord'))
  }
  else {
    localStorage.setItem('joinedDiscord', JSON.stringify(false))
    joinedDiscord = false
  }
  Actions.updateJoinedDiscord(joinedDiscord)
} catch (err) { }

import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  dsn: 'https://ccbfd4597ae74ae9bd5c08a6bb2c0efa@o587804.ingest.sentry.io/5738803',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

// myUndefinedFunction();
import VirtualJoystickPlugin from 'phaser3-rex-plugins/plugins/virtualjoystick-plugin.js'
import WebFontLoaderPlugin from 'phaser3-rex-plugins/plugins/webfontloader-plugin.js'
import {clientDebugOptions, debugOptions, serverDebugOptions} from './debug'
import {bigScreenBorderProperties, colorPalette} from "./components/uiStyles";
// import {PickupsDict} from "../shared/types";

class mainScene extends Phaser.Scene {
  constructor() {
    super('GameScene')
    this.preload = preload
    this.create = create
    this.update = update
  }
}

class uiScene extends Phaser.Scene {
  constructor() {
    super({ key: 'uiScene', active: true })
    this.preload = preloadUI
    this.create = createUI
    this.update = updateUI
  }
}
// mainScene.preload = preload
// mainScene.create = create
// mainScene.update = update

const config = {
  type: Phaser.AUTO,
  mipmapFilter: 'LINEAR_MIPMAP_LINEAR',

  // mipmapFilter: 'NEAREST_MIPMAP_NEAREST',
  // mipmapFilter: 'LINEAR',
  scale: {
    parent: 'yourgamediv',
    mode: Phaser.Scale.RESIZE
    // mode:Phaser.Scale.FIT
  },
  physics: {
    default: 'arcade',
    arcade: {
      gravity: { y: 0 },
    }
  },
  scene: [
    mainScene,
    uiScene
    //   {

    //   preload: preload,
    //   create: create,
    //   update: update,

    // },

    //   {
    //     preload: preloadUI,
    //     create: createUI,
    //     update: updateUI,
    //   },

  ],
  plugins: {
    scene: [
      { key: 'spine.SpinePlugin', plugin: spine.SpinePlugin, mapping: 'spine' },

    ],
    global: [
      {
        key: 'rexVirtualJoystick',
        plugin: VirtualJoystickPlugin,
        start: true
      },
      // {
      //   key: 'rexWebFontLoader',
      //   plugin: WebFontLoaderPlugin,
      //   start: true
      // },
    ]
  },
  callbacks: {
    postBoot: function (game) {
      game.hasFocus = document.hasFocus()
    }
  },
}


function isChromeOS() {
  return navigator.userAgent.includes('CrOS');
}

console.log('is chromeOS', isChromeOS()); // Returns true if Chrome OS, false otherwise


if (isChromeOS()) {
config.defaultPipeline = 'MobilePipeline'
}
// if (window.location.hash == "#")
//  { window.location.hash = "" }

if (String(window.location.hash).substring(0, 1) == '#') {
  window.location.hash = ''
  window.location.href = window.location.href.slice(0, -1)
}
// Firefox version of the hack
if (String(location.hash).substring(0, 1) == '#') {
  location.hash = ''
  location.href = location.href.substring(0, location.href.length - 3)
}

// let url = `${baseUrl}/auth/login/success`
if (window.location.host.includes('beta')) {
  setTimeout(function () {
    Actions.updateSelectedServer('https://beta1.mageclash.io')
  }, 50)
  setTimeout(function () {
    Actions.updateSelectedServer('https://beta1.mageclash.io')
  }, 150)
}

let referrer = document.referrer
console.log('referrer read in index', referrer)
if (referrer.includes('crazygames') || referrer.includes('1001juegos')) {
    referrer = 'crazygames'
    updatedReferrer.value = 'crazygames'
}
console.log('final referrer', referrer)

let loginType
if (updatedReferrer.value === 'crazygames') {
  loginType = 'crazygames'
} else {  
  loginType = 'oAuth'
}
console.log('login type', loginType)

let testCrazyGames = false 
// UNCOMMENT BELOW TO TEST CRAZYGAMES IN LOCALHOST
// testCrazyGames = true

if (testCrazyGames) {
  loginType = 'crazygames'
  referrer = 'crazygames'
}
initiateLoginProcess(loginType, baseUrl, referrer)

if (testCrazyGames) {
  Actions.updateReferrer('crazygames')
}

//   })
// gameanalytics.GameAnalytics.setEnabledInfoLog(true);
// gameanalytics.GameAnalytics.setEnabledVerboseLog(true);
gameanalytics.GameAnalytics.configureBuild('test 0.0.1')
gameanalytics.GameAnalytics.configureAvailableResourceCurrencies(['level', 'playerKills', 'creatureKills', 'chestKills', 'score', 'killedBy'])
gameanalytics.GameAnalytics.configureAvailableResourceItemTypes(['playerStats'])

gameanalytics.GameAnalytics.initialize('869a6937063c671caa6e9c9fed3a11b7', '9ef586763fb4e09d768949e74def370d43652c89')

// const container = document.getElementById('container');
// if (container) {
//   const root = createRoot(container);
//   root.render(<Provider store={store}><App /></Provider>);
// }

// Inject React into DOM
const wrapper = document.getElementById('container')
wrapper ? ReactDOM.render(<Provider store={store}> <App /> </Provider>, wrapper) : false


var game = new Phaser.Game(config)
console.log('pipeline', game.renderer.pipelines)
// createUsername()

// export let loaded
export let username
// function setLoaded(){
//   loaded=true
// }

export function setMute() {
  phaserObject.sound.game.scene.scenes[0].sound.mute = true
}

export function unMute() {
  phaserObject.sound.game.scene.scenes[0].sound.mute = false
}

export function toggleMute() {
  // console.log('phaser object', phaserObject)
  if (phaserObject.sound.game.scene.scenes[0].sound.mute == false) {
    phaserObject.sound.game.scene.scenes[0].sound.mute = true
    try {
      localStorage.setItem('mute', 'true')
    }
    catch (err) { }

    Actions.toggleMute(true)
  } else {
    phaserObject.sound.game.scene.scenes[0].sound.mute = false
    try {
      localStorage.setItem('mute', 'false')
    } catch (err) { }
    Actions.toggleMute(false)
  }
}

export function toggleMuteMusic() {
  if (phaserObject.sound.game.scene.scenes[0].sound.mute == false) {
    // phaserObject.sound.game.scene.scenes[0].sound.mute = true
    phaserObject.sfx.stop('bgm')
    try {
      localStorage.setItem('muteMusic', 'true')
    } catch (err) { }

    Actions.toggleMute(true)
  } else {
    // phaserObject.sound.game.scene.scenes[0].sound.mute = false
    this.sfx.play('bgm', { loop: true })
    try { localStorage.setItem('muteMusic', 'false') } catch (err) { }
    Actions.toggleMute(false)
  }
}

// export function startFullscreen() {
//   console.log('fullscreen called')
//   phaserObject.scale.startFullscreen()
// }
function mapKeyboardKeys() {

  phaserObject.input.keyboard.on('keydown-ENTER', toggleChatbox, phaserObject)

  phaserObject.input.keyboard.on('keydown-L', () => {
    console.log("toggling collision draw")
    serverDebugOptions.collisionDraw = !serverDebugOptions.collisionDraw
  }, phaserObject)
  phaserObject.input.keyboard.on('keydown-K', () => {
    console.log("toggling collision draw")
    clientDebugOptions.collisionDraw = !clientDebugOptions.collisionDraw
  }, phaserObject)

  phaserObject.wasd = phaserObject.input.keyboard.addKeys(
    {
      up: Phaser.Input.Keyboard.KeyCodes.W,
      down: Phaser.Input.Keyboard.KeyCodes.S,
      left: Phaser.Input.Keyboard.KeyCodes.A,
      right: Phaser.Input.Keyboard.KeyCodes.D,
      // space: Phaser.Input.Keyboard.KeyCodes.SPACE
    },
    false
  )
}

export function startGame(usernameInput, selectedHead, serverURL, adblock, token, guest, IS_TOUCH) {
  playerCurrentUIState.value = 'mainGame'

  gameanalytics.GameAnalytics.addDesignEvent('design:sessionTracker:clickedPlay')
  username = createUsername(usernameInput)
  player.usernameText.text = username
  mapKeyboardKeys()

  startSocketConnection(serverURL)

  Promise.all([
    connect(onGameOver),
  ]).then(() => {
    play([username, selectedHead, adblock, IS_TOUCH, token, guest])
    // initState()
  }).catch(console.error)
}

function onGameOver(thingsKept, minZone) {

  cmds.gameplayStop()

  if (IS_TOUCH) {
    joysticks.joystickL.visible = false
    joysticks.joystickL.enable = false
    joysticks.joystickR.visible = false
    joysticks.joystickR.enable = false
  }
  pointerManager.hidePointers()
  gameStarted.value = false
  gameanalytics.GameAnalytics.addProgressionEvent(gameanalytics.EGAProgressionStatus.Complete,
    'GameplaySession', 'Standard', 'Standard', thingsKept.prevLevel)

  gameanalytics.GameAnalytics.addDesignEvent('died:level', thingsKept.metrics.level)
  gameanalytics.GameAnalytics.addDesignEvent('died:playerKills', thingsKept.metrics.playerKills)
  gameanalytics.GameAnalytics.addDesignEvent('died:creatureKills', thingsKept.metrics.creatureKills)
  gameanalytics.GameAnalytics.addDesignEvent('died:chestKills', thingsKept.metrics.chestKills)
  gameanalytics.GameAnalytics.addDesignEvent('died:score', thingsKept.metrics.score)
  gameanalytics.GameAnalytics.addDesignEvent('died:killedBy', thingsKept.metrics.killedBy)

  let prevScore = thingsKept.prevScore
  let currentExp = (accountLevelUpDict[accountLevelG.value] * accountExpRatioG.value) + Math.min(500000, prevScore)
  let levelObj = processAccountLevelUp(accountLevelG.value, currentExp, accountLevelUpDict)
  let newExpRatio = levelObj.currentExp / accountLevelUpDict[levelObj.accountLevel]
  accountExpRatioG.value = newExpRatio

  accountLevelG.value = levelObj.accountLevel
  Actions.updateAccountEXP(levelObj.accountLevel, levelObj.currentExp, newExpRatio)

  let spellData = []
  thingsKept.spellsToKeep.forEach(spellID => {
    let singleSpellData = Pickups.spells.find(spell => spell.spellID == spellID)
    spellData.push(singleSpellData)
  })

  thingsKept.spellsToKeep = spellData

  let gearData = []
  thingsKept.gearToKeep.forEach(gearDict => {
    let singleGearData = Pickups[gearDict['type']].find(gear => gear.id == gearDict.id)
    gearData.push({ type: gearDict.type, data: singleGearData })
  })
  thingsKept.gearToKeep = gearData

  setTimeout(function () {
    Actions.updateLevel(thingsKept.level, 0)
    Actions.updateMinZone(thingsKept.minZone)
    Actions.updateZoneCountdown(0)
    minZone.value = thingsKept.minZone
  }, 1500)

  Actions.triggerPlayerDied(true, thingsKept)
  minimapManager.hideMinimap()
  if (phaserObject.minimap) {
    phaserObject.minimap.visible = false
  }

  playerCurrentUIState.value = 'menu'
}