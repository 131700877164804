import Constants from '../shared/constants'
import Phaser from 'phaser'
import {store} from './index.js'
import { respawnMenuToMainMenu } from './components/RespawnMenu'
import { loadMenu } from './components/settings/GeneralSettings'

import * as Actions from './actions/actions'

import { gameRestarted, getCurrentState, resetServerTimestamp } from './state'
import {
  handleInput,
  handleShot,
  handlePickupObject,
  handleSheatheWeapon,
  handleCMovement,
  handleAngleUpdate, handleTakeDamage
} from './input'
import { equippedSpells as equippedSpellsImport, assignedDict, serverSettings, stats, playerMultipliers } from './equippedSpells.js'
import { loaded } from './equippedSpells'
import { getMouseAngle, getMousePos, distance, angleToShot } from './phaserUtils'
import { levelUpAnimation, rippleAnimation, lavaRippleAnimation, punch, castSpellAnim, tweenWeapon, tweenDaggerWeapon, updateValues, updatePlayerAngle, updateMinimapPosition, equipWeapon, drawWeapon, sheatheWeapon, toggleWeapon, updateSpellCooldowns, updatePlayerLabels } from './playerScripts.js'
import {
  player,
  cursors,
  playerMinimapCircle,
  displayCoords,
  joysticks,
  cullingManager,
  bigCullingManager, playerSpeed, playerStats, mapManager, phaserObject, entityPoolManager
} from './create'
import { loadRemainingAssets } from './preload'
import { updateGroup, createOtherPlayer, updateOtherPlayer, destroyOtherPlayer, createEnemy, updateEnemy, destroyEnemy, createBullet, updateBullet, destroyBullet, createPickup, updatePickup, destroyPickup } from './updateGroups'
import { Pickups } from './index';
import { checkBulletConditions } from "../shared/bulletCondition"
import Bowser from 'bowser'
export let gameStarted = { value: false }



export let eventCoordsList = [null, null, null, null]

export let chatboxActive = { value: false }
export let angleLocked = { value: false }

export const collisions = createCollisionSystem()

export const ingameShopActive = { value: false }
export const chatActive = { value: false }
export const emoteWheelActive = { value: false }

export const mobileFiring = { value: false }
let resetTimer = 0

export const spellPointers = JSON.parse(JSON.stringify(Constants.POINTER_ACTIVE_TEMPLATE))

import { deviceType } from 'detect-it';
const browser = Bowser.getParser(window.navigator.userAgent)
const browserDevice = browser.getPlatformType()
// export function disableChatbox() {
//   chatboxActive = false
//   this.input.keyboard.enabled = true
// }

// export let chatboxActive


// const data = JSON.parse(localStorage.getItem('level'))
// if (!data) {localStorage.setItem('level', {level: 1, exp: 0})}
// console.log('LOCAL LEVEL', localStorage.getItem('level'))
// let levelData
// if (localStorage.hasOwnProperty("levelData") && (localStorage.getItem("levelData") !== 'null') && (localStorage.getItem("levelData") !== '')) {
//       levelData = localStorage.getItem("levelData");
//   }
//   else {
//     localStorage.setItem("levelData", JSON.stringify({level: 1, exp: 0}));
//       levelData = localStorage.getItem("levelData");
//   }

////// TEST BOTS/// ENABLE THIS AND BELOW
// import {playEmote} from './playerScripts'
// function getRandomItem(array) {
//   let randomItem = array[Math.floor(Math.random() * array.length)];
//   return randomItem
// }
// let testFiring = true
////////


// let levelData = window.localStorage.levelData
// console.log('storage', myStorage)
// console.log('level data', JSON.parse(levelData))
export function calculateScaleFactor(width, height) {
  let calculatedDiffW = .5 + (((width - 650) / 650) / 2)
  let calculatedDiffH = .2 + ((height / 800) / 2)
  if (height < 600) { calculatedDiffH = 0.45 }
  if (height < 500) { calculatedDiffH = 0.4 }
  if (height < 400) { calculatedDiffH = 0.35 }
  if (height < 300) { calculatedDiffH = 0.3 }
  if (height < 200) { calculatedDiffH = 0.2 }
  // console.log('calculdatedDiffW', calculatedDiffW, 'calculatedDiffH', calculatedDiffH)
  let calculatedDiff = Math.min(calculatedDiffW, calculatedDiffH)
  return calculatedDiff
  // return 1
}


export let currentlyPressed = 1
export function setCurrentlyPressed(key) {
  currentlyPressed = key
}
export let lastPressed = 1
export function setLastPressed(key) {
  lastPressed = key
}
export let shiftActive = { value: false, keySlot: null }

export let IS_TOUCH = false
console.log('device checks:', {
  d: deviceType,
  b: browserDevice,
})
if (deviceType === 'touchOnly' || browserDevice === 'mobile' || browserDevice === 'tablet') {
  IS_TOUCH = true
}
// window.addEventListener('touchstart', function (e) {
//   // console.log('touch toggled')
//   if (IS_TOUCH == false) {
//     Actions.toggleTouchControls(true)
//   }
//   IS_TOUCH = true
//   // window.removeEventListener('touchstart')

// })

export let otherPlayersList = []
let enemyList = []
let bulletList = []
let pickupsList = []

let timeCalculations = {
  previousServerTime: null,
  dt: null,
  dtCalc: null
}

let playerKills = 0

export let gameMode = 'world'

export let loadedServers = { value: false }
export let BRCircleUpdate = {}

export let level = { value: 1 }
export let EXPRatio = { value: 0 }

export let mapCenter = {}
export let circlePointDict = {}
export let currentCircle
let prevCircle
export let minZone = { 'value': 1 }
let prevZoneCountdown = { value: null }

export let updatePlayerSprite = { 'weapon': 0 }

export let globalSpellCooldown = { value: 0, baseValue: 0 }

export let playerResources = {}
import { keyBinds } from "./keyBinds";
import { minimapManager, playerCurrentUIState } from "./uiScene";
import {
  checkCollision,
  createCircle,
  createCollisionSystem, createPoint,
  extractNearbyCollisionData,
  getPotentialMatches, handleBodyOverlap, PhysicsBody, removeBody
} from "../shared/collisions";
import { clientDebugOptions, updateDebug } from "./debug";
import { toggleIngameSettingsMenu, toggleShop } from "./actions/actions";
import { checkClientsideResourceCost, handleCauthProjectileShot, handleCauthStaticShot, handleCauthMeleeShot, updateClientBullets, destroyClientsideBullet } from "./cauth";
import { dustEmitter } from "./MapManager";
import { getShouldShowGamePage } from './components/mainUIScreens/UI'

export function destroyEntities() {
  setTimeout(() => {
    gameRestarted.value = true
  }, 1000)
}
export function flushEntityCache(iterationCount = 0) {
  // only bullet cache for now, since only bullets are using sprite pooling

    phaserObject.bullets.getChildren().forEach(bullet => {
      destroyBullet(bullet, bulletList, true)
    })
    phaserObject.clientsideBullets.getChildren().forEach(bullet =>{
      destroyClientsideBullet(bullet, true)
    })
    // let testList1 = JSON.stringify(phaserObject.bullets.getChildren().map(bullet => bullet.id))
    // let testList2 = JSON.stringify(phaserObject.clientsideBullets.getChildren().map(bullet => bullet.id))
    if ((phaserObject.bullets.getLength()>0 || phaserObject.clientsideBullets.getLength()>0) && iterationCount < 7) {
      console.log('[DEBUG] bullet and cauth bullet list flushed but has new bullets. trying again. iteration:', iterationCount)
      iterationCount++
      setTimeout(() => {
        flushEntityCache(iterationCount)
      }, 500)
    } else {
      if ((phaserObject.bullets.getLength()>0 || phaserObject.clientsideBullets.getLength()>0)) {
        let testList1 = JSON.stringify(phaserObject.bullets.getChildren().map(bullet => bullet.id))
        let testList2 = JSON.stringify(phaserObject.clientsideBullets.getChildren().map(bullet => bullet.id))
        console.log('[DEBUG] bullet and cauth bullet list still not fully flushed after 5 tries. remaining bullets & cauth bullets:', testList1, testList2)
      }
      bulletList.length = 0
      // console.log('[DEBUG] bullet and cauth bullet list flushed. remaining bullets & cauth bullets:', testList1, testList2)
      setTimeout(() => {
        // phaserObject.bulletCache.clear(true, true)
        // let testList = JSON.stringify(phaserObject.bulletCache.getChildren().map(bullet => bullet.id))
        // console.log('[DEBUG] bullet cache flushed. remaining bullets:', testList)
      }, 2000)
    }
}

function updateTimeCalculations(timeCalculations, serverTime) {
  if (timeCalculations.previousServerTime) {
    timeCalculations.dt = serverTime - timeCalculations.previousServerTime
    timeCalculations.previousServerTime = serverTime
  }
  else {
    timeCalculations.previousServerTime = serverTime
  }
  timeCalculations.dtCalc = timeCalculations.dt / 1000
  if (timeCalculations.dtCalc < 0) {
    timeCalculations.dtCalc = 0
  }
}

export function updateSpellSelection(keyPressed) {
  if (serverSettings && serverSettings.fullObjTest && serverSettings.fullObjTest.selectableKeys || keyPressed === 'shift' || keyPressed === 'Shift') {
    switch (keyPressed) {
      case ('Shift'):
      case ('shift'):
        shiftActive.value = true
      default:
        let keyArray
        // if (Constants.GAME == 'mageclash' || Constants.GAME == 'monstermaster') {
        // if (Constants.GAME == 'mageclash') {
        if (['1', '2', '3', '4', '5'].includes(keyPressed)) {
          //   if (['1', '2', '3', '4'].includes(keyPressed) && serverSettings.fullObjTest.selectableKeys) {
          currentlyPressed = keyPressed
          Actions.updatePressedKey(currentlyPressed)
        }
        // }
        break
    }
  }
}


export function toggleChatbox() {
  if (!chatboxActive.value) {
    Actions.toggleChatboxActive(true)
    this.input.keyboard.enabled = false
    chatboxActive.value = true
    this.input.keyboard.enableGlobalCapture()
  }
}

function callAnimation(equippedSpell, phaserData) {
  if (equippedSpell.hasWeapon && player.playerWeapon) {
    if (phaserData.game.hasFocus && equippedSpell.audio.swing) {
      phaserData.sfx.play(equippedSpell.audio.swing)
    }
    tweenWeapon(player, equippedSpell, phaserData)
  } else {
    castSpellAnim(player, phaserData)
  }
}

export function listenToEntityClicks(phaserData, playerInfo) {
  let mousePos
  if (!IS_TOUCH) {
    mousePos = getMousePos(phaserData)
  } else {
    mousePos = [player.x, player.y]
  }
  const collisionPoint = createCircle(collisions, mousePos[0], mousePos[1], 13)
  const potentials = getPotentialMatches(collisions, collisionPoint)
  let clickData = {
    id: undefined,
    petOwnerId: undefined,
    objectType: undefined,
    objectRef: undefined,
  }
  let targetDistance = 0
  for (let i = 0; i < potentials.length; i++) {
    const object = potentials[i]
    if (object.objectType === 'enemy' && checkCollision(collisions, collisionPoint, object)) {
      let distanceToObj = distance(collisionPoint, object)
      if (clickData.id && distanceToObj > targetDistance) {
        continue
      } else {
        if (object.isPet) {
          clickData.objectType = 'pet'
          clickData.petOwnerId = object.petOwnerId
        } else {
          clickData.objectType = 'enemy'
        }
        clickData.id = object.enemyServerId
        clickData.objectRef = object
        targetDistance = distanceToObj
        continue
      }
    }
    else if (object.objectType === 'otherPlayer' && checkCollision(collisions, collisionPoint, object)) {
      let distanceToObj = distance(collisionPoint, object)
      if (clickData.id && distanceToObj > targetDistance) {
        continue
      } else {
        console.log('touching other player', object.playerId)
        clickData.id = object.playerId
        clickData.objectType = 'otherPlayer'
        clickData.objectRef = object
        targetDistance = distanceToObj
        continue
      }
    } else if (!object.objectType && checkCollision(collisions, collisionPoint, object) && object === player.collisionBody) {
      let distanceToObj = distance(collisionPoint, object)

      if (clickData.id && distanceToObj > targetDistance) {
        continue
      } else {
        clickData.objectType = 'player'
        clickData.objectRef = object
        targetDistance = distanceToObj
      }
    }
  }
  removeBody(collisions, collisionPoint)
  return clickData
}
export function fire(phaserData, playerInfo, selectedKey, equippedSpells, movementDir) {
  // if (selectedKey === 1) {
  //   listenToEntityClicks(phaserData, playerInfo)
  // }
  if (ingameShopActive.value === true || chatActive.value === true || emoteWheelActive.value === true) {
    return
  }
  if (currentCircle >= minZone.value) {
    if (globalSpellCooldown.value <= 0) {
      for (let i = 0; i < equippedSpells.length; i++) {
        let spellSlot = i
        if (equippedSpells[i].cooldownCounter == null) {
          equippedSpells[i].cooldownCounter = 0
        }
        if (globalSpellCooldown.value == null) {
          globalSpellCooldown.value = globalSpellCooldown.baseValue
        }
        if (equippedSpells[i].assignedKey == selectedKey && equippedSpells[i].cooldownCounter <= 0) {
          if (spellPointers[equippedSpells[i].assignedKey] === true) {
            Actions.setSpellPointer(equippedSpells[i].assignedKey, false)
          }
          const equippedSpell = equippedSpells[i]
          let spellID = equippedSpell.spellID
          if (spellID !== 0) {
            let executedShot
            let spellType = equippedSpell.type
            if (equippedSpell.castAtPointer) {
              spellType = 'static'
            }
            let dir
            if (!IS_TOUCH) {
              dir = getMouseAngle(phaserData, playerInfo)
            } else {
              dir = angleToShot(player.angle)
            }
            let mousePos
            if (!IS_TOUCH) {
              mousePos = getMousePos(phaserData)
            } else {
              mousePos = [player.x, player.y]
            }
            let clickData
            if ((selectedKey === 1 || selectedKey === 'shift') && equippedSpell.listenToEntityClicks) {
              clickData = listenToEntityClicks(phaserData, playerInfo)
            }
            let finalSpellData = equippedSpell
            let conditionResult = checkBulletConditions(equippedSpell, equippedSpell.conditions, clickData)
            if (typeof conditionResult === 'number') {
              // alt spell
              finalSpellData = Pickups.spells.find(spellData => spellData.spellID == conditionResult)
              spellID = conditionResult
              conditionResult = checkBulletConditions(finalSpellData, finalSpellData.conditions, clickData)
            }
            if (typeof conditionResult === 'undefined') {
              // spell failed. do nothing. show a notification or message?
              console.log('condition check returned undefined. spell failed')
              return
            } else if (typeof conditionResult === typeof equippedSpell) {
              // returned unchagned or modified spell. use it
              if (finalSpellData !== conditionResult) {
                finalSpellData = conditionResult
              }
            }
            // console.log('clientside spell damage check', finalSpellData.damage)

            if (Constants.CLIENTSIDE_BULLETS) {
              if (spellType === 'projectile' || spellType === 'multi' || equippedSpell.type2 === 'projectile' || equippedSpell.type2 === 'multi') {
                let cauthResourceCheck = checkClientsideResourceCost(player, playerResources, equippedSpell)
                if (cauthResourceCheck) {
                  handleCauthProjectileShot(dir, finalSpellData, clickData)
                }
              } else {
              }
            }
            switch (spellType) {
              case 'self':
                callAnimation(finalSpellData, phaserData)

                if (distance(player, { x: mousePos[0], y: mousePos[1] }) <= 400) {

                  if (Constants.CLIENTSIDE_BULLETS) {
                    let dirVal = Number.parseFloat(dir?.toPrecision(3))
                    if (!dirVal) {
                      dirVal = 0
                    }
                    let cauthResourceCheck = checkClientsideResourceCost(player, playerResources, equippedSpell)
                    if (cauthResourceCheck) {
                      handleCauthStaticShot({ x: Math.round(playerInfo.x), y: Math.round(playerInfo.y) }, dirVal, finalSpellData, clickData)
                    }
                  }

                  handleShot({ x: Math.round(playerInfo.x), y: Math.round(playerInfo.y) }, spellID, spellSlot, clickData)
                  executedShot = true
                }
                break
              case 'static':
                callAnimation(finalSpellData, phaserData)

                if (distance(player, { x: mousePos[0], y: mousePos[1] }) <= 400) {

                  if (Constants.CLIENTSIDE_BULLETS) {
                    let dirVal = Number.parseFloat(dir?.toPrecision(3))
                    if (!dirVal) {
                      dirVal = 0
                    }
                    let cauthResourceCheck = checkClientsideResourceCost(player, playerResources, equippedSpell)
                    if (cauthResourceCheck) {
                      handleCauthStaticShot({ x: Math.round(mousePos[0]), y: Math.round(mousePos[1]) }, dirVal, finalSpellData, clickData)
                    }
                  }

                  handleShot(mousePos, spellID, spellSlot, clickData)
                  executedShot = true
                }
                break
              case 'multiStatic':
                callAnimation(finalSpellData, phaserData)

                if (distance(player, { x: mousePos[0], y: mousePos[1] }) <= 400) {

                  if (Constants.CLIENTSIDE_BULLETS) {
                    let dirVal = Number.parseFloat(dir?.toPrecision(3))
                    if (!dirVal) {
                      dirVal = 0
                    }
                    let cauthResourceCheck = checkClientsideResourceCost(player, playerResources, equippedSpell)
                    if (cauthResourceCheck) {
                      // if multiStatic is cast at pointer, then it is cast as if static spell with castAtPointer, so only self-style multiStatic spells need handling
                      handleCauthStaticShot({ x: Math.round(playerInfo.x), y: Math.round(playerInfo.y) }, dirVal, finalSpellData, clickData)
                    }
                  }

                  handleShot({ x: Math.round(playerInfo.x), y: Math.round(playerInfo.y) }, spellID, spellSlot, clickData)
                  executedShot = true
                }
                break
              case 'movement':
                if (phaserData.game.hasFocus && finalSpellData.audio.cast) {
                  phaserData.sfx.play(finalSpellData.audio.cast)
                }
                handleShot(movementDir, spellID, spellSlot)
                executedShot = true
                break

              case 'melee': {
                if (phaserData.game.hasFocus) {
                  phaserData.sfx.play(finalSpellData.audio.swing)
                }
                // phaserData.sound.play(equippedSpells[i].audio.swing[Math.floor(Math.random() * equippedSpells[i].audio.swing.length)])
                if (finalSpellData['spellID'] == 12) {
                  punch(player, phaserData)
                } else if (finalSpellData.tweenType == 'dagger') {
                  let spell = finalSpellData
                  tweenDaggerWeapon(player, spell, phaserData)
                }
                // else if (equippedSpells[i].isSpear) {
                //   angleLocked.value = true
                //   setTimeout(
                //       () => {
                //         angleLocked.value = false
                //       },
                //       equippedSpells[i].preTweenDuration + equippedSpells[i].tweenForwardDuration + equippedSpells[i].tweenBackwardDuration
                //   )
                //   let spell = equippedSpells[i]
                //   tweenWeapon(player, spell, phaserData)
                // }
                else {
                  let spell = finalSpellData
                  tweenWeapon(player, spell, phaserData)
                }
                let dir
                if (!IS_TOUCH) {
                  dir = getMouseAngle(phaserData, playerInfo)
                } else {
                  dir = angleToShot(player.angle)
                }
                let extraMultiplier = 1
                // console.log('[DEBUG] melee: dir, id, slot:', dir, spellID, spellSlot)

                if (Constants.CLIENTSIDE_BULLETS && !finalSpellData.type2) {
                  // check for player's sprint/movement spell and stealth status
                  if (player.alpha === 0.5 && equippedSpells[equippedSpells.length - 1] && equippedSpells[equippedSpells.length - 1].sprintTicks && equippedSpells[equippedSpells.length - 1].stealth && equippedSpells[equippedSpells.length - 1].damageMultiplier) {
                    // stealthed and spell has multiplier
                    extraMultiplier = equippedSpells[equippedSpells.length - 1].damageMultiplier
                  }

                  let cauthResourceCheck = checkClientsideResourceCost(player, playerResources, equippedSpell)
                  if (cauthResourceCheck) {
                    handleCauthMeleeShot(dir, finalSpellData, clickData, extraMultiplier)
                  }
                }
                handleShot(dir, spellID, spellSlot)
                executedShot = true
              }
                break
              case 'aura': {
                if (phaserData.game.hasFocus && finalSpellData.audio.cast) {
                  phaserData.sfx.play(finalSpellData.audio.cast)
                }
                callAnimation(finalSpellData, phaserData)
                let dir
                if (!IS_TOUCH) {
                  dir = getMouseAngle(phaserData, playerInfo)
                } else {
                  dir = angleToShot(player.angle)
                }
                handleShot(dir, spellID, spellSlot)
                executedShot = true
                break

              }
              default:
                callAnimation(finalSpellData, phaserData)
                let dir
                if (!IS_TOUCH) {
                  dir = getMouseAngle(phaserData, playerInfo)
                } else {
                  dir = angleToShot(player.angle)
                }
                handleShot(dir, spellID, spellSlot)
                executedShot = true
                break
            }
            if (executedShot) {
              assignedDict[equippedSpells[i].assignedKey].reload = true
              // Actions.updateSpells(assignedDict)
              if (equippedSpells[i].spellCooldown >= 1 || equippedSpells[i].resourceCost) {
                Actions.triggerReload(selectedKey, true)
              }
              assignedDict[equippedSpells[i].assignedKey].reload = false

              equippedSpells[i].cooldownCounter = (equippedSpells[i].spellCooldown)

              if (equippedSpells[i].type != 'movement') {
                if (equippedSpells[i].damage >= 0) {
                  globalSpellCooldown.value = globalSpellCooldown.baseValue
                }
              }
            }
          }
        } 
      }
    }
  }
}

export function calculateZoomFactor(display_X, display_Y) {
  let max_factor = 3000
  let min_res = Math.min(max_factor, Math.max(display_X, display_Y))
  let max_res = Math.max(display_X, display_Y)

  // higher number increases view area 
  // let SCALE_FACT = 232
  // let SCALE_FACT = 200


  // let SCALE_FACT = 230
  let SCALE_FACT = 215



  // let SCALE_FACT = 220
  // let SCALE_FACT = 222
  // lower numbers increase view area exponentially for bigger res screens
  let SCALE_OFFSET = .0000012
  let EXPONENTIAL_INCREASE = 0.9
  let MIN_ZOOM = 1

  let zoomScale
  zoomScale = (min_res / SCALE_FACT) - (min_res * (min_res * EXPONENTIAL_INCREASE) * SCALE_OFFSET)

  let zoomFactor
  zoomFactor = Math.max(MIN_ZOOM, zoomScale)
  // after a certain amount the zoom will start getting increased by a different stronger ratio that prevents zooming out
  if (min_res == max_factor) { zoomFactor = Math.max(max_res / 500, Math.max(MIN_ZOOM, zoomScale)) }

  // console.log(' zoom factor', zoomFactor)

  if (display_X <= 1200 || display_Y <= 1200) {
    zoomFactor -= 0.7
  }

  return zoomFactor

}

function checkBrowserDimensionChange(displayCoords) {
  // if (Math.floor(displayCoords.x) !=Math.floor(this.scale.displaySize.width) || Math.floor(displayCoords.y) != Math.floor(this.scale.displaySize.height)) {
  if (displayCoords.x !== window.innerWidth || displayCoords.y !== window.innerHeight) {
    console.log('browser dim changed', {
      x: displayCoords.x,
      width: window.innerWidth,
      y: displayCoords.y,
      height: window.innerHeight,
      scaleW: this.scale.displaySize.width,
      scaleH: this.scale.displaySize.height
    
    })
    return true
  }
}
function updateMapZoom(displayCoords) {
  let zoomFactor = calculateZoomFactor(window.innerWidth, window.innerHeight)
  this.cameras.main.zoomEffect.start(zoomFactor, 10)
  displayCoords.x = window.innerWidth
  displayCoords.y = window.innerHeight
  minimapManager.updateMinimapZoom()
}

function updateJoysticks(displayCoords) {
  let zoomFactor = calculateZoomFactor(this.scale.displaySize.width, this.scale.displaySize.height)
  let windowWidth = this.scale.displaySize.width
  let windowHeight = this.scale.displaySize.height

  joysticks.joystickL.x = (windowWidth / 2) - ((windowWidth * .2) / zoomFactor)
  joysticks.joystickL.y = (windowHeight / 2) + ((windowHeight * .15) / zoomFactor)
  joysticks.joystickL.radius = 60 / zoomFactor
  joysticks.joystickL.base.radius = 60 / zoomFactor
  joysticks.joystickL.thumb.radius = 30 / zoomFactor

  joysticks.joystickR.x = (windowWidth / 2) + ((windowWidth * .2) / zoomFactor)
  joysticks.joystickR.y = (windowHeight / 2) + ((windowHeight * .15) / zoomFactor)
  joysticks.joystickR.radius = 60 / zoomFactor
  joysticks.joystickR.base.radius = 60 / zoomFactor
  joysticks.joystickR.thumb.radius = 30 / zoomFactor

}

function calculateCurrentCircle(me, mapCenter) {
  let distanceFromCenter = distance(me, mapCenter)
  let currentCircle
  if (distanceFromCenter < circlePointDict['circlePoint1']['distance']) {
    currentCircle = 1
  }
  else if (distanceFromCenter < circlePointDict['circlePoint2']['distance']) {
    currentCircle = 2
  }
  else if (distanceFromCenter < circlePointDict['circlePoint3']['distance']) {
    currentCircle = 3
  }
  else { currentCircle = 4 }
  return currentCircle
}

function processTraps(spikeSwitch) {
  if (spikeSwitch === true) {
    this.spikeDict['spikesOff'].forEach(function (spikeSprite) {

      spikeSprite.setFrame(1)
    })
    this.spikeDict['spikesOn'].forEach(function (spikeSprite) {
      spikeSprite.setFrame(0)
    })
    this.spikeDict['spikeSwitch'] = false
  }

  else if (spikeSwitch === false) {
    this.spikeDict['spikesOff'].forEach(function (spikeSprite) {

      spikeSprite.setFrame(0)
    })
    this.spikeDict['spikesOn'].forEach(function (spikeSprite) {
      spikeSprite.setFrame(1)
    })
    this.spikeDict['spikeSwitch'] = true
  }

}

function processLavaPit(lavaPit) {
  // console.log('lava switch', lavaPit)
  if (lavaPit === true) {
    this.lavaDict['lavaOff'].forEach(function (lavaSprite) {

      lavaSprite.setFrame(1)
    })
    this.lavaDict['lavaOn'].forEach(function (lavaSprite) {
      lavaSprite.setFrame(0)
    })
    this.lavaDict['lavaPit'] = false
  }

  else if (lavaPit === false) {
    this.lavaDict['lavaOff'].forEach(function (lavaSprite) {

      lavaSprite.setFrame(0)
    })
    this.lavaDict['lavaOn'].forEach(function (lavaSprite) {
      lavaSprite.setFrame(1)
    })
    this.lavaDict['lavaPit'] = true
  }

}

export function processDirection(cursors, wasd) {
  let xMove = 0
  let yMove = 0
  if (cursors.down.isDown || wasd.down.isDown) {
    yMove += 1
  }
  if (cursors.up.isDown || wasd.up.isDown) {
    yMove -= 1
  }
  if (cursors.left.isDown || wasd.left.isDown) {
    xMove -= 1
  }
  if (cursors.right.isDown || wasd.right.isDown) {
    xMove = + 1
  }
  return {
    xMove: xMove, yMove: yMove
  }
}
export function processJoystickDirection(cursors) {
  let xMove = 0
  let yMove = 0
  if (cursors.down) {
    yMove += 1
  }
  if (cursors.up) {
    yMove -= 1
  }
  if (cursors.left) {
    xMove -= 1
  }
  if (cursors.right) {
    xMove = + 1
  }
  return {
    xMove: xMove, yMove: yMove
  }
}

function processMovement(movement, shiftActive, equippedSpells, dt) {

  // Did player move his position?
  let playerMovedPosition
  if (!(movement.xMove === 0 & movement.yMove === 0)) {
    playerMovedPosition = true
  }
  let moveDirection

  if (movement.xMove == 0 && movement.yMove == 0) {
    moveDirection = 0
  }
  else if (movement.xMove == 1 && movement.yMove == 0) {
    moveDirection = 1
  }
  else if (movement.xMove == 0 && movement.yMove == 1) {
    moveDirection = 2
  }
  else if (movement.xMove == -1 && movement.yMove == 0) {
    moveDirection = 3
  }
  else if (movement.xMove == 0 && movement.yMove == -1) {
    moveDirection = 4
  }
  else if (movement.xMove == -1 && movement.yMove == -1) {
    moveDirection = 5
  }
  else if (movement.xMove == 1 && movement.yMove == -1) {
    moveDirection = 6
  }
  else if (movement.xMove == -1 && movement.yMove == 1) {
    moveDirection = 7
  }
  else if (movement.xMove == 1 && movement.yMove == 1) {
    moveDirection = 8
  }

  // Did player move his angle?
  let playerMovedAngle
  if (player.prevAngle - player.angle !== 0) {
    playerMovedAngle = true
  }

  if (shiftActive.value) {
    // Movementattack
    let movementSpell = equippedSpells.find(spell => spell.type == 'movement')
    if (movementSpell) {
      if (movementSpell.cooldownCounter <= 0) {
        let dirRadians
        dirRadians = Phaser.Math.DEG_TO_RAD * player.angle
        handleInput(moveDirection, player.angle)
        fire(this, player, 'shift', equippedSpells, dirRadians)
        movementSpell.cooldownCounter = movementSpell.spellCooldown
      }
    } else {
      fire(this, player, 'shift', equippedSpells)
    }
    shiftActive.value = false

  }
  // If not dash, process normal movement
  else {
    if (Constants.NETWORKING == 'CAUTH' && playerCurrentUIState.value !== 'menu') {
      const movementDir = Constants.MOVEMENT_DIR_DICT[moveDirection]
      if (movementDir !== 'stop') {
        if (dt && playerSpeed.value) {
          const newX = dt * playerSpeed.value * Math.cos(movementDir)
          const newY = dt * playerSpeed.value * Math.sin(movementDir)
          if (!player.emittedDust) {
            setTimeout(() => {
              if (phaserObject.game.loop.actualFps > Constants.FPS_THRESHOLDS.LOW) {
                dustEmitter.explode(1, player.x, player.y)
              }
            }, 50)
            player.emittedDust = true
            setTimeout(() => {
              player.emittedDust = false
            }, 100)
          }

          player.x += newX
          player.y += newY
          updatePlayerLabels(player)
          player.collisionBody.setPosition(player.x, player.y)
          if (movementUpdateTimer > minimumMsForMovement) {
            handleCMovement([player.x, player.y])
            movementUpdateTimer = 0
          }
          const potentials = getPotentialMatches(collisions, player.collisionBody)
          for (let i = 0; i < potentials.length; i++) {
            const object = potentials[i]
            if (checkCollision(collisions, player.collisionBody, object)) {
              if (object.minLevel && level.value >= object.minLevel) {
              } else {
                if (!['bullet'].includes(object.objectType)) {
                  if (!(object.objectType === 'enemy' && object.isPet && !serverSettings.defaultPlayerOptions?.petCollisionList?.player)) {
                    handleBodyOverlap(player.collisionBody, collisions.response, collisions)

                    player.x = player.collisionBody.x
                    player.y = player.collisionBody.y
                  }
                } else {
                }
              }
            }
          }
        }
      }
    } else {
      handleInput(moveDirection, player.angle)
    }
  }
}

function updateZoneCountdown(zoneCountdown, prevZoneCountdown, minZone, level) {
  let flooredZoneCountdown = Math.floor(zoneCountdown)
  if (flooredZoneCountdown != prevZoneCountdown.value) {
    // Actions update zone countdown redux
    Actions.updateZoneCountdown(flooredZoneCountdown)
    prevZoneCountdown.value = flooredZoneCountdown
    if (flooredZoneCountdown == 0) {
      // console.log('updating new zone')
      minZone.value += 1
      while (level > this.gameApi.defaultOptions.zoneSettings.zoneLevels[minZone.value]) {
        minZone.value += 1
      }
      Actions.updateMinZone(minZone.value)
    }
  }
}
export function sendToMainMenu(){
  const {currentGame, token, playerDied, guestAccount, sdkLoaded, gameLoaded, authenticated, accountUsername, betaActive, alphaActive} = store.getState().stuff
  if (playerDied) {
    respawnMenuToMainMenu(currentGame, token, guestAccount)
  } else if (!getShouldShowGamePage(sdkLoaded, gameLoaded, authenticated, accountUsername, betaActive, alphaActive )){
    loadMenu(currentGame, token, guestAccount)
  } else {
    console.log('player is alive but did not press main menu button. Likely server closed connection')
    loadMenu(currentGame, token, guestAccount)
  }
}
let enteredUpdate = false
// export updateOnServerTicks() {
//
// }

let minimumMsForAngle = 35
let angleUpdateTimer = 0
let movementUpdateTimer = 0
let minimumMsForMovement = 20
export function update(time, dt) {

  angleUpdateTimer += dt
  movementUpdateTimer += dt
  dt = dt / 1000
  // console.log('params', {
  //   time: time, dt: dt
  // })
  if (!enteredUpdate) {
    enteredUpdate = true
    loadRemainingAssets.bind(this)()
  }
  // console.log('MOUSE POS', getMousePos(this))

  if (checkBrowserDimensionChange.bind(this)(displayCoords)) {
    console.log('window dimensions changed')
    updateMapZoom.bind(this)(displayCoords)
    // console.log('coords', {
    //   width: this.scale.displaySize.width,
    //   length: this.scale.displaySize.height
    // })
    // Actions.updateWidth(this.scale.displaySize.width)
    // Actions.updateHeight(this.scale.displaySize.height)
    Actions.updateWidth(window.innerWidth)
    Actions.updateHeight(window.innerHeight)
    if (IS_TOUCH) {
      updateJoysticks.bind(this)(displayCoords)
    }
  }
  // if (loaded.value) {
  if (mapManager.isMapLoaded()) {
    cullingManager.update()
    bigCullingManager.update()
  }



  if (loaded) {
    const { me, others, enemies, bullets, leaderboard, spikeSwitch, serverTime, pickups, lavaPit, eventCoords } = getCurrentState()
    if (me) {

      // console.log('fps', this.game.loop.actualFps)
      entityPoolManager.updateTimedPools(dt)
      
      if (angleUpdateTimer > minimumMsForAngle) {
      handleAngleUpdate(player.angle)
      angleUpdateTimer = 0
      }

      // console.log('nearby collisions', nearbyCollisions)
      if (process.env.NODE_ENV === 'development') {
        let nearbyCollisions
        // if (clientDebugOptions.collisionDraw) {
        nearbyCollisions = extractNearbyCollisionData(collisions, { x: player.x, y: player.y })
        updateDebug(nearbyCollisions, clientDebugOptions, 0x00FF00, 1)

        // } else {
        //   nearbyCollisions = {circles: [], polygons: []}
        // }
        // updateDebug(nearbyCollisions, clientDebugOptions, 0x00FF00)
      }

      resetTimer += dt   
      if (resetTimer > 5) {
        resetServerTimestamp()
        resetTimer = 0
      }

      if (eventCoords) {
        eventCoordsList = eventCoords
      }

      if (gameStarted.value) {
        this.sfx.stop('bgm')

        Actions.triggerGameStarted(true)
        gameStarted.value = false
      }

      // Update Timers
      updateTimeCalculations(timeCalculations, serverTime)

      if (me.zoneCountdown) {
        updateZoneCountdown(me.zoneCountdown, prevZoneCountdown, minZone, level.value)
      }
      if (me.level || me.expRatio) {
        console.log('level', me.level, 'expratio', me.expRatio)
        if (me.level != level || me.expRatio != EXPRatio.value) {
          // Update Level
          if (!me.expRatio) { me.expRatio = 0 }
          Actions.updateLevel(me.level, me.expRatio)
          if (me.level != level.value) {
            levelUpAnimation(player, this)
          }
          level.value = me.level
          EXPRatio.value = me.expRatio
        }
      }
      // console.log('position', player.x, player.y)
      if (me.statPoints) {
        // Update Stat Points
        Actions.updateStatPoints(me.statPoints)
      }
      if (me.stealth) { player.alpha = .5 } else { player.alpha = 1 }

      if (me.onWater && !player.onWater) {
        player.onWater = true
        rippleAnimation(player, this)
      } else if (!me.onWater && player.onWater) {
        player.onWater = false
        player.rippleAnim.stop()
        player.ripple.visible = false
        player.ripple.scale = 1
      }
      if (me.onLava && !player.onLava) {
        player.onLava = true
        lavaRippleAnimation(player, this)
      } else if (!me.onLava && player.onLava) {
        player.onLava = false
        player.lavaRippleAnim.stop()
        player.lavaRipple.visible = false
        player.lavaRipple.scale = 1
      }
      if (me.livePets) {
        player.livePets = me.livePets - 1
      }

      // If melee selected or deselected, toggle weapon
      if (+lastPressed == 1 && +currentlyPressed != 1 || +lastPressed != 1 && +currentlyPressed == 1) {
        console.log('TOGGLING WEAPON')
        handleSheatheWeapon(currentlyPressed)
        lastPressed = currentlyPressed
        if (player.playerWeapon) {
          toggleWeapon(player, this)
        }
      }

      // this is needed to equip weapon because generateStarterSpells is called before createPlayer when player starts.
      // would need to create a promise and deal with socketIO to fix this, so I can call equipWeapon() directly from equippedSpells.js
      // if (updatePlayerSprite['weapon'] !== 0) {
      //   // equipWeapon(player, updatePlayerSprite['weapon'].spellID, true, this)
      //   // if (updatePlayerSprite['weapon'].spellID != 12) {
      //   //   currentlyPressed == 1 ? drawWeapon(player, this) : sheatheWeapon(player, this)
      //   // }
      //   // updatePlayerSprite['weapon'] = 0
      //   // lastPressed = currentlyPressed
      //   //
      //   // minimapManager.showMinimap()
      // }

      // If player kills changed, update counter
      if (me.playerKills & me.playerKills != playerKills) {
        Actions.updatePlayerKills(me.playerKills)
        playerKills = me.playerKills
      }

      // Update Leaderboard + other things in this tick 
      if (leaderboard) {
        Actions.updateLeaderboard(leaderboard)
        // put distance calculation here so it's only done a couple times per second rather than each update
        currentCircle = calculateCurrentCircle(me, mapCenter)
        if (currentCircle != prevCircle) {
          prevCircle = currentCircle
          Actions.updateCircle(currentCircle)
        }
      }

      processTraps.bind(this)(spikeSwitch)
      processLavaPit.bind(this)(lavaPit)

      // Update entity groups
      updateGroup.bind(this)(this.otherPlayers, otherPlayersList, others, updateOtherPlayer, createOtherPlayer, destroyOtherPlayer)
      updateGroup.bind(this)(this.enemies, enemyList, enemies, updateEnemy, createEnemy, destroyEnemy)
      updateGroup.bind(this)(this.bullets, bulletList, bullets, updateBullet, createBullet, destroyBullet, true)
      updateGroup.bind(this)(this.pickups, pickupsList, pickups, updatePickup, createPickup, destroyPickup)

      // Update Player Values
      updateSpellCooldowns(equippedSpellsImport, globalSpellCooldown, timeCalculations.dtCalc, timeCalculations.dt)

      if (mobileFiring.value) {
        fire(this, player, currentlyPressed, equippedSpellsImport)
      }
      if (!IS_TOUCH && !angleLocked.value) {
        updatePlayerAngle.bind(this)(player)
      }

      if (Constants.NETWORKING == 'CAUTH') {
        me.x = player.x
        me.y = player.y

        if (Constants.TRACK_CLIENTSIDE_HP) {
          if (me.hp) {
            if (!player.clientHp) {
              player.clientHp = me.hp
            }
          }
          me.hp = player.clientHp
        }
      }






      updateValues(player, me, this)

      if (Constants.NETWORKING == 'CAUTH') {
        updateClientPhysicsAndHealth(me, dt)
        if (Constants.CLIENTSIDE_BULLETS) {
          updateClientBullets(dt)
        }
      }

      updateMinimapPosition(playerMinimapCircle, me)


      //test 
      // DISABLE PROCESS DIRECTION FUNCTION BELLOW, ENABLE THIS
      // let xTest = getRandomItem([-1,0,1])
      // let yTest = getRandomItem([-1, 0, 1])
      // let movement = {xMove: xTest, yMove: yTest}
      // if (testFiring) {
      //   fire(this, player, currentlyPressed, equippedSpells)
      //   playEmote(player, 0, {
      //     0: 1,
      //     1: 2,
      //     2: 3,
      //     3: 4,
      //   }, this)
      //   testFiring = false
      //   setTimeout(() => {
      //     testFiring = true
      //   },
      // 200)
      // }

      // Input Execution
      let movement
      if (!chatboxActive.value) {
        if (!IS_TOUCH) {
          movement = processDirection(cursors, this.wasd)
        } else {
          movement = processJoystickDirection(joysticks.joystickL)
        }
        processMovement.bind(this)(movement, shiftActive, equippedSpellsImport, dt)
      }
      // Reset DT for next loop
      timeCalculations.dt = 0
    }
  }
  else {
    if (!loaded) {
      // small transition during menu screen
      if (player) {
        player.x += Constants.START_MENU_X_SCROLL_SPEED
        if (player.x >= 9700) {
          player.x = Constants.GAME_MODE_MAP.find(mode => mode.gameName === Constants.GAME).startMenuCameraCoordinates[0]
        }
      }
    }
  }
}

function updateClientPhysicsAndHealth(me, dt) {

  if (Constants.TRACK_CLIENTSIDE_HP) {
    // health regen
    if (playerStats.value.healthRegen && player.clientHp < playerStats.value.health) {
      player.clientHp += playerStats.value.healthRegen * dt
      me.hp = player.clientHp
      if (player.clientHp > playerStats.value.maxHealth) {
        player.clientHp = playerStats.value.maxHealth
        me.hp = player.clientHp
      }
      updateValues(player, me, this)
    }
  }

  player.collisionBody.x = player.x
  player.collisionBody.y = player.y

  const potentials = getPotentialMatches(collisions, player.collisionBody)
  for (let i = 0; i < potentials.length; i++) {
    const object = potentials[i]
    if (object.deactivated) {
      continue
    }
    if (checkCollision(collisions, player.collisionBody, object)) {
      if (object.objectType === 'enemy' || !object.objectType) {
        if (!(object.isPet && !serverSettings.defaultPlayerOptions?.petCollisionList?.player)) {
          if (object.objectType === 'enemy') {
          handleBodyOverlap(player.collisionBody, collisions.response, collisions)
        }
          player.x = player.collisionBody.x
          player.y = player.collisionBody.y
          handleCMovement([player.x, player.y])
        }
      }
      else if (object.objectType === 'bullet' && !object.damageProcessed && !object.clientsideBullet && (!(serverSettings.defaultPlayerOptions?.petDeathBeforePlayerDamage && player.livePets !== 0) || object.damage < 0)) {
        if (!object.willCharge || object.willCharge && object.charged) {
          if (!object.isFriendly) {
            if (Constants.TRACK_CLIENTSIDE_HP) {
              // handle bullet hit
  
              player.clientHp -= object.damage
              me.hp = player.clientHp
  
  
              updateValues(player, me, this)
            }
            handleTakeDamage(object.damage, object.objectId)
            object.damageProcessed = true
          } else if (object.levelDiffFlag) {
            handleTakeDamage(object.damage, object.objectId, true)
            object.damageProcessed = true
          } else if (object.damage < 0){
            // console.log('[DEBUG] friendly healing bullet')
            // friendly healing spell
            if (Constants.TRACK_CLIENTSIDE_HP) {
              player.clientHp -= object.damage
              me.hp = player.clientHp
              updateValues(player, me, this)
            }
            handleTakeDamage(object.damage, object.objectId)
            object.damageProcessed = true
          }
        
        }

      }
    }
  }
}