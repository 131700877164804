import Constants from '../shared/constants'

import { player } from './create'
import { currentCircle, eventCoordsList } from './game'
import {PointerManager} from './PointerManager'
import {MinimapManager} from "./MinimapManager";
// console.log('pointer manager object', PointerManager)
// setTimeout(
//     () => {console.log('pointer manager object 2', PointerManager)}
// , 5000)
export let pointerManager

export let eventContainer
let phaserObject
let mainGame
export let minimapManager

export let playerCurrentUIState = { value: 'mainGame' }// 'mainGame', 'offworld', 'menu'
export function handlePlayerOffworld(isOffworld) {
    if (isOffworld) {
        playerCurrentUIState.value = 'offworld'
        pointerManager.hidePointers()
    } else {
        playerCurrentUIState.value = 'mainGame'
    }
}

export function preloadUI() {
    this.load.image('eventMarker', 'assets/phaserUI/events_exclamation.png', { frameWidth: 22, frameHeight: 22 })
    this.load.image('eventPointer', 'assets/phaserUI/events_pointer.png', { frameWidth: 22, frameHeight: 22 })
    this.load.image('eventRectangle', 'assets/phaserUI/events_square.png', { frameWidth: 22, frameHeight: 22 })
    this.load.image('dungeonGate', 'assets/phaserUI/dungeon_gate.png', { frameWidth: 22, frameHeight: 22 })
}

export function handleTrialNotification(coordinates) {
    pointerManager.createPointer('dungeonGate', {
        'despawnTime': Constants.TRIAL_DEFAULT_PILLAR_SPAWN_TIME * 1000,
        'fixedCoords': { x: coordinates[0], y: coordinates[1] }
    })
}

export function createUI() {
    mainGame = this.scene.get('GameScene')
    phaserObject = this
    pointerManager = new PointerManager(this, 'GameScene')
    pointerManager.createPointer('eventMarker', {name: 'event'}, false, true)
    pointerManager.hidePointers()
    minimapManager = new MinimapManager(this)
}

export function updateUI() {

    if (playerCurrentUIState.value == 'mainGame') {
        for (const [key, value] of Object.entries(pointerManager.namedPointers)) {
            if (key !== 'event') {
                pointerManager.updateNamedPointer(key, player, value.fixedCoords)
            }
        }
        if (player && eventCoordsList && currentCircle && eventCoordsList[currentCircle - 1] && eventCoordsList[currentCircle - 1] != null) {
            let eventCoords = { x: eventCoordsList[currentCircle - 1][0], y: eventCoordsList[currentCircle - 1][1] }
            pointerManager.updateNamedPointer('event', player, eventCoords)
        }

        if (player) {
            pointerManager.updateFixedPointers(player)
        }
    }
}
