import React, { Component } from 'react'
import { Grid, Box } from '@mui/material'
import { ButtonBase } from '@mui/material'
import { connect } from 'react-redux'
import { Paper } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import LinearProgress from '@mui/material/LinearProgress'
import { IconButton } from '@mui/material'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import Constants from '../../shared/constants'
import {baseUrl, domainName, EnemyConstants} from '../index'
import { AchievementsData } from '../../shared/achievements'
import Typography from '@mui/material/Typography'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Slide from '@mui/material/Slide'
import ItemReward from './ItemReward.js'

import { toggleChallengeMenu } from '../actions/actions'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />
})

import * as Actions from '../actions/actions'

import ColorChangingChallengeButton from './ColorChangingChallengeButton.js'

import {bigScreenBorderProperties, colorPalette} from './uiStyles.js'
import {CosmeticsData} from "../../shared/cosmetics/cosmetics";
import ColorTable from "../../shared/colorTable";
import {styled} from "@mui/material/styles";



const StyledLinearProgress = styled(LinearProgress)({
    backgroundColor: '#362D44', // Color for the background
    '& .MuiLinearProgress-bar': {
        backgroundColor: '#0A83D4', // Color for the bar
    },
});

class Challenges extends Component {
    constructor() {
        super()
        this.state = {
            maxChallengesPerPage: 5,
            dialog: false,
            dialogNewItem: false,
            itemEarnedPath: 'assets/emotes/smile.png',
            itemEarned: {
                id: 1,
                name: 'Thumbs Up',
                imgPath: 'thumb_up.png',
                rarity: 'common',
                unlockRequirement: null,
                free: true,
                type: 'emote'
            },
            itemEarnedMessage: 'Legendary Emote',
            page: 0,
            enemyTable: [{
                id: 7,
                name: '',
                path: 'assets/gifs/woodland_idle.gif',
                type: 'air',
                weakness: 'fire',
                tip: 'Don\'t get too close to its multi attacks!'
            }],
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { accountKillStats } = this.props
        if (prevProps.accountKillStats != accountKillStats) {
            this.componentDidMount()
        }
    }


    componentDidMount() {
        let { accountKillStats } = this.props

    }

    increasePage() {
        let { page, maxChallengesPerPage } = this.state
        if (page + 1 < (AchievementsData.length / maxChallengesPerPage)) {
            this.setState({
                page: page + 1
            })
        } else {
            this.setState({
                page: 0
            })
        }
    }
    decreasePage() {
        let { page, maxChallengesPerPage } = this.state
        if (page - 1 >= 0) {
            this.setState({
                page: page - 1
            })
        } else {
            this.setState({
                page: Math.floor(AchievementsData.length / maxChallengesPerPage)
            })
        }
    }

    calculateTotalMonsterLogCompletes(killStats, threshold) {
        let monsterLogCompletes = 0
        Object.entries(killStats).forEach(([key, val]) => {
            // exclude chests
            if (key < 100) {
                // console.log("DICT KEY", key)
                let enemyStats = EnemyConstants.find(enemy => enemy.enemyID == key)
                let killThresholds
                // if (enemyStats && )
                if (enemyStats && enemyStats.boss) {
                    killThresholds = Constants.MONSTER_JOURNAL_THRESHOLDS.boss
                } else {
                    killThresholds = Constants.MONSTER_JOURNAL_THRESHOLDS.normal
                }
                if (val >= killThresholds[threshold]) {
                    monsterLogCompletes += 1
                }
            }
        })

        return monsterLogCompletes
    }

    validateChallenge(challenge, accountKillStats, accountStats, accountChallenges, joinedDiscord, accountLevel) {
        let progress
        let status
        let backgroundColor = 'rgb(0,0,0,0)'
        if (challenge.type == 'monsterJournal') {
            if (challenge.type2 == 'single') {
                let enemyStats = EnemyConstants.find(enemy => enemy.enemyID == challenge.enemyId)
                let killThresholds
                let reqKills
                if (challenge.type3 == 'specific') {
                    killThresholds = challenge.type4
                    reqKills = challenge.type4
                }
                else if (enemyStats.boss) {
                    killThresholds = Constants.MONSTER_JOURNAL_THRESHOLDS.boss
                    reqKills = killThresholds[challenge.type3]
                } else {
                    killThresholds = Constants.MONSTER_JOURNAL_THRESHOLDS.normal
                    reqKills = killThresholds[challenge.type3]
                }
                let currentKills = accountKillStats[challenge.enemyId]
                if (!currentKills) { currentKills = 0 }
                progress = `${currentKills}/${reqKills}`
                if (currentKills >= reqKills) {
                    status = 'claim'
                    backgroundColor = 'green'
                }
                else {
                    status = 'unearned'
                }
            }
            else if (challenge.type2 == 'multi') {
                let currentProgress = this.calculateTotalMonsterLogCompletes(accountKillStats, challenge.type3)
                // console.log("CURRENT COMPLETES", currentCompletes)
                let requiredProgress = challenge.type4
                progress = `${currentProgress}/${requiredProgress}`
                if (currentProgress >= requiredProgress) {
                    status = 'claim'
                    backgroundColor = 'green'
                }
                else {
                    status = 'unearned'
                }
            }
        }

        else if (challenge.type == 'stats') {
            if (challenge.type2 != 'totalScore') {
                progress = `${accountStats[challenge.type2]}/${challenge.type3}`
            }
            else {
                progress = `${Math.floor(accountStats[challenge.type2] / 1000)}k/${challenge.type3 / 1000}k`

            }
            if (accountStats[challenge.type2] >= challenge.type3) {
                status = 'claim'
            }
            else {
                status = 'unearned'
            }
        }

        else if (challenge.type == 'accountLevel') {
            progress = `${accountLevel}/${challenge.levelNeeded}`
            if (accountLevel >= challenge.levelNeeded) {
                status = 'claim'
            } else {
                status = 'unearned'
            }
        }

        else if (challenge.type == 'pass') {
            if (challenge.type2 == 'discord') {
                if (joinedDiscord) {
                    progress = '1/1'
                    status = 'claim'
                    backgroundColor = '#ffd16c'
                }
                else {
                    status = 'unearned'
                    progress = '0/1'
                    backgroundColor = '#fff1d2'
                }
            }
        }

        if (accountChallenges.includes(challenge.id)) {
            status = 'claimed'
            backgroundColor = '#fde4a1'
        }

        return {
            progress: progress,
            status: status,
            // backgroundColor: backgroundColor
        }

    }

    render() {

        const handleClickOpen = () => {
            toggleChallengeMenu(true)
            // this.setState({ 'dialog': true })

        }
        const handleClose = () => {
            toggleChallengeMenu(false)
            // this.setState({ 'dialog': false }) 

        }

        const handleCloseNewItem = () => { this.setState({ 'dialogNewItem': false }) }

        let manaColorTable = ColorTable.elementHex
        let gridStyle = {
            marginBottom: '5px',
        }
        let { enemyTable, page, dialog, dialogNewItem, maxChallengesPerPage, itemEarnedPath, itemEarned, itemEarnedMessage } = this.state
        let { accountKillStats, accountStats, accountChallenges, token, guestAccount, challengeDialog, joinedDiscord, accountLevel, height } = this.props


        let dialogHeight

        if (height < 560) {
            if (maxChallengesPerPage != 3) {
                this.setState({ maxChallengesPerPage: 3 })
            }
            // dialogHeight = '396px'
            dialogHeight = '350px'
        } else if (height < 630) {
            if (maxChallengesPerPage != 4) {
                this.setState({ maxChallengesPerPage: 4 })
            }
            dialogHeight = '471px'
        } else {
            if (maxChallengesPerPage != 5) {
                this.setState({ maxChallengesPerPage: 5 })
            }
            dialogHeight = '547px'
        }

        AchievementsData.forEach(challenge => {
            let { progress, status } = this.validateChallenge(challenge, accountKillStats, accountStats, accountChallenges, joinedDiscord, accountLevel)
            challenge.progress = progress
            challenge.status = status
        })

        // Sort by claimable first
        let sortedAchievementsData = AchievementsData.filter(x => x.status == 'claim').concat(
            AchievementsData.filter(x => x.status != 'claim')
        )

        let challengeWaiting = false

        var challengeList = sortedAchievementsData.slice(page * maxChallengesPerPage, maxChallengesPerPage * (page + 1))

        let challengeGrid = challengeList.map((challenge, index) => {
            let { progress, status } = challenge
            let reward
            let rewardImgPath
            let borderColor
            let scale = 'scale(1)'
            let backgroundColor
            let top = '0px'
            if (challenge.reward.type == 'head') {
                reward = CosmeticsData.heads.find(head => head.id == challenge.reward.id)
                rewardImgPath = `assets/player/heads/${reward.imgPath}`
                borderColor = ColorTable.rarityInfo[reward.rarity].loadoutBorder
                top = '-10px'
            }
            else if (challenge.reward.type == 'emote') {
                reward = CosmeticsData.emotes.find(emote => emote.id == challenge.reward.id)
                rewardImgPath = `assets/emotes/${reward.imgPath}`
                borderColor = ColorTable.rarityInfo[reward.rarity].loadoutBorder
            }
            else if (challenge.reward.type == 'coins') {
                rewardImgPath = 'assets/ui/rune.png'
                borderColor = ColorTable.rarityInfo['rare'].loadoutBorder
                scale = 'scale(0.65)'
            }
            else if (challenge.reward.type == 'gems') {
                rewardImgPath = 'assets/ui/glyph.png'
                borderColor = ColorTable.rarityInfo['legendary'].loadoutBorder
                scale = 'scale(0.65)'
            }

            switch (status) {
                case 'claim':
                    challengeWaiting = true
                    backgroundColor = '#ffd16c'
                    break
                case 'claimed':
                    backgroundColor = '#fde4a1'
                    break
                default:
                    backgroundColor = colorPalette.primary
                    break
            }


            return (
                <Grid container xs={12}
                    style={{
                        marginBottom: '1%',
                        // minHeight: '89px'
                    }}
                >


                    <Grid
                        style={{
                            background: backgroundColor,
                            borderRadius: '3px',
                            borderWidth: '2px', borderStyle: 'solid',
                            borderColor: '#362D44',
                            boxShadow: '0 0 0 2px #FFFFFF',
                            // borderRadius: '4px'
                        }}

                        container xs={12}>

                        <Grid xs={6} style={{ margin: 'auto' }}>
                            {challenge.title}
                        </Grid>

                        <Grid xs={2} style={{ margin: 'auto' }}>
                            {progress}
                        </Grid>

                        <Grid xs={2} style={{ margin: 'auto' }}>
                            <ButtonBase style={{
                                // background: backgroundColor,
                                // borderRadius: '15px'

                                // background: backgroundColor,
                                borderRadius: '15px',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                borderColor: borderColor,
                                boxShadow: '0 0 0 2px #C79B7B',

                                width: '67px',
                                height: '67px',
                                position: 'relative',
                            }}>

                                <img style={{
                                    position: 'relative', pointerEvents: 'none',
                                    width: '67px',
                                    margin: 'auto',
                                    transform: scale,
                                    top: top,
                                }}
                                    src={rewardImgPath} />
                                {challenge.reward.type == 'emote' &&
                                    <img style={{
                                        position: 'absolute',
                                        width: '35px',
                                        left: '-20%',
                                        top: '-20%',
                                        pointerEvents: 'none',
                                        margin: 'auto',
                                        zIndex: 10,
                                    }}


                                        // src='assets/emotes/smile.png'
                                        src='assets/ui/icon_emote.png'

                                    />
                                }

                                {challenge.reward.type == 'head' &&
                                    <img style={{
                                        position: 'absolute',
                                        width: '35px',
                                        left: '-20%',
                                        top: '-20%',
                                        pointerEvents: 'none',
                                        margin: 'auto',
                                        zIndex: 10,
                                    }}


                                        // src='assets/emotes/smile.png'
                                        src='assets/ui/icon_head.png'

                                    />
                                }
                                {/* 
                                <div style={{
                                    position: 'absolute',
                                    // width: '33px',
                                    // left: '-15%',
                                    // top: '-22%',
                                    pointerEvents: 'none',
                                    // margin: 'auto',
                                    zIndex: 10,
                                }}
                                >
                                    abc
                           

                                </div> */}


                                {(challenge.reward.type == 'coins' || challenge.reward.type == 'gems') &&
                                    <div style={{
                                        position: 'absolute',
                                        // width: '33px',
                                        // left: '-15%',
                                        // top: '-22%',

                                        right: '5%',
                                        bottom: '1%',


                                        pointerEvents: 'none',
                                        // margin: 'auto',
                                        zIndex: 10,

                                        color: 'white',
                                        fontSize: '20px',
                                        textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
                                    }}
                                    >
                                        {/* abc */}
                                        {challenge.reward.quantity}

                                    </div>
                                }

                            </ButtonBase>
                        </Grid>

                        <Grid

                            container justifyContent="center" direction="column"

                            xs={2}


                        style={{ margin: 'auto', height: '100%' }}>
                        {status == 'claimed' &&
                            // <div>
                            //     Claimed
                            // </div>
                            <ButtonBase
                                style={{
                                    // top: '13px',
                                }}
                            >
                                <img style={{
                                    position: 'relative', pointerEvents: 'none',
                                    width: '52px',
                                    margin: 'auto',
                                }}
                                    src='assets/ui/checkmark.png' />
                            </ButtonBase>
                        }
                        {status == 'claim' &&
                            <div
                                style={{
                                    width: '85%',
                                    height: '70%',
                                    pointerEvents: 'auto'
                                }}
                                onClick={() => {
                                    fetch(`${baseUrl}/account/claimChallenge`, {
                                        method: 'POST',
                                        credentials: 'include',
                                        headers: {
                                            Accept: 'application/json',
                                            'Content-Type': 'application/json',
                                            'Access-Control-Allow-Credentials': true
                                        },
                                        body: JSON.stringify({
                                            challengeId: challenge.id,
                                            token: token,
                                            //  guestToken: guestToken,
                                            guest: guestAccount
                                        })
                                    })
                                        .then(response => {
                                            if (response.status === 200) {
                                                // console.log("RESPONSE", response)
                                                return response.json().then(user => {
                                                    console.log('USER RESPONSE', user)
                                                    let accountItems = user.accountItems
                                                    let challenges = user.accountChallenges
                                                    let rewardEarned = user.itemEarned
                                                    Actions.updateAccountItems(accountItems)
                                                    Actions.updateAccountChallenges(challenges)

                                                        Actions.updateCoins(user.coins)
                                                        Actions.updateGems(user.gems)

                                                        let itemEarned = user.itemEarned

                                                        let reward
                                                        let rewardImgPath
                                                        let itemMessage


                                                        if (itemEarned.type == 'head') {
                                                            reward = CosmeticsData.heads.find(head => head.id == challenge.reward.id)
                                                            reward.type = 'head'
                                                            rewardImgPath = `assets/player/heads/${reward.imgPath}`
                                                            itemMessage = `${reward.rarity} Skin`
                                                        }
                                                        else if (itemEarned.type == 'emote') {
                                                            reward = CosmeticsData.emotes.find(emote => emote.id == challenge.reward.id)
                                                            reward.type = 'emote'
                                                            rewardImgPath = `assets/emotes/${reward.imgPath}`
                                                            itemMessage = `${reward.rarity} Emote`
                                                        }

                                                        else if (itemEarned.type == 'coins') {
                                                            reward = { rarity: 'rare', quantity: rewardEarned.quantity, type: 'coins' }
                                                            rewardImgPath = 'assets/ui/rune.png'
                                                            itemMessage = `${reward.quantity} Runes`
                                                        }
                                                        else if (itemEarned.type == 'gems') {
                                                            reward = { rarity: 'legendary', quantity: rewardEarned.quantity, type: 'gems' }
                                                            rewardImgPath = 'assets/ui/glyph.png'
                                                            itemMessage = `${reward.quantity} Glyphs`
                                                        }

                                                        this.setState({
                                                            itemEarnedPath: rewardImgPath,
                                                            itemEarned: reward,
                                                            itemEarnedMessage: itemMessage,
                                                            dialogNewItem: true
                                                        })
                                                        // if (itemEarned.type) == 'emote' {

                                                        // }
                                                    })
                                                }
                                            })
                                    }}
                                    class='shineIcon'
                                    >
                                    <ButtonBase
                                        style={{
                                            zIndex: 9999999,
                                            // width: '100%',
                                            // height: '100%',

                                            width: '93%',
                                            height: '90%',
                                            margin: '3px',

                                            borderRadius: '7px',
                                            borderWidth: '2px',
                                            borderStyle: 'solid',
                                            boxShadow: '0 0 0 2px #C79B7B',
                                            // width: '85%',
                                            // height: '70%',
                                            background: '#50c96a',
                                            // top: '10px',
                                        }}
                                        onMouseOver={() => {
                                        }}
                                        onMouseOut={() => {
                                        }
                                        }

                                    >
                                        Claim
                                    </ButtonBase>
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Grid >
            );
        })


        return <>
            <Box component="span"
                style={{
                    minWidth: '200px',
                    maxWidth: '200px'
                }}
            >
                <ColorChangingChallengeButton buttonFunction={handleClickOpen}
                    config={
                        {
                            idle: {
                                fillColor: '#7C4E4D',
                                outerBorderColor: '#362D44',
                                innerBorderColor: '#543131',
                            },
                            hover: {
                                fillColor: '#CC7A82',
                                outerBorderColor: '#362D44',
                                innerBorderColor: '#AA5561',
                            },
                            down: {
                                fillColor: '#CC7A82',
                                outerBorderColor: '#362D44',
                                innerBorderColor: '#AA5561',
                            },
                        }

                    }
                    title='Challenges'
                    buttonStyling={{
                        marginTop: '10px', marginRight: '5px',
                        color: 'white',
                        // width: '70%',
                        padding: '1%',
                        // minWidth: '200px',
                        // maxWidth: '200px'
                        width: '100%'
                    }}
                    challengeWaiting={challengeWaiting}
                />

                {/* <img
                        style={{
                            pointerEvents: 'none',
                            position: 'relative',
                            left: '-69.5px',
                            bottom: '-6px'
                            // top: '129px',
                            // position: 'absolute'
                        }}

                        src="assets/ui/icon_challenges.png" /> */}

            </Box>







            <Dialog
                maxWidth={'md'}
                style={{
                    padding: '10px',

                }}
                onClose={handleClose} aria-labelledby="simple-dialog-title"
                // open={dialog}
                open={challengeDialog}
            // open={true}
            >
                <IconButton
                    style={{
                        alignSelf: 'flex-end',
                        marginTop: -5,
                        position: 'absolute'
                    }}
                    onClick={handleClose}
                    size="large">
                    <CancelPresentationIcon style={{ fontSize: '3rem' }} />
                </IconButton>

                <Paper align='center' justify="center" alignItems="center" direction="row"
                    style={{
                        background: colorPalette.primary,
                        borderColor: colorPalette.primaryBorder,
                        ...bigScreenBorderProperties
                    }}>
                    <Grid container
                        align='center' justifyContent="center" alignItems="center" direction="row"
                        style={{
                            marginBottom: '1%',
                        }}>

                        <Grid
                            container xs={12} style={{
                                paddingBottom: '2px'
                            }}>

                            <Grid xs={2}
                                style={{ margin: 'auto', position: 'relative' }}
                            >
                                <b
                                    style={Object.assign({
                                        color: 'black',
                                        borderWidth: '2px', borderStyle: 'solid',
                                        borderColor: '#FFFFFF',
                                        boxShadow: '0 0 0 2px #362D44',
                                        borderBottomRightRadius: '5px',
                                        borderTopRightRadius: '0px',
                                        borderTopLeftRadius: '5px',
                                        borderBottomLeftRadius: '0px',
                                        left: '-2px',
                                        bottom: '-7px',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        position: 'absolute',
                                        backgroundColor: '#dbbb83',
                                        minWidth: '100px',


                                    },
                                        // invertedInnerScreenBorderProperties
                                    )}
                                > Page {page + 1}/{Math.ceil(sortedAchievementsData.length / maxChallengesPerPage)}</b>
                            </Grid>
                            <Grid xs={8}>
                                {/* <h1><b> Challenges </b></h1> */}
                                <div
                                    style={{
                                        paddingTop: '10px',
                                        fontSize: '19px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    <b

                                    > CHALLENGES </b>
                                </div>
                            </Grid>
                            <Grid xs={2}></Grid>
                        </Grid>
                        <Grid xs={12} style={{ marginBottom: '1%' }}>
                            Challenge Progress
                        </Grid>
                        <Grid xs={12} style={{ marginBottom: '2%' }}>

                            <Box display="flex" alignItems="center"
                                style={{ marginLeft: '20%', marginRight: '20%' }}
                            >
                                <Box width="100%" mr={1}>
                                    <StyledLinearProgress variant="determinate"
                                        // value={enemy.progressRatio}
                                        value={(accountChallenges.length / AchievementsData.length) * 100}
                                        style={{
                                            minHeight: '20px', borderRadius: '3px',
                                            borderWidth: '2px', borderStyle: 'solid', borderColor: 'black',
                                        }} />
                                    <Typography variant="body2" color="textSecondary" style={{
                                        position: 'absolute',
                                        color: 'white',
                                        marginTop: '-21.5px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',

                                    }}>
                                        {accountChallenges.length} / {AchievementsData.length}
                                        {/* {enemy.kills}/{enemy.nextAchievement} */}

                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>


                        <Grid xs={2}>
                            <IconButton
                                style={{
                                }}
                                onClick={this.decreasePage.bind(this)}
                                size="large">
                                <KeyboardArrowLeftIcon
                                    style={{ fontSize: '3rem' }}
                                />
                            </IconButton>
                        </Grid>


                        <Grid
                            container

                            xs={8}>
                            <Grid style={{
                                marginBottom: '20px',

                            }}
                                container xs={12}>
                                <Grid xs={6}>
                                    Challenge
                                </Grid>
                                <Grid xs={2}>
                                    Progress
                                </Grid>
                                <Grid xs={2}>
                                    Reward
                                </Grid>
                                <Grid xs={2}>
                                    Status
                                </Grid>
                            </Grid>

                            <Grid xs={12}
                                // style={{ minHeight: '426px' }}
                                style={{ minHeight: { dialogHeight } }}
                            >
                                {challengeGrid}
                            </Grid>
                        </Grid>
                        <Grid
                            xs={2}>
                            <IconButton
                                style={{
                                    // position: 'absolute'
                                }}
                                onClick={this.increasePage.bind(this)}
                                size="large">
                                <KeyboardArrowRightIcon style={{ fontSize: '3rem' }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Dialog>


            <ItemReward
                dialogNewItem={dialogNewItem}
                handleCloseNewItem={handleCloseNewItem}
                itemEarned={itemEarned}
                itemEarnedMessage={itemEarnedMessage}
                itemEarnedPath={itemEarnedPath}
            />

        </>;
    }
}


function mapStateToProps(state) {
    return {
        accountKillStats: state.stuff.accountKillStats,
        accountStats: state.stuff.accountStats,
        token: state.stuff.token,
        guestAccount: state.stuff.guestAccount,
        accountChallenges: state.stuff.accountChallenges,
        challengeDialog: state.stuff.challengeDialog,
        joinedDiscord: state.stuff.joinedDiscord,
        accountLevel: state.stuff.accountLevel,
        height: state.stuff.height
    }
}

export default connect(
    mapStateToProps,
)(Challenges)