"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChestsData = exports.currencyTypes = exports.conversionDict = exports.itemTypes = exports.conversionPrices = void 0;
exports.conversionPrices = {
    eventEmotes: {
        coins: 10,
        gems: 5
    },
    eventSkins: {
        coins: 10,
        gems: 5
    },
    common: {
        coins: 10,
        gems: 5
    },
    uncommon: {
        coins: 20,
        gems: 10
    },
    rare: {
        coins: 30,
        gems: 15
    },
    epic: {
        coins: 40,
        gems: 20
    },
    legendary: {
        coins: 60,
        gems: 30
    }
};
exports.itemTypes = [
    {
        type: 'head',
        chestKey: 'heads',
        accountItemKey: 'heads',
        eventKey: 'eventSkins'
    },
    {
        type: 'emote',
        chestKey: 'emotes',
        accountItemKey: 'emotes',
        eventKey: 'eventEmotes'
    },
];
exports.conversionDict = {};
exports.itemTypes.forEach(itemType => {
    exports.conversionDict[itemType.type] = itemType.accountItemKey;
});
exports.currencyTypes = ['coins', 'gems'];
const baseChestItems = {
    heads: [
        2, 3, 4, 6,
        //  7,
        9, 10, 11, 12,
        14, 15, 16, 18,
        // 19,
        21, 22, 23, 24, 25,
        //   26, 
        //   27,
        28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
        //    39,
        40, 41, 42, 43,
        //    44,
        45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61,
        // 62, 
        63,
        // 64,
        65, 66, 68, 69, 70, 71, 72, 73, 74,
        //  75,
        76, 77, 78,
        //    80,
        81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223,
        // 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243
        244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301, 302,
        303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314,
    ],
    emotes: [
        6, 7,
        8, 9, 10,
        11, 14, 12, 13,
        52, 53, 54,
        55, 56,
        // 57,
        58, 59, 60, 61
        // 42, 43, 44, 45, 46, 47, 48, 49
    ]
};
const odds = {
    smallChest: {
        common: 0.68,
        uncommon: 0.25,
        rare: 0.062,
        epic: 0.005,
        legendary: 0.003,
        eventEmotes: 0,
        eventSkins: 0,
    },
    mediumChest: {
        common: 0.5,
        uncommon: 0.325,
        rare: 0.125,
        epic: 0.04,
        legendary: 0.01,
        eventEmotes: 0,
        eventSkins: 0,
    },
    largeChest: {
        common: 0.30,
        uncommon: 0.35,
        rare: 0.20,
        epic: 0.10,
        legendary: 0.05,
        eventEmotes: 0,
        eventSkins: 0,
    },
    glowingChest: {
        common: 0.28,
        uncommon: 0.25,
        rare: 0.22,
        epic: 0.15,
        legendary: 0.10,
        eventEmotes: 0,
        eventSkins: 0,
    }
};
exports.ChestsData = [
    {
        id: 1,
        name: 'Spooky Chest',
        currencyType: 'coins',
        price: 100,
        itemsToReward: 1,
        allowRepeats: true,
        odds: {
            common: 0.35,
            uncommon: 0.30,
            rare: 0.09,
            epic: 0.05,
            legendary: 0.01,
            eventEmotes: 0.10,
            eventSkins: 0.10,
        },
        items: {
            heads: [
                2, 3, 4, 6,
                //  7,
                9, 10, 11, 12,
                14, 15, 16, 18,
                //  19, 
                21, 22, 23, 24, 25,
                //  26, 27,
                28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
                //    39,
                40, 41,
                42, 43,
                //  44, 
                45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61,
                //  62,
                63,
                //   64, 
                65, 66, 68, 69, 70, 71, 72, 73, 74,
                //    75,
                76, 77, 78,
                //  80, 
                81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223,
                228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243
            ],
            emotes: [
                6, 7,
                8, 9, 10,
                11, 14, 12, 13,
                42, 43, 44, 45, 46, 47, 48, 49
            ]
        }
    },
    {
        id: 2,
        name: 'Spooky Chest',
        currencyType: 'gems',
        price: 50,
        itemsToReward: 1,
        allowRepeats: true,
        odds: {
            common: 0.35,
            uncommon: 0.30,
            rare: 0.09,
            epic: 0.05,
            legendary: 0.01,
            eventEmotes: 0.10,
            eventSkins: 0.10,
        },
        items: {
            heads: [
                2, 3, 4, 6,
                //  7,
                9, 10, 11, 12,
                14, 15, 16, 18,
                // 19,
                21, 22, 23, 24, 25,
                //  26, 27, 
                28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
                //   39,
                40, 41, 42, 43,
                //  44,
                45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61,
                //   62,
                63,
                //    64, 
                65, 66, 68, 69, 70, 71, 72, 73, 74,
                // 75,
                76, 77, 78,
                //   80,
                81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223,
                228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243
            ],
            emotes: [
                6, 7,
                8, 9, 10,
                11, 14, 12, 13,
                42, 43, 44, 45, 46, 47, 48, 49
            ]
        }
    },
    {
        id: 3,
        name: 'Glowing Spooky Chest',
        currencyType: 'coins',
        price: 250,
        itemsToReward: 2,
        allowRepeats: true,
        odds: {
            common: 0.23,
            uncommon: 0.20,
            rare: 0.10,
            epic: 0.08,
            legendary: 0.04,
            eventEmotes: 0.15,
            eventSkins: 0.20,
        },
        items: {
            heads: [
                2, 3, 4, 6,
                //  7,
                9, 10, 11, 12,
                14, 15, 16, 18,
                // 19,
                21, 22, 23, 24, 25,
                //   26,
                //    27, 
                28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
                //    39,
                40, 41, 42, 43,
                //    44,
                45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61,
                // 62,
                63,
                //   64, 
                65, 66, 68, 69, 70, 71, 72, 73, 74,
                //    75, 
                76, 77, 78,
                // 80,
                81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223,
                228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243
            ],
            emotes: [
                6, 7,
                8, 9, 10,
                11, 14, 12, 13,
                42, 43, 44, 45, 46, 47, 48, 49
            ]
        }
    },
    {
        id: 4,
        name: 'Glowing Spooky Chest',
        currencyType: 'gems',
        price: 125,
        itemsToReward: 2,
        allowRepeats: true,
        odds: {
            common: 0.23,
            uncommon: 0.20,
            rare: 0.10,
            epic: 0.08,
            legendary: 0.04,
            eventEmotes: 0.15,
            eventSkins: 0.20,
        },
        items: {
            heads: [
                2, 3, 4, 6,
                //  7,
                9, 10, 11, 12,
                14, 15, 16, 18,
                // 19,
                21, 22, 23, 24, 25,
                //  26,
                //   27, 
                28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
                //    39,
                40, 41, 42, 43,
                //   44,
                45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61,
                //    62,
                63,
                // 64,
                65, 66, 68, 69, 70, 71, 72, 73, 74,
                //  75,
                76, 77, 78,
                //    80, 
                81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223,
                228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243
            ],
            emotes: [
                6, 7,
                8, 9, 10,
                11, 14, 12, 13,
                42, 43, 44, 45, 46, 47, 48, 49
            ]
        }
    },
    {
        id: 5,
        name: 'Common Chest',
        currencyType: 'coins',
        price: 60,
        itemsToReward: 1,
        allowRepeats: true,
        odds: odds.smallChest,
        items: baseChestItems
    },
    {
        id: 6,
        name: 'Common Chest',
        currencyType: 'gems',
        price: 30,
        itemsToReward: 1,
        allowRepeats: true,
        odds: odds.smallChest,
        items: baseChestItems
    },
    {
        id: 7,
        name: 'Medium Chest',
        currencyType: 'coins',
        price: 110,
        itemsToReward: 2,
        allowRepeats: true,
        odds: odds.mediumChest,
        items: baseChestItems
    },
    {
        id: 8,
        name: 'Medium Chest',
        currencyType: 'gems',
        price: 55,
        itemsToReward: 2,
        allowRepeats: true,
        odds: odds.mediumChest,
        items: baseChestItems
    },
    {
        id: 9,
        name: 'Large Chest',
        currencyType: 'gems',
        price: 100,
        itemsToReward: 4,
        allowRepeats: true,
        odds: odds.largeChest,
        items: baseChestItems
    },
    {
        id: 10,
        name: 'Glowing  Chest',
        currencyType: 'gems',
        price: 190,
        itemsToReward: 8,
        allowRepeats: true,
        odds: odds.glowingChest,
        items: baseChestItems
    },
    {
        id: 11,
        name: 'Elf Chest',
        currencyType: 'coins',
        // price: 115,
        price: 100,
        itemsToReward: 1,
        allowRepeats: true,
        odds: {
            // common: 0,
            // uncommon: 0.4,
            // rare: 0.4,
            // epic: 0.15,
            // legendary: 0.05,
            // eventEmotes: 0,
            // eventSkins: 0,
            common: 0,
            uncommon: 0.35,
            rare: 0.35,
            epic: 0.2,
            legendary: 0.1,
            eventEmotes: 0,
            eventSkins: 0,
        },
        items: {
            heads: [
                213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223,
                244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301,
                302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314,
            ],
            emotes: [
                55, 56,
                //  57,
                58, 59, 60, 61
            ]
        }
    },
    {
        id: 12,
        name: 'Elf Chest',
        currencyType: 'gems',
        price: 70,
        itemsToReward: 2,
        allowRepeats: true,
        odds: {
            common: 0,
            uncommon: 0.35,
            rare: 0.35,
            epic: 0.2,
            legendary: 0.1,
            eventEmotes: 0,
            eventSkins: 0,
        },
        items: {
            heads: [
                213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223,
                244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301,
                302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314,
            ],
            emotes: [
                55, 56,
                // 57, 
                58, 59, 60, 61
            ]
        }
    },
];
