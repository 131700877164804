import React from 'react'
import ColorChangingIcon from '../ColorChangingIcon.js'
import { iconStyle, iconStyleSmall } from '../uiStyles.js'
import SettingsIcon from '@mui/icons-material/Settings'
function IconSettings(props) {

    let chosenIconStyle
    if (props.buttonSize == 'small') {
        chosenIconStyle = iconStyleSmall
    } else {
        chosenIconStyle = iconStyle
    }



    return <ColorChangingIcon
        buttonFunction={props.buttonFunction}
        config={{
            idle: {
                fillColor: '#7C4E4D',
                outerBorderColor: '#362D44',
                innerBorderColor: '#543131',
            },
            hover: {
                fillColor: '#CC7A82',
                outerBorderColor: '#362D44',
                innerBorderColor: '#AA5561',
            },
            down: {
                fillColor: '#CC7A82',
                outerBorderColor: '#362D44',
                innerBorderColor: '#AA5561',
            }
        }}
        buttonStyling={chosenIconStyle}
        IconImage={SettingsIcon}
    />
}

export default (IconSettings)