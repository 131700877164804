import Constants from '../shared/constants'
import {
    circlePointDict,
    mapCenter,
    calculateZoomFactor,
    shiftActive,
    currentlyPressed,
    fire,
    calculateScaleFactor,
    collisions,
    mobileFiring,
    setCurrentlyPressed
} from './game.js'
import { equippedSpells } from './equippedSpells.js'
import { createPlayer, updateHandPosition } from './playerScripts.js'
import {username, toggleMute, domainInfo, updateGameConstants, currentGame} from './index'
export let player
export let playerSpeed = {value: 120}
export let playerStats = {value: {
        'health': 100,
        'healthRegen': 0,
        'defense': 0,
        'mana': 100,
        'manaRegen': 0,
    }}
export let playerMinimapCircle
export let displayCoords = {}
export let cursors
export let phaserObject
export let eventIndicator
import {
    updateSettings,
    addLoadProgress,
    updateLoadProgress,
    mapLoadComplete,
    setGame,
    setSingleGame, setTitleLoaded, resetGlobalChat
} from './actions/actions'
import {minimapManager, pointerManager} from "./uiScene";
import {CullingManager} from "./CullingManager";
import {MapManager} from "./MapManager";
import {EntityPoolManager} from "./EntityPoolManager";
import {createCircle, createPolygon, createBox, createPolygonFromPoints, getPolygonArray} from "../shared/collisions";
import {degreesToRadians, rotate} from "../server/utils";
// const mapJSON = require(`../../public/assets/games/${Constants.GAME}/map/map.json`)

export let settings
export let joysticks = {}

export let cullingManager = new CullingManager(Constants.CULLING_GRID_RANGE)
export let bigCullingManager = new CullingManager(Constants.BIG_CULLING_GRID_RANGE)
export let mapManager
export let objectDict
export let spikeDict
export let lavaDict
export let entityPoolManager
let loaderProgress = 0
let minimapProgress = 0
let mapProgress = 0

function createGridlines() {
    // Create gridlines

    // let line
    // let xMapLength = Constants.MAP_SIZE_X * 2
    // let yMapLength = Constants.MAP_SIZE_Y * 2
    // let lineColor = '808080'
    // let lineAlpha = 0.3
    // let lineDepth = 0
    // let intervalLength = 150
    // let lineWidth = 0.2


    // for (let i = 0; i < Constants.MAP_SIZE_X / intervalLength; i++) {
    //     let xValue = i * intervalLength
    //     line = this.add.line(lineWidth, lineWidth, xValue, 0, xValue, yMapLength, lineColor, lineAlpha)
    //     line.setLineWidth(lineWidth)
    //     line.setDepth(lineDepth)
    //     this.minimap.ignore(line)
    // }

    // for (let i = 0; i < Constants.MAP_SIZE_Y / intervalLength; i++) {
    //     let yValue = i * intervalLength
    //     line = this.add.line(lineWidth, lineWidth, 0, yValue, xMapLength, yValue, lineColor, lineAlpha)
    //     line.setLineWidth(lineWidth)
    //     line.setDepth(lineDepth)
    //     this.minimap.ignore(line)
    // }

}

export function create() {
    phaserObject = this

    this.input.setDefaultCursor('url(assets/ui/cursor.png), pointer');
    let gameName = domainInfo.gameName

    mapManager = new MapManager(phaserObject)
    entityPoolManager = new EntityPoolManager(phaserObject)
    // createBox(collisions, 10428, 1443, 100,100)
    // createBox(collisions, 10428, 1443, 300,100, {angle: 90})
    // createPolygon(collisions,10428,1443, [[0,0],[0,100],[100,100],[100,0]], {rotation: 0})
    // createPolygon(collisions,10428,1443, [[0,0],[0,100],[100,100],[100,0]], {rotation: 0})
    // createCircle(collisions, 10428, 1443, 15)
    // let videoSettings
    try {
        if (localStorage.hasOwnProperty('settings') && (localStorage.getItem('settings') !== 'null') && (localStorage.getItem('settings') !== '')) {
            settings = JSON.parse(localStorage.getItem('settings'))
            // console.log('test', settings.settings)

            // for (let [key, value] of Object.entries(settings.videoSettings)) {
            //     updateSettings({ settingType: 'videoSettings', key: key, value: value })
            // }
        }
        else {
            localStorage.setItem('settings', JSON.stringify(
                {
                    videoSettings: {
                        'shadows': true,
                        'smallDecorations': true,
                        'smudges': true
                    }
                }
            ))
            settings = JSON.parse(localStorage.getItem('settings'))
        }
    }
    catch (err) {
        settings = {
            videoSettings: {
                'shadows': true,
                'smallDecorations': true,
                'smudges': true
            }
        }

    }

    for (let [key, value] of Object.entries(settings.videoSettings)) {
        updateSettings({ settingType: 'videoSettings', key: key, value: value })
    }

    try {

        if (localStorage.hasOwnProperty('mute') && (localStorage.getItem('mute') == 'true')) {
            toggleMute()
            // console.log('mute read, toggling mute', localStorage.getItem("mute"))
        }
        else {
            // console.log('no mute val read', localStorage.getItem("mute"))
        }
    } catch (err) { }

    // this.input.setDefaultCursor('url(../../../public/assets/ui/crosshair_2.png), crosshair')
    // minimapManager.createMinimap()
    let windowWidth = window.innerWidth
    let windowHeight = window.innerHeight
    // let scaleFactor = calculateScaleFactor(window.innerWidth, window.innerHeight)
    // // create map
    // let minimapWidth = 200 * scaleFactor
    // let minimapHeight = 200 * scaleFactor
    // let minimapMarginX = windowWidth * 0.005
    // let minimapMarginY = windowHeight * 0.005
    // this.minimap = this.cameras.add(minimapMarginX, windowHeight - minimapMarginY - minimapHeight, minimapWidth, minimapHeight).setZoom(0.25).setName('mini')


    console.log('calling create player')
    player = createPlayer(player, username, this, true)
    player.clientPlayer = true
    player.weaponDrawn = true
    console.log('created player', player)
    if (Constants.NETWORKING == 'CAUTH') {
        player.collisionBody = createCircle(collisions, player.x, player.y, 8)
        player.collisionBody.objectType = 'player'
    }

    // playerMinimapCircle = this.add.circle(0, 0, 20, 0xFFFF00)
    // playerMinimapCircle.setStrokeStyle(2, 0x000000)
    // playerMinimapCircle.setDepth(5)


    // this.cameras.main.ignore(playerMinimapCircle)

    /// canvas stuff
    displayCoords.x = this.scale.displaySize.width
    displayCoords.y = this.scale.displaySize.height
    let zoomFactor = calculateZoomFactor(displayCoords.x, displayCoords.y)
    this.cameras.main.setZoom(zoomFactor)

    // this.minimap.visible = false
    minimapManager.ignoreEntity(player)
    minimapManager.ignoreEntity(player.healthBar.bar)
    minimapManager.ignoreEntity(player.manaBar.bar)
    minimapManager.ignoreEntity(player.usernameText)

    // let rect = this.add.rectangle(minimapMarginX, this.scale.displaySize.height - minimapMarginY - minimapHeight, minimapWidth, minimapHeight, 0x1a65ac)
    // rect.setScrollFactor(0, 0)
    // rect.setStrokeStyle(5, 0x1a65ac).setDepth(10)


    this.cameras.main.startFollow(player)
    // this.minimap.startFollow(player, true)
    minimapManager.startMinimap(player)

    this.objectDict = mapManager.createMap(this, settings.videoSettings, 'mainportal', true)
    this.spikeDict = this.objectDict['spikeDict']
    this.lavaDict = this.objectDict['lavaDict']

    objectDict = this.objectDict
    spikeDict = this.spikeDict
    lavaDict = this.lavaDict

    // let lineThickness = 4

    // let circle1 = this.add.circle(circlePointDict['circlePoint1'].x, circlePointDict['circlePoint1'].y, circlePointDict['circlePoint1'].distance, 0x008000)
    // circle1.fillAlpha = 0
    // circle1.setStrokeStyle(lineThickness, 0x008000)
    //
    // let circle2 = this.add.circle(circlePointDict['circlePoint2'].x, circlePointDict['circlePoint2'].y, circlePointDict['circlePoint2'].distance, 0xffa500)
    // circle2.fillAlpha = 0
    // circle2.setStrokeStyle(lineThickness, 0xffa500)
    //
    // let circle3 = this.add.circle(circlePointDict['circlePoint3'].x, circlePointDict['circlePoint3'].y, circlePointDict['circlePoint3'].distance, 0xFF0000)
    // circle3.fillAlpha = 0
    // circle3.setStrokeStyle(lineThickness, 0xFF0000)

    this.otherPlayers = this.physics.add.group()

    this.enemies = this.physics.add.group()

    this.pickups = this.physics.add.group()


    cursors = this.input.keyboard.createCursorKeys()

    this.input.mouse.disableContextMenu()

    this.bullets = this.physics.add.group()
    this.bullets.enableBody = false

    this.clientsideBullets = this.physics.add.group()
    this.clientsideBullets.enableBody = false


    player.shadow.destroy()
    let playerShadow = this.add.circle(0, 0, 29, 0x000000)
    playerShadow.tint = Constants.SHADOW_TINT
    playerShadow.alpha = Constants.SHADOW_ALPHA

    playerShadow.x = playerShadow.x + 2
    playerShadow.y = playerShadow.y + 2
    playerShadow.setScale(0.25)
    player.shadow = playerShadow
    player.shadow.visible = false

    windowWidth = this.scale.displaySize.width
    windowHeight = this.scale.displaySize.height


    this.input.addPointer(1)

    var joystickL = this.plugins.get('rexVirtualJoystick').add(this, {
        x: (windowWidth / 2) - ((windowWidth * .2) / zoomFactor),
        y: (windowHeight / 2) + ((windowHeight * .15) / zoomFactor),
        radius: 60 / zoomFactor,
        base: this.add.circle(0, 0, 60 / zoomFactor, 0x888888, 0.5),
        thumb: this.add.circle(0, 0, 30 / zoomFactor, 0xcccccc, 0.3),

        forceMin: 0,
    })
    joystickL.base.setDepth(Constants.DEPTHS.joystick)
    joystickL.thumb.setDepth(Constants.DEPTHS.joystick)
    joystickL.dir = '8dir'


    var joystickR = this.plugins.get('rexVirtualJoystick').add(this, {
        x: (windowWidth / 2) + ((windowWidth * .2) / zoomFactor),
        y: (windowHeight / 2) + ((windowHeight * .15) / zoomFactor),
        radius: 60 / zoomFactor,
        base: this.add.circle(0, 0, 60 / zoomFactor, 0x888888, 0.5),
        thumb: this.add.circle(0, 0, 30 / zoomFactor, 0xcccccc, 0.3),
        forceMin: 0,
    })
    joystickR.base.setDepth(Constants.DEPTHS.joystick)
    joystickR.thumb.setDepth(Constants.DEPTHS.joystick)

    joystickL.visible = false
    joystickL.enable = false

    joystickR.visible = false
    joystickR.visible = false

    joysticks.joystickL = joystickL
    joysticks.joystickR = joystickR

    joystickL.on('update', function () {
        // if (joystickL.force >= 75 / zoomFactor) {
        //     // if (equippedSpells[4]) {
        //         shiftActive.value = true
        //         shiftActive.keySlot = 'shift'
        //     // }
        // }
    })

    joystickR.on('update', function () {
        player.angle = joystickR.angle
        if (joystickR.force >= 60 / zoomFactor) {
            mobileFiring.value = true
            // fire(phaserObject, player, currentlyPressed, equippedSpells)
        } else {
            mobileFiring.value = false
        }
    })
    joystickR.on('pointerUp', function () {
        mobileFiring.value = false
    })

    setTimeout(() => {
        // phaserObject.load.off('fileprogress', fileProgressListener)
        mapLoadComplete(true)
        cullingManager.forceUpdate()
        bigCullingManager.forceUpdate()
    }, 200)
    resetPortalCameraPosition(gameName)

}
export function resetPortalCameraPosition(gameName){
    console.log('finding portal gamename', gameName)
    player.x = Constants.GAME_MODE_MAP.find(mode => mode.gameName === gameName).startMenuCameraCoordinates[0]
    player.y = Constants.GAME_MODE_MAP.find(mode => mode.gameName === gameName).startMenuCameraCoordinates[1]
}
// function onTemplateLoaded(gameName, portal) {
//     phaserObject.objectDict = mapManager.createMap(phaserObject, settings.videoSettings, gameName, portal)
//     phaserObject.spikeDict = phaserObject.objectDict['spikeDict']
//     phaserObject.lavaDict = phaserObject.objectDict['lavaDict']
//     if (minimapManager){
//         if (minimapManager.minimap) {
//             minimapManager.changeMinimap(gameName)
//         } else {
//             minimapManager.createMinimap(gameName)
//         }
//     }  else {
//         console.log('TNOTREACHED. resetGameObjects minmapManager does not exist')
//         // minimapManager not initialized, which should have been. TNOTREACHED
//     }
//     player.x = Constants.GAME_MODE_MAP.find(mode => mode.gameName === gameName).startMenuCameraCoordinates[0]
//     player.y = Constants.GAME_MODE_MAP.find(mode => mode.gameName === gameName).startMenuCameraCoordinates[1]
// }
export function resetGameObjects(gameName = 'mainportal', portal = false,){
    mapLoadComplete(false)
    bigCullingManager.removeAllEntities()
    cullingManager.removeAllEntities()

    setCurrentlyPressed('1')
    resetGlobalChat()
    console.log('resetting game objects', pointerManager)
    pointerManager.hidePointers()

    updateHandPosition(player, 'default')

    if (phaserObject && phaserObject.objectDict) {phaserObject.objectDict.length = 0}
    if (phaserObject && phaserObject.spikeDict) {phaserObject.spikeDict.length = 0}
    if (phaserObject && phaserObject.lavaDict) {phaserObject.lavaDict.length = 0}

    if (mapManager && mapManager.phaserData && mapManager.phaserData.map){
        mapManager.removeMap()
    }
    function onTemplateLoaded() {
        phaserObject.objectDict = mapManager.createMap(phaserObject, settings.videoSettings, gameName, portal)
        phaserObject.spikeDict = phaserObject.objectDict['spikeDict']
        phaserObject.lavaDict = phaserObject.objectDict['lavaDict']
        if (minimapManager){
            if (minimapManager.minimap) {
                minimapManager.changeMinimap(gameName)
            } else {
                minimapManager.createMinimap(gameName)
            }
        }  else {
            console.log('TNOTREACHED. resetGameObjects minmapManager does not exist')
            // minimapManager not initialized, which should have been. TNOTREACHED
        }
        console.log("ON TEMPLATE LOADED")
        setTimeout(() => {
            phaserObject.load.off('fileprogress', fileProgressListener)
            mapLoadComplete(true)
            cullingManager.forceUpdate()
            bigCullingManager.forceUpdate()
            console.log("MAP LOAD COMPLETE")
        }, 200)
    }
    let portalPrefix = ''
    if (portal) {
        portalPrefix = 'portal'
    }
    function fileProgressListener(file, percentComplete) {

        let valueToAdd = 0
        if(file.type === "tilemapJSON") {
            valueToAdd = Math.floor((percentComplete)*30) - Math.floor(mapProgress*30)
        } else if (file.type ==="image"){
            valueToAdd = Math.floor((percentComplete)*30) - Math.floor(minimapProgress*30)
        }
        if (valueToAdd < 1) {
            return
        } else {
            console.log("Map Loading Progress", file.key,', percent: ', percentComplete)
            let val = 0
            let interval = setInterval(() => {
                val += 1
                addLoadProgress(1)
                if (val >= valueToAdd) {
                  clearInterval(interval)
                }
            }, 20)
            loaderProgress += valueToAdd

            if(file.type === "tilemapJSON") {
                mapProgress = percentComplete
            } else if (file.type ==="image"){
                minimapProgress = percentComplete
            }
            console.log("INCREASING LOAD PROGRESS BY: ", valueToAdd, "Total Map Loading Progress: ", loaderProgress)
        }
    }
    if (!phaserObject.load.cacheManager?.tilemap?.has(`${portalPrefix}map${gameName}`)){
        let path
        if (portal){
            path = `assets/mainPortal/map/${gameName}${Constants.MAP_JSON_NAME}`
        } else {
            path = `assets/games/${gameName}/map/${Constants.MAP_JSON_NAME}`
        }
        phaserObject.load.tilemapTiledJSON(`${portalPrefix}map${gameName}`, path)
        if (gameName !== 'mainportal' && !portal) {
            if (!phaserObject.load.textureManager?.exists(`minimap${gameName}`)){
                phaserObject.load.image(`minimap${gameName}`,`assets/games/${gameName}/map/map.jpg`)
            }
        }
        mapProgress = 0
        minimapProgress = 0
        loaderProgress = 0

        phaserObject.load.on('fileprogress', fileProgressListener)

        phaserObject.load.once('complete', onTemplateLoaded)
        // phaserObject.load.once('complete', (gameName, portal) => {onTemplateLoaded(gameName, portal)})
        phaserObject.load.start()
    } else {
        onTemplateLoaded()
        // onTemplateLoaded(gameName, portal)

    }


}