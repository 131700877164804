import { EnemyConstants } from './index'
import { Pickups } from './index';
import Constants from '../shared/constants'

import {phaserObject, player, entityPoolManager} from "./create.js";
import {
    levelUpAnimation,
    playEmoteOther,
    playChat,
    rippleAnimation,
    lavaRippleAnimation,
    createPlayer,
    punch,
    tweenWeapon,
    tweenDaggerWeapon,
    updateValues,
    equipWeapon,
    toggleWeapon,
    equipBodyWear,
    equipTopWear,
    equipHandWear,
    equipHead,
    castSpellAnim,
    setRank,
    createFloatingNumber,
    setIcon, tweenRotation, tweenScale
} from './playerScripts.js'
import { HealthBar } from './prefabs/HealthBar.js'
import {  settings } from './create.js'
import {minimapManager} from "./uiScene";
import {createCircle} from "../shared/collisions";
import {collisions} from "./game";
import {hexTo0x} from "./phaserUtils";
import ColorTable from "../shared/colorTable";
import {dustEmitter, emitter} from "./MapManager";
export const hackyMeleeBulletsList = []
export function updateGroup(groupList, currentEntityList, updateData, updateEntity, createEntity, destroyEntity, isBullet) {
    let entityChecker = []
    if (updateData.length > 0) {
        for (let index = 0; index < updateData.length; ++index) {
            let entityInfo = updateData[index]
            entityChecker.push(entityInfo.id)

            // if not added, add
            if (!(currentEntityList.includes(entityInfo.id))) {
                createEntity.bind(this)(entityInfo, currentEntityList)
            }
            // else update information
            else {
                let entityToUpdate = groupList.getChildren().find(entity => entity.id == entityInfo.id)
                updateEntity.bind(this)(entityToUpdate, entityInfo)
            }
        }
    }
    // if no longer exists, remove
    for (let index = 0; index < currentEntityList.length; ++index) {
        if (!(entityChecker.includes(currentEntityList[index]))) {
            let entityToDelete = groupList.getChildren().find(entity => entity && entity.id === currentEntityList[index])
            if (entityToDelete) {
                    if (isBullet){
                        destroyEntity.bind(this)(entityToDelete, currentEntityList)
                    }
                    else {
                        let entityArraySpot = currentEntityList.findIndex(entityID => entityID == entityToDelete.id)
                        if (entityArraySpot !== -1) {
                            currentEntityList.splice(entityArraySpot, 1)
                            destroyEntity.bind(this)(entityToDelete)
                        } else {
                            console.log('entity not found in currentEntityList', entityToDelete)
                        }
                    }
            } else {
                let id = JSON.stringify(currentEntityList[index])
                let entityList = JSON.stringify(groupList.getChildren().map(entity => entity.id))
                console.log('finding entity to destroy failed. entity id: ', id, 'entityGroupList:', entityList)
            }
        }
    }
}

export function createOtherPlayer(playerInfo, idList) {
    const otherPlayer = createPlayer(playerInfo, playerInfo.username, this)

    otherPlayer.id = playerInfo.id

    equipBodyWear(otherPlayer, playerInfo.bodyWear)
    equipHandWear(otherPlayer, playerInfo.handWear)
    equipTopWear(otherPlayer, playerInfo.topWear)
    equipHead(otherPlayer, playerInfo.head)
    equipWeapon(otherPlayer, playerInfo.weapon, playerInfo.weaponDrawn, this)
    setRank(otherPlayer, playerInfo.rankId)

    this.otherPlayers.add(otherPlayer)

    otherPlayer.list.forEach(sprite => {
        minimapManager.ignoreEntity(sprite)
    })
    minimapManager.ignoreEntity(otherPlayer.healthBar.bar)
    minimapManager.ignoreEntity(otherPlayer.manaBar.bar)
    minimapManager.ignoreEntity(otherPlayer.usernameText)
    minimapManager.ignoreEntity(otherPlayer.shadow)
    if (Constants.NETWORKING == 'CAUTH') {
        otherPlayer.collisionBody = createCircle(collisions, playerInfo.x, playerInfo.y, playerInfo.radius)
        otherPlayer.collisionBody.objectType = 'otherPlayer'
        otherPlayer.collisionBody.playerId = playerInfo.id
    }
    idList.push(playerInfo.id)
}

export function updateOtherPlayer(otherPlayer, playerInfo) {
    if ((otherPlayer.x !== playerInfo.x) || (otherPlayer.y !== playerInfo.y)) {
        if (!otherPlayer.emittedDust) {
            setTimeout(() => {
                if (phaserObject.game.loop.actualFps > Constants.FPS_THRESHOLDS.LOW) {
                    dustEmitter.explode(1, otherPlayer.x, otherPlayer.y)
                }
            }, 50)
            otherPlayer.emittedDust = true
            setTimeout(() => {
                otherPlayer.emittedDust = false
            }, 100)
        }
        var playerDir = Phaser.Math.Angle.Between(otherPlayer.x, otherPlayer.y, playerInfo.x, playerInfo.y)
        if (playerDir < 0) {
            playerDir = playerDir + (2 * Math.PI)
        }
        playerDir = Phaser.Math.RAD_TO_DEG * playerDir
    }
    else {
        playerDir = null
    }

    otherPlayer.angle = playerInfo.angle

    updateValues(otherPlayer, playerInfo, phaserObject)

    if (playerInfo.weapon != otherPlayer.weaponData.spellID) {
        equipWeapon(otherPlayer, playerInfo.weapon, playerInfo.weaponDrawn, this)
    }
    if (playerInfo.bodyWear != otherPlayer.bodyWearData.id) {
        equipBodyWear(otherPlayer, playerInfo.bodyWear)
    }
    if (playerInfo.handWear != otherPlayer.handWearData.id) {
        equipHandWear(otherPlayer, playerInfo.handWear)
    }
    if (playerInfo.topWear != otherPlayer.topWearData.id) {
        equipTopWear(otherPlayer, playerInfo.topWear)
    }
    if (otherPlayer.playerWeapon && otherPlayer.weaponDrawn != playerInfo.weaponDrawn) {
        toggleWeapon(otherPlayer, this)
    }

    if (playerInfo.onWater && !otherPlayer.onWater) {
        otherPlayer.onWater = true
        rippleAnimation(otherPlayer, this)
    } else if (!playerInfo.onWater && otherPlayer.onWater) {
        otherPlayer.onWater = false
        otherPlayer.rippleAnim.stop()
        otherPlayer.ripple.visible = false
        otherPlayer.ripple.scale = 1
    }

    if (playerInfo.onLava && !otherPlayer.onLava) {
        // console.log("ON LAVA!!")
        otherPlayer.onLava = true
        lavaRippleAnimation(otherPlayer, this)
    } else if (!playerInfo.onLava && otherPlayer.onLava) {
        otherPlayer.onLava = false
        otherPlayer.lavaRippleAnim.stop()
        otherPlayer.lavaRipple.visible = false
        otherPlayer.lavaRipple.scale = 1
    }

    if (!otherPlayer.handlingAttackAnim) {
        if (playerInfo.melee) {
            if (playerInfo.weapon == 12) {
                punch.bind(this)(otherPlayer, this)
                otherPlayer.handlingAttackAnim = true
            }
            else if (otherPlayer.weaponData.tweenType == 'dagger') {
                tweenDaggerWeapon(otherPlayer, otherPlayer.weaponData, this)
            }
            else {
                tweenWeapon(otherPlayer, otherPlayer.weaponData, this)
            }
            otherPlayer.handlingAttackAnim = true
            setTimeout(function () { otherPlayer.handlingAttackAnim = false }, 100)
        }
        if (playerInfo.cast) {
            castSpellAnim.bind(this)(otherPlayer, this)
            otherPlayer.handlingAttackAnim = true
            setTimeout(function () { otherPlayer.handlingAttackAnim = false }, 100)
        }
    }
    if (playerInfo.leveledUp) {
        console.log('playing level up anim')
        levelUpAnimation(otherPlayer, this)
        otherPlayer.leveledUp = false
    }
    if (playerInfo.emote) {
        playEmoteOther(otherPlayer, playerInfo.emote, phaserObject)
    }

    if (playerInfo.chatMessage) {
        if (playerInfo.chatMessage) {
            playChat(otherPlayer, playerInfo.chatMessage, this)
        }
    }
    if (Constants.NETWORKING == 'CAUTH') {
        otherPlayer.collisionBody.setPosition(playerInfo.x, playerInfo.y)
    }
}

export function destroyOtherPlayer(otherPlayer) {
    otherPlayer.healthBar.bar.destroy()
    otherPlayer.manaBar.bar.destroy()
    otherPlayer.usernameText.destroy()

    if (otherPlayer.chatTimeout) {
        clearTimeout(otherPlayer.chatTimeout)
    }
    otherPlayer.chatText.destroy()
    otherPlayer.chatRectangle.destroy()

    if (otherPlayer.rank.frame && otherPlayer.rank.frame.name) {
        let rankIcon = otherPlayer.rank
        otherPlayer.rank.setActive(false)
        otherPlayer.rank.setVisible(false)
        entityPoolManager.addObjectToPool('rankIcon', otherPlayer.rank.frame.name, rankIcon)
    } else {
        // clean up in case icon cannot be pooled
        console.log('destroying rank icon without name')
        otherPlayer.rank.destroy()
    }
    // enemy.rank.destroy()
    otherPlayer.rankCircle.destroy()
    delete otherPlayer.rank
    delete otherPlayer.rankCircle
    // otherPlayer.rank.destroy()
    // otherPlayer.rankCircle.destroy()

    otherPlayer.shadow.destroy()

    if (Constants.NETWORKING == 'CAUTH') {
        collisions.remove(otherPlayer.collisionBody)
    }
    otherPlayer.destroy()
}


export function createEnemy(enemyInfo, idList) {
    let enemyData = EnemyConstants.find(enemy => enemy.enemyID == enemyInfo.enemyID)
    let enemy
    let shadowRadius = enemyData.radius * 4
    let shadow


    if (enemyData.spine) {
        let spineEnemy = entityPoolManager.getObjectFromPool('spineEnemies', enemyData.spriteName)
        if (spineEnemy){
            enemy = spineEnemy
            enemy.setActive(true)
            enemy.setVisible(true)
        } else {
            let atlasName = `${enemyData.spriteName}_atlas`
            enemy = this.add.spine(enemyInfo.x, enemyInfo.y, enemyData.spriteName, atlasName)
            enemy.animationState.setAnimation(0, 'idle', true)
            enemy.spriteName = enemyData.spriteName
        }
        // console.log('adding enemy', enemyData)
        // console.log('spine game object', enemy)
        if (enemyData.spineSkin) {
            console.log('skin list', enemy.getSkinList())
            console.log('set skin function1', enemy.setSkin)
            console.log('set skin funct2', enemy.setSkinByName)
            // enemy.customParams = {
            //     skin: 0,
            //     animation: 0,
            //     attachment: 0
            // }
            enemy.setSkin(null)
            enemy.setSkinByName(enemyData.spineSkin)
            // enemy.setSkin(enemyData.spineSkin)
        }
        if (enemyData.boss) {
            enemy.boss = true
        } else {
            enemy.boss = false
        }

        enemy.attackComplete = true

        enemy.spine = true
        enemy.currentState = 1

        if (enemyData.spineTwoAttacks) { enemy.spineTwoAttacks = true } else { enemy.spineTwoAttacks = false }
    }

    else {
        let cachedEnemy = entityPoolManager.getObjectFromPool('enemies', enemyData.imgName)
        if (cachedEnemy){
            enemy = cachedEnemy
            enemy.setActive(true)
            enemy.setVisible(true)
        } else {
            enemy = this.add.sprite(enemyInfo.x, enemyInfo.y, 'mainAssets', enemyData.imgName)
            enemy.imgName = enemyData.imgName
        }
        // if (enemyData.isChest) {

        //     let enemyScaleRatio = ((Math.log(enemyInfo.radius + 4) * 2.5) / 22) * (64 / enemy.width) * 2

        //     if (shadow) {
        //         shadow.setScale(enemyScaleRatio)
        //     }
        // }
    }
    let enemyScaleRatio =  (enemyData.radius / 22) * (64 / enemy.width)

    if (!enemyData.isChest) {

        shadowRadius = enemyInfo.radius * 4

        if (settings.videoSettings.shadows !== false) {
            if (enemy.shadow) {
                enemy.shadow.setRadius(shadowRadius)
            } else {
                shadow = this.add.circle(0, 0, shadowRadius, 0x000000).setScale(0.25)
            }
        }

    }
    else {
        let enemyScaleRatio = ((Math.log(enemyInfo.radius + 4) * 2.5) / 22) * (64 / enemy.width) * 2

        if (settings.videoSettings.shadows !== false && !enemy.shadow) {
            if (!enemy.shadow) {
                shadow = this.add.sprite(enemyInfo.x, enemyInfo.y, 'mainAssets', enemyData.imgName)
                shadow.tint = Constants.SHADOW_TINT
                if (shadow) {
                    shadow.setScale(enemyScaleRatio)
                }
            } else {
                enemy.shadow.setScale(enemyScaleRatio)
            }
        }
    }
    // shadow.alpha = 0.3

    if (!enemy.shadow) {
        enemy.shadow = shadow
    }
    if (enemy.shadow) {
        enemy.shadow.setActive(true)
        enemy.shadow.setVisible(true)
        enemy.shadow.setAlpha(Constants.SHADOW_ALPHA)
    }

    enemy.setScale(enemyScaleRatio)
    enemy.setDepth(Constants.DEPTHS.enemy)
    // enemy.setDepth(2);

    // if (!enemyData.noHealthBar) {
    enemy.healthBar = new HealthBar(this, enemy.x, enemy.y)
    enemy.healthBarOffset = (((enemyData.radius / 8) * 7) - 7)

    minimapManager.ignoreEntity(enemy.healthBar.bar)
    // }
    // else { enemy.noHealthBar = true }


    enemy.usernameBaseText = enemyData.label

    let label = enemyData.label
    if (enemyInfo.label) { label = enemyInfo.label 
    }
    if (label) {
        enemy.usernameText = this.add.bitmapText(400, 50, 'rubikOutlined', label, 8)
        if (enemyInfo.labelColor) {
            enemy.usernameText.tint = parseInt(enemyInfo.labelColor.replace('#',''),16)
        } else if (enemyData.labelColor) {
            enemy.usernameText.tint = parseInt(enemyData.labelColor.replace('#',''),16)
        }
        enemy.usernameTextXTransformation = (((64 * enemy.scaleX) / 2) - 8)
        enemy.usernameTextYTransformation = 26
        enemy.usernameText.setOrigin(0.41, 0)
        enemy.usernameText.setDepth(Constants.DEPTHS.entityLabels)
    }

    let icon = enemyData.icon
    if (enemyInfo.icon) { icon = enemyInfo.icon }

    if (icon && label) {
        let iconColor
        if (enemyInfo.iconColor) {
            iconColor = enemyInfo.iconColor
        } else if (enemyData.iconColor) {
            iconColor = enemyData.iconColor
        } else {
            iconColor = '#d68a56'
        }
        setIcon(enemy, icon, iconColor)
    }

    // if (enemyData.randomTint) { enemy.setTint(Math.random() * 0xffffff) }
    enemy.id = enemyInfo.id
    enemy.enemyID = enemyInfo.enemyID


    if (Constants.NETWORKING == 'CAUTH') {
        let radius = enemyData.radius
        if (enemyData.radiusModifier) {
            radius = radius * enemyData.radiusModifier
        }
        enemy.collisionBody = createCircle(collisions, enemyInfo.x, enemyInfo.y, radius)
        enemy.collisionBody.objectType = 'enemy'
        enemy.collisionBody.enemyServerId = enemyInfo.id
        if (enemyInfo.isPet) {
            enemy.collisionBody.isPet = true
            enemy.collisionBody.petOwnerId = enemyInfo.petOwnerId
        }
        enemy.collisionBody.radius = radius
    }
    this.enemies.add(enemy)
    minimapManager.ignoreEntity(enemy)
    //
    if (enemy.shadow) {
        minimapManager.ignoreEntity(enemy.shadow)
    }

    // Push player ID into array
    idList.push(enemyInfo.id)
}

export function updateEnemy(enemy, enemyInfo) {
    if (enemy.turnedInvisible) {
        if (enemyInfo.hp > 0) {
            enemy.turnedInvisible = false
            enemy.visible = true
            enemy.healthBar.bar.visible = true
            if (enemy.usernameText) {
                enemy.usernameText.visible = true
            }
            if (enemy.rank) {
                enemy.rank.visible = true
                enemy.rankCircle.visible = true
            }
            if (enemy.collisionBody) {
                enemy.collisionBody.deactivated = false
            }
        } else {
            return
        }
    }

    if ((enemy.x !== enemyInfo.x) || (enemy.y !== enemyInfo.y)) {
        var playerDir = Phaser.Math.Angle.Between(enemy.x, enemy.y, enemyInfo.x, enemyInfo.y)
        if (playerDir < 0) {
            playerDir = playerDir + (2 * Math.PI)
        }
        playerDir = Phaser.Math.RAD_TO_DEG * playerDir
    }
    else {
        playerDir = null
    }
    if (enemyInfo.label && enemyInfo.label !== enemy.usernameBaseText) {
        enemy.usernameText.setText(enemyInfo.label)
        enemy.usernameBaseText = enemyInfo.label
        if (enemyInfo.labelColor) {
            enemy.usernameText.setTint(parseInt(enemyInfo.labelColor?.replace('#',''),16))
        }
    }
    if (enemy.rank && enemyInfo.icon != enemy.rankIcon) {
        if (enemyInfo.icon){
            if (enemyInfo.iconColor) {
                setIcon(enemy, enemyInfo.icon, enemyInfo.iconColor)
            } else {
                setIcon(enemy, enemyInfo.icon)
            }
        }
    }
    // set enemy angle here
    if (enemy.spine) {
        enemy.angle = enemyInfo.angle + 90
    } else {
        enemy.angle = enemyInfo.angle
    }

    updateValues(enemy, enemyInfo, phaserObject)

    if (enemyInfo.radius) {
        let enemyScaleRatio = ((Math.log(enemyInfo.radius + 4) * 2.5) / 22) * (64 / enemy.width) * 2
        enemy.setScale(enemyScaleRatio)

        if (settings.videoSettings.shadows !== false) {
            enemy.shadow.setScale(enemyScaleRatio)
        }
        enemy.collisionBody.radius = enemyInfo.radius
    }
    if (enemyInfo.newState != enemy.currentState) {
        if (enemy.spine) {
            let trackedEntry
            //     if (enemy.enemyID == 9) {
            //     console.log('update info',
            //     {'newstate': enemyInfo.newState,
            //     'current state': enemy.currentState})
            // }
            switch (enemyInfo.newState) {
                case 1:
                    if (enemy.attackComplete) {
                        // enemy.play('idle', true, true)
                        enemy.animationState.setAnimation(0, 'idle', true)
                        enemy.currentState = enemyInfo.newState
                        trackedEntry = enemy.animationState.getCurrent(0)
                    }
                    break
                case 2:
                    if (enemy.attackComplete) {
                        // enemy.play('moving', true, true)
                        enemy.animationState.setAnimation(0, 'moving', true)
                        enemy.currentState = enemyInfo.newState
                        trackedEntry = enemy.animationState.getCurrent(0)
                    }
                    break
                case 3:
                    if (enemy.attackComplete) {
                        enemy.currentState = enemyInfo.newState
                        enemy.attackComplete = false
                        if (!enemy.spineTwoAttacks) {
                            // enemy.play('attack', false, false)
                            enemy.animationState.setAnimation(0, 'attack', false)
                            trackedEntry = enemy.animationState.getCurrent(0)
                        }
                        else {
                            if (Math.random() > 0.5) { 
                                // enemy.play('attack', false, false)
                                enemy.animationState.setAnimation(0, 'attack', false)
                                trackedEntry = enemy.animationState.getCurrent(0)
                            } else {
                                // enemy.play('attack2', false, false)
                                enemy.animationState.setAnimation(0, 'attack2', false)
                                trackedEntry = enemy.animationState.getCurrent(0)
                            }
                        }
                        setTimeout(function () {
                            if (enemy.active) {
                                enemy.attackComplete = true
                                if (enemy.currentState == 1) {
                                    // enemy.play('idle', true, true)
                                    enemy.animationState.setAnimation(0, 'idle', true)
                                }
                                else if (enemy.currentState == 2) {
                                    // enemy.play('moving', true, true)
                                    enemy.animationState.setAnimation(0, 'moving', true)
                                }
                                else {
                                    // enemy.play('idle', true, true)
                                    enemy.animationState.setAnimation(0, 'idle', true)
                                }
                            }
                        // }, enemy.getCurrentAnimation().duration * 500)
                        },
                            trackedEntry.animationEnd * 1000
                        )
                    }
                    break
            }
        }
    }
    if (Constants.NETWORKING == 'CAUTH') {
        enemy.collisionBody.setPosition(enemyInfo.x, enemyInfo.y)
    }
    if (enemyInfo.hp <= 0) { // hacky implementation so hp updates before render delay
        console.log(" EXPLODING ENEMY", {
            x: enemy.x,
            y: enemy.y
        })
        const distance = Phaser.Math.Distance.Between(enemy.x, enemy.y, enemyInfo.x, enemyInfo.y)
        if (distance < 300) {
            let particlesToExplode = 3
            if (enemy.radius > 10) {
                particlesToExplode = 5
            }
            if (enemy.boss) {
                particlesToExplode = 15
            }
            if (phaserObject.game.loop.actualFps > Constants.FPS_THRESHOLDS.LOW) {
                emitter.explode(particlesToExplode, enemy.x, enemy.y)
            }
        }

        enemy.turnedInvisible = true
        enemy.visible = false
        enemy.healthBar.bar.visible = false
        enemy.visible = false
        if (enemy.usernameText) {
            enemy.usernameText.visible = false
        }
        if (enemy.rank) {
            enemy.rank.visible = false
            enemy.rankCircle.visible = false
        }
        if (enemy.collisionBody) {
            enemy.collisionBody.deactivated = true
        }
        return
    }
}

export function destroyEnemy(enemy) {
    // const emitter1  = phaserObject.add.particles(0, 0, 'flares', {
    //     frame: [ 'red', 'yellow', 'green' ],
    //     lifespan: 1000,
    //     speed: { min: 50, max: 150 },
    //     scale: { start: 0.5, end: 0 },
    //     gravityY: 150,
    //     blendMode: 'ADD',
    //     emitting: false
    // });
    // emitter1.setScale(0.25)

    // emitter1.explode(16, player.x, player.y)
    if (enemy.healthBar) {
        // need remove enemy command to show hp numbers
        // let prevHp //
        // if (enemy.prevHp) {
        //     prevHp = enemy.prevHp
        //     createFloatingNumber(prevHp, enemy, phaserObject)
        //
        // }
        enemy.healthBar.bar.destroy()
        delete enemy.healthBar
    }
    if (enemy.usernameText) {
        enemy.usernameText.destroy()
        delete enemy.usernameText
    }
    if (enemy.rank) {
        if (enemy.rank.frame && enemy.rank.frame.name) {
            let rankIcon = enemy.rank
            enemy.rank.setActive(false)
            enemy.rank.setVisible(false)
            entityPoolManager.addObjectToPool('rankIcon', enemy.rank.frame.name, rankIcon)
        } else {
            // clean up in case icon cannot be pooled
            console.log('destroying rank icon without name')
            enemy.rank.destroy()
        }
        // enemy.rank.destroy()
        enemy.rankCircle.destroy()
        delete enemy.rank
        delete enemy.rankCircle
    }
    if (Constants.NETWORKING == 'CAUTH') {
        collisions.remove(enemy.collisionBody)
    }

    //enemy.destroy()
    this.enemies.killAndHide(enemy)
    this.enemies.remove(enemy)
    if (enemy.spine) {
        entityPoolManager.addObjectToPool('spineEnemies', enemy.spriteName, enemy)
    } else {
        entityPoolManager.addObjectToPool('enemies', enemy.imgName, enemy)
    }


    if (settings.videoSettings.shadows !== false && enemy.shadow) {
        // enemy.shadow.destroy()
        enemy.shadow.setActive(false)
        enemy.shadow.setVisible(false)
    }
}

export function createBulletSprite(spellData, bulletInfo, cauthBullet = false) {
    let type = spellData.type
    if (spellData.type2) {type = spellData.type2}
    let spriteName
    if (spellData.spellSpriteName) {
        spriteName = spellData.spellSpriteName
    } else {
        spriteName = spellData.spriteName
    }
    let bullet
    if (Constants.CLIENTSIDE_BULLETS && spellData.type === 'melee' && !spellData.type2){
        spellData.noSprite = true
        spellData.noAnim = true
    }

    if (!spellData.noSprite){
        bullet = entityPoolManager.getObjectFromPool('bullets', spellData.spriteName)
    } else {
        bullet = entityPoolManager.getObjectFromPool('bullets', 'noSpriteBullets')
    }

    if (!bullet) {
        if (spellData.noSprite) {
            if (!cauthBullet) {
                bullet = phaserObject.bullets.create(bulletInfo.x, bulletInfo.y, undefined, undefined, true, true)
            } else {
                bullet = phaserObject.clientsideBullets.create(bulletInfo.x, bulletInfo.y)
            }
            bullet.alpha = 0
        }
        else if (spellData.atlas) {
            if (!cauthBullet) {
                bullet = phaserObject.bullets.create(bulletInfo.x, bulletInfo.y, 'mainAssets', spriteName,true, true)
            } else {
                bullet = phaserObject.clientsideBullets.create(bulletInfo.x, bulletInfo.y, 'mainAssets', spriteName,true, true)
            }
        } else {
            if (!cauthBullet) {
                bullet = phaserObject.bullets.create(bulletInfo.x, bulletInfo.y, spriteName, undefined, true, true)
            } else {
                bullet = phaserObject.clientsideBullets.create(bulletInfo.x, bulletInfo.y, spriteName, undefined, true, true)
            }
        }
        if (!bullet){
            // bullets array is full
            console.log('bullets array is full')
            return null
        }
    } else {
        // bullet exists. reuse it!      

        if (!cauthBullet) {
            phaserObject.bullets.add(bullet)
        } else {
            phaserObject.clientsideBullets.add(bullet)
        }

        bullet.setPosition(bulletInfo.x, bulletInfo.y)
        if (!spellData.noSprite && (!bullet.active || !bullet.visible)) {
            bullet.setActive(true)
            bullet.setVisible(true)
        }
    }

    if (spellData.tint) {
        bullet.setTint(spellData.tint)
    } else {
        bullet.clearTint()
    }

    if (typeof bulletInfo.direction === 'undefined') {
        bulletInfo.direction = 0
    }
    bullet.setDepth(Constants.DEPTHS.bullet)
    bullet.angle = bulletInfo.direction * Phaser.Math.RAD_TO_DEG
    bullet.spellID = spellData.spellID
    bullet.origRadius = bulletInfo.radius
    bullet.currentRadius = bulletInfo.radius

    if (!bullet.origDisplayHeight) {
        bullet.origDisplayHeight = bullet.displayHeight
    }
    let scaleRatio = (bullet.origDisplayHeight / 32)
    let radiusScaleRatio = (bulletInfo.radius / 3)
    let totalScale = Number.parseFloat(((1 / scaleRatio) * (radiusScaleRatio / 2)).toFixed(3))
    bullet.origScale = totalScale
    bullet.setScale(totalScale)
    if (spellData.spin && !phaserObject.tweens.isTweening(bullet)) {
        console.log('sending spell data', spellData)
        tweenRotation(phaserObject, bullet, spellData.spin)
    }
    if (spellData.scale && !phaserObject.tweens.isTweening(bullet)) {
        tweenScale(phaserObject, bullet, spellData.scale, totalScale)
    }

    bullet.spriteName = spellData.spriteName
    bullet.type = type

    minimapManager.ignoreEntity(bullet)

    if (spellData.staticFrame && !spellData.playImmediately) {
        bullet.setFrame(spellData.staticFrame)
    }
    else {
        if (!spellData.noAnim && spellData.spellID !== 12) {
            bullet.anims.play(spriteName)
        } else {
            bullet.noAnim = true
        }
    }
    if (!spellData.noSprite) {
        bullet.noSprite = false
    } else {
        bullet.noSprite = true
    }
    bullet.id = bulletInfo.id
    let bulletVolume
    bulletVolume = spellData.bullets? (Constants.GAME_OVERALL_VOLUME/spellData.bullets).toFixed(2) : Constants.GAME_OVERALL_VOLUME
    bullet.sound = bullet.sound? bullet.sound : phaserObject.sound.addAudioSprite('sfx', { volume: bulletVolume })
    bullet.soundData = spellData.audio
    phaserObject.game.events.once('blur', function () {
        let stopSound = bullet.sound.stop()
        // stop() returns false if the sound is already stopped
        // if (!stopSound) {
        //     console.log('Bullet sound stop failed')
        // }
    })

    if (spellData.tint) {
        bullet.tint = spellData.tint
    }

    switch (type) {
        case 'projectile':
        case 'multi':
            if (phaserObject.game.hasFocus && spellData.audio.cast){
                bullet.sound.play(spellData.audio.cast)
            }
            break
        case 'static':
        case 'multiStatic':
        case 'self':
            if (phaserObject.game.hasFocus && spellData.audio.prep){
                bullet.sound.play(spellData.audio.prep)
            }
            break
    }
    // console.log("CREATING BULLET", bullet)
    return bullet
}
export function createBulletCollision(bullet, bulletInfo, type, bulletData) {
    // console.log('creating bullet collision', bulletInfo)
    bullet.collisionBody = createCircle(collisions, bulletInfo.x, bulletInfo.y, bulletInfo.radius)
    bullet.collisionBody.objectType = 'bullet'
    bullet.collisionBody.objectId = bulletInfo.id
    bullet.collisionBody.damage = bulletInfo.damage
    bullet.collisionBody.isFriendly = bulletInfo.isFriendly
    bullet.collisionBody.levelDiffFlag = bulletInfo.levelDiffFlag
    bullet.collisionBody.bulletType = type
    let cauthBulletId
    if (bullet.bulletId) {
        //cauth bullet
        cauthBulletId = bullet.bulletId
    }
    if (bulletData.chargeCooldown) {
        bullet.collisionBody.willCharge = true
        bullet.collisionBody.charged = false
        setTimeout(() => {
            if (bullet && bullet.collisionBody && bullet.bulletId === cauthBulletId) {
            bullet.collisionBody.charged = true
        }}, bulletData.chargeCooldown * 1000
        )
    }
    return bullet
}
export function createBullet(bulletInfo, idList) {
    if (hackyMeleeBulletsList.includes(bulletInfo.id)) {
        return
    }
    let spellData = Pickups.spells.filter(spell => spell.spellID == bulletInfo.spellID)[0]
    let type = spellData.type
    if (spellData.type2) {type = spellData.type2}

    let bullet = {}

    if (type != 'melee') {
        bullet = createBulletSprite(spellData, bulletInfo)
        if (!bullet) {
            console.log('bullet creation failed')
            return
        }
        idList.push(bulletInfo.id)
    }
    if (Constants.NETWORKING == 'CAUTH') {
        createBulletCollision(bullet, bulletInfo, type,spellData)

        if (type === 'melee') {
            hackyMeleeBulletsList.push(bulletInfo.id)
            setTimeout(() => {
                collisions.remove(bullet.collisionBody)
                hackyMeleeBulletsList.filter(hackyBullet => hackyBullet !== bulletInfo.id)
            }, 200)

        }
    }
}

export function updateBullet(bullet, bulletInfo) {
    if (bullet) {
        bullet.setPosition(bulletInfo.x, bulletInfo.y)
        if (bulletInfo.radius != bullet.currentRadius) {
            bullet.currentRadius = bulletInfo.radius
            let totalScale = bullet.origScale * (bulletInfo.radius / bullet.origRadius)
            bullet.setScale(totalScale)
        }
        if (Constants.NETWORKING == 'CAUTH') {
            if (bullet.collisionBody) {
                bullet.collisionBody.setPosition(bulletInfo.x, bulletInfo.y)
            } else {
                let id = JSON.stringify(bullet.id)
                let bulletInfoId = JSON.stringify(bulletInfo.id)
                let checkedBul = JSON.stringify(bullet.checkedBul)
                console.log('bullet update: no collision body', id, bulletInfoId, checkedBul)
            }
        }
    } else {
        console.log("BULLET NOT FOUND", {
            bullet: bullet,
            bulletInfo: bulletInfo
        })
    }
}

export function destroyBullet(bullet, currentEntityList, cleanup = false) {
    if (bullet) {
        if (cleanup) {
            bullet.sound.stop(bullet.soundData.cast)

            if (currentEntityList) {
                let entityArraySpot = currentEntityList.findIndex(entityID => entityID == bullet.id)
                if (entityArraySpot !== -1) {
                    currentEntityList.splice(entityArraySpot, 1)
                } else {
                    let id = JSON.stringify(bullet.id)
                    console.log('bullet destroy attempt. bullet not found in currentEntityList', id) // beware of bullets that are not destroyed!
                }
            }

            if (Constants.NETWORKING == 'CAUTH') {
                if (bullet.collisionBody) {
                    let collisionBodyRef = bullet.collisionBody
                    collisionBodyRef.deactivated = true
                    collisions.remove(collisionBodyRef)
                } else {
                    let id = JSON.stringify(bullet.id)
                    let checkedBul = JSON.stringify(bullet.checkedBul)
                    console.log('bullet destroy static: no collision body', id, checkedBul)
                }
                bullet.collisionBody = undefined
            }
            phaserObject.bullets.killAndHide(bullet)
            phaserObject.bullets.remove(bullet, undefined, true)
        }else if (!bullet.noAnim && (bullet.type == 'static' || bullet.type == 'self' || bullet.type == 'multiStatic')) {
            if (bullet.checkedBul !== true) {
                bullet.sound.stop(bullet.soundData.prep)
                if (phaserObject.game.hasFocus && bullet.soundData.impact) {
                    bullet.sound.play(bullet.soundData.impact)
                }

                bullet.once('animationcomplete', () => {
                    let entityArraySpot = currentEntityList.findIndex(entityID => entityID == bullet.id)
                    if (entityArraySpot !== -1) {
                        currentEntityList.splice(entityArraySpot, 1)
                    } else {
                        let id = JSON.stringify(bullet.id)
                        console.log('bullet destroy attempt. bullet not found in currentEntityList', id) // beware of bullets that are not destroyed!
                    }
                    if (Constants.NETWORKING == 'CAUTH') {
                        if (bullet.collisionBody) {
                            let collisionBodyRef = bullet.collisionBody
                            collisionBodyRef.deactivated = true
                            collisions.remove(collisionBodyRef)
                        } else {
                            let id = JSON.stringify(bullet.id)
                            let checkedBul = JSON.stringify(bullet.checkedBul)
                            console.log('bullet destroy static: no collision body', id, checkedBul)
                        }
                        bullet.collisionBody = undefined
                    }
                    bullet.checkedBul = false

                    bullet.id = -1
                    phaserObject.bullets.killAndHide(bullet)
                    phaserObject.bullets.remove(bullet)
                    if (!bullet.noSprite) {
                        entityPoolManager.addObjectToPool('bullets', bullet.spriteName, bullet)
                    } else {
                        entityPoolManager.addObjectToPool('bullets', 'noSpriteBullets', bullet)
                    }
                })
                bullet.anims.play(bullet.spriteName, true)

                bullet.checkedBul = true
            }
        }
        else {
            bullet.sound.stop(bullet.soundData.cast)
            if (phaserObject.game.hasFocus && bullet.soundData.impact) {
                bullet.sound.play(bullet.soundData.impact)
            }
            let entityArraySpot = currentEntityList.findIndex(entityID => entityID == bullet.id)
            if (entityArraySpot !== -1) {
                currentEntityList.splice(entityArraySpot, 1)
            } else {
                let id = JSON.stringify(bullet.id)
                console.log('bullet destroy attempt. bullet not found in currentEntityList', id) // beware of bullets that are not destroyed!
            }
            if (Constants.NETWORKING == 'CAUTH') {
                if (bullet.collisionBody) {
                    let collisionBodyRef = bullet.collisionBody
                    collisionBodyRef.deactivated = true
                    collisions.remove(collisionBodyRef)
                } else {
                    let id = JSON.stringify(bullet.id)
                    let checkedBul = JSON.stringify(bullet.checkedBul)
                    console.log('bullet destroy: no collision body', id, checkedBul)
                }
                bullet.collisionBody = undefined
            }
            bullet.id = -1
            phaserObject.bullets.killAndHide(bullet)
            phaserObject.bullets.remove(bullet)
            if (!bullet.noSprite) {
                entityPoolManager.addObjectToPool('bullets', bullet.spriteName, bullet)
            } else {
                entityPoolManager.addObjectToPool('bullets', 'noSpriteBullets', bullet)
            }
        }
    } else {
        console.log("BULLET NOT FOUND", {
            bullet: bullet,
            bulletInfo: bulletInfo
        })
    }
}


export function createPickup(pickup, idList) {
    let createpickup = true
    switch (pickup.type) {
        case 'spell':
            {
                let pickupSprite
                let spellData = Pickups.spells.find(spell => pickup.pickupID == spell.spellID)
                if (spellData) {
                    let spellColor = ColorTable.element[spellData.mana]
                    let circleColor = ColorTable.rarity[spellData.rarity]


                    let circleOutline2 = this.add.circle(pickup.x, pickup.y, Constants.SPELL_DISPLAY_RADIUS + 0.5, 0xffffff)
                    circleOutline2.setStrokeStyle(1, 0xffffff)

                    let circle = this.add.circle(pickup.x, pickup.y, Constants.SPELL_DISPLAY_RADIUS, circleColor)
                    circle.setStrokeStyle(0.5, 0x000000)
                    pickupSprite = this.add.sprite(pickup.x, pickup.y, 'mainAssets', spellData.imgPath)
                    pickupSprite.setTintFill(spellColor)
                    pickupSprite.circle = circle
                    pickupSprite.circleOutline2 = circleOutline2
                    pickupSprite.setScale(0.018)
                    pickupSprite.pickupID = pickup.id
                    pickupSprite.pickupType = 'spell'
                    pickupSprite.id = pickup.id
                    pickupSprite.circleOutline2.setDepth(Constants.DEPTHS.floorObjects)
                    pickupSprite.circle.setDepth(Constants.DEPTHS.floorObjects)
                    pickupSprite.setDepth(Constants.DEPTHS.floorObjects)

                    this.pickups.add(pickupSprite)

                    minimapManager.ignoreEntity(pickupSprite)
                    minimapManager.ignoreEntity(pickupSprite.circle)
                    minimapManager.ignoreEntity(pickupSprite.circleOutline2)
                } else {
                    createpickup = false
                }
            }
            break

        case 'pickup':
            {
                let scale = pickup.icon.scale ? pickup.icon.scale : 1
                let circleColor = pickup.icon && pickup.icon.circleColor ? hexTo0x(pickup.icon.circleColor) : 0x9B5B2F
                let circleOutline2 = this.add.circle(pickup.x, pickup.y, (Constants.SPELL_DISPLAY_RADIUS * scale) + 0.5, 0xffffff)
                circleOutline2.setStrokeStyle(1, 0xffffff)

                let circle = this.add.circle(pickup.x, pickup.y, Constants.SPELL_DISPLAY_RADIUS * scale, circleColor)
                circle.setStrokeStyle(0.5, 0x000000)
                if (pickup.icon && pickup.icon.iconName) {
                    circle.pickupSprite = this.add.sprite(pickup.x, pickup.y, 'mainAssets', pickup.icon.iconName)
                    circle.pickupSprite.setScale(0.108 * scale)
                    minimapManager.ignoreEntity(circle.pickupSprite)
                }

                if (pickup.icon && pickup.icon.iconTint) {
                    circle.pickupSprite.setTintFill(hexTo0x(pickup.icon.iconTint))
                }
                circle.circleOutline2 = circleOutline2

                circle.pickupID = pickup.id
                circle.pickupType = 'pickup'
                circle.id = pickup.id

                circle.circleOutline2.setDepth(Constants.DEPTHS.floorObjects)
                circle.setDepth(Constants.DEPTHS.floorObjects)
                if (circle.pickupSprite) {
                    circle.pickupSprite.setDepth(Constants.DEPTHS.floorObjects)
                }
           
                this.pickups.add(circle)
                minimapManager.ignoreEntity(circle)
                minimapManager.ignoreEntity(circleOutline2)
            }
            break
        case 'bodyWear':
        case 'topWear':
        case 'handWear':
            {
                let pickupSprite
                let gearData = Pickups[pickup.type].find(gear => pickup.pickupID == gear.id)
                console.log('gearData', gearData)
                let circleColor = ColorTable.rarity[gearData.rarity]
                if (gearData.customCircleColor) {

                    circleColor = parseInt(gearData.customCircleColor.replace('#', ''), 16)
                    // circleColor = gearData.customCircleColor
                }

                let circleOutline2 = this.add.circle(pickup.x, pickup.y, Constants.SPELL_DISPLAY_RADIUS + 0.5, 0xffffff)
                circleOutline2.setStrokeStyle(1, 0xffffff)

                let circle = this.add.circle(pickup.x, pickup.y, Constants.SPELL_DISPLAY_RADIUS, circleColor)
                circle.setStrokeStyle(0.5, 0x000000)
                pickupSprite = this.add.sprite(pickup.x, pickup.y, 'mainAssets', `gear-icons/${gearData.imgPath}`)
                pickupSprite.circle = circle
                pickupSprite.circleOutline2 = circleOutline2
                // pickupSprite.setScale(0.018)
                pickupSprite.setScale(0.072)
                pickupSprite.pickupID = pickup.id
                pickupSprite.pickupType = pickup.type
                pickupSprite.id = pickup.id

                pickupSprite.circleOutline2.setDepth(Constants.DEPTHS.floorObjects)
                pickupSprite.circle.setDepth(Constants.DEPTHS.floorObjects)
                pickupSprite.setDepth(Constants.DEPTHS.floorObjects)

                this.pickups.add(pickupSprite)
                minimapManager.ignoreEntity(pickupSprite.circle)
                minimapManager.ignoreEntity(pickupSprite)
                minimapManager.ignoreEntity(pickupSprite.circleOutline2)
            }
            break
        case 'currency':
            {
                let spriteName
                if (pickup.pickupID == 1) {
                    spriteName = 'rune.png'
                } else {
                    spriteName = 'glyph.png'
                }

                let circleOutline2 = this.add.circle(pickup.x, pickup.y, Constants.MANA_DISPLAY_RADIUS + 0.5, 0xffffff)
                circleOutline2.setStrokeStyle(0.5, 0xffffff)

                let circle = this.add.sprite(pickup.x, pickup.y, 'mainAssets', spriteName)
                circle.setScale(0.072)
                circle.pickupID = pickup.id
                circle.pickupType = 'currency'
                circle.id = pickup.id

                circle.circleOutline2 = circleOutline2

                circle.circleOutline2.setDepth(Constants.DEPTHS.floorObjects)
                circle.setDepth(Constants.DEPTHS.floorObjects)

                this.pickups.add(circle)
                minimapManager.ignoreEntity(circle)
                minimapManager.ignoreEntity(circleOutline2)
            }
    }
    if (createpickup) {
        idList.push(pickup.id)
    }
}

export function updatePickup(pickup, pickupInfo) {
    switch (pickupInfo.type) {
        case 'spell':
            pickup.setPosition(pickupInfo.x, pickupInfo.y)
            pickup.circle.setPosition(pickupInfo.x, pickupInfo.y)
            pickup.circleOutline2.setPosition(pickupInfo.x, pickupInfo.y)
            break
        case 'pickup':
            // if (!pickup.noSprite) {
            pickup.setPosition(pickupInfo.x, pickupInfo.y)
            // }
            if (pickup.pickupSprite) {
                pickup.pickupSprite.setPosition(pickupInfo.x, pickupInfo.y)
            }
            // pickup.circle.setPosition(pickupInfo.x, pickupInfo.y)
            pickup.circleOutline2.setPosition(pickupInfo.x, pickupInfo.y)
            break
        case 'currency':
            pickup.setPosition(pickupInfo.x, pickupInfo.y)
            pickup.circleOutline2.setPosition(pickupInfo.x, pickupInfo.y)
            break
        case 'bodyWear':
        case 'topWear':
        case 'handWear':
            pickup.setPosition(pickupInfo.x, pickupInfo.y)
            pickup.circle.setPosition(pickupInfo.x, pickupInfo.y)
            pickup.circleOutline2.setPosition(pickupInfo.x, pickupInfo.y)
            break
    }
}

export function destroyPickup(pickup) {
    switch (pickup.pickupType) {
        case 'currency':
            pickup.destroy()
            pickup.circleOutline2.destroy()
            break
        case 'pickup':
            if (pickup.pickupSprite) {
                pickup.pickupSprite.destroy()
            }
            pickup.circleOutline2.destroy()
            pickup.destroy()
            break
        default:
            pickup.circleOutline2.destroy()
            pickup.circle.destroy()
            pickup.destroy()
            break
    }
}
