import React, { Component, useState, setState } from 'react'
import { Grid, Box, responsiveFontSizes, capitalize } from '@mui/material'
import { ButtonBase } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { IconButton } from '@mui/material'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import Constants from '../../shared/constants'
import ColorChangingButton from './ColorChangingButton.js'
import Slide from '@mui/material/Slide'
import * as Actions from '../actions/actions'
import {bigScreenBorderProperties, colorPalette, mainBorderProperties} from './uiStyles.js'
import { Paper } from '@mui/material'
import ColorTable from "../../shared/colorTable";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />
})

function ItemReward(props) {
    let { dialogNewItem, handleCloseNewItem, itemEarned, itemEarnedMessage, itemEarnedPath, hideLoadout } = props
    let logoPath
    let transform
    let top = '0px'
    switch (itemEarned.type) {
        case 'emote':
            logoPath = 'assets/ui/icon_emote.png'
            break
        case 'head':
            logoPath = 'assets/ui/icon_head.png'
            top = '-10px'
            break
        case 'coins':
            logoPath = 'assets/ui/rune.png'
            break
        case 'gems':
            logoPath = 'assets/ui/glyph.png'
            break
        default:
            break

    }
    return (
        <Dialog
            open={dialogNewItem}
            onClose={handleCloseNewItem}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={'sm'}
            style={{zIndex:100002}}
        >
            <IconButton
                style={{
                    alignSelf: 'flex-end',
                    marginTop: -5,
                    position: 'absolute'
                }}
                onClick={handleCloseNewItem}
                size="large">

                <CancelPresentationIcon style={{ fontSize: '3rem' }} />
            </IconButton>
                      <Paper style={Object.assign({
                                background: colorPalette.primary,
                                padding: '2%',
                          borderColor: colorPalette.primaryBorder,
                            }, bigScreenBorderProperties)}>
            <Grid container align='center' xs={12}
                style={{
                    minWidth: '500px',
                    minHeight: '440px',
                    background: colorPalette.primary,
                }}
            >
                <Grid xs={12}>
                    <div
                        style={{
                            paddingTop: '10px',
                        }}
                    >
                        <b style={{
                            fontSize: '21px'
                        }}

                        > ITEM EARNED! </b>
                    </div>
                </Grid>
                <Grid xs={12}>
                    <div style={{

                        fontSize: '35px',
                        textTransform: 'uppercase',
                        background: ColorTable.rarityInfo[itemEarned.rarity].rarityColor,
                        color: 'white',
                        borderRadius: '25px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        width: '50%',
                        position: 'relative',
                    }}
                    >
                        <img style={{
                            position: 'absolute',
                            width: '33px',
                            left: '4.5%',
                            top: '50%',
                            transform: 'translateY(-50%)',

                            pointerEvents: 'none',
                            margin: 'auto',
                            zIndex: 10,
                        }}
                            src={logoPath}
                        />

                        {itemEarnedMessage}  </div>
                </Grid>
                <Grid xs={12}>
                    <ButtonBase style={{
                        borderRadius: '15px',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: ColorTable.rarityInfo[itemEarned.rarity].loadoutBorder,
                        boxShadow: '0 0 0 2px #C79B7B',

                        width: '150px',
                        height: '150px',

                        position: 'relative',
                    }}>
                        <img style={{
                            position: 'relative', pointerEvents: 'none',
                            width: '150px',
                            margin: 'auto',
                            transform: transform,
                            top: top,
                        }}
                            src={itemEarnedPath} />
                        {itemEarned.quantity &&
                            <div style={{
                                position: 'absolute',
                                right: '5%',
                                bottom: '1%',
                                pointerEvents: 'none',
                                zIndex: 10,
                                color: 'white',
                                fontSize: '20px',
                                textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
                            }}
                            >
                                {itemEarned.quantity}
                            </div>
                        }
                    </ButtonBase>
                </Grid>
                {!hideLoadout &&
                    <Grid xs={12}>
                        <ColorChangingButton buttonFunction={() => {
                            Actions.toggleLoadoutMenu(true)
                            // handleClose()
                            handleCloseNewItem()
                        }}
                            config={
                                {
                                    idle: {
                                        fillColor: '#7C4E4D',
                                        outerBorderColor: '#362D44',
                                        innerBorderColor: '#543131',
                                    },
                                    hover: {
                                        fillColor: '#CC7A82',
                                        outerBorderColor: '#362D44',
                                        innerBorderColor: '#AA5561',
                                    },
                                    down: {
                                        fillColor: '#CC7A82',
                                        outerBorderColor: '#362D44',
                                        innerBorderColor: '#AA5561',
                                    },
                                }}
                            title='Loadout'
                            buttonStyling={{
                                marginTop: '10px', marginRight: '5px',
                                color: 'white',
                                width: '70%',
                                padding: '1%'
                            }}
                        />
                    </Grid>
                }
            </Grid>
            </Paper>
        </Dialog>
    );
}

export default ItemReward