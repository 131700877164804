import initialState from './initialState'
import {
  UPDATE_ACCOUNT_EXP,
  UPDATE_PREV_ACCOUNT_EXP,
  UPDATE_KEY,
  TOGGLE_SHIFT,
  UPDATE_SPELLS,
  TRIGGER_SPELL_RELOAD,
  UPDATE_PICKUP_UI,
  GAME_LOAD_COMPLETE,
  MAP_LOAD_COMPLETE,
  UPDATE_COOLDOWN_BAR,
  UPDATE_BR_TIMER,
  UPDATE_LEADERBOARD,
  UPDATE_PLAYER_KILLS,
  TRIGGER_PLAYER_DIED,
  TRIGGER_PLAYER_REVIVED,
  UPDATE_LEVEL,
  UPDATE_CIRCLE,
  UPDATE_STAT_POINTS,
  UPDATE_STATS,
  UPDATE_GEAR_DATA,
  TRIGGER_GAME_STARTED,
  UPDATE_USERNAME_INPUT,
  UPDATE_SELECTED_HEAD,
  UPDATE_SERVER_TABLE,
  UPDATE_SERVER_SELECTION,
  UPDATE_SELECTED_SERVER,
  UPDATE_ZONE_COUNTDOWN,
  UPDATE_MIN_ZONE,
  UPDATE_HIGHSCORE_LIST,
  UPDATE_CODE_INPUT,
  TOGGLE_MUTE,
  TOGGLE_FULLSCREEN,
  TOGGLE_ADBLOCK,
  UPDATE_HEIGHT,
  UPDATE_WIDTH,
  UPDATE_JOINED_DISCORD,
  TOGGLE_INIT_GAME,
  UPDATE_LOAD_PROGRESS,
  ADD_LOAD_PROGRESS,
  TOGGLE_AUTHENTICATED,
  UPDATE_ACCOUNT_ITEMS,
  UPDATE_TOKEN,
  UPDATE_ACCOUNT_USERNAME,
  UPDATE_ACCOUNT_USERNAME_INPUT,
  UPDATE_ACCOUNT_STATS,
  UPDATE_ACCOUNT_KILL_STATS,
  UPDATE_GUEST_ACCOUNT,
  TOGGLE_BETA_ACTIVE,
  TOGGLE_ALPHA_ACTIVE,
  UPDATE_EMOTE_DICT,
  UPDATE_ACCOUNT_CHALLENGES,
  TOGGLE_LOADOUT_MENU,
  TOGGLE_CHALLENGE_MENU,
  TOGGLE_SHOP_MENU,
  UPDATE_COINS,
  UPDATE_GEMS,
  UPDATE_DAILY_QUESTS,
  UPDATE_DAILY_QUESTS_TIME_LEFT,
  UPDATE_ACCOUNT_ID,
  UPDATE_ACCOUNT_EMAIL,
  TOGGLE_PAYMENT_MENU,
  TOGGLE_TOUCH_CONTROLS,
  LOADED_REMAINING_FILES,
  UPDATE_CHAT_INPUT,
  TOGGLE_CHATBOX_ACTIVE,
  UPDATE_SETTINGS,
  UPDATE_PROMO_CODE_INPUT,
  UPDATE_SELECTED_SCORE,
  UPDATE_SELECTED_TIME_VALUE,
  UPDATE_RANK,
  UPDATE_RANK_SCORE,
  HIDE_CROSS_LINKS,
  UPDATE_REFERRER,
  UPDATE_SHOP_TAB,
  UPDATE_DISABLE_ADBLOCK_POPUP,
  UPDATE_TRIAL_VICTORY,
  UPDATE_NEW_UPDATE_DIALOG,
  UPDATE_GAME_PLAYED_ONCE,
  UPDATE_GLOBAL_CHAT,
  SET_MIDDLE_LOWER_TEXT,
  UPDATE_EMOTE_BAR_DATA,
  SET_STATS_DATA,
  UPDATE_RESOURCE_PROPERTIES,
  SET_RESOURCES,
  UPDATE_RESOURCE,
  SET_CUSTOM_BASE_STAT_CAPS,
  SET_STATS,
  TOGGLE_SHOP,
  SET_SHOP_DATA,
  UPDATE_SELECTED_HIGHSCORE_GAME_MODE,
  SET_GAME,
  TOGGLE_INGAME_SETTINGS_MENU,
  UPDATE_INGAME_MENU_TAB, TOGGLE_SIGN_IN_MENU,
  SET_SINGLE_GAME, SET_TITLE_LOADED,
  UPDATE_ALERT_MESSAGE,
  RESET_GLOBAL_CHAT, SET_SPELL_POINTER, SDK_LOADED,
  UPDATE_GENERIC_BAR_NAME,
  UPDATE_GENERIC_BAR_VALUE,
  UPDATE_STAT_BAR_VISIBILITY,
} from '../actions/actionTypes'

export default function stuff(state = initialState.stuff, action) {
  switch (action.type) {
    case UPDATE_KEY:
      return {
        ...state,
        'selectedKey': action.selectedKey
      }
    case UPDATE_HIGHSCORE_LIST:
      return {
        ...state,
        highscoresReceived: true,
        'highscores': action.highscoreList
      }
    case TOGGLE_SHIFT:
      return {
        ...state,
        'shiftActive': action.shiftActive
      }
    case UPDATE_ALERT_MESSAGE:
      return {
        ...state,
        'alertMessage': action.alertMessage
      }
    case SET_SPELL_POINTER:
      console.log('setting spell pointer reducer', {action: action})
      // Check if we need to remove other pointers
      let fadeScreenValue = false
      if (action.fadeScreen) {
        fadeScreenValue = true
      }
  if (action.removeOtherPointers) {
    // Create a new object with all spell pointers set to false
    const newSpellPointers = Object.keys(state.spellPointers).reduce((acc, key) => {
      acc[key] = false; // Set all spell pointers to false
      return acc;
    }, {});

    // Set the specified spell pointer to true
    newSpellPointers[action.spellSlot] = action.active;

    return {
      ...state,
      spellPointers: newSpellPointers,
      gameFocusOverlay: fadeScreenValue
      
    };
  } else {
    // If not removing other pointers, just update the specified spell pointer
    return {
      ...state,
      gameFocusOverlay: fadeScreenValue,
      spellPointers: {
        ...state.spellPointers,
        [action.spellSlot]: action.active
      }
    };
      // return {
      //   ...state,
      //   spellPointers: {
      //     ...state.spellPointers,
      //     [action.spellSlot]: action.active
      //   }
      }
    case UPDATE_SPELLS:
      // FOR DEBUGGING: This causes a lot of lag - I can try adding reconciliation methods with this to see if I can counteract redux lag in general
      // return JSON.parse(JSON.stringify({
      //   ...state,
      //   'spellsList': action.spellsList
      // }))

      return {
        ...state,
        'spellsList': action.spellsList
      }

    case TRIGGER_SPELL_RELOAD:
      return {
        ...state,
        spellReloads: {
          ...state.spellReloads,
          [action.spellKey]: action.value
        }
      }
    case UPDATE_PICKUP_UI:
      return {
        ...state,
        'pickupUIItem': action.pickup
      }
    case UPDATE_BR_TIMER:
      return {
        ...state,
        'BRCircleTime': action.BRCircleTime
      }
    case UPDATE_LEADERBOARD:
      return {
        ...state,
        'leaderboard': action.leaderboard
      }

    case GAME_LOAD_COMPLETE:
      return {
        ...state,
        'gameLoaded': action.gameLoaded
      }
    case MAP_LOAD_COMPLETE:
      return {
        ...state,
        'mapLoaded': action.mapLoaded
      }

    case UPDATE_PLAYER_KILLS:
      return {
        ...state,
        'playerKills': action.playerKills
      }

    case TRIGGER_PLAYER_DIED:
      return {
        ...state,
        'playerDied': action.playerDied,
        'thingsKept': action.thingsKept,
        'adId': action.playerDied ? state.adId + 1 : state.adId
      }

    case TRIGGER_PLAYER_REVIVED:
      return {
        ...state,
        'playerDied': false,
      }

    case TRIGGER_GAME_STARTED:
      return {
        ...state,
        'startedGame': action.gameStarted,
      }

    case UPDATE_LEVEL:
      return {
        ...state,
        'level': action.level,
        'expRatio': action.expRatio,
      }

    case UPDATE_CIRCLE:
      return {
        ...state,
        'currentCircle': action.currentCircle
      }

    case UPDATE_STAT_POINTS:
      return {
        ...state,
        'statPoints': action.statPoints
      }

    case UPDATE_STATS:
      return {
        ...state,
        // 'stats': action.stat
        stats: {
          ...state.stats,
          [action.stat.statName]: action.stat.statPoints,
        }
      }
    case SET_STATS:
      console.log("SETTING STATS", action.stats)
        return {
            ...state,
            stats: action.stats,
            statsUpdate: state.statsUpdate + 1
        }
    case UPDATE_STAT_BAR_VISIBILITY: 
      return {
        ...state,
        statsBarOpen: action.value
      }

    case UPDATE_GEAR_DATA:
      return {
        ...state,
        [action.gearName]: action.gearData,
        [action.gearType]: action.gearData.stats
      }

    case UPDATE_USERNAME_INPUT:
      return {
        ...state,
        usernameInput: action.input
      }
    case UPDATE_PROMO_CODE_INPUT:
      return {
        ...state,
        promoCodeInput: action.input
      }
    case UPDATE_SELECTED_HEAD:
      return {
        ...state,
        selectedHead: action.input
      }
    case UPDATE_CODE_INPUT:
      return {
        ...state,
        codeInput: action.input
      }

    case UPDATE_SERVER_TABLE:
      return {
        ...state,
        serverData: action.serverData
      }

    case UPDATE_SERVER_SELECTION:
      return JSON.parse(JSON.stringify({
        ...state,
        serverData: action.serverData
      }))

    case UPDATE_SELECTED_SERVER:
      return {
        ...state,
        selectedServer: action.url
      }

    case UPDATE_ZONE_COUNTDOWN:
      return {
        ...state,
        zoneCountdown: action.countdown
      }

    case UPDATE_MIN_ZONE:
      return {
        ...state,
        minZone: action.minZone
      }

    case UPDATE_ACCOUNT_EXP:
      return {
        ...state,
        accountLevel: action.level,
        accountExp: action.exp,
        accountExpRatio: action.expRatio,
      }

    case UPDATE_PREV_ACCOUNT_EXP:
      return {
        ...state,
        prevAccountLevel: action.level,
        prevAccountExp: action.exp,
        prevAccountExpRatio: action.expRatio,
      }

    case TOGGLE_MUTE:
      return {
        ...state,
        mute: action.mute
      }

    case TOGGLE_FULLSCREEN:
      return {
        ...state,
        fullscreen: action.fullscreen
      }

    case TOGGLE_ADBLOCK:
      return {
        ...state,
        adblock: action.adblock
      }

    case UPDATE_HEIGHT:
      return {
        ...state,
        height: action.height
      }

    case UPDATE_WIDTH:
      return {
        ...state,
        width: action.width
      }

    case UPDATE_JOINED_DISCORD:
      return {
        ...state,
        joinedDiscord: action.value
      }

    case TOGGLE_INIT_GAME:
      return {
        ...state,
        initGame: action.value
      }

    case UPDATE_LOAD_PROGRESS:
      return {
        ...state,
        loadProgress: action.value
      }
    case ADD_LOAD_PROGRESS:
      return {
        ...state,
        loadProgress: state.loadProgress + action.value
      }

    case TOGGLE_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.value
      }

    case UPDATE_ACCOUNT_ITEMS:
      return {
        ...state,
        accountItems: action.value
      }

    case UPDATE_ACCOUNT_CHALLENGES:
      return {
        ...state,
        accountChallenges: action.value
      }

    case UPDATE_TOKEN:
      return {
        ...state,
        token: action.value
      }

    case UPDATE_ACCOUNT_USERNAME:
      return {
        ...state,
        accountUsername: action.value,
        authenticated: true
      }

    case UPDATE_ACCOUNT_USERNAME_INPUT:
      return {
        ...state,
        accountUsernameInput: action.input
      }

    case UPDATE_ACCOUNT_STATS:
      return {
        ...state,
        accountStats: action.value
      }

    case UPDATE_ACCOUNT_KILL_STATS:
      return {
        ...state,
        accountKillStats: action.value
      }
    case UPDATE_GUEST_ACCOUNT:
      return {
        ...state,
        guestAccount: action.value
      }

    case TOGGLE_BETA_ACTIVE:
      return {
        ...state,
        betaActive: action.value
      }
    case TOGGLE_ALPHA_ACTIVE:
      return {
        ...state,
        alphaActive: action.value
      }

    case UPDATE_EMOTE_DICT:
      return {
        ...state,
        emoteDict: {
          ...state.emoteDict,
          [action.selectedEmoteSlot]: action.emoteId
        }
      }

    case TOGGLE_LOADOUT_MENU:
      return {
        ...state,
        loadoutDialog: action.value
      }


    case TOGGLE_CHALLENGE_MENU:
      return {
        ...state,
        challengeDialog: action.value
      }

    case TOGGLE_SIGN_IN_MENU:
        return {
            ...state,
            signInDialogActive: action.value
        }

    case TOGGLE_SHOP_MENU:
      return {
        ...state,
        shopDialog: action.value
      }

      case UPDATE_INGAME_MENU_TAB:
        return {
            ...state,
            inGameMenuTab: action.value
        }

    case UPDATE_SHOP_TAB:
      return {
        ...state,
        shopTab: action.value
      }

    case TOGGLE_PAYMENT_MENU:
      return {
        ...state,
        paymentDialog: action.value
      }

    case UPDATE_COINS:
      return {
        ...state,
        coins: action.value
      }

    case UPDATE_GEMS:
      return {
        ...state,
        gems: action.value
      }

    case UPDATE_DAILY_QUESTS:
      return {
        ...state,
        dailyQuests: action.value
      }

    case UPDATE_DAILY_QUESTS_TIME_LEFT:
      return {
        ...state,
        dailyQuestsTimeLeft: action.value
      }

    case UPDATE_ACCOUNT_ID:
      return {
        ...state,
        accountId: action.value
      }

    case UPDATE_ACCOUNT_EMAIL:
      return {
        ...state,
        accountEmail: action.value
      }

    case TOGGLE_TOUCH_CONTROLS:
      return {
        ...state,
        touchControls: action.value
      }

    case LOADED_REMAINING_FILES:
      return {
        ...state,
        loadedRemainingFiles: action.value
      }

    case UPDATE_CHAT_INPUT:
      return {
        ...state,
        chatInput: action.input
      }

    case TOGGLE_CHATBOX_ACTIVE:
      return {
        ...state,
        chatboxActive: action.input
      }

    case UPDATE_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          // [action.settingType]: {
          videoSettings: {
            // ...state.settings[action.settingType],
            ...state.settings.videoSettings,
            [action.input.key]: action.input.value
          }
        }
      }

    case UPDATE_SELECTED_SCORE:
      return {
        ...state,
        selectedScore: action.input
      }

    case UPDATE_SELECTED_TIME_VALUE:
      return {
        ...state,
        selectedTimeValue: action.input
      }
    case UPDATE_SELECTED_HIGHSCORE_GAME_MODE:
        return {
            ...state,
            highscoreSelectedGameMode: action.input
        }
    case UPDATE_RANK:
      return {
        ...state,
        rankId: action.input
      }

    case UPDATE_RANK_SCORE:
      return {
        ...state,
        rankingScore: action.input
      }

    case HIDE_CROSS_LINKS:
      return {
        ...state,
        hideCrossLinks: action.input
      }

    case UPDATE_REFERRER:
      return {
        ...state,
        referrer: action.input
      }

    case UPDATE_DISABLE_ADBLOCK_POPUP:
      return {
        ...state,
        disableAdblockPopupActive: action.input
      }

    case UPDATE_TRIAL_VICTORY:
      return {
        ...state,
        trialVictory: action.input,
        'adId': state.adId + 1
      }

    case UPDATE_NEW_UPDATE_DIALOG:
      return {
        ...state,
        newUpdateDialog: action.input
      }

    case UPDATE_GAME_PLAYED_ONCE:
      return {
        ...state,
        gamePlayedOnce: action.input
      }

    case UPDATE_GLOBAL_CHAT:
      let newArray = state.globalChat.concat([action.input])
      if (newArray.length > 20) { newArray.shift() }
      return {
        ...state,
        globalChat: newArray
      }
      case RESET_GLOBAL_CHAT:
        return {
          ...state,
          globalChat: []
        }
    case SET_MIDDLE_LOWER_TEXT:
      return {
        ...state,
        middleLowerText: action.input
      }
    case UPDATE_EMOTE_BAR_DATA:
      return {
        ...state,
        emoteBarData: action.input
      }
    case SET_STATS_DATA:
      return {
        ...state,
        statsData: action.input
      }
    case SET_CUSTOM_BASE_STAT_CAPS:
        return {
            ...state,
            customBaseStatCaps: action.input
        }
    case UPDATE_RESOURCE_PROPERTIES:
      console.log('updated resource properties', action.input)
      return {
        ...state,
        resourceProperties: action.input
      }
      case UPDATE_RESOURCE:
          return {
            ...state,
            availableResources: {
              ...state.availableResources,
              [action.resourceType]: action.value
            }
          }
      case SET_RESOURCES:
          return {
              ...state,
              resources: action.input
          }
    case TOGGLE_SHOP:
        return {
            ...state,
            ingameShopActive: action.value
        }

    case TOGGLE_INGAME_SETTINGS_MENU:
      if (action.finalInput === 'toggle') {
        return {
          ...state,
          ingameSettingsActive: !state.ingameSettingsActive
        }
      }
      return {
        ...state,
        ingameSettingsActive: action.finalInput
      }
    case SET_SHOP_DATA:
        return {
            ...state,
          ingameShopData: action.input
        }
    case SET_GAME:
        return {
            ...state,
            currentGame: action.currentGame
        }
    case SET_SINGLE_GAME:
        return {
            ...state,
            singleGame: action.input
        }
    case SET_TITLE_LOADED:
        return {
            ...state,
            titleLoaded: action.input
        }
    case SDK_LOADED:
        return {
            ...state,
            sdkLoaded: action.input
        }
    case UPDATE_GENERIC_BAR_NAME:
      const id = action.id
      const propertyName = 'genericBar' + id + 'Name'
      const propertySettingsName = 'genericBar' + id + 'Settings'
      const settings = action.settings
      console.log('reducer settings', settings)
      return {
        ...state,
        [propertyName]: action.name,
        [propertySettingsName]: action.settings,
      }
    case UPDATE_GENERIC_BAR_VALUE:
      const barId = action.id
      const barPropertyName = 'genericBar' + barId + 'Value'
      return {
        ...state,
        [barPropertyName]: action.value
      }
    default:
        return state
  }
}