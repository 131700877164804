import Constants from '../shared/constants'
import { Pickups } from './index';
console.log('imported cpickups', Pickups)
import * as Actions from './actions/actions'
import {currentlyPressed, flushEntityCache, globalSpellCooldown} from './game'
import {minimapManager, playerCurrentUIState} from './uiScene'
export let equippedSpells = []
import { player, phaserObject, joysticks } from './create'
import { updatePlayerSprite, gameStarted, IS_TOUCH, pickupIsCurrency } from './game'
import { equipWeapon, equipBodyWear, equipTopWear, equipHandWear, setRank, equipHead, updateHandPosition } from './playerScripts'
import { currHP, initState, resetServerTimestamp } from './state'
import { gameanalytics } from './index'
import CurrencyData from "../shared/currency";
import ElementsData from "../shared/elements";
import { CustomTypes } from "./index";
let replayNumber = 1

export let loaded

export let stats = {
}

export let equipmentStats = {
  top: {},
  body: {},
  hand: {}
}


export let assignedDict = JSON.parse(JSON.stringify(Constants.ASSIGNED_SPELLS_TEMPLATE))

export function setLoadedFalse() {
  loaded = false
}

export let serverSettings

export let playerMultipliers = {}
export function joinGame(gameData) {

  // let serverSettings

    serverSettings = gameData[6]
    equipHead(player, gameData[0], true)
    setRank(player, gameData[1])
  if (gameData[2]) {
    serverSettings = gameData[2]
    console.log("SERVER SETTINGS", serverSettings)
    if (serverSettings.stats) {
      const statsKeys = {}
        serverSettings.stats.properties.forEach((stat) => {
            statsKeys[stat.name] = 0
        })
      Actions.setStats(statsKeys)
      Actions.setStatsData(gameData[2].stats)
    }
    if (serverSettings.defaultPlayerOptions.globalSpellCooldown) {
        globalSpellCooldown.baseValue = serverSettings.defaultPlayerOptions.globalSpellCooldown

    }
    if (serverSettings.resourceProperties) {
      Actions.updateResourceProperties(serverSettings.resourceProperties)
      if (serverSettings.resourceProperties.properties) {
        const resourceKeys = {}
        serverSettings.resourceProperties.properties.forEach((property) => {
          resourceKeys[property.name] = property.name
        })
        Actions.setResources(serverSettings.resourceProperties.properties)
      }
    }
  }
  setGameState(false)

  loaded = true
  console.log("CALLING INIT STATE")
  initState()
  
  let val = 0
  let interval = setInterval(() => {
    val += 1
    Actions.addLoadProgress(1)
    if (val === 40) {
      Actions.gameLoadComplete(true)
      clearInterval(interval)
      // setTimeout(() => {


      setTimeout(() => {
        // flushEntityCache()
        resetServerTimestamp()
      }, 1000)
      // }, 2000)
    }
  }, 20)



}
export function restartGame(gameData) {
  equipHead(player, gameData[0], true)
  setRank(player, gameData[1])
  setGameState()
}
function setGameState(setLoaded=true) {
  playerCurrentUIState.value = 'mainGame'

  if (IS_TOUCH) {
    joysticks.joystickL.visible = true
    joysticks.joystickL.enable = true
    joysticks.joystickR.visible = true
    joysticks.joystickR.enable = true
  }

  // gameanalytics.NewProgressionEvent(GAProgressionStatus.Start, "GameplaySession")
  gameanalytics.GameAnalytics.addProgressionEvent(gameanalytics.EGAProgressionStatus.Start,
      'GameplaySession', 'Standard', 'Standard')

  gameanalytics.GameAnalytics.addDesignEvent('design:playNumber:' + replayNumber)
  replayNumber += 1

  assignedDict = JSON.parse(JSON.stringify(Constants.ASSIGNED_SPELLS_TEMPLATE))

  player.healthBar.value = 100
  player.healthBar.draw()
  currHP.value = 100

  if (setLoaded) {
    loaded = true
  }
  gameStarted.value = true
  player.visible = true

  minimapManager.updateMinimapZoom()
  minimapManager.showMinimap()


}
export function setPlayerSpell(input) {
  const {spellSlot, spell} = input
  const idxToKey = {
    0: '1',
    1: '2',
    2: '3',
    3: '4',
    4: 'shift',
    5: '5',
  }
  assignedDict[idxToKey[spellSlot]] = spell
  assignedDict[idxToKey[spellSlot]].equipped = true
  assignedDict[idxToKey[spellSlot]].assignedKey = idxToKey[spellSlot]
  equippedSpells[spellSlot] = spell

  if (spellSlot == 0) {
    let weaponDrawn
    currentlyPressed == 1 ? weaponDrawn = true : weaponDrawn = false
    if (spell.baseTweenForwardDuration) {
      spell.baseTweenForwardDuration = JSON.parse(JSON.stringify(spell.tweenForwardDuration))
      spell.baseTweenBackwardDuration = JSON.parse(JSON.stringify(spell.tweenBackwardDuration))
    }
    if (spell.spellID !== 0) {
      equipWeapon(player, spell.spellID, weaponDrawn, phaserObject)
    }
  }
  Actions.updateSpells(JSON.parse(JSON.stringify(assignedDict)))
}
export function receivePickup(pickup) {
  switch (pickup.pickupType) {
    case 'spell':
      break
    case 'resource':
      Actions.updateResource(pickup.resourceType, pickup.quantity)
      break
    case 'bodyWear':
      equipBodyWear(player, pickup.id, true)
      break
    case 'topWear':
      equipTopWear(player, pickup.id, true)
      break
    case 'handWear':
      equipHandWear(player, pickup.id, true)
      break
    case 'currency':
      // console.log('received currency pickup', pickup)
      break

  }
}

export function updatePickupUI(pickup) {
  let pickupUIUpdate = null
  if (pickup) {
    // if (pickup.pickupType == 'currency') {pickupIsCurrency.value = true} else {pickupIsCurrency.value = false}
    switch (pickup.pickupType) {
      case 'spell':
        pickupUIUpdate = pickup.spellData
        break
      case 'mana':
        pickupUIUpdate = ElementsData.find(element => pickup.id == element.id)
        pickupUIUpdate.manaQuantity = pickup.manaQuantity
        break
      case 'pickup':
        pickupUIUpdate = pickup
        break
      case 'bodyWear':
        pickupUIUpdate = Pickups.bodyWear.find(gear => pickup.id == gear.id)
        break
      case 'topWear':
        pickupUIUpdate = Pickups.topWear.find(gear => pickup.id == gear.id)
        break
      case 'handWear':
        pickupUIUpdate = Pickups.handWear.find(gear => pickup.id == gear.id)
        break
      case 'currency':
        // console.log('received pickup', pickup)
        pickupUIUpdate = CurrencyData.find(currency => pickup.id == currency.id)
        pickupUIUpdate.quantity = pickup.quantity
        break
      case 'trial':
        pickupUIUpdate = pickup
        // pickupUIUpdate = TrialsData.find(trial => pickup.id == trial.id)
        // pickupUIUpdate.quantity = pickup.quantity
        break
      default:
        pickupUIUpdate = CustomTypes[pickup.pickupType].find(element => element.id == pickup.id)
        break
    }
    if (pickupUIUpdate) {
      pickupUIUpdate.pickupType = pickup.pickupType
    } else {
      console.log('attempted to update pickup UI with invalid pickup', pickup)
    }
  } else {
    // pickupIsCurrency.value = false
  }
  Actions.updatePickupUI(pickupUIUpdate)
}
// export function processStats(newStatData, equipmentStats, stats, updateStatPoints) {
export function processStats(newStatData, stats) {
    for (const [key, value] of Object.entries(newStatData)) {
      if (key != 'statPoints' && key != 'multipliers') {
        Actions.updateStats({ statName: key, statPoints: value})
        stats[key] = value
      }
    }
    
    Actions.updateStatPoints(newStatData.statPoints)
}
export function setStats(newStatData, stats) {
  let newDict = {}
  for (const [key, value] of Object.entries(newStatData)) {
    if (key != 'statPoints'&& key != 'multipliers') {
      newDict[key] = value
      stats[key] = value
    }
  }
  console.log("CALLING SET STATS", newDict)
  Actions.setStats(newDict)
 
  Actions.updateStatPoints(newStatData.statPoints)
}

