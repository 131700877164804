"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityPoolManager = void 0;
// Purpose of EntityPoolManager is to manage the pool arrays for different types of pool groups, and manage pooling groups for efficient access of pooled objects
// works like a queue, first in first out
class EntityPoolManager {
    constructor(phaserData) {
        this.phaserData = phaserData;
        this.objectPools = {};
        this.timedObjectPools = [];
    }
    addObjectToPool(poolType, objectType, object, maxCacheTimer = -1) {
        if (!this.objectPools[poolType]) {
            this.objectPools[poolType] = {};
        }
        if (!this.objectPools[poolType][objectType]) {
            this.objectPools[poolType][objectType] = [];
        }
        if (maxCacheTimer > 0) {
            Object.assign(object, { cacheTimer: maxCacheTimer });
            this.timedObjectPools.push({ poolType: poolType, objectType: objectType });
        }
        this.objectPools[poolType][objectType].push(object);
        // if (poolType !== 'objects'){console.log('[DEBUG] cached object. poolType:', poolType, 'objectType:', objectType, 'poolSize:', this.objectPools[poolType][objectType].length)}
    }
    getObjectFromPool(poolType, objectType) {
        if (this.objectPools[poolType] && this.objectPools[poolType][objectType] && this.objectPools[poolType][objectType].length > 0) {
            // if (poolType !== 'objects'){console.log('[DEBUG] getting object from pool. poolType:', poolType, 'objectType:', objectType, 'poolSize:', this.objectPools[poolType][objectType].length)}
            let obj = this.objectPools[poolType][objectType].shift();
            if (typeof obj.cacheTimer === 'number') {
                delete obj.cacheTimer;
            }
            return obj;
        }
        return null;
    }
    getPoolSize(poolType, objectType) {
        if (this.objectPools[poolType] && this.objectPools[poolType][objectType]) {
            return this.objectPools[poolType][objectType].length;
        }
        return 0;
    }
    poolExists(poolType, objectType) {
        if (this.objectPools[poolType] && this.objectPools[poolType][objectType]) {
            return true;
        }
        return false;
    }
    updateTimedPools(dt) {
        this.timedObjectPools.forEach((object) => {
            if (this.objectPools[object.poolType] && this.objectPools[object.poolType][object.objectType] && this.objectPools[object.poolType][object.objectType].length > 0) {
                this.objectPools[object.poolType][object.objectType].forEach((poolObject) => {
                    if (poolObject.cacheTimer > 0) {
                        poolObject.cacheTimer -= dt;
                    }
                    if (poolObject.cacheTimer <= 0) {
                        this.clearPoolObject(object.poolType, object.objectType, poolObject);
                    }
                });
            }
        });
    }
    // phaser object destroy for sprites & text, etc
    // WARNING: this will destroy the object, but it's not taking into account any extra objects added to it as a prop, so depending on the type, must be careful!
    // usage only for text objects that can be destroyed by itself right now
    clearPoolObject(poolType, objectType, object) {
        if (this.objectPools[poolType] && this.objectPools[poolType][objectType] && this.objectPools[poolType][objectType].length > 0) {
            let index = this.objectPools[poolType][objectType].indexOf(object);
            if (index > -1) {
                this.objectPools[poolType][objectType].splice(index, 1);
            }
            object.destroy();
        }
    }
    clearPool(poolType, objectType) {
        // TODO: not Implemented yet. has to have different logic for different entities, which might be better to be handled in the game logic
        switch (poolType) {
            case 'objectPool':
                break;
            case 'spineEnemies':
                break;
            case 'enemies':
                break;
            case 'bullets':
            default:
                break;
        }
        console.log('cleared entity pool for: ', poolType, objectType);
    }
    clearAllPools() {
        for (let prop in this.objectPools) {
            for (let subProp in this.objectPools[prop]) {
                this.clearPool(prop, subProp);
            }
        }
        this.objectPools = {};
    }
}
exports.EntityPoolManager = EntityPoolManager;
