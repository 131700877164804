"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handWear = void 0;
const drops_1 = require("../../../../drops");
const statIncreases = {
    big: {
        t1: 40,
        t2: 80,
        t3: 100,
        t4: 120,
        t5: 140,
        t6: 180,
    },
    small: {
        t1: 3,
        t2: 5,
        t3: 6,
        t4: 7,
        t5: 8,
        t6: 10,
    }
};
exports.handWear = [{
        id: 1,
        name: 'Adventurer Gauntlets',
        refName: 'hands_starter',
        rarity: 'common',
        stats: {},
        imgPath: 'hand.png',
    },
    {
        id: 2,
        name: 'Wizard Gloves',
        refName: 'hands_wizard_uncommon',
        rarity: 'uncommon',
        stats: {
        // damage: 1
        },
        imgPath: 'winter-gloves.png',
    },
    {
        id: 3,
        name: 'Rogue Gauntlets',
        refName: 'hands_rogue_uncommon',
        rarity: 'uncommon',
        stats: {
        // agility: 1
        },
        imgPath: 'rogue-gloves.png',
    },
    {
        id: 4,
        name: 'Knight Gauntlets',
        refName: 'hands_knight_uncommon',
        rarity: 'uncommon',
        stats: {
        // health: 1,
        },
        imgPath: 'knight-gauntlets.png',
    },
    {
        id: 5,
        name: 'Wizard Gloves',
        refName: 'hands_wizard_rare',
        rarity: 'rare',
        stats: {
        // damage: 1,
        // agility: 2,
        },
        imgPath: 'winter-gloves.png',
    },
    {
        id: 6,
        name: 'Rogue Gauntlets',
        refName: 'hands_rogue_rare',
        rarity: 'rare',
        stats: {
        // agility: 2,
        // speed: 1,
        },
        imgPath: 'rogue-gloves.png',
    },
    {
        id: 7,
        name: 'Knight Gauntlets',
        refName: 'hands_knight_rare',
        rarity: 'rare',
        stats: {
        // health: 1,
        // damage: 2,
        },
        imgPath: 'knight-gauntlets.png',
    },
    {
        id: 8,
        name: 'Wizard Gloves',
        refName: 'hands_wizard_epic',
        rarity: 'epic',
        stats: {
        // damage: 1,
        // agility: 2,
        },
        imgPath: 'winter-gloves.png',
    },
    {
        id: 9,
        name: 'Rogue Gauntlets',
        refName: 'hands_rogue_epic',
        rarity: 'epic',
        stats: {
        // agility: 2,
        // speed: 2,
        },
        imgPath: 'rogue-gloves.png',
    },
    {
        id: 10,
        name: 'Knight Gauntlets',
        refName: 'hands_knight_epic',
        rarity: 'epic',
        stats: {
        // health: 2,
        // damage: 2,
        },
        imgPath: 'knight-gauntlets.png',
    },
    {
        id: 11,
        name: 'Wizard Gloves',
        refName: 'hands_wizard_legendary',
        rarity: 'legendary',
        stats: {
        // damage: 2,
        // agility: 2,
        },
        imgPath: 'winter-gloves.png',
    },
    {
        id: 12,
        name: 'Rogue Gauntlets',
        refName: 'hands_rogue_legendary',
        rarity: 'legendary',
        stats: {
        // agility: 3,
        // speed: 2,
        },
        imgPath: 'rogue-gloves.png',
    },
    {
        id: 13,
        name: 'Knight Gauntlets',
        refName: 'hands_knight_legendary',
        rarity: 'legendary',
        stats: {
        // health: 2,
        // damage: 3,
        },
        imgPath: 'knight-gauntlets.png',
    },
    {
        id: 14,
        name: 'Slime Gauntlets',
        refName: 'hands_slime_rare',
        rarity: 'rare',
        stats: {
        // health: 1,
        // speed: 1,
        // damage: 1,
        },
        imgPath: 'slime-gloves.png',
        // broad: true,
    },
    {
        id: 16,
        name: 'Slime Gauntlets',
        refName: 'hands_slime_legendary',
        rarity: 'legendary',
        stats: {
        // health: 1,
        // speed: 2,
        // agility: 1,
        // damage: 1,
        },
        imgPath: 'slime-gloves.png',
    },
    {
        id: 18,
        name: 'Ice Gauntlets',
        refName: 'hands_ice_legendary',
        rarity: 'legendary',
        stats: {
        // health: 5,
        },
        imgPath: 'icon_ice_armour_gloves.png',
    },
    {
        id: 19,
        name: 'Crab Gauntlets',
        refName: 'hands_crab',
        rarity: 'rare',
        stats: {
        // damage: 2,
        // agility: 1,
        },
        traits: {
            spiky: 0.10
        },
        imgPath: 'icon_crab_armour_gloves.png',
    },
    {
        id: 101,
        name: 'T0 Defense Glove',
        rarity: 'common',
        stats: {
        // defense: 1
        },
        refName: 'hands_knight_uncommon',
        imgPath: 'knight-gauntlets.png',
        customCircleColor: drops_1.gloveSpellColors.t0.customCircleColor
    },
    {
        id: 102,
        name: 'T0 Agility Glove',
        rarity: 'common',
        stats: {
        // agility: 1
        },
        refName: 'hands_rogue_uncommon',
        imgPath: 'rogue-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t0.customCircleColor
    },
    {
        id: 103,
        name: 'T0 Spirit Glove',
        rarity: 'common',
        stats: {
        // spirit: 1
        },
        refName: 'hands_wizard_uncommon',
        imgPath: 'winter-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t0.customCircleColor
    },
    {
        id: 111,
        name: 'T1 Plate Gauntlet',
        rarity: 'uncommon',
        stats: {
            health: 1
        },
        refName: 'hands_knight_uncommon',
        imgPath: 'knight-gauntlets.png',
        customCircleColor: drops_1.gloveSpellColors.t1.customCircleColor
    },
    {
        id: 112,
        name: 'T2 Plate Gauntlet',
        rarity: 'uncommon',
        stats: {
            health: 2
        },
        refName: 'hands_knight_uncommon',
        imgPath: 'knight-gauntlets.png',
        customCircleColor: drops_1.gloveSpellColors.t2.customCircleColor
    },
    {
        id: 113,
        name: 'T3 Plate Gauntlet',
        rarity: 'uncommon',
        stats: {
            health: 3
        },
        refName: 'hands_knight_uncommon',
        imgPath: 'knight-gauntlets.png',
        customCircleColor: drops_1.gloveSpellColors.t3.customCircleColor
    },
    {
        id: 114,
        name: 'T4 Plate Gauntlet',
        rarity: 'uncommon',
        stats: {
            health: 4
        },
        refName: 'hands_knight_uncommon',
        imgPath: 'knight-gauntlets.png',
        customCircleColor: drops_1.gloveSpellColors.t4.customCircleColor
    },
    {
        id: 115,
        name: 'T5 Plate Gauntlet',
        rarity: 'uncommon',
        stats: {
            health: 5
        },
        refName: 'hands_knight_uncommon',
        imgPath: 'knight-gauntlets.png',
        customCircleColor: drops_1.gloveSpellColors.t5.customCircleColor
    },
    {
        id: 116,
        name: 'T6 Plate Gauntlete',
        rarity: 'rare',
        stats: {
            health: 6
        },
        refName: 'hands_knight_rare',
        imgPath: 'knight-gauntlets.png',
        customCircleColor: drops_1.gloveSpellColors.t6.customCircleColor
    },
    {
        id: 117,
        name: 'T7 Plate Gauntlete',
        rarity: 'rare',
        stats: {
            health: 7
        },
        refName: 'hands_knight_rare',
        imgPath: 'knight-gauntlets.png',
        customCircleColor: drops_1.gloveSpellColors.t7.customCircleColor
    },
    {
        id: 118,
        name: 'T8 Plate Gauntlete',
        rarity: 'rare',
        stats: {
            health: 8
        },
        refName: 'hands_knight_rare',
        imgPath: 'knight-gauntlets.png',
        customCircleColor: drops_1.gloveSpellColors.t8.customCircleColor
    },
    {
        id: 119,
        name: 'T9 Plate Gauntlete',
        rarity: 'epic',
        stats: {
            health: 9
        },
        refName: 'hands_knight_epic',
        imgPath: 'knight-gauntlets.png',
        customCircleColor: drops_1.gloveSpellColors.t9.customCircleColor
    },
    {
        id: 120,
        name: 'T10 Plate Gauntlete',
        rarity: 'epic',
        stats: {
            health: 10
        },
        refName: 'hands_knight_epic',
        imgPath: 'knight-gauntlets.png',
        customCircleColor: drops_1.gloveSpellColors.t10.customCircleColor
    },
    // {
    //     id: 121,
    //     name: 'T1 Defense Glove',
    //     rarity: 'common',
    //     stats: {
    //         // defense: statIncreases.small.t1
    //     },
    //     refName: 'hands_knight_uncommon',
    //     imgPath: 'knight-gauntlets.png',
    //     customCircleColor: gloveSpellColors.t1.customCircleColor
    // },
    // {
    //     id: 122,
    //     name: 'T2 Defense Glove',
    //     rarity: 'uncommon',
    //     stats: {
    //         // defense: statIncreases.small.t2
    //     },
    //     refName: 'hands_knight_rare',
    //     imgPath: 'knight-gauntlets.png',
    //     customCircleColor: gloveSpellColors.t2.customCircleColor
    // },
    // {
    //     id: 123,
    //     name: 'T3 Defense Glove',
    //     rarity: 'rare',
    //     stats: {
    //         // defense: statIncreases.small.t3
    //     },
    //     refName: 'hands_knight_rare',
    //     imgPath: 'knight-gauntlets.png',
    //     customCircleColor: gloveSpellColors.t3.customCircleColor
    // },
    // {
    //     id: 124,
    //     name: 'T4 Defense Glove',
    //     rarity: 'rare',
    //     stats: {
    //         // defense: statIncreases.small.t4
    //     },
    //     refName: 'hands_knight_rare',
    //     imgPath: 'knight-gauntlets.png',
    //     customCircleColor: gloveSpellColors.t4.customCircleColor
    // },
    // {
    //     id: 125,
    //     name: 'T5 Defense Glove',
    //     rarity: 'epic',
    //     stats: {
    //         // defense: statIncreases.small.t5
    //     },
    //     refName: 'hands_knight_epic',
    //     imgPath: 'knight-gauntlets.png',
    //     customCircleColor: gloveSpellColors.t5.customCircleColor
    // },
    // {
    //     id: 126,
    //     name: 'T6 Defense Glove',
    //     rarity: 'legendary',
    //     stats: {
    //         // defense: statIncreases.small.t6
    //     },
    //     refName: 'hands_knight_legendary',
    //     imgPath: 'knight-gauntlets.png',
    //     customCircleColor: gloveSpellColors.t6.customCircleColor
    // },
    // {
    //     id: 131,
    //     name: 'T1 Vitality Glove',
    //     rarity: 'common',
    //     stats: {
    //         // vitality: statIncreases.small.t1
    //     },
    //     refName: 'hands_knight_uncommon',
    //     imgPath: 'knight-gauntlets.png',
    //     customCircleColor: gloveSpellColors.t1.customCircleColor
    // },
    // {
    //     id: 132,
    //     name: 'T2 Vitality Glove',
    //     rarity: 'uncommon',
    //     stats: {
    //         // vitality: statIncreases.small.t2
    //     },
    //     refName: 'hands_knight_rare',
    //     imgPath: 'knight-gauntlets.png',
    //     customCircleColor: gloveSpellColors.t2.customCircleColor
    // },
    // {
    //     id: 133,
    //     name: 'T3 Vitality Glove',
    //     rarity: 'rare',
    //     stats: {
    //         // vitality: statIncreases.small.t3
    //     },
    //     refName: 'hands_knight_rare',
    //     imgPath: 'knight-gauntlets.png',
    //     customCircleColor: gloveSpellColors.t3.customCircleColor
    // },
    // {
    //     id: 134,
    //     name: 'T4 Vitality Glove',
    //     rarity: 'rare',
    //     stats: {
    //         // vitality: statIncreases.small.t4
    //     },
    //     refName: 'hands_knight_rare',
    //     imgPath: 'knight-gauntlets.png',
    //     customCircleColor: gloveSpellColors.t4.customCircleColor
    // },
    // {
    //     id: 135,
    //     name: 'T5 Vitality Glove',
    //     rarity: 'epic',
    //     stats: {
    //         // vitality: statIncreases.small.t5
    //     },
    //     refName: 'hands_knight_epic',
    //     imgPath: 'knight-gauntlets.png',
    //     customCircleColor: gloveSpellColors.t5.customCircleColor
    // },
    // {
    //     id: 136,
    //     name: 'T6 Vitality Glove',
    //     rarity: 'legendary',
    //     stats: {
    //         // vitality: statIncreases.small.t6
    //     },
    //     refName: 'hands_knight_legendary',
    //     imgPath: 'knight-gauntlets.png',
    //     customCircleColor: gloveSpellColors.t6.customCircleColor
    // },
    {
        id: 141,
        name: 'T1 Leather Glove',
        rarity: 'uncommon',
        stats: {
            monsterHealthRegen: 1
        },
        refName: 'hands_rogue_uncommon',
        imgPath: 'rogue-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t1.customCircleColor
    },
    {
        id: 142,
        name: 'T2 Leather Glove',
        rarity: 'uncommon',
        stats: {
            monsterHealthRegen: 2
        },
        refName: 'hands_rogue_uncommon',
        imgPath: 'rogue-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t2.customCircleColor
    },
    {
        id: 143,
        name: 'T3 Leather Glove',
        rarity: 'uncommon',
        stats: {
            monsterHealthRegen: 3
        },
        refName: 'hands_rogue_uncommon',
        imgPath: 'rogue-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t3.customCircleColor
    },
    {
        id: 144,
        name: 'T4 Leather Glove',
        rarity: 'uncommon',
        stats: {
            monsterHealthRegen: 4
        },
        refName: 'hands_rogue_uncommon',
        imgPath: 'rogue-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t4.customCircleColor
    },
    {
        id: 145,
        name: 'T5 Leather Glove',
        rarity: 'uncommon',
        stats: {
            monsterHealthRegen: 5
        },
        refName: 'hands_rogue_uncommon',
        imgPath: 'rogue-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t5.customCircleColor
    },
    {
        id: 146,
        name: 'T6 Leather Glove',
        rarity: 'rare',
        stats: {
            monsterHealthRegen: 6
        },
        refName: 'hands_rogue_rare',
        imgPath: 'rogue-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t6.customCircleColor
    },
    {
        id: 147,
        name: 'T7 Leather Glove',
        rarity: 'rare',
        stats: {
            monsterHealthRegen: 7
        },
        refName: 'hands_rogue_rare',
        imgPath: 'rogue-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t7.customCircleColor
    },
    {
        id: 148,
        name: 'T8 Leather Glove',
        rarity: 'rare',
        stats: {
            monsterHealthRegen: 8
        },
        refName: 'hands_rogue_rare',
        imgPath: 'rogue-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t8.customCircleColor
    },
    {
        id: 149,
        name: 'T9 Leather Glove',
        rarity: 'rare',
        stats: {
            monsterHealthRegen: 9
        },
        refName: 'hands_rogue_rare',
        imgPath: 'rogue-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t9.customCircleColor
    },
    {
        id: 150,
        name: 'T10 Leather Glove',
        rarity: 'rare',
        stats: {
            monsterHealthRegen: 10
        },
        refName: 'hands_rogue_rare',
        imgPath: 'rogue-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t10.customCircleColor
    },
    // {
    //     id: 151,
    //     name: 'T1 Agility Glove',
    //     rarity: 'common',
    //     stats: {
    //         // agility: statIncreases.small.t1
    //     },
    //     refName: 'hands_rogue_uncommon',
    //     imgPath: 'rogue-gloves.png',
    //     customCircleColor: gloveSpellColors.t1.customCircleColor
    // },
    // {
    //     id: 152,
    //     name: 'T2 Agility Glove',
    //     rarity: 'uncommon',
    //     stats: {
    //         // agility: statIncreases.small.t2
    //     },
    //     refName: 'hands_rogue_rare',
    //     imgPath: 'rogue-gloves.png',
    //     customCircleColor: gloveSpellColors.t2.customCircleColor
    // },
    // {
    //     id: 153,
    //     name: 'T3 Agility Glove',
    //     rarity: 'rare',
    //     stats: {
    //         // agility: statIncreases.small.t3
    //     },
    //     refName: 'hands_rogue_rare',
    //     imgPath: 'rogue-gloves.png',
    //     customCircleColor: gloveSpellColors.t3.customCircleColor
    // },
    // {
    //     id: 154,
    //     name: 'T4 Agility Glove',
    //     rarity: 'rare',
    //     stats: {
    //         // agility: statIncreases.small.t4
    //     },
    //     refName: 'hands_rogue_rare',
    //     imgPath: 'rogue-gloves.png',
    //     customCircleColor: gloveSpellColors.t4.customCircleColor
    // },
    // {
    //     id: 155,
    //     name: 'T5 Agility Glove',
    //     rarity: 'epic',
    //     stats: {
    //         // agility: statIncreases.small.t5
    //     },
    //     refName: 'hands_rogue_epic',
    //     imgPath: 'rogue-gloves.png',
    //     customCircleColor: gloveSpellColors.t5.customCircleColor
    // },
    // {
    //     id: 156,
    //     name: 'T6 Agility Glove',
    //     rarity: 'legendary',
    //     stats: {
    //         // agility: statIncreases.small.t6
    //     },
    //     refName: 'hands_rogue_legendary',
    //     imgPath: 'rogue-gloves.png',
    //     customCircleColor: gloveSpellColors.t6.customCircleColor
    // },
    // {
    //     id: 161,
    //     name: 'T1 Speed Glove',
    //     rarity: 'common',
    //     stats: {
    //         // speed: statIncreases.small.t1
    //     },
    //     refName: 'hands_rogue_uncommon',
    //     imgPath: 'rogue-gloves.png',
    //     customCircleColor: gloveSpellColors.t1.customCircleColor
    // },
    // {
    //     id: 162,
    //     name: 'T2 Speed Glove',
    //     rarity: 'uncommon',
    //     stats: {
    //         // speed: statIncreases.small.t2
    //     },
    //     refName: 'hands_rogue_rare',
    //     imgPath: 'rogue-gloves.png',
    //     customCircleColor: gloveSpellColors.t2.customCircleColor
    // },
    // {
    //     id: 163,
    //     name: 'T3 Speed Glove',
    //     rarity: 'rare',
    //     stats: {
    //         // speed: statIncreases.small.t3
    //     },
    //     refName: 'hands_rogue_rare',
    //     imgPath: 'rogue-gloves.png',
    //     customCircleColor: gloveSpellColors.t3.customCircleColor
    // },
    // {
    //     id: 164,
    //     name: 'T4 Speed Glove',
    //     rarity: 'rare',
    //     stats: {
    //         // speed: statIncreases.small.t4
    //     },
    //     refName: 'hands_rogue_rare',
    //     imgPath: 'rogue-gloves.png',
    //     customCircleColor: gloveSpellColors.t4.customCircleColor
    // },
    // {
    //     id: 165,
    //     name: 'T5 Speed Glove',
    //     rarity: 'epic',
    //     stats: {
    //         // speed: statIncreases.small.t5
    //     },
    //     refName: 'hands_rogue_epic',
    //     imgPath: 'rogue-gloves.png',
    //     customCircleColor: gloveSpellColors.t5.customCircleColor
    // },
    // {
    //     id: 166,
    //     name: 'T6 Speed Glove',
    //     rarity: 'legendary',
    //     stats: {
    //         // speed: statIncreases.small.t6
    //     },
    //     refName: 'hands_rogue_legendary',
    //     imgPath: 'rogue-gloves.png',
    //     customCircleColor: gloveSpellColors.t6.customCircleColor
    // },
    {
        id: 171,
        name: 'T1 Cloth Glove',
        rarity: 'uncommon',
        stats: {
            monsterManaRegen: 1
        },
        refName: 'hands_wizard_uncommon',
        imgPath: 'winter-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t1.customCircleColor
    },
    {
        id: 172,
        name: 'T2 Cloth Glove',
        rarity: 'uncommon',
        stats: {
            monsterManaRegen: 2
        },
        refName: 'hands_wizard_uncommon',
        imgPath: 'winter-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t2.customCircleColor
    },
    {
        id: 173,
        name: 'T3 Cloth Glove',
        rarity: 'uncommon',
        stats: {
            monsterManaRegen: 3
        },
        refName: 'hands_wizard_uncommon',
        imgPath: 'winter-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t3.customCircleColor
    },
    {
        id: 174,
        name: 'T4 Cloth Glove',
        rarity: 'uncommon',
        stats: {
            monsterManaRegen: 4
        },
        refName: 'hands_wizard_uncommon',
        imgPath: 'winter-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t4.customCircleColor
    },
    {
        id: 175,
        name: 'T5 Cloth Glove',
        rarity: 'uncommon',
        stats: {
            monsterManaRegen: 5
        },
        refName: 'hands_wizard_uncommon',
        imgPath: 'winter-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t5.customCircleColor
    },
    {
        id: 176,
        name: 'T6 Cloth Glove',
        rarity: 'rare',
        stats: {
            monsterManaRegen: 6
        },
        refName: 'hands_wizard_rare',
        imgPath: 'winter-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t6.customCircleColor
    },
    {
        id: 177,
        name: 'T7 Cloth Glove',
        rarity: 'rare',
        stats: {
            monsterManaRegen: 7
        },
        refName: 'hands_wizard_rare',
        imgPath: 'winter-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t7.customCircleColor
    },
    {
        id: 178,
        name: 'T8 Cloth Glove',
        rarity: 'rare',
        stats: {
            monsterManaRegen: 8
        },
        refName: 'hands_wizard_rare',
        imgPath: 'winter-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t8.customCircleColor
    },
    {
        id: 179,
        name: 'T9 Cloth Glove',
        rarity: 'rare',
        stats: {
            monsterManaRegen: 9
        },
        refName: 'hands_wizard_epic',
        imgPath: 'winter-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t9.customCircleColor
    },
    {
        id: 180,
        name: 'T10 Cloth Glove',
        rarity: 'rare',
        stats: {
            monsterManaRegen: 10
        },
        refName: 'hands_wizard_epic',
        imgPath: 'winter-gloves.png',
        customCircleColor: drops_1.gloveSpellColors.t10.customCircleColor
    },
    // {
    //     id: 181,
    //     name: 'T1 Spirit Glove',
    //     rarity: 'common',
    //     stats: {
    //         // spirit: statIncreases.small.t1
    //     },
    //     refName: 'hands_wizard_uncommon',
    //     imgPath: 'winter-gloves.png',
    //     customCircleColor: gloveSpellColors.t1.customCircleColor
    // },
    // {
    //     id: 182,
    //     name: 'T2 Spirit Glove',
    //     rarity: 'uncommon',
    //     stats: {
    //         // spirit: statIncreases.small.t2
    //     },
    //     refName: 'hands_wizard_rare',
    //     imgPath: 'winter-gloves.png',
    //     customCircleColor: gloveSpellColors.t2.customCircleColor
    // },
    // {
    //     id: 183,
    //     name: 'T3 Spirit Glove',
    //     rarity: 'rare',
    //     stats: {
    //         // spirit: statIncreases.small.t3
    //     },
    //     refName: 'hands_wizard_rare',
    //     imgPath: 'winter-gloves.png',
    //     customCircleColor: gloveSpellColors.t3.customCircleColor
    // },
    // {
    //     id: 184,
    //     name: 'T4 Spirit Glove',
    //     rarity: 'rare',
    //     stats: {
    //         // spirit: statIncreases.small.t4
    //     },
    //     refName: 'hands_wizard_rare',
    //     imgPath: 'winter-gloves.png',
    //     customCircleColor: gloveSpellColors.t4.customCircleColor
    // },
    // {
    //     id: 185,
    //     name: 'T5 Spirit Glove',
    //     rarity: 'epic',
    //     stats: {
    //         // spirit: statIncreases.small.t5
    //     },
    //     refName: 'hands_wizard_epic',
    //     imgPath: 'winter-gloves.png',
    //     customCircleColor: gloveSpellColors.t5.customCircleColor
    // },
    // {
    //     id: 186,
    //     name: 'T6 Spirit Glove',
    //     rarity: 'legendary',
    //     stats: {
    //         // spirit: statIncreases.small.t6
    //     },
    //     refName: 'hands_wizard_legendary',
    //     imgPath: 'winter-gloves.png',
    //     customCircleColor: gloveSpellColors.t6.customCircleColor
    // },
];
