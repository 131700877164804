"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RankingData = void 0;
exports.RankingData = [
    {
        id: 0,
        name: 'Slime',
        rank: 'slime',
        imgPath: 'enemy_slime.png',
        score: 0,
        type: 'bronze',
        circleColor: 0xd68a56,
    },
    {
        id: 1,
        name: 'Flower',
        rank: 'flower',
        imgPath: 'enemy_flowerCreature.png',
        type: 'bronze',
        circleColor: 0xd68a56,
        score: 25000,
    },
    {
        id: 2,
        name: 'Starfish',
        rank: 'starfish',
        imgPath: 'starfish_normal.png',
        score: 75000,
        type: 'bronze',
        circleColor: 0xd68a56,
    },
    {
        id: 3,
        name: 'Freddie',
        rank: 'freddie',
        imgPath: 'enemy_crabNormal.png',
        score: 125000,
        type: 'bronze',
        circleColor: 0xd68a56,
    },
    {
        id: 4,
        name: 'Woodland',
        rank: 'woodlandCreature',
        imgPath: 'enemy_woodlandWhelp.png',
        score: 200000,
        type: 'bronze',
        circleColor: 0xd68a56,
    },
    {
        id: 5,
        name: 'Chimera',
        rank: 'chimera',
        imgPath: 'enemy_leopard.png',
        score: 350000,
        type: 'bronze',
        circleColor: 0xd68a56,
    },
    {
        id: 6,
        name: 'Cannon',
        rank: 'cannon',
        imgPath: 'enemy_cannon.png',
        score: 650000,
        type: 'bronze',
        circleColor: 0xd68a56,
    },
    {
        id: 7,
        name: 'Seahorse',
        rank: 'seahorse',
        imgPath: 'enemy_seahorse.png',
        score: 1000000,
        type: 'bronze',
        circleColor: 0xd68a56,
    },
    {
        id: 8,
        name: 'Piranha',
        rank: 'piranha',
        imgPath: 'enemy_piranha.png',
        score: 1500000,
        type: 'silver',
        circleColor: 0xe3f5ff,
    },
    {
        id: 9,
        name: 'Charlie',
        rank: 'charlie',
        imgPath: 'enemy_evilCreature.png',
        score: 2500000,
        type: 'silver',
        circleColor: 0xe3f5ff,
    },
    {
        id: 10,
        name: 'Dark Wizard',
        rank: 'darkWizard',
        imgPath: 'enemy_darkWiz.png',
        score: 4500000,
        type: 'silver',
        circleColor: 0xe3f5ff,
    },
    {
        id: 11,
        name: 'Mammoth',
        rank: 'mammoth',
        imgPath: 'enemy_mammoth.png',
        score: 7000000,
        type: 'silver',
        circleColor: 0xffd522,
    },
    {
        id: 12,
        name: 'Dragon Lord',
        rank: 'dragonLord',
        imgPath: 'enemy_dragonBoss.png',
        score: 10000000,
        type: 'gold',
        circleColor: 0xffd522,
    },
];
