import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'

class AlertMessage extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    componentDidMount() {

    }

    render() {
        let { alertMessage } = this.props
        if (!alertMessage || alertMessage == '') {
            return null
        } else {
            return (
                <div>
                    <Grid container spacing={0} justifyContent="center" style={{ margin: 'auto' }}>
                        <Grid item xs={12} >
                            <Paper style={{
                                userSelect: 'none', textAlign: 'center', backgroundColor: 'white',
                                borderRadius: '2px',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                borderColor: 'rgba(0,0,0,0.25)',
                                boxShadow: '0 0 0 2px #362D44',
                            }}>
                                <h1 >
                                    {alertMessage}
                                </h1>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }
}


function mapStateToProps(state) {
    return {
        // pickupUIItem: state.stuff.pickupUIItem,
        // adblock: state.stuff.adblock,
        // middleLowerText: state.stuff.middleLowerText,
        alertMessage: state.stuff.alertMessage,
    }
}

export default connect(
    mapStateToProps,
)(AlertMessage)
