import React, { Component } from 'react'
import { ButtonBase } from '@mui/material'

class CosmeticItem extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        let { borderColor, locked, imgPath, topOffset, imgWidth, acquired } = this.props
        return (
            <ButtonBase
                style={{
                    borderRadius: '15px',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: borderColor,
                    boxShadow: '0 0 0 2px #C79B7B',

                    width: '67px',
                    height: '67px',
                    margin: '1%'
                }}
            >
                {!locked &&
                    <img style={{
                        position: 'relative', pointerEvents: 'none',
                        top: topOffset,
                        width: imgWidth,
                    }}
                        src={imgPath}
                    />
                }
                {locked &&
                    <>
                        <img style={{
                            position: 'relative',
                            pointerEvents: 'none',
                            top: topOffset,
                            width: imgWidth,
                        }}
                            src={imgPath}
                        />
                        <img style={{
                            position: 'absolute',
                            width: '44px',
                            top: '-15px',
                            right: '-15px',
                        }}
                            src="assets/ui/icon_lock.png" />
                    </>
                }
                {acquired &&
                    <>
                        {/* <img style={{
                            position: 'relative',
                            pointerEvents: 'none',
                            top: topOffset,
                            width: imgWidth,
                        }}
                            src={imgPath}
                        /> */}
                        <img style={{
                            position: 'absolute',
                            // width: '44px',
                            // top: '-15px',
                            // right: '-15px',
                        }}
                            src="assets/ui/checkmark.png" />
                    </>
                }
            </ButtonBase>
        )
    }
}

export default (CosmeticItem)