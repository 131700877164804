import React, { Component } from 'react'
import {Grid, Box, } from '@mui/material'
import { connect } from 'react-redux'
import {
    updateInGameMenuTab,
    toggleShop
} from '../actions/actions'
import ColorChangingButton from './ColorChangingButton.js'
import {selectShopItem} from "../networking";
import {StyledText} from "./TextStyling";
import GenericMenu from "./genericMenuComponents/GenericMenu";
import {GenericMenuItemGrid} from "./genericMenuComponents/GenericMenuItemGrid";
import ColorTable from "../../shared/colorTable";
import ResourceContainer from './ResourceContainer'

class IngameShop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dialog: true,
            // page: 0,
            // tab: 0,
            selectedItemIndex: undefined,
            selectedItem: undefined,
            dialogNewItem: false,
            itemEarnedPath: 'assets/emotes/smile.png',
            itemEarned: {
                id: 1,
                name: 'Thumbs Up',
                imgPath: 'thumb_up.png',
                rarity: 'common',
                unlockRequirement: null,
                free: true,
            },
            itemEarnedMessage: 'Legendary Emote',
            showBuyButton: true,
        }
    }

    componentDidMount() {
    }
    shouldComponentUpdate(nextProps, nextState) {
        return true
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.inGameMenuTab !== this.props.inGameMenuTab) {
            // this.setTab(this.props.inGameMenuTab)
            this.setState({
                // tab: this.props.inGameMenuTab,
                selectedItem: undefined,
                selectedItemIndex: undefined,

            })
        } else {
            const updatedItem = this.props.ingameShopData.categories[this.props.inGameMenuTab].items[this.state.selectedItemIndex]
            if (this.state.selectedItemIndex !== undefined && JSON.stringify(this.state.selectedItem) !== JSON.stringify(updatedItem)) {
                this.setState({
                    selectedItem: updatedItem
                })
            }
        }
    }

    setTab(tab) {
        const currentTab = this.props.inGameMenuTab
        if (currentTab !== tab) {
            updateInGameMenuTab(tab)
            this.setState({
                selectedItem: undefined,
                selectedItemIndex: undefined,
            })
        }
    }
    updateSelectedItem(item, index) {
        this.setState({
            selectedItem: item,
            selectedItemIndex: index
        })
    }
    render() {
        let { ingameShopActive, ingameShopData, inGameMenuTab} = this.props
        let {
            // tab,
            selectedItem} = this.state

        const handleClickOpen = () => {
            toggleShop(true)
        }
        const handleClose = () => {
            this.setState({
                selectedItem: undefined,
                selectedItemIndex: undefined,
            })
            toggleShop(false)
        }

        let tabToShow = inGameMenuTab
        if (!ingameShopData.categories[inGameMenuTab]) {
            updateInGameMenuTab(0)
            tabToShow = 0
        }

        const categoryData = ingameShopData.categories[tabToShow]
        console.log('categoryData', categoryData)
        let includeResourceContainer = true
        if (categoryData.hideResources) {
            includeResourceContainer = false
        }
        const handleCloseNewItem = () => { this.setState({ 'dialogNewItem': false }) }

        return (
            <GenericMenu
                menuActive={ingameShopActive}
                onOpen={handleClickOpen}
                onClose={handleClose}
                menuColumns={ingameShopData.categories}
                title='Menu'
                onChangeTab={(tab) => { this.setTab(tab)}}
                style={{height: '100%'}}
                currentTab={tabToShow}
                includeResourceContainer={includeResourceContainer}
            >
                <Grid container xs={12}
                      style={{
                          maxHeight: '400px',
                          // minHeight: '400px'
                      }}
                >
                    <Grid xs={6}
                          style={{
                              maxHeight: '100%',
                              overflow: 'auto',
                          }}
                    >
                        <Grid container xs={12}
                              style={{
                                  overflow: 'auto',
                                  paddingRight: '5%',
                                  paddingLeft: '5%'
                              }}
                        >
                            <GenericMenuItemGrid
                                items={ingameShopData.categories[tabToShow].items}
                                updateSelectedItem={this.updateSelectedItem.bind(this)}
                                style={{overflow: 'auto'}}
                            />
                        </Grid>
                    </Grid>
                    <Grid xs={6}>
                        <ItemInfoPanel
                            selectedItem={selectedItem}
                            onSelectItem={(button) => {
                                selectShopItem(
                                    {
                                        categoryIndex: tabToShow,
                                        iconIndex:this.state.selectedItemIndex,
                                        buttonClicked: button
                                    })
                                if (selectedItem.closeOnSelect) {
                                    setTimeout(() => {
                                        handleClose()
                                    }, 100)
                                }
                            }
                            }
                        />
                    </Grid>
                </Grid>
              
            </GenericMenu>
           
        )
    }
}

function mapStateToProps(state) {
    return {
        ingameShopActive: state.stuff.ingameShopActive,
        ingameShopData: state.stuff.ingameShopData,
        inGameMenuTab: state.stuff.inGameMenuTab,
    }
}

export default connect(
    mapStateToProps,
)(IngameShop)


const ItemInfoPanel = (props) => {
    const selectedItem = props.selectedItem
    let colorTable = ColorTable
    let selectedItemImagePath
    if (selectedItem && selectedItem.imgPath) {
        if (selectedItem.imgPath.type === 'local') {
            selectedItemImagePath = `assets/${selectedItem.imgPath.path}`
        }
    }
    return (
        <Grid container
                     direction='row'
                     alignItems='flex-start'
                     justifyContent='flex-start'
                     align='center'
                     style={{ height: '100%' }}
        >
            { (selectedItem && selectedItem.rarity) &&
                <Grid container
                      direction='row'
                      justifyContent='center'
                      xs={12}
                      style={{
                      }}
                >
                    <Grid xs={3}></Grid>
                    <Grid xs={6}
                          style={{
                              background: colorTable.rarityBackground[selectedItem.rarity],
                              borderRadius: '15px',
                              marginBottom: '2.5%',
                              borderWidth: '2px', borderStyle: 'solid',
                              borderColor: '#362D44',
                              boxShadow: '0 0 0 2px #FFFFFF',
                          }}>
                        <b style={{
                            textTransform: 'capitalize',
                            color: colorTable.rarityText[selectedItem.rarity]
                        }}> {selectedItem.rarity}</b>
                    </Grid>
                    <Grid xs={3}></Grid>
                </Grid>
            }
            <Grid container
                  direction='row'
                  justifyContent='center'

                  xs={12}
                  style={{
                  }}
            >
            </Grid>

            <Grid xs={12} >
                <h2> <b> {selectedItem && selectedItem.name}</b></h2>
                {/*  <h2> <b> {selectedItem && <StyledText data={selectedItem.name}/>}</b></h2>*/}
            </Grid>
            {selectedItemImagePath &&
                <Grid xs={12} >

                    <img style={{
                        width: '80px',
                        height: '80px',
                        pointerEvents: 'none',
                    }}
                         src={selectedItemImagePath} />

                </Grid>
            }
            <Grid xs={12} >
                {selectedItem && <StyledText data={selectedItem.description}/>}
            </Grid>
            {selectedItem  &&
                <Grid xs={12}
                      style={{
                          minHeight: '46px',
                          maxHeight: '46px',
                          paddingRight: '15%',
                          paddingLeft: '15%',
                      }}
                >
                    { selectedItem.clickable  &&
                        <ColorChangingButton
                            buttonFunction={() => {
                                props.onSelectItem(1)
                            }
                            }
                            title={selectedItem.buttonText ? selectedItem.buttonText : 'Select'}
                            config={
                                {
                                    idle: {
                                        fillColor: '#7C4E4D',
                                        outerBorderColor: '#362D44',
                                        innerBorderColor: '#543131',
                                    },
                                    hover: {
                                        fillColor: '#CC7A82',
                                        outerBorderColor: '#362D44',
                                        innerBorderColor: '#AA5561',
                                    },
                                    down: {
                                        fillColor: '#CC7A82',
                                        outerBorderColor: '#362D44',
                                        innerBorderColor: '#AA5561',
                                    },
                                }}
                            buttonStyling={{
                                marginTop: '10px',
                                color: 'white',
                                width: '100%',
                                paddingTop: '1%', paddingBottom: '1%'
                            }}
                        />
                    }
                    { !selectedItem.clickable &&     <ColorChangingButton
                        buttonFunction={() => {
                        }
                        }
                        title={selectedItem.buttonText ? selectedItem.buttonText : 'Select'}
                        config={
                            {
                                idle: {
                                    fillColor: '#808080',
                                    outerBorderColor: '#362D44',
                                    innerBorderColor: '#543131',
                                },
                                hover: {
                                    fillColor: '#808080',
                                    outerBorderColor: '#362D44',
                                    innerBorderColor: '#543131',
                                },
                                down: {
                                    fillColor: '#808080',
                                    outerBorderColor: '#362D44',
                                    innerBorderColor: '#543131',
                                },
                            }}
                        buttonStyling={{
                            marginTop: '10px',
                            color: 'white',
                            width: '100%',
                            paddingTop: '1%', paddingBottom: '1%'
                        }}
                    />}
                    { selectedItem.buttonText2 && selectedItem.clickable2 &&
                        <ColorChangingButton
                            buttonFunction={() => {
                                props.onSelectItem(2)
                            }
                            }
                            title={selectedItem.buttonText2 ? selectedItem.buttonText2 : 'Select'}
                            config={
                                {
                                    idle: {
                                        fillColor: '#7C4E4D',
                                        outerBorderColor: '#362D44',
                                        innerBorderColor: '#543131',
                                    },
                                    hover: {
                                        fillColor: '#CC7A82',
                                        outerBorderColor: '#362D44',
                                        innerBorderColor: '#AA5561',
                                    },
                                    down: {
                                        fillColor: '#CC7A82',
                                        outerBorderColor: '#362D44',
                                        innerBorderColor: '#AA5561',
                                    },
                                }}
                            buttonStyling={{
                                marginTop: '10px',
                                color: 'white',
                                width: '100%',
                                paddingTop: '1%', paddingBottom: '1%'
                            }}
                        />
                    }
                    { selectedItem.buttonText2 && !selectedItem.clickable2 &&     <ColorChangingButton
                        buttonFunction={() => {
                        }
                        }
                        title={selectedItem.buttonText2 ? selectedItem.buttonText2 : 'Select'}
                        config={
                            {
                                idle: {
                                    fillColor: '#808080',
                                    outerBorderColor: '#362D44',
                                    innerBorderColor: '#543131',
                                },
                                hover: {
                                    fillColor: '#808080',
                                    outerBorderColor: '#362D44',
                                    innerBorderColor: '#543131',
                                },
                                down: {
                                    fillColor: '#808080',
                                    outerBorderColor: '#362D44',
                                    innerBorderColor: '#543131',
                                },
                            }}
                        buttonStyling={{
                            marginTop: '10px',
                            color: 'white',
                            width: '100%',
                            paddingTop: '1%', paddingBottom: '1%'
                        }}
                    />}
                </Grid>
            }
        </Grid>
    );
}