import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Paper, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const HealthProgress = styled(LinearProgress)(({ theme }) => ({
    '&.MuiLinearProgress-colorPrimary': {
        backgroundColor: '#443f54',
    },
    '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#880808',
    }
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    '&.MuiLinearProgress-colorPrimary': {
        backgroundColor: '#443f54',
    },
    '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#0000FF',
    }
}));

class ManaHealthBar extends Component {
    render() {
        let { health, maxHealth, mana, maxMana } = this.props;
        return (
            <Grid container spacing={0} justifyContent="center" sx={{ marginBottom: '5%', marginLeft: '5%', height: '100%', width: '100%' }}>
                <Paper sx={{
                    minWidth: '135px',
                    height: '100%',
                    width: '100%',
                    borderRadius: '6px',
                    borderColor: 'rgba(0,0,0,0.25)',
                    boxShadow: '0 0 0 2px #362D44',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    padding: '3px'
                }}>
                    <Grid item xs={12} sx={{ textAlign: 'center', height: '100%', width: '100%' }}>
                        <HealthProgress
                            variant="determinate"
                            value={Math.floor((health / maxHealth) * 100)}
                            sx={{ minHeight: '15px', borderRadius: '2px', boxShadow: '0 0 0 1px #362D44', mb: '5px' }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center', height: '100%', width: '100%' }}>
                        <StyledLinearProgress
                            variant="determinate"
                            value={Math.floor((mana / maxMana) * 100)}
                            sx={{ minHeight: '15px', borderRadius: '2px', boxShadow: '0 0 0 1px #362D44' }}
                        />
                    </Grid>
                </Paper>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        health: state.stuff.health,
        maxHealth: state.stuff.maxHealth,
        mana: state.stuff.mana,
        maxMana: state.stuff.maxMana,
    };
}

export default connect(mapStateToProps)(ManaHealthBar);