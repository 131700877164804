"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const material_1 = require("@mui/material");
const react_redux_1 = require("react-redux");
const TextStyling_1 = require("./TextStyling");
const chatStyle = {
    textAlign: 'left',
    width: '100%',
    overflowWrap: 'anywhere',
};
class ChatPanel extends react_1.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let { globalChat } = this.props;
        let parsedMessages = [];
        for (let i = 0; i < globalChat.length; i++) {
            parsedMessages.push(react_1.default.createElement(TextStyling_1.StyledText, { data: globalChat[i], style: chatStyle }));
            parsedMessages.push(react_1.default.createElement(TextStyling_1.StyledText, { data: '\n' }));
        }
        return (react_1.default.createElement(material_1.Grid, { container: true, xs: 12, style: {
                marginBottom: '5%', width: '100%', height: '100%',
            } },
            react_1.default.createElement(material_1.Paper, { style: {
                    width: '100%', userSelect: 'none', textAlign: 'center',
                    paddingBottom: '3px',
                    paddingRight: '3px',
                    paddingLeft: '3px',
                    borderRadius: '4px',
                    borderWidth: '1px', borderStyle: 'solid',
                    borderColor: 'white',
                    boxShadow: '0 0 0 1px #000000',
                    background: '#e6cab2',
                } },
                react_1.default.createElement("div", { style: {
                        position: 'fixed',
                        bottom: 0,
                        textAlign: 'left',
                    } }, parsedMessages))));
    }
}
function mapStateToProps(state) {
    return {
        globalChat: state.stuff.globalChat
    };
}
exports.default = (0, react_redux_1.connect)(mapStateToProps)(ChatPanel);
