export let mainDict = [
    [
        't',
        'int'
    ],
    [
        'enemies',
        'list'
    ],
    [
        'me',
        'list'
    ],
    [
        'bullets',
        'list'
    ],
    [
        'pickups',
        'list'
    ],
    [
        'others',
        'list'
    ],
    [
        'leaderboard',
        // "dict"
        'list'
    ],
    [
        'eventCoords',
        // "dict"
        'list'
    ],
    [
        'spikeSwitch',
        'bool'
    ],
    [
        'lavaPit',
        'bool'
    ],
]

export let meDict = [
    [
        'x',
        'int'
    ],
    [
        'y',
        'int'
    ],
    [
        'hp',
        'int'
    ],
    [
        'hpChangeAmount',
        'int'
    ],
    [
        'manaVal',
        'float'
    ],
    [
        'maxMana',
        'int'
    ],
    [
        'statPoints',
        'int'
    ],
    [
        'level',
        'int'
    ],
    [
        'expRatio',
        'float'
    ],
    [
        'playerKills',
        'int'
    ],
    [
        'zoneCountdown',
        'float'
    ],
    [
        'stealth',
        'bool'
    ],
    [
        'onWater',
        'bool'
    ],
    [
        'onLava',
        'bool'
    ],
    [
        'mana',
        'int'
    ],
    [
        'livePets',
        'int'
    ],
]

export let othersDict = [
    [
        'id',
        'object'
    ],
    [
        'x',
        'int'
    ],
    [
        'y',
        'int'
    ],
    [
        'angle',
        'int'
    ],
    [
        'hp',
        'int'
    ],
    [
        'mana',
        'int'
    ],
    [
        'hpChangeAmount',
        'int'
    ],
    [
        'cast',
        'bool'
    ],
    [
        'melee',
        'bool'
    ],
    [
        'weaponDrawn',
        'bool'
    ],
    [
        'weapon',
        'int'
    ],
    [
        'topWear',
        'int'
    ],
    [
        'bodyWear',
        'int'
    ],
    [
        'handWear',
        'int'
    ],
    [
        'username',
        'object'
    ],
    [
        'head',
        'int'
    ],
    [
        'leveledUp',
        'bool'
    ],
    [
        'onWater',
        'bool'
    ],
    [
        'onLava',
        'bool'
    ],
    [
        'emote',
        'int'
    ],
    [
        'chatMessage',
        'string'
    ],
    [
        'rankId',
        'int'
    ],
]
export let enemiesDict = [
    [
        'id',
        'object'
    ],
    [
        'x',
        'int'
    ],
    [
        'y',
        'int'
    ],
    [
        'angle',
        'int'
    ],
    [
        'hp',
        'int'
    ],
    [
        'hpChangeAmount',
        'int'
    ],
    [
        'radius',
        'int'
    ],
    [
        'enemyID',
        'int'
    ],
    [
        'newState',
        'int'
    ],
    [
        'mana',
        'int'
    ],
    [
        'label',
        'object'
    ],
    [
        'labelColor',
        'object'
    ],
    [
        'icon',
        'object'
    ],
    [
        'iconColor',
        'object'
    ],
    [
        'isPet',
        'bool'
    ],
    [
        'petOwnerId',
        'int'
    ],
]

export let bulletsDict = [
    [
        'id',
        'object'
    ],
    [
        'x',
        'int'
    ],
    [
        'y',
        'int'
    ],
    [
        'direction',
        'float'
    ],
    [
        'radius',
        'int'
    ],
    [
        'spellID',
        'int'
    ],
    [
        'damage',
        'int'
    ],
    [
        'isFriendly',
        'bool'
    ],
    [
        'levelDiffFlag',
        'bool'
    ]
]

export let pickupsDict = [
    [
        'id',
        'object'
    ],
    [
        'x',
        'int'
    ],
    [
        'y',
        'int'
    ],
    [
        'radius',
        'int'
    ],
    [
        'type',
        'object'
    ],
    [
        'pickupID',
        'int'
    ],
    [
        'icon',
        'object'
    ],
]