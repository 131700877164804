import React from 'react'
import ColorChangingIcon from '../ColorChangingIcon.js'
import { iconStyle, iconStyleSmall } from '../uiStyles.js'
import { updateJoinedDiscord } from '../../actions/actions'

function IconDiscord(props) {

    let chosenIconStyle
    if (props.buttonSize == 'small') {
        chosenIconStyle = iconStyleSmall
    } else {
        chosenIconStyle = iconStyle
    }


    return <ColorChangingIcon
        buttonFunction={() => { 
            window.open('https://discord.gg/fkFFzP47Bv') 
try{
            localStorage.setItem('joinedDiscord', JSON.stringify(true))
} catch(err){}
            updateJoinedDiscord(true)
        
        }}
        config={{
            idle: {
                fillColor: '#7689D4',
                outerBorderColor: '#362D44',
                innerBorderColor: '#4E65AF',
            },
            hover: {
                fillColor: '#9BB2F9',
                outerBorderColor: '#362D44',
                innerBorderColor: '#5C96EA',
            },
            down: {
                fillColor: '#5D7AC1',
                outerBorderColor: '#362D44',
                innerBorderColor: '#445EB2',
            }
        }}
        buttonStyling={chosenIconStyle}
        imgUrl='assets/ui/icon_discord.png'
    />
}

export default (IconDiscord)