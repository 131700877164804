import * as types from './actionTypes'
import {store} from '../index.js'
import {chatActive, emoteWheelActive, ingameShopActive, playerResources, spellPointers} from "../game"
import {serverSettings} from "../equippedSpells"
import {cmds} from "../sdkIntegration"
import { defaultGenericBarOptionsValues } from '../components/GenericBar'

export function updatePressedKey(key) {
    if (!serverSettings) {
        return
    }
    if (!serverSettings.fullObjTest.selectableKeys) {
        return
    }
    let action =  {type: types.UPDATE_KEY, selectedKey: key}
    store.dispatch(action)
}
export function updateAlertMessage(message) {
    let action = {type: types.UPDATE_ALERT_MESSAGE, alertMessage: message}
    store.dispatch(action)
}

export function updateHighscores(highscoreList) {
    let action = {type: types.UPDATE_HIGHSCORE_LIST, highscoreList: highscoreList}
    store.dispatch(action)
}

export function toggleShift(toggle) {
    let action = {type: types.TOGGLE_SHIFT, shiftActive: toggle}
    store.dispatch(action)
}

export function updateSpells(spellsList) {
    let action = {type: types.UPDATE_SPELLS, spellsList: spellsList}
    store.dispatch(action)
}

export function triggerReload(key, value) {
    let action = { type: types.TRIGGER_SPELL_RELOAD, spellKey: key, value: value}
    store.dispatch(action)
}

export function updatePickupUI(pickup) {
    let action = { type: types.UPDATE_PICKUP_UI, pickup: pickup}
    store.dispatch(action)
}

export function updateBRTimer(time) {
    let action = { type: types.UPDATE_BR_TIMER, BRCircleTime: time}
    store.dispatch(action)
}

export function updateLeaderboard(leaderboard) {
    let action = { type: types.UPDATE_LEADERBOARD, leaderboard: leaderboard}
    store.dispatch(action)
}

export function updatePlayerKills(playerKills) {
    let action = { type: types.UPDATE_PLAYER_KILLS, playerKills: playerKills}
    store.dispatch(action)
}

export function triggerPlayerDied(trigger, thingsKept) {
    let action = { type: types.TRIGGER_PLAYER_DIED, playerDied: trigger, thingsKept: thingsKept}
    store.dispatch(action)
}

export function triggerPlayerRevived(trigger) {
    let action = { type: types.TRIGGER_PLAYER_DIED, playerDied: false }
    store.dispatch(action)
}

export function triggerGameStarted(trigger) {
    let action = { type: types.TRIGGER_GAME_STARTED, gameStarted: trigger }
    store.dispatch(action)
}

export function updateLevel(level, expRatio) {
    let action = { type: types.UPDATE_LEVEL, level: level, expRatio: expRatio}
    store.dispatch(action)
}

export function updateCircle(currentCircle) {
    let action = { type: types.UPDATE_CIRCLE, currentCircle: currentCircle }
    store.dispatch(action)
}

export function updateStatPoints(statPoints) {
    let action = { type: types.UPDATE_STAT_POINTS, statPoints: statPoints }
    store.dispatch(action)
}

export function updateStats(stat) {
    let action = { type: types.UPDATE_STATS, stat: stat }
    store.dispatch(action)
}

export function setStats(stats) {
    let action = { type: types.SET_STATS, stats: stats }
    store.dispatch(action)
}

export function updateGearData(gearType, gearName, gearData) {
    let action = { type: types.UPDATE_GEAR_DATA, gearType: gearType, gearName: gearName, gearData: gearData }
    store.dispatch(action)
}

export function updateUsernameInput(input) {
    let action = { type: types.UPDATE_USERNAME_INPUT, input: input}
    store.dispatch(action)
}

export function updateSelectedHead(input) {
    let action = { type: types.UPDATE_SELECTED_HEAD, input: input }
    store.dispatch(action)
}

export function updateCodeInput(input) {
    let action = { type: types.UPDATE_CODE_INPUT, input: input}
    store.dispatch(action)
}

export function updateServerTable(serverData) {
    let action = { type: types.UPDATE_SERVER_TABLE, serverData: serverData}
    // console.log("ACTION", action)
    store.dispatch(action)
}

export function updateServerSelection(serverData) {
    let action = { type: types.UPDATE_SERVER_SELECTION, serverData: serverData}
    // console.log("ACTION", action)
    store.dispatch(action)
}

export function updateSelectedServer(url) {
    let action = { type: types.UPDATE_SELECTED_SERVER, url: url}
    store.dispatch(action)
}


export function updateZoneCountdown(countdown) {
    let action = { type: types.UPDATE_ZONE_COUNTDOWN, countdown: countdown}
    store.dispatch(action)
}

export function updateMinZone(minZone) {
    let action = { type: types.UPDATE_MIN_ZONE, minZone: minZone }
    store.dispatch(action)
}

export function gameLoadComplete(gameLoaded) {
    console.log('gameLoaded: ', gameLoaded)
    let action = { type: types.GAME_LOAD_COMPLETE, gameLoaded: gameLoaded }
    store.dispatch(action)
}
export function mapLoadComplete(mapLoaded) {
    console.log('mapLoaded: ', mapLoaded)
    let action = { type: types.MAP_LOAD_COMPLETE, mapLoaded: mapLoaded }
    store.dispatch(action)
}

export function setSpellPointer(spellSlot, active, removeOtherPointers, fadeScreen) {
    spellPointers[spellSlot] = active
    let action = { type: types.SET_SPELL_POINTER, spellSlot, active, removeOtherPointers, fadeScreen  }
    store.dispatch(action)
}

export function setGame(gameMode) {
    let action = { type: types.SET_GAME, currentGame: gameMode }
    store.dispatch(action)
}

export function updateAccountEXP(level, exp, expRatio) {
    let action = { type: types.UPDATE_ACCOUNT_EXP, level: level, exp: exp, expRatio: expRatio}
    store.dispatch(action)
}

export function updatePrevAccountEXP(level, exp, expRatio) {
    let action = { type: types.UPDATE_PREV_ACCOUNT_EXP, level: level, exp: exp, expRatio: expRatio }
    store.dispatch(action)
}

export function toggleMute(value) {
    let action = { type: types.TOGGLE_MUTE, mute: value}
    store.dispatch(action)
}

export function toggleFullScreen(value) {
    let action = { type: types.TOGGLE_FULLSCREEN, fullscreen: value }
    store.dispatch(action)
}

export function toggleAdblock(value) {
    let action = { type: types.TOGGLE_ADBLOCK, adblock: value }
    store.dispatch(action)
}


export function updateHeight(value) {
    let action = { type: types.UPDATE_HEIGHT, height: value }
    store.dispatch(action)
}

export function updateWidth(value) {
    let action = { type: types.UPDATE_WIDTH, width: value }
    store.dispatch(action)
}

export function updateJoinedDiscord(value) {
    let action = { type: types.UPDATE_JOINED_DISCORD, value: value}
    store.dispatch(action)
}


export function toggleInitGame(value) {
    let action = { type: types.TOGGLE_INIT_GAME, value: value}
    store.dispatch(action)
}

export function updateLoadProgress(value) {
    let action = { type: types.UPDATE_LOAD_PROGRESS, value: value}
    store.dispatch(action)
}
export function addLoadProgress(value) {
    let action = { type: types.ADD_LOAD_PROGRESS, value: value}
    store.dispatch(action)
}

export function toggleAuthenticated(value) {
    let action = {type: types.TOGGLE_AUTHENTICATED, value: value}
    store.dispatch(action)
}

export function updateAccountItems(value) {
    let action = {type: types.UPDATE_ACCOUNT_ITEMS, value: value}
    store.dispatch(action)
}

export function updateAccountChallenges(value) {
    let action = { type: types.UPDATE_ACCOUNT_CHALLENGES, value: value }
    store.dispatch(action)
}

export function updateToken(value) {
    let action = {type: types.UPDATE_TOKEN, value: value}
    store.dispatch(action)
}

export function updateAccountUsername(value) {
    let action = {type: types.UPDATE_ACCOUNT_USERNAME, value: value}
    store.dispatch(action)
}

export function updateAccountUsernameInput(input) {
    let action = { type: types.UPDATE_ACCOUNT_USERNAME_INPUT, input: input }
    store.dispatch(action)
}

export function updateAccountStats(value) {
    let action = { type: types.UPDATE_ACCOUNT_STATS, value: value }
    store.dispatch(action)
}

export function updateAccountKillStats(value) {
    let action = { type: types.UPDATE_ACCOUNT_KILL_STATS, value: value }
    store.dispatch(action)
}

export function updateGuestAccount(value) {
    let action = {type: types.UPDATE_GUEST_ACCOUNT, value: value}
    store.dispatch(action)
}

export function toggleBetaActive(value) {
    let action = { type: types.TOGGLE_BETA_ACTIVE, value: value }
    store.dispatch(action)
}

export function toggleAlphaActive(value) {
    let action = { type: types.TOGGLE_ALPHA_ACTIVE, value: value }
    store.dispatch(action)
}

export function updateEmoteDict(selectedEmoteSlot, emoteId) {
    let action = {type: types.UPDATE_EMOTE_DICT, selectedEmoteSlot: selectedEmoteSlot, emoteId: emoteId}
    store.dispatch(action)
}

export function toggleLoadoutMenu(bool) {
    let action = {type: types.TOGGLE_LOADOUT_MENU, value: bool}
    store.dispatch(action)
}

export function toggleChallengeMenu(bool) {
    let action = { type: types.TOGGLE_CHALLENGE_MENU, value: bool }
    store.dispatch(action)
}

export function toggleSignInMenu(bool) {
    let action = { type: types.TOGGLE_SIGN_IN_MENU, value: bool }
    store.dispatch(action)
}
export function toggleShopMenu(bool) {
    let action = { type: types.TOGGLE_SHOP_MENU, value: bool }
    store.dispatch(action)
}

export function updateInGameMenuTab(tab) {
    let action = { type: types.UPDATE_INGAME_MENU_TAB, value: tab }
    store.dispatch(action)
}

export function updateShopTab(value) {
    let action = { type: types.UPDATE_SHOP_TAB, value: value }
    store.dispatch(action)
}

export function togglePaymentMenu(bool) {
    let action = { type: types.TOGGLE_PAYMENT_MENU, value: bool }
    store.dispatch(action)
}

export function updateCoins(value) {
    let action = { type: types.UPDATE_COINS, value: value}
    store.dispatch(action)
}

export function updateGems(value) {
    let action = { type: types.UPDATE_GEMS, value: value}
    store.dispatch(action)
}

export function updateDailyQuests(value) {
    let action = { type: types.UPDATE_DAILY_QUESTS, value: value}
    store.dispatch(action)
}

export function updateDailyQuestsTimeLeft(value) {
    let action = { type: types.UPDATE_DAILY_QUESTS_TIME_LEFT, value: value}
    store.dispatch(action)
}

export function updateAccountId(value) {
    let action = { type: types.UPDATE_ACCOUNT_ID, value: value }
    store.dispatch(action)
}

export function updateAccountEmail(value) {
    let action = { type: types.UPDATE_ACCOUNT_EMAIL, value: value }
    store.dispatch(action)
}

export function toggleTouchControls(value) {
    let action = { type: types.TOGGLE_TOUCH_CONTROLS, value: value }
    store.dispatch(action)
}

export function loadedRemainingFiles(value) {
    let action = { type: types.LOADED_REMAINING_FILES, value: value }
    store.dispatch(action)
}

export function updateChatInput(input) {
    let action = { type: types.UPDATE_CHAT_INPUT, input: input }
    store.dispatch(action)
}

export function toggleChatboxActive(input) {
    let action = { type: types.TOGGLE_CHATBOX_ACTIVE, input: input }
    chatActive.value = input
    store.dispatch(action)
}

export function updateSettings(input) {
    let action = { type: types.UPDATE_SETTINGS, input: input }
    store.dispatch(action)
}

export function updatePromoCodeInput(input) {
    let action = { type: types.UPDATE_PROMO_CODE_INPUT, input: input}
    store.dispatch(action)
}

export function updateSelectedScore(input) {
    let action = { type: types.UPDATE_SELECTED_SCORE, input: input}
    store.dispatch(action)
}

export function updateSelectedTimeValue(input) {
    let action = { type: types.UPDATE_SELECTED_TIME_VALUE, input: input}
    store.dispatch(action)
}
export function updateSelectedHighscoreGameMode(input) {
    let action = { type: types.UPDATE_SELECTED_HIGHSCORE_GAME_MODE, input: input}
    store.dispatch(action)
}

export function updateRank(input) {
    let action = {type: types.UPDATE_RANK, input: input}
    store.dispatch(action)
}

export function updateRankScore(input) {
    let action = {type: types.UPDATE_RANK_SCORE, input: input}
    store.dispatch(action)
}

export function hideCrossLinks(input) {
    let action = {type: types.HIDE_CROSS_LINKS, input: input}
    store.dispatch(action)
}

export function updateReferrer(input) {
    console.log('updating referrer', input)
    let action = { type: types.UPDATE_REFERRER, input: input }
    store.dispatch(action)
}

export function updateDisableAdblockPopupActive(input) {
    let action = { type: types.UPDATE_DISABLE_ADBLOCK_POPUP, input: input}
    store.dispatch(action)
}

export function updateTrialVictory(input) {
    if (input) {
        cmds.gameplayStop()
    } else {
        cmds.gameplayStart()
    }
    let action = { type: types.UPDATE_TRIAL_VICTORY, input: input}
    store.dispatch(action)
}

export function updateNewUpdateDialog(input) {
    let action = { type: types.UPDATE_NEW_UPDATE_DIALOG, input: input}
    store.dispatch(action)
}

export function updateGamePlayedOnce(input) {
    let action = { type: types.UPDATE_GAME_PLAYED_ONCE, input: input }
    store.dispatch(action)
}

export function updateGlobalChat(input) {
    let action = {type: types.UPDATE_GLOBAL_CHAT, input: input}
    store.dispatch(action)
}
export function resetGlobalChat() {
    let action = {type: types.RESET_GLOBAL_CHAT}
    store.dispatch(action)
}

export function setMiddleLowerText(input) {
    let action = {type: types.SET_MIDDLE_LOWER_TEXT, input: input}
    store.dispatch(action)
}


export function updateEmoteBarData(input) {
    emoteWheelActive.value = input.showMenu
    let action = { type: types.UPDATE_EMOTE_BAR_DATA, input }
    store.dispatch(action)
}
export function setStatsData(input) {
    let action = { type: types.SET_STATS_DATA, input }
    store.dispatch(action)
}

export function updateStatBarVisibility(input) {
    console.log('updating stat bar visibility', input)
    let action = { type: types.UPDATE_STAT_BAR_VISIBILITY, value: input }
    store.dispatch(action)
}

export function updateResourceProperties(input) {
    let action = { type: types.UPDATE_RESOURCE_PROPERTIES, input }
    store.dispatch(action)
}
export function updateResource(resourceType, value) {
    // console.log('updating resource', {
    //     resourceType: resourceType,
    //     value: value
    // })
    let action = { type: types.UPDATE_RESOURCE, resourceType: resourceType, value: value }
    if (!playerResources[resourceType]) {
        playerResources[resourceType] = {value: 0}
    }
    playerResources[resourceType].value = value
    // console.log('updated player resources', playerResources)
    store.dispatch(action)
}

export function setResources(resources) {
    let action = { type: types.SET_RESOURCES, resources: resources }
    store.dispatch(action)

}

export function setCustomBaseStatCaps(input) {
    let action = { type: types.SET_CUSTOM_BASE_STAT_CAPS, input }
    store.dispatch(action)
}

export function toggleIngameSettingsMenu(input) {
    console.log('calling toggle ingame settings', input)
    let finalInput = input
    if (input === undefined) {
        finalInput = 'toggle'
    }
    console.log('final input', finalInput)
    let action = { type: types.TOGGLE_INGAME_SETTINGS_MENU, finalInput }
    store.dispatch(action)
}
export function toggleShop(input) {
    // let action = { type: types.TOGGLE_SHOP, input }
    console.log('input', input)
    let finalInput
    if (input === true || input === false) {
        ingameShopActive.value = input
        finalInput = input
    } else {
        ingameShopActive.value = !ingameShopActive.value
        finalInput = ingameShopActive.value
    }
    console.log('check on how this looks', {type: types.TOGGLE_SHOP, finalInput})
    store.dispatch({type: types.TOGGLE_SHOP, value: finalInput})
}
export function setShopData(input) {
    let action = { type: types.SET_SHOP_DATA, input }
    store.dispatch(action)
}

export function setSingleGame(input) {
    let action = { type: types.SET_SINGLE_GAME, input }
    store.dispatch(action)
}

export function setTitleLoaded(input) {
    let action = { type: types.SET_TITLE_LOADED, input }
    store.dispatch(action)
}

export function sdkLoaded(input) {
    let action = { type: types.SDK_LOADED, input }
    store.dispatch(action)
}

export function updateGenericBarValue(id, value) {
    let action = { type: types.UPDATE_GENERIC_BAR_VALUE, id: id, value: value }
    store.dispatch(action)
}
export function updateGenericBarName(id, name, settings) {
    let settingsDict = {
        color: settings?.color ? settings.color : defaultGenericBarOptionsValues.color,
        clickable: settings?.clickable ? settings.clickable : defaultGenericBarOptionsValues.clickable,
        inBarName: settings?.inBarName ? settings.inBarName : defaultGenericBarOptionsValues.inBarName,
        inBarNameColor: settings?.inBarNameColor ? settings.inBarNameColor : defaultGenericBarOptionsValues.inBarNameColor
    }
    let action = { type: types.UPDATE_GENERIC_BAR_NAME, id: id, name: name, settings: settingsDict }
    store.dispatch(action)
}