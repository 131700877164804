import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'
import { Card } from '@mui/material'
import ColorTable from "../../shared/colorTable";
import {serverSettings} from "../equippedSpells";
let starIcon = require('../../../public/assets/icons/round-star.png')

class PickupBox extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    componentDidMount() {

    }

    render() {

        let { pickupUIItem, adblock, statsData } = this.props
        let textToDisplay
        let secondaryText
        let pickupColor
        let pickupRarity
        let pickupType
        let spellType
        let starCount = 1
        let backgroundColor
        let starRendering = []
        let manaColor
        let reactStarIcon
        let baseCooldownText = ''
        let spellCooldown
        let baseSpeedText = ''
        let spellSpeed

        let spellDamage
        let baseDamageText

        let damageColor = 'black'
        let speedColor = 'black'
        let cooldownColor = 'black'

        let customPickupText = JSON.parse(JSON.stringify((serverSettings.defaultPlayerOptions.pickupText)))
        customPickupText.bodyWear = customPickupText.gear
        customPickupText.topWear = customPickupText.gear
        customPickupText.handWear = customPickupText.gear



        if (pickupUIItem) {
            if (pickupUIItem.pickupType == 'pickup') {
                // pickupType = 'pickupUIItem.pickupType'
                pickupType = pickupUIItem.pickupType
                textToDisplay = `${pickupUIItem.name}`
            }
           else if (pickupUIItem.pickupType == 'mana') {
                pickupType = 'mana'
                textToDisplay = `${pickupUIItem.manaQuantity} ${pickupUIItem.name}`
            }
            else if (pickupUIItem.pickupType == 'bodyWear' || pickupUIItem.pickupType == 'topWear' || pickupUIItem.pickupType == 'handWear') {
                pickupType = pickupUIItem.pickupType
                pickupRarity = pickupUIItem.rarity
                pickupColor = ColorTable.rarityInfo[pickupUIItem.rarity]['textColor']
                backgroundColor = ColorTable.rarityInfo[pickupUIItem.rarity]['rarityColor']
                starCount = ColorTable.rarityInfo[pickupUIItem.rarity]['starCount']

            }
            else if (pickupUIItem.pickupType == 'spell') {
                pickupType = 'spell'
                pickupRarity = pickupUIItem.rarity
                pickupColor = ColorTable.rarityInfo[pickupUIItem.rarity]['textColor']
                backgroundColor = ColorTable.rarityInfo[pickupUIItem.rarity]['rarityColor']
                starCount = ColorTable.rarityInfo[pickupUIItem.rarity]['starCount']

                manaColor = ColorTable.elementHex[pickupUIItem.mana]


                spellCooldown = JSON.parse(JSON.stringify(pickupUIItem.spellCooldown))

                spellSpeed = JSON.parse(JSON.stringify(pickupUIItem.speed))

                if (pickupUIItem.damage) {
                    spellDamage = JSON.parse(JSON.stringify(pickupUIItem.damage)).toFixed(2)
                }
                if (pickupUIItem.spellCooldown) {
                    spellCooldown = JSON.parse(JSON.stringify(pickupUIItem.spellCooldown)).toFixed(2)
                }
                if (pickupUIItem.speed) {
                    spellSpeed = JSON.parse(JSON.stringify(pickupUIItem.speed)).toFixed(2)
                }

               if (pickupUIItem.spellCooldown != pickupUIItem.baseSpellCooldown) {
                   baseCooldownText = `(${pickupUIItem.baseSpellCooldown})`
                   cooldownColor = 'blue'
               }
               if (pickupUIItem.speed != pickupUIItem.baseSpellSpeed) {
                   baseSpeedText = `(${pickupUIItem.baseSpellSpeed})`
                   speedColor = 'blue'
               }
               if (pickupUIItem.damage != pickupUIItem.baseDamage) {
                   let baseDamage = pickupUIItem.baseDamage
                   if (baseDamage < 0) { baseDamage *= -1 }

                   baseDamageText = `(${baseDamage})`
                   damageColor = 'blue'
               }

                if (spellDamage < 0) {
                    spellDamage *= -1
                }

                let reactStarIcon = <img style={{ height: '5%', width: '5%', overflow: 'hidden', objectFit: 'contain' }} src={starIcon.default} alt="new" />
                for (var i = 0; i < starCount; i++) {
                    starRendering.push(reactStarIcon)
                }

                switch (pickupUIItem.type) {
                    case 'projectile':
                        spellType = 'projectile'
                        break
                    case 'static':
                        spellType = 'static spell'
                        break
                    case 'self':
                        spellType = 'surround spell'
                        break
                }
            }
            else if (pickupUIItem.pickupType == 'currency') {
                pickupType = 'currency'
              
                textToDisplay = `${pickupUIItem.quantity} ${pickupUIItem.name}`
                if (pickupUIItem.quantity > 1) { textToDisplay += 's' }
                
                if (pickupUIItem.id == 1) {
                    pickupUIItem.imgPath = 'assets/ui/rune.png'
                } else if (pickupUIItem.id == 2) {
                    pickupUIItem.imgPath = 'assets/ui/glyph.png'
                }

            }
            else if (pickupUIItem.pickupType == 'trial') {
                pickupType = 'trial'
                pickupColor: pickupColor = ColorTable.rarityInfo['legendary']['textColor']
            } else {
                   pickupType = pickupUIItem.pickupType
                   textToDisplay = pickupUIItem.textToDisplay
           }
        }
        if (pickupUIItem) {
            if (pickupType == 'pickup') {
                return (
                    <div>
                        <Grid container spacing={0} justifyContent="center" style={{ margin: 'auto' }}>
                            <Grid item xs={12} >
                                <Paper style={{
                                    userSelect: 'none', textAlign: 'center', backgroundColor: 'white',
                                    borderRadius: '2px',
                                    borderWidth: '2px',
                                    borderStyle: 'solid',
                                    borderColor: 'rgba(0,0,0,0.25)',
                                    boxShadow: '0 0 0 2px #362D44',
                                }}>
                                    <h3 >
                                        {textToDisplay}
                                    </h3>
                                    <h4>
                                        { customPickupText[pickupUIItem.pickupCategory] &&
                                            <>
                                            {customPickupText[pickupUIItem.pickupCategory][2] &&  <div>{customPickupText[pickupUIItem.pickupCategory][2]}</div> }
                                            {customPickupText[pickupUIItem.pickupCategory][1] &&  <div>{customPickupText[pickupUIItem.pickupCategory][1]}</div> }
                                          </>}
                                        { !customPickupText[pickupUIItem.pickupCategory] &&
                                        <>
                                            {customPickupText[pickupUIItem.pickupType][2] &&  <div>{customPickupText[pickupUIItem.pickupType][2]}</div> }
                                            {customPickupText[pickupUIItem.pickupType][1] &&  <div>{customPickupText[pickupUIItem.pickupType][1]}</div> }
                                            </>}

                                    </h4>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                );
            }
            else if (pickupType == 'currency') {
                return (
                    <div>
                        <Grid container spacing={0} justifyContent="center" style={{ margin: 'auto' }}>
                            {adblock &&
                                <Grid item xs={12} style={{
                                    paddingBottom: '15px',
                                }}>
                                    <div style={{
                                        backgroundColor: 'red',
                                        borderTopLeftRadius: '2px',
                                        borderTopRightRadius: '2px',
                                        borderBottomLeftRadius: '0px',
                                        borderBottomRightRadius: '0px',
                                        color: 'white',
                                        fontWeight: 'bold',
                                        opacity: '100%',
                                        userSelect: 'none', textAlign: 'center',
                                        // backgroundColor: '#2596be',
                                        borderRadius: '2px',
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        borderColor: 'rgba(0,0,0,0.25)',
                                        boxShadow: '0 0 0 2px #362D44',
                                    }}>
                                        Disable adblock to pick up currency and support server costs
                                    </div>
                                </Grid>
                            }

                            <Grid item xs={12} >
                                <Card style={{
                                    userSelect: 'none', textAlign: 'center',
                                    backgroundColor: '#2596be',
                                    borderRadius: '2px',
                                    borderWidth: '2px',
                                    borderStyle: 'solid',
                                    borderColor: 'rgba(0,0,0,0.25)',
                                    boxShadow: '0 0 0 2px #362D44',
                                }}>
                                    <h3 style={{ textAlign: 'center', marginBottom: 3, marginTop: 3, fontWeight: 'bold', color: 'white' }}>
                                        Account Currency
                                    </h3>
                                    <Paper style={{
                                        // background: 'rgb(255,255,255, 0.3)',
                                        background: 'rgb(255,255,255, 0.5)',
                                        borderTop: '2px solid black',
                                        borderTopLeftRadius: '0px',
                                        borderTopRightRadius: '0px',
                                        height: '100%', color: 'black',
                                        padding: '10%'
                                    }}>
                                        <b
                                            style={{
                                                position: 'relative',
                                                marginRight: '30px'
                                            }}
                                        >
                                            {textToDisplay}
                                            <img style={{
                                                position: 'absolute',
                                                width: '33px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                pointerEvents: 'none',
                                                margin: 'auto',
                                                zIndex: 10,
                                            }}
                                                src={pickupUIItem.imgPath}
                                            />
                                        </b>
                                    </Paper>
                                    <h4 style={{ textAlign: 'center', marginBottom: 0, marginTop: 3 }} > Pickup: F</h4>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                );
            }

            else if (pickupType == 'spell') {
                return (
                    <div>
                        <Grid container spacing={0} justifyContent="center" style={{ margin: 'auto' }}>
                            <Grid item xs={12} >
                                <Card style={{
                                    userSelect: 'none', textAlign: 'center', backgroundColor: backgroundColor,
                                    borderRadius: '2px',
                                    borderWidth: '2px',
                                    borderStyle: 'solid',
                                    borderColor: 'rgba(0,0,0,0.25)',
                                    boxShadow: '0 0 0 2px #362D44',
                                }}>
                                    <h4 style={{ textAlign: 'center', marginBottom: 3, marginTop: 1 }}>
                                        <span style={{ color: pickupColor, textTransform: 'capitalize' }} > {pickupRarity} </span> <span style={{ color: manaColor }}>{pickupUIItem.mana != 'none' ? pickupUIItem.mana : null} </span>  {spellType}
                                    </h4>
                                    <h3 style={{ textAlign: 'center', marginBottom: 3, marginTop: 3, fontWeight: 'bold', color: 'white' }}>  {pickupUIItem.name} </h3>

                                    <Paper style={{
                                        background: 'rgb(255,255,255, 0.5)',
                                        borderTop: '2px solid black',
                                        borderTopLeftRadius: '0px',
                                        borderTopRightRadius: '0px',
                                        height: '100%', color: 'black'
                                    }}>
                                        {pickupUIItem.effects.length > 0 ? <h4 style={{ textAlign: 'center', marginBottom: 0, marginTop: 0.1 }}> {pickupUIItem.effects} effect </h4> : null}
                                        {spellDamage &&
                                            <div style={{ textAlign: 'center', marginBottom: 0, marginTop: 0.1 }}> {pickupUIItem.damage >= 0 ? <span><strong>Damage:</strong></span> : <span><strong>Heal:</strong></span>}
                                                <span style={{ color: damageColor }}>  {spellDamage}  </span>   {baseDamageText}
                                            </div>
                                        }

                                        {pickupUIItem.speed ? <div> <strong>Speed: </strong>  <span style={{ color: speedColor }}>  {spellSpeed}  </span>   {baseSpeedText}  </div> : null}
                                        {pickupUIItem.damageMultiplier ? <div> <strong>Damage Multiplier (Melee):</strong> {pickupUIItem.damageMultiplier} </div> : null}
                                        {pickupUIItem.sprintTicks && pickupUIItem.sprintTicks > 1 ? <div> <strong>Duration: </strong>{Math.round((pickupUIItem.sprintTicks / 60) * 10) / 10}</div> : null}
                                        {pickupUIItem.speedBoost && pickupUIItem.speedBoost < 1000 ? <div> <strong> Speed Boost:</strong> {pickupUIItem.speedBoost} </div> : null}
                                        {/* <div><strong>Firerate: </strong>{Math.round(((1/pickupUIItem.spellCooldown)*10))/10}</div> */}

                                        {/* <div><strong>Cooldown: </strong>{pickupUIItem.spellCooldown}s </div> */}
                                        {/* <div><strong>Cooldown: </strong>{(pickupUIItem.spellCooldown * multipliers.spellFirerate).toFixed(2)}s <span style={{ color: 'blue' }}>{baseCooldownText}</span> </div> */}

                                        <div><strong>Cooldown: </strong> <span style={{ color: cooldownColor }}>  {spellCooldown}s  </span>   {baseCooldownText}   </div>

                                        {pickupUIItem.manaCost ? <div><strong>Mana Cost:</strong> {pickupUIItem.manaCost} </div> : null}
                                    </Paper>
                                    <h4 style={{ textAlign: 'center', marginBottom: 0, marginTop: 3 }} >
                                        {customPickupText[pickupUIItem.pickupType][2] &&  <div>{customPickupText[pickupUIItem.pickupType][2]}</div> }
                                        {customPickupText[pickupUIItem.pickupType][1] &&  <div>{customPickupText[pickupUIItem.pickupType][1]}</div> }
                                    </h4>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                );
            }
            else if (pickupType == 'bodyWear' || pickupType == 'topWear' || pickupType == 'handWear') {
                let statsBox = []
                let traitsBox = []
                let traitDict = {
                    'spiky': 'Spiky',
                    'swimming': 'Swimming'
                }

                if (statsData && Object.keys(pickupUIItem.stats).length > 0) {
                    for (let [key, value] of Object.entries(pickupUIItem.stats)) {
                        // let plusSigns = '+'.repeat(value)
                        let statDisplayName = statsData.properties.find(stat => stat.name == key).displayName
                        statsBox.push(
                            // <h4 style={{ textAlign: "center", marginBottom: 0, marginTop: 0.1 }}> {plusSigns} {statDict[key]} </h4> 
                            <h4 style={{ textAlign: 'center', marginBottom: 0, marginTop: 0.1 }}> +{value} {statDisplayName} </h4>

                        )
                    }
                }

                if (pickupUIItem.traits && Object.keys(pickupUIItem.traits).length > 0) {
                    for (let [key, value] of Object.entries(pickupUIItem.traits)) {
                        traitsBox.push(
                            <h4 style={{ textAlign: 'center', marginBottom: 0, marginTop: 0.1 }}> +{Math.round(value*100)}%  {traitDict[key]} </h4>
                        )
                    }
                }

                return (
                    <div>
                        <Grid container spacing={0} justifyContent="center" style={{ margin: 'auto' }}>
                            {/* <Grid container> */}
                            <Grid item xs={12} >
                                <Card style={{
                                    userSelect: 'none', textAlign: 'center', backgroundColor: backgroundColor,
                                    borderRadius: '2px',
                                    borderWidth: '2px',
                                    borderStyle: 'solid',
                                    borderColor: 'rgba(0,0,0,0.25)',
                                    boxShadow: '0 0 0 2px #362D44',
                                }}>
                                    <h4 style={{ textAlign: 'center', marginBottom: 3, marginTop: 1 }}><span style={{ color: pickupColor, textTransform: 'capitalize' }} > {pickupRarity} </span> <span style={{ color: manaColor }}>{pickupUIItem.mana != 'none' ? pickupUIItem.mana : null} </span>  {spellType}</h4>
                                    <h3 style={{ textAlign: 'center', marginBottom: 3, marginTop: 3, fontWeight: 'bold', color: 'white' }}>  {pickupUIItem.name} </h3>

                                    <Paper style={{
                                        // background: 'rgb(255,255,255, 0.3)',
                                        background: 'rgb(255,255,255, 0.5)',
                                        borderTop: '2px solid black',
                                        borderTopLeftRadius: '0px',
                                        borderTopRightRadius: '0px',
                                        height: '100%', color: 'black'
                                    }}>
                                        <p>
                                        {statsBox.length > 0 ? statsBox : null}
                                        </p>
                                        <p style={{paddingBottom: '3px'}}>
                                        {traitsBox.length > 0 && traitsBox}
                                        </p>
                                    </Paper>
                                    <h4 style={{ textAlign: 'center', marginBottom: 0, marginTop: 3 }} >
                                        {customPickupText[pickupUIItem.pickupType][2] &&  <div>{customPickupText[pickupUIItem.pickupType][2]}</div> }
                                        {customPickupText[pickupUIItem.pickupType][1] &&  <div>{customPickupText[pickupUIItem.pickupType][1]}</div> }
                                    </h4>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                );
            }
            else if (pickupType == 'trial') {
                for (let [key, value] of Object.entries(pickupUIItem.data)) {
                    pickupUIItem[key] = value
                }
                return (
                    <div>
                        <Grid container spacing={0} justifyContent="center" style={{ margin: 'auto' }}>
                            <Grid item xs={12} >

                                <Card style={{
                                    userSelect: 'none', textAlign: 'center',
                                    // backgroundColor: 'blue',
                                    backgroundColor: ColorTable.rarityInfo['legendary']['rarityColor'],

                                    borderRadius: '2px',
                                    borderWidth: '2px',
                                    borderStyle: 'solid',
                                    borderColor: 'rgba(0,0,0,0.25)',
                                    boxShadow: '0 0 0 2px #362D44',
                                }}>
                                    <h4 style={{ textAlign: 'center', marginBottom: 3, marginTop: 1 }}><span style={{ color: pickupColor, textTransform: 'capitalize' }} > Trial </span>  </h4>
                                    <h3 style={{ textAlign: 'center', marginBottom: 3, marginTop: 3, fontWeight: 'bold', color: 'white' }}> {pickupUIItem.name} </h3>

                                    <Paper style={{
                                        // background: 'rgb(255,255,255, 0.3)',
                                        background: 'rgb(255,255,255, 0.5)',
                                        borderTop: '2px solid black',
                                        borderTopLeftRadius: '0px',
                                        borderTopRightRadius: '0px',
                                        height: '100%', color: 'black'
                                    }}>
                                        {/* <div>+{Math.round((pickupUIItem.expModifier - 1) * 100)}% Exp</div>
                                        <div>+{Math.round((pickupUIItem.scoreModifier - 1) * 100)}% Score</div> */}

                                        <div> <b> + {pickupUIItem.score} Score </b> </div>
                                        <div> <b> Max Players: {pickupUIItem.maxPlayers} </b></div>

                                        <div><b>Cost:</b></div>
                                        <b>
                                            {pickupUIItem.cost.mana.fire && <div> {pickupUIItem.cost.mana.fire} Fire Mana</div>}
                                            {pickupUIItem.cost.mana.water && <div> {pickupUIItem.cost.mana.water} Water Mana</div>}
                                            {pickupUIItem.cost.mana.air && <div> {pickupUIItem.cost.mana.air} Air Mana</div>}
                                            {pickupUIItem.cost.mana.earth && <div> {pickupUIItem.cost.mana.earth} Earth Mana</div>}
                                            {pickupUIItem.cost.mana.light && <div> {pickupUIItem.cost.mana.light} Light Mana</div>}
                                            {pickupUIItem.cost.mana.dark && <div> {pickupUIItem.cost.mana.dark} Dark Mana</div>}
                                        </b>
                                        {/* <div>Minimum Lvl: {pickupUIItem.minimumLevel}</div> */}
                                        {/* {statsBox.length > 0 ? statsBox : null} */}
                                    </Paper>
                                    {/* <div style={{ textAlign: "center", marginBottom: 0, marginTop: 3, fontSize: 12 }} >
                                        Your match will end after the trial, and your score and exp are multiplied by the bonus if you win.
                                    </div> */}
                                    <h4 style={{ textAlign: 'center', marginBottom: 0, marginTop: 3 }} > Enter: F</h4>

                                </Card>


                            </Grid>
                        </Grid>
                    </div>
                );
            }  else {
                return (
                    <div>
                        <Grid container spacing={0} justifyContent="center" style={{ margin: 'auto' }}>
                            <Grid item xs={12} >
                                <Paper style={{
                                    userSelect: 'none', textAlign: 'center', backgroundColor: 'white',
                                    borderRadius: '2px',
                                    borderWidth: '2px',
                                    borderStyle: 'solid',
                                    borderColor: 'rgba(0,0,0,0.25)',
                                    boxShadow: '0 0 0 2px #362D44',
                                }}>
                                    <h2 >
                                        {textToDisplay}
                                    </h2>
                                    {customPickupText[pickupUIItem.pickupType] &&
                                        <h4>
                                    {customPickupText[pickupUIItem.pickupType][2] &&  <div>{customPickupText[pickupUIItem.pickupType][2]}</div> }
                                    {customPickupText[pickupUIItem.pickupType][1] &&  <div>{customPickupText[pickupUIItem.pickupType][1]}</div> }
                                        </h4>
                                    }
                                    {!customPickupText[pickupUIItem.pickupType] &&
                                    <h4> Action: F</h4>
                                    }
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                );
            }
        }
        else {
            return null
        }
    }
}


function mapStateToProps(state) {
    return {
        pickupUIItem: state.stuff.pickupUIItem,
        adblock: state.stuff.adblock,
        statsData: state.stuff.statsData,
    }
}

export default connect(
    mapStateToProps,
)(PickupBox)
