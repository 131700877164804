import React, { Component } from 'react'
import { Grid, LinearProgress } from '@mui/material'
import { Paper } from '@mui/material'
import { Box } from '@mui/material'
import { connect } from 'react-redux'
import { ButtonBase } from '@mui/material'
import Challenges from './Challenges.js'
import Constants from '../../shared/constants'
import { DailyQuestsData, DailyQuestRewardsData } from '../../shared/dailyQuests'
import * as Actions from '../actions/actions'
import ItemReward from './ItemReward.js'
import { styled } from '@mui/material/styles';
import {invertedInnerScreenBorderProperties, bigScreenBorderProperties, colorPalette} from './uiStyles.js'
import {baseUrl} from "../index";
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import Typography from '@mui/material/Typography'


const StyledLinearProgress = styled(LinearProgress)({
    backgroundColor: '#362D44', // Color for the background
    '& .MuiLinearProgress-bar': {
        backgroundColor: '#0A83D4', // Color for the bar
    },
});


const columns = [
    {
        id: 'username', label: 'Username', align: 'center',
    },
    {
        id: 'score', label: 'Score', align: 'center',
    },
]

class DailyQuests extends Component {
    constructor() {
        super()
        this.state = {

            dailyQuestsTimeLeft: 45797634,
            time: {
                hours: 12,
                minutes: 30,
                seconds: 30,
            },

            dialogNewItem: false,
            itemEarnedPath: 'assets/emotes/smile.png',
            itemEarned: {
                id: 1,
                name: 'Thumbs Up',
                imgPath: 'thumb_up.png',
                rarity: 'common',
                unlockRequirement: null,
                free: true,
                type: 'emote'
            },
            itemEarnedMessage: 'Legendary Emote',
        }
        this.timer = 0
        this.startTimer = this.startTimer.bind(this)
        this.countDown = this.countDown.bind(this)
    }

    componentDidMount() {

        let { dailyQuestsTimeLeft } = this.props
        this.setState({ dailyQuestsTimeLeft: dailyQuestsTimeLeft / 1000 })


        this.startTimer()
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60))

        let divisor_for_minutes = secs % (60 * 60)
        let minutes = Math.floor(divisor_for_minutes / 60)

        let divisor_for_seconds = divisor_for_minutes % 60
        let seconds = Math.ceil(divisor_for_seconds)

        let obj = {
            hours: hours,
            minutes: minutes,
            seconds: seconds
        }
        return obj
    }


    startTimer() {
        if (this.timer == 0 && this.state.dailyQuestsTimeLeft > 0) {
            this.timer = setInterval(this.countDown, 1000)
        }
    }


    countDown() {
        // Remove one second, set state so a re-render happens.
        let dailyQuestsTimeLeft = this.state.dailyQuestsTimeLeft - 1
        this.setState({
            time: this.secondsToTime(dailyQuestsTimeLeft),
            dailyQuestsTimeLeft: dailyQuestsTimeLeft,
        })

        // Check if we're at zero.
        if (dailyQuestsTimeLeft == 0) {
            clearInterval(this.timer)
        }
    }

    claimQuest(questId, token, guestAccount) {
        console.log('CALLING CLAIM QUEST', {
            questId: questId,
            guestAccount: guestAccount,
        })
        fetch(`${baseUrl}/account/claimQuest`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true
            },
            body: JSON.stringify({
                questId: questId,
                token: token,
                guest: guestAccount
            })
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json().then(user => {
                        let dailyQuests = user.accountQuests

                        Actions.updateDailyQuests(dailyQuests)
                        Actions.updateCoins(user.coins)

                        let reward
                        let rewardImgPath
                        let itemEarnedMessage

                        reward = { rarity: 'rare', quantity: user.reward.quantity, type: 'coins' }
                        rewardImgPath = 'assets/ui/rune.png'
                        itemEarnedMessage = `${user.reward.quantity} Runes`

                        this.setState({
                            itemEarnedPath: rewardImgPath,
                            itemEarned: reward,
                            itemEarnedMessage: itemEarnedMessage,
                            dialogNewItem: true
                        })
                    })
                }
            })
    }


    render() {

        let { dialogNewItem, itemEarnedPath, itemEarned, itemEarnedMessage, dailyQuestsTimeLeft, time } = this.state;
        const handleCloseNewItem = () => { this.setState({ 'dialogNewItem': false }) };

        let { dailyQuests, token, guestAccount } = this.props;
        let runeImgPath = 'assets/ui/rune.png';

        let questsComplete = 0;
        let updatedDailyQuests = dailyQuests.map(quest => {
            const questInfo = DailyQuestsData.find(q => q.id === quest.id);
            const questReward = DailyQuestRewardsData[questInfo.difficulty].quantity;

            let progressWriting, status;
            if (quest.turnedIn) {
                status = 'claimed';
                progressWriting = 'CLAIMED';
                questsComplete += 1;
            } else if (quest.completed) {
                status = 'claim';
                progressWriting = 'CLAIM';
                questsComplete += 1;
            } else {
                progressWriting = `${quest.progress} / ${questInfo.quantity}`;
                status = 'unearned';
            }

            return {
                ...quest,
                questInfo,
                questReward,
                progressWriting,
                questStatus: status
            };
        });

        let questGrid = updatedDailyQuests.map((quest, index) => {
            return (
                <React.Fragment key={index}>
                    <TableRow align='center' hover>
                        <TableCell align='center' style={{ padding: '1px', borderBottom: 'none', width: '70%' }}>
                            <Grid xs={12}>
                                {quest.questInfo.title}
                            </Grid>
                            <Grid xs={12}>
                                <ButtonBase
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        if (quest.completed && !quest.turnedIn) {
                                            this.claimQuest(quest.id, token, guestAccount)
                                        }
                                    }}
                                >
                                    <StyledLinearProgress
                                        color="primary"
                                        style={{
                                            width: '90%',
                                            height: '20px',
                                            margin: '2%',
                                            borderRadius: '6px',
                                            marginBottom: '2.5%',
                                            borderWidth: '2px', borderStyle: 'solid', borderColor: 'black',
                                            boxShadow: '0 0 0 2px #FCD5A2',
                                        }}
                                        variant="determinate"
                                        value={(quest.progress / quest.questInfo.quantity) * 100}
                                    />
                                    <Typography variant="body2" color="textSecondary" style={{
                                        position: 'absolute',
                                        color: 'white',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                    }}>
                                        {quest.progressWriting}
                                    </Typography>
                                </ButtonBase>
                            </Grid>
                        </TableCell>
                        <TableCell align='center' style={{ padding: '1px', borderBottom: 'none', width: '30%' }}>
                            <ButtonBase>
                                <img
                                    style={{
                                        position: 'relative', pointerEvents: 'none',
                                        width: '35px',
                                        margin: 'auto',
                                    }}
                                    src={runeImgPath}
                                />
                                <div style={{
                                    position: 'absolute',
                                    right: '5%',
                                    bottom: '1%',
                                    pointerEvents: 'none',
                                    zIndex: 10,
                                    color: 'white',
                                    fontSize: '15px',
                                    textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
                                }}>
                                    {quest.questReward}
                                </div>
                            </ButtonBase>
                        </TableCell>
                    </TableRow>
                </React.Fragment>
            );
        });
        return <>
            <Paper
                style={Object.assign({
                    background: colorPalette.primary,
                    padding: '7px',
                    minWidth: '243px',
                    maxWidth: '243px',
                    borderColor: colorPalette.primaryBorder,
                }, bigScreenBorderProperties)}
            >
                <Grid container xs={12}>
                    <Grid xs={8}>
                        <div style={{
                            fontWeight: 900,
                            // fontSize: 'max(1.5vh, 16px)', 
                            fontSize: 'max(1.5vh, 19px)',
                            maxHeight: '31px',
                            paddingBottom: '3%'
                        }}> DAILY QUESTS </div>
                    </Grid>
                    <Grid container justifyContent='flex-end' xs={4}>
                        <div style={{
                            fontWeight: 900,
                            // fontSize: 'max(1.5vh, 16px)', 
                            fontSize: 'max(1.5vh, 19px)',
                            maxHeight: '31px',
                            paddingBottom: '3%'
                        }}> {questsComplete} / {dailyQuests.length} </div>
                    </Grid>

                </Grid>

                <Grid xs={12}>
                    Time Left: {time.hours}h {time.minutes}m  {time.seconds}s
                </Grid>

                <Grid xs={12}>
                    <TableContainer align='center'
                        style={Object.assign({
                            height: '100%',
                            boxShadow: `0 0 0 2px ${colorPalette.secondaryBorder}`,
                        }, invertedInnerScreenBorderProperties)} >
                        <Table align='center' style={{
                            paddingLeft: '3%',
                            paddingRight: '3%',
                            paddingBottom: '3%',
                        }}>
                            <Box style={{ minHeight: '3px' }}></Box>
                            <TableBody align='center'>
                                {questGrid}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid xs={12}
                    style={{
                        marginTop: '10px'
                    }}
                >
                </Grid>



                <Grid xs={12}
                    style={{
                        marginTop: '10px'
                    }}
                >

                    <Challenges style={{
                        // minWidth: '170px'
                        minWidth: '100%'

                    }} />
                </Grid>
            </Paper>

            <ItemReward
                dialogNewItem={dialogNewItem}
                handleCloseNewItem={handleCloseNewItem}
                itemEarned={itemEarned}
                itemEarnedMessage={itemEarnedMessage}
                itemEarnedPath={itemEarnedPath}
            />
        </>;
    }
}

function mapStateToProps(state) {
    return {
        dailyQuests: state.stuff.dailyQuests,
        dailyQuestsTimeLeft: state.stuff.dailyQuestsTimeLeft,

        token: state.stuff.token,
        guestAccount: state.stuff.guestAccount,
    }
}

export default connect(
    mapStateToProps,
)(DailyQuests)
