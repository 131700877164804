"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkBulletConditions = void 0;
function checkBulletConditions(spellData, condition, clickData) {
    let ret;
    // TODO: Currently only one condition is supported. Implement support for multiple conditions (given architecture, some are inherently not allowed together).
    if (spellData.conditions) {
        for (let i = 0; i < spellData.conditions.length; i++) {
            const condition = spellData.conditions[i];
            if (!condition.type) {
                continue;
            }
            switch (condition.type) {
                // TODO: implement all types of conditions
                // 'clickOption' | 'moveOption' | 'envOption' | 'chainOption'| 'spellCombo'
                // | 'effectOption' | 'itemOption' | 'probabilityOption' | 'customOption',
                case 'clickOption':
                    if (condition.clickOption && clickData && clickData.objectType) {
                        if (condition.clickOption === clickData.objectType && condition.result) {
                            ret = checkConditionResult(spellData, condition.result, clickData);
                        }
                    }
                    else {
                        // condition exists but no clickData. simply use normal spell
                        ret = spellData;
                    }
                    break;
                // case 'moveOption':
                // case 'envOption':
                // case 'chainOption':
                // case 'spellCombo':
                // case 'effectOption':
                // case 'itemOption':
                // case 'probabilityOption':
                // case 'customOption':
                default:
                    ret = spellData;
                    break;
            }
        }
    }
    else {
        ret = spellData;
    }
    return ret;
}
exports.checkBulletConditions = checkBulletConditions;
// returns original spellData for no result. undefined for trigger failing
function checkConditionResult(spellData, result, clickData = undefined) {
    let ret;
    for (let i = 0; i < result.length; i++) {
        let resultInstance = result[i];
        switch (resultInstance.type) {
            case 'altSpell':
                if (resultInstance.altSpellID) {
                    ret = resultInstance.altSpellID;
                }
                else {
                    ret = spellData;
                }
                break;
            case 'triggerSpell':
                if (resultInstance.trigger) {
                    ret = spellData;
                }
                else {
                    return;
                }
                break;
            case 'replaceProps':
                // let newSpell = JSON.parse(JSON.stringify(spellData))
                let newSpell = Object.assign({}, spellData);
                for (let i = 0; i < resultInstance.replaceProp.length; i++) {
                    const prop = resultInstance.replaceProp[i];
                    if (prop.prop && prop.value) {
                        newSpell[prop.prop] = prop.value;
                        // console.log(`replaced ${prop.prop} with ${prop.value}. newSpell value: ${newSpell[prop.prop]}, old spell value: ${spellData[prop.prop]}`)
                    }
                }
                ret = newSpell;
                break;
            // case 'triggerEffect':
            // link result effect to spellData's effect appropriately and return spellData
            default:
                ret = spellData;
                break;
        }
    }
    return ret;
}
