"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnemyTierById = exports.getEnemyNameByTier = exports.petIDList = exports.petIdByTier = exports.iconByType = exports.tierIconColors = exports.labelColors = exports.petData = exports.monsterBuildTypes = exports.enemyIdByLevel = exports.petExpPerNextLevel = exports.petDefaultStats = exports.petConfig = void 0;
exports.petConfig = {
    maxSlot: 10,
    maxActiveSlot: 2,
    // maxLevelCap: 30,
    maxLevelCap: 15,
    playerFlatModifier: {
        health: 0,
        damage: 0,
        defense: 0,
        healthRegen: 0,
        manaRegen: 0,
    },
    playerPercentModifier: {
        health: 0,
        damage: 0,
        defense: 0,
        healthRegen: 0,
        manaRegen: 0,
    },
    flatModifierEffectiveness: {
        health: 0,
        damage: 0.02,
        defense: 0,
        healthRegen: 0,
        manaRegen: 0,
    },
    percentModifierEffectiveness: {
        health: 0,
        damage: 0,
        defense: 0.01,
        healthRegen: 0.005,
        manaRegen: 0,
    },
    modifierType: {
        health: 'flat',
        // health: 'percent',
        damage: 'flat',
        defense: 'percent',
        healthRegen: 'percent',
        manaRegen: 'flat',
    },
};
exports.petDefaultStats = {
    health: 200,
    damage: 5,
    defense: 0,
    healthRegen: 7,
    manaRegen: 10,
};
exports.petExpPerNextLevel = {
    // 1: 2000,
    // 2: 3000,
    // 3: 4500,
    // 4: 6750,
    1: 700,
    2: 1000,
    3: 2000,
    4: 4000,
    5: 10125,
    6: 15188,
    7: 22782,
    8: 34173,
    9: 51260,
    10: 76890,
    11: 115335,
    12: 173003,
    13: 259505,
    14: 389258,
    15: 428184,
    16: 471002,
    17: 518102,
    18: 569912,
    19: 626903,
    20: 689593,
    21: 758552,
    22: 834407,
    23: 917848,
    24: 1009633,
    25: 1110596,
    26: 1221656,
    27: 1343822,
    28: 1478204,
    29: 1626024,
    30: 1788626,
    31: 20971520000,
    32: 41943040000,
    33: 83886080000,
    34: 167772160000,
    35: 335544320000,
    36: 671088640000,
    37: 1342177280000,
    38: 2684354560000,
    39: 5368709120000,
    40: 10737418240000,
};
exports.enemyIdByLevel = {
    l1: [1, 7, 15, 16, , 9000, 9001, 9002, 9003],
    l2: [1000, 7000, 15000, 16000],
    l3: [],
    l4: [],
    l5: [],
    l6: [],
    l7: [],
    l8: [],
    l9: [],
    l10: [],
    l11: [],
    l12: [],
    l13: [],
    l14: [],
    l15: [],
};
const petDataTemplate = {
    base: {
        petId: 9000,
        name: 'base',
        exp: 0,
        level: 1,
        tier: 1,
        type: 'Slime',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [900, 26],
        playerPetSpell: 26,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 1,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0
        },
        modifiedStats: {
            health: 200,
            damage: 1,
            defense: 1,
            healthRegen: 7,
            manaRegen: 10,
        },
        state: 0,
        imgPath: '',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    slime: {
        petId: 9001,
        name: 'Slimey',
        exp: 0,
        level: 1,
        tier: 1,
        type: 'Slime',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [960],
        playerPetSpell: 951,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/slime_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    charlie: {
        petId: 9016,
        name: 'Mini Charlie',
        exp: 0,
        level: 1,
        tier: 1,
        type: 'Demon',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [417, 41],
        playerPetSpell: 41,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/mini_charlie_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    babyDragon: {
        petId: 9015,
        name: 'Baby Dragon',
        exp: 0,
        level: 1,
        tier: 1,
        type: 'Dragon',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [1, 26],
        playerPetSpell: 26,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/dragon_whelp_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    critter: {
        petId: 9007,
        name: 'Critter',
        exp: 0,
        level: 1,
        tier: 1,
        type: 'Nature',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [10],
        playerPetSpell: 469,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/woodland_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    bigCritter: {
        petId: 9013,
        name: 'Big Critter',
        exp: 0,
        level: 1,
        tier: 2,
        type: 'Nature',
        subType: 'Slime',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [39, 413],
        playerPetSpell: 413,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/woodland_beast_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    aquaFlower: {
        petId: 9021,
        name: 'Aqua Flower',
        exp: 0,
        level: 1,
        tier: 2,
        type: 'Nature',
        subType: 'Dragon',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [441, 41],
        playerPetSpell: 956,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/flower_creature_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    leopard: {
        petId: 9006,
        name: 'Leopard',
        exp: 0,
        level: 1,
        tier: 2,
        type: 'Nature',
        subType: 'Demon',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [401, 422],
        playerPetSpell: 422,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/leopard_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    mammoth: {
        petId: 9038,
        name: 'Mammoth',
        exp: 0,
        level: 1,
        tier: 2,
        type: 'Demon',
        subType: 'Nature',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [457, 429],
        playerPetSpell: 429,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/mammoth_normal_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    crab: {
        petId: 9020,
        name: 'Crab',
        exp: 0,
        level: 1,
        tier: 2,
        type: 'Demon',
        subType: 'Dragon',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [426, 467],
        playerPetSpell: 467,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/crab_normal_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    bigCharlie: {
        petId: 9005,
        name: 'Big Charlie',
        exp: 0,
        level: 1,
        tier: 2,
        type: 'Demon',
        subType: 'Slime',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [400, 428],
        playerPetSpell: 428,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/evil_creature_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    ent: {
        petId: 9019,
        name: 'Ent',
        exp: 0,
        level: 1,
        tier: 2,
        type: 'Dragon',
        subType: 'Nature',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [67, 82],
        playerPetSpell: 82,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/ent_normal_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    darkWizard: {
        petId: 9002,
        name: 'Dark Wizard',
        exp: 0,
        level: 1,
        tier: 2,
        type: 'Dragon',
        subType: 'Demon',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [468, 425],
        playerPetSpell: 425,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/dark_wizard_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    dragon: {
        petId: 9008,
        name: 'Dragon',
        exp: 0,
        level: 1,
        tier: 2,
        type: 'Dragon',
        subType: 'Slime',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [404, 60],
        playerPetSpell: 60,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/dragon_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    redSlime: {
        petId: 9017,
        name: 'Power Slime',
        exp: 0,
        level: 1,
        tier: 2,
        type: 'Slime',
        subType: 'Nature',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [418, 422],
        playerPetSpell: 422,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/slime_red_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    lavaSlime: {
        petId: 9029,
        name: 'Lava Slime',
        exp: 0,
        level: 1,
        tier: 2,
        type: 'Slime',
        subType: 'Demon',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [81],
        playerPetSpell: 470,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/slime_lava_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    iceSlime: {
        petId: 9043,
        name: 'Ice Slime',
        exp: 0,
        level: 1,
        tier: 2,
        type: 'Slime',
        subType: 'Dragon',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [46],
        playerPetSpell: 466,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/ice_slime_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    seahorse: {
        petId: 9035,
        name: 'Seahorse',
        exp: 0,
        level: 1,
        tier: 3,
        type: 'Nature',
        subType: 'Slime',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [446],
        playerPetSpell: 77,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/sea_horse_normal_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    starfish: {
        petId: 9018,
        name: 'Starfish',
        exp: 0,
        level: 1,
        tier: 3,
        type: 'Nature',
        subType: 'Dragon',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [424],
        playerPetSpell: 956,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/starfish_normal_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    hellhound: {
        petId: 9023,
        name: 'Hellhound',
        exp: 0,
        level: 1,
        tier: 3,
        type: 'Nature',
        subType: 'Demon',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [430, 438],
        playerPetSpell: 438,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/hellhound_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    piranha: {
        petId: 9033,
        name: 'Piranha',
        exp: 0,
        level: 1,
        tier: 3,
        type: 'Demon',
        subType: 'Nature',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [426, 429],
        playerPetSpell: 429,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/piranha_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    iceGolem: {
        petId: 9041,
        name: 'Ice Golem',
        exp: 0,
        level: 1,
        tier: 3,
        type: 'Demon',
        subType: 'Dragon',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [448, 409],
        playerPetSpell: 409,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/ice_golem_normal_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    charles: {
        petId: 9014,
        name: 'Charles',
        exp: 0,
        level: 1,
        tier: 3,
        type: 'Demon',
        subType: 'Slime',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [414, 431],
        playerPetSpell: 431,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/big_charlie_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    crabSuperior: {
        petId: 9031,
        name: 'Spikey Crab',
        exp: 0,
        level: 1,
        tier: 3,
        type: 'Dragon',
        subType: 'Nature',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [442, 444],
        playerPetSpell: 444,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/crab_superior_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    mammothSuperior: {
        petId: 9039,
        name: 'Shadow Mammoth',
        exp: 0,
        level: 1,
        tier: 3,
        type: 'Dragon',
        subType: 'Demon',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [458, 447],
        playerPetSpell: 447,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/mammoth_superior_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    cannon: {
        petId: 9009,
        name: 'Cannon',
        exp: 0,
        level: 1,
        tier: 3,
        type: 'Dragon',
        subType: 'Slime',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [405, 439],
        playerPetSpell: 439,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/cannon_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    slimeBoss: {
        petId: 9004,
        name: 'Slime Boss',
        exp: 0,
        level: 1,
        tier: 3,
        type: 'Slime',
        subType: 'Nature',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [412],
        playerPetSpell: 951,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/slime_boss_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    lavaSlimeBoss: {
        petId: 9028,
        name: 'Lava Slime Boss',
        exp: 0,
        level: 1,
        tier: 3,
        type: 'Slime',
        subType: 'Demon',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [422, 433],
        playerPetSpell: 433,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/slime_lava_boss_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
    iceSlimeBoss: {
        petId: 9044,
        name: 'Ice Slime Boss',
        exp: 0,
        level: 1,
        tier: 3,
        type: 'Slime',
        subType: 'Dragon',
        maxLevel: exports.petConfig.maxLevelCap,
        spells: [453, 452],
        playerPetSpell: 452,
        baseStats: {
            health: 200,
            damage: 1,
            defense: 0,
            healthRegen: 7,
            manaRegen: 10,
        },
        statsPerLevelUp: {
            health: 100,
            damage: 0.2,
            defense: 1,
            healthRegen: 1,
            manaRegen: 0,
        },
        modifiedStats: {
            health: 770,
            damage: 1,
            defense: 25,
            healthRegen: 50,
            manaRegen: 50,
        },
        state: 0,
        imgPath: '../../gifs/ice_slime_boss_idle.gif',
        expAllowed: true,
        fusionLevel: 0,
        fusionSelected: false
    },
};
exports.monsterBuildTypes = [
    {
        type: 'tank',
        names: ['charlie', 'leopard', 'mammoth', 'crab', 'piranha', 'crabSuperior', 'mammothSuperior', 'redSlime', 'iceSlimeBoss'],
    },
    {
        type: 'dps',
        names: ['babyDragon', 'bigCritter', 'dragon', 'darkWizard', 'hellhound', 'cannon', 'lavaSlimeBoss'],
    },
    {
        type: 'healer',
        names: ['slime', 'slimeBoss', 'starfish', 'aquaFlower'],
    },
    {
        type: 'buffer',
        names: ['critter', 'ent', 'seahorse', 'lavaSlime', 'iceSlime'],
    },
    {
        type: 'allRounder',
        names: ['base', 'bigCharlie', 'iceGolem', 'charles'],
    },
];
const monsterListByManaCost = [
    {
        cost: 10,
        names: [],
    },
    {
        cost: 20,
        names: [],
    },
    {
        cost: 30,
        names: [],
    },
    {
        cost: 40,
        names: [],
    },
    {
        cost: 50,
        names: ['critter', 'ent', 'seahorse', 'lavaSlime', 'iceSlime'],
    },
    {
        cost: 60,
        names: ['slime', 'slimeBoss', 'starfish', 'aquaFlower'],
    },
    {
        cost: 70,
        names: ['charlie', 'leopard', 'mammoth', 'crab', 'piranha', 'crabSuperior', 'mammothSuperior', 'redSlime', 'iceSlimeBoss'],
    },
    {
        cost: 80,
        names: ['base', 'bigCharlie', 'iceGolem', 'charles'],
    },
    {
        cost: 90,
        names: ['babyDragon', 'bigCritter', 'dragon', 'darkWizard', 'hellhound', 'cannon', 'lavaSlimeBoss'],
    },
    {
        cost: 100,
        names: [],
    },
    {
        cost: 110,
        names: [],
    },
    {
        cost: 120,
        names: [],
    },
    {
        cost: 130,
        names: [],
    },
    {
        cost: 140,
        names: [],
    },
    {
        cost: 150,
        names: [],
    },
    {
        cost: 160,
        names: [],
    },
];
function setBaseData() {
    for (const pet in petDataTemplate) {
        petDataTemplate[pet].baseStats = {
            health: 1000,
            damage: 1,
            defense: 20,
            healthRegen: 20,
            manaRegen: 10,
        };
        petDataTemplate[pet].statsPerLevelUp = {
            health: 1000,
            damage: 1,
            defense: 20,
            healthRegen: 20,
            manaRegen: 1,
        };
        petDataTemplate[pet].modifiedStats = {
            health: 1000,
            damage: 1,
            defense: 20,
            healthRegen: 20,
            manaRegen: 10,
        };
    }
    exports.monsterBuildTypes.forEach((group) => {
        switch (group.type) {
            case 'tank':
                group.names.forEach((name) => {
                    petDataTemplate[name].statsPerLevelUp = {
                        health: 1091.67,
                        damage: 0.74,
                        defense: 22.67,
                        healthRegen: 22.17,
                        manaRegen: 1,
                    };
                });
                break;
            case 'dps':
                group.names.forEach((name) => {
                    petDataTemplate[name].statsPerLevelUp = {
                        health: 737.47,
                        damage: 1.6,
                        defense: 8.67,
                        healthRegen: 15.08,
                        manaRegen: 1,
                    };
                });
                break;
            case 'healer':
            case 'buffer':
                group.names.forEach((name) => {
                    petDataTemplate[name].statsPerLevelUp = {
                        health: 737.47,
                        damage: 0.74,
                        defense: 22.67,
                        healthRegen: 15.08,
                        manaRegen: 1,
                    };
                });
                break;
            // allRounder can stay with base stats
            // case 'allRounder':
            //     group.names.forEach((name) => {
            //         petDataTemplate[name].statsPerLevelUp = {
            //             health: 100,
            //             damage: 0.4,
            //             defense: 3,
            //             healthRegen: 1,
            //             manaRegen: 1,
            //         }
            //     })
            //     break;
            default:
                break;
        }
    });
    monsterListByManaCost.forEach((group) => {
        if (group.names.length > 0) {
            group.names.forEach((name) => {
                petDataTemplate[name].playerPetSpellCost = group.cost;
            });
        }
    });
    return petDataTemplate;
}
exports.petData = setBaseData();
exports.labelColors = {
    enemies: '#a81414',
    // enemies: '#cf550e',
    players: '#2FAD25',
    activePets: '#2C8F24',
    otherPlayerPets: '#0F7F06',
    npc: '#00FF00',
};
exports.tierIconColors = {
    t1: '#70767F',
    t2: '#2FAD25',
    t3: '#0174c3',
    t4: '#FED141',
};
exports.iconByType = {
    Slime: 'enemy_slime.png',
    Demon: 'enemy_evilCreature.png',
    Dragon: 'enemy_greenDragonWhelp.png',
    Nature: 'enemy_woodlandWhelp.png',
};
function listPetIdByTier() {
    let petIdByTier = {
        t1: [],
        t2: [],
        t3: [],
    };
    for (const pet in exports.petData) {
        if (exports.petData[pet].tier === 1) {
            petIdByTier.t1.push(exports.petData[pet].petId);
        }
        else if (exports.petData[pet].tier === 2) {
            petIdByTier.t2.push(exports.petData[pet].petId);
        }
        else if (exports.petData[pet].tier === 3) {
            petIdByTier.t3.push(exports.petData[pet].petId);
        }
    }
    return petIdByTier;
}
exports.petIdByTier = listPetIdByTier();
function listPetIDs() {
    const petIDs = [];
    for (const pet in exports.petData) {
        petIDs.push(exports.petData[pet].petId);
    }
    return petIDs;
}
exports.petIDList = listPetIDs();
function getEnemyNameByTier(tier) {
    let enemyNames = [];
    for (const pet in exports.petData) {
        if (exports.petData[pet].tier === tier) {
            enemyNames.push(JSON.parse(JSON.stringify(exports.petData[pet].name)));
        }
    }
    return enemyNames;
}
exports.getEnemyNameByTier = getEnemyNameByTier;
function getEnemyTierById(enemyID) {
    for (const tier in exports.petIdByTier) {
        if (exports.petIdByTier[tier].includes(enemyID + 9000)) {
            return tier;
        }
    }
    return null;
}
exports.getEnemyTierById = getEnemyTierById;
// export const primarySpells = ['staff', 'wand', 'dagger', 'sword']
// export const secondarySpells = ['spell', 'tome', 'cloak','dash']
// export const gearDropColors = {
//     't0': {'customCircleColor': '#70767F'}, // base common
//     't1': {'customCircleColor': '#2FAD25'}, // base uncommon
//     't2': {'customCircleColor': '#2C8F24'},
//     't3': {'customCircleColor': '#0F7F06'},
//     't4': {'customCircleColor': '#0E7006'},
//     't5': {'customCircleColor': '#1A82FF'},
//     't6': {'customCircleColor': '#0B6FE7'}, // base rare
//     't7': {'customCircleColor': '#085EC6'},
//     't8': {'customCircleColor': '#0353B4'},
//     't9': {'customCircleColor': '#8948EF'}, // base epic
//     't10': {'customCircleColor': '#6E37C4'},
//     't11': {'customCircleColor': '#552C94'},
//     't12': {'customCircleColor': '#F47C40'}, // base legendary
// }
// export const gloveSpellColors = {
//     't0': {'customCircleColor': '#70767F'},
//     't1': {'customCircleColor': '#2C8F24'},// base uncommon
//     't2': {'customCircleColor': '#1A82FF'},
//     't3': {'customCircleColor': '#1A82FF'},// base rare
//     't4': {'customCircleColor': '#0353B4'},
//     't5': {'customCircleColor': '#8948EF'}, // base epic
//     't6': {'customCircleColor': '#F47C40'}, // base legendary
// }
// export const primarySpellColors = {
//     't0': {'customCircleColor': '#70767F'}, // base common
//     't1': {'customCircleColor': '#2FAD25'}, // base uncommon
//     't2': {'customCircleColor': '#2C8F24'},
//     't3': {'customCircleColor': '#0F7F06'},
//     't4': {'customCircleColor': '#0E7006'},
//     't5': {'customCircleColor': '#1A82FF'}, // base rare
//     't6': {'customCircleColor': '#085EC6'},
//     't7': {'customCircleColor': '#0353B4'},
//     't8': {'customCircleColor': '#8948EF'}, // base epic
//     't9': {'customCircleColor': '#6E37C4'},
// }
// export const secondarySpellColors = {
//     't0': {'customCircleColor': '#70767F'},
//     't1': {'customCircleColor': '#2C8F24'},// base uncommon
//     't2': {'customCircleColor': '#1A82FF'},
//     't3': {'customCircleColor': '#1A82FF'},// base rare
//     't4': {'customCircleColor': '#0353B4'},
//     't5': {'customCircleColor': '#8948EF'}, // base epic
//     't6': {'customCircleColor': '#F47C40'}, // base legendary
// }
// function createGearArray(tier, weight) {
//     return [
//         {type: 'top', id: 100+tier, weight: weight},
//         {type: 'top', id: 200+tier, weight: weight},
//         {type: 'top', id: 300+tier, weight: weight},
//         {type: 'body', id: 100+tier, weight: weight},
//         {type: 'body', id: 200+tier, weight: weight},
//         {type: 'body', id: 300+tier, weight:weight},
//     ]
// }
// function createGlovesArray(tier, weight) {
//     return [
//         {type: 'hand', id: 110 + tier, weight: weight},
//         {type: 'hand', id: 120 + tier, weight: weight},
//         {type: 'hand', id: 130 + tier, weight: weight},
//         {type: 'hand', id: 140 + tier, weight: weight},
//         {type: 'hand', id: 150 + tier, weight: weight},
//         {type: 'hand', id: 160 + tier, weight: weight},
//         {type: 'hand', id: 170 + tier, weight: weight},
//         {type: 'hand', id: 180 + tier, weight: weight},
//     ]
// }
// function createWeaponsArray(tier, weight) {
//     const staffTier = 248 + tier
//     const wandTier = 231 + tier
//     const swordTier = 900 + tier
//     const daggerTier = 930 + tier
//     return [
//         {type: 'spell', id: staffTier, weight: weight},
//         {type: 'spell', id: wandTier, weight: weight},
//         {type: 'spell', id: swordTier, weight: weight},
//         {type: 'spell', id: daggerTier, weight: weight},
//     ]
// }
// function createSecondaryWeaponsArray(tier, weight) {
//     const spellTier = 800 + tier
//     const tomeTier = 850 + tier
//     const stealthTier = 750 + tier
//     const dashTier = 650 + tier
//     return [
//         {type: 'spell', id: spellTier, weight: weight},
//         {type: 'spell', id: tomeTier, weight: weight},
//         {type: 'spell', id: stealthTier, weight: weight},
//         {type: 'spell', id: dashTier, weight: weight},
//     ]
// }
// export const dropGroups = [
//     {
//       enemies: ['tinycreature'],
//       drops: [
//           {type: 'nothing', id: 0, weight: 1}
//       ]
//     },
//     {
//         enemies: ['slime','meleeSlime', 'flowerCreature'],
//         drops: [
//             {type: 'nothing', id: 0, weight: 32},
//         ].concat(
//             createGearArray(2, 8)).concat(
//                 createGearArray(3, 3)).concat(
//                     createWeaponsArray(1, 8)
//         )
//     },
//     {
//         enemies: ['leopard', 'woodlandWhelp', 'crabNormal', 'crabSuperior', 'starfishNormal','slimeBoss', 'meleeSlimeBoss'],
//         drops: [
//             {type: 'nothing', id: 0, weight: 32},
//         ].concat(
//             createGearArray(3, 8).concat(
//                 createGearArray(4, 4)).concat(
//                     createGlovesArray(1,2))).concat(
//                         createWeaponsArray(2, 8)).concat(
//             createSecondaryWeaponsArray(1, 4))
//     },
//     {
//         enemies: ['creature', 'seahorse', 'cannon', 'piranha', 'seahorseSuperior', 'kingLeopard', 'crabBoss'],
//         drops: [
//             {type: 'nothing', id: 0, weight: 32},
//         ].concat(
//             createGearArray(4, 8).concat(
//                 createGearArray(5, 1)).concat(
//                     createGlovesArray(2,2))).concat(
//             createWeaponsArray(3, 8)).concat(
//             createSecondaryWeaponsArray(2, 4))
//     },
//     {
//         enemies: ['iceGolem','babyDragonWhelp', 'darkWiz', 'piranhaBoss', 'cannonBoss', 'creatureBoss'],
//         drops: [
//             {type: 'nothing', id: 0, weight: 32},
//         ].concat(
//             createGearArray(5, 8)).concat(
//                 createGearArray(6, 2)).concat(
//                     createGlovesArray(2,2).concat(
//                         createWeaponsArray(4, 8)).concat(
//                         createSecondaryWeaponsArray(2, 4))
//         )
//     },
//     {
//         enemies: [ 'Mammoth', 'MammothSuperior', 'dragonWhelp', 'hellhound',  'piranhaBoss', 'cannonBoss', 'creatureBoss'],
//         drops: [
//             {type: 'nothing', id: 0, weight: 32},
//         ].concat(
//             createGearArray(6, 8)).concat(
//                 createGearArray(7, 2)).concat(
//                     createGlovesArray(3,2).concat(
//                         createWeaponsArray(5, 8)).concat(
//                         createSecondaryWeaponsArray(3, 4))
//         )
//     },
//     {
//         enemies: ['slimeIceBoss', 'darkWizBoss', 'slimeLavaBoss', 'hellhoundBoss'],
//         drops: [
//             {type: 'nothing', id: 0, weight: 32},
//         ].concat(
//             createGearArray(7, 8)).concat(
//                 createGearArray(8, 2)).concat(
//                     createGlovesArray(3,2).concat(
//                         createWeaponsArray(6, 8)).concat(
//                         createSecondaryWeaponsArray(3, 4))
//         )
//     },
//     {
//         enemies: ['dragonBig', 'iceGolemBoss'],
//         drops: [
//             {type: 'nothing', id: 0, weight: 32},
//         ].concat(
//             createGearArray(8, 8)).concat(
//                 createGearArray(9, 2)).concat(
//                     createGlovesArray(4,2).concat(
//                         createWeaponsArray(7, 8)).concat(
//                         createSecondaryWeaponsArray(4, 4))
//         )
//     }
// ]
// console.log('dropgroups', dropGroups)
