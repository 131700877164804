import React, { Component } from 'react'
import Button from '@mui/material/Button'

class ColorChangingButton extends Component {
    constructor() {
        super()
        this.state = {
            fillColor: '#E7CAB2',
            outerBorderColor: '#362D44',
            innerBorderColor: '#FFF1D2',
            config: {
                idle: {
                    fillColor: '#E7CAB2',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFF1D2',
                },
                hover: {
                    fillColor: '#E7CAB2',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFF1D2',
                },
                down: {
                    fillColor: '#E7CAB2',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFF1D2',
                }
            },
            countdown: null,
        }
    }

    componentDidMount() {
        let { config, countdown } = this.props
        this.setState({ config: config,
        fillColor: config.idle.fillColor,
        outerBorderColor: config.idle.outerBorderColor,
        innerBorderColor: config.idle.innerBorderColor,
        countdown: countdown
        })

        if (countdown && countdown > 0) {
            setTimeout(
                () => {
                    this.setState({
                        countdown: countdown - 1
                    })
                }, 1000

            )
        }
    }


    componentDidUpdate(prevProps, prevState) {
        const { countdown } = this.state
        if (prevState.countdown != countdown) {
            if (countdown && countdown > 0) {
                setTimeout(
                    () => {
                        this.setState({
                            countdown: countdown - 1
                        })
                    }, 1000

                )
            }
        }
    }


    render() {
        let { fillColor, innerBorderColor, outerBorderColor, config, countdown } = this.state
        let { buttonFunction, title, buttonStyling, textColor, image } = this.props
  
        if (countdown && countdown > 0) {
            title = `${countdown}...`
        }
        // console.log("CHOSEN FILL COLOR", fillColor)
        if (!textColor) {textColor = 'white'}
        return (
            <Button
                style={
                    Object.assign({
                        fontSize: '2vh',
                        // color: textColor,
                        borderRadius: '6px',
                        borderWidth: '2px', borderStyle: 'solid',
                        background: fillColor,
                        borderColor: innerBorderColor,
                        boxShadow: `0 0 0 2px ${outerBorderColor}`,
                        textTransform: 'none',
                        zIndex: 4
                    },
                    buttonStyling)
                }

                variant="contained"

                onMouseOver={() => {
                    this.setState({
                        fillColor: config.hover.fillColor,
                        outerBorderColor: config.hover.outerBorderColor,
                        innerBorderColor: config.hover.innerBorderColor
                    })
                }}
                onMouseOut={() => {
                    this.setState({
                        fillColor: config.idle.fillColor,
                        outerBorderColor: config.idle.outerBorderColor,
                        innerBorderColor: config.idle.innerBorderColor
                    })
                }
                }
                onMouseDown={(e) => {
                    this.setState({
                        fillColor: config.down.fillColor,
                        outerBorderColor: config.down.outerBorderColor,
                        innerBorderColor: config.down.innerBorderColor
                    })
                }}

                onClick={(e) => {
                   if (!countdown) {
                    e.nativeEvent.stopImmediatePropagation()
                    e.preventDefault()
                    e.stopPropagation()

                    buttonFunction()
                   }
                }}
            >


{image && 
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '70%',
        margin: 'auto'
    }}>
        <img style={{
            maxWidth: '70%',
            display: 'block',
        }}
        alt={'More Games!'}
        src={image}
        />
        <b style={{
            fontSize: 'max(1.2vh, 19px)',
            color: 'white',
            // textShadow: '0 0 8px black, 2px 2px 4px black, -2px -2px 4px black',
            marginTop: '20px', // Adjust as needed for spacing between the image and the text
        }}> 
            {title} 
        </b> 
    </div>
}


{!image &&
  <b  style={{
    fontSize: 'max(1.2vh, 19px)', color: textColor,
}}> 
{title} 
</b> 
}
            </Button >
        )
    }
}

export default (ColorChangingButton)