import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { Box } from '@mui/material'
import { connect } from 'react-redux'
import {
    updateHighscores,
    updateSelectedHighscoreGameMode,
    updateSelectedScore,
    updateSelectedTimeValue
} from '../actions/actions'
import Constants from '../../shared/constants'
import {
    smallBorderProperties,
    invertedInnerScreenBorderProperties,
    dropDownMenuProperties,
    bigScreenBorderProperties,
    colorPalette
} from './uiStyles.js'
import { MenuItem, Select } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'



import {domainName} from "../index";

function timeUnits(ms) {
    if (!Number.isInteger(ms)) {
        return null
    }
    /**
     * Takes as many whole units from the time pool (ms) as possible
     * @param {int} msUnit - Size of a single unit in milliseconds
     * @return {int} Number of units taken from the time pool
     */
    const allocate = msUnit => {
        const units = Math.trunc(ms / msUnit)
        ms -= units * msUnit
        return units
    }
    // Property order is important here.
    // These arguments are the respective units in ms.
    return {
        // weeks: (604800000), // Uncomment for weeks
        days: allocate(86400000),
        hours: allocate(3600000),
        minutes: allocate(60000),
        seconds: allocate(1000),
        ms: ms // remainder
    }
}

const columns = [
    {
        id: 'username', label: 'Username', align: 'center',
        // minWidth: 170
    },
    {
        id: 'score', label: 'Score', align: 'center',
        // minWidth: 100
    },
]

const scoreData = [
    {
        id: 1,
        nameId: 'score',
        name: 'Score',
        scoreName: 'score',
        gameModes: ['mageclash', 'grix', 'monstermaster']
        // gameModes: ['mageclash', 'grix']
    },
    {
        id: 2,
        nameId: 'creatureKills',
        name: 'Creature Kills',
        scoreName: 'creatureKills',
        gameModes: ['mageclash', 'grix', 'monstermaster']
        // gameModes: ['mageclash', 'grix']
    },
    {
        id: 3,
        nameId: 'playerKills',
        name: 'Player Kills',
        scoreName: 'playerKills',
        gameModes: ['mageclash']
    },
    {
        id: 3,
        nameId: 'chestKills',
        name: 'Chests Opened',
        scoreName: 'chestKills',
        gameModes: ['mageclash']
    },
    {
        id: 4,
        nameId: 'accountLevel',
        name: 'Account Level',
        allTimeOnly: true,
        scoreName: 'accountLevel',
        gameModes: ['mageclash', 'grix', 'monstermaster']
        // gameModes: ['mageclash', 'grix']
    },
    {
        id: 5,
        nameId: 'totalScore',
        name: 'Total Score',
        allTimeOnly: true,
        scoreName: 'totalScore',
        gameModes: ['mageclash', 'grix', 'monstermaster']
        // gameModes: ['mageclash', 'grix']
    },
    {
        id: 6,
        nameId: 'completedDailyQuests',
        name: 'Daily Quests',
        allTimeOnly: true,
        scoreName: 'completedDailyQuests',
        gameModes: ['mageclash', 'grix', 'monstermaster']
        // gameModes: ['mageclash', 'grix']
    },
    {
        id: 7,
        nameId: 'charlieChallengeTime',
        name: 'Trial - Charlie Challenge',
        type: 'trial',
        scoreName: 'time',
        gameModes: ['mageclash']
        // monthlyOnly: true,
    },
    {
        id: 9,
        // nameId: 'gnarlyGravityTime',
        // name: 'Trial - Gnarly Gravity',
        nameId: 'trapMazeTime',
        name: 'Trial - Trap Maze',
        type: 'trial',
        scoreName: 'time',
    gameModes: ['mageclash']
        // monthlyOnly: true,
    },
    {
        id: 10,
        nameId: 'iceMayhemTime',
        name: 'Trial - Ice Mayhem',
        type: 'trial',
        scoreName: 'time',
        gameModes: ['mageclash']
        // monthlyOnly: true,
    },
    {
        id: 8,
        nameId: 'dualDragonsTime',
        name: 'Trial - Dual Dragons',
        type: 'trial',
        scoreName: 'time',
        gameModes: ['mageclash']
        // monthlyOnly: true,
    },

    {
        id: 11,
        nameId: 'iceMayhemMammoth',
        name: 'Trial - Ice Mayhem Mammoth',
        type: 'trial',
        scoreName: 'time',
        gameModes: ['grix']
    },
    {
        id: 12,
        nameId: 'iceMayhemSlime',
        name: 'Trial - Ice Mayhem Slime',
        type: 'trial',
        scoreName: 'time',
        gameModes: ['grix']
    },
    {
        id: 13,
        nameId: 'dragonValley',
        name: 'Trial - Dragon Valley',
        type: 'trial',
        scoreName: 'time',
        gameModes: ['grix']
    },
    {
        id: 14,
        nameId: 'iceValley',
        name: 'Trial - Ice Valley',
        type: 'trial',
        scoreName: 'time',
        gameModes: ['grix']
    },
    {
        id: 16,
        nameId: 'hauntedGraveyard',
        name: 'Trial - Haunted Graveyard',
        type: 'trial',
        scoreName: 'time',
        gameModes: ['grix']
    }

]

const timeData = [
    // {
    //     id: 1,
    //     nameId: 'daily',
    //     name: 'Daily'
    // },
    {
        id: 2,
        nameId: 'weekly',
        name: 'Weekly'
    },
    {
        id: 3,
        nameId: 'monthly',
        name: 'Monthly'
    },
    {
        id: 4,
        nameId: 'allTime',
        name: 'All Time'
    }
]

const gameModeData = [
    {
        id: 1,
        nameId: 'mageclash',
        name: 'Mageclash'
    },
    {
        id: 2,
        nameId: 'grix',
        name: 'Grix'
    },
    {
        id: 3,
        nameId: 'monstermaster',
        name: 'Monster Master'
    },
]


class Highscores extends Component {
    constructor() {
        super()
        this.state = {
            scoreInfo: {
                id: 1,
                nameId: 'score',
                name: 'Score',
                scoreName: 'score'
            },
        }
    }

    componentDidMount() {
        let url
        if (domainName == 'localhost:3000') {
            url = 'http://localhost:3000'
        }
        else {
            url = `https://${domainName}`
        }
        let highScoreList
        console.log('calling fetch highscores')
        fetch(`${url}/getHighscores`).then(function (response) {
            // this.setState({loadedData: true})
            return response.text()
        }).then(
            function (text) {
                console.log("HIGHSCORES RECEIVED")

                updateHighscores(JSON.parse(text))
            }
        )
    }




    render() {
        const { classes } = this.props

        const handleChangeCategory = (event) => {
            if (!this.props.highscoresReceived) { return }
            let scoreType = scoreData.find(score => score.nameId == event.target.value)
            this.setState({
                scoreInfo: scoreType
            })
            if (scoreType.allTimeOnly) { updateSelectedTimeValue('allTime') }
            if (scoreType.monthlyOnly) { updateSelectedTimeValue('monthly')  }
            updateSelectedScore(event.target.value)
        }

        const handleChangeTime = (event) => {
            if (!this.props.highscoresReceived) { return }
            updateSelectedTimeValue(event.target.value)
        }
        const handleChangeGameMode = (event) => {
            if (!this.props.highscoresReceived) { return }
            console.log('updating game mode to: ', event.target.value)
            updateSelectedHighscoreGameMode(event.target.value)
            handleChangeCategory({ target: { value: 'score' } })
        }

        let { highscores, selectedScore, selectedTimeValue, highscoreSelectedGameMode } = this.props
        let { scoreInfo } = this.state

        // console.log("HIGHSCORES: ", JSON.stringify(highscores))
        // let filteredHighscores = highscores[selectedTimeValue][selectedScore]
        let filteredHighscores = highscores[highscoreSelectedGameMode][selectedTimeValue][selectedScore]
        // console.log('filteredHighscores: ', filteredHighscores)
        let scoreType = scoreData.find(score => score.nameId == selectedScore)


        var i
        // console.log('filteredHighscores: ', filteredHighscores)
        // console.log(' score info', scoreInfo)
        for (i = 0; i < filteredHighscores.length; i++) {
            // console.log('filteredHighscores[i]: ', filteredHighscores[i])
            if (scoreInfo.type == 'trial') {
                let usernameColumn = ''
                let playersList = JSON.parse(filteredHighscores[i].players)
                for (let b = 0; b < playersList.length; b++) {
                    if (b != playersList.length - 1) {
                        usernameColumn += `${playersList[b]}, `
                    } else {
                        usernameColumn += playersList[b]
                    }
                }
                filteredHighscores[i].username = usernameColumn

                let timeDict = timeUnits(filteredHighscores[i].timeToComplete)

                let timeDisplay

                if (timeDict.hours) {
                    timeDisplay = `${timeDict.hours}'${timeDict.minutes}'${timeDict.seconds}'${timeDict.ms}`
                } else if (timeDict.minutes) {
                    timeDisplay = `${timeDict.minutes}'${timeDict.seconds}'${timeDict.ms}`
                } else if (timeDict.seconds) {
                    timeDisplay = `${timeDict.seconds}'${timeDict.ms}`
                } else {
                    timeDisplay = `0'${timeDict.ms}`
                }
                filteredHighscores[i].time = timeDisplay
            }

            if (i == 0) {
                filteredHighscores[i].backgroundColor = '#ffd522'
                filteredHighscores[i].padding = '3px'
                filteredHighscores[i] = Object.assign(filteredHighscores[i], smallBorderProperties)
                filteredHighscores[i].top3 = true
            }
            else if (i == 1) {
                filteredHighscores[i].backgroundColor = '#e3f5ff'
                filteredHighscores[i].padding = '3px'
                filteredHighscores[i] = Object.assign(filteredHighscores[i], smallBorderProperties)
                filteredHighscores[i].top3 = true
            }
            else if (i == 2) {
                filteredHighscores[i].backgroundColor = '#d68a56'
                filteredHighscores[i].padding = '3px'
                filteredHighscores[i] = Object.assign(filteredHighscores[i], smallBorderProperties)
                filteredHighscores[i].top3 = true
            }
            else if ((i % 2) == 0) {
                filteredHighscores[i].padding = '1px'
                filteredHighscores[i].backgroundColor = '#efd3bd'
            }
            else {
                filteredHighscores[i].padding = '1px'
                filteredHighscores[i].backgroundColor = '#d8bca7'
            }
        }

        let scoreSelectTable = scoreData.map((data) => {
            if (data.gameModes.includes(highscoreSelectedGameMode)) {
                return <MenuItem
                    style={{ fontWeight: 'bold' }}
                    value={data.nameId}> {data.name} </MenuItem>
            }
        })


        let timeSelectTable = timeData.map((data) => {
            // index += 1
            // return <MenuItem value={server.url}> {server.name} - {server.players} </MenuItem>
            let disabled = false
            if (scoreType.allTimeOnly && data.nameId != 'allTime') { disabled = true }
            return <MenuItem
                style={{ fontWeight: 'bold' }}
                disabled={disabled} value={data.nameId}> {data.name} </MenuItem>
        })

        let gameSelectTable = gameModeData.map((data) => {
            // index += 1
            // return <MenuItem value={server.url}> {server.name} - {server.players} </MenuItem>
            let disabled = false
            // if (scoreType.allTimeOnly && data.nameId != 'allTime') { disabled = true }
            return <MenuItem
                style={{ fontWeight: 'bold' }}
                disabled={disabled} value={data.nameId}> {data.name} </MenuItem>
        })



        return <>
            <Grid container
                style={{
                    minHeight: '315px', maxHeight: '315px',
                }}>

                <Paper style={Object.assign({
                    userSelect: 'none',
                    textAlign: 'center',
                    width: '100%',
                    padding: '2%',
                    background: colorPalette.primary,
                    borderWidth: '1px',
                    borderColor: 'black',
                    borderStyle: 'solid',
                    minHeight: '315px', maxHeight: '315px',

                }, bigScreenBorderProperties)}>

                    <Grid container
                        justifyContent='center'
                        xs={12} style={{
                            width: '100%'
                        }} >
                        <Grid item xs={4}>
                            {/*{!Constants.SINGLE_GAME_NAME &&*/}
                            {/*<Select*/}
                            {/*    style={Object.assign({*/}
                            {/*        background: colorPalette.secondary,*/}
                            {/*        height: '20px',*/}
                            {/*        opacity: '100%',*/}
                            {/*        minWidth: '90px',*/}
                            {/*        boxShadow: `0 0 0 2px ${colorPalette.secondaryBorder}`,*/}
                            {/*    }, invertedInnerScreenBorderProperties)}*/}
                            {/*    value={highscoreSelectedGameMode}*/}
                            {/*    onChange={handleChangeGameMode}*/}
                            {/*    MenuProps={{*/}
                            {/*        PaperProps: {*/}
                            {/*            style: dropDownMenuProperties*/}
                            {/*        }*/}
                            {/*    }}*/}
                            {/*    inputProps={{ fontWeight: 'bold' }}*/}
                            {/*>*/}
                            {/*    {gameSelectTable}*/}
                            {/*</Select>*/}
                            {/*}*/}
                        </Grid>
                        <Grid item xs={4}>
                            <div style={{
                                fontWeight: 900,
                                fontSize: 'max(1.5vh, 19px)',

                                maxHeight: '31px',
                                paddingBottom: '3%'
                            }}>
                                SCORES

                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                style={Object.assign({
                                    background: colorPalette.secondary,
                                    height: '20px',
                                    opacity: '100%',
                                    minWidth: '90px',
                                    boxShadow: `0 0 0 2px ${colorPalette.secondaryBorder}`,
                                }, invertedInnerScreenBorderProperties)}
                                value={selectedTimeValue}
                                onChange={handleChangeTime}

                                MenuProps={{
                                    PaperProps: {
                                        style: dropDownMenuProperties
                                    }
                                }}
                                inputProps={{ fontWeight: 'bold' }}
                            >
                                {timeSelectTable}
                            </Select>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12}> */}

                    {/* </Grid> */}
                    <Grid container
                        justifyContent='center'
                        direction='column'
                        xs={12} style={{
                            width: '100%'
                        }} >
                        <Paper style={Object.assign({
                            background: colorPalette.highscoreGrid,
                            borderRadius: '4px',
                            width: '100%',
                            boxShadow: `0 0 0 2px ${colorPalette.secondaryBorder}`,
                        }, invertedInnerScreenBorderProperties)}>
                            <TableContainer align='center' style={{
                                height: '100%',
                            }} >

                                <Table align='center' style={{
                                    paddingLeft: '3%',
                                    paddingRight: '3%',
                                    paddingBottom: '3%',
                                }}>
                                    <TableHead align='center' style={{}}>
                                        <TableRow align='center'>
                                            {/* {columns.map((column) => (
                                            <TableCell align='center'
                                                key={column.id}
                                                align={column.align}
                                                style={{ 
                                                width: '50%',
                                                    padding: '1px',
                                                borderColor: 'black'
                                            
                                            }}
                                            >
                                                <b>{column.label}</b>
                                            </TableCell>
                                        ))} */}
                                            <TableCell 
                                                align='center'
                                                key='username'
                                                style={{
                                                    width: '50%',
                                                    padding: '1px',
                                                    borderColor: 'black'

                                                }}
                                            >
                                                <b>Username</b>
                                            </TableCell>

                                            <TableCell 
                                                align='center'
                                                key='score'
                                                style={{
                                                    width: '50%',
                                                    padding: '1px',
                                                    borderColor: 'black'

                                                }}
                                            >
                                                <Select
                                                    style={Object.assign({
                                                        background: colorPalette.secondary,
                                                        minWidth: '130px',
                                                        height: '20px',
                                                        opacity: '100%',
                                                        boxShadow: `0 0 0 2px ${colorPalette.secondaryBorder}`,
                                                    }, invertedInnerScreenBorderProperties)}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: dropDownMenuProperties
                                                        }
                                                    }}                                                    value={selectedScore}
                                                    onChange={handleChangeCategory}
                                                >
                                                    {scoreSelectTable}
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <Box style={{ minHeight: '3px' }}></Box>
                                    <TableBody align='center'>
                                        {filteredHighscores.map((row) => (
                                            <>
                                                <TableRow align='center'
                                                    hover
                                                    style={{
                                                        backgroundColor: row.backgroundColor,
                                                        padding: row.padding,
                                                        borderRadius: row.borderRadius,
                                                        borderWidth: row.borderWidth,
                                                        borderStyle: row.borderStyle,
                                                        borderColor: row.borderColor,
                                                        boxShadow: row.boxShadow
                                                    }}>

                                                    {/* {scoreInfo.type == 'trial' &&
                                                        <TableCell align='center'
                                                            style={{
                                                                padding: '1px',
                                                                borderBottom: "none",
                                                                width: '50%',
                                                            }}>
                                                                {row.players}
                                                        </TableCell>
                                                       } */}

                                                    {/* {scoreInfo.type != 'trial' && */}
                                                        <TableCell align='center'
                                                            style={{
                                                                padding: '1px',
                                                                borderBottom: 'none',
                                                                width: '50%',
                                                            }}>
                                                            {row.username}
                                                        </TableCell>
                                                    {/* } */}
                                                 
                                                    <TableCell align='center'
                                                        style={{
                                                            padding: '1px',
                                                            borderBottom: 'none',
                                                        }}
                                                    >
                                                        {/* {row[selectedScore]} */}
                                                        {row[scoreInfo.scoreName]}
                                                    </TableCell>

                                                </TableRow>
                                                {row.top3 &&
                                                    <Box style={{ minHeight: '1px' }}></Box>
                                                }
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Paper>
            </Grid>
        </>;
    }
}

function mapStateToProps(state) {
    return {
        highscores: state.stuff.highscores,
        highscoresReceived: state.stuff.highscoresReceived,
        selectedScore: state.stuff.selectedScore,
        selectedTimeValue: state.stuff.selectedTimeValue,
        highscoreSelectedGameMode: state.stuff.highscoreSelectedGameMode,
    }
}

export default connect(mapStateToProps)(Highscores)
