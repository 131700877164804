"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrialsData = void 0;
const fullTrialsData_1 = require("../../fullTrialsData");
exports.TrialsData = fullTrialsData_1.pdTrialsData;
// export const TrialsData: Trial[] =  [
// 		{
// 			id: 1,
// 			name: 'Charlie Challenge',
// 			zone: 4,
// 			scoreModifier: 1.1,
// 			expModifier: 1.1,
// 			maxPlayers: 2,
// 			minimumLevel: 23,
// 			score: 10000,
// 			coinsEarned: 2,
// 			difficulty: 'Medium',
// 			type: 'wave',
// 			totalWaves: 3,
// 			cost: {
// 				mana: {
// 					dark: 250,
// 				}
// 			}
// 		},
// 		{
// 			id: 2,
// 			name: 'Dual Dragons',
// 			zone: 4,
// 			scoreModifier: 1.3,
// 			expModifier: 1.3,
// 			maxPlayers: 3,
// 			minimumLevel: 25,
// 			coinsEarned: 5,
// 			difficulty: 'very hard',
// 			score: 25000,
// 			cost: {
// 				mana: {
// 					fire: 500,
// 				}
// 			},
// 			type: 'wave',
// 			totalWaves: 3,
// 		},
// 		{
// 			id: 3,
// 			name: 'Trap Maze',
// 			zone: 4,
// 			cost: {
// 				mana: {
// 					dark: 250,
// 				}
// 			},
// 			score: 10000,
// 			maxPlayers: 2,
// 			difficulty: 'Hard',
// 			coinsEarned: 5,
// 			type: 'boss',
// 		},
// 		{
// 			id: 4,
// 			name: 'Ice Mayhem',
// 			zone: 4,
// 			cost: {
// 				mana: {
// 					water: 400,
// 					// dark: 25000,
// 				}
// 			},
// 			score: 15000,
// 			difficulty: 'Hard',
// 			maxPlayers: 2,
// 			coinsEarned: 5,
// 			type: 'wave',
// 			totalWaves: 4,
// 		},
// 		{
// 			id: 5,
// 			name: 'Slime Treasure Lands',
// 			zone: 1,
// 			cost: {
// 				mana: {
// 				}
// 			},
// 			score: 1000,
// 			maxPlayers: 5,
// 			difficulty: 'Easy',
// 			coinsEarned: 5,
// 			type: 'wave',
// 			totalWaves: 3,
// 			waveCd: 0.25,
// 		},
// 		{
// 			id: 6,
// 			name: 'Slime Valley',
// 			zone: 1,
// 			cost: {
// 				mana: {
// 				}
// 			},
// 			score: 2000,
// 			maxPlayers: 5,
// 			difficulty: 'Easy',
// 			coinsEarned: 5,
// 			type: 'boss',
// 		},
// 		{
// 			id: 7,
// 			name: 'Forest Treasure Lands',
// 			zone: 2,
// 			cost: {
// 				mana: {
// 				}
// 			},
// 			score: 1000,
// 			maxPlayers: 5,
// 			difficulty: 'Easy',
// 			coinsEarned: 5,
// 			type: 'wave',
// 			totalWaves: 3,
// 			waveCd: 0.25,
// 		},
// 		{
// 			id: 8,
// 			name: 'Maze Treasure Lands',
// 			zone: 3,
// 			cost: {
// 				mana: {
// 				}
// 			},
// 			score: 1000,
// 			maxPlayers: 5,
// 			difficulty: 'Easy',
// 			coinsEarned: 5,
// 			type: 'wave',
// 			totalWaves: 3,
// 			waveCd: 0.25,
// 		},
// 		{
// 			id: 9,
// 			name: 'Lava Treasure Lands',
// 			zone: 4,
// 			cost: {
// 				mana: {
// 				}
// 			},
// 			score: 1000,
// 			maxPlayers: 5,
// 			difficulty: 'Easy',
// 			coinsEarned: 5,
// 			type: 'wave',
// 			totalWaves: 3,
// 			waveCd: 0.25,
// 		},
// 		{
// 			id: 10,
// 			name: 'Forest Forray',
// 			zone: 2,
// 			cost: {
// 				mana: {
// 				}
// 			},
// 			score: 5000,
// 			maxPlayers: 5,
// 			difficulty: 'Medium',
// 			coinsEarned: 5,
// 			type: 'boss',
// 		},
// 		{
// 			id: 11,
// 			name: 'Mini Mayhem',
// 			zone: 3,
// 			cost: {
// 				mana: {
// 				}
// 			},
// 			score: 7000,
// 			maxPlayers: 5,
// 			difficulty: 'Medium',
// 			coinsEarned: 5,
// 			type: 'wave',
// 			totalWaves: 3,
// 		},
// 	{
// 		id: 12,
// 		name: 'Dragon Valley',
// 		zone: 4,
// 		cost: {
// 			mana: {
// 			}
// 		},
// 		score: 10000,
// 		maxPlayers: 5,
// 		difficulty: 'Hard',
// 		coinsEarned: 5,
// 		type: 'boss',
//
// 	},
// 	{
// 		id: 13,
// 		name: 'Ice Valley',
// 		zone: 4,
// 		cost: {
// 			mana: {
// 			}
// 		},
// 		score: 10000,
// 		maxPlayers: 5,
// 		difficulty: 'Hard',
// 		coinsEarned: 5,
// 		type: 'boss',
// 	},
// 	{
// 		id: 14,
// 		name: 'Ice Mayhem',
// 		zone: 4,
// 		cost: {
// 			mana: {
// 				water: 400,
// 				// dark: 25000,
// 			}
// 		},
// 		score: 15000,
// 		difficulty: 'Hard',
// 		maxPlayers: 2,
// 		coinsEarned: 5,
// 		type: 'wave',
// 		totalWaves: 4,
// 	},
// 	{
// 		id: 15,
// 		name: 'Hellhound',
// 		zone: 4,
// 		cost: {
// 			mana: {
// 			}
// 		},
// 		score: 10000,
// 		maxPlayers: 5,
// 		difficulty: 'Hard',
// 		coinsEarned: 5,
// 		type: 'boss',
// 	},
// 	{
// 		id: 16,
// 		name: 'Haunted Graveyard',
// 		zone: 4,
// 		cost: {
// 			mana: {
// 			}
// 		},
// 		score: 10000,
// 		maxPlayers: 5,
// 		difficulty: 'Hard',
// 		coinsEarned: 5,
// 		type: 'wave',
// 		totalWaves: 2,
// 	},
// 	]
