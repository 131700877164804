
export const UPDATE_KEY = 'UPDATE_KEY'
export const UPDATE_HIGHSCORE_LIST = 'UPDATE_HIGHSCORE_LIST'
export const TOGGLE_SHIFT = 'TOGGLE_SHIFT'
export const UPDATE_SPELLS = 'UPDATE_SPELLS'
export const UPDATE_PICKUP_UI = 'UPDATE_PICKUP_UI'
export const UPDATE_COOLDOWN_BAR = 'UPDATE_COOLDOWN_BAR'
export const UPDATE_BR_TIMER = 'UPDATE_BR_TIMER'
export const UPDATE_LEADERBOARD = 'UPDATE_LEADERBOARD'
export const UPDATE_PLAYER_KILLS = 'UPDATE_PLAYER_KILLS'
export const TRIGGER_PLAYER_DIED = 'TRIGGER_PLAYER_DIED'
export const TRIGGER_PLAYER_REVIVED = 'TRIGGER_PLAYER_REVIVED'
export const TRIGGER_GAME_STARTED = 'TRIGGER_GAME_STARTED'

export const RESET_GLOBAL_CHAT = 'RESET_GLOBAL_CHAT'
export const UPDATE_ALERT_MESSAGE = 'UPDATE_ALERT_MESSAGE'

export const SET_SPELL_POINTER = 'SET_SPELL_POINTER'

export const UPDATE_LEVEL = 'UPDATE_LEVEL'
export const UPDATE_CIRCLE = 'UPDATE_CIRCLE'

export const UPDATE_STAT_POINTS = 'UPDATE_STAT_POINTS'
export const UPDATE_STATS = 'UPDATE_STATS'
export const SET_STATS = 'SET_STATS'
export const UPDATE_STAT_BAR_VISIBILITY = 'UPDATE_STAT_BAR_VISIBILITY'
export const UPDATE_GEAR_DATA = 'UPDATE_GEAR_DATA'

export const UPDATE_USERNAME_INPUT = 'UPDATE_USERNAME_INPUT'
export const UPDATE_SELECTED_HEAD = 'UPDATE_SELECTED_HEAD'
export const UPDATE_CODE_INPUT = 'UPDATE_CODE_INPUT'

export const UPDATE_SERVER_TABLE = 'UPDATE_SERVER_TABLE'
export const UPDATE_SERVER_SELECTION = 'UPDATE_SERVER_SELECTION'
export const UPDATE_SELECTED_SERVER = 'UPDATE_SELECTED_SERVER'

export const TRIGGER_SPELL_RELOAD = 'TRIGGER_RELOAD'
export const FINISH_TRIGGER_SPELL_RELOAD = 'FINISH_TRIGGER_RELOAD'

export const UPDATE_ZONE_COUNTDOWN = 'UPDATE_ZONE_COUNTDOWN'
export const UPDATE_MIN_ZONE = 'UPDATE_MIN_ZONE'

export const GAME_LOAD_COMPLETE = 'GAME_LOAD_COMPLETE'
export const MAP_LOAD_COMPLETE = 'MAP_LOAD_COMPLETE'

export const UPDATE_ACCOUNT_EXP = 'UPDATE_ACCOUNT_EXP'
export const UPDATE_PREV_ACCOUNT_EXP = 'UPDATE_PREV_ACCOUNT_EXP'
export const TOGGLE_MUTE = 'TOGGLE_MUTE'
export const TOGGLE_FULLSCREEN = 'TOGGLE_FULLSCREEN'

export const TOGGLE_ADBLOCK = 'TOGGLE_ADBLOCK'

export const UPDATE_HEIGHT = 'UPDATE_HEIGHT'
export const UPDATE_WIDTH = 'UPDATE_WIDTH'

export const UPDATE_JOINED_DISCORD = 'JOINED_DISCORD'

export const TOGGLE_INIT_GAME = 'TOGGLE_INIT_GAME'

export const UPDATE_LOAD_PROGRESS = 'UPDATE_LOAD_PROGRESS'
export const ADD_LOAD_PROGRESS = 'ADD_LOAD_PROGRESS'

export const TOGGLE_AUTHENTICATED = 'TOGGLE_AUTHENTICATED'


export const UPDATE_ACCOUNT_ITEMS = 'UPDATE_ACCOUNT_ITEMS'

export const UPDATE_TOKEN = 'UPDATE_TOKEN'

export const UPDATE_ACCOUNT_USERNAME = 'UPDATE_ACCOUNT_USERNAME'
export const UPDATE_ACCOUNT_USERNAME_INPUT = 'UPDATE_ACCOUNT_USERNAME_INPUT'

export const UPDATE_ACCOUNT_ID = 'UPDATE_ACCOUNT_ID'
export const UPDATE_ACCOUNT_EMAIL = 'UPDATE_ACCOUNT_EMAIL'

export const UPDATE_ACCOUNT_STATS = 'UPDATE_ACCOUNT_STATS'

export const UPDATE_ACCOUNT_KILL_STATS = 'UPDATE_ACCOUNT_KILL_STATS'

export const UPDATE_ACCOUNT_CHALLENGES = 'UPDATE_ACCOUNT_CHALLENGES'

export const UPDATE_GUEST_ACCOUNT = 'UPDATE_GUEST_ACCOUNT'

export const TOGGLE_BETA_ACTIVE = 'TOGGLE_BETA_ACTIVE'
export const TOGGLE_ALPHA_ACTIVE = 'TOGGLE_ALPHA_ACTIVE'

export const UPDATE_EMOTE_DICT = 'UPDATE_EMOTE_DICT'

export const TOGGLE_LOADOUT_MENU = 'TOGGLE_LOADOUT_MENU'
export const TOGGLE_CHALLENGE_MENU = 'TOGGLE_CHALLENGE_MENU'
export const TOGGLE_SHOP_MENU = 'TOGGLE_SHOP_MENU'

export const UPDATE_INGAME_MENU_TAB = 'UPDATE_INGAME_MENU_TAB'
export const TOGGLE_INGAME_SETTINGS_MENU = 'TOGGLE_INGAME_SETTINGS_MENU'
export const UPDATE_SHOP_TAB = 'UPDATE_SHOP_TAB'
export const TOGGLE_PAYMENT_MENU = 'TOGGLE_PAYMENT_MENU'

export const TOGGLE_SIGN_IN_MENU = 'TOGGLE_SIGN_IN_MENU'

export const UPDATE_COINS = 'UPDATE_COINS'
export const UPDATE_GEMS = 'UPDATE_GEMS'

export const UPDATE_DAILY_QUESTS = 'UPDATE_DAILY_QUESTS'
export const UPDATE_DAILY_QUESTS_TIME_LEFT = 'UPDATE_DAILY_QUESTS_TIME_LEFT'

export const TOGGLE_TOUCH_CONTROLS = 'TOGGLE_TOUCH_CONTROLS'

export const LOADED_REMAINING_FILES = 'LOADED_REMAINING_FILES'

export const UPDATE_CHAT_INPUT = 'UPDATE_CHAT_INPUT'
export const TOGGLE_CHATBOX_ACTIVE = 'TOGGLE_CHATBOX_ACTIVE'

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'

export const UPDATE_PROMO_CODE_INPUT = 'UPDATE_PROMO_INPUT'

export const UPDATE_SELECTED_SCORE = 'UPDATE_SELECTED_SCORE'

export const UPDATE_SELECTED_TIME_VALUE = 'UPDATE_SELECTED_TIME_VALUE'

export let UPDATE_SELECTED_HIGHSCORE_GAME_MODE = 'UPDATE_SELECTED_HIGHSCORE_GAME_MODE'
export const UPDATE_RANK = 'UPDATE_RANK'
export const UPDATE_RANK_SCORE = 'UPDATE_RANK_SCORE'

export const HIDE_CROSS_LINKS = 'HIDE_CROSS_LINKS'
export const UPDATE_REFERRER = 'UPDATE_REFERRER'

export const UPDATE_DISABLE_ADBLOCK_POPUP = 'UPDATE_DISABLE_ADBLOCK_POPUP'

export const UPDATE_TRIAL_VICTORY = 'UPDATE_TRIAL_VICTORY'

export const UPDATE_NEW_UPDATE_DIALOG = 'UPDATE_NEW_UPDATE_DIALOG'

export const UPDATE_GAME_PLAYED_ONCE = 'GAME_PLAYED_ONCE'

export const UPDATE_GLOBAL_CHAT = 'UPDATE_GLOBAL_CHAT'

export const SET_MIDDLE_LOWER_TEXT = 'SET_MIDDLE_LOWER_TEXT'

export const UPDATE_EMOTE_BAR_DATA = 'UPDATE_EMOTE_BAR_DATA'

export const SET_STATS_DATA = 'SET_STATS_DATA'

export const UPDATE_RESOURCE_PROPERTIES = 'UPDATE_RESOURCE_PROPERTIES'

export const SET_RESOURCES = 'SET_RESOURCES'
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE'

export const SET_CUSTOM_BASE_STAT_CAPS = 'SET_CUSTOM_BASE_STAT_CAPS'

export const TOGGLE_SHOP = 'TOGGLE_SHOP'

export const SET_SHOP_DATA = 'SET_SHOP_DATA'

export const SET_GAME = 'SET_GAME'

export const SET_SINGLE_GAME = 'SET_SINGLE_GAME'

export const SET_TITLE_LOADED = 'SET_TITLE_LOADED'

export const SDK_LOADED = 'SDK_LOADED'

export const UPDATE_GENERIC_BAR_VALUE = 'UPDATE_GENERIC_BAR_VALUE'
export const UPDATE_GENERIC_BAR_NAME = 'UPDATE_GENERIC_BAR_NAME'
