import React, { Component } from 'react'
import { Grid, Paper } from '@mui/material'
import { connect } from 'react-redux'
import * as Actions from '../actions/actions'
import Constants from '../../shared/constants'
import {bigScreenBorderProperties, colorPalette} from './uiStyles.js'
import ColorChangingButton from './ColorChangingButton.js'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import { IconButton } from '@mui/material'

class NewUpdate extends Component {
    constructor() {
        super()
        this.state = {

        }
    }

    render() {
        let { newUpdateDialog } = this.props

        return <>
            {newUpdateDialog &&
                <Grid container
                    align="center" justifyContent="center" alignItems="center"
                    xs={12} style={{
                        position: 'absolute',

                        marginBottom: '5%', width: '100%', height: '100%',
                        // left: "50%",
                        // top: "50%",
                        // transform: 'translateX(50%)',
                        // transform: `translate(-50%, -50%
                        zIndex: 100000000

                    }}>




                    <Paper
                        style={Object.assign({
                            background: colorPalette.primary,
                            padding: '0.5%',
                            borderColor: colorPalette.primaryBorder,
                        }, bigScreenBorderProperties)}
                    >
                        <Grid container xs={12}>
                            <Grid container xs={2}> </Grid>
                            <Grid container
                                align="center" justifyContent="center" alignItems="center"
                                xs={8}>
                                 <h2> New Update! </h2>
                                {/*<h2> New Game Mode! </h2>*/}
                            </Grid>
                            <Grid
                                justifyContent="flex-end"
                                container xs={2}>
                                <IconButton
                                    style={{
                                        alignSelf: 'flex-end',
                                        marginTop: -5,
                                    }}
                                    onClick={
                                        () => {
                                            Actions.updateNewUpdateDialog(false)
                                            try {
                                                // console.log('setting last v played')
                                                localStorage.setItem('lastVersionPlayed', Constants.CURRENT_VERSION)
                                            } catch (err) { }
                                        }
                                    }
                                    size="large">
                                    <CancelPresentationIcon style={{ fontSize: '3rem' }} />
                                </IconButton>

                            </Grid>




                        </Grid>

                        <Grid container xs={12}>
                            <Grid container
                                align="center" justifyContent="center" alignItems="center"
                                xs={6}>
                                <img
                                    style={{
                                        borderRadius: '4px',
                                        maxHeight: '200px'
                                    }}
                                    // src={'assets/generalObjects/trial_pillar.png'}
                                    // src={`assets/pickups/chest_currency.png`}
                                    src={`assets/pickups/chest_currency_christmass.png`}
                                    // src={`assets/gifs/dragon_boss_idle.gif`}
                                    className='pulse'
                                />
                            </Grid>


                            {/* <Grid item xs={6}>
                                <div style={{
                                    fontSize: '20px',
                                }}>
                                    <b> Trials </b> </div>
                                <ul>
                                    <li
                                        style={{ marginBottom: '2%' }}
                                    >
                                        <b>
                                            Test yourself against challenging trials
                                        </b>
                                    </li>
                                    <li
                                        style={{ marginBottom: '2%' }}
                                    >
                                        <b>
                                            Bring your friends along to help you complete them
                                        </b>
                                    </li>

                                    <li
                                        style={{ marginBottom: '2%' }}
                                    >
                                        <b>
                                            Compete for fastest times in the leaderboard
                                        </b>
                                    </li>
                                </ul>
                            </Grid> */}

<Grid item xs={6}>
<div style={{
                                    fontSize: '20px',
                                }}>
                                    {/* <b> New Game Mode! </b>  */}
                                    </div>
<ul>
    {/* <li
        style={{ marginBottom: '2%' }}
    >
        <b>
            Chests now drop twice as often!
        </b>
    </li> */}
    {/*<li*/}
    {/*    style={{ marginBottom: '2%' }}*/}
    {/*>*/}
    {/*    <b>*/}
    {/*        Holiday update!*/}
    {/*    </b>*/}
    {/*</li>*/}
    <li
        style={{marginBottom: '2%'}}
    >
        <b>
            Get gifts from enemies for more currency!
        </b>
    </li>
    <li
        style={{marginBottom: '2%'}}
    >
        <b>
            Complete challenges by getting gifts!
        </b>
    </li>
    <li
        style={{marginBottom: '2%'}}
    >
        <b>
            Buy new cosmetics from the Elf Chests
        </b>
    </li>
    {/* <li
        style={{ marginBottom: '2%' }}
    >
        <b>
            Turn in the "FREEGLYPHS" promo code for free glyphs
        </b>
    </li>
    <li
        style={{ marginBottom: '2%' }}
    >
        <b>
            Turn in the "FREERUNES" promo code for free runes
        </b>
    </li> */}
    {/* <div style={{
                                    fontSize: '20px',
                                }}>
                                    <b> New Game Mode! </b> </div>
<ul>
    <li
        style={{ marginBottom: '2%' }}
    >
        <b>
            Character's now save, level them up
        </b>
    </li>
    <li
        style={{ marginBottom: '2%' }}
    >
        <b>
            Boost your stats and gain great power
        </b>
    </li>
    <li
        style={{ marginBottom: '2%' }}
    >
        <b>
            Death is permanent, but you unlock new classes
        </b>
    </li> */}
    {/*    <b> Trial Drops! </b> </div>*/}
    {/*<ul>*/}
    {/*<li*/}
    {/*        style={{ marginBottom: '2%' }}*/}
    {/*    >*/}
    {/*        <b>*/}
    {/*            Trials now drop from enemies*/}
    {/*        </b>*/}
    {/*    </li>*/}
    {/*    <li*/}
    {/*        style={{ marginBottom: '2%' }}*/}
    {/*    >*/}
    {/*        <b>*/}
    {/*            Find trials easily with new indicators*/}
    {/*        </b>*/}
    {/*    </li>*/}
    {/*    <li*/}
    {/*        style={{ marginBottom: '2%' }}*/}
    {/*    >*/}
    {/*        <b>*/}
    {/*            Try the 7 new trials!*/}
    {/*        </b>*/}
    {/*    </li>*/}
    {/* <li
                                        style={{ marginBottom: '2%' }}
                                    >
                                        <b>
                                            More updates coming soon :)
                                        </b>
                                    </li> */}
    {/* <li
                                        style={{ marginBottom: '2%' }}
                                    >
                                        <b>
                                            Fight the new Ice Golem Boss!
                                        </b>
                                    </li>
                                    <li
                                        style={{ marginBottom: '2%' }}
                                    >
                                        <b>
                                           Spears and many new drops have been added. Loot drops now improve as you progress the Monster Journal!
                                        </b>
                                    </li>
                                    <li
                                        style={{ marginBottom: '2%' }}
                                    >
                                        <b>
                                            Find Gifts and complete new challenges for more currency, and unlock new cosmetics from the Elf Chest!
                                        </b>
                                    </li> */}
    {/* <li
                                        style={{ marginBottom: '2%' }}
                                    >
                                        <b>
                                            New Elf Cosmetics and Elf Chest
                                        </b>
                                    </li> */}
    {/* <li
                                        style={{ marginBottom: '2%' }}
                                    >
                                        <b>
                                            Get better loot drops as you progress the monster journal
                                        </b>
                                    </li>
                                    <li
                                        style={{ marginBottom: '2%' }}
                                    >
                                        <b>
                                            Promo code for the careful readers: candycane
                                        </b>
                                    </li> */}
</ul>
</Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <ColorChangingButton
                                style={{
                                    zIndex: 100000000
                                }}
                                buttonFunction={
                                    () => {

                                        Actions.updateNewUpdateDialog(false)
                                        try {
                                        } catch (err) { }
                                    }
                                }
                                config={
                                    {
                                        idle: {
                                            fillColor: '#F7A74F',
                                            outerBorderColor: '#362D44',
                                            innerBorderColor: '#CE782C',
                                        },
                                        hover: {
                                            fillColor: '#FFD16C',
                                            outerBorderColor: '#362D44',
                                            innerBorderColor: '#FFB66E',
                                        },
                                        down: {
                                            fillColor: '#F79B37',
                                            outerBorderColor: '#362D44',
                                            innerBorderColor: '#E08634',
                                        },
                                    }}
                                title='Continue'
                                buttonStyling={{
                                    marginTop: '10px',
                                    color: 'white',
                                    width: '100%',
                                    paddingTop: '1%',
                                    paddingBottom: '1%'
                                }}
                            />

                        </Grid>

                    </Paper>

                </Grid>
            }
        </>;
    }
}

function mapStateToProps(state) {
    return {
        newUpdateDialog: state.stuff.newUpdateDialog,
    }
}


export default connect(
    mapStateToProps,
)(NewUpdate)
