import React from 'react'
import ColorChangingIcon from '../ColorChangingIcon.js'
import { iconStyle } from '../uiStyles.js'
import { toggleShopMenu, updateShopTab} from '../../actions/actions'
import AddIcon from '@mui/icons-material/Add'

function IconGemsTwo() {
    return <ColorChangingIcon
        buttonFunction={() => { 
            updateShopTab(0)
            toggleShopMenu(true)
            // window.open("https://discord.gg/fkFFzP47Bv") 

            // localStorage.setItem("joinedDiscord", JSON.stringify(true));
            // updateJoinedDiscord(true)
        
        }}
        config={{
            idle: {
                fillColor: '#FF6155',
                // fillColor: '#7689D4',
                outerBorderColor: '#362D44',
                innerBorderColor: '#4E65AF',
            },
            hover: {
                fillColor: '##FF647E',
                outerBorderColor: '#362D44',
                innerBorderColor: '#5C96EA',
            },
            down: {
                fillColor: '#FF6155',
                outerBorderColor: '#362D44',
                innerBorderColor: '#445EB2',
            }
        }}
        buttonStyling={iconStyle}
        // imgUrl='assets/ui/plus.png'
        IconImage={AddIcon}
    />
}

export default (IconGemsTwo)