import Constants from '../shared/constants'
import { throttle } from 'throttle-debounce'
import { processGameUpdate } from './state'
import {
  joinGame,
  restartGame,
  receivePickup,
  updatePickupUI,
  processStats,
  setStats,
  equipmentStats,
  stats, setPlayerSpell, playerMultipliers
} from './equippedSpells'
import {loadedServers, minZone, sendToMainMenu} from './game'
import {handleTrialNotification, handlePlayerOffworld, pointerManager} from './uiScene'
import {updateDebug, serverDebugOptions} from './debug'
import * as Actions from './actions/actions'
import {createExpFloatingNumber, setPlayerStats, setPosition, setSpeed} from "./playerScripts";
import {phaserObject, playerStats, player} from "./create";
import {toggleShop, toggleShopMenu, updateInGameMenuTab, updateSelectedServer, updateShopTab} from "./actions/actions";
import { createBaseUrl, currentGame} from "./index";
import { cmds } from './sdkIntegration'
import { timeoutToMainMenu } from './components/RespawnMenu'
const baseUrl = createBaseUrl()
var notepack = require('notepack.io')

export let ws
let connectedPromise
let prevMovement
let prevAngle

export function startSocketConnection(socketURL) {
  if (window.location.host == 'localhost:3000') {
    ws = new WebSocket('ws://localhost:9001')
    // ws = new WebSocket('wss://silicon-valley-1.mageclash.io')


    // ws = new WebSocket('wss://new-york-1.mageclash.io')



    // ws = new WebSocket('wss://chicago-2.mageclash.io')

    // ws = new WebSocket('wss://frankfurt-1.mageclash.io')
    // ws = new WebSocket('wss://frankfurt-2.mageclash.io')
    // ws = new WebSocket('wss://tokyo-2.mageclash.io')

    // ws = new WebSocket('wss://tokyo-1.mageclash.io')
    // ws = new WebSocket('wss://frankfurt-4.mageclash.io')
    // ws = new WebSocket('wss://silicon-valley-2.mageclash.io')
    // ws = new WebSocket('wss://london1.mageclash.io')
    ws.binaryType = 'arraybuffer'
    ws.emit = function (action, message) {
      if (message) {
        this.send(notepack.encode([action, message]))
      } else {
        this.send(notepack.encode([action]))
      }
    }
    // ws.onclose = function (event) {
    //   console.log('socket closed', event.code)
    //   if (event.code !== 1000) {
    //   Actions.updateAlertMessage('The game has been updated. Please refresh your browser to play the latest version.')
    //   }
    // }
  }
  else {
    let trimmedURL = socketURL.replace(/(^\w+:|^)\/\//, '')
    console.log('using trimmed url', trimmedURL)
    ws = new WebSocket(`wss://${trimmedURL}`)
    ws.binaryType = 'arraybuffer'
    ws.emit = function (action, message) {
      if (message) {
        this.send(notepack.encode([action, message]))
      } else {
        this.send(notepack.encode([action]))
      }
    }
  }
  ws.onclose = function (event) {
    console.log('socket closed', event.code)
    if (event.code !== 1000) {
    timeoutToMainMenu()
    // Actions.updateAlertMessage('The game has been updated. Please refresh your browser to play the latest version.')
    }
  }
  connectedPromise = new Promise(resolve => {
    ws.onopen = () => {
      resolve()
    }
  })
}

export function fetchServerInfo() {
  console.log('calling fetchServerInfo server on', baseUrl)
  try {
    fetch(`${baseUrl}/getServer`).then(function (response) {
      return response.text()
    }).then(function (text) {
      console.log('received get server response', text)
      let serverInfo = JSON.parse(text)
      callback(serverInfo)
    })
  } catch (e) {
    console.log('error fetching server info, retrying...', e)
    fetchServerInfo()
  }
}

function callback(serverList) {
  console.log('updating serverlist on callback', serverList)
  Actions.updateServerTable(serverList)
  if (serverList.beta) {
    updateSelectedServer(serverList.beta.selectedServer.url)
  } else {
    if (serverList[currentGame.value]) {
      updateSelectedServer(serverList[currentGame.value].selectedServer.url)
    }
  }
  loadedServers.value = true
}

export const connect = onGameOver => (
  connectedPromise.then(() => {
    ws.onmessage = function (message) {

      let messageData = notepack.decode(message.data)

      switch (messageData[0]) {
        case Constants.MSG_TYPES.JOIN_GAME:
          joinGame(messageData[1])
          break
        case Constants.MSG_TYPES.RESTART_GAME:
          restartGame(messageData[1])
          break
        case Constants.MSG_TYPES.SEND_STATS:
          processStats(messageData[1], stats)
          break
        case Constants.MSG_TYPES.SET_STATS:
          setStats(messageData[1], stats)
          break
        case Constants.MSG_TYPES.SEND_PLAYER_PICKUP:
          receivePickup(messageData[1])
          break
        // case Constants.MSG_TYPES.SET_PLAYER_SPELLS:
        //   setPlayerSpells(messageData[1])
        //   break
        case Constants.MSG_TYPES.SET_PLAYER_SPELL:
          setPlayerSpell(messageData[1])
          break
        case Constants.MSG_TYPES.UPDATE_PICKUP_UI:
          updatePickupUI(messageData[1])
          break
        case Constants.MSG_TYPES.SEND_BR_POS:
          pushBRUpdate(messageData[1])
          break
        case Constants.MSG_TYPES.GAME_UPDATE:
          processGameUpdate(messageData[1])
          break
        case Constants.MSG_TYPES.GAME_OVER:
          onGameOver(messageData[1], minZone)
          break
        case Constants.MSG_TYPES.SERVER_FULL:
          fetchServerInfo()
          Actions.updateAlertMessage('Server Full! Servers refreshing - Please try again')
          setTimeout(() => {
            Actions.updateAlertMessage('')
          }, 2000)
          // alert('Server Full! Servers refreshing - Please try again')
          break
        case Constants.MSG_TYPES.ALREADY_PLAYING:
          // alert('Already playing! Switch to your other browser')
          Actions.updateAlertMessage('Already playing! Switch to your other browser')
          break
        case Constants.MSG_TYPES.NEW_LOGIN_DETECTED:
          // alert('User logged in in another tab or window! Switch to your other browser')
          Actions.updateAlertMessage('User logged in in another tab or window! Switch to your other browser')
          break
        case Constants.MSG_TYPES.INVALID_TOKEN:
          // alert('Failed to join game. Please refresh and try again')
          Actions.updateAlertMessage('Failed to join game. Please refresh and try again \n Error: Invalid token')
          console.log('invalid token')
          break
        case Constants.MSG_TYPES.NO_TOKEN:
          // alert('Failed to join game. Please refresh and try again')
          Actions.updateAlertMessage('Failed to join game. Please refresh and try again \n Error: No token')
          console.log('no token')
          break
        case Constants.MSG_TYPES.JOIN_FAILED:
          // alert('Failed to join game. Please refresh and try again')
          Actions.updateAlertMessage('Failed to join game. Please refresh and try again \n Error: Join failed')
          console.log('player creation failed')
          break
        case Constants.MSG_TYPES.INVALID_JOIN_GAME_REQUEST:
          // alert('Failed to join game. Please refresh and try again')
          Actions.updateAlertMessage('Failed to join game. Please refresh and try again \n Error: Invalid join game request')
          console.log('invalid join game request')
          break
        case Constants.MSG_TYPES.SEND_TRIAL_VICTORY:
          // handleTrialVictory(messageData[1])
          Actions.updateTrialVictory(messageData[1])
          break
        case Constants.MSG_TYPES.SEND_MESSAGE:
          console.log('global chat message', messageData[1])
          Actions.updateGlobalChat(messageData[1])
          break
        case Constants.MSG_TYPES.TRIAL_NOTIFICATION:
          handleTrialNotification(messageData[1])
          break
        case Constants.MSG_TYPES.PLAYER_OFFWORLD: 
          handlePlayerOffworld(messageData[1])
          break
        case Constants.MSG_TYPES.SYSTEM_DATA:
          updateDebug(messageData[1], serverDebugOptions, 0x1a65ac, 2, true)
          break
        case Constants.MSG_TYPES.SET_MIDDLE_LOWER_TEXT:
          Actions.setMiddleLowerText(messageData[1])
            break
        case Constants.MSG_TYPES.SET_POSITION:
          if (Constants.NETWORKING === 'CAUTH') {
            setPosition(messageData[1])
          }
          break
        case Constants.MSG_TYPES.SET_SPEED:
            if (Constants.NETWORKING === 'CAUTH') {
              setSpeed(messageData[1])
            }
            break
        case Constants.MSG_TYPES.SET_CUSTOM_BASE_STAT_CAPS:
            Actions.setCustomBaseStatCaps(messageData[1])
            break
        case Constants.MSG_TYPES.SET_SHOP_DATA:
            Actions.setShopData(messageData[1])
            break
        case Constants.MSG_TYPES.UPDATE_PLAYER_STATS:
          setPlayerStats(messageData[1])
          break
        case Constants.MSG_TYPES.FORCE_OPEN_MENU:
          toggleShop(true)
            if (messageData[1] !== undefined) {
              updateInGameMenuTab(messageData[1])
            } else {
                updateInGameMenuTab(0)
            }
          break
        case Constants.MSG_TYPES.CLOSE_MENU:
            toggleShop(false)
            break
        case Constants.MSG_TYPES.SET_SPELL_POINTER:
          console.log('setting spell pointer', messageData[1])
          Actions.setSpellPointer(messageData[1].spellSlot, messageData[1].active, messageData[1].removeOtherPointers, messageData[1].fadeScreen)
          break
        case Constants.MSG_TYPES.EXP_GAINED:
          createExpFloatingNumber(player, messageData[1], phaserObject)
          // Actions.updateExpGained(messageData[1])
          break
        case Constants.MSG_TYPES.SET_GAMEPLAY_ACTIVE:
          let gameplayActive = messageData[1]
          console.log('setting gameplay active', gameplayActive)
          if (gameplayActive) {
            cmds.gameplayStart()
          } else {
            cmds.gameplayStop()
          }
          break
        case Constants.MSG_TYPES.UPDATE_GENERIC_BAR_NAME:
          console.log('updating generic bar name', messageData[1])
          Actions.updateGenericBarName(messageData[1].id, messageData[1].name, messageData[1].settings)
          break
        case Constants.MSG_TYPES.UPDATE_GENERIC_BAR_VALUE:
          console.log('updating generic bar value', messageData[1].id, messageData[1].value)
          Actions.updateGenericBarValue(messageData[1].id, messageData[1].value)
          break
        case Constants.MSG_TYPES.SEND_TO_MAIN_MENU:
          console.log('player is sent to menu by server')
          timeoutToMainMenu(messageData[1].cause)
          break
        case Constants.MSG_TYPES.CREATE_POINTER:
          console.log('creating pointer', messageData[1])
          const pointerName = messageData[1].name
          const pointerSettings = messageData[1].settings
          pointerSettings.name = pointerName
          pointerManager.createPointer('eventMarker', pointerSettings, true)
          break
        case Constants.MSG_TYPES.DELETE_POINTER:
          console.log('deleting pointer', messageData[1])
          pointerManager.removeNamedPointer(messageData[1].name)
          break
    }
    }
  })
)

export const play = input => {
  // console.log('SENDING PLAY', input)
  ws.emit(Constants.MSG_TYPES.JOIN_GAME_REQUEST, input)
}

export const updateDirection = throttle(20, input => {
  if (input || input === 0) {
    if (input != prevMovement) {
      ws.emit(Constants.MSG_TYPES.MOVEMENT, input)
      prevMovement = input
    }
  }
  else {
    if (input !== prevMovement) {
      ws.emit(Constants.MSG_TYPES.MOVEMENT, 'stop')
      prevMovement = input
    }
  }

})
export const updateCPosition = input => {
  ws.emit(Constants.MSG_TYPES.CMOVEMENT, input)
}

export const takeDamage = throttle(20, input => {
    ws.emit(Constants.MSG_TYPES.TAKE_DAMAGE, input)
})

export const giveDamage = throttle(20, input => {
    ws.emit(Constants.MSG_TYPES.GIVE_DAMAGE, input)
})


export const updateAngle = input => {
  if (input != prevAngle) {
    prevAngle = input
    ws.emit(Constants.MSG_TYPES.ANGLE, input)
  }
}



export const shootBullet = throttle(20, input => {
  ws.emit(Constants.MSG_TYPES.SHOOT, input)
})

export const pickupObject = throttle(20, input => {
  ws.emit(Constants.MSG_TYPES.REQUEST_PICKUP, input)
})

export const sheatheWeapon = throttle(20, input => {
  ws.emit(Constants.MSG_TYPES.SHEATHE_WEAPON, input)
})

export const upgradeStats = throttle(20, input => {
  ws.emit(Constants.MSG_TYPES.UPGRADE_STATS, input)
})

export const restartGameRequest = throttle(20, input => {
  ws.emit(Constants.MSG_TYPES.RESTART_GAME_REQUEST)
})

export const sendEmote = throttle(20, input => {
  ws.emit(Constants.MSG_TYPES.EMOTE, input)
})

export const sendChat = throttle(20, input => {
  ws.emit(Constants.MSG_TYPES.CHAT, input)
})

export const claimTrial = throttle(20, input => {
  ws.emit(Constants.MSG_TYPES.CLAIM_TRIAL, input)
})

export const selectShopItem = throttle(1000, input => {
  console.log('selecting shop item', input)
  ws.emit(Constants.MSG_TYPES.SELECT_SHOP_ITEM, input)
})

export const clickGenericBar = input => {
  ws.emit(Constants.MSG_TYPES.CLICK_GENERIC_BAR, input)
}