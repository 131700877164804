"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.keyBinds = exports.updateEmoteBarCenter = void 0;
const Actions = __importStar(require("./actions/actions"));
const create_1 = require("./create");
const equippedSpells_1 = require("./equippedSpells");
const game_1 = require("./game");
const create_2 = require("./create");
const actions_1 = require("./actions/actions");
const input_1 = require("./input");
const _1 = require(".");
document.addEventListener('contextmenu', function (event) {
    // Prevent the default context menu from opening
    event.preventDefault();
});
document.addEventListener('mouseup', (e) => {
    if (e.button == 2) {
        exports.keyBinds.rightClickUp(e);
    }
});
let leftMouseTimer = null;
document.addEventListener('mousedown', (e) => {
    if (e.button == 0) {
        if (!game_1.IS_TOUCH) {
            clearInterval(leftMouseTimer);
            leftMouseTimer = setInterval(function () {
                exports.keyBinds.leftClickDown();
            }, 50);
        }
    }
    if (e.button == 2) {
        exports.keyBinds.rightClickDown(e);
    }
});
document.addEventListener('mouseup', (e) => {
    if (e.button == 0) {
        clearInterval(leftMouseTimer);
    }
});
document.addEventListener("keydown", (event) => {
    if (event.repeat) {
        return;
    }
    let key = event.key.toLowerCase();
    if (!game_1.chatboxActive.value) {
        if (exports.keyBinds['keyDown'][key]) {
            exports.keyBinds['keyDown'][key](event);
        }
    }
});
document.addEventListener("keyup", (event) => {
    let key = event.key.toLowerCase();
    if (!game_1.chatboxActive.value) {
        if (exports.keyBinds['keyUp'][key]) {
            let key = event.key.toLowerCase();
            exports.keyBinds['keyUp'][key](event);
        }
    }
});
function updateEmoteBarCenter(showMenu) {
    const x = window.innerWidth / 2;
    const y = window.innerHeight / 2;
    Actions.updateEmoteBarData({ 'showMenu': showMenu, xPos: x, yPos: y });
}
exports.updateEmoteBarCenter = updateEmoteBarCenter;
// const spellType = 'attackOnDown'
// if (Constants.GAME === (Constants.GAME == 'mageclash' || Constants.GAME == 'monstermaster') {
//     spellType = 'selectOnDown'
// }
exports.keyBinds = {
    'rightClickUp': (e) => {
        if (create_2.settings.swapShift) {
            Actions.updateEmoteBarData({ 'showMenu': false, xPos: e.pageX, yPos: e.pageY });
        }
    },
    'rightClickDown': (e) => {
        if (create_2.settings.swapShift) {
            Actions.updateEmoteBarData({ 'showMenu': true, xPos: e.pageX, yPos: e.pageY });
        }
        else {
            (0, game_1.updateSpellSelection)('Shift');
        }
    },
    'leftClickDown': () => {
        (0, game_1.fire)(create_1.phaserObject, create_1.player, game_1.currentlyPressed, equippedSpells_1.equippedSpells);
    },
    'keyDown': {
        'Shift': () => {
            (0, game_1.updateSpellSelection)('Shift');
            // fire(this, player, 'Shift', equippedSpells)
        },
        1: () => {
            (0, game_1.updateSpellSelection)('1');
            (0, game_1.fire)(create_1.phaserObject, create_1.player, '1', equippedSpells_1.equippedSpells);
        },
        2: () => {
            (0, game_1.updateSpellSelection)('2');
            (0, game_1.fire)(create_1.phaserObject, create_1.player, '2', equippedSpells_1.equippedSpells);
        },
        3: () => {
            (0, game_1.updateSpellSelection)('3');
            (0, game_1.fire)(create_1.phaserObject, create_1.player, '3', equippedSpells_1.equippedSpells);
        },
        4: () => {
            (0, game_1.updateSpellSelection)('4');
            (0, game_1.fire)(create_1.phaserObject, create_1.player, '4', equippedSpells_1.equippedSpells);
        },
        5: () => {
            (0, game_1.updateSpellSelection)('5');
            (0, game_1.fire)(create_1.phaserObject, create_1.player, '5', equippedSpells_1.equippedSpells);
        },
        'enter': () => {
            console.log('enter detected');
            if (equippedSpells_1.loaded) {
                // toggleChatbox()
            }
        },
        'escape': () => {
            console.log('escape detected');
            console.log('chatboxactive', {
                chatboxActvie: _1.store.getState().stuff.chatboxActive
            });
            if (equippedSpells_1.loaded) {
                (0, actions_1.toggleIngameSettingsMenu)(true);
            }
        },
        'o': () => {
            console.log('o detected');
            if (equippedSpells_1.loaded) {
                (0, actions_1.toggleIngameSettingsMenu)();
            }
        },
        'g': () => {
            if (equippedSpells_1.loaded) {
                (0, actions_1.toggleShop)();
            }
        },
        'q': () => {
            if (!game_1.chatboxActive.value) {
                updateEmoteBarCenter(true);
            }
        },
        'f': () => {
            if (equippedSpells_1.loaded) {
                (0, input_1.handlePickupObject)(game_1.currentlyPressed, equippedSpells_1.assignedDict, 1);
            }
        },
        'e': () => {
            if (equippedSpells_1.loaded) {
                (0, input_1.handlePickupObject)(game_1.currentlyPressed, equippedSpells_1.assignedDict, 2);
            }
        }
    },
    'keyUp': {
        'Shift': () => {
            //     updateEmoteBarCenter(false)
            (0, game_1.updateSpellSelection)('Shift');
        },
        'q': () => {
            updateEmoteBarCenter(false);
        }
    }
};
