import React, { Component } from 'react'
import {baseUrl, domainName, Pickups} from '../index'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'
import { Card } from '@mui/material'
import { ButtonBase } from '@mui/material'
import { handleRestartGame } from '../input'
import { cmds } from '../sdkIntegration'
import { triggerPlayerDied, mapLoadComplete, triggerGameStarted, updatePrevAccountEXP, updateHighscores, updateStats, updateLevel, updateStatPoints, updateMinZone, updatePressedKey, setMiddleLowerText, updateGenericBarName, updateGenericBarValue, updateAlertMessage} from '../actions/actions'
import MovingAccountExpBar from './MovingAccountExpBar.js'
import { setLoadedFalse, updatePickupUI } from '../equippedSpells.js'
import {minZone, sendToMainMenu, level, EXPRatio, destroyEntities, flushEntityCache, setLastPressed, setCurrentlyPressed, eventCoordsList} from '../game.js'
import {updateUserData} from '../auth'
// import {updateUserData} from '../index'

import { player, resetGameObjects, resetPortalCameraPosition } from '../create.js'

import { fetchServerInfo, ws } from '../networking.js'
import { initState } from '../state.js'
import Constants from '../../shared/constants'
import {bigScreenBorderProperties, colorPalette} from './uiStyles.js'
import ColorChangingButton from './ColorChangingButton.js'
import DailyQuests from './DailyQuests.js'
import BannerAd from './BannerAd.js'
import {StyledText} from './TextStyling'
import ColorTable from "../../shared/colorTable";
import { toggleWeapon } from '../playerScripts'
import BottomBannerAd from './ads/BottomBannerAd.js'
import { pointerManager } from '../uiScene'

function handleRespawn() {
    handleRestartGame()
    destroyEntities()
    triggerPlayerDied()
}
export function respawnMenuToMainMenu(currentGame, token, guestAccount){
    handleMainMenu(token, guestAccount)
    mapLoadComplete(false)
    resetPortalCameraPosition(currentGame)
    setTimeout(() => {
    resetGameObjects('mainportal', true)
    }, 200)
}
export function timeoutToMainMenu(cause = 'The game has been updated. Please refresh your browser to play the latest version.') {
    console.log('player is sent to menu by server')
    sendToMainMenu()
    updateAlertMessage(`${cause}`)
    setTimeout(() => {
      updateAlertMessage('')
    }, 5000)
}

export function handleMainMenu(token, guestAccount) {
    fetchServerInfo()
    pointerManager.removeAllCustomNamedPointers()
    fetch(`${baseUrl}/getHighscores`).then(function (response) {
        return response.text()
    }).then(
        function (text) {
            updateHighscores(JSON.parse(text))
        }
    )

    updateGenericBarName(1, '')
    updateGenericBarName(2, '')
    updateGenericBarName(3, '')
    updateGenericBarName(4, '')
    updateGenericBarValue(1, 0)
    updateGenericBarValue(2, 0)
    updateGenericBarValue(3, 0)
    updateGenericBarValue(4, 0)
    setLoadedFalse()
    triggerGameStarted(false)
    console.log('calling ws close client!')
    ws.close(1000)
    initState()

    player.visible = false

    for (let i = 0; i < eventCoordsList.length; i++) {
        eventCoordsList[i] = null
    }

    updatePressedKey('1')
    setLastPressed('1')
    setCurrentlyPressed('1')
    player.weaponDrawn = true
    if (player.playerWeapon) {
        toggleWeapon(player, this)
    }
    updatePickupUI()
    setMiddleLowerText('')

    minZone.value = 1
    level.value = 1
    EXPRatio.value = 0

    updateMinZone(1)

    destroyEntities()
    // flushEntityCache()
    triggerPlayerDied(false, {
        level: 1,
        manaToKeep: {
            air: 0, dark: 0, earth: 0, fire: 0, light: 0, water: 0
        },
        spellsToKeep: [],
        gearToKeep: [],
    })
    updateLevel(1, 0)
    // updateStatPoints(0)
}

function updateAccountData(token, guestAccount) {
    let url
    if (window.location.host == 'localhost:3000') {
        url = 'http://localhost:3000/account/queryAccountData'
    }
    else {
        url = `https://${domainName}/account/queryAccountData`
    }
    fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true
        },
        body: JSON.stringify({ token: token, guest: guestAccount })
    })
        .then(response => {
            if (response.status === 200) {
                return response.json().then(user => {
                    // console.log("RECEIVED ACCOUNT DATA", user)
                    updateUserData(user)
                })
            }
        })
}

class RespawnMenu extends Component {
    constructor() {
        super()
        this.state = {
            buttonPath: 'btn_play_again_idle.png',
            rarityInfo: ColorTable.rarityInfo,
            manaColorTable: ColorTable.elementHex,
            showPlayAgain: false,
            idleTimeout: undefined
        }
    }


    componentDidMount() {
        let { token, guestAccount, adblock } = this.props
        setTimeout(() => { updateAccountData(token, guestAccount) }, 500)
        setTimeout(() => { this.setState({ showPlayAgain: true }) }, 3000)
        this.idleTimeout = setTimeout(() => {
            console.log('IDLE TIMEOUT')
            timeoutToMainMenu()
        }, (Constants.INACTIVE_COUNTER*1000)+Constants.INACTIVE_DC_PERIOD)
    }
    componentWillUnmount() {
        if (this.idleTimeout) {
            clearTimeout(this.idleTimeout)
        }
    }

    componentDidUpdate(prevProps) {
        const { playerDied } = this.props
        if (prevProps.playerDied != playerDied) {
            console.log('PLAYER DIED DETECTED')
        }
    }

    render() {
        let { thingsKept, token, accountLevel, accountExp, accountExpRatio, adblock, width, height, guestAccount, rankId, rankingScore, currentGame, renderBottomAdBanner } = this.props
        let { buttonPath, rarityInfo, manaColorTable, showPlayAgain } = this.state

        console.log('things kept', thingsKept)
        let trialVictory
        let trialData
        if (thingsKept.trialVictory) {
            trialVictory = true
            let trialId = parseInt(thingsKept.trialVictory)
            console.log('trial Id', trialId)
            trialData = Pickups.TrialsData.find(trial => trial.id == trialId)
            console.log('trial data', trialData)
        }

       let spellsToKeep = thingsKept.spellsToKeep.filter(spell => spell.spellID != 300 && spell.spellID != 12 && spell.spellID != 0)
        const spells = []
        spellsToKeep.forEach(spell => {
            if (spell
            ) {
                let rarityDetails = rarityInfo[spell.rarity]

                let cardToAdd =
                    <Grid style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
                        <Card
                            style={{
                                userSelect: 'none', backgroundColor: 'grey', textAlign: 'center', fontWeight: 'bold',
                                position: 'relative', height: '100%', maxHeight: '100%', width: '100%', pointerEvents: 'auto', userSelect: 'none',
                                textAlign: 'center', fontWeight: 'bold',
                                borderRadius: '8px',
                                borderWidth: '2px', borderStyle: 'solid',
                                borderColor: rarityInfo.innerBorder,
                                boxShadow: '0 0 0 2px #362D44',
                                minHeight: '90px', minWidth: '90px',
                                maxWidth: '90px', maxHeight: '90px',
                            }}>

                            <img src={`assets/icons/spell-icons-with-color/${spell.imgPath}`}
                                style={{
                                    backgroundColor: rarityDetails.rarityColor, textAlign: 'right',

                                    minHeight: '90px', minWidth: '90px',
                                    maxWidth: '90px', maxHeight: '90px',
                                    objectFit: 'scale-down',
                                }}>
                            </img>
                        </Card>
                    </Grid>
                spells.push(cardToAdd)
            }
        })
        thingsKept.gearToKeep.forEach(gear => {
            let rarityDetails = rarityInfo[gear.data.rarity]

            let cardToAdd =
                <Grid style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
                    <Card
                        style={{
                            userSelect: 'none', backgroundColor: 'grey', textAlign: 'center', fontWeight: 'bold',
                            borderRadius: '8px',
                            borderWidth: '2px', borderStyle: 'solid',
                            borderColor: rarityInfo.innerBorder,
                            boxShadow: '0 0 0 2px #362D44',
                            minHeight: '90px', minWidth: '90px',
                            maxWidth: '90px', maxHeight: '90px',
                        }}>
                        <img src={`assets/icons/gear-icons/${gear.data.imgPath}`}
                            style={{
                                backgroundColor: rarityDetails.rarityColor, textAlign: 'right',

                                minHeight: '90px', minWidth: '90px',
                                maxWidth: '90px', maxHeight: '90px',
                                objectFit: 'scale-down',
                            }}>
                        </img>
                    </Card>
                </Grid>

            spells.push(cardToAdd)

        })
        return (
            <div>
                <Grid container spacing={0} justifyContent="center" style={{ width: '100', margin: 'auto' }}>

                    <Grid container align='flex-end' justifyContent="center" alignItems="flex-end" direction="column"
                        xs={4}
                        style={{
                            minHeight: '100%',
                            paddingRight: '20px'
                        }}>
                        <BannerAd size='small' adId='mageclash-io_300x250_1' width={300} height={250}  dimensions='300x250' adIdCrazyGames='respawn1' bannerWidth='300px' bannerHeight='250px' />
                        {width >= 1280 &&
                            <></>
                        }
                    </Grid>

                    <Grid item
                        xs={4}
                    >
                        <Paper style={Object.assign({
                            userSelect: 'none', textAlign: 'center',
                            background: colorPalette.primary,
                            marginLeft: '1%', marginRight: '1%',
                            paddingLeft: '10%', paddingRight: '10%',
                                borderColor: colorPalette.primaryBorder,
                        },
                            bigScreenBorderProperties
                        )}>
                            <Grid container>

                                <Grid xs={12}>
                                    {!trialVictory &&
                                        <b style={{ color: '#FF3B53', fontSize: '6vh' }}>YOU DIED!</b>
                                    }
                                    {trialVictory &&
                                        <b style={{ color: '#FF3B53', fontSize: '6vh' }}>TRIAL COMPLETE!</b>
                                    }

                                </Grid>



                                <Grid xs={12}>
                                    <MovingAccountExpBar />
                                </Grid>

                                {/* <Grid container xs={12}>
                                    <Paper style={Object.assign({
                                        // height: '100%', 
                                        width: '100%',
                                        background: '#E7CAB2',

                                        borderRadius: '8px',
                                        borderWidth: '2px', borderStyle: 'solid',
                                        borderColor: '£362D44',
                                        boxShadow: '0 0 0 2px #FFFFFF',
                                        marginBottom: '2.5%',
                                        padding: '2px'
                                    },
                                        //  bigScreenBorderProperties
                                    )} >
                                        <Grid container xs={12}>
                                            <Grid container
                                                direction="row" align='center' justify='center' alignItems="center"
                                                xs={6}
                                            >
                                                <RankDialog />
                                            
                                            </Grid>
                                            <Grid container xs={6}>
                                                <Grid xs={12}>
                                                    Points to next rank:
                                                </Grid>
                                                <Grid xs={12}>
                                                    {(rankId == RankingData.length - 1) ?
                                                        <div>MAX RANK</div> : null}

                                                    {(rankId < RankingData.length - 1) ?
                                                        <div> {RankingData[rankId + 1].score - rankingScore} </div> : null}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid> */}

                                <Grid xs={12} >
                                    <Paper style={{
                                        background: '#E7CAB2',

                                        borderRadius: '8px',
                                        borderWidth: '2px', borderStyle: 'solid',
                                        borderColor: '£362D44',
                                        boxShadow: '0 0 0 2px #FFFFFF',
                                        marginBottom: '2.5%'

                                    }}>
                                        {!trialVictory &&
                                            <>

                                            {
                                                thingsKept.customDeathMessage ?
                                                    <StyledText
                                                    data={thingsKept.customDeathMessage}
                                                />
                                                :
                                                <>
                                                <Grid xs={12}>

                                                    <h3 style={{ textAlign: 'center' }}> Play again and return with </h3>

                                                </Grid>

                                                <Grid xs={12}>
                                                    <h3 style={{ textAlign: 'center' }}> Level {thingsKept.level} </h3>
                                                </Grid>
                                                <Grid xs={12}>
                                                    <h3 style={{ textAlign: 'center' }}> Score {thingsKept.score} </h3>
                                                </Grid>
                                                </>
                                            }
                                            </>
                                        }
                                        {trialVictory &&
                                            <Grid xs={12}>
                                                <h3 style={{ textAlign: 'center' }}> Congratulations on completing {trialData.name}! </h3>
                                                <h3 style={{ textAlign: 'center' }}> Score Bonus: {Math.round((trialData.scoreModifier - 1) * 100)}% </h3>
                                                <h3 style={{ textAlign: 'center' }}> EXP Bonus: {Math.round((trialData.expModifier - 1) * 100)}% </h3>

                                                <h3 style={{ textAlign: 'center' }}> Total score: {thingsKept.score} </h3>
                                            </Grid>
                                        }
                                    </Paper>
                                </Grid>

                                <Grid container justifyContent='center' alignItems='center' xs={12}>
                                    {spells}
                                </Grid>


                            </Grid>

                            <Grid item align='center' justifyContent="center" alignItems="center" direction="column"
                                xs={12}
                                // lg={3}
                                // xs={3}
                                style={{
                                    minHeight: '100%',
                                    //  padding: '1%'
                                }}
                            >

                                <Grid style={{
                                    // paddingBottom: '20px', paddingTop: '20px' 
                                }} xs={12}>

                                    {showPlayAgain &&
                                        <ButtonBase style={{
                                            margin: '3%',
                                        }}
                                            onMouseOver={() => {
                                                this.setState({ buttonPath: 'btn_play_again_hover.png' })
                                            }}
                                            onMouseOut={() => {
                                                this.setState({ buttonPath: 'btn_play_again_idle.png' })
                                            }
                                            }
                                            onMouseDown={() => {
                                                this.setState({ buttonPath: 'btn_play_again_down.png' })
                                            }
                                            }
                                            onClick={(e) => {
                                                e.nativeEvent.stopImmediatePropagation()
                                                e.preventDefault()
                                                e.stopPropagation()
                                                console.log('clicked restart game')
                                            
                                                let actions = () => {
                                                    // cmds.gameplayStart()
                                                    updatePrevAccountEXP(accountLevel, accountExp, accountExpRatio)
                                                    handleRespawn()
                                                
                                                }
                                                cmds.playPreroll(actions)



                                                // updatePrevAccountEXP(accountLevel, accountExp, accountExpRatio)
                                                // handleRespawn()
                                            }}
                                        >
                                            <img
                                                style={{
                                                    width: '90%',
                                                    pointerEvents: 'none',
                                                    pointerEvents: 'none',
                                                }}
                                                src={`assets/ui/${buttonPath}`} />
                                        </ButtonBase>
                                    }
                                    {!showPlayAgain &&
                                        <ButtonBase style={{
                                            margin: '3%',
                                        }}

                                        >
                                            <img
                                                style={{
                                                    width: '90%',
                                                    pointerEvents: 'none',
                                                    pointerEvents: 'none',
                                                }}
                                                src={'assets/ui/btn_loading.png'} />
                                        </ButtonBase>

                                    }
                                </Grid>

                                <Grid style={{
                                    paddingBottom: '20px',
                                    // paddingTop: '20px' 
                                }} xs={12}>

                                    <ColorChangingButton buttonFunction={() => {
                                        console.log('clicked main menu')
                                        respawnMenuToMainMenu(currentGame, token, guestAccount)
                                    }}
                                        config={
                                            {
                                                idle: {
                                                    fillColor: '#E7CAB2',
                                                    outerBorderColor: '#362D44',
                                                    innerBorderColor: '#FFF1D2',
                                                },
                                                hover: {
                                                    fillColor: '#FFC897',
                                                    outerBorderColor: '#362D44',
                                                    innerBorderColor: '#FFF1D2',
                                                },
                                                down: {
                                                    fillColor: '#C19272',
                                                    outerBorderColor: '#362D44',
                                                    innerBorderColor: '#9B7871',
                                                },
                                            }}
                                        title='Main Menu'
                                        countdown={3}
                                        buttonStyling={{
                                            color: '#b06c66',
                                            width: '35%',
                                            fontSize: '2vh',
                                        }}
                                        textColor='#b06c66'
                                    />
                                </Grid>

                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid
                        container
                        justifyContent="center"
                        direction="column"
                        xs={4}
                    >
                        <DailyQuests />
                    </Grid>
                </Grid >
                <Grid item align='center' justifyContent="center" alignItems="center" direction="column" xs={12} style={{
                    //  padding: '2%',
                    paddingTop: '0.35%',
                    minHeight: '100%'
                }}>
                    <BottomBannerAd adName='gamesPage' width={width} fake={!renderBottomAdBanner}/>
                    {/* <BannerAd size='large' adId='mageclash-io_970x250' width={728} height={90} dimensions='970x250' bannerWidth='728px' bannerHeight='90px' adIdCrazyGames='respawn2' /> */}
                    {width >= 1280 &&

                        <></>
                    }
                </Grid>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        playerDied: state.stuff.playerDied,
        thingsKept: state.stuff.thingsKept,
        accountLevel: state.stuff.accountLevel,
        accountExp: state.stuff.accountExp,
        accountExpRatio: state.stuff.accountExpRatio,
        adblock: state.stuff.adblock,
        width: state.stuff.width,
        height: state.stuff.height,
        token: state.stuff.token,
        guestAccount: state.stuff.guestAccount,
        rankId: state.stuff.rankId,
        rankingScore: state.stuff.rankingScore,
        currentGame: state.stuff.currentGame,
    }
}

export default connect(
    mapStateToProps,
)(RespawnMenu)