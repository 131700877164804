// The "current" state will always be RENDER_DELAY ms behind server time.
// This makes gameplay smoother and lag less noticeable.
import { mainDict, meDict, othersDict, enemiesDict, bulletsDict, pickupsDict } from './stateLists.js'
import { player } from './create'
import Constants from '../shared/constants'
let currX = 0
let currY = 0
export let currHP = { value: 100 }
export let currMana = { value: 100 }
// mana above is scaled, mana below is not
export let currManaVal = { value: 100 }
export let currMaxMana = { value: 100 }

export let gameRestarted = {value: false}

let entityList = [
  {
    updateName: 'others',
    currentList: [],
    entityDict: othersDict,
    updates: new Map([
      ['x', 'delta'],
      ['y', 'delta'],
      ['angle', 'delta'],
      ['hp', 'replaceIfNewValue'],
      ['mana', 'replaceIfNewValue'],
      ['hpChangeAmount', 'makeNullIfNotPresent'],
      ['emote', 'makeNullIfNotPresent'],
      ['chatMessage', 'makeNullIfNotPresent']
    ]),
  },
  {
    updateName: 'enemies',
    currentList: [],
    entityDict: enemiesDict,
    updates: new Map([
      ['x', 'replaceIfNewValueNotZero'],
      ['y', 'replaceIfNewValueNotZero'],
      ['angle', 'delta'],
      ['hp', 'replaceIfNewValue'],
      ['mana', 'replaceIfNewValue'],
      ['hpChangeAmount', 'makeNullIfNotPresent'],
    ]),
  },
  {
    updateName: 'bullets',
    currentList: [],
    entityDict: bulletsDict,
    updates: new Map([
      ['x', 'delta'],
      ['y', 'delta'],
      ['direction', 'delta'],
      ['radius', 'delta'],
    ]),
  },
  {
    updateName: 'pickups',
    currentList: [],
    entityDict: pickupsDict,
    updates: new Map([
      ['x', 'delta'],
      ['y', 'delta'],
    ]),
  },
]

for (let i = 0; i < entityList.length; i++) {
  entityList[i].mapValues = []
  entityList[i].updates.forEach((key) => {
    entityList[i].mapValues.push(key)
  })
}


let serverTimeCounter = 0

// const RENDER_DELAY = 125
// const RENDER_DELAY = 200

let RENDER_DELAY = 75
if (Constants.NETWORKING === 'CAUTH') {
    // RENDER_DELAY = 50
  RENDER_DELAY = 150
}

// const RENDER_DELAY = 0

const gameUpdates = []
let gameStart = 0
let firstServerTimestamp = 0

export function resetServerTimestamp() {
  firstServerTimestamp = 0
}
export function initState() {
  gameStart = 0
  firstServerTimestamp = 0

  entityList.currentList = []
  entityList.currentEnemies = []
  entityList.currentBullets = []
  entityList.currentPickups = []

  serverTimeCounter = 0
  currX = 0
  currY = 0
  currHP = { value: 100 }
}

function parseValue(value, type) {
  if (value == '' && type != 'bool') {
    if (type == 'list') { return [] }
    return undefined
  }
  switch (type) {
    case 'int':
      if (value == '0' || value == 0) { return 0 }
      return parseInt(value)
    case 'float':
      return parseFloat(value)
    case 'object':
      if (value == 'null') { return undefined }
      return JSON.parse(JSON.stringify(value))
    case 'bool':
      return value
    case 'list':
      return value
    case 'dict':
      return value
    case 'string':
      return value
  }
}

export function processGameUpdate(update) {

  // need both
  serverTimeCounter += update[0]
  update[0] = serverTimeCounter

  let mainParsed = {}

  for (let i = 0; i < update.length; i++) {
    mainParsed[mainDict[i][0]] = parseValue(update[i], mainDict[i][1])
  }
  update = JSON.parse(JSON.stringify(mainParsed))

  if (!update.me) {
    update.me = []
  }
  if (!update.enemies) {
    update.enemies = []
  }
  if (!update.others) {
    update.others = []
  }
  if (!update.pickups) {
    update.pickups = []
  }
  if (!update.bullets) {
    update.bullets = []
  }

  if (update.leaderboard) {
    let leaderboardParsed = []
    update.leaderboard.forEach(player =>
      leaderboardParsed.push(
        { username: player[0], score: player[1] }
      )
    )
    update.leaderboard = leaderboardParsed
  }

  // if (update.eventCoords) {
  //   console.log('EVENT COORDS RECEIVED', update.eventCoords)
  // }

  let meParsed = {}
  let meList = update.me
  for (let i = 0; i < meList.length; i++) {
    meParsed[meDict[i][0]] = parseValue(meList[i], meDict[i][1])
  }
  update.me = meParsed
  if (update.me.x) {
    currX += update.me.x
    update.me.x = currX
  }
  if (!update.me.x) {
    update.me.x = currX
  }
  if (update.me.y) {
    currY += update.me.y
    update.me.y = currY
  }
  if (!update.me.y) { update.me.y = currY }
  if (update.me.hp) {
    currHP.value = update.me.hp
  }
  if (!update.me.hp) { update.me.hp = currHP.value }
  if (update.me.mana) {
    currMana.value = update.me.mana
  }
  if (!update.me.mana) { update.me.mana = currMana.value }
  if (update.me.manaVal) {
    currManaVal.value = update.me.manaVal
  }
  if (!update.me.manaVal) { update.me.manaVal = currManaVal.value }
  if (update.me.maxMana) {
    currMaxMana.value = update.me.maxMana
  }
  if (!update.me.maxMana) { update.me.maxMana = currMaxMana.value }

  entityList.forEach(entityType => {
    let parsedEntityList = []
    let entityMetaList = JSON.parse(JSON.stringify(update[entityType.updateName]))

    // List of ids in entity update pushed while iterating
    const currentEntityIds = []
    // Convert optimised array of values into a key-value dictionary.
    entityMetaList.forEach(entityData => {
      let entityParsed = {}

      for (let i = 0; i < entityData.length; i++) {
        entityParsed[entityType.entityDict[i][0]] = parseValue(entityData[i], entityType.entityDict[i][1])
      }
      currentEntityIds.push(entityParsed.id)
      parsedEntityList.push(entityParsed)
    })
    update[entityType.updateName] = JSON.parse(JSON.stringify(parsedEntityList))

    // Destroy any ids in current entity list that are no longer present
    entityType.currentList = entityType.currentList.filter(entity => currentEntityIds.includes(entity.id))

    if (gameRestarted.value == true) {
      // destroy duplicate ids in currentList
      entityType.currentList = entityType.currentList.filter((thing, index, self) =>
              index === self.findIndex((t) => (
                  t.id === thing.id
              ))
      )
    }


    // Iterate over new values and update changes, taking into account whether to replace the value or add a delta
    const entityIdList = entityType.currentList.map((obj) => obj.id)
    for (let i = 0; i < update[entityType.updateName].length; i++) {
      //  if enemy has already been processed
      if (entityIdList.includes(update[entityType.updateName][i].id)) {
        let index = entityIdList.indexOf(update[entityType.updateName][i].id)
        entityType.updates.forEach((value, key) => {
          if (value == 'delta') {
            if (update[entityType.updateName][i][key]) { update[entityType.updateName][i][key] += entityType.currentList[index][key] } else { update[entityType.updateName][i][key] = entityType.currentList[index][key] }
          } else if (value == 'replaceIfNewValue') {
            if (update[entityType.updateName][i][key]) { entityType.currentList[index][key] = update[entityType.updateName][i][key] } else { update[entityType.updateName][i][key] = entityType.currentList[index][key] }
          } else if (value == 'replaceIfNewValueNotZero') {
            if (!update[entityType.updateName][i][key] || update[entityType.updateName][i][key] == 0) { update[entityType.updateName][i][key] = entityType.currentList[index][key] }
          }
          else if (value == 'makeNullIfNotPresent') {
            if (!update[entityType.updateName][i][key]) { entityType.currentList[index][key] = null }
          }
        })
        for (let [key, value] of Object.entries(update[entityType.updateName][i])) {
          if (value != NaN && value != null) {
            entityType.currentList[index][key] = value
          }
        }
      }
      // if entity is new
      else {
        if (!update[entityType.updateName][i].angle) {
          update[entityType.updateName][i].angle = 0
        }
        entityType.currentList.push(update[entityType.updateName][i])
      }
    }
    update[entityType.updateName] = JSON.parse(JSON.stringify(entityType.currentList))
  })
  if (gameRestarted.value == true) {
    gameRestarted.value = false
  }

// console.timeEnd('test1')


  if (!firstServerTimestamp) {
    firstServerTimestamp = update.t
    serverTimeCounter = update.t
    gameStart = Date.now()
  }
  gameUpdates.push(update)
  // Keep only one game update before the current server time
  const base = getBaseUpdate()
  if (base > 0) {
    gameUpdates.splice(0, base)
  }
}

function currentServerTime() {
  return firstServerTimestamp + (Date.now() - gameStart) - RENDER_DELAY
}

// Returns the index of the base update, the first game update before
// current server time, or -1 if N/A.
function getBaseUpdate() {
  const serverTime = currentServerTime()
  for (let i = gameUpdates.length - 1; i >= 0; i--) {
    if (gameUpdates[i].t <= serverTime) {
      return i
    }
  }
  return -1
}

export function getCurrentState() {
  if (!firstServerTimestamp) {
    return {}
  }
  const base = getBaseUpdate()
  const serverTime = currentServerTime()
  // If base is the most recent update we have, use its state.
  // Otherwise, interpolate between its state and the state of (base + 1).
  if (base < 0 || base === gameUpdates.length - 1) {
    // console.log('using base state')
    gameUpdates[gameUpdates.length - 1].serverTime = gameUpdates[gameUpdates.length - 1].t
    return gameUpdates[gameUpdates.length - 1]
  } else {

    const baseUpdate = gameUpdates[base]
    const next = gameUpdates[base + 1]
    const ratio = (serverTime - baseUpdate.t) / (next.t - baseUpdate.t)

    // hacky implementation so hp updates before render delay
    for (let i = base; i < gameUpdates.length; i++) {
      baseUpdate.enemies.forEach((enemy) => {
        gameUpdates[i].enemies.forEach((enemyNextUpdates) => {
            if (enemy.id == enemyNextUpdates.id) {
                enemy.hp = enemyNextUpdates.hp
                enemy.hpChangeAmount = enemyNextUpdates.hpChangeAmount
            }
        })
      })
      // console.log('base update me', baseUpdate.me)

      if (gameUpdates[i].me.hpChangeAmount) {
        baseUpdate.me.hpChangeAmount = gameUpdates[i].me.hpChangeAmount
        baseUpdate.me.hp = gameUpdates[i].me.hp
        gameUpdates[i].me.hpChangeAmount = undefined
      }
      if (gameUpdates[i].me.livePets) {
        baseUpdate.me.livePets = gameUpdates[i].me.livePets
        gameUpdates[i].me.livePets = undefined
      }
      // baseUpdate.me.hp = gameUpdates[i].me.hp
      // baseUpdate.me.hpChangeAmount = gameUpdates[i].me.hpChangeAmount
    }



    ///

    if (baseUpdate.me && next.me) {
      // console.log('both updates exist')
      // console.log('interpolating')
      return {
        me: interpolateObject(baseUpdate.me, next.me, ratio, 'me', player),
        others: interpolateObjectArray(baseUpdate.others, next.others, ratio),
        enemies: interpolateObjectArray(baseUpdate.enemies, next.enemies, ratio),
        bullets: interpolateObjectArray(baseUpdate.bullets, next.bullets, ratio),
        leaderboard: baseUpdate.leaderboard,
        spikeSwitch: baseUpdate.spikeSwitch,
        lavaPit: baseUpdate.lavaPit,
        serverTime: baseUpdate.t,
        pickups: interpolateObjectArray(baseUpdate.pickups, next.pickups, ratio),
        eventCoords: baseUpdate.eventCoords
      }
    }
    else {
      return { serverTime: baseUpdate.t }
    }
  }
}

function interpolateObject(object1, object2, ratio, type, data) {
  if (!object2) {
    if (type == 'me') {
      if (!object1.x) { object1.x = data.x }
      if (!object1.y) { object1.y = data.y }
      // console.log('return coords', [object1.x, object1.y])
    }
    return object1
  }

  const interpolated = {}
  Object.keys(object1).forEach(key => {
    if (key === 'angle') {
      interpolated[key] = interpolateDirection(object1[key], object2[key], ratio)
    }
    else if (key == 'x' || key == 'y') {
      // console.log('x deltas and ratio', [object1[key], object2[key], ratio])
      interpolated[key] = object1[key] + (object2[key] - object1[key]) * ratio
      return interpolated
    }
    else {
      interpolated[key] = object1[key]
    }
  })
  return interpolated
}

function interpolateObjectArray(objects1, objects2, ratio) {
  return objects1.map(o => interpolateObject(o, objects2.find(o2 => o.id === o2.id), ratio))
}

// Determines the best way to rotate (cw or ccw) when interpolating a direction.
// For example, when rotating from -3 radians to +3 radians, we should really rotate from
// -3 radians to +3 - 2pi radians.
function interpolateDirection(d1, d2, ratio) {
  if ((d1 > 0 && d2 < 0) || d1 < 0 && d2 > 0) { return d1 }
  else {
    return d1 + (d2 - d1) * ratio
  }
}