// import {createStore, compose, applyMiddleware} from 'redux'
// import rootReducer from '../reducers/rootReducer'
// import thunk from 'redux-thunk'
//
// // const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//
//
// let composeEnhancer = null
// if (process.env.NODE_ENV === 'development') {
//   composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// } else {
//   composeEnhancer = compose
// }
//
// export default function configureStore() {
//   return createStore(
//     rootReducer,
//     // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
//     // applyMiddleware(thunk)
//     composeEnhancer(applyMiddleware(thunk)),
//   )
// }
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers/rootReducer';

export default function configureAppStore() {
  return configureStore({
    reducer: rootReducer,
    // You can add additional middleware here
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(/* your custom middleware */),
    // Redux Toolkit automatically adds the Redux DevTools support
  });
}
