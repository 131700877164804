import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'
import { updateSelectedServer } from '../actions/actions'
import Constants from '../../shared/constants'
import {  assignedDict } from '../equippedSpells.js'
import {currentlyPressed} from '../game.js'
import { handlePickupObject } from '../input.js'

import {colorPalette, mainBorderProperties} from './uiStyles.js'
import ButtonBase from '@mui/material/ButtonBase'

const columns = [
    {
        id: 'name', label: 'Name', align: 'center',
        minWidth: 170
    },
    {
        id: 'code', label: 'Players', align: 'center',
        minWidth: 100
    },
]

const handleClick = (event) => {
    updateSelectedServer(event.target.value)
}

class PickupButton extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    render() {
        let { touchControls } = this.props


        return (
            <>
            {touchControls &&
                <ButtonBase
                    onClick={(e) => {
                        e.nativeEvent.stopImmediatePropagation()
                        e.preventDefault()
                        e.stopPropagation()
                        // updateSpellSelection({ key: spellSlot })
                        console.log("calling handle pickup object", currentlyPressed, assignedDict)
                        handlePickupObject(currentlyPressed, assignedDict)
                    }}
                >
                <Grid container style={{
                    width: '100%',
                    maxWidth: '250px'
                }}>

                    <Paper
                        style={Object.assign({
                            userSelect: 'none',
                            textAlign: 'center',
                            backgroundColor: colorPalette.primary,
                            width: '100%',
                            maxHeight: '50px',
                            paddingBottom: '1%'
                        }, mainBorderProperties)}
                    >

                        <Grid item xs={12}>
                            <div style={{ fontWeight: 900,
                            width: '120px',
                            height: '40px',
                            
                            }}> Interact  </div>
                        </Grid>
                        <Grid item xs={12} >


                        </Grid>
                    </Paper>
                </Grid>
                </ButtonBase>
                }
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        touchControls: state.stuff.touchControls,
    }
}

export default connect(
    mapStateToProps,
)(PickupButton)