"use strict";
const uiStyles_1 = require("../client/components/uiStyles");
const Constants = {
    ////////// Project Settings //////////
    RELEASE_STATUS: 'PRODUCTION', // 'Alpha' // 'Beta' // 'Production'
    // MAX_PLAYERS_SERVER: 70,
    // MAX_PLAYERS_SERVER: 40,
    // MAX_PLAYERS_SERVER: 30,
    MAX_PLAYERS_SERVER: 50,
    // GAME: 'mageclash',
    // GAME: 'grix',
    GAME: 'monstermaster',
    //
    // SINGLE_GAME_NAME: 'grix',
    // SINGLE_GAME_NAME: 'monstermaster',
    GAME_MODE_MAP: [
        { gameName: 'mainportal', 'dbName': null, dbId: null, startMenuCameraCoordinates: [7340, 8181], domain: 'www.mageclash.io', websiteName: 'Mageclash.io', hideTitleWhenLoading: false, style: uiStyles_1.mainStyle },
        { gameName: 'mageclash', 'dbName': 'game1', dbId: 1, startMenuCameraCoordinates: [7340, 8181], domain: 'www.mageclash.io', websiteName: 'Mageclash.io', hideTitleWhenLoading: false, style: uiStyles_1.mainStyle },
        { gameName: 'grix', 'dbName': 'game2', dbId: 2, startMenuCameraCoordinates: [7340, 6817], domain: 'www.grix.io', websiteName: 'Grix.io', hideTitleWhenLoading: false, style: {
                primary: '#C0C0C0',
                secondary: '#FF7F50',
                highscoreGrid: '#D2B48C',
                primaryBorder: '#404040',
                secondaryBorder: '#404040',
            }
        },
        { gameName: 'monstermaster', 'dbName': 'game3', dbId: 3, startMenuCameraCoordinates: [7483, 11423], domain: 'www.monstermaster.io', websiteName: 'MonsterMaster.io', hideTitleWhenLoading: false, style: uiStyles_1.mainStyle
        }
    ],
    PROJECT_ID: 127111, //xsolla
    GAME_ID: 1,
    NETWORKING: 'CAUTH',
    // NETWORKING: 'SAUTH',
    TRACK_CLIENTSIDE_HP: false,
    CLIENTSIDE_BULLETS: true,
    // CLIENTSIDE_BULLETS: false,
    INGAME_MENU_TRANSPARENCY: 0.25,
    PERIODIC_SAVE_TIMER: 60 * 5, // 5 minutes,
    CURRENT_VERSION: '1.0009',
    USERNAME_CHAR_LIMIT: 10, // max in-game name length
    PLAYER_INTEREST_MANAGEMENT: 300, // viewport size
    CHAT_CHAR_LIMIT: 30, // max in-game chat length
    CHAT_COOLDOWN: 300,
    CHAT_DURATION: 2800,
    // How long to wait to remove player socket after death
    INACTIVE_COUNTER: 300,
    INACTIVE_DC_PERIOD: 30000,
    EMOTE_COOLDOWN: 1250, // ms to wait between emotes
    GAME_OVERALL_VOLUME: 0.25, // phaser volume 
    LEADERBOARD_INTERVAL: 1, // seconds interval between leaderboard updates
    MAP_SIZE_X: 19200,
    MAP_SIZE_Y: 19200,
    SPAWN_MIN_X: 480,
    SPAWN_MAX_X: 960,
    SPAWN_MIN_Y: 480,
    SPAWN_MAX_Y: 960,
    CULLING_GRID_RANGE: 275,
    BIG_CULLING_GRID_RANGE: 600,
    // TICK_RATE: 3,
    // TICK_RATE: 8,
    TICK_RATE: 10,
    // TICK_RATE: 15,
    // TICK_RATE: 10,
    // TICK_RATE: 3,
    // TICK_RATE: 30,
    // HIGHSCORE_REFRESH_INTERVAL: 1000 * 60 * 30,
    // HIGHSCORE_REFRESH_INTERVAL: 1000 * 5,
    HIGHSCORE_REFRESH_INTERVAL: 1000 * 60 * 60 * 12,
    ENABLE_DEV_HIGHSCORES: false,
    // HIGHSCORE_REFRESH_INTERVAL: 1000,
    // SERVER_TABLE_REFRESH_INTERVAL: 1000 * 60,
    SERVER_TABLE_REFRESH_INTERVAL: 1000,
    ////////// Test Settings //////////
    CREATE_BALANCING_CSV: false,
    ASSET_TESTING: false,
    TRIAL_TESTING: false,
    HIGH_MANA: false,
    MAX_LEVEL: false,
    GOD_MODE: false,
    HYPER_SPEED: false,
    TRACK_DT_PERFORMANCE: false,
    // CREATE_BALANCING_CSV: true,
    // ASSET_TESTING: true,
    // TRIAL_TESTING: true,
    // HIGH_MANA: true,
    // MAX_LEVEL: true,
    // GOD_MODE: true,
    // HYPER_SPEED: true,
    SEND_SYSTEM_DATA: true,
    // TRACK_DT_PERFORMANCE: true,
    ////////// Account Settings //////////
    ACC_LVL_INITIAL_EXP_REQ: 75000,
    ACC_LVL_EXTRA_EXP_PER_LVL: 25000,
    ACC_MAX_LEVEL: 100,
    BASE_ITEMS: { heads: [], emotes: [], back: [] },
    MONSTER_JOURNAL_THRESHOLDS: {
        normal: {
            bronze: 250,
            silver: 500,
            gold: 1000,
            platinum: 2500,
        },
        boss: {
            bronze: 25,
            silver: 50,
            gold: 100,
            platinum: 200,
        },
        event: {
            bronze: 5,
            silver: 10,
            gold: 25,
            platinum: 50,
        }
    },
    ////////
    ////////// Game Rendering //////////
    PLAYER_SIZE: {
        body: 47,
        top: {
            default: 47,
        },
        hands: 18,
        head: {
            default: 48,
        }
    },
    PLAYER_HAND_POSITIONS: {
        default: { left: [23, -21], right: [23, 21] },
        narrow: { left: [23, -21], right: [23, 21] },
        veryNarrow: { left: [23, -10], right: [23, 10] },
        spear: { left: [3, 17], right: [23, 17] },
        leftPunch: { left: [48, -21], right: [23, 21] },
        rightPunch: { left: [23, -21], right: [48, 21] },
        leftSpell: { left: [30, -10], right: [23, 21] },
        rightSpell: { left: [23, -21], right: [30, 10] },
    },
    SHADOW_TINT: 0x1D1D1B,
    SHADOW_ALPHA: 0.3,
    START_MENU_CAMERA_COORDINATES: [7340, 8181],
    START_MENU_X_SCROLL_SPEED: 0.08,
    ////////// Game Settings //////////
    EVENT_COOLDOWN: 225,
    // EVENT_COOLDOWN: 10,
    EVENT_MESSAGE_INTERVAL: 1, // seconds interval between leaderboard updates
    // Player
    SERVER_COOLDOWN_BUFFER: 0.15,
    // Bot and AI
    B_RESPAWN_TIMER: 240,
    AI_TARGET_TIME: 3,
    // Bullet settings
    MAX_BULLET_TIME: 8,
    ASSIGNED_SPELLS_TEMPLATE: { '1': {}, '2': {}, '3': {}, '4': {}, 'shift': {}, '5': {} },
    POINTER_ACTIVE_TEMPLATE: { '1': false, '2': false, '3': false, '4': false, 'shift': false, '5': false },
    GRAVITY_OTHER_BULLET_POWER: 1.5,
    ATTACKED_WHILE_CONCEALED_TIMER: 2,
    MELEE_RADIUS_RATIO: 0.8,
    // Traps
    TRAP_COOLDOWN_TICKS: 45,
    SPIKE_SWITCH_COUNTER: 2,
    LAVA_PIT_COUNTER: 4,
    // Pickups
    PICKUP_DESPAWN_TIME: 20,
    PICKUP_SPEED: 40,
    PICKUP_MOVE_TIME: 0.5,
    SPELL_DISPLAY_RADIUS: 6.5,
    MANA_DISPLAY_RADIUS: 3.56,
    SPELL_PICKUP_RADIUS: 20,
    MANA_PICKUP_RADIUS: 20,
    GEAR_PICKUP_RADIUS: 20,
    TRIAL_CHECK_INTERVAL: 1,
    TRIAL_DEFAULT_PILLAR_SPAWN_TIME: 25,
    TRIAL_CHECK_ENEMIES_INTERVAL: 1,
    ////////// CSS (must refactor out) //////////
    BACKGROUND_GRADIENT: 'linear-gradient(90deg, rgba(249,160,58,1) 0%, rgba(254,216,156,1) 2%, rgba(252,237,195,1) 30%, rgba(252,237,195,1) 70%, rgba(254,216,156,1) 98%, rgba(249,160,58,1) 100%)',
    ////////// Message Types //////////
    MSG_TYPES: {
        JOIN_GAME_REQUEST: 1,
        GAME_UPDATE: 2,
        MOVEMENT: 3,
        ANGLE: 4,
        GAME_OVER: 6,
        SHOOT: 7,
        JOIN_GAME: 8,
        SEND_STATS: 9,
        SEND_PLAYER_PICKUP: 10,
        REQUEST_PICKUP: 11,
        UPDATE_WORLD_PICKUP: 12,
        UPDATE_PICKUP_UI: 13,
        SEND_BR_POS: 14,
        SHEATHE_WEAPON: 15,
        UPGRADE_STATS: 16,
        RESTART_GAME_REQUEST: 17,
        SERVER_FULL: 18,
        ALREADY_PLAYING: 19,
        EMOTE: 20,
        CHAT: 21,
        SEND_TRIAL_VICTORY: 22,
        CLAIM_TRIAL: 23,
        SEND_MESSAGE: 24,
        TRIAL_NOTIFICATION: 25,
        PLAYER_OFFWORLD: 26,
        SYSTEM_DATA: 27,
        SET_MIDDLE_LOWER_TEXT: 28,
        SET_PLAYER_SPELLS: 29,
        SET_PLAYER_SPELL: 30,
        CMOVEMENT: 31,
        SET_POSITION: 32,
        SET_SPEED: 33,
        SET_CUSTOM_BASE_STAT_CAPS: 34,
        RESTART_GAME: 35,
        SET_STATS: 36,
        SET_SHOP_DATA: 37,
        SELECT_SHOP_ITEM: 38,
        TAKE_DAMAGE: 39,
        UPDATE_PLAYER_STATS: 40,
        GIVE_DAMAGE: 41,
        UPDATE_ENTITY_HEALTH: 42,
        FORCE_OPEN_MENU: 43,
        CLOSE_MENU: 44,
        NEW_LOGIN_DETECTED: 45,
        INVALID_TOKEN: 46,
        NO_TOKEN: 47,
        JOIN_FAILED: 48,
        INVALID_JOIN_GAME_REQUEST: 49,
        SET_SPELL_POINTER: 50,
        EXP_GAINED: 51,
        SET_GAMEPLAY_ACTIVE: 52,
        UPDATE_GENERIC_BAR_NAME: 53,
        UPDATE_GENERIC_BAR_VALUE: 54,
        SEND_TO_MAIN_MENU: 55,
        CREATE_POINTER: 56,
        REMOVE_POINTER: 57,
        CLICK_GENERIC_BAR: 58
        // COMMUNICATE_STAT_CHANGE
    },
    ////////// Tiled Layers //////////
    MAP_JSON_NAME: 'map.json',
    TILESIZE: 16,
    // STATIC_LAYER_LIST: ['Obstacles'],
    OBJECT_LAYER_LIST: ['Decoration', 'Traps', 'GeneralObjects', 'Pickups', 'Enemies', 'MovementImpairingFloor', 'SpawnPoint', 'MapLocation', 'EnemyBlocker', 'CirclePoints', 'BaseBlocker', 'MinZones', 'TrialTeleporters', 'TrialLocations', 'TrialEnemies', 'EventEnemies', 'Custom'],
    IGNORE_LAYER_LIST: ['SmallDecorations', 'IslandSmudges', 'Rounders'],
    TILED_PARSE_COMMANDS: {
        Tree: 20 / 64,
        PalmTree: 24 / 64,
        Rock: 120 / 128,
        RockWater: 65 / 128,
        RockLava: 120 / 128,
        RockIce: 120 / 128,
        RockIce1: 120 / 128,
        spellTemplate: 1,
        manaTemplate: 1,
        Bush: 0.7,
        LavaBush: 0.7,
        OceanBush: 0.7,
        IceBush: 0.7,
        Campfire: 1.3,
        lava_flow_glow_spike_base: 0.6,
        lava_ground_glow_spike_base: 0.6,
        lava_flow_spike_base: 0.45,
        lava_ground_spike_base: 0.45,
        ice_bone_base: 0.6,
        ice_crystal_1_base: 0.45,
        ice_crystal_2_base: 0.45,
        ice_crystal_3_base: 0.45
    },
    MOVEMENT_DIR_DICT: {
        0: "stop",
        1: 0,
        2: 1.57,
        3: 3.14,
        4: 4.71,
        5: 3.93,
        6: -0.79,
        7: 2.36,
        8: 0.79
    },
    FPS_THRESHOLDS: {
        'VERY_LOW': 15,
        'LOW': 30,
        'MEDIUM': 60,
        'HIGH': 144,
    },
    INITIAL_RANDOM_HEADS: [1, 7, 26, 62, 27, 64],
    DEPTHS: {
        floorEnvironments: 1,
        floorObjects: 2,
        shadow: 3,
        bullet: 4,
        enemy: 5,
        groundObjects: 5,
        player: 6,
        mediumObjects: 6,
        chatRectangle: 7,
        chatText: 8,
        emoteCircle: 7,
        emote: 8,
        entityLabelBackground: 7,
        entityLabels: 8,
        highObjects: 9,
        UI: 20,
        text: 50,
        joystick: 100,
        debug: 1000,
    }
};
if (!Constants.SINGLE_GAME_NAME) {
    Constants.SINGLE_GAME_NAME = undefined;
}
module.exports = Constants;
