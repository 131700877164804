import React, { Component } from 'react'
import GearBox from './GearBox.js'
import { Grid } from '@mui/material'

import { connect } from 'react-redux'
// import * as stuffActions from '../actions/stuffActions';




class GearContainer extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    componentDidMount() {

    }

    render() {
        // let { availableSpellKeys } = this.state;
        let { bodyWear, topWear, handWear, statsData } = this.props
        // console.log("GEAR CONTAINER GEAR NAME", bodyWear)
        let bodyWearBox = <Grid xs={12} > <GearBox gear={bodyWear} gearType='bodyWear' statsData={statsData}>  </GearBox> </Grid>
        let topWearBox = <Grid xs={12}> <GearBox gear={topWear} gearType='topWear' statsData={statsData}>  </GearBox> </Grid>
        let handsWearBox = <Grid xs={12}> <GearBox gear={handWear} gearType='handWear' statsData={statsData}>  </GearBox> </Grid>
        return (
                    <Grid container xs={12} style={{ height: '100%', width: '100%'  }}>
                        {/* <Grid xs={6} sm={0}></Grid><Grid xs={6} sm={12}>{topWearBox}</Grid> */}
                    <Grid xs={4}></Grid>
                    <Grid xs={8}>
                        {topWearBox}
                        {bodyWearBox}
                        {handsWearBox}
                        </Grid>
                    </Grid>
        )
    }
}


function mapStateToProps(state) {
    return {
        bodyWear: state.stuff.bodyWear,
        topWear: state.stuff.topWear,
        handWear: state.stuff.handWear,
        statsData: state.stuff.statsData,
    }
}

export default connect(
    mapStateToProps,
)(GearContainer)