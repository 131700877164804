import React, { Component } from 'react'
import { ButtonBase } from '@mui/material'
import { Grid } from '@mui/material'

class GlyphButton extends Component {
    constructor() {
        super()
        this.state = {
            fillColor: '#E7CAB2',
            outerBorderColor: '#362D44',
            innerBorderColor: '#FFF1D2',
            imgScale: 'scale(1)',
            config: {
                idle: {
                    fillColor: '#FFD522',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#F79E3C',
                    imgScale: 'scale(.9)'
                },
                hover: {
                    fillColor: '#FEFF69',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFD522',
                    imgScale: 'scale(1)'
                },
                down: {
                    fillColor: '#F49540',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#D66C16',
                    imgScale: 'scale(1)'
                },
            }
        }
    }

    componentDidMount() {
        let { config } = this.state
        this.setState({
            fillColor: config.idle.fillColor,
            outerBorderColor: config.idle.outerBorderColor,
            innerBorderColor: config.idle.innerBorderColor
        })
    }

    render() {
        let { fillColor, innerBorderColor, outerBorderColor, imgScale, config } = this.state
        let { buttonFunction, title, textColor, Text1, Text2, ImgSource, Background, SpecialText, imagePaddingHor, imagePaddingVert, imageSize, fontSize, showSpecialText, priceLocation } = this.props


        if (!textColor) { textColor = 'white' }
        return (
            <Grid container xs={12}>
                <ButtonBase
                    style={
                        {
                            fontSize: '2vh',
                            borderRadius: '6px',
                            borderWidth: '2px', borderStyle: 'solid',
                            background: Background,

                            borderColor: innerBorderColor,
                            boxShadow: `0 0 0 2px ${outerBorderColor}`,
                            textTransform: 'none',
                           
                            marginTop: '10px',
                            fontSize: '2vh',
                            margin: '5%',
                            position: 'relative',
                            width: '260px'
                        }
                    }

                    variant="contained"

                    onMouseOver={() => {
                        this.setState({
                            fillColor: config.hover.fillColor,
                            outerBorderColor: config.hover.outerBorderColor,
                            innerBorderColor: config.hover.innerBorderColor,
                            imgScale: config.hover.imgScale
                        })
                    }}
                    onMouseOut={() => {
                        this.setState({
                            fillColor: config.idle.fillColor,
                            outerBorderColor: config.idle.outerBorderColor,
                            innerBorderColor: config.idle.innerBorderColor,
                            imgScale: config.idle.imgScale
                        })
                    }
                    }
                    onMouseDown={(e) => {
                        this.setState({
                            fillColor: config.down.fillColor,
                            outerBorderColor: config.down.outerBorderColor,
                            innerBorderColor: config.down.innerBorderColor,
                            imgScale: config.down.imgScale
                        })
                    }}

                    onClick={(e) => {
                        e.nativeEvent.stopImmediatePropagation()
                        e.preventDefault()
                        e.stopPropagation()

                        buttonFunction()
                    }}
                >
                        <img
                            style={{
                                // padding: '35px',
                                // padding: imagePadding,
                                paddingLeft: imagePaddingHor,
                                paddingRight: imagePaddingHor,
                                paddingTop: imagePaddingVert,
                                paddingBottom: imagePaddingVert,
                                
                                transform: imgScale,
                                pointerEvents: 'none',
                                height: imageSize,
                                width: imageSize,

                            }}
                            src={ImgSource}
                        />

                        <b
                            style={{
                                // fontSize: '25px',
                                fontSize: fontSize,
                                position: 'absolute',
                                top: '3%',
                                color: 'white'
                            }}
                        >
                            {Text1}
                        </b>
                        {SpecialText && showSpecialText &&
                        // <Grid xs={12}>
                        <div
                        style={{
                            // fontSize: '23px',
                            fontSize: fontSize,
                            position: 'absolute',
                            top: '76%',
                            color: 'white',
                            borderRadius: '6px',
                            borderWidth: '2px', borderStyle: 'solid',
                            borderColor: 'black',
                            backgroundColor: Background,
                            width: '110%',
                            paddingBottom: '4px',
                            paddingTop: '4px'
                        }}
                        >
                            {SpecialText}
                        </div>
                        // </Grid>
                            }
                        <div
                            style={{
                                // fontSize: '23px',
                                fontSize: fontSize,
                                position: 'absolute',
                                // top: '89%',
                                top: priceLocation,
                                color: 'white'
                            }}
                        >
                            {Text2}
                        </div>
                </ButtonBase >
            </Grid>       
        )
    }
}

export default (GlyphButton)