import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import { handleUpgradeStat } from '../input.js'
import LinearProgress from '@mui/material/LinearProgress'
import Constants from '../../shared/constants'
import { phaserObject } from '../create'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { updateStatBarVisibility } from '../actions/actions.js'

const styles = theme => ({
    bar: {
        transition: 'none'
    },
})

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    '&.MuiLinearProgress-colorPrimary': {
        backgroundColor: '#5050a0',
    },
    '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#9f9fff',
    },
    '&.MuiLinearProgress-colorSecondary': {
        backgroundColor: '#ba587b',
    },
    '& .MuiLinearProgress-barColorSecondary': {
        backgroundColor: '#ffb6d7',
    },
}));
class Stats extends Component {
    constructor() {
        super()
        this.state = {
        }
    }
    componentDidMount() {
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.stats != nextProps.stats
            || (this.props.statPoints != nextProps.statPoints)
            || (this.props.statsTopWear != nextProps.statsTopWear)
            || (this.props.statsBodyWear != nextProps.statsBodyWear)
            || (this.props.statsHandWear != nextProps.statsHandWear) ||
            this.props.customBaseStatCaps != nextProps.customBaseStatCaps ||
            this.props.statsUpdate != nextProps.statsUpdate || this.props.width != nextProps.width || 
            this.props.activeGenericSlots != nextProps.activeGenericSlots ||
            this.props.statsBarOpen != nextProps.statsBarOpen
        ) {
            return true
        }
        else {
            return false
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.statPoints === 0 && this.props.statPoints > 0) {
            console.log('stat points added, changing vis')
            updateStatBarVisibility(true)
        }
        if (prevProps.statPoints > 0 && this.props.statPoints === 0) {
            console.log('used stat points, removing vis')
                setTimeout(() => {
                    updateStatBarVisibility(false)
                }, 1000)
        }
    }
    render() {
        let { stats, statPoints, statsTopWear, statsBodyWear, statsHandWear, statsData, customBaseStatCaps, width, activeGenericSlots, statsBarOpen } = this.props
        let buttonKeys = this.state

        let leftVal = '20.5vw'
        let lvlUpPointsLeftVal = '3vw'
        if (width > 1000) {
            leftVal = '207px',
            lvlUpPointsLeftVal = '40px'
        }
        console.log('stats data', statsData)
        console.log('stats obj', stats)

        let extraOffset = activeGenericSlots * 10
        let hiddenExraOffset = 0
        if (activeGenericSlots > 2) {
            extraOffset -= (activeGenericSlots - 2) * 7
            hiddenExraOffset = (activeGenericSlots - 2) * -3
        }

        console.log('EXTRA OFFSET', extraOffset)
        console.log('reading stats bar open', statsBarOpen)
        let statBox = []
        let lvlUpMsg = []
        let statOverlayOffset = statsBarOpen ? `${79-extraOffset}%` : `${(13-extraOffset)-hiddenExraOffset}%`
        if (statPoints > 0) {
            lvlUpMsg.push(<Grid container style={{
                justifyContent: 'right',
                alignItems: 'right',
                position: 'absolute',
                // bottom: '80%',
                bottom: statOverlayOffset,
                left: lvlUpPointsLeftVal,
                //13% when closed // 79% when open
                zIndex: 100}}>
                <Grid xs={10}> </Grid>
                <Grid xs={2}> <h1 style={{
                    textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
                    color:'white',
                    transform: 'rotate(-19deg)'
                }}> <strong> x{statPoints} </strong></h1> </Grid>
            </Grid>)
            }
        for (let [key, value] of Object.entries(stats)) {
            if (key === 'multipliers') {
                continue
            }
            let gearStatsValue = (statsTopWear[key] || 0) + (statsBodyWear[key] || 0) + (statsHandWear[key] || 0)

            let totalStatValue = gearStatsValue + value

            let scaledStatValue = value * 12.5
            if (customBaseStatCaps[key]) {
                scaledStatValue = value * (100 / customBaseStatCaps[key])
            }


            if (gearStatsValue === 0) {
                gearStatsValue = ''
            } else {
                gearStatsValue = `+${gearStatsValue}`
            }
            let imgUrl
            if (!buttonKeys[key]) {
                imgUrl = `assets/ui/btn_add_idle.png`
            } else {
                imgUrl = `assets/ui/${buttonKeys[key]}`
            }
            // console.log(' stats data', statsData)
            let individualStatData = statsData.properties.find(stat => stat.name == key)
            console.log('reading individual stat', individualStatData)
            let statDisplayName = individualStatData.displayName

            let showBar = statsData.global.showBar



            let statNameSize = 12
            if (!showBar) {
                statNameSize = 6
            }
            let statSize = 4
            if (!showBar) {
                statSize = 6
            }
            statBox.push(
                <Grid container style={{ justifyContent: 'center', alignItems: 'center',
                    marginRight: '16px' }}>
                    <Grid item xs={statNameSize}
                          style={{padding:'3%'}}
                    > <div> <strong>{
                        statDisplayName
                    } </strong></div> </Grid>
                    {showBar &&
                    <Grid item xs={8}>
                        <StyledLinearProgress variant="determinate" color='secondary' value={scaledStatValue} style={{ width: '100%',
                        minHeight: '15px',
                        borderTopLeftRadius: '2px',
                        borderBottomLeftRadius: '2px',
                        borderTopRightRadius: '2px',
                        borderBottomRightRadius: '2px',
                        boxShadow: '0 0 0 1px #362D44',
                    }}
                        />
                    </Grid>
                    }
                    {(statPoints > 0 && value < statsData.global.maxUpgradableValue && statsBarOpen
                    && individualStatData.upgradable !== false
                    ) ?
                        <Grid item xs={statSize}>
                            <strong> {value} </strong>
                            <strong style={{color: 'blue'}}> {gearStatsValue} </strong>
                            <IconButton style={{ pointerEvents: 'auto',
                                zIndex: -1,
                                position: 'absolute',
                                transform: 'translate(0%, -35%)',
                                left: leftVal

                            }} size='small'
                                        onMouseOver={() => {
                                            this.setState({ [key]: 'btn_add_hover.png' })
                                        }}
                                        onMouseOut={() => {
                                            this.setState({ [key]: 'btn_add_idle.png' })
                                        }}
                                        onMouseUp={() => {
                                            this.setState({ [key]: 'btn_add_hover.png' })
                                        }}
                                        onMouseDown={(e) => {
                                            e.nativeEvent.stopImmediatePropagation()
                                            e.preventDefault()
                                            e.stopPropagation()
                                            phaserObject.sfx.play('click-6')
                                            handleUpgradeStat(key)
                                            this.setState({ [key]: 'btn_add_down.png' })
                                        }}>
                                <img
                                    src= {imgUrl}
                                />
                            </IconButton>
                        </Grid>
                        :
                        <Grid item xs={statSize}>
                            <strong> {value} </strong>
                            <strong style={{color: 'blue'}}> {gearStatsValue} </strong>
                        </Grid>
                    }
                </Grid>
            )

        }
        return (
            <Grid container spacing={0} justifyContent="center" style={{
                marginLeft: '5%',
                height: '100%',
            }}>
                <Grid item xs={12} style={{height: '100%'}} >
                    {lvlUpMsg}


                    <Accordion expanded={statsBarOpen} style={{
                        paddingLeft: '5%',
                        paddingBottom: '2.5%',
                        textAlign: 'center', backgroundColor: 'white',
                        borderRadius: '6px',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'rgba(0,0,0,0.25)',
                        boxShadow: '0 0 0 2px #362D44',


                        width: '20vw',
                        maxWidth: '200px'
                        // maxWidth: '20vw',
                        // maxWidth: '150px'
                    }}>
                        <AccordionSummary style={{
                            pointerEvents: 'auto',
                        }}
                            expandIcon={<ExpandMoreIcon
                                onMouseDown={(e) => {
                                    e.nativeEvent.stopImmediatePropagation()
                                    e.preventDefault()
                                    e.stopPropagation()
                                    updateStatBarVisibility(!statsBarOpen)
                                }}
                           />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <strong>Stats</strong>
                        </AccordionSummary>
                            {statBox}
                    </Accordion>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stuff.stats,
        statPoints: state.stuff.statPoints,
        statsTopWear: state.stuff.statsTopWear,
        statsBodyWear: state.stuff.statsBodyWear,
        statsHandWear: state.stuff.statsHandWear,
        statsData: state.stuff.statsData,
        customBaseStatCaps: state.stuff.customBaseStatCaps,
        width: state.stuff.width,
        statsBarOpen: state.stuff.statsBarOpen,
    }
}

export default connect(
    mapStateToProps,
)(Stats)