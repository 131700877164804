import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Paper, Box } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'

import {styled} from "@mui/material/styles";

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    '&.MuiLinearProgress-colorPrimary': {
        backgroundColor: '#443f54',
    },
    '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#14cfed',
    }
}));

class EXPBarMin extends Component {
  constructor() {
    super()
    this.state = {
      selected: false,
      timer: 100,
    }
  }

  componentDidMount() {

  }
  render() {
    const { classes } = this.props
    let { level, expRatio } = this.props
    return (
      <Grid container spacing={0} justifyContent="center" style={{ marginBottom: '5%', marginLeft: '5%' , height: '100%', width: '100%', }}>
        <Paper style={{
            width: '20vw',
            maxWidth: '200px',
        height: '100%',
          borderRadius: '6px',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'rgba(0,0,0,0.25)',
        boxShadow: '0 0 0 2px #362D44',
          borderWidth: '1px', borderStyle: 'solid',
        paddingBottom: '3px',
        paddingRight: '3px',
        paddingLeft: '3px',
        }} >
        <Grid xs={12} style={{ 'textAlign': 'center', height: '100%', width: '100%', marginBottom: '0.5%' }}>
            <div style={{height: '100%',
            //  fontSize: '12px',
            width: '100%'
            }}><strong>
                Level {level}
                </strong></div>
        </Grid>
        
        <Grid xs={12} style={{ 'textAlign': 'center', height: '100%', width: '100%',
      }}>
            <StyledLinearProgress variant="determinate"
            value={expRatio} style={{ 
               minHeight: '15px',
               borderRadius: '2px',
              boxShadow: '0 0 0 1px #362D44',
               }}
               />
        </Grid>
        </Paper>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    level: state.stuff.level,
    expRatio: state.stuff.expRatio
  }
}

export default connect(mapStateToProps)(EXPBarMin)
