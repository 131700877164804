import React, { Component } from 'react'
// import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase'

import {connect} from "react-redux";

const styles = theme => ({
    bar: {
        transition: 'none'
    },
})

class MageClashTitle extends Component {
    constructor() {
        super()
        this.state = {
        }
    }


    render() {
        let { currentGame} = this.props
        let path = 'mainPortal/titlebanner.png'
        if (currentGame) {
            path = `games/${currentGame}/titlebanner.png`
        }
        return (
            <ButtonBase>
            <img
                style={{width: '100%', pointerEvents: 'none'}}
                src={`assets/${path}`}
            />
            </ButtonBase>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentGame: state.stuff.currentGame,
    }
}

export default connect(
    mapStateToProps,
)(MageClashTitle)