import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'


class OverleveledIndicator extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    componentDidMount() {

    }

    render() {
        let { currentCircle, zoneCountdown, minZone } = this.props

        return (
            <div>

                <Grid container spacing={0} justifyContent="center" style={{ height: '90%', width: '100%' }}>
                    <Grid item xs={12} >
                        {zoneCountdown > 0 && minZone+1 > currentCircle &&
                            <Paper style={{
                                backgroundColor: 'red', textAlign: 'center',
                                borderRadius: '2px',
                                borderWidth: '2px', borderStyle: 'solid',
                                borderColor: '#FFFFFF',
                                boxShadow: '0 0 0 2px #362D44',
                                padding: '5%',
                                width: '120%',
                            }}>
                                <div><h3><b>You are overleveled</b></h3></div>
                                <div><h3>Move out to the next zone!</h3></div>
                                <div><h3>Attacks disabling in: {zoneCountdown}</h3></div>
                            </Paper>
                        }
                        {zoneCountdown <= 0 && minZone > currentCircle &&
                            <Paper style={{
                                backgroundColor: 'red', textAlign: 'center',
                                borderRadius: '2px',
                                borderWidth: '2px', borderStyle: 'solid',
                                borderColor: '#FFFFFF',
                                boxShadow: '0 0 0 2px #362D44',
                                padding: '5%',
                                width: '120%',
                            }}>
                                <div><h3><b>You are overleveled</b></h3></div>
                                <div><h3>Move out to the next zone!</h3></div>
                                <div><h3>Attacks disabled</h3></div>
                            </Paper>
                        }
                    </Grid>
                </Grid>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        currentCircle: state.stuff.currentCircle,
        zoneCountdown: state.stuff.zoneCountdown,
        minZone: state.stuff.minZone,
    }
}
export default connect(
    mapStateToProps
)(OverleveledIndicator)
