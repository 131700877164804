import React, { Component } from "react"
import { Grid, Paper } from '@mui/material'
import { connect } from 'react-redux'
import {bigScreenBorderProperties, colorPalette} from './uiStyles.js'
import ButtonBase from '@mui/material/ButtonBase'
import Constants from '../../shared/constants'
import {getRandomItem} from '../phaserUtils'

class FeaturedVideo extends Component {
    constructor() {
        super()
        this.state = {
            // 'leaderboard': []

            chosenVideo: {
                url: 'https://www.youtube.com/watch?v=Ux92ghq5aKM',
                thumbnailName: 'newupdate.webp'
            }, 
        }
    }

    componentDidMount() {

        let videoList = [
            {
                url: 'https://www.youtube.com/watch?v=Ux92ghq5aKM',
                thumbnailName: 'newupdate.webp'
            }, 
            {
                url: 'https://www.youtube.com/watch?v=temSsj-_tDk',
                thumbnailName: 'howtogrind.jpg'
            },


            // {
            //     url: 'https://www.youtube.com/watch?v=Xw4DnSm4BFE',
            //     thumbnailName: 'dragonisscary.webp'
            // }
            {
                url: 'https://www.youtube.com/watch?v=nl7KKJ_YJLY',
                // thumbnailName: 'newgametn.png'
                thumbnailName: 'newgame.webp'

            }

        ]

        let chosenVideo = getRandomItem(videoList)

        this.setState({
            chosenVideo: chosenVideo
        })
    }

    render() {
        // let { leaderboard } = this.props

        let {chosenVideo} = this.state


        return (
            <Grid container xs={12} style={{
                marginBottom: '5%', width: '100%', height: '100%',
                // minWidth: '120px'
            }}>
                <Paper
                    style={Object.assign({
                        background: colorPalette.primary,
                        // padding: '7px',
                        minWidth: '200px',
                        maxWidth: '200px',
                        borderColor: colorPalette.primaryBorder,
                    }, bigScreenBorderProperties)}
                >
                    <Grid container align='center' justifyContent="center" alignItems="center"  xs={12}>
                        <Grid xs={12}>
                            {/* <h4>
                                FEATURED VIDEO
                            </h4> */}
                            <div style={{
                                fontWeight: 900,
                                // fontSize: 'max(1.5vh, 16px)', 
                                fontSize: 'max(1.5vh, 19px)',
                                maxHeight: '31px',
                                paddingBottom: '3%'
                            }}> FEATURED VIDEO </div>
                        </Grid>
                        <Grid xs={12}>
                            {/* video here */}
                            <ButtonBase
                                onClick={(e) => {

                                    // window.open("https://www.youtube.com/watch?v=Ux92ghq5aKM")
                                    window.open(chosenVideo.url)
                                    e.nativeEvent.stopImmediatePropagation()
                                    e.preventDefault()
                                    e.stopPropagation()

                                }}
                            >
                            <img style={{
                                position: 'relative', pointerEvents: 'none',
                                width: '180px',

                                margin: 'auto',
                                borderRadius: '4px',

                                borderWidth: '2px',
                                borderStyle: 'solid',
                                borderColor: '#fcd7a6',
                            }}
                                // src='assets/videoThumbnails/newUpdate.webp'
                                    src={`assets/videoThumbnails/${chosenVideo.thumbnailName}`}
                                />
                            </ButtonBase>
                        </Grid>
                    </Grid>

                </Paper>
         
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        // leaderboard: state.stuff.leaderboard
    }
}


export default connect(
    mapStateToProps,
)(FeaturedVideo)
