import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'
import ResourceBox from "./ResourceBox.js";

class ResourceContainer extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    render() {
        let { resourceProperties, availableResources} = this.props
        let resources = resourceProperties.properties

        let resourceBoxes = resources.map((resource) => {
            if (!resource.hidden) {
                let resourceQuantity = availableResources[resource.name] // placeholder
                return <Grid item xs={4}><div
                >
                    <ResourceBox resourceData={resource} resourceQuantity={resourceQuantity}
                    ></ResourceBox>
                </div> </Grid>
            }
        })

        return (
            <Grid container
                  style={{
                marginRight: '0.5%',
                height: '90%',
                marginBottom: '0.5%'
            }}>
                <Grid xs={6}
                    md={9}
                ></Grid>
                <Grid xs={6}
                    md={3}
                >
                    <Grid container
                          justifyContent="flex-end"
                    >
                        {resourceBoxes}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        availableResources: state.stuff.availableResources,
        resourceProperties: state.stuff.resourceProperties,
    }
}

export default connect(
    mapStateToProps,
)(ResourceContainer)
