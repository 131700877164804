import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'
import { Paper } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import LinearProgress from '@mui/material/LinearProgress'
import { IconButton } from '@mui/material'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import Constants from '../../shared/constants'
import { updateSettings } from '../actions/actions'
import IconSettings from './icons/IconSettings.js'
import Switch from '@mui/material/Switch'

import {bigScreenBorderProperties, colorPalette} from './uiStyles.js'


class SettingsMenu extends Component {
    constructor() {
        super()
        this.state = {
            dialog: false,

        }
    }

    componentDidUpdate(prevProps, prevState) {

    }


    componentDidMount() {
        let { accountKillStats } = this.props

    }

    updateSettings(key, settingsType) {
        let {settings, width} = this.props

        try{
        let localStorageSettings = JSON.parse(localStorage.getItem('settings'))

        localStorageSettings[settingsType][key] = !settings[settingsType][key]

        // let localStorageVideoSettings = localStorageSettings.settings
        // localStorageVideoSettings[key] = !settings.videoSettings[key]
        // localStorage.setItem("videoSettings", JSON.stringify(localStorageVideoSettings))

       
        localStorage.setItem('settings', JSON.stringify(localStorageSettings))

        updateSettings({settingType: settingsType, key: key, value: !settings[settingsType][key] }) } catch(err){}
    }

    render() {
        const handleClickOpen = () => { this.setState({ 'dialog': true }) }
        const handleClose = () => { this.setState({ 'dialog': false }) }


        let gridStyle = {
            marginBottom: '5px',
            // whiteSpace: 'pre-wrap' 
        }
        let { dialog } = this.state

        let {settings, width} = this.props


        let buttonSize
        if (width < 1280) {buttonSize = 'small'}

        return <>
            {/* <ColorChangingButton buttonFunction={handleClickOpen}
                config={
                    {
                        idle: {
                            fillColor: '#7C4E4D',
                            outerBorderColor: '#362D44',
                            innerBorderColor: '#543131',
                        },
                        hover: {
                            fillColor: '#CC7A82',
                            outerBorderColor: '#362D44',
                            innerBorderColor: '#AA5561',
                        },
                        down: {
                            fillColor: '#CC7A82',
                            outerBorderColor: '#362D44',
                            innerBorderColor: '#AA5561',
                        },
                    }

                }
                title='Settings'
                buttonStyling={{
                    marginTop: '10px', marginRight: '5px',
                    color: 'white',
                    width: '70%',
                    padding: '1%'
                }}
            /> */}
            <IconSettings buttonFunction={handleClickOpen} buttonSize={buttonSize} />

            <Dialog
                style={{
                    padding: '10px',
                }}
                onClose={handleClose} aria-labelledby="simple-dialog-title" open={dialog}>
                <IconButton
                    style={{
                        alignSelf: 'flex-end',
                        marginTop: -5,
                        position: 'absolute'
                    }}
                    onClick={handleClose}
                    size="large">
                    <CancelPresentationIcon style={{ fontSize: '3rem' }} />
                </IconButton>

                <Paper align='center' justify="center" alignItems="center" direction="row"
                    style={Object.assign({
                        background: colorPalette.primary,
                        borderColor: colorPalette.primaryBorder,
                    }, bigScreenBorderProperties)}>
                    <Grid container
                        align='center' justifyContent="center" alignItems="center" direction="row"
                        style={{
                            marginBottom: '2.5%'
                        }}>
                        <Grid
                            item xs={12} style={{
                                paddingBottom: '2px'
                            }}>
                            <h1
                            style={{marginBottom: '3px'}}
                            ><b>Settings </b></h1>
                        </Grid>

                        <Grid
                            item xs={12} style={{
                                marginBottom: '12px'
                            }}>
                            <h2
                            style={{marginBottom: '4px'}}
                            ><b>Graphics Settings  </b></h2>
                            <b>    (Requires page reload)  </b>
                        </Grid>

                
                    
                        <Grid container> 
                            <Grid container xs={12}>
                                <Grid xs={6}> 
                                Shadows
                                </Grid>
                                <Grid xs={6}>
                                    <Switch
                                        checked={settings.videoSettings.shadows}
                                        onChange={() => this.updateSettings('shadows', 'videoSettings')}
                                        name="checkedA"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                    {/* Yes */}
                                </Grid>
                            </Grid>
                            <Grid container xs={12}>
                                {/* <Grid xs={2}> </Grid> */}
                                <Grid xs={6}>
                                    Decorations
                                </Grid>
                                <Grid xs={6}>
                                    {/* Yes */}
                                    <Switch
                                        checked={settings.videoSettings.smallDecorations}
                                        onChange={() => this.updateSettings('smallDecorations', 'videoSettings')}
                                        name="checkedB"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>
                                    {/* <Grid xs={2}> </Grid> */}
                            </Grid>
                            <Grid container xs={12}>
                                <Grid xs={6}>
                                    Ground Marks
                                </Grid>
                                <Grid xs={6}>
                                    {/* Yes */}
                                    <Switch
                                        checked={settings.videoSettings.smudges}
                                        onChange={() => this.updateSettings('smudges', 'videoSettings')}
                                        name="checkedC"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>
                            </Grid>
                         
                        </Grid>
                    </Grid>
                </Paper>
            </Dialog>
        </>;
    }
}


function mapStateToProps(state) {
    return {
        // videoSettings: state.stuff.videoSettings
        settings: state.stuff.settings,
        width: state.stuff.width
    }
}

export default connect(
    mapStateToProps,
)(SettingsMenu)