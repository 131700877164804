import React, {  } from 'react'
import { Grid } from '@mui/material'
import { ButtonBase } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { Paper } from '@mui/material'
import { IconButton } from '@mui/material'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import Constants from '../../shared/constants'
import ColorChangingButton from './ColorChangingButton.js'
import Slide from '@mui/material/Slide'
import {bigScreenBorderProperties, colorPalette} from './uiStyles.js'
import * as Actions from '../actions/actions'
import ColorTable from "../../shared/colorTable";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />
})

function ItemRewardFull(props) {
    let { dialogNewItem, itemRewardList, handleCloseNewItem, } = props

    // let logoPathDict = {
    //     emote: {
    //         path: 'assets/ui/icon_emote.png',
    //         top: '0px'
    //     },
    //     head: {
    //         path: 'assets/ui/icon_head.png',
    //         top: '10px'
    //     },
    //     coins: {
    //         path: 'assets/ui/rune.png',
    //         top: '0px'
    //     },
    //     gems: {
    //         path: 'assets/ui/glyph.png',
    //         top: '0px'
    //     }
    // }

    if (itemRewardList.length > 0) {
    let itemRewardGrid = itemRewardList.map((item) => {
 
        // let logoPath = logoPathDict[itemEarned.type].path
        // let top = logoPathDict[itemEarned.type].top
        let top = '0px'
        if (item.reward.type == 'head') {
            top = '10px'
        }
  
        let gridSize
        if (itemRewardList.length == 1) {gridSize = 12} 
        else if (itemRewardList.length <= 4 ) {gridSize = 6}
        // else if (itemRewardList.length == 4) {gridSize = 6}
        else {gridSize = 3} 

        return <Grid xs={gridSize}>
            <ButtonBase style={{
                borderRadius: '15px',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: ColorTable.rarityInfo[item.reward.rarity].loadoutBorder,
                boxShadow: '0 0 0 2px #C79B7B',

                width: '150px',
                height: '150px',

                position: 'relative',
                margin: '1%'
            }}>
                <img style={{
                    position: 'relative', pointerEvents: 'none',
                    width: '150px',
                    margin: 'auto',
                    // transform: transform,
                    top: top,
                }}
                    src={item.rewardImgPath} />
                {item.reward.quantity &&
                    <div style={{
                        position: 'absolute',
                        right: '5%',
                        bottom: '1%',
                        pointerEvents: 'none',
                        zIndex: 10,
                        color: 'white',
                        fontSize: '20px',
                        textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
                    }}
                    >
                        {item.reward.quantity}
                    </div>
                }
            </ButtonBase>
        </Grid>
    }) 

    return (
        <Dialog
            open={dialogNewItem}
            onClose={handleCloseNewItem}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={'md'}
            style={{zIndex:100002}}
        >
            <IconButton
                style={{
                    alignSelf: 'flex-end',
                    marginTop: -5,
                    position: 'absolute'
                }}
                onClick={handleCloseNewItem}
                size="large">

                <CancelPresentationIcon style={{ fontSize: '3rem' }} />
            </IconButton>

              <Paper style={Object.assign({
                                background: colorPalette.primary,
                                padding: '2%',
                  borderColor: colorPalette.primaryBorder,
                            }, bigScreenBorderProperties)}>
            <Grid container align='center' xs={12}
                style={{
                    minWidth: '500px',
                    minHeight: '440px',
                    background: colorPalette.primary,
                }}
            >
                <Grid xs={12}>
                    <div
                        style={{
                            paddingTop: '10px',
                        }}
                    >
                        <b style={{
                            fontSize: '21px'
                        }}

                        > ITEMS EARNED </b>
                    </div>
                </Grid>
          
                <Grid container xs={12}>
                        {itemRewardGrid}
                </Grid>
                    <Grid xs={12}>
                        <ColorChangingButton buttonFunction={() => {
                            Actions.toggleLoadoutMenu(true)
                            handleClose()
                            handleCloseNewItem()
                        }}
                            config={
                                {
                                    idle: {
                                        fillColor: '#7C4E4D',
                                        outerBorderColor: '#362D44',
                                        innerBorderColor: '#543131',
                                    },
                                    hover: {
                                        fillColor: '#CC7A82',
                                        outerBorderColor: '#362D44',
                                        innerBorderColor: '#AA5561',
                                    },
                                    down: {
                                        fillColor: '#CC7A82',
                                        outerBorderColor: '#362D44',
                                        innerBorderColor: '#AA5561',
                                    },
                                }}
                            title='Loadout'
                            buttonStyling={{
                                marginTop: '10px', marginRight: '5px',
                                color: 'white',
                                width: '70%',
                                padding: '1%'
                            }}
                        />
                    </Grid>
                
            </Grid>
            </Paper>
        </Dialog>
    );
    } else {return null}
}

export default ItemRewardFull