"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomTypes = void 0;
const classGivers = [
    {
        id: 'priest',
        textToDisplay: 'Become a priest!'
    },
    {
        id: 'mage',
        textToDisplay: 'Become a mage!'
    },
    {
        id: 'warrior',
        textToDisplay: 'Become a warrior!'
    },
    {
        id: 'rogue',
        textToDisplay: 'Become a rogue!'
    },
];
const monsterGivers = [
    {
        id: 'TinyCreaturePet',
        textToDisplay: 'Get a charlie!'
    },
    {
        id: 'SlimePet',
        textToDisplay: 'Get a slime!'
    },
    {
        id: 'BabyDragonWhelpPet',
        textToDisplay: 'Get a baby dragon!'
    },
    {
        id: 'WoodlandWhelpPet',
        textToDisplay: 'Get a critter!'
    },
];
const portals = [
    {
        id: 'dest_world_1_1',
        textToDisplay: 'Enter World 1-1! [lv. 1~5]'
    },
    {
        id: 'dest_world_1_2',
        textToDisplay: 'Enter World 1-2! [lv. 2~5]'
    },
    {
        id: 'dest_world_1_3',
        textToDisplay: 'Enter World 1-3! [lv. 2~5]'
    },
    {
        id: 'dest_world_2',
        textToDisplay: 'Enter World 2! [lv. 2~8]'
    },
    {
        id: 'dest_world_3',
        textToDisplay: 'Enter World 3! [lv. 5~13]'
    },
    {
        id: 'dest_world_4',
        textToDisplay: 'Enter World 4! [lv. 8~15]'
    },
    {
        id: 'dest_world_4_1',
        textToDisplay: 'Travel to next area!'
    },
    {
        id: 'dest_world_4_2',
        textToDisplay: 'Travel to next area!'
    },
    {
        id: 'dest_world_4_2_1',
        textToDisplay: 'Travel to previous area!'
    },
    {
        id: 'dest_world_4_Secret',
        textToDisplay: 'Where will this portal take you...?'
    },
    {
        id: 'dest_world_4_3',
        textToDisplay: 'Travel to next area!'
    },
    {
        id: 'dest_world_4_3_1',
        textToDisplay: 'Travel to previous area!'
    },
    {
        id: 'dest_world_4_4',
        textToDisplay: 'Travel to next area!'
    },
    {
        id: 'dest_world_4_4_1',
        textToDisplay: 'Travel to previous area!'
    },
    {
        id: 'dest_world_4_5',
        textToDisplay: 'Travel to next area!'
    },
    {
        id: 'dest_world_4_5_1',
        textToDisplay: 'Travel to previous area!'
    },
    {
        id: 'dest_world_4_6',
        textToDisplay: 'Travel to next area!'
    },
    {
        id: 'dest_world_4_6_1',
        textToDisplay: 'Travel to previous area!'
    },
    {
        id: 'dest_town_port_1',
        textToDisplay: 'Return to town!'
    },
    {
        id: 'dest_town_port_2',
        textToDisplay: 'Return to town!'
    },
    {
        id: 'dest_town_main',
        textToDisplay: 'Return to town!'
    },
    {
        id: 'dest_town_fusion',
        textToDisplay: 'Return to town!'
    },
    {
        id: 'dest_fusion',
        textToDisplay: 'Enter Fusion Area!'
    },
    {
        id: 'dest_tut_0',
        textToDisplay: 'Tutorial'
    },
    {
        id: 'dest_tut_1',
        textToDisplay: 'Learn to catch'
    },
    {
        id: 'dest_tut_2',
        textToDisplay: 'Start your Adventure!'
    },
    {
        id: 'dest_tut_3',
        textToDisplay: 'Tutorial 3'
    },
];
const fusionAltars = [
    {
        id: 'main',
        textToDisplay: 'Remove Main Pet!'
    },
    {
        id: 'sub',
        textToDisplay: 'Remove Sub Pet!'
    },
    {
        id: 'fusionTrigger',
        textToDisplay: 'FUUUUUUUUSIONNN!'
    },
];
const deafNpcText = [
    {
        id: 'Slime Master',
        textToDisplay: 'Welcome to the tutorial! Learn how to control your pets, and become the best monster master!'
    },
    {
        id: 'Net Master',
        textToDisplay: 'Throw your nets at monsters to catch one!.'
    },
    {
        id: 'Dragon Master',
        textToDisplay: 'Every monster has a special ability. Try using them!.'
    },
    {
        id: 'Apprentice',
        textToDisplay: 'You are almost there!'
    },
    {
        id: 'Novice Tamer',
        textToDisplay: "I'm here to catch some slimes at the beach. they seem pretty cute...!!!"
    },
    {
        id: 'Novice Tamer 2',
        textToDisplay: "There's an Ent down at the end of this road..."
    },
    {
        id: 'Novice Tamer 3',
        textToDisplay: "My pet hellhound escaped! I need to get it back, but maybe after a bit of rest..."
    },
    {
        id: 'Grave Warning',
        textToDisplay: 'Hellhound Sanctuary. DO NOT ENTERRRR!'
    },
    {
        id: 'Challenge Guardian',
        textToDisplay: 'Enter the Challenge Chamber to test your skills!'
    },
    {
        id: 'Wizard',
        textToDisplay: 'Go to a Pillar and press F to teleport'
    },
    {
        id: 'Party Leader',
        textToDisplay: 'Make sure to bring friends to these challenges!'
    }
];
const npcText = [
    {
        id: 'Net Seller',
        textToDisplay: 'Buy Nets!'
    },
    {
        id: 'Pet Shop Master',
        textToDisplay: 'Buy and Sell your pets!'
    },
    // {
    //     id: 'Wizard',
    //     textToDisplay: 'Go to a Pillar and press F to teleport'
    // },
    {
        id: 'Fusion Apprentice',
        textToDisplay: 'Enter here to fuse your pets!'
    },
    {
        id: 'Fusion Apprentice 1',
        textToDisplay: 'Main pet will be the primary pet used for fusion'
    },
    {
        id: 'Fusion Apprentice 2',
        textToDisplay: 'Sub pet will be used to reinforce primary pet'
    },
    {
        id: 'Fusion Tutorial Trigger',
        textToDisplay: 'Try Fusion!'
    },
    {
        id: 'Fusion Master',
        textToDisplay: 'Learn to fuse!'
    },
    {
        id: 'Pet Storage',
        textToDisplay: 'Store and Retrieve your pets!'
    },
    {
        id: 'Stat Resetter',
        textToDisplay: 'Reset your stats, at a price.'
    },
];
const rankChallenge = [
    {
        id: 'Rank Challenge',
        textToDisplay: 'Enter the Challenge Chamber!'
    }
];
exports.CustomTypes = {
    classGiver: classGivers,
    monsterGiver: monsterGivers,
    portal: portals,
    fusionAltar: fusionAltars,
    deafNpcText: deafNpcText,
    npcText: npcText,
    rankChallenge: rankChallenge
};
