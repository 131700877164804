import React, { Component } from 'react'
import {Grid, Menu} from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'
import { updateSelectedServer } from '../actions/actions'
import Constants from '../../shared/constants'
import {  MenuItem, Select } from '@mui/material'
import {
    mainBorderProperties,
    invertedInnerScreenBorderProperties,
    dropDownMenuProperties,
    colorPalette
} from './uiStyles.js'
import {styled} from "@mui/material/styles";

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
    ...mainBorderProperties,
    userSelect: 'none',
    textAlign: 'center',
    backgroundColor: colorPalette.primary,
    width: '100%',
    maxHeight: '50px',
    paddingBottom: '1%'
}));

const StyledSelect = styled(Select)(({ theme }) => ({
    ...invertedInnerScreenBorderProperties,
    boxShadow: `0 0 0 2px ${colorPalette.secondaryBorder}`,
    background: colorPalette.secondary,
    minWidth: '170px',
    height: '20px',
    opacity: '100%'
}));
// const StyledMenu = styled(Menu)(dropDownMenuProperties);

const StyledMenu = styled((props) => (
    <Menu {...props} PaperProps={{ style: dropDownMenuProperties }} />
))({});
const columns = [
    {
        id: 'name', label: 'Name', align: 'center',
        minWidth: 170
    },
    {
        id: 'code', label: 'Players', align: 'center',
        minWidth: 100
    },
]

const handleClick = (event) => {
    updateSelectedServer(event.target.value)
}

class ServerDropdown extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    render() {
        const { classes } = this.props

        let { serverData, selectedServer, currentGame} = this.props

        console.log('server data in dropdown', serverData )
        console.log('current game', currentGame)
        console.log('selected server', selectedServer)
        let gameServerData
        const isBeta = serverData.beta ? true : false
        if (isBeta) {
            gameServerData = serverData.beta.servers
        } else {
            if (currentGame) {
                if (serverData[currentGame]) {
                    gameServerData = serverData[currentGame].servers
                } else {
                    gameServerData = [
                        {
                            name: 'Loading...',
                            url: 'https://www.mageclash.io',
                            // players: 10,
                            players: 'low',
                            // selected: true,
                        }
                    ]
                }
            }
            else {
                gameServerData = [
                    {
                        name: 'Loading...',
                        url: 'https://www.mageclash.io',
                        // players: 10,
                        players: 'low',
                        // selected: true,
                    }
                ]
            }
        }
        console.log('game server data', gameServerData)

        const maxPlayers = Constants.MAX_PLAYERS_SERVER

     
        let index = -1
        let serverSelectTable = gameServerData.map((server)=>{
            index += 1
            // return <MenuItem value={server.url}> {server.name} - {server.players} </MenuItem>
            return <MenuItem 
                style={{ fontWeight: 'bold' }}
            value={server.url}> {server.name} </MenuItem>
            })

      

        const handleChange = (event) => {
            updateSelectedServer(event.target.value)
        }

        return (
            <>
                <Grid container style={{
                    width: '100%',
                    maxWidth: '250px'
                }}>
                   
                    <Paper 
                    style={Object.assign({
                        userSelect: 'none',
                        textAlign: 'center',
                        backgroundColor: colorPalette.primary,
                        width: '100%',
                        maxHeight: '50px',
                        paddingBottom: '1%'
                    }, mainBorderProperties)}
                    >

                        <Grid item xs={12}>
                            <div style={{  fontWeight: 900 }}> SERVER </div>
                        </Grid>
                        <Grid item xs={12} >
                            <Select
                                sx={Object.assign({
                                    background: colorPalette.secondary,
                                    minWidth: '170px',
                                    height: '20px',
                                    opacity: '100%',
                                    boxShadow: `0 0 0 2px ${colorPalette.secondaryBorder}`,
                                }, invertedInnerScreenBorderProperties)}
                                MenuProps={{
                                    PaperProps: {
                                        style: dropDownMenuProperties
                                    }
                                }}
                        //     style={Object.assign({
                        //             background: colorPalette.secondary,
                        //             minWidth: '170px',
                        //             height: '20px',
                        //             opacity: '100%'
                        // }, invertedInnerScreenBorderProperties)}
                        //
                        //     MenuComponent={StyledMenu}
                                // MenuProps={{ sx: dropDownMenuProperties }}
                                value={selectedServer}
                                onChange={handleChange}

                            >
                                {serverSelectTable}
                            </Select>
                        </Grid>
                    </Paper>
                </Grid>
            </>
        )
    }
}
        function mapStateToProps(state) {
            return {
                serverData: state.stuff.serverData,
                selectedServer: state.stuff.selectedServer,
                currentGame: state.stuff.currentGame,
            }
        }

export default connect(mapStateToProps)(ServerDropdown)