import React, { Component } from 'react'
import { connect } from 'react-redux'
import Constants from '../../shared/constants'
import { updateChatInput, toggleChatboxActive } from '../actions/actions'
import {chatboxActive} from '../game.js'
import {phaserObject} from '../create.js'
import {handleChat} from '../input.js'
import { player } from '../create.js'
import { playChat } from '../playerScripts.js'
import {colorPalette} from "./uiStyles";

class ChatBox extends Component {
    constructor() {
        super()
        this.state = {
        }

    }

    componentDidMount() {
    }

   
    disableChatBox() {
        toggleChatboxActive(false)
        phaserObject.input.keyboard.enabled = true
        setTimeout(()=>{ chatboxActive.value = false}, 100)
        updateChatInput('')
    }

    render() {
        let {chatInput} = this.props
        const handleChange = (event) => {
            if (event.target.value.length <= Constants.CHAT_CHAR_LIMIT) {
                let value = event.target.value  
                // alow spaces
                // value = value.replace(/[^A-Za-z0-9\s]/ig, '')
                value = value.replace(/[^A-Za-z0-9\s-?!.,:;()/']/ig, '')
                updateChatInput(value)
            }
        }
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {  
                this.disableChatBox()
            }
            if (event.key == ' ') {
                if (event.target.value.length <= Constants.CHAT_CHAR_LIMIT) {
                let value = event.target.value + ' '
                updateChatInput(value)
                }
            }
            if (event.key === 'Enter') {
  

                if (chatInput.length != 0) {
               
                handleChat(chatInput)
                if (chatInput[0] !== '/') {
                    playChat(player, chatInput, phaserObject)
                }
                }

                this.disableChatBox()
            }
        }
            return (
                <input autoFocus
                    style={{
                        marginBottom: '3%',
                        borderRadius: '4px',
                        borderWidth: '1.5px', borderStyle: 'solid',
                        borderColor: 'rgba(0,0,0,0.25)',
                        boxShadow: '0 0 0 2px hsl(0, 0%, 10%)',
                        background: colorPalette.secondary,
                        width: '95%',
                        outline: 'none',
                        height: '30px',
                        textAlign: 'center',
                        fontSize: '20px',
                        cursor: 'url(assets/ui/cursor_text.png), text'
                    }}
                    onBlur={({ target }) => target.focus()}
                    value={chatInput}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                />
            )
        }
    }


function mapStateToProps(state) {
    return {
        chatInput: state.stuff.chatInput
    }
}

export default connect(
    mapStateToProps,
)(ChatBox)

