"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnemyConstants = exports.EnemyConstantsBase = void 0;
const drops_1 = require("../drops");
const config_1 = require("../config");
exports.EnemyConstantsBase = [
    {
        'enemyID': 1,
        'name': 'Slime',
        'radius': 8,
        'spriteName': 'slime',
        'imgName': 'enemy_slime.png',
        'type': 'air',
        'tiledName': 'Slime',
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 35,
        // 'respawnCooldown': 1,
        // 'scoreOnKill': 25,
        'scoreOnKill': 50,
        'speed': 50,
        'hp': 300,
        label: 'Lvl 1 Slime',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        iconColor: '#00ff00',
        // 'hp': 8,
        'proximityRadius': 100,
        'shootingRange': 100,
        'spells': [411],
        'ai': { canMove: true, followTarget: false, lookAtTarget: true },
        // 'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'trialDropChance': {
            baseChance: 0.01,
            dropTable: [
                { id: 5, weight: 100 },
                { id: 6, weight: 100 },
            ]
        },
        // 'trialDropChance': {
        // 	baseChance: 1,
        // 	dropTable: [
        // 		{id: 5, weight: 1000000},
        // 		{id: 6, weight: 1000000000000},
        // 	]
        // },
        'dropTable': {
            starter: [
                { type: 'body', id: 2, weight: 6 }, // Uncommon Wizard Body
                { type: 'body', id: 3, weight: 6 }, // Uncommon Rogue Body
                { type: 'body', id: 4, weight: 6 }, // Uncommon Knight Body
                { type: 'top', id: 2, weight: 6 }, // Uncommon Wizard Hat
                { type: 'top', id: 3, weight: 6 }, // Uncommon Rogue Hat
                { type: 'top', id: 4, weight: 6 }, // Uncommon Knight Hat
                { type: 'hand', id: 2, weight: 6 }, // Uncommon Wizard Gloves
                { type: 'hand', id: 3, weight: 6 }, // Uncommon Rogue Hands
                { type: 'hand', id: 4, weight: 6 }, // Uncommon Knight Gloves
                { type: 'spell', id: 63, weight: 20, manaQuantity: 40 }, // Uncommon Earthquake
                { type: 'spell', id: 250, weight: 20, manaQuantity: 40 }, // Uncommon Fire Staff
                { type: 'spell', id: 232, weight: 20, manaQuantity: 40 }, // Uncommon Fire Wand
                { type: 'spell', id: 201, weight: 20, manaQuantity: 40 }, // Uncommon Sword
                { type: 'spell', id: 214, weight: 5 }, // Uncommon Dagger
                { type: 'spell', id: 301, weight: 10, manaQuantity: 40 }, // Uncommon  Dash
                { type: 'spell', id: 311, weight: 10, manaQuantity: 40 }, // Uncommon Stealth
            ],
            bronze: [
            // { type: 'hand', id: 5, weight: 2 }, // Rare Wizard Gloves
            // { type: 'hand', id: 6, weight: 2 }, // Rare Rogue Gloves
            // { type: 'hand', id: 7, weight: 2 }, // Rare Knight Gloves
            // { type: 'spell', id: 226, weight: 5 }, // Uncommon Spear
            ],
            silver: [
                { type: 'hand', id: 14, weight: 3 }, // Slime Hands
            ],
            gold: [
                { type: 'top', id: 14, weight: 3 }, // Slime Top
            ],
            platinum: [
                { type: 'body', id: 14, weight: 3 }, // Slime Body
            ]
        },
        'drops': [
            { type: 'spell', id: 6, weight: 20, manaQuantity: 40 }, // Uncommon Wind Shot
            { type: 'spell', id: 11, weight: 20, manaQuantity: 40 }, // Uncommon Fire Shot
            { type: 'spell', id: 85, weight: 30, manaQuantity: 40 }, // Uncommon Heal Self
            { type: 'spell', id: 201, weight: 20, manaQuantity: 40 }, // Uncommon Sword
            { type: 'spell', id: 214, weight: 5 }, // Uncommon Dagger
            { type: 'spell', id: 301, weight: 10, manaQuantity: 40 }, // Uncommon Dash
            { type: 'spell', id: 307, weight: 10, manaQuantity: 40 }, // Uncommon Charge
            { type: 'spell', id: 311, weight: 10, manaQuantity: 40 }, // Uncommon Stealth,
            { type: 'mana', id: 1, weight: 20, manaQuantity: 30 },
            { type: 'mana', id: 2, weight: 20, manaQuantity: 30 },
            { type: 'mana', id: 3, weight: 20, manaQuantity: 30 },
            { type: 'mana', id: 4, weight: 20, manaQuantity: 30 },
            { type: 'body', id: 2, weight: 6 }, // Uncommon Wizard Body
            { type: 'body', id: 3, weight: 6 }, // Uncommon Rogue Body
            { type: 'body', id: 4, weight: 6 }, // Uncommon Knight Body
            { type: 'top', id: 2, weight: 6 }, // Uncommon Wizard Hat
            { type: 'top', id: 3, weight: 6 }, // Uncommon Rogue Hat
            { type: 'top', id: 4, weight: 6 }, // Uncommon Knight Hat
            { type: 'hand', id: 2, weight: 6 }, // Uncommon Wizard Hands
            { type: 'hand', id: 3, weight: 6 }, // Uncommon Rogue Hands
            { type: 'hand', id: 4, weight: 6 }, // Uncommon Knight Hands
            { type: 'hand', id: 5, weight: 1 }, // Rare Wizard Gloves
            { type: 'hand', id: 6, weight: 1 }, // Rare Rogue Gloves
            { type: 'hand', id: 7, weight: 1 }, // Rare knight Gloves
        ]
    },
    {
        'enemyID': 2,
        'name': 'Dark Wizard',
        'type': 'dark',
        'radius': 6,
        'spriteName': 'darkWiz',
        'imgName': 'enemy_darkWiz.png',
        'tiledName': 'darkWiz',
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 90,
        'scoreOnKill': 200,
        'speed': 80,
        'hp': 50,
        label: 'Lvl 1 Slime',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        'proximityRadius': 150,
        'shootingRange': 125,
        'spells': [410, 425],
        'ai': { canMove: true, followTarget: false, lookAtTarget: true, predictLocation: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 8, weight: 10, manaQuantity: 40 }, // Legendary Gravity Ball
                { type: 'spell', id: 28, weight: 10, manaQuantity: 40 }, // Rare Gravity Bolt
                { type: 'spell', id: 29, weight: 10, manaQuantity: 40 }, // Epic Triple Gravity Bolt
                { type: 'spell', id: 72, weight: 20, manaQuantity: 50 }, // Epic Dark Hole
                { type: 'spell', id: 73, weight: 5, manaQuantity: 60 }, // Legendary Dark Hole
                { type: 'mana', id: 1, weight: 20, manaQuantity: 45 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 45 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 45 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 45 },
                { type: 'mana', id: 5, weight: 20, manaQuantity: 45 },
                { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body
                { type: 'body', id: 8, weight: 10 }, // Epic Wizard Body
                { type: 'body', id: 11, weight: 2 }, // Legendary Wizard Body
                { type: 'top', id: 5, weight: 10 }, // Rare Wizard Hat
                { type: 'top', id: 8, weight: 10 }, // Epic Wizard Hat
                { type: 'top', id: 11, weight: 2 }, // Legendary Wizard Hat
                { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Hands
                { type: 'hand', id: 8, weight: 10 }, // Epic Wizard Hands
                { type: 'hand', id: 11, weight: 2 }, // Legendary Wizard Hands
            ],
            bronze: [
                { type: 'body', id: 11, weight: 1 }, // Legendary Wizard Body
                { type: 'top', id: 11, weight: 1 }, // Legendary Wizard Hat
                { type: 'hand', id: 11, weight: 1 }, // Legendary Wizard Hands
            ],
            silver: [
                { type: 'body', id: 11, weight: 1 }, // Legendary Wizard Body
                { type: 'top', id: 11, weight: 1 }, // Legendary Wizard Hat
                { type: 'hand', id: 11, weight: 1 }, // Legendary Wizard Hands
            ],
            gold: [
                { type: 'body', id: 11, weight: 1 }, // Legendary Wizard Body
                { type: 'top', id: 11, weight: 1 }, // Legendary Wizard Hat
                { type: 'hand', id: 11, weight: 1 }, // Legendary Wizard Hands
            ],
            platinum: [
                { type: 'body', id: 11, weight: 1 }, // Legendary Wizard Body
                { type: 'top', id: 11, weight: 1 }, // Legendary Wizard Hat
                { type: 'hand', id: 11, weight: 1 }, // Legendary Wizard Hands
            ]
        },
        'drops': [
            { type: 'spell', id: 8, weight: 10, manaQuantity: 40 }, // Legendary Gravity Ball
            { type: 'spell', id: 28, weight: 10, manaQuantity: 40 }, // Rare Gravity Bolt
            { type: 'spell', id: 29, weight: 10, manaQuantity: 40 }, // Epic Triple Gravity Bolt
            { type: 'spell', id: 72, weight: 20, manaQuantity: 50 }, // Epic Dark Hole
            { type: 'spell', id: 73, weight: 5, manaQuantity: 60 }, // Legendary Dark Hole
            { type: 'mana', id: 1, weight: 20, manaQuantity: 45 },
            { type: 'mana', id: 2, weight: 20, manaQuantity: 45 },
            { type: 'mana', id: 3, weight: 20, manaQuantity: 45 },
            { type: 'mana', id: 4, weight: 20, manaQuantity: 45 },
            { type: 'mana', id: 5, weight: 20, manaQuantity: 45 },
            { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body
            { type: 'body', id: 8, weight: 10 }, // Epic Wizard Body
            { type: 'body', id: 11, weight: 2 }, // Legendary Wizard Body
            { type: 'top', id: 5, weight: 10 }, // Rare Wizard Hat
            { type: 'top', id: 8, weight: 10 }, // Epic Wizard Hat
            { type: 'top', id: 11, weight: 2 }, // Legendary Wizard Hat
            { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Hands
            { type: 'hand', id: 8, weight: 10 }, // Epic Wizard Hands
            { type: 'hand', id: 11, weight: 2 }, // Legendary Wizard Hands
        ]
    },
    {
        'enemyID': 4,
        'name': 'Slime Boss',
        'boss': true,
        'radius': 40,
        'spriteName': 'bossSlime',
        'imgName': 'enemy_slime.png',
        'type': 'fire',
        'tiledName': 'slimeBoss',
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 300,
        // 'respawnCooldown': 20,
        // 'scoreOnKill': 350,
        'scoreOnKill': 1,
        'speed': 80,
        'hp': 700,
        // hp: 5,
        'randomTint': true,
        'proximityRadius': 250,
        'shootingRange': 250,
        'spells': [412, 419, 421],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'numberOfDrops': 3,
        'trialDropChance': {
            baseChance: 1,
            dropTable: [
                { id: 5, weight: 50 },
                { id: 6, weight: 50 }
            ]
        },
        'dropTable': {
            starter: [
                { type: 'top', id: 14, weight: 10 }, // Rare Slime hat
                { type: 'body', id: 14, weight: 10 }, // Rare Slime Body
                { type: 'hand', id: 14, weight: 10 }, // Rare Slime Hands
                { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                { type: 'top', id: 5, weight: 10 }, // Rare Wizard Hat
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Hat
                { type: 'top', id: 7, weight: 10 }, // Rare Knight Hat
                { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gloves
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gloves
                { type: 'spell', id: 8, weight: 10, manaQuantity: 60 }, // Legendary Gravity Ball
                { type: 'spell', id: 208, weight: 10, manaQuantity: 60 }, // Rare Sword
                { type: 'spell', id: 86, weight: 10, manaQuantity: 60 }, // Rare Heal Self
                { type: 'spell', id: 302, weight: 10, manaQuantity: 60 }, // Rare Dash
                { type: 'spell', id: 308, weight: 10, manaQuantity: 60 }, // Rare Charge
                { type: 'spell', id: 312, weight: 10, manaQuantity: 60 }, // Rare Stealth
            ],
            bronze: [
                { type: 'top', id: 14, weight: 5 }, // Rare Slime hat
                { type: 'body', id: 14, weight: 5 }, // Rare Slime Body
                { type: 'hand', id: 14, weight: 5 }, // Rare Slime Hands
            ],
            silver: [
                { type: 'top', id: 14, weight: 5 }, // Rare Slime hat
                { type: 'body', id: 14, weight: 5 }, // Rare Slime Body
                { type: 'hand', id: 14, weight: 5 }, // Rare Slime Hands
            ],
            gold: [
                { type: 'top', id: 14, weight: 5 }, // Rare Slime hat
                { type: 'body', id: 14, weight: 5 }, // Rare Slime Body
                { type: 'hand', id: 14, weight: 5 }, // Rare Slime Hands
            ],
            platinum: [
                { type: 'top', id: 14, weight: 5 }, // Rare Slime hat
                { type: 'body', id: 14, weight: 5 }, // Rare Slime Body
                { type: 'hand', id: 14, weight: 5 }, // Rare Slime Hands
            ]
        },
        'drops': [
            { type: 'top', id: 14, weight: 10 }, // Rare Slime hat
            { type: 'body', id: 14, weight: 10 }, // Rare Slime Body
            { type: 'hand', id: 14, weight: 10 }, // Rare Slime Hands
            { type: 'spell', id: 8, weight: 10, manaQuantity: 60 }, // Legendary Gravity Ball
            { type: 'spell', id: 208, weight: 10, manaQuantity: 60 }, // Rare Sword
            { type: 'spell', id: 86, weight: 10, manaQuantity: 60 }, // Rare Heal Self
            { type: 'spell', id: 302, weight: 10, manaQuantity: 60 }, // Rare Dash
            { type: 'spell', id: 308, weight: 10, manaQuantity: 60 }, // Rare Charge
            { type: 'spell', id: 312, weight: 10, manaQuantity: 60 }, // Rare Stealth
            { type: 'mana', id: 1, weight: 20, manaQuantity: 60 },
            { type: 'mana', id: 2, weight: 20, manaQuantity: 60 },
            { type: 'mana', id: 3, weight: 20, manaQuantity: 60 },
            { type: 'mana', id: 4, weight: 20, manaQuantity: 60 },
            { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body
            { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body
            { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
            { type: 'top', id: 5, weight: 10 }, // Rare Wizard Hat
            { type: 'top', id: 6, weight: 10 }, // Rare Rogue Hat
            { type: 'top', id: 7, weight: 10 }, // Rare Knight Hat
            { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves
            { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gloves
            { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gloves
        ]
    },
    {
        'enemyID': 5,
        'name': 'Charlie',
        'type': 'dark',
        'radius': 14,
        'spriteName': 'evilCreature',
        'imgName': 'enemy_evilCreature.png',
        'tiledName': 'Creature',
        'spine': true,
        'spineTwoAttacks': true,
        'respawnCooldown': 90,
        // 'scoreOnKill': 150,
        'scoreOnKill': 200,
        'speed': 70,
        'hp': 150,
        'proximityRadius': 150,
        'shootingRange': 55,
        'spells': [400],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'trialDropChance': {
            baseChance: 0.01,
            dropTable: [
                { id: 8, weight: 50 },
                { id: 11, weight: 50 },
            ]
        },
        'dropTable': {
            starter: [
                { type: 'spell', id: 202, weight: 10, manaQuantity: 30 }, // Epic Ice Katana
                { type: 'spell', id: 203, weight: 10, manaQuantity: 30 }, // Epic Wind Katana
                { type: 'spell', id: 33, weight: 10, manaQuantity: 30 }, // Rare Healing Circle
                { type: 'spell', id: 302, weight: 10, manaQuantity: 60 }, // Rare Dash
                { type: 'spell', id: 307, weight: 10, manaQuantity: 60 }, // Uncommon Charge
                { type: 'spell', id: 312, weight: 10, manaQuantity: 60 }, // Rare Stealth
                { type: 'spell', id: 71, weight: 15, manaQuantity: 50 }, // Rare Dark Hole
                { type: 'mana', id: 1, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 30 },
                { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                { type: 'body', id: 8, weight: 2 }, // Rare Wizard Body,
                { type: 'body', id: 9, weight: 2 }, // Rare Rogue Body,
                { type: 'body', id: 10, weight: 2 }, // Rare Knight Body,
                { type: 'top', id: 5, weight: 10 }, // Rare Wizard Body
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Body
                { type: 'top', id: 7, weight: 10 }, // Rare Knight Armor
                { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gauntlets
            ],
            bronze: [
                { type: 'spell', id: 8, weight: 10, manaQuantity: 30 }, // Legendary Gravity Ball
            ],
            silver: [
                { type: 'top', id: 8, weight: 2 }, // Epic Wizard Body
                { type: 'top', id: 9, weight: 2 }, // Epic Rogue Body
                { type: 'top', id: 10, weight: 2 }, // Epic Knight Body,
                { type: 'hand', id: 8, weight: 2 }, // Epic Wizard Gloves
                { type: 'hand', id: 9, weight: 2 }, // Epic Rogue Gloves
                { type: 'hand', id: 10, weight: 2 }, // Epic Knight Gauntlets,
            ],
            gold: [
                { type: 'top', id: 8, weight: 1 }, // Epic Wizard Body
                { type: 'top', id: 9, weight: 1 }, // Epic Rogue Body
                { type: 'top', id: 10, weight: 1 }, // Epic Knight Body,
                { type: 'hand', id: 8, weight: 1 }, // Epic Wizard Gloves
                { type: 'hand', id: 9, weight: 1 }, // Epic Rogue Gloves
                { type: 'hand', id: 10, weight: 1 }, // Epic Knight Gauntlets,
            ],
            platinum: [
                { type: 'top', id: 8, weight: 1 }, // Epic Wizard Body
                { type: 'top', id: 9, weight: 1 }, // Epic Rogue Body
                { type: 'top', id: 10, weight: 1 }, // Epic Knight Body,
                { type: 'hand', id: 8, weight: 1 }, // Epic Wizard Gloves
                { type: 'hand', id: 9, weight: 1 }, // Epic Rogue Gloves
                { type: 'hand', id: 10, weight: 1 }, // Epic Knight Gauntlets,
            ]
        },
        'drops': [
            { type: 'top', id: 10, weight: 1 }, // Epic Knight Body,
        ]
    },
    {
        'enemyID': 6,
        'name': 'Leopard',
        'radius': 14,
        'spriteName': 'leopard',
        'type': 'earth',
        'spine': true,
        'spineTwoAttacks': true,
        'imgName': 'enemy_leopard.png',
        'tiledName': 'Leopard',
        'respawnCooldown': 90,
        // 'scoreOnKill': 125,
        'scoreOnKill': 180,
        // 'speed': 90,
        // 'speed': 105,
        'speed': 100,
        'hp': 90,
        label: 'Lvl 1 Slime',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        'proximityRadius': 150,
        'shootingRange': 55,
        'spells': [401],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'trialDropChance': {
            baseChance: 0.01,
            dropTable: [
                { id: 7, weight: 50 },
                { id: 10, weight: 50 },
            ]
        },
        'dropTable': {
            starter: [
                { type: 'mana', id: 1, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 5, weight: 20, manaQuantity: 40 },
                { type: 'spell', id: 1, weight: 10, manaQuantity: 40 }, // Rare Fireball
                { type: 'spell', id: 5, weight: 10, manaQuantity: 40 }, // Rare Ice Bolt
                { type: 'spell', id: 10, weight: 10, manaQuantity: 40 }, // Rare Wind Bolt
                { type: 'spell', id: 26, weight: 10, manaQuantity: 40 }, // Rare Triple Fireball
                { type: 'spell', id: 33, weight: 10, manaQuantity: 40 }, // Rare Healing Circle
                { type: 'spell', id: 208, weight: 10, manaQuantity: 40 }, // Rare Sword
                { type: 'spell', id: 214, weight: 5 }, // Uncommon Dagger
                { type: 'spell', id: 215, weight: 5 }, // Rare Dagger
                { type: 'spell', id: 302, weight: 10, manaQuantity: 60 }, // Rare Dash
                { type: 'spell', id: 307, weight: 10, manaQuantity: 60 }, // Uncommon Charge
                { type: 'spell', id: 308, weight: 10, manaQuantity: 60 }, // Rare Charge,
                { type: 'body', id: 3, weight: 5 }, // Uncommon Rogue Gloves
                { type: 'top', id: 3, weight: 5 }, // Uncommon Rogue Hat
                { type: 'hand', id: 3, weight: 5 }, // Uncommon Rogue Gloves ,
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
            ],
            bronze: [
                { type: 'spell', id: 227, weight: 5 }, // Rare Spear
                { type: 'body', id: 9, weight: 1 }, // Epic Rogue Body,
                { type: 'top', id: 9, weight: 1 }, // Epic Rogue Hat,
                { type: 'hand', id: 9, weight: 1 }, // Epic Rogue Gloves,
            ],
            silver: [
                { type: 'body', id: 9, weight: 1 }, // Epic Rogue Body,
                { type: 'top', id: 9, weight: 1 }, // Epic Rogue Hat,
                { type: 'hand', id: 9, weight: 1 }, // Epic Rogue Gloves,
            ],
            gold: [
                { type: 'body', id: 9, weight: 1 }, // Epic Rogue Body,
                { type: 'top', id: 9, weight: 1 }, // Epic Rogue Hat,
                { type: 'hand', id: 9, weight: 1 }, // Epic Rogue Gloves,
            ],
            platinum: [
                { type: 'body', id: 9, weight: 1 }, // Epic Rogue Body,
                { type: 'top', id: 9, weight: 1 }, // Epic Rogue Hat,
                { type: 'hand', id: 9, weight: 1 }, // Epic Rogue Gloves,
            ]
        },
        'drops': [
            { type: 'hand', id: 9, weight: 1 }, // Epic Rogue Gloves,
        ]
    },
    {
        'enemyID': 7,
        'name': 'Critter',
        'type': 'air',
        'radius': 7,
        'spriteName': 'woodlandWhelp',
        'imgName': 'enemy_woodlandWhelp.png',
        'tiledName': 'WoodlandWhelp',
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 90,
        // 'scoreOnKill': 100,
        'scoreOnKill': 135,
        'speed': 45,
        'hp': 300,
        label: 'Lvl 1 Critter',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        'proximityRadius': 150,
        'shootingRange': 125,
        'spells': [402],
        'ai': { canMove: true, followTarget: false, lookAtTarget: true },
        'trialDropChance': {
            baseChance: 0.01,
            dropTable: [
                { id: 7, weight: 50 },
                { id: 10, weight: 50 },
            ]
        },
        'dropTable': {
            starter: [
                { type: 'mana', id: 1, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 5, weight: 20, manaQuantity: 40 },
                { type: 'spell', id: 6, weight: 10, manaQuantity: 40 }, // Uncommon Wind Shot
                { type: 'spell', id: 10, weight: 10, manaQuantity: 40 }, // Rare Wind Bolt, // Rare Wind Bolt
                { type: 'spell', id: 33, weight: 10, manaQuantity: 40 }, // Rare healing Circle
                { type: 'spell', id: 39, weight: 10, manaQuantity: 40 }, // Rare Triple Wind Shot
                { type: 'spell', id: 55, weight: 10, manaQuantity: 40 }, // Rare Double Wind Bolt
                { type: 'spell', id: 312, weight: 15, manaQuantity: 40 }, // Rare Stealth
                { type: 'body', id: 2, weight: 5 }, // Uncommon Wizard Body
                { type: 'body', id: 3, weight: 5 }, // Uncommon Rogue Body
                { type: 'body', id: 4, weight: 5 }, // Uncommon Knight Body
                { type: 'hand', id: 2, weight: 5 }, // Uncommon Wizard Gloves,
                { type: 'hand', id: 3, weight: 5 }, // Uncommon Rogue Gloves ,
                { type: 'hand', id: 4, weight: 5 }, // Uncommon Knight Gauntlets,
                { type: 'top', id: 2, weight: 5 }, // Uncommon Wizard Hat
                { type: 'top', id: 3, weight: 5 }, // Uncommon Rogue Hat,
                { type: 'top', id: 4, weight: 5 }, // Uncommon Knight Helmet
                { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                { type: 'top', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'top', id: 7, weight: 10 }, // Rare Knight Armor,
                { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gauntlets
            ],
            bronze: [
                { type: 'body', id: 8, weight: 1 }, // Epic Wizard Body,
                { type: 'body', id: 9, weight: 1 }, // Epic Rogue Body,
                { type: 'body', id: 10, weight: 1 }, // Epic Knight Body,
            ],
            silver: [
                { type: 'top', id: 8, weight: 1 }, // Epic Wizard Hat,
                { type: 'top', id: 9, weight: 1 }, // Epic Rogue Hat,
                { type: 'top', id: 10, weight: 1 }, // Epic Knight Helm,
            ],
            gold: [
                { type: 'hand', id: 8, weight: 1 }, // Epic Wizard Gloves,
                { type: 'hand', id: 9, weight: 1 }, // Epic Rogue Gloves,
                { type: 'hand', id: 10, weight: 1 }, // Epic Knight Gauntlets,
            ],
            platinum: [
                { type: 'body', id: 8, weight: 1 }, // Epic Wizard Body,
                { type: 'body', id: 9, weight: 1 }, // Epic Rogue Body,
                { type: 'body', id: 10, weight: 1 }, // Epic Knight Body,
            ]
        },
        'drops': [
            { type: 'body', id: 10, weight: 1 }, // Epic Knight Body,
        ]
    },
    {
        'enemyID': 8,
        'name': 'Dragon',
        'type': 'fire',
        // 'radius': 18,
        'radius': 27,
        'spriteName': 'dragonWhelp',
        'imgName': 'enemy_dragonWhelp.png',
        'tiledName': 'DragonWhelp',
        'spine': true,
        'shotDelay': true,
        'shotDelayLength': 400,
        'respawnCooldown': 90,
        'scoreOnKill': 230,
        'speed': 45,
        'hp': 330,
        label: 'Lvl 1 Slime',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        'proximityRadius': 150,
        'shootingRange': 125,
        'spells': [404],
        'ai': { canMove: true, followTarget: false, lookAtTarget: true, predictLocation: true },
        'trialDropChance': {
            baseChance: 0.01,
            dropTable: [
                { id: 9, weight: 50 },
            ]
        },
        'dropTable': {
            starter: [
                { type: 'mana', id: 1, weight: 20, manaQuantity: 80 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 80 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 80 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 80 },
                { type: 'mana', id: 5, weight: 20, manaQuantity: 80 },
                { type: 'spell', id: 1, weight: 1, manaQuantity: 80 }, // Rare Fireball
                { type: 'spell', id: 2, weight: 10, manaQuantity: 80 }, // Epic Meteor
                { type: 'spell', id: 34, weight: 10, manaQuantity: 80 }, // Epic Healing Circle
                { type: 'spell', id: 216, weight: 5 }, // Epic Dagger
                { type: 'spell', id: 217, weight: 2 }, // Legendary Dagger
                { type: 'spell', id: 44, weight: 10, manaQuantity: 40 }, // Epic Double Fireball
                { type: 'spell', id: 51, weight: 10, manaQuantity: 40 }, // Epic Fireball
                { type: 'body', id: 5, weight: 5 }, // Rare Wizard Robe
                { type: 'body', id: 6, weight: 5 }, // Rare Rogue Garments
                { type: 'body', id: 7, weight: 5 }, // Rare Knght Armor
                { type: 'body', id: 8, weight: 10 }, // Epic Wizard Robe
                { type: 'body', id: 9, weight: 10 }, // Epic Rogue Garments
                { type: 'body', id: 10, weight: 10 }, // Epic Knight Armor
                { type: 'top', id: 5, weight: 5 }, // Rare Wizard Hat
                { type: 'top', id: 6, weight: 5 }, // Rare Rogue Hood
                { type: 'top', id: 7, weight: 5 }, // Rare Knight Helm
                { type: 'top', id: 8, weight: 10 }, // Epic Wizard Hat
                { type: 'top', id: 9, weight: 10 }, // Epic Rogue Hood
                { type: 'top', id: 10, weight: 10 }, // Epic Knight Helm
                { type: 'hand', id: 5, weight: 5 }, // Rare Wizard Gloves
                { type: 'hand', id: 6, weight: 5 }, // Rare Rogue Garments
                { type: 'hand', id: 7, weight: 5 }, // Rare Knight Gauntlets
                { type: 'hand', id: 8, weight: 10 }, // Epic Wizard Gloves
                { type: 'hand', id: 9, weight: 10 }, // Epic Rogue Gauntlets
                { type: 'hand', id: 10, weight: 10 }, // Epic Knight Gauntlets
                { type: 'body', id: 11, weight: 2 }, // Legendary Wizard Body
                { type: 'body', id: 12, weight: 2 }, // Legendary Rogue Body
                { type: 'body', id: 13, weight: 2 }, // Lgendary Knight Armor
                { type: 'top', id: 11, weight: 2 }, // Legendary Wizard Hat
                { type: 'top', id: 12, weight: 2 }, // Legendary Rogue Hood
                { type: 'top', id: 13, weight: 2 }, // Legendary Knight Helm
                { type: 'hand', id: 11, weight: 2 }, // Legendary Wizard Gloves
                { type: 'hand', id: 12, weight: 2 }, // Legendary Rogue Gauntlets
                { type: 'hand', id: 13, weight: 2 }, // Legendary Knight Gauntlets
            ],
            bronze: [
                { type: 'spell', id: 228, weight: 5 }, // Epic Spear
                { type: 'body', id: 11, weight: 2 }, // Legendary Wizard Body
                { type: 'body', id: 12, weight: 2 }, // Legendary Rogue Body
                { type: 'body', id: 13, weight: 2 }, // Lgendary Knight Armor
            ],
            silver: [
                { type: 'top', id: 11, weight: 2 }, // Legendary Wizard Hat
                { type: 'top', id: 12, weight: 2 }, // Legendary Rogue Hood
                { type: 'top', id: 13, weight: 2 }, // Legendary Knight Helm
            ],
            gold: [
                { type: 'hand', id: 11, weight: 2 }, // Legendary Wizard Gloves
                { type: 'hand', id: 12, weight: 2 }, // Legendary Rogue Gauntlets
                { type: 'hand', id: 13, weight: 2 }, // Legendary Knight Gauntlets
            ],
            platinum: [
                { type: 'spell', id: 52, weight: 10, manaQuantity: 50 }, // Legendary Fireball
            ]
        },
        'drops': [
            { type: 'hand', id: 11, weight: 2 }, // Legendary Wizard Gloves
            { type: 'hand', id: 12, weight: 2 }, // Legendary Rogue Gauntlets
            { type: 'hand', id: 13, weight: 2 }, // Legendary Knight Gauntlets
        ]
    },
    {
        'enemyID': 9,
        'name': 'Cannon',
        'radius': 7,
        'type': 'fire',
        'spriteName': 'cannon',
        'imgName': 'enemy_cannon.png',
        'tiledName': 'Cannon',
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 90,
        'scoreOnKill': 100,
        'speed': 0,
        'hp': 100,
        label: 'Lvl 1 Slime',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        'proximityRadius': 150,
        'shootingRange': 140,
        'spells': [405],
        'ai': { canMove: false, followTarget: false, lookAtTarget: true },
        'trialDropChance': {
            baseChance: 0.01,
            dropTable: [
                { id: 8, weight: 50 },
            ]
        },
        'dropTable': {
            starter: [
                { type: 'spell', id: 1, weight: 10, manaQuantity: 30 }, // Rare Fireball
                { type: 'spell', id: 5, weight: 10, manaQuantity: 30 }, // Rare Ice Bolt
                { type: 'spell', id: 10, weight: 10, manaQuantity: 30 }, // Rare Wind Bolt
                { type: 'spell', id: 33, weight: 10, manaQuantity: 30 }, // Rare Healing Circle
                { type: 'spell', id: 208, weight: 20, manaQuantity: 30 }, // Rare Sword
                { type: 'mana', id: 1, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 5, weight: 20, manaQuantity: 30 },
                { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                { type: 'top', id: 5, weight: 10 }, // Rare Wizard Hat,
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Hat,
                { type: 'top', id: 7, weight: 10 }, // Rare Knight Hat,
                { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gauntlets
            ],
            bronze: [
                { type: 'spell', id: 227, weight: 5 }, // Rare Spear
                { type: 'body', id: 8, weight: 1 }, // Epic Wizard Body,
                { type: 'body', id: 9, weight: 1 }, // Epic Rogue Body,
                { type: 'body', id: 10, weight: 1 }, // Epic Knight Body,
            ],
            silver: [
                { type: 'top', id: 8, weight: 1 }, // Epic Wizard Hat,
                { type: 'top', id: 9, weight: 1 }, // Epic Rogue Hat,
                { type: 'top', id: 10, weight: 1 }, // Epic Knight Helm,
            ],
            gold: [
                { type: 'hand', id: 8, weight: 1 }, // Epic Wizard Gloves,
                { type: 'hand', id: 9, weight: 1 }, // Epic Rogue Gloves,
                { type: 'hand', id: 10, weight: 1 }, // Epic Knight Gauntlets,
            ],
            platinum: [
                { type: 'spell', id: 44, weight: 20, manaQuantity: 45 }, // Epic Fireball
            ]
        },
        'drops': [
            { type: 'body', id: 8, weight: 1 }, // Epic Wizard Body,
            { type: 'body', id: 9, weight: 1 }, // Epic Rogue Body,
            { type: 'body', id: 10, weight: 1 }, // Epic Knight Body,
            { type: 'top', id: 8, weight: 1 }, // Epic Wizard Hat,
            { type: 'top', id: 9, weight: 1 }, // Epic Rogue Hat,
            { type: 'top', id: 10, weight: 1 }, // Epic Knight Helm,
            { type: 'hand', id: 8, weight: 1 }, // Epic Wizard Gloves,
            { type: 'hand', id: 9, weight: 1 }, // Epic Rogue Gloves,
            { type: 'hand', id: 10, weight: 1 }, // Epic Knight Gauntlets,
        ]
    },
    {
        'enemyID': 10,
        'name': 'Dragon',
        'boss': true,
        'type': 'fire',
        'radius': 42,
        'spriteName': 'dragonBig',
        'imgName': 'enemy_dragonBoss.png',
        'tiledName': 'DragonBig',
        'spine': true,
        'shotDelay': true,
        'shotDelayLength': 400,
        'respawnCooldown': 300,
        'scoreOnKill': 2000,
        // 'speed': 45,
        'speed': 30,
        'hp': 3000,
        label: 'Lvl 1 Slime',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        // 'proximityRadius': 150,
        // 'shootingRange': 145,
        'proximityRadius': 400,
        'shootingRange': 375,
        'spells': [407, 406, 416, 423],
        // 'spells': [416],
        'noGravity': true,
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        // 'alwaysDrops': [{ type: 'spell', id: 207, weight: -1, manaQuantity: 50 }],
        'numberOfDrops': 2,
        'dropTable': {
            starter: [
                { type: 'spell', id: 36, weight: 10, manaQuantity: 50 }, // Legendary Meteor
                { type: 'spell', id: 27, weight: 10, manaQuantity: 50 }, // Legendary Quintuple Fireball
                { type: 'spell', id: 45, weight: 10, manaQuantity: 50 }, // Legendary Double Fireball
                { type: 'spell', id: 52, weight: 10, manaQuantity: 50 }, // Legendary Fireball
                { type: 'body', id: 11, weight: 10 }, // Legendary Wizard Robe
                { type: 'body', id: 12, weight: 10 }, // Legendary Rogue Garments
                { type: 'body', id: 13, weight: 10 }, // Legendary Knight Armor
                { type: 'top', id: 11, weight: 10 }, // Legendary Wizard Hat
                { type: 'top', id: 12, weight: 10 }, // Legendary Rogue Hood
                { type: 'top', id: 13, weight: 10 }, // Legendary Knight Helm
                { type: 'hand', id: 11, weight: 10 }, // Legendary Wizard Gloves
                { type: 'hand', id: 12, weight: 10 }, // Legendary Rogue Garments
                { type: 'hand', id: 13, weight: 10 }, // Legendary Knight Gauntlets
            ],
            bronze: [
                { type: 'spell', id: 207, weight: 10, manaQuantity: 50 }, // Legendary Moonblade
            ],
            silver: [
                { type: 'spell', id: 207, weight: 10, manaQuantity: 50 }, // Legendary Moonblade
            ],
            gold: [
                { type: 'spell', id: 306, weight: 10, manaQuantity: 50 }, // Legendary Teleport
            ],
            platinum: [
                { type: 'spell', id: 207, weight: 10, manaQuantity: 50 }, // Legendary Moonblade
            ]
        },
        'drops': []
    },
    {
        'enemyID': 12,
        'name': 'King Leopard',
        'boss': true,
        'radius': 36,
        'spriteName': 'bossLeopard',
        'imgName': 'enemy_leopard.png',
        'tiledName': 'KingLeopard',
        'spine': true,
        'type': 'earth',
        'respawnCooldown': 90,
        'scoreOnKill': 750,
        'speed': 100,
        'hp': 500,
        label: 'Lvl 1 Slime',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        'proximityRadius': 150,
        'shootingRange': 70,
        'spells': [408],
        'noGravity': true,
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'trialDropChance': {
            baseChance: 0.45,
            dropTable: [
                { id: 7, weight: 50 },
                { id: 10, weight: 50 },
            ]
        },
        'numberOfDrops': 2,
        'dropTable': {
            starter: [
                { type: 'mana', id: 1, weight: 20, manaQuantity: 60 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 60 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 60 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 60 },
                { type: 'spell', id: 8, weight: 10, manaQuantity: 30 }, // Legendary Gravity Bolt
                { type: 'spell', id: 214, weight: 5 }, // Uncommon Dagger
                { type: 'spell', id: 215, weight: 5 }, // Rare Dagger
                { type: 'spell', id: 33, weight: 10, manaQuantity: 30 }, // Rare Healing Circle
                { type: 'spell', id: 202, weight: 10, manaQuantity: 30 }, // Epic Ice Katana
                { type: 'spell', id: 203, weight: 10, manaQuantity: 30 }, // Epic Wind Katana
                { type: 'spell', id: 309, weight: 15, manaQuantity: 60 }, // Epic Charge
                { type: 'spell', id: 303, weight: 15, manaQuantity: 60 }, // Epic Dash
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'body', id: 9, weight: 2 }, // Rare Rogue Body,
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'top', id: 9, weight: 2 }, // Epic Rogue Body,
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
                { type: 'hand', id: 9, weight: 2 }, // Epic Rogue Gloves,
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: [
                { type: 'spell', id: 202, weight: 10, manaQuantity: 30 }, // Epic Ice Katana
                { type: 'spell', id: 203, weight: 10, manaQuantity: 30 }, // Epic Wind Katana
            ]
        },
        'drops': [
            { type: 'hand', id: 9, weight: 2 }, // Epic Rogue Gloves,
        ]
    },
    {
        'enemyID': 13,
        'name': 'Critter Boss',
        'boss': true,
        'type': 'air',
        'radius': 30,
        'spriteName': 'bossWoodland',
        'imgName': 'enemy_woodlandWhelp.png',
        'tiledName': 'WoodlandWhelpBoss',
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 300,
        'scoreOnKill': 750,
        'speed': 45,
        'hp': 400,
        label: 'Lvl 1 Slime',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        'proximityRadius': 300,
        'shootingRange': 260,
        'spells': [413],
        'ai': { canMove: true, followTarget: false, lookAtTarget: true },
        'trialDropChance': {
            baseChance: 0.45,
            dropTable: [
                { id: 7, weight: 50 },
                { id: 10, weight: 50 },
            ]
        },
        'numberOfDrops': 3,
        'dropTable': {
            starter: [
                { type: 'mana', id: 1, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 5, weight: 20, manaQuantity: 40 },
                { type: 'spell', id: 6, weight: 10, manaQuantity: 40 }, // Uncommon Wind Shot
                { type: 'spell', id: 10, weight: 10, manaQuantity: 40 }, // Rare Wind Bolt
                { type: 'spell', id: 33, weight: 10, manaQuantity: 40 }, // Rare Healing Circle
                { type: 'spell', id: 39, weight: 10, manaQuantity: 40 }, // Rare Triple Wind Shot
                { type: 'spell', id: 312, weight: 15, manaQuantity: 40 }, // Rare Stealth
                { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                { type: 'top', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'top', id: 7, weight: 10 }, // Rare Knight Armor,
                { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gauntlets
            ],
            bronze: [
                { type: 'top', id: 8, weight: 2 }, // Epic Wizard Top,
                { type: 'top', id: 9, weight: 2 }, // Epic Rogue Top,
                { type: 'top', id: 10, weight: 2 }, // Epic Knight Top,
            ],
            silver: [
                { type: 'body', id: 8, weight: 2 }, // Epic Wizard Body,
                { type: 'body', id: 9, weight: 2 }, // Epic Rogue Body,
                { type: 'body', id: 10, weight: 2 }, // Epic Knight Body,
            ],
            gold: [
                { type: 'hand', id: 8, weight: 2 }, // Epic Wizard Hand,
                { type: 'hand', id: 9, weight: 2 }, // Epic Rogue Hand,
                { type: 'hand', id: 10, weight: 2 }, // Epic Knight Hand,
            ],
            platinum: [
                { type: 'spell', id: 56, weight: 10, manaQuantity: 40 }, // Epic Double Wind Bolt
            ]
        },
        'drops': [
            { type: 'top', id: 8, weight: 2 }, // Epic Wizard Body,
            { type: 'top', id: 9, weight: 2 }, // Epic Rogue Body,
            { type: 'top', id: 10, weight: 2 }, // Epic Knight Body,
            { type: 'hand', id: 8, weight: 2 }, // Epic Wizard Gloves,
            { type: 'hand', id: 9, weight: 2 }, // Epic Rogue Gloves,
            { type: 'hand', id: 10, weight: 2 }, // Epic Knight Gauntlets
        ]
    },
    {
        'enemyID': 14,
        'name': 'Giant Charlie',
        'boss': true,
        'type': 'dark',
        'radius': 50,
        'spriteName': 'bossCharlie',
        'imgName': 'enemy_evilCreature.png',
        'tiledName': 'CreatureBoss',
        'spine': true,
        'spineTwoAttacks': true,
        'respawnCooldown': 90,
        'scoreOnKill': 1000,
        // 'speed': 70,
        'noWaterSlow': true,
        'speed': 80,
        'hp': 450,
        label: 'Lvl 1 Slime',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        'proximityRadius': 250,
        'shootingRange': 80,
        'spells': [414],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'numberOfDrops': 3,
        'dropTable': {
            starter: [
                { type: 'mana', id: 1, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 30 },
                { type: 'spell', id: 202, weight: 10, manaQuantity: 30 }, // Epic Ice Katana
                { type: 'spell', id: 203, weight: 10, manaQuantity: 30 }, // Epic Wind Katana
                { type: 'body', id: 8, weight: 2 }, // Rare Wizard Body
                { type: 'body', id: 9, weight: 2 }, // Rare Rogue Body
                { type: 'body', id: 10, weight: 2 }, // Rare Knight Body
                { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gauntlets
                { type: 'spell', id: 33, weight: 10, manaQuantity: 30 }, // Rare Healing Circle
                { type: 'spell', id: 302, weight: 10, manaQuantity: 60 }, // Rare Dash
                { type: 'spell', id: 307, weight: 10, manaQuantity: 60 }, // Uncommon Charge
                { type: 'spell', id: 312, weight: 10, manaQuantity: 60 }, // Rare Stealth
                { type: 'top', id: 8, weight: 10 }, // Epic Wizard Hat
                { type: 'top', id: 9, weight: 10 }, // Epic Rogue Hat
                { type: 'top', id: 10, weight: 10 }, // Epic Knight Helm
                { type: 'hand', id: 8, weight: 10 }, // Epic Wizard Hat
                { type: 'hand', id: 9, weight: 10 }, // Epic Rogue Hat
                { type: 'hand', id: 10, weight: 10 }, // Epic Knight Helm
                { type: 'spell', id: 8, weight: 10, manaQuantity: 30 }, // Legendary Gravity Ball
                { type: 'spell', id: 216, weight: 5 }, // Epic Dagger
                { type: 'spell', id: 217, weight: 2 }, // Legendary Dagger
            ],
            bronze: [
                { type: 'spell', id: 202, weight: 10, manaQuantity: 30 }, // Epic Ice Katana
                { type: 'spell', id: 203, weight: 10, manaQuantity: 30 }, // Epic Wind Katana
            ],
            silver: [
                { type: 'body', id: 11, weight: 2 }, // Legendary Wizard Body
                { type: 'body', id: 12, weight: 2 }, // Legendary Rogue Body
                { type: 'body', id: 13, weight: 2 }, // Lgendary Knight Armor
            ],
            gold: [
                { type: 'top', id: 11, weight: 2 }, // Legendary Wizard Hat
                { type: 'top', id: 12, weight: 2 }, // Legendary Rogue Hat
                { type: 'top', id: 13, weight: 2 }, // Legenary Knight Helm
            ],
            platinum: [
                { type: 'hand', id: 11, weight: 2 }, // Legendary Wizard Gloves
                { type: 'hand', id: 12, weight: 2 }, // Legendary Rogue Gloves
                { type: 'hand', id: 13, weight: 2 }, // Legendary Knight Guantlets
            ]
        },
        'drops': [
            { type: 'spell', id: 8, weight: 10, manaQuantity: 30 }, // Legendary Gravity Ball
        ]
    },
    {
        'enemyID': 15,
        'name': 'Baby Dragon',
        'type': 'fire',
        'radius': 19,
        'spriteName': 'babyDragonWhelp',
        'imgName': 'enemy_dragonWhelp.png',
        'tiledName': 'BabyDragonWhelp',
        'spine': true,
        'shotDelay': true,
        'shotDelayLength': 400,
        'respawnCooldown': 90,
        'scoreOnKill': 125,
        'speed': 45,
        'hp': 150,
        label: 'Lvl 1 Baby Dragon',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        'proximityRadius': 150,
        'shootingRange': 125,
        'spells': [415],
        'ai': { canMove: true, followTarget: false, lookAtTarget: true },
        'trialDropChance': {
            baseChance: 0.01,
            dropTable: [
                { id: 9, weight: 50 },
            ]
        },
        'dropTable': {
            starter: [
                { type: 'mana', id: 1, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 5, weight: 20, manaQuantity: 40 },
                { type: 'spell', id: 1, weight: 1, manaQuantity: 40 }, // Rare Fireball
                { type: 'spell', id: 2, weight: 10, manaQuantity: 40 }, // Epic Meteor
                { type: 'spell', id: 34, weight: 10, manaQuantity: 40 }, // Epic Healing Circle
                { type: 'spell', id: 43, weight: 10, manaQuantity: 40 }, // Rare Double Fireball
                { type: 'spell', id: 44, weight: 10, manaQuantity: 40 }, // Epic Double Fireball
                { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                { type: 'body', id: 8, weight: 10 }, // Epic Wizard Body
                { type: 'body', id: 9, weight: 10 }, // Epic Rogue Body
                { type: 'body', id: 10, weight: 10 }, // Epic Knight Body
                { type: 'top', id: 5, weight: 10 }, // Rare Wizard Hat
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Hat,
                { type: 'top', id: 7, weight: 10 }, // Rare Knight AHat,
                { type: 'top', id: 8, weight: 10 }, // Epic Wizard Hat
                { type: 'top', id: 9, weight: 10 }, // Epic Rogue Hat
                { type: 'top', id: 10, weight: 10 }, // Epic Knight Helmet
                { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gauntlets
                { type: 'hand', id: 8, weight: 10 }, // Epic Wizard Gloves
                { type: 'hand', id: 9, weight: 10 }, // Epic Rogue Gloves
                { type: 'hand', id: 10, weight: 10 }, // Epic Knight Gauntlets
            ],
            bronze: [
                { type: 'body', id: 11, weight: 2 }, // Legendary Wizard Body
                { type: 'body', id: 12, weight: 2 }, // Legendary Rogue Body
                { type: 'body', id: 13, weight: 2 }, // Lgendary Knight Armor
            ],
            silver: [
                { type: 'top', id: 11, weight: 2 }, // Legendary Wizard Hat
                { type: 'top', id: 12, weight: 2 }, // Legendary Rogue Hood
                { type: 'top', id: 13, weight: 2 }, // Legendary Knight Helm
            ],
            gold: [
                { type: 'hand', id: 11, weight: 2 }, // Legendary Wizard Gloves
                { type: 'hand', id: 12, weight: 2 }, // Legendary Rogue Gauntlets
                { type: 'hand', id: 13, weight: 2 }, // Legendary Knight Gauntlets
            ],
            platinum: [
                { type: 'spell', id: 52, weight: 2, manaQuantity: 50 }, // Legendary Fireball
            ]
        },
        'drops': []
    },
    {
        'enemyID': 16,
        'name': 'Mini Charlie',
        'type': 'dark',
        'radius': 9,
        'spriteName': 'miniCharlie',
        'imgName': 'enemy_evilCreature.png',
        'tiledName': 'TinyCreature',
        'spine': true,
        'spineTwoAttacks': true,
        'respawnCooldown': 90,
        // 'respawnCooldown': 5,
        'scoreOnKill': 50,
        'speed': 50,
        'hp': 300,
        label: 'Lvl 1 Mini Charlie',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        iconColor: '#00ff00',
        'proximityRadius': 300,
        'shootingRange': 35,
        'spells': [417],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'trialDropChance': {
            baseChance: 0.01,
            dropTable: [
                { id: 11, weight: 50 },
            ]
        },
        'dropTable': {
            starter: [
                { type: 'spell', id: 8, weight: 10, manaQuantity: 30 }, // Legendary Gravity Bolt
                { type: 'spell', id: 33, weight: 10, manaQuantity: 30 }, // Rare Healing Circle
                { type: 'spell', id: 302, weight: 10, manaQuantity: 60 }, // Rare Dash
                { type: 'spell', id: 307, weight: 10, manaQuantity: 60 }, // Uncommon Charge
                { type: 'spell', id: 312, weight: 10, manaQuantity: 60 }, // Rare Stealth
                { type: 'mana', id: 1, weight: 600, manaQuantity: 15 },
                { type: 'mana', id: 2, weight: 600, manaQuantity: 15 },
                { type: 'mana', id: 3, weight: 600, manaQuantity: 15 },
                { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                { type: 'body', id: 8, weight: 2 }, // Rare Wizard Body,
                { type: 'body', id: 9, weight: 2 }, // Rare Rogue Body,
                { type: 'body', id: 10, weight: 2 }, // Rare Knight Body,
                { type: 'top', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'top', id: 7, weight: 10 }, // Rare Knight Armor,
                { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gauntlets
            ],
            bronze: [
                { type: 'hand', id: 8, weight: 2 }, // Epic Wizard Gloves,
                { type: 'hand', id: 9, weight: 2 }, // Epic Rogue Gloves,
                { type: 'hand', id: 10, weight: 2 }, // Epic Knight Gauntlets,
            ],
            silver: [
                { type: 'top', id: 8, weight: 2 }, // Epic Wizard Body,
                { type: 'top', id: 9, weight: 2 }, // Epic Rogue Body
                { type: 'top', id: 10, weight: 2 }, // Epic Knight Body
            ],
            gold: [
                { type: 'spell', id: 203, weight: 10, manaQuantity: 30 }, // Epic Wind Katana
            ],
            platinum: [
                { type: 'spell', id: 202, weight: 10, manaQuantity: 30 }, // Epic Ice Katana
            ]
        },
        'drops': [
            { type: 'mana', id: 1, weight: 600, manaQuantity: 15 },
        ]
    },
    {
        'enemyID': 17,
        'name': 'Melee Slime',
        // 'radius': 12,
        'radius': 20,
        'spriteName': 'slimeMelee',
        'imgName': 'enemy_slime.png',
        // 'spineTwoAttacks': true,
        'type': 'air',
        'tiledName': 'SlimeMelee',
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 25,
        'scoreOnKill': 75,
        'speed': 55,
        'hp': 45,
        label: 'Lvl 1 Slime',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        'randomTint': true,
        'proximityRadius': 100,
        'shootingRange': 100,
        'spells': [418, 422],
        // spells: [418],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'trialDropChance': {
            baseChance: 0.01,
            dropTable: [
                { id: 5, weight: 50 },
                { id: 6, weight: 50 }
            ]
        },
        'dropTable': {
            starter: [
                // { type: 'spell', id: 6, weight: 20, manaQuantity: 40 }, // Uncommon Wind Shot
                // { type: 'spell', id: 11, weight: 20, manaQuantity: 40 }, // Uncommon Fire Shot
                { type: 'spell', id: 214, weight: 5 }, // Uncommon Dagger
                { type: 'spell', id: 85, weight: 10, manaQuantity: 40 }, // Uncommon Heal Self
                { type: 'spell', id: 301, weight: 10, manaQuantity: 40 }, // Uncommon Dash
                // { type: 'spell', id: 307, weight: 10, manaQuantity: 40 }, // Uncommon Charge
                { type: 'spell', id: 311, weight: 10, manaQuantity: 40 }, // Uncommon Stealth
                // { type: 'mana', id: 1, weight: 20, manaQuantity: 30 },
                // { type: 'mana', id: 2, weight: 20, manaQuantity: 30 },
                // { type: 'mana', id: 3, weight: 20, manaQuantity: 30 },
                // { type: 'mana', id: 4, weight: 20, manaQuantity: 30 },
                { type: 'body', id: 2, weight: 6 }, // Uncommon Wizard Body
                { type: 'body', id: 3, weight: 6 }, // Uncommon Rogue Body,
                { type: 'body', id: 4, weight: 6 }, // Uncommon Knight Body,
                { type: 'top', id: 2, weight: 6 }, // Uncommon Wizard Hat,
                { type: 'top', id: 3, weight: 6 }, // Uncommon Rogue Hat,
                { type: 'top', id: 4, weight: 6 }, // Uncommon Knight Hat,
                { type: 'hand', id: 2, weight: 6 }, // Uncommon Wizard Hands,
                { type: 'hand', id: 3, weight: 6 }, // Uncommon Rogue Hands,
                { type: 'hand', id: 4, weight: 6 }, // Uncommon Knight Hands,
                { type: 'hand', id: 5, weight: 1 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 1 }, // Rare Rogue Gloves,
                { type: 'hand', id: 7, weight: 1 }, // Rare knight Gloves,
            ],
            bronze: [
                // { type: 'spell', id: 226, weight: 5 }, // Uncommon Spear
                { type: 'top', id: 5, weight: 1 }, // Rare Wizard Hat,
                { type: 'top', id: 6, weight: 1 }, // Rare Rogue Hat,
                { type: 'top', id: 7, weight: 1 }, // Rare Knight Hat,
            ],
            silver: [
                { type: 'body', id: 5, weight: 1 }, // Rare Wizard Body,
                { type: 'body', id: 6, weight: 1 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 1 }, // Rare Knight Body
            ],
            gold: [
                { type: 'hand', id: 5, weight: 1 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 1 }, // Rare Rogue Gloves,
                { type: 'hand', id: 7, weight: 1 }, // Rare knight Gloves,
            ],
            platinum: [
            // { type: 'spell', id: 308, weight: 10, manaQuantity: 60 }, // Rare Charge
            ]
        },
        'drops': []
    },
    {
        'enemyID': 18,
        'name': 'Starfish',
        'radius': 8,
        'spriteName': 'starfishNormal',
        // 'spriteName': 'cannon',
        'imgName': 'enemy_starfishNormal.png',
        'type': 'air',
        'tiledName': 'StarfishNormal',
        'spine': true,
        'shotDelay': true,
        //In seconds. make it 300 (5 min) after testing
        'respawnCooldown': 15,
        'scoreOnKill': 50,
        // 'scoreOnKill': 500,
        // 'scoreOnKill': 2000,
        // 'scoreOnKill': 500000,
        'speed': 0,
        'hp': 55,
        label: 'Lvl 1 Slime',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        // 'hp':1,
        // 'proximityRadius': null,
        'proximityRadius': 300,
        'shootingRange': 300,
        'spells': [424],
        'ai': { canMove: false, followTarget: false, lookAtTarget: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 97, weight: 10, manaQuantity: 30 }, // Uncommon Water Blast
                { type: 'spell', id: 98, weight: 10, manaQuantity: 40 }, // Rare Water Blast
                { type: 'spell', id: 600, weight: 10, manaQuantity: 30 }, // Uncommon Swimming Aura
                { type: 'spell', id: 601, weight: 5, manaQuantity: 40 }, // Rare Swimming Aura
                { type: 'mana', id: 2, weight: 80, manaQuantity: 30 },
            ],
            bronze: [
                { type: 'spell', id: 601, weight: 5, manaQuantity: 40 }, // Rare Swimming Aura
            ],
            silver: [
                { type: 'spell', id: 98, weight: 5, manaQuantity: 40 }, // Rare Water Blast
            ],
            gold: [
                { type: 'spell', id: 601, weight: 5, manaQuantity: 40 }, // Rare Swimming Aura
            ],
            platinum: [
                { type: 'spell', id: 98, weight: 5, manaQuantity: 40 }, // Rare Water Blast
            ]
        },
        'drops': [
            { type: 'spell', id: 98, weight: 5, manaQuantity: 40 }, // Rare Water Blast
        ]
    },
    {
        'enemyID': 19,
        'name': 'Ent',
        'radius': 16,
        'spriteName': 'ent_normal',
        // 'spriteName': 'cannon',
        'imgName': 'enemy_slime.png',
        'type': 'air',
        'tiledName': 'Ent',
        'spine': true,
        'shotDelay': true,
        //In seconds. make it 300 (5 min) after testing
        'respawnCooldown': 15,
        'scoreOnKill': 50,
        // 'scoreOnKill': 500,
        // 'scoreOnKill': 2000,
        // 'scoreOnKill': 500000,
        'speed': 80,
        'hp': 300,
        // 'hp':1,
        // 'proximityRadius': null,
        'proximityRadius': 100,
        'shootingRange': 100,
        'spells': [67, 82],
        // 'spells': [3],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'dropTable': {
            starter: [],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': [
        // { type: 'spell', id: 6, weight: 20, manaQuantity: 40 },
        ]
    },
    {
        'enemyID': 20,
        'name': 'Crab',
        'radius': 8,
        'spriteName': 'crabNormal',
        // 'spriteName': 'cannon',
        'imgName': 'enemy_crabNormal.png',
        'type': 'water',
        'tiledName': 'CrabNormal',
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 60,
        // 'respawnCooldown': 1,
        'scoreOnKill': 100,
        // 'scoreOnKill': 500,
        // 'scoreOnKill': 2000,
        // 'scoreOnKill': 500000,
        'speed': 80,
        'hp': 60,
        label: 'Lvl 1 Slime',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        // 'hp':1,
        // 'randomTint': true,
        // 'proximityRadius': null,
        'proximityRadius': 200,
        'shootingRange': 100,
        'spells': [426],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 600, weight: 5, manaQuantity: 30 }, // Uncommon Swimming Aura
                { type: 'spell', id: 601, weight: 3, manaQuantity: 40 }, // Rare Swimming Aura
                { type: 'spell', id: 85, weight: 5, manaQuantity: 40 }, // Uncommon Heal Self
                { type: 'spell', id: 86, weight: 5, manaQuantity: 40 }, // Rare Heal Self
                { type: 'spell', id: 32, weight: 5, manaQuantity: 40 }, // Uncommon Healing Circle
                { type: 'spell', id: 33, weight: 5, manaQuantity: 40 }, // Rare Healing Circle
                { type: 'spell', id: 218, weight: 5 }, // Uncommon Ice Sword
                { type: 'body', id: 2, weight: 6 }, // Uncommon Wizard Body
                { type: 'body', id: 3, weight: 6 }, // Uncommon Rogue Body,
                { type: 'body', id: 4, weight: 6 }, // Uncommon Knight Body,
                { type: 'body', id: 5, weight: 3 }, // Rare Wizard Body
                { type: 'body', id: 6, weight: 3 }, // Rare Rogue Body
                { type: 'body', id: 7, weight: 3 }, // Rare Knight Armor
                { type: 'top', id: 2, weight: 6 }, // Uncommon Wizard Hat,
                { type: 'top', id: 3, weight: 6 }, // Uncommon Rogue Hat,
                { type: 'top', id: 4, weight: 6 }, // Uncommon Knight Hat,
                { type: 'top', id: 5, weight: 3 }, // Rare Wizard Hat
                { type: 'top', id: 6, weight: 3 }, // Rare Rogue Hat
                { type: 'top', id: 7, weight: 3 }, // Rare Knight Helm
                { type: 'hand', id: 2, weight: 6 }, // Uncommon Wizard Hands,
                { type: 'hand', id: 3, weight: 6 }, // Uncommon Rogue Hands,
                { type: 'hand', id: 4, weight: 6 }, // Uncommon Knight Hands,
                { type: 'hand', id: 5, weight: 3 }, // Rare Wizard Gloves
                { type: 'hand', id: 6, weight: 3 }, // Rare Rogue Gauntlets
                { type: 'hand', id: 7, weight: 3 }, // Rare Knight Hands
                { type: 'mana', id: 2, weight: 40, manaQuantity: 30 },
                { type: 'mana', id: 4, weight: 40, manaQuantity: 30 },
            ],
            bronze: [
                { type: 'spell', id: 226, weight: 5 }, // Uncommon Spear
                { type: 'body', id: 8, weight: 1 }, // Epic Wizard Body,
                { type: 'body', id: 9, weight: 1 }, // Epic Rogue Body
                { type: 'body', id: 10, weight: 1 }, // Epic Knight Body,
            ],
            silver: [
                { type: 'top', id: 8, weight: 1 }, // Epic Wizard Hat
                { type: 'top', id: 9, weight: 1 }, // Epic Rogue Hat
                { type: 'top', id: 10, weight: 1 }, // Epic Knight Helm
            ],
            gold: [
                { type: 'hand', id: 8, weight: 1 }, // Epic Wizard Gloves
                { type: 'hand', id: 9, weight: 1 }, // Epic Rogue Gloves
                { type: 'hand', id: 10, weight: 1 }, // Epic Knight Gauntlets
            ],
            platinum: [
                { type: 'body', id: 8, weight: 1 }, // Epic Wizard Body,
                { type: 'body', id: 9, weight: 1 }, // Epic Rogue Body
                { type: 'body', id: 10, weight: 1 }, // Epic Knight Body,
            ]
        },
        'drops': [
            { type: 'spell', id: 600, weight: 5, manaQuantity: 30 }, // Uncommon Swimming Aura
        ]
    },
    {
        'enemyID': 21,
        'name': 'Aqua Flower',
        'radius': 8,
        'spriteName': 'flowerCreature',
        // 'spriteName': 'cannon',
        'imgName': 'enemy_flowerCreature.png',
        'type': 'air',
        'tiledName': 'FlowerCreature',
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 35,
        // 'respawnCooldown': 1,
        'scoreOnKill': 75,
        // 'scoreOnKill': 500,
        // 'scoreOnKill': 2000,
        // 'scoreOnKill': 500000,
        'speed': 0,
        'hp': 75,
        label: 'Lvl 1 Aqua Flower',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        // 'hp':1,
        // 'randomTint': true,
        // 'proximityRadius': null,
        'proximityRadius': 200,
        'shootingRange': 200,
        'spells': [441],
        'ai': { canMove: false, followTarget: false, lookAtTarget: true },
        'dropTable': {
            starter: [
                // { type: 'spell', id: 93, weight: 30, manaQuantity: 40 }, // Uncommon Bubbles
                // { type: 'spell', id: 11, weight: 20, manaQuantity: 40 }, // Uncommon Fire Shot
                { type: 'spell', id: 85, weight: 30, manaQuantity: 40 }, // Uncommon Heal Self
                // { type: 'spell', id: 600, weight: 20, manaQuantity: 30 }, // Uncommon Swimming Aura
                { type: 'spell', id: 218, weight: 5 }, // Uncommon Ice Sword
                { type: 'spell', id: 221, weight: 5 }, // Uncommon Ice Dagger
                { type: 'spell', id: 301, weight: 10, manaQuantity: 40 }, // Uncommon Dash
                // { type: 'spell', id: 307, weight: 10, manaQuantity: 40 }, // Uncommon Charge
                { type: 'spell', id: 311, weight: 10, manaQuantity: 40 }, // Uncommon Stealth,
                // { type: 'mana', id: 1, weight: 20, manaQuantity: 30 },
                // { type: 'mana', id: 2, weight: 20, manaQuantity: 30 },
                // { type: 'mana', id: 3, weight: 20, manaQuantity: 30 },
                // { type: 'mana', id: 4, weight: 20, manaQuantity: 30 },
                { type: 'body', id: 2, weight: 6 }, // Uncommon Wizard Body
                { type: 'body', id: 3, weight: 6 }, // Uncommon Rogue Body,
                { type: 'body', id: 4, weight: 6 }, // Uncommon Knight Body,
                { type: 'top', id: 2, weight: 6 }, // Uncommon Wizard Hat,
                { type: 'top', id: 3, weight: 6 }, // Uncommon Rogue Hat,
                { type: 'top', id: 4, weight: 6 }, // Uncommon Knight Hat,
                { type: 'hand', id: 2, weight: 6 }, // Uncommon Wizard Hands,
                { type: 'hand', id: 3, weight: 6 }, // Uncommon Rogue Hands,
                { type: 'hand', id: 4, weight: 6 }, // Uncommon Knight Hands,
                { type: 'hand', id: 5, weight: 1 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 1 }, // Rare Rogue Gloves
                { type: 'hand', id: 7, weight: 1 }, // Rare Knight Gloves,
            ],
            bronze: [
                // { type: 'spell', id: 226, weight: 5 }, // Uncommon Spear
                { type: 'hand', id: 5, weight: 1 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 1 }, // Rare Rogue Gloves
                { type: 'hand', id: 7, weight: 1 }, // Rare Knight Gloves,
            ],
            silver: [
                { type: 'body', id: 5, weight: 1 }, // Rare Wizard Body,
                { type: 'body', id: 6, weight: 1 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 1 }, // Rare Knight Body
            ],
            gold: [
                { type: 'hand', id: 5, weight: 1 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 1 }, // Rare Rogue Gauntlets,
                { type: 'hand', id: 7, weight: 1 }, // Rare Knight Gauntlets
            ],
            platinum: [
                { type: 'body', id: 5, weight: 1 }, // Rare Wizard Body,
                { type: 'body', id: 6, weight: 1 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 1 }, // Rare Knight Body
            ]
        },
        'drops': [
            { type: 'hand', id: 7, weight: 1 }, // Rare Knight Gloves,
        ]
    },
    {
        'enemyID': 22,
        'name': 'Melee Boss',
        'radius': 45,
        'radiusModifier': 0.5,
        'spriteName': 'meleeBoss',
        'imgName': 'enemy_meleeBoss.png',
        'type': 'dark',
        'tiledName': 'MeleeBoss',
        'spine': true,
        'shotDelay': true,
        'boss': true,
        noGravity: true,
        'respawnCooldown': 15,
        // 'respawnCooldown': 1,
        'scoreOnKill': 1500,
        // 'scoreOnKill': 500,
        // 'scoreOnKill': 2000,
        // 'scoreOnKill': 500000,
        'speed': 80,
        'hp': 2000,
        label: 'Lvl 1 Slime',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        // 'hp':1,
        // 'randomTint': true,
        // 'proximityRadius': null,
        'proximityRadius': 300,
        'shootingRange': 200,
        'spells': [427, 428, 431],
        // 'spells': [427],
        // 'spells': [63],
        'ai': { canMove: false, followTarget: true, lookAtTarget: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 213, weight: 6 }, // Legendary Darksword
                { type: 'spell', id: 73, weight: 12, manaQuantity: 80 }, // Legendary Dark Hole
                { type: 'spell', id: 72, weight: 12, manaQuantity: 50 }, // Epic Dark Hole
                { type: 'body', id: 17, weight: 6 },
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': [
            { type: 'spell', id: 213, weight: 6 }, // Legendary Darksword
        ]
    },
    {
        'enemyID': 23,
        'name': 'Hellhound',
        'radius': 24,
        'spriteName': 'hellhound',
        'imgName': 'enemy_hellhound.png',
        'type': 'air',
        'tiledName': 'Hellhound',
        'spine': true,
        // spineSkin: 'hellhound',
        // spineSkin: 'hellhound_boss',
        'shotDelay': true,
        'respawnCooldown': 15,
        // 'respawnCooldown': 1,
        'scoreOnKill': 300,
        // 'scoreOnKill': 500,
        // 'scoreOnKill': 2000,
        // 'scoreOnKill': 500000,
        'speed': 65,
        'hp': 300,
        label: 'Lvl 1 Slime',
        labelColor: '#00ff00',
        icon: 'enemy_slime.png',
        // 'hp':1,
        // 'randomTint': true,
        // 'proximityRadius': null,
        'proximityRadius': 300,
        'shootingRange': 300,
        'spells': [430, 429, 438],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 216, weight: 5 }, // Epic Dagger
                { type: 'spell', id: 217, weight: 2 }, // Legendary Dagger,
                { type: 'spell', id: 6, weight: 20, manaQuantity: 40 }, // Uncommon Wind Shot,
                { type: 'spell', id: 11, weight: 20, manaQuantity: 40 }, // Uncommon Fire Shot
                { type: 'spell', id: 85, weight: 30, manaQuantity: 40 }, // Uncommon Heal Self
                { type: 'spell', id: 201, weight: 20, manaQuantity: 40 }, // Uncommon Sword
                { type: 'spell', id: 301, weight: 10, manaQuantity: 40 }, // Uncommon Dash,
                { type: 'spell', id: 307, weight: 10, manaQuantity: 40 }, // Uncommon Charge,
                { type: 'spell', id: 311, weight: 10, manaQuantity: 40 }, // Uncommon Stealth
                { type: 'mana', id: 1, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 30 },
                { type: 'body', id: 2, weight: 6 }, // Uncommon Wizard Body
                { type: 'body', id: 3, weight: 6 }, // Uncommon Rogue Body,
                { type: 'body', id: 4, weight: 6 }, // Uncommon Knight Body,
                { type: 'top', id: 2, weight: 6 }, // Uncommon Wizard Hat,
                { type: 'top', id: 3, weight: 6 }, // Uncommon Rogue Hat,
                { type: 'top', id: 4, weight: 6 }, // Uncommon Knight Hat,
                { type: 'hand', id: 2, weight: 6 }, // Uncommon Wizard Hands,
                { type: 'hand', id: 3, weight: 6 }, // Uncommon Rogue Hands,
                { type: 'hand', id: 4, weight: 6 }, // Uncommon Knight Hands,
                { type: 'hand', id: 5, weight: 1 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 1 }, // Rare rogue gloves
                { type: 'hand', id: 7, weight: 1 }, // Rare knight Gloves,
            ],
            bronze: [
                { type: 'hand', id: 11, weight: 2 }, // Legendary Wizard Gloves
                { type: 'hand', id: 12, weight: 2 }, // Legendary Rogue Gauntlets
                { type: 'hand', id: 13, weight: 2 }, // Legendary Knight Gauntlets
            ],
            silver: [
                { type: 'body', id: 11, weight: 2 }, // Legendary Wizard Body
                { type: 'body', id: 12, weight: 2 }, // Legendary Rogue Body
                { type: 'body', id: 13, weight: 2 }, // Lgendary Knight Armor
            ],
            gold: [
                { type: 'top', id: 11, weight: 2 }, // Legendary Wizard Hat
                { type: 'top', id: 12, weight: 2 }, // Legendary Rogue Hood
                { type: 'top', id: 13, weight: 2 }, // Legendary Knight Helm
            ],
            platinum: [
                { type: 'spell', id: 91, weight: 30, manaQuantity: 40 }, // Legendary Fire Stream
            ]
        },
        'drops': [
            { type: 'hand', id: 7, weight: 1 }, // Rare knight Gloves,
        ]
    },
    {
        'enemyID': 24,
        'name': 'Dark Wizard Boss',
        'type': 'dark',
        'radius': 25,
        'spriteName': 'darkWizBoss',
        'imgName': 'enemy_darkWizBoss.png',
        'tiledName': 'DarkWizBoss',
        'spine': true,
        'shotDelay': true,
        'boss': true,
        'respawnCooldown': 90,
        'scoreOnKill': 200,
        'speed': 80,
        'hp': 800,
        'proximityRadius': 150,
        'shootingRange': 125,
        'spells': [435, 436, 437],
        'ai': { canMove: true, followTarget: false, lookAtTarget: true, predictLocation: true },
        'dropTable': {
            starter: [
                { type: 'hand', id: 8, weight: 10 }, // Epic Wizard Gloves
                { type: 'hand', id: 11, weight: 10 }, // Legendary Wizard Gloves
                { type: 'top', id: 8, weight: 10 }, // Epic Wizard Hat
                { type: 'top', id: 11, weight: 10 }, // Legendary Wizard hat
                { type: 'body', id: 8, weight: 10 }, // Epic Wizard Body
                { type: 'body', id: 11, weight: 10 }, // Legendary Wizard Body
                { type: 'spell', id: 92, weight: 10, manaQuantity: 60 }, // Legendary Triple Meteor
                { type: 'spell', id: 8, weight: 10, manaQuantity: 60 }, // Legendary Gravity Ball
                { type: 'spell', id: 29, weight: 10, manaQuantity: 60 }, // Epic Triple Gravity Bolt
            ],
            bronze: [
                { type: 'body', id: 11, weight: 10 }, // Legendary Wizard Body
            ],
            silver: [
                { type: 'top', id: 11, weight: 10 }, // Legendary Wizard hat
            ],
            gold: [
                { type: 'hand', id: 11, weight: 10 }, // Legendary Wizard Gloves
            ],
            platinum: [
                { type: 'spell', id: 92, weight: 10, manaQuantity: 60 }, // Legendary Triple Meteor
            ]
        },
        'drops': [
            { type: 'hand', id: 8, weight: 10 }, // Epic Wizard Gloves
        ]
    },
    {
        'enemyID': 25,
        'name': 'Hellhound Boss',
        'radius': 30,
        'spriteName': 'hellhoundBoss',
        // 'spriteName': 'cannon',
        'imgName': 'enemy_hellhound.png',
        'type': 'air',
        'tiledName': 'HellhoundBoss',
        'spine': true,
        // spineSkin: 'hellhound',
        // spineSkin: 'hellhound_boss',
        'shotDelay': true,
        'boss': true,
        'respawnCooldown': 15,
        // 'respawnCooldown': 1,
        'scoreOnKill': 1500,
        // 'scoreOnKill': 500,
        // 'scoreOnKill': 2000,
        // 'scoreOnKill': 500000,
        'speed': 65,
        'hp': 1000,
        // 'hp':1,
        // 'randomTint': true,
        // 'proximityRadius': null,
        'proximityRadius': 300,
        'shootingRange': 300,
        'spells': [430, 429, 438],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'numberOfDrops': 2,
        'dropTable': {
            starter: [
                { type: 'spell', id: 90, weight: 30, manaQuantity: 40 }, // Epic Fire Stream
                { type: 'spell', id: 91, weight: 30, manaQuantity: 40 }, // Legendary Fire Stream
                { type: 'spell', id: 309, weight: 20 }, // Epic Charge
                { type: 'spell', id: 310, weight: 20 }, // Legendary Charge
                { type: 'spell', id: 2, weight: 20, manaQuantity: 60 }, // Epic Meteor
                { type: 'spell', id: 26, weight: 20, manaQuantity: 60 }, // Rare Triple Fireball
                { type: 'spell', id: 27, weight: 20, manaQuantity: 60 }, // Legendary Quintuple Fireball
            ],
            bronze: [
                { type: 'spell', id: 212, weight: 10 }, // Legendary Flame
            ],
            silver: [
                { type: 'spell', id: 91, weight: 30, manaQuantity: 40 }, // Legendary Fire Stream
            ],
            gold: [
                { type: 'spell', id: 27, weight: 20, manaQuantity: 60 }, // Legendary Quintuple Fireball
            ],
            platinum: [
                { type: 'spell', id: 212, weight: 10 }, // Legendary Flame
            ]
        },
        'drops': [
            { type: 'spell', id: 90, weight: 30, manaQuantity: 40 }, // Epic Fire Stream
            // { type: 'spell', id: 91, weight: 30, manaQuantity: 40 }, // Legendary Fire Stream
            // { type: 'spell', id: 212, weight: 10 }, // Legendary Flame
            // { type: 'spell', id: 309, weight: 20 }, // Epic Charge
            // { type: 'spell', id: 310, weight: 20 }, // Legendary Charge
            // { type: 'spell', id: 2, weight: 20, manaQuantity: 60 }, // Epic Meteor
            // { type: 'spell', id: 26, weight: 20, manaQuantity: 60 }, // Rare Triple Fireball
            // { type: 'spell', id: 27, weight: 20, manaQuantity: 60 }, // Legendary Quintuple Fireball
        ]
    },
    {
        'enemyID': 26,
        'name': 'Starfish',
        'radius': 8,
        'spriteName': 'starfishNormal2',
        // 'spriteName': 'cannon',
        'imgName': 'enemy_starfishNormal.png',
        'type': 'air',
        'tiledName': 'StarfishNormal2',
        'spine': true,
        'shotDelay': true,
        //In seconds. make it 300 (5 min) after testing
        'respawnCooldown': 15,
        'scoreOnKill': 25,
        // 'scoreOnKill': 500,
        // 'scoreOnKill': 2000,
        // 'scoreOnKill': 500000,
        'speed': 0,
        'hp': 25,
        // 'hp': 25000,
        // 'hp':1,
        // 'proximityRadius': null,
        'proximityRadius': 100,
        'shootingRange': 100,
        'spells': [424],
        'ai': { canMove: false, followTarget: false, lookAtTarget: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 97, weight: 10, manaQuantity: 30 }, // Uncommon Water Blast
                { type: 'spell', id: 98, weight: 10, manaQuantity: 40 }, // Rare Water Blast
                { type: 'spell', id: 600, weight: 10, manaQuantity: 30 }, // Uncommon Swimming Aura
                { type: 'spell', id: 601, weight: 5, manaQuantity: 40 }, // Rare Swimming Aura
                { type: 'mana', id: 2, weight: 80, manaQuantity: 30 },
            ],
            bronze: [
                { type: 'spell', id: 601, weight: 5, manaQuantity: 40 }, // Rare Swimming Aura
            ],
            silver: [
                { type: 'spell', id: 98, weight: 5, manaQuantity: 40 }, // Rare Water Blast
            ],
            gold: [
                { type: 'spell', id: 601, weight: 5, manaQuantity: 40 }, // Rare Swimming Aura
            ],
            platinum: [
                { type: 'spell', id: 98, weight: 5, manaQuantity: 40 }, // Rare Water Blast
            ]
        },
        'drops': [
            // { type: 'spell', id: 309, weight: 10000, manaQuantity: 40 },
            // { type: 'spell', id: 308, weight: 10000, manaQuantity: 40 },
            // { type: 'spell', id: 306, weight: 10000, manaQuantity: 40 },
            // { type: 'spell', id: 305, weight: 10000, manaQuantity: 40 },
            { type: 'spell', id: 6, weight: 20, manaQuantity: 40 }, // Uncommon Wind Shot,
            { type: 'spell', id: 11, weight: 20, manaQuantity: 40 }, // Uncommon Fire Shot
            { type: 'spell', id: 32, weight: 20, manaQuantity: 40 }, // Rare Fire Dash,
            { type: 'spell', id: 301, weight: 10, manaQuantity: 40 }, // Uncommon Dash,
            { type: 'spell', id: 307, weight: 10, manaQuantity: 40 }, // Uncommon Charge,
            { type: 'spell', id: 311, weight: 10, manaQuantity: 40 }, // Uncommon Stealth,
            { type: 'mana', id: 1, weight: 20, manaQuantity: 30 },
            { type: 'mana', id: 2, weight: 20, manaQuantity: 30 },
            { type: 'mana', id: 3, weight: 20, manaQuantity: 30 },
            { type: 'mana', id: 4, weight: 20, manaQuantity: 30 },
            { type: 'body', id: 2, weight: 6 }, // Uncommon Wizard Body
            { type: 'body', id: 3, weight: 6 }, // Uncommon Rogue Body,
            { type: 'body', id: 4, weight: 6 }, // Uncommon Knight Body,
            { type: 'top', id: 2, weight: 6 }, // Uncommon Wizard Hat,
            { type: 'top', id: 3, weight: 6 }, // Uncommon Rogue Hat,
            { type: 'top', id: 4, weight: 6 }, // Uncommon Knight Hat,
            { type: 'hand', id: 2, weight: 6 }, // Uncommon Wizard Hands,
            { type: 'hand', id: 3, weight: 6 }, // Uncommon Rogue Hands,
            { type: 'hand', id: 4, weight: 6 }, // Uncommon Knight Hands,
            { type: 'hand', id: 5, weight: 1 }, // Rare Wizard Gloves,
            { type: 'hand', id: 6, weight: 1 }, // Rare Rogue Gloves
            { type: 'hand', id: 7, weight: 1 }, // Rare knight Gloves,
        ]
    },
    {
        'enemyID': 27,
        'name': 'Superior Starfish',
        'radius': 8,
        'spriteName': 'starfishSuperior',
        // 'spriteName': 'cannon',
        'imgName': 'enemy_starfishSuperior.png',
        'type': 'air',
        'tiledName': 'StarfishSuperior',
        'spine': true,
        'shotDelay': true,
        //In seconds. make it 300 (5 min) after testing
        'respawnCooldown': 15,
        'scoreOnKill': 25,
        // 'scoreOnKill': 500,
        // 'scoreOnKill': 2000,
        // 'scoreOnKill': 500000,
        'speed': 0,
        'hp': 25,
        // 'hp':1,
        // 'proximityRadius': null,
        'proximityRadius': 100,
        'shootingRange': 100,
        'spells': [424],
        'ai': { canMove: false, followTarget: false, lookAtTarget: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 6, weight: 20, manaQuantity: 40 }, // Uncommon Wind Shot,
                { type: 'spell', id: 11, weight: 20, manaQuantity: 40 }, // Uncommon Fire Shot
                { type: 'spell', id: 32, weight: 20, manaQuantity: 40 }, // Rare Fire Dash
                { type: 'spell', id: 301, weight: 10, manaQuantity: 40 }, // Uncommon Dash,
                { type: 'spell', id: 307, weight: 10, manaQuantity: 40 }, // Uncommon Charge,
                { type: 'spell', id: 311, weight: 10, manaQuantity: 40 }, // Uncommon Stealth,
                { type: 'mana', id: 1, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 30 },
                { type: 'body', id: 2, weight: 6 }, // Uncommon Wizard Body
                { type: 'body', id: 3, weight: 6 }, // Uncommon Rogue Body,
                { type: 'body', id: 4, weight: 6 }, // Uncommon Knight Body,
                { type: 'top', id: 2, weight: 6 }, // Uncommon Wizard Hat,
                { type: 'top', id: 3, weight: 6 }, // Uncommon Rogue Hat,
                { type: 'top', id: 4, weight: 6 }, // Uncommon Knight Hat,
                { type: 'hand', id: 2, weight: 6 }, // Uncommon Wizard Hands,
                { type: 'hand', id: 3, weight: 6 }, // Uncommon Rogue Hands,
                { type: 'hand', id: 4, weight: 6 }, // Uncommon Knight Hands,
                { type: 'hand', id: 5, weight: 1 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 1 }, // Rare Rogue Gloves,
                { type: 'hand', id: 7, weight: 1 }, // Rare knight Gloves,
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': [
            { type: 'hand', id: 7, weight: 1 }, // Rare knight Gloves,
        ]
    },
    {
        'enemyID': 28,
        'name': 'LavaSlime Boss',
        'boss': true,
        'radius': 40,
        // 'spriteName': 'slime',
        'spriteName': 'bossLavaSlime',
        'imgName': 'enemy_slime.png',
        'type': 'fire',
        'tiledName': 'SlimeLavaBoss',
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 300,
        'scoreOnKill': 1000,
        'speed': 80,
        'hp': 1500,
        'randomTint': true,
        'proximityRadius': 250,
        'shootingRange': 250,
        'spells': [422, 432, 433, 434],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'numberOfDrops': 2,
        'dropTable': {
            starter: [
                { type: 'spell', id: 2, weight: 10, manaQuantity: 60 }, // Epic Meteor
                { type: 'spell', id: 27, weight: 6, manaQuantity: 60 }, // Legendary Quintuple Fireball
                { type: 'spell', id: 44, weight: 10, manaQuantity: 60 }, // Epic Double Fireball
                { type: 'spell', id: 45, weight: 5, manaQuantity: 60 }, // Legendary Double Fireball
                { type: 'spell', id: 51, weight: 10, manaQuantity: 60 }, // Epic Fireball
                { type: 'spell', id: 52, weight: 5, manaQuantity: 60 }, // Legendary Fireball
                { type: 'spell', id: 216, weight: 10 }, // Epic Dagger
                { type: 'spell', id: 217, weight: 5 }, // Legendary Dagger
                { type: 'top', id: 16, weight: 6 }, // Legendary Slime Gauntlets
                { type: 'body', id: 16, weight: 6 }, // Legendary Slime Body
                { type: 'hand', id: 16, weight: 6 }, // Legendary Slime Gloves
                { type: 'mana', id: 1, weight: 15, manaQuantity: 70 },
            ],
            bronze: [
                { type: 'hand', id: 16, weight: 6 }, // Legendary Slime Gloves
            ],
            silver: [
                { type: 'body', id: 16, weight: 6 }, // Legendary Slime Body
            ],
            gold: [
                { type: 'top', id: 16, weight: 6 }, // Legendary Slime Gauntlets
            ],
            platinum: [
                { type: 'spell', id: 27, weight: 6, manaQuantity: 60 }, // Legendary Quintuple Fireball
            ]
        },
        'drops': [
            // { type: 'spell', id: 2, weight: 10, manaQuantity: 60 }, // Epic Meteor
            // { type: 'spell', id: 27, weight: 6, manaQuantity: 60 }, // Legendary Quintuple Fireball
            // { type: 'spell', id: 44, weight: 10, manaQuantity: 60 }, // Epic Double Fireball
            // { type: 'spell', id: 45, weight: 5, manaQuantity: 60 }, // Legendary Double Fireball
            // { type: 'spell', id: 51, weight: 10, manaQuantity: 60 }, // Epic Fireball
            // { type: 'spell', id: 52, weight: 5, manaQuantity: 60 }, // Legendary Fireball
            // { type: 'spell', id: 216, weight: 10 }, // Epic Dagger
            // { type: 'spell', id: 217, weight: 5 }, // Legendary Dagger
            // { type: 'top', id: 16, weight: 6 }, // Legendary Slime Gauntlets
            // { type: 'body', id: 16, weight: 6 }, // Legendary Slime Body
            // { type: 'hand', id: 16, weight: 6 }, // Legendary Slime Gloves
            { type: 'mana', id: 1, weight: 15, manaQuantity: 70 },
        ]
    },
    {
        'enemyID': 29,
        'name': 'Lava Slime',
        'radius': 40,
        // 'spriteName': 'slime',
        'spriteName': 'lavaSlime',
        'imgName': 'enemy_slime.png',
        'type': 'fire',
        'tiledName': 'SlimeLava',
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 300,
        'scoreOnKill': 350,
        'speed': 80,
        'hp': 700,
        'randomTint': true,
        'proximityRadius': 250,
        'shootingRange': 250,
        'spells': [412, 419, 421],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'numberOfDrops': 3,
        'dropTable': {
            starter: [
                { type: 'spell', id: 8, weight: 10, manaQuantity: 60 }, // Legendary Gravity Ball
                { type: 'spell', id: 208, weight: 10, manaQuantity: 60 }, // Rare Sword
                { type: 'spell', id: 86, weight: 10, manaQuantity: 60 }, // Rare Heal Self
                { type: 'spell', id: 302, weight: 10, manaQuantity: 60 }, // Rare Dash
                { type: 'spell', id: 308, weight: 10, manaQuantity: 60 }, // Rare Charge
                { type: 'spell', id: 312, weight: 10, manaQuantity: 60 }, // Rare Stealth
                { type: 'mana', id: 1, weight: 20, manaQuantity: 60 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 60 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 60 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 60 },
                { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                { type: 'top', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'top', id: 7, weight: 10 }, // Rare Knight Armor,
                { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gauntlets
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': [
            // { type: 'spell', id: 8, weight: 10, manaQuantity: 60 }, // Legendary Gravity Ball
            // { type: 'spell', id: 208, weight: 10, manaQuantity: 60 }, // Rare Sword
            // { type: 'spell', id: 86, weight: 10, manaQuantity: 60 }, // Rare Heal Self
            // { type: 'spell', id: 302, weight: 10, manaQuantity: 60 }, // Rare Dash
            // { type: 'spell', id: 308, weight: 10, manaQuantity: 60 }, // Rare Charge
            // { type: 'spell', id: 312, weight: 10, manaQuantity: 60 }, // Rare Stealth
            // { type: 'mana', id: 1, weight: 20, manaQuantity: 60 },
            // { type: 'mana', id: 2, weight: 20, manaQuantity: 60 },
            // { type: 'mana', id: 3, weight: 20, manaQuantity: 60 },
            // { type: 'mana', id: 4, weight: 20, manaQuantity: 60 },
            // { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body,
            // { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
            // { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
            // { type: 'top', id: 5, weight: 10 }, // Rare Wizard Body,
            // { type: 'top', id: 6, weight: 10 }, // Rare Rogue Body,
            // { type: 'top', id: 7, weight: 10 }, // Rare Knight Armor,
            // { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves,
            // { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
            { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gauntlets
        ]
    },
    {
        'enemyID': 30,
        'name': 'BFC',
        'radius': 25,
        'spriteName': 'cannonBoss',
        'imgName': 'enemy_cannonBoss.png',
        'tiledName': 'CannonBoss',
        'type': 'fire',
        'spine': true,
        'shotDelay': true,
        'boss': true,
        'respawnCooldown': 90,
        'scoreOnKill': 900,
        'speed': 0,
        'hp': 600,
        'proximityRadius': 300,
        'shootingRange': 300,
        'spells': [439, 440],
        'ai': { canMove: false, followTarget: false, lookAtTarget: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 44, weight: 10, manaQuantity: 60 }, // Epic Double Fireball,
                { type: 'spell', id: 26, weight: 10, manaQuantity: 60 }, // Rare Triple Fireball
                { type: 'spell', id: 51, weight: 10, manaQuantity: 60 }, // Epic Fireball,
            ],
            bronze: [
                { type: 'spell', id: 44, weight: 10, manaQuantity: 60 }, // Epic Double Fireball,
            ],
            silver: [
                { type: 'spell', id: 44, weight: 10, manaQuantity: 60 }, // Epic Double Fireball,
            ],
            gold: [
                { type: 'spell', id: 45, weight: 2, manaQuantity: 70 }, // Legendary Double Fireball
            ],
            platinum: [
                { type: 'spell', id: 52, weight: 2, manaQuantity: 70 }, // Legendary Fireball
            ]
        },
        'drops': [
            // 	{ type: 'spell', id: 44, weight: 10, manaQuantity: 60 }, // Epic Double Fireball,
            // 	{ type: 'spell', id: 26, weight: 10, manaQuantity: 60 }, // Rare Triple Fireball
            { type: 'spell', id: 51, weight: 10, manaQuantity: 60 }, // Epic Fireball,
        ]
    },
    {
        'enemyID': 31,
        'name': 'Spiky Crab',
        'radius': 18,
        'spriteName': 'crabSuperior',
        // 'spriteName': 'cannon',
        'imgName': 'enemy_crabSuperior.png',
        'type': 'water',
        'tiledName': 'CrabSuperior',
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 80,
        // 'respawnCooldown': 1,
        'scoreOnKill': 230,
        // 'scoreOnKill': 500,
        // 'scoreOnKill': 2000,
        // 'scoreOnKill': 500000,
        'speed': 80,
        'hp': 155,
        // 'hp':1,
        // 'randomTint': true,
        // 'proximityRadius': null,
        'proximityRadius': 250,
        'shootingRange': 100,
        'spells': [442, 444],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 600, weight: 15, manaQuantity: 30 }, // Uncommon Swimming Aura
                { type: 'spell', id: 601, weight: 10, manaQuantity: 40 }, // Rare Swimming Aura
                { type: 'spell', id: 85, weight: 5, manaQuantity: 40 }, // Uncommon Heal Self
                { type: 'spell', id: 86, weight: 5, manaQuantity: 40 }, // Rare Heal Self
                { type: 'spell', id: 32, weight: 5, manaQuantity: 40 }, // Uncommon Healing Circle
                { type: 'spell', id: 33, weight: 5, manaQuantity: 40 }, // Rare Healing Circle
                { type: 'spell', id: 219, weight: 5 }, // Rare Ice Sword
                { type: 'spell', id: 222, weight: 5 }, // Rare Ice Dagger
                { type: 'spell', id: 63, weight: 10, manaQuantity: 40 }, // Uncommon Earthquake
                { type: 'spell', id: 64, weight: 7, manaQuantity: 40 }, // Rare Earthquake
                { type: 'spell', id: 64, weight: 2, manaQuantity: 40 }, // Epic Earthquake
                { type: 'mana', id: 2, weight: 40, manaQuantity: 30 },
                { type: 'mana', id: 4, weight: 40, manaQuantity: 30 },
                { type: 'body', id: 2, weight: 5 }, // Uncommon Wizard Body,
                { type: 'body', id: 3, weight: 5 }, // Uncommon Rogue Gloves,
                { type: 'body', id: 4, weight: 5 }, // Uncommon Knight Body,
                { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                { type: 'body', id: 8, weight: 1 }, // Epic Wizard Body,
                { type: 'body', id: 9, weight: 1 }, // Epic Rogue Body
                { type: 'body', id: 10, weight: 1 }, // Epic Knight Body,
                { type: 'top', id: 2, weight: 5 }, // Uncommon Wizard Hat,
                { type: 'top', id: 3, weight: 5 }, // Uncommon Rogue Hat,
                { type: 'top', id: 4, weight: 5 }, // Uncommon Knight Helmet,
                { type: 'top', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'top', id: 7, weight: 10 }, // Rare Knight Armor,
                { type: 'top', id: 8, weight: 1 }, // Epic Wizard Hat
                { type: 'top', id: 9, weight: 1 }, // Epic Rogue Hat
                { type: 'top', id: 10, weight: 1 }, // Epic Knight Helm
                { type: 'hand', id: 2, weight: 5 }, // Uncommon Wizard Gloves
                { type: 'hand', id: 3, weight: 5 }, // Uncommon Rogue Gloves
                { type: 'hand', id: 4, weight: 5 }, // Uncommon Knight Gauntlets
                { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gauntlets
                { type: 'hand', id: 8, weight: 1 }, // Epic Wizard Gloves
                { type: 'hand', id: 9, weight: 1 }, // Epic Rogue Gloves
                { type: 'hand', id: 10, weight: 1 }, // Epic Knight Gauntlets
            ],
            bronze: [
                { type: 'top', id: 8, weight: 1 }, // Epic Wizard Hat
                { type: 'top', id: 9, weight: 1 }, // Epic Rogue Hat
                { type: 'top', id: 10, weight: 1 }, // Epic Knight Helm
            ],
            silver: [
                { type: 'body', id: 8, weight: 1 }, // Epic Wizard Body,
                { type: 'body', id: 9, weight: 1 }, // Epic Rogue Body
                { type: 'body', id: 10, weight: 1 }, // Epic Knight Body,
            ],
            gold: [
                { type: 'hand', id: 8, weight: 1 }, // Epic Wizard Gloves
                { type: 'hand', id: 9, weight: 1 }, // Epic Rogue Gloves
                { type: 'hand', id: 10, weight: 1 }, // Epic Knight Gauntlets
            ],
            platinum: [
                { type: 'spell', id: 66, weight: 2, manaQuantity: 70 }, // Legendary Earthquake
            ]
        },
        'drops': [
            { type: 'spell', id: 600, weight: 15, manaQuantity: 30 }, // Uncommon Swimming Aura
        ]
    },
    {
        'enemyID': 32,
        'name': 'Boss Crab',
        'radius': 30,
        'spriteName': 'crabBoss',
        // 'spriteName': 'cannon',
        'imgName': 'enemy_crabBoss.png',
        'type': 'water',
        'tiledName': 'CrabBoss',
        'spine': true,
        'shotDelay': true,
        boss: true,
        'respawnCooldown': 90,
        // 'respawnCooldown': 1,
        'scoreOnKill': 750,
        // 'scoreOnKill': 500,
        // 'scoreOnKill': 2000,
        // 'scoreOnKill': 500000,
        'speed': 50,
        'hp': 600,
        // 'hp':1,
        // 'randomTint': true,
        // 'proximityRadius': null,
        'proximityRadius': 250,
        'shootingRange': 100,
        'spells': [443, 445],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'numberOfDrops': 2,
        'dropTable': {
            starter: [
                { type: 'spell', id: 601, weight: 15, manaQuantity: 30 }, // Rare Swimming Aura
                { type: 'spell', id: 602, weight: 10, manaQuantity: 40 }, // Epic Swimming Aura
                { type: 'spell', id: 64, weight: 7, manaQuantity: 40 }, // Rare Earthquake
                { type: 'spell', id: 65, weight: 10, manaQuantity: 40 }, // Epic Earthquake
                { type: 'spell', id: 223, weight: 5 }, // Epic Ice Dagger
                { type: 'mana', id: 2, weight: 40, manaQuantity: 30 },
                { type: 'body', id: 19, weight: 10 }, // Rare Crab Body,
                { type: 'top', id: 19, weight: 10 }, // Rare Crab Helmet,
                { type: 'hand', id: 19, weight: 10 }, // Rare Crab Hands,
                { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                // { type: 'body', id: 8, weight: 3 }, // Epic Wizard Body
                // { type: 'body', id: 9, weight: 3 }, // Epic Rogue Body
                // { type: 'body', id: 10, weight: 3 }, // Epic Knight Body
                { type: 'top', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'top', id: 7, weight: 10 }, // Rare Knight Armor,
                // { type: 'top', id: 8, weight: 3 }, // Epic Wizard Top
                // { type: 'top', id: 9, weight: 3 }, // Epic Rogue Top
                // { type: 'top', id: 10, weight: 3 }, // Epic Knight Top
                { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gauntlets
                // { type: 'hand', id: 8, weight: 3 }, // Epic Wizard Hands
                // { type: 'hand', id: 9, weight: 3 }, // Epic Rogue Hands
                // { type: 'hand', id: 10, weight: 3 }, // Epic Knight Hands
            ],
            bronze: [
                { type: 'body', id: 19, weight: 10 }, // Rare Crab Body,
                { type: 'top', id: 19, weight: 10 }, // Rare Crab Helmet,
                { type: 'hand', id: 19, weight: 10 }, // Rare Crab Hands,
            ],
            silver: [
                { type: 'body', id: 19, weight: 10 }, // Rare Crab Body,
                { type: 'top', id: 19, weight: 10 }, // Rare Crab Helmet,
                { type: 'hand', id: 19, weight: 10 }, // Rare Crab Hands,
            ],
            gold: [
                { type: 'body', id: 19, weight: 10 }, // Rare Crab Body,
                { type: 'top', id: 19, weight: 10 }, // Rare Crab Helmet,
                { type: 'hand', id: 19, weight: 10 }, // Rare Crab Hands,
            ],
            platinum: [
                { type: 'spell', id: 66, weight: 5, manaQuantity: 70 }, // Legendary Earthquake
            ]
        },
        'drops': [
            { type: 'hand', id: 10, weight: 3 }, // Epic Knight Hands
        ]
    },
    {
        'enemyID': 33,
        'name': 'Piranha',
        'type': 'water',
        'radius': 14,
        // 'spriteName': 'enemy_piranha',
        'spriteName': 'enemy_piranha',
        'imgName': 'enemy_piranha.png',
        'tiledName': 'Piranha',
        spine: true,
        'respawnCooldown': 90,
        'scoreOnKill': 125,
        'speed': 100,
        'hp': 65,
        'proximityRadius': 250,
        'shootingRange': 35,
        'spells': [426],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 601, weight: 50, manaQuantity: 40 }, // Rare Swimming Aura
                { type: 'spell', id: 602, weight: 5, manaQuantity: 40 }, // Epic Swimming Aura
                { type: 'spell', id: 215, weight: 10 }, // Rare Dagger
                { type: 'spell', id: 216, weight: 10 }, // Epic Dagger
                { type: 'spell', id: 85, weight: 5, manaQuantity: 40 }, // Uncommon Heal Self
                { type: 'spell', id: 86, weight: 5, manaQuantity: 40 }, // Rare Heal Self
                { type: 'spell', id: 87, weight: 5, manaQuantity: 40 }, // Epic Heal Self
                { type: 'spell', id: 32, weight: 5, manaQuantity: 40 }, // Uncommon Healing Circle
                { type: 'spell', id: 33, weight: 5, manaQuantity: 40 }, // Rare Healing Circle
                { type: 'spell', id: 34, weight: 5, manaQuantity: 40 }, // Epic Healing CIrcle
                { type: 'spell', id: 219, weight: 5 }, // Rare Ice Sword
                { type: 'spell', id: 222, weight: 5 }, // Rare Ice Dagger
                { type: 'mana', id: 1, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 5, weight: 20, manaQuantity: 40 },
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                { type: 'body', id: 9, weight: 1 }, // Epic Rogue Body,
                { type: 'body', id: 10, weight: 1 }, // Epic Knight Body
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'top', id: 7, weight: 10 }, // Rare Knight Armor,
                { type: 'top', id: 9, weight: 10 }, // Epic Rogue Top
                { type: 'top', id: 10, weight: 10 }, // Epic Knight Top
                { type: 'top', id: 13, weight: 1 }, // Epic Rogue Top
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gauntlets
                { type: 'hand', id: 9, weight: 10 }, // Epic Rogue Hands
                { type: 'hand', id: 10, weight: 10 }, // Epic Knight Hands
                { type: 'top', id: 20, weight: 1 }, // Epic Piranha Helmet,
            ],
            bronze: [
                { type: 'top', id: 20, weight: 2 }, // Epic Piranha Helmet,
                { type: 'spell', id: 227, weight: 5 }, // Rare Spear
                // { type: 'body', id: 12, weight: 1 }, // Legendary Rogue Body
                // { type: 'body', id: 13, weight: 1 }, // Legendary Knight Armor,
            ],
            silver: [
                { type: 'top', id: 20, weight: 2 }, // Epic Piranha Helmet,
                { type: 'spell', id: 228, weight: 5 }, // Epic Spear
                // { type: 'hand', id: 12, weight: 1 }, // Legendary Rogue Hands
                // { type: 'hand', id: 13, weight: 1 }, // Legendary Knight Hands
            ],
            gold: [
                { type: 'top', id: 20, weight: 2 }, // Epic Piranha Helmet,
                // { type: 'top', id: 12, weight: 1 }, // Legendary Rogue Top
                // { type: 'top', id: 13, weight: 1 }, // Legendary Knight Hat
            ],
            platinum: [
                { type: 'top', id: 20, weight: 3 }, // Epic Piranha Helmet,
                // { type: 'top', id: 12, weight: 1 }, // Legendary Rogue Top
                // { type: 'top', id: 13, weight: 1 }, // Legendary Knight Hat
            ]
        },
        'drops': [
            { type: 'hand', id: 13, weight: 1 }, // Legendary Knight Hands
        ]
    },
    {
        'enemyID': 34,
        'name': 'Piranha Boss',
        'type': 'water',
        'radius': 35,
        'spriteName': 'enemy_piranha_boss',
        'imgName': 'enemy_piranha_boss.png',
        spine: true,
        'tiledName': 'PiranhaBoss',
        'respawnCooldown': 90,
        'scoreOnKill': 125,
        'speed': 100,
        'hp': 450,
        'proximityRadius': 250,
        'shootingRange': 70,
        'spells': [454, 455],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'numberOfDrops': 2,
        'dropTable': {
            starter: [
                { type: 'spell', id: 602, weight: 50, manaQuantity: 40 }, // Epic Swimming Aura
                { type: 'spell', id: 603, weight: 5, manaQuantity: 40 }, // Legendary Swimming Aura
                { type: 'spell', id: 216, weight: 10 }, // Epic Dagger,
                { type: 'spell', id: 217, weight: 2 }, // Legendary Dagger
                { type: 'spell', id: 219, weight: 5 }, // Rare Ice Sword
                { type: 'spell', id: 223, weight: 5 }, // Epic Ice Dagger
                { type: 'mana', id: 1, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 40 },
                { type: 'mana', id: 5, weight: 20, manaQuantity: 40 },
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                { type: 'body', id: 9, weight: 1 }, // Epic Rogue Body,
                { type: 'body', id: 10, weight: 1 }, // Epic Knight Body,
                { type: 'body', id: 12, weight: 1 }, // Legendary Rogue Body,
                { type: 'body', id: 13, weight: 1 }, // Legendary Knight Armor
                { type: 'top', id: 20, weight: 30 }, // Epic Piranha Helmet,
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'top', id: 7, weight: 10 }, // Rare Knight Armor,
                { type: 'top', id: 9, weight: 10 }, // Epic Rogue Hat
                { type: 'top', id: 10, weight: 10 }, // Epic Knight Helm
                { type: 'top', id: 12, weight: 1 }, // Epic Rogue Hat
                { type: 'top', id: 13, weight: 1 }, // Epic Knight Helm
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gauntlets
                { type: 'hand', id: 9, weight: 10 }, // Epic Rogue Hands
                { type: 'hand', id: 10, weight: 10 }, // Epic Knight Hands
                { type: 'hand', id: 12, weight: 1 }, // Legendary Rogue Hands
                { type: 'hand', id: 13, weight: 1 }, // Legendary Knight Hands
            ],
            bronze: [
                { type: 'spell', id: 228, weight: 5 }, // Epic Spear
                { type: 'top', id: 20, weight: 15 }, // Epic Piranha Helmet,
                // { type: 'body', id: 12, weight: 2 }, // Legendary Rogue Body,
                // { type: 'body', id: 13, weight: 2 }, // Legendary Knight Armor
            ],
            silver: [
                { type: 'top', id: 20, weight: 15 }, // Epic Piranha Helmet,
                // { type: 'hand', id: 12, weight: 1 }, // Legendary Rogue Hands
                // { type: 'hand', id: 13, weight: 1 }, // Legendary Knight Hands
            ],
            gold: [
                { type: 'top', id: 20, weight: 15 }, // Epic Piranha Helmet,
                // { type: 'top', id: 12, weight: 10 }, // Legendary Rogue Hat
                // { type: 'top', id: 13, weight: 10 }, // Legendary Knight Hat
            ],
            platinum: [
                { type: 'top', id: 20, weight: 15 }, // Epic Piranha Helmet,
            ]
        },
        'drops': [
            { type: 'hand', id: 13, weight: 1 }, // Legendary Knight Hands
        ]
    },
    {
        'enemyID': 35,
        'name': 'Seahorse',
        'type': 'water',
        'radius': 14,
        'spriteName': 'enemy_seahorse',
        spine: true,
        'imgName': 'enemy_seahorse.png',
        'tiledName': 'Seahorse',
        'respawnCooldown': 90,
        'scoreOnKill': 125,
        'speed': 100,
        'hp': 100,
        'proximityRadius': 150,
        'shootingRange': 150,
        'spells': [446],
        'ai': { canMove: true, followTarget: false, lookAtTarget: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 101, weight: 3, manaQuantity: 30 }, // Uncommon Water Spray
                { type: 'spell', id: 102, weight: 10, manaQuantity: 40 }, // Rare Water Spray
                { type: 'spell', id: 103, weight: 3, manaQuantity: 50 }, // Epic Water Spray
                { type: 'spell', id: 85, weight: 5, manaQuantity: 40 }, // Uncommon Heal Self
                { type: 'spell', id: 86, weight: 5, manaQuantity: 40 }, // Rare Heal Self
                { type: 'spell', id: 32, weight: 5, manaQuantity: 40 }, // Uncommon Healing Circle
                { type: 'spell', id: 33, weight: 5, manaQuantity: 40 }, // Rare Healing Circle
                { type: 'spell', id: 603, weight: 3, manaQuantity: 40 }, // Legendary Swimming Aura
                { type: 'spell', id: 602, weight: 50, manaQuantity: 40 }, // Epic Swimming Aura
                { type: 'mana', id: 2, weight: 20, manaQuantity: 40 },
                { type: 'body', id: 2, weight: 3 }, // Uncommon Wizard Body
                { type: 'body', id: 5, weight: 6 }, // Rare Wizard Body
                { type: 'body', id: 8, weight: 3 }, // Epic Wizard Body
                { type: 'top', id: 2, weight: 3 }, // Uncommon Wizard Top
                { type: 'top', id: 5, weight: 6 }, // Rare Wizard Top
                { type: 'top', id: 8, weight: 3 }, // Epic Wizard Top
                { type: 'hand', id: 2, weight: 3 }, // Uncommon Wizard Hands
                { type: 'hand', id: 5, weight: 6 }, // Rare Wizard Hands
                { type: 'hand', id: 6, weight: 3 }, // Epic Wizard Hands
            ],
            bronze: [
                { type: 'spell', id: 603, weight: 5, manaQuantity: 40 }, // Legendary Swimming Aura
            ],
            silver: [
                { type: 'spell', id: 104, weight: 1, manaQuantity: 60 }, // Legendary Water Spray
            ],
            gold: [
                { type: 'spell', id: 104, weight: 1, manaQuantity: 60 }, // Legendary Water Spray
            ],
            platinum: [
                { type: 'spell', id: 104, weight: 1, manaQuantity: 60 }, // Legendary Water Spray
            ]
        },
        'drops': [
            { type: 'hand', id: 6, weight: 3 }, // Epic Wizard Hands
        ]
    },
    {
        'enemyID': 36,
        'name': 'Seahorse Superior',
        'type': 'water',
        'radius': 19,
        'spriteName': 'enemy_seahorse_superior',
        spine: true,
        'imgName': 'enemy_seahorse_superior.png',
        'tiledName': 'SeahorseSuperior',
        'respawnCooldown': 90,
        'scoreOnKill': 125,
        'speed': 100,
        'hp': 250,
        'proximityRadius': 150,
        'shootingRange': 150,
        'spells': [449],
        'ai': { canMove: true, followTarget: false, lookAtTarget: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 105, weight: 15, manaQuantity: 30 }, // Rare Ice Spray
                { type: 'spell', id: 106, weight: 10, manaQuantity: 40 }, // Epic Ice Spray
                { type: 'spell', id: 107, weight: 1, manaQuantity: 50 }, // Legendary Ice Spray
                { type: 'spell', id: 86, weight: 5, manaQuantity: 40 }, // Rare Heal Self
                { type: 'spell', id: 87, weight: 5, manaQuantity: 40 }, // Epic Heal Self
                { type: 'spell', id: 33, weight: 5, manaQuantity: 40 }, // Rare Healing Circle
                { type: 'spell', id: 34, weight: 5, manaQuantity: 40 }, // Epic Healing Circle
                { type: 'spell', id: 602, weight: 10, manaQuantity: 40 }, // Epic Swimming Aura
                { type: 'spell', id: 603, weight: 3, manaQuantity: 40 }, // Legendary Swimming Aura
                { type: 'mana', id: 2, weight: 20, manaQuantity: 40 },
                { type: 'body', id: 5, weight: 3 }, // Rare Wizard Body
                { type: 'body', id: 8, weight: 6 }, // Epic Wizard Body
                { type: 'body', id: 11, weight: 1 }, // Legendary Wizard Body
                { type: 'top', id: 5, weight: 3 }, // Rare Wizard Top
                { type: 'top', id: 8, weight: 6 }, // Epic Wizard Top
                { type: 'top', id: 11, weight: 1 }, // Legendary Wizard Top
                { type: 'hand', id: 5, weight: 3 }, // Rare Wizard Hands
                { type: 'hand', id: 8, weight: 6 }, // Epic Wizard Hands
                { type: 'hand', id: 11, weight: 1 }, // Legendary Wizard Hands
            ],
            bronze: [
                { type: 'hand', id: 11, weight: 1 }, // Legendary Wizard Hands
            ],
            silver: [
                { type: 'body', id: 11, weight: 1 }, // Legendary Wizard Body
            ],
            gold: [
                { type: 'top', id: 11, weight: 1 }, // Legendary Wizard Top
            ],
            platinum: [
                { type: 'spell', id: 107, weight: 1, manaQuantity: 50 }, // Legendary Ice Spray
            ]
        },
        'drops': [
            { type: 'hand', id: 11, weight: 1 },
        ]
    },
    {
        'enemyID': 37,
        'name': 'Seahorse Boss',
        boss: true,
        'type': 'water',
        'radius': 33,
        'spriteName': 'enemy_seahorse_boss',
        spine: true,
        'imgName': 'enemy_seahorse_boss.png',
        'tiledName': 'SeahorseBoss',
        'respawnCooldown': 90,
        'scoreOnKill': 125,
        'speed': 100,
        'hp': 65,
        'proximityRadius': 150,
        'shootingRange': 150,
        'spells': [411],
        'ai': { canMove: true, followTarget: false, lookAtTarget: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 105, weight: 5, manaQuantity: 30 }, // Rare Ice Spray
                { type: 'spell', id: 106, weight: 10, manaQuantity: 40 }, // Epic Ice Spray
                { type: 'spell', id: 107, weight: 10, manaQuantity: 50 }, // Legendary Ice Spray
                { type: 'spell', id: 602, weight: 5, manaQuantity: 40 }, // Epic Swimming Aura
                { type: 'spell', id: 603, weight: 10, manaQuantity: 40 }, // Legendary Swimming Aura
                { type: 'mana', id: 2, weight: 20, manaQuantity: 80 },
                { type: 'body', id: 8, weight: 6 }, // Epic Wizard Body
                { type: 'body', id: 11, weight: 1 }, // Legendary Wizard Body
                { type: 'top', id: 8, weight: 6 }, // Epic Wizard Top
                { type: 'top', id: 11, weight: 1 }, // Legendary Wizard Top
                { type: 'hand', id: 8, weight: 6 }, // Epic Wizard Hands
                { type: 'hand', id: 11, weight: 1 }, // Legendary Wizard Hands
            ],
            bronze: [
                { type: 'top', id: 11, weight: 1 }, // Legendary Wizard Top
            ],
            silver: [
                { type: 'body', id: 11, weight: 1 }, // Legendary Wizard Body
            ],
            gold: [
                { type: 'hand', id: 11, weight: 1 }, // Legendary Wizard Hands
            ],
            platinum: [
                { type: 'spell', id: 107, weight: 10, manaQuantity: 50 }, // Legendary Ice Spray
            ]
        },
        'drops': [
            { type: 'hand', id: 11, weight: 1 },
        ]
    },
    {
        'enemyID': 38,
        'name': 'Mammoth',
        'radius': 24,
        'spriteName': 'enemy_mammoth_normal',
        spine: true,
        'imgName': 'enemy_mammoth_normal.png',
        'type': 'earth',
        'tiledName': 'Mammoth',
        // 'spine': true,
        // spineSkin: 'hellhound',
        // spineSkin: 'hellhound_boss',
        'shotDelay': true,
        // 'respawnCooldown': 15,
        'respawnCooldown': 45,
        // 'respawnCooldown': 1,
        'scoreOnKill': 300,
        // 'scoreOnKill': 500,
        // 'scoreOnKill': 2000,
        // 'scoreOnKill': 500000,
        'speed': 65,
        'hp': 200,
        // 'hp':1,
        // 'randomTint': true,
        // 'proximityRadius': null,
        'proximityRadius': 200,
        'shootingRange': 200,
        // 'spells': [430, 429, 438],
        'spells': [457, 429],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'trialDropChance': {
            baseChance: 0.01,
            dropTable: [
                { id: 9, weight: 50 },
            ]
        },
        'dropTable': {
            starter: [
                { type: 'spell', id: 216, weight: 5 }, // Epic Dagger
                { type: 'spell', id: 217, weight: 2 }, // Legendary Dagger
                { type: 'spell', id: 222, weight: 5 }, // Rare Ice Dagger
                { type: 'spell', id: 202, weight: 5 }, // Epic Ice Katana,
                { type: 'spell', id: 223, weight: 5 }, // Epic Ice Dagger
                { type: 'spell', id: 86, weight: 5, manaQuantity: 40 }, // Rare Heal Self
                { type: 'spell', id: 87, weight: 5, manaQuantity: 40 }, // Epic Heal Self
                { type: 'spell', id: 33, weight: 5, manaQuantity: 40 }, // Rare Healing Circle
                { type: 'spell', id: 34, weight: 5, manaQuantity: 40 }, // Epic Healing Circle
                { type: 'spell', id: 67, weight: 5, manaQuantity: 40 }, // Uncommon Binding Shot
                { type: 'spell', id: 68, weight: 5, manaQuantity: 40 }, // Rare Binding Shot
                { type: 'spell', id: 70, weight: 2, manaQuantity: 40 }, // Legendary Binding Shot
                { type: 'spell', id: 308, weight: 20, manaQuantity: 40 }, // Rare Charge
                { type: 'spell', id: 309, weight: 20, manaQuantity: 40 }, // Epic Charge
                { type: 'mana', id: 2, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 30 },
                { type: 'body', id: 7, weight: 6 }, // Rare Knight Armor
                { type: 'body', id: 10, weight: 6 }, // Epic Knight ARmor
                { type: 'top', id: 7, weight: 6 }, // Rare Knight Top
                { type: 'top', id: 10, weight: 6 }, // Epic Knight Top
                { type: 'hand', id: 7, weight: 6 }, // Rare Knight Hands
                { type: 'hand', id: 10, weight: 6 }, // Epic Knight Hands
            ],
            bronze: [
                { type: 'top', id: 13, weight: 2 }, // Legendary Knight Hat
            ],
            silver: [
                { type: 'spell', id: 229, weight: 5 }, // Legendary Spear
                { type: 'body', id: 13, weight: 2 }, // Legendary Knight Body
            ],
            gold: [
                { type: 'hand', id: 13, weight: 2 }, // Legendary Knight Gauntlets
            ],
            platinum: [
                { type: 'spell', id: 217, weight: 2 }, // Legendary Dagger
            ]
        },
        'drops': [
            // axe?
            { type: 'hand', id: 10, weight: 6 },
        ]
    },
    {
        'enemyID': 39,
        'name': 'Mammoth Superior',
        'radius': 32,
        'spriteName': 'enemy_mammoth_superior',
        spine: true,
        'imgName': 'enemy_mammoth_superior.png',
        'type': 'earth',
        'tiledName': 'MammothSuperior',
        // 'spine': true,
        // spineSkin: 'hellhound',
        // spineSkin: 'hellhound_boss',
        'shotDelay': true,
        'respawnCooldown': 45,
        // 'respawnCooldown': 1,
        'scoreOnKill': 300,
        // 'scoreOnKill': 500,
        // 'scoreOnKill': 2000,
        // 'scoreOnKill': 500000,
        'speed': 65,
        'hp': 350,
        // 'hp':1,
        // 'randomTint': true,
        // 'proximityRadius': null,
        'proximityRadius': 200,
        'shootingRange': 200,
        'spells': [458, 429, 447],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'trialDropChance': {
            baseChance: 0.01,
            dropTable: [
                { id: 9, weight: 50 },
            ]
        },
        'dropTable': {
            starter: [
                { type: 'spell', id: 216, weight: 5 }, // Epic Dagger,
                { type: 'spell', id: 217, weight: 2 }, // Legendary Dagger
                { type: 'spell', id: 202, weight: 5 }, // Epic Ice Katana
                { type: 'spell', id: 223, weight: 5 }, // Epic Ice Dagger,
                { type: 'spell', id: 224, weight: 2 }, // Legendary Ice Dagger
                { type: 'spell', id: 308, weight: 20, manaQuantity: 40 }, // Rare Charge,
                { type: 'spell', id: 309, weight: 20, manaQuantity: 40 }, // Epic Charge,
                { type: 'spell', id: 310, weight: 10, manaQuantity: 40 }, // Legendary Charge
                { type: 'spell', id: 68, weight: 3, manaQuantity: 40 }, // Rare Binding Shot
                { type: 'spell', id: 67, weight: 5, manaQuantity: 40 }, // Uncommon Binding Shot,
                { type: 'spell', id: 70, weight: 5, manaQuantity: 40 }, // Legendary Binding Shot
                { type: 'spell', id: 86, weight: 5, manaQuantity: 40 }, // Rare Heal Self
                { type: 'spell', id: 87, weight: 5, manaQuantity: 40 }, // Epic Heal Self
                { type: 'spell', id: 88, weight: 3, manaQuantity: 40 }, // legendary Heal Self
                { type: 'spell', id: 33, weight: 5, manaQuantity: 40 }, // Rare Healing Circle
                { type: 'spell', id: 34, weight: 5, manaQuantity: 40 }, // Epic Healing Circle,
                { type: 'spell', id: 35, weight: 3, manaQuantity: 40 }, // Legendary Healing Circle
                { type: 'mana', id: 2, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 30 },
                { type: 'body', id: 7, weight: 3 }, // Rare Knight Body
                { type: 'body', id: 10, weight: 6 }, // Epic Knight Body
                { type: 'body', id: 13, weight: 3 }, // Legendary Knight Body
                { type: 'top', id: 7, weight: 3 }, // Rare Knight Top
                { type: 'top', id: 10, weight: 6 }, // Epic Knight Top
                { type: 'top', id: 13, weight: 3 }, // Legendary Knight Top
                { type: 'hand', id: 7, weight: 3 }, // Rare Knight Hands
                { type: 'hand', id: 10, weight: 6 }, // Epic Knight Hands
                { type: 'hand', id: 13, weight: 3 }, // Legendary Knight Hands
            ],
            bronze: [
                { type: 'spell', id: 229, weight: 5 }, // Legendary Spear
                { type: 'spell', id: 70, weight: 5, manaQuantity: 40 }, // Legendary Binding Shot
            ],
            silver: [
                { type: 'body', id: 13, weight: 3 }, // Legendary Knight Body
            ],
            gold: [
                { type: 'top', id: 13, weight: 3 }, // Legendary Knight Top
            ],
            platinum: [
                { type: 'hand', id: 13, weight: 3 }, // Legendary Knight Hands
            ]
        },
        'drops': [
            // axe?
            { type: 'hand', id: 13, weight: 3 }, // Legendary Knight Hands
        ]
    },
    {
        'enemyID': 40,
        'name': 'Mammoth Boss',
        'radius': 40,
        'spriteName': 'enemy_mammoth_boss',
        spine: true,
        boss: true,
        'imgName': 'enemy_mammoth_boss.png',
        'type': 'earth',
        'tiledName': 'MammothBoss',
        // 'spine': true,
        // spineSkin: 'hellhound',
        // spineSkin: 'hellhound_boss',
        'shotDelay': true,
        'respawnCooldown': 15,
        // 'respawnCooldown': 1,
        'scoreOnKill': 300,
        // 'scoreOnKill': 500,
        // 'scoreOnKill': 2000,
        // 'scoreOnKill': 500000,
        'speed': 65,
        'hp': 1200,
        // 'hp':1,
        // 'randomTint': true,
        // 'proximityRadius': null,
        'proximityRadius': 200,
        'shootingRange': 200,
        'spells': [430, 429, 456],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 216, weight: 5 }, // Epic Dagger,
                { type: 'spell', id: 217, weight: 2 }, // Legendary Dagger,
                { type: 'spell', id: 202, weight: 5 }, // Epic Ice Katana,
                { type: 'spell', id: 223, weight: 2 }, // Epic Ice Dagger
                { type: 'spell', id: 224, weight: 5 }, // Legendary Ice Dagger
                { type: 'spell', id: 309, weight: 10, manaQuantity: 40 }, // Epic Charge
                { type: 'spell', id: 310, weight: 20, manaQuantity: 40 }, // Legendary Charge
                { type: 'mana', id: 2, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 30 },
                { type: 'body', id: 7, weight: 3 }, // Rare Knight Body
                { type: 'body', id: 10, weight: 6 }, // Epic Knight Body
                { type: 'body', id: 13, weight: 3 }, // Legendary Knight Body
                { type: 'top', id: 7, weight: 3 }, // Rare Knight Top
                { type: 'top', id: 10, weight: 6 }, // Epic Knight Top
                { type: 'top', id: 13, weight: 3 }, // Legendary Knight Top
                { type: 'hand', id: 7, weight: 3 }, // Rare Knight Hands
                { type: 'hand', id: 10, weight: 6 }, // Epic Knight Hands
                { type: 'hand', id: 13, weight: 3 }, // Legendary Knight Hands
            ],
            bronze: [
                { type: 'spell', id: 229, weight: 5 }, // Legendary Spear
            ],
            silver: [
                { type: 'spell', id: 224, weight: 5 }, // Legendary Ice Dagger
            ],
            gold: [
                { type: 'body', id: 13, weight: 3 }, // Legendary Knight Body
                { type: 'top', id: 13, weight: 3 }, // Legendary Knight Top
                { type: 'hand', id: 13, weight: 3 }, // Legendary Knight Hands
            ],
            platinum: [
                { type: 'body', id: 13, weight: 3 }, // Legendary Knight Body
                { type: 'top', id: 13, weight: 3 }, // Legendary Knight Top
                { type: 'hand', id: 13, weight: 3 }, // Legendary Knight Hands
            ]
        },
        'drops': [
            { type: 'spell', id: 216, weight: 5 }, // Epic Dagger,
        ]
    },
    {
        'enemyID': 41,
        'name': 'Ice Golem',
        'type': 'water',
        // 'radius': 18,
        'radius': 27,
        'spriteName': 'enemy_ice_golem',
        spine: true,
        'imgName': 'enemy_ice_golem.png',
        'tiledName': 'IceGolem',
        // 'spine': true,
        'shotDelay': true,
        'shotDelayLength': 400,
        'respawnCooldown': 90,
        'scoreOnKill': 230,
        'speed': 45,
        'hp': 330,
        'proximityRadius': 150,
        'shootingRange': 125,
        'spells': [448],
        'ai': {
            canMove: true, followTarget: false, lookAtTarget: true,
            //  predictLocation: true
        },
        'trialDropChance': {
            baseChance: 0.01,
            dropTable: [
                { id: 9, weight: 50 },
            ]
        },
        'dropTable': {
            starter: [
                { type: 'spell', id: 30, weight: 30, manaQuantity: 80 }, // Epic Triple Ice Bolt
                { type: 'spell', id: 86, weight: 5, manaQuantity: 40 }, // Rare Heal Self
                { type: 'spell', id: 87, weight: 5, manaQuantity: 40 }, // Epic Heal Self
                { type: 'spell', id: 88, weight: 3, manaQuantity: 40 }, // Legendary Heal Self
                { type: 'spell', id: 33, weight: 5, manaQuantity: 40 }, // Rare Healing Circle
                { type: 'spell', id: 34, weight: 5, manaQuantity: 40 }, // Epic Healing Circle,
                { type: 'spell', id: 35, weight: 3, manaQuantity: 40 }, // Legendary Healing Circle
                { type: 'spell', id: 9, weight: 15, manaQuantity: 80 }, // Epic Ice Nova,
                { type: 'spell', id: 42, weight: 5, manaQuantity: 80 }, // Legendary Ice Nova,
                { type: 'spell', id: 46, weight: 20, manaQuantity: 80 }, // Rare Double Ice Bolt,
                { type: 'spell', id: 49, weight: 20, manaQuantity: 80 }, // Epic Ice Bolt,
                { type: 'spell', id: 48, weight: 2, manaQuantity: 80 }, // Legendary Double Ice Bolt
                { type: 'spell', id: 50, weight: 2, manaQuantity: 80 }, // Legendary Ice Bolt
                { type: 'spell', id: 202, weight: 10 }, // Epic Katana,
                { type: 'spell', id: 223, weight: 5 }, // Epic Ice Dagger,
                { type: 'spell', id: 216, weight: 5 }, // Epic Dagger,
                { type: 'spell', id: 217, weight: 2 }, // Legendary Dagger,
                { type: 'mana', id: 2, weight: 20, manaQuantity: 80 },
                { type: 'body', id: 5, weight: 5 }, // Rare Wizard Robe,
                { type: 'body', id: 6, weight: 5 }, // Rare Rogue Robe
                { type: 'body', id: 7, weight: 5 }, // Rare Knight Armor
                { type: 'body', id: 8, weight: 10 }, // Epic Wizard Robe
                { type: 'body', id: 9, weight: 10 }, // Epic Rogue Robe
                { type: 'body', id: 10, weight: 10 }, // Epic Knight Robe
                { type: 'body', id: 11, weight: 2 }, // Legendary Wizard Body,
                { type: 'body', id: 12, weight: 2 }, // Legendary Rogue Body,
                { type: 'body', id: 13, weight: 2 }, // Lgendary Knight Armor,
                { type: 'top', id: 5, weight: 5 }, // Rare Wizard Hat
                { type: 'top', id: 6, weight: 5 }, // Rare Rogue Hat
                { type: 'top', id: 7, weight: 5 }, // Rare Knight Hat
                { type: 'top', id: 8, weight: 10 }, // Epic Wizard Hat
                { type: 'top', id: 9, weight: 10 }, // Epic Rogue Hat
                { type: 'top', id: 10, weight: 10 }, // Epic Knight Hat
                { type: 'top', id: 11, weight: 2 }, // Legendary Wizard Hat
                { type: 'top', id: 12, weight: 2 }, // Legendary Rogue Hat
                { type: 'top', id: 13, weight: 2 }, // Legendary Knight Hat
                { type: 'hand', id: 5, weight: 5 }, // Rare Wizard Gloves
                { type: 'hand', id: 6, weight: 5 }, // Rare Rogue Gloves
                { type: 'hand', id: 7, weight: 5 }, // Rare Knight Gloves
                { type: 'hand', id: 8, weight: 10 }, // Epic Wizard Gloves
                { type: 'hand', id: 9, weight: 10 }, // Epic Rogue Gloves
                { type: 'hand', id: 10, weight: 10 }, // Epic Knight Gloves
                { type: 'hand', id: 11, weight: 2 }, // Legendary Wizard Gloves
                { type: 'hand', id: 12, weight: 2 }, // Legendary Rogue Gloves
                { type: 'hand', id: 13, weight: 2 }, // Legendary Knight Gloves
            ],
            bronze: [
                { type: 'hand', id: 11, weight: 2 }, // Legendary Wizard Gloves
                { type: 'hand', id: 12, weight: 2 }, // Legendary Rogue Gloves
                { type: 'hand', id: 13, weight: 2 }, // Legendary Knight Gloves
            ],
            silver: [
                { type: 'top', id: 11, weight: 2 }, // Legendary Wizard Hat
                { type: 'top', id: 12, weight: 2 }, // Legendary Rogue Hat
                { type: 'top', id: 13, weight: 2 }, // Legendary Knight Hat
            ],
            gold: [
                { type: 'body', id: 11, weight: 2 }, // Legendary Wizard Body,
                { type: 'body', id: 12, weight: 2 }, // Legendary Rogue Body,
                { type: 'body', id: 13, weight: 2 }, // Lgendary Knight Armor,
            ],
            platinum: [
                { type: 'spell', id: 31, weight: 8, manaQuantity: 80 }, // Legendary Quintuple Ice Bolt
            ]
        },
        'drops': [
            { type: 'hand', id: 13, weight: 2 },
        ]
    },
    {
        'enemyID': 42,
        'name': 'Ice Golem Boss',
        'type': 'water',
        // 'radius': 18,
        'radius': 50,
        'spriteName': 'enemy_ice_golem_boss',
        'imgName': 'enemy_ice_golem_boss.png',
        'tiledName': 'IceGolemBoss',
        'spine': true,
        'shotDelay': true,
        'shotDelayLength': 400,
        'respawnCooldown': 90,
        'scoreOnKill': 230,
        'speed': 45,
        'hp': 1100,
        'proximityRadius': 350,
        'shootingRange': 300,
        'spells': [459, 460, 466],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true, predictLocation: true },
        'numberOfDrops': 2,
        'dropTable': {
            starter: [
                { type: 'spell', id: 30, weight: 15, manaQuantity: 80 }, // Epic Triple Ice Bolt
                { type: 'spell', id: 9, weight: 15, manaQuantity: 80 }, // Epic Ice Nova
                { type: 'spell', id: 42, weight: 5, manaQuantity: 80 }, // Legendary Ice Nova
                { type: 'spell', id: 46, weight: 20, manaQuantity: 80 }, // Rare Double Ice Bolt
                { type: 'spell', id: 49, weight: 20, manaQuantity: 80 }, // Epic Ice Bolt
                { type: 'spell', id: 31, weight: 30, manaQuantity: 80 }, // Legendary Quintuple Ice Bolt
                { type: 'spell', id: 48, weight: 15, manaQuantity: 80 }, // Legendary Double Ice Bolt
                { type: 'spell', id: 50, weight: 15, manaQuantity: 80 }, // Legendary Ice Bolt
                // { type: 'spell', id: 202, weight: 10 }, // Epic Katana
                // { type: 'spell', id: 216, weight: 5 }, // Epic Dagger,
                { type: 'spell', id: 217, weight: 2 }, // Legendary Dagger,
                // { type: 'spell', id: 230, weight: 10 }, // Legendary Shard,
                { type: 'mana', id: 2, weight: 20, manaQuantity: 80 },
                { type: 'body', id: 18, weight: 10 }, // Legenary Ice Armor
                { type: 'top', id: 18, weight: 10 }, // Legenary Ice Helmet
                { type: 'hand', id: 18, weight: 10 }, // Legenary Ice Gauntlets
                // { type: 'body', id: 8, weight: 10 }, // Epic Wizard Body
                // { type: 'body', id: 9, weight: 10 }, // Epic Rogue body
                // { type: 'body', id: 10, weight: 10 }, // Epic Knight Body
                // { type: 'body', id: 11, weight: 7 }, // Legendary Wizard Body
                // { type: 'body', id: 12, weight: 7 }, // Legendary Rogue Body
                // { type: 'body', id: 13, weight: 7 }, // Legendary Knight Body
                // { type: 'top', id: 8, weight: 7 }, // Epic Wizard Hat
                // { type: 'top', id: 9, weight: 7 }, // Epic Rogue Hat
                // { type: 'top', id: 10, weight: 7 }, // Epic Knight Hat
                // { type: 'top', id: 11, weight: 7 }, // Legendary Wizard Hat
                // { type: 'top', id: 12, weight: 7 }, // Legendary Rogue Hat
                // { type: 'top', id: 13, weight: 7 }, // Legendary Knight Hat
                // { type: 'hand', id: 8, weight: 7 }, // Epic Wizard Gloves
                // { type: 'hand', id: 9, weight: 7 }, // Epic Rogue Gloves
                // { type: 'hand', id: 10, weight: 7 }, // Epic Knight Gloves
                // { type: 'hand', id: 11, weight: 7 }, // Legendary Wizard Gloves
                // { type: 'hand', id: 12, weight: 7 }, // Legendary Rogue Gloves
                // { type: 'hand', id: 13, weight: 7 }, // Legendary Knight Gloves
            ],
            bronze: [
                { type: 'spell', id: 230, weight: 10 }, // Legendary Shard,
            ],
            silver: [
                { type: 'body', id: 18, weight: 5 }, // Legenary Ice Armor
                { type: 'top', id: 18, weight: 5 }, // Legenary Ice Helmet
                { type: 'hand', id: 18, weight: 5 }, // Legenary Ice Gauntlets
            ],
            gold: [
                { type: 'body', id: 18, weight: 5 }, // Legenary Ice Armor
                { type: 'top', id: 18, weight: 5 }, // Legenary Ice Helmet
                { type: 'hand', id: 18, weight: 5 }, // Legenary Ice Gauntlets
            ],
            platinum: [
                { type: 'spell', id: 230, weight: 10 }, // Legendary Shard,
            ]
        },
        'drops': [
            { type: 'spell', id: 30, weight: 15, manaQuantity: 80 }, // Epic Triple Ice Bolt
        ]
    },
    {
        'enemyID': 43,
        'name': 'Ice Slime',
        'radius': 10,
        // 'spriteName': 'slime',
        'spriteName': 'iceSlime',
        'imgName': 'enemy_iceSlime.png',
        'type': 'water',
        'tiledName': 'IceSlime',
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 300,
        'scoreOnKill': 100,
        'speed': 80,
        'hp': 55,
        'randomTint': true,
        'proximityRadius': 250,
        'shootingRange': 250,
        'spells': [450],
        'ai': { canMove: true, followTarget: false, lookAtTarget: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 8, weight: 10, manaQuantity: 60 }, // Legendary Gravity Ball
                { type: 'spell', id: 208, weight: 10, manaQuantity: 60 }, // Rare Sword,
                { type: 'spell', id: 86, weight: 10, manaQuantity: 60 }, // Rare Heal Self,
                { type: 'spell', id: 302, weight: 10, manaQuantity: 60 }, // Rare Dash
                { type: 'spell', id: 308, weight: 10, manaQuantity: 60 }, // Rare Charge,
                { type: 'spell', id: 312, weight: 10, manaQuantity: 60 }, // Rare Stealth,
                { type: 'mana', id: 1, weight: 20, manaQuantity: 60 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 60 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 60 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 60 },
                { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                { type: 'top', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'top', id: 7, weight: 10 }, // Rare Knight Armor,
                { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gauntlets
            ],
            bronze: [
                { type: 'spell', id: 228, weight: 5 }, // Epic Spear
            ],
            silver: [
                { type: 'hand', id: 16, weight: 3 }, // Legendary Slime Gloves
            ],
            gold: [
                { type: 'body', id: 16, weight: 3 }, // Legendary Slime Body
            ],
            platinum: [
                { type: 'top', id: 16, weight: 3 }, // Legendary Slime Helmet
            ]
        },
        'drops': [
            { type: 'spell', id: 8, weight: 10, manaQuantity: 60 }, // Legendary Gravity Ball
        ]
    },
    {
        'enemyID': 44,
        'name': 'Ice Slime Boss',
        'radius': 40,
        // 'spriteName': 'slime',
        'spriteName': 'iceSlimeBoss',
        'imgName': 'enemy_iceSlime.png',
        'type': 'water',
        'tiledName': 'IceSlimeBoss',
        boss: true,
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 300,
        'scoreOnKill': 350,
        'speed': 80,
        'hp': 1500,
        'randomTint': true,
        'proximityRadius': 250,
        'shootingRange': 250,
        'spells': [453, 422, 451, 452],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'numberOfDrops': 3,
        'dropTable': {
            starter: [
                { type: 'spell', id: 8, weight: 10, manaQuantity: 60 }, // Legendary Gravity Ball
                { type: 'spell', id: 42, weight: 5, manaQuantity: 80 }, // Legendary Ice Nova
                { type: 'spell', id: 46, weight: 20, manaQuantity: 80 }, // Rare Double Ice Bolt
                { type: 'spell', id: 49, weight: 20, manaQuantity: 80 }, // Epic Ice Bolt
                { type: 'spell', id: 30, weight: 15, manaQuantity: 80 }, // Epic Triple Ice Bolt
                { type: 'spell', id: 9, weight: 15, manaQuantity: 80 }, // Epic Ice Nova
                { type: 'mana', id: 1, weight: 20, manaQuantity: 60 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 60 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 60 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 60 },
                { type: 'top', id: 16, weight: 6 }, // Legendary Slime Gauntlets
                { type: 'body', id: 16, weight: 6 }, // Legendary Slime Body
                { type: 'hand', id: 16, weight: 6 }, // Legendary Slime Gloves
            ],
            bronze: [
                { type: 'hand', id: 16, weight: 6 }, // Legendary Slime Gloves
            ],
            silver: [
                { type: 'body', id: 16, weight: 6 }, // Legendary Slime Body
            ],
            gold: [
                { type: 'top', id: 16, weight: 6 }, // Legendary Slime Gauntlets
            ],
            platinum: [
                { type: 'spell', id: 31, weight: 8, manaQuantity: 80 }, // Legendary Quintuple Ice Bolt
            ]
        },
        'drops': [
            { type: 'spell', id: 8, weight: 10, manaQuantity: 60 }, // Legendary Gravity Ball
        ]
    },
    {
        'enemyID': 45,
        'name': 'Jack O Charlie',
        seasonalBoss: true,
        'boss': true,
        'type': 'dark',
        'radius': 50,
        'spriteName': 'bossCharlieHalloween',
        'imgName': 'enemy_charlieHalloween.png',
        'tiledName': 'CharlieHalloween',
        'spine': true,
        'spineTwoAttacks': true,
        'respawnCooldown': 300,
        // 'respawnCooldown': 5,
        'scoreOnKill': 2000,
        // 'speed': 70,
        'noWaterSlow': true,
        'noGravity': true,
        'noKnockback': true,
        noSlow: true,
        noStun: true,
        noRoot: true,
        'speed': 95,
        'hp': 2500,
        'proximityRadius': 350,
        // 'shootingRange': 80,
        'shootingRange': 250,
        // 'spells': [462, 461, 463],
        'spells': [462,
            //  464,
            461,
            465
        ],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'numberOfDrops': 2,
        'dropTable': {
            starter: [
                { type: 'spell', id: 8, weight: 10, manaQuantity: 30 }, // Legendary Gravity Ball
                { type: 'spell', id: 202, weight: 10, manaQuantity: 30 }, // Epic Ice Katana
                { type: 'spell', id: 203, weight: 10, manaQuantity: 30 }, // Epic Wind Katana
                { type: 'spell', id: 216, weight: 5 }, // Epic Dagger,
                { type: 'spell', id: 217, weight: 2 }, // Legendary Dagger,
                { type: 'spell', id: 33, weight: 10, manaQuantity: 30 }, // Rare Healing Circle
                { type: 'spell', id: 302, weight: 10, manaQuantity: 60 }, // Rare Dash
                { type: 'spell', id: 307, weight: 10, manaQuantity: 60 }, // Uncommon Charge
                { type: 'spell', id: 312, weight: 10, manaQuantity: 60 }, // Rare Stealth,
                { type: 'spell', id: 69, weight: 10, manaQuantity: 60 }, // Epic Binding Shot
                { type: 'spell', id: 70, weight: 10, manaQuantity: 60 }, // Legendary Binding Shot
                { type: 'mana', id: 1, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 30 },
                { type: 'body', id: 8, weight: 10 }, // Epic Wizard Body
                { type: 'body', id: 9, weight: 10 }, // Epic Rogue Body
                { type: 'body', id: 10, weight: 10 }, // Epic Knight Body
                { type: 'body', id: 11, weight: 5 }, // Legendary Wizard Body
                { type: 'body', id: 12, weight: 5 }, // Legendary Rogue Body
                { type: 'body', id: 13, weight: 5 }, // Legendary Knight Body
                { type: 'top', id: 8, weight: 10 }, // Epic Wizard Hat
                { type: 'top', id: 9, weight: 10 }, // Epic Rogue Hat
                { type: 'top', id: 10, weight: 10 }, // Epic Knight Hat
                { type: 'top', id: 11, weight: 5 }, // Legendary Wizard Hat
                { type: 'top', id: 12, weight: 5 }, // Legendary Rogue Hat
                { type: 'top', id: 13, weight: 5 }, // Legendary Knight Hat
                { type: 'hand', id: 8, weight: 10 }, // Epic Wizard Gloves
                { type: 'hand', id: 9, weight: 10 }, // Epic Rogue Gloves
                { type: 'hand', id: 10, weight: 10 }, // Epic Knight Gauntlets
                { type: 'hand', id: 11, weight: 5 }, // Legendary Wizard Gloves
                { type: 'hand', id: 12, weight: 5 }, // Legendary Rogue Gloves
                { type: 'hand', id: 13, weight: 5 }, // Legendary Knight Gauntlets
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': [
            { type: 'spell', id: 8, weight: 10, manaQuantity: 30 }, // Legendary Gravity Ball
            // { type: 'spell', id: 202, weight: 10, manaQuantity: 30 }, // Epic Ice Katana
            // { type: 'spell', id: 203, weight: 10, manaQuantity: 30 }, // Epic Wind Katana
            // { type: 'spell', id: 216, weight: 5 }, // Epic Dagger,
            // { type: 'spell', id: 217, weight: 2 }, // Legendary Dagger,
            // { type: 'spell', id: 33, weight: 10, manaQuantity: 30 }, // Rare Healing Circle
            // { type: 'spell', id: 302, weight: 10, manaQuantity: 60 }, // Rare Dash
            // { type: 'spell', id: 307, weight: 10, manaQuantity: 60 }, // Uncommon Charge
            // { type: 'spell', id: 312, weight: 10, manaQuantity: 60 }, // Rare Stealth,
            // { type: 'spell', id: 69, weight: 10, manaQuantity: 60 }, // Epic Binding Shot
            // { type: 'spell', id: 70, weight: 10, manaQuantity: 60 }, // Legendary Binding Shot
            // { type: 'mana', id: 1, weight: 20, manaQuantity: 30 },
            // { type: 'mana', id: 2, weight: 20, manaQuantity: 30 },
            // { type: 'mana', id: 3, weight: 20, manaQuantity: 30 },
            // { type: 'mana', id: 4, weight: 20, manaQuantity: 30 },
            // { type: 'body', id: 8, weight: 10 }, // Epic Wizard Body
            // { type: 'body', id: 9, weight: 10 }, // Epic Rogue Body
            // { type: 'body', id: 10, weight: 10 }, // Epic Knight Body
            // { type: 'body', id: 11, weight: 5 }, // Legendary Wizard Body
            // { type: 'body', id: 12, weight: 5 }, // Legendary Rogue Body
            // { type: 'body', id: 13, weight: 5 }, // Legendary Knight Body
            // { type: 'top', id: 8, weight: 10 }, // Epic Wizard Hat
            // { type: 'top', id: 9, weight: 10 }, // Epic Rogue Hat
            // { type: 'top', id: 10, weight: 10 }, // Epic Knight Hat
            // { type: 'top', id: 11, weight: 5 }, // Legendary Wizard Hat
            // { type: 'top', id: 12, weight: 5 }, // Legendary Rogue Hat
            // { type: 'top', id: 13, weight: 5 }, // Legendary Knight Hat
            // { type: 'hand', id: 8, weight: 10 }, // Epic Wizard Gloves
            // { type: 'hand', id: 9, weight: 10 }, // Epic Rogue Gloves
            // { type: 'hand', id: 10, weight: 10 }, // Epic Knight Gauntlets
            // { type: 'hand', id: 11, weight: 5 }, // Legendary Wizard Gloves
            // { type: 'hand', id: 12, weight: 5 }, // Legendary Rogue Gloves
            // { type: 'hand', id: 13, weight: 5 }, // Legendary Knight Gauntlets
        ]
    },
    {
        'enemyID': 46,
        'name': 'Gravity Cannon',
        'radius': 7,
        'type': 'dark',
        'spriteName': 'cannon',
        'imgName': 'enemy_cannon.png',
        'tiledName': 'CannonGravity',
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 90,
        'scoreOnKill': 100,
        'speed': 0,
        'hp': 100,
        'proximityRadius': 150,
        'shootingRange': 140,
        'spells': [8],
        'ai': { canMove: false, followTarget: false, lookAtTarget: true },
        'dropTable': {
            starter: [
                { type: 'spell', id: 1, weight: 10, manaQuantity: 30 }, // Rare Fireball
                { type: 'spell', id: 5, weight: 10, manaQuantity: 30 }, // Rare Ice Bolt
                { type: 'spell', id: 10, weight: 10, manaQuantity: 30 }, // Rare Wind Bolt
                { type: 'spell', id: 33, weight: 10, manaQuantity: 30 }, // Rare Healing Circle
                { type: 'spell', id: 208, weight: 20, manaQuantity: 30 }, // Rare Sword
                { type: 'mana', id: 1, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 30 },
                { type: 'mana', id: 5, weight: 20, manaQuantity: 30 },
                { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                { type: 'body', id: 8, weight: 2 }, // Epic Wizard Body,
                { type: 'body', id: 9, weight: 2 }, // Epic Rogue Body,
                { type: 'body', id: 10, weight: 2 }, // Epic Knight Body,
                { type: 'top', id: 5, weight: 10 }, // Rare Wizard Body,
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Body,
                { type: 'top', id: 7, weight: 10 }, // Rare Knight Armor,
                { type: 'top', id: 8, weight: 1 }, // Epic Wizard Hat,
                { type: 'top', id: 9, weight: 1 }, // Epic Rogue Hat,
                { type: 'top', id: 10, weight: 1 }, // Epic Knight Helm,
                { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves,
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gauntlets
                { type: 'hand', id: 8, weight: 1 }, // Epic Wizard Gloves,
                { type: 'hand', id: 9, weight: 1 }, // Epic Rogue Gloves,
                { type: 'hand', id: 10, weight: 1 }, // Epic Knight Gauntlets,
            ],
            bronze: [
                { type: 'hand', id: 8, weight: 1 }, // Epic Wizard Gloves,
                { type: 'hand', id: 9, weight: 1 }, // Epic Rogue Gloves,
                { type: 'hand', id: 10, weight: 1 }, // Epic Knight Gauntlets,
            ],
            silver: [
                { type: 'top', id: 8, weight: 1 }, // Epic Wizard Hat,
                { type: 'top', id: 9, weight: 1 }, // Epic Rogue Hat,
                { type: 'top', id: 10, weight: 1 }, // Epic Knight Helm,
            ],
            gold: [
                { type: 'body', id: 8, weight: 2 }, // Epic Wizard Body,
                { type: 'body', id: 9, weight: 2 }, // Epic Rogue Body,
                { type: 'body', id: 10, weight: 2 }, // Epic Knight Body,
            ],
            platinum: [
                { type: 'spell', id: 51, weight: 10, manaQuantity: 60 }, // Epic Fireball,
            ]
        },
        'drops': [
            { type: 'spell', id: 1, weight: 10, manaQuantity: 30 }, // Rare Fireball
        ]
    },
    {
        'enemyID': 47,
        'name': 'Melee Slime Boss',
        'boss': true,
        'radius': 40,
        // 'spriteName': 'slime',
        'spriteName': 'slimeMelee',
        'imgName': 'enemy_slime.png',
        'type': 'air',
        'tiledName': 'SlimeMeleeBoss',
        'spine': true,
        'shotDelay': true,
        'respawnCooldown': 300,
        'scoreOnKill': 350,
        'speed': 80,
        'hp': 550,
        'randomTint': true,
        'proximityRadius': 250,
        'shootingRange': 250,
        'spells': [477, 422],
        'ai': { canMove: true, followTarget: true, lookAtTarget: true },
        'numberOfDrops': 3,
        'dropTable': {
            starter: [
                { type: 'top', id: 14, weight: 10 }, // Rare Slime hat
                { type: 'body', id: 14, weight: 10 }, // Rare Slime Body
                { type: 'hand', id: 14, weight: 10 }, // Rare Slime Hands
                { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body
                { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body
                { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
                { type: 'top', id: 5, weight: 10 }, // Rare Wizard Hat
                { type: 'top', id: 6, weight: 10 }, // Rare Rogue Hat
                { type: 'top', id: 7, weight: 10 }, // Rare Knight Hat
                { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves
                { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gloves
                { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gloves
                { type: 'spell', id: 8, weight: 10, manaQuantity: 60 }, // Legendary Gravity Ball
                { type: 'spell', id: 208, weight: 10, manaQuantity: 60 }, // Rare Sword
                { type: 'spell', id: 86, weight: 10, manaQuantity: 60 }, // Rare Heal Self
                { type: 'spell', id: 302, weight: 10, manaQuantity: 60 }, // Rare Dash
                { type: 'spell', id: 308, weight: 10, manaQuantity: 60 }, // Rare Charge
                { type: 'spell', id: 312, weight: 10, manaQuantity: 60 }, // Rare Stealth
            ],
            bronze: [
                { type: 'top', id: 14, weight: 5 }, // Rare Slime hat
                { type: 'body', id: 14, weight: 5 }, // Rare Slime Body
                { type: 'hand', id: 14, weight: 5 }, // Rare Slime Hands
            ],
            silver: [
                { type: 'top', id: 14, weight: 5 }, // Rare Slime hat
                { type: 'body', id: 14, weight: 5 }, // Rare Slime Body
                { type: 'hand', id: 14, weight: 5 }, // Rare Slime Hands
            ],
            gold: [
                { type: 'top', id: 14, weight: 5 }, // Rare Slime hat
                { type: 'body', id: 14, weight: 5 }, // Rare Slime Body
                { type: 'hand', id: 14, weight: 5 }, // Rare Slime Hands
            ],
            platinum: [
                { type: 'top', id: 14, weight: 5 }, // Rare Slime hat
                { type: 'body', id: 14, weight: 5 }, // Rare Slime Body
                { type: 'hand', id: 14, weight: 5 }, // Rare Slime Hands
            ]
        },
        'drops': [
            { type: 'top', id: 14, weight: 10 }, // Rare Slime hat
            { type: 'body', id: 14, weight: 10 }, // Rare Slime Body
            { type: 'hand', id: 14, weight: 10 }, // Rare Slime Hands
            { type: 'spell', id: 8, weight: 10, manaQuantity: 60 }, // Legendary Gravity Ball
            { type: 'spell', id: 208, weight: 10, manaQuantity: 60 }, // Rare Sword
            { type: 'spell', id: 86, weight: 10, manaQuantity: 60 }, // Rare Heal Self
            { type: 'spell', id: 302, weight: 10, manaQuantity: 60 }, // Rare Dash
            { type: 'spell', id: 308, weight: 10, manaQuantity: 60 }, // Rare Charge
            { type: 'spell', id: 312, weight: 10, manaQuantity: 60 }, // Rare Stealth
            { type: 'mana', id: 1, weight: 20, manaQuantity: 60 },
            { type: 'mana', id: 2, weight: 20, manaQuantity: 60 },
            { type: 'mana', id: 3, weight: 20, manaQuantity: 60 },
            { type: 'mana', id: 4, weight: 20, manaQuantity: 60 },
            { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body
            { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body
            { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
            { type: 'top', id: 5, weight: 10 }, // Rare Wizard Hat
            { type: 'top', id: 6, weight: 10 }, // Rare Rogue Hat
            { type: 'top', id: 7, weight: 10 }, // Rare Knight Hat
            { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves
            { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gloves
            { type: 'hand', id: 7, weight: 10 }, // Rare Knight Gloves
        ]
    },
    {
        'enemyID': 100,
        'name': 'chestCommon',
        'type': null,
        'spine': false,
        'shootingRange': 0,
        'radius': 6,
        'spriteName': 'chestCommon',
        'tiledName': 'chestCommon',
        'imgName': 'chest_common.png',
        'isChest': true,
        'respawnCooldown': 300,
        'scoreOnKill': 0,
        // 'scoreOnKill': 50000,
        'speed': 0,
        'hp': 25,
        'proximityRadius': null,
        'noHealthBar': true,
        'spells': null,
        'resizeRadius': true,
        'noGravity': true,
        'noKnockback': true,
        'ai': { canMove: false, followTarget: false, lookAtTarget: false },
        'numberOfDrops': 2,
        'dropTable': {
            starter: [
                { type: 'mana', id: 1, weight: 20, manaQuantity: 25 },
                { type: 'mana', id: 2, weight: 20, manaQuantity: 25 },
                { type: 'mana', id: 3, weight: 20, manaQuantity: 25 },
                { type: 'mana', id: 4, weight: 20, manaQuantity: 25 },
                { type: 'mana', id: 5, weight: 10, manaQuantity: 25 },
                { type: 'mana', id: 6, weight: 10, manaQuantity: 25 },
                { type: 'mana', id: 1, weight: 2, manaQuantity: 75 },
                { type: 'mana', id: 2, weight: 2, manaQuantity: 75 },
                { type: 'mana', id: 3, weight: 2, manaQuantity: 75 },
                { type: 'mana', id: 4, weight: 22, manaQuantity: 75 },
                { type: 'mana', id: 5, weight: 1, manaQuantity: 75 },
                { type: 'mana', id: 6, weight: 1, manaQuantity: 75 },
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': [
            { type: 'mana', id: 1, weight: 20, manaQuantity: 25 },
            // { type: 'mana', id: 2, weight: 20, manaQuantity: 25 },
            // { type: 'mana', id: 3, weight: 20, manaQuantity: 25 },
            // { type: 'mana', id: 4, weight: 20, manaQuantity: 25 },
            // { type: 'mana', id: 5, weight: 10, manaQuantity: 25 },
            // { type: 'mana', id: 6, weight: 10, manaQuantity: 25 },
            // { type: 'mana', id: 1, weight: 2, manaQuantity: 75 },
            // { type: 'mana', id: 2, weight: 2, manaQuantity: 75 },
            // { type: 'mana', id: 3, weight: 2, manaQuantity: 75 },
            // { type: 'mana', id: 4, weight: 22, manaQuantity: 75 },
            // { type: 'mana', id: 5, weight: 1, manaQuantity: 75 },
            // { type: 'mana', id: 6, weight: 1, manaQuantity: 75 },
        ]
    },
    {
        'enemyID': 101,
        'name': 'chestUncommon',
        'type': null,
        'spine': false,
        'shootingRange': 0,
        'radius': 6,
        'spriteName': 'chestUncommon',
        'tiledName': 'chestUncommon',
        'imgName': 'chest_uncommon.png',
        'isChest': true,
        'respawnCooldown': 300,
        'scoreOnKill': 0,
        'speed': 0,
        'hp': 50,
        'proximityRadius': null,
        'noHealthBar': true,
        'spells': null,
        'resizeRadius': true,
        'noGravity': true,
        'noKnockback': true,
        'ai': { canMove: false, followTarget: false, lookAtTarget: false },
        'dropTable': {
            starter: [
                { type: 'spell', id: 6, weight: 10, manaQuantity: 40 }, // Uncommon Wind Shot
                { type: 'spell', id: 11, weight: 10, manaQuantity: 40 }, // Uncommon Fire Shot
                { type: 'spell', id: 201, weight: 10, manaQuantity: 40 }, // Uncommon Sword
                { type: 'spell', id: 93, weight: 10, manaQuantity: 40 }, // Uncommon Bubbles
                { type: 'spell', id: 214, weight: 5 }, // Uncommon Dagger
                { type: 'spell', id: 85, weight: 10, manaQuantity: 40 }, // Uncommon Heal Self
                { type: 'spell', id: 301, weight: 10, manaQuantity: 40 }, // Uncommon Dash,
                { type: 'spell', id: 301, weight: 10, manaQuantity: 40 }, // Uncommon Dash,
                { type: 'spell', id: 67, weight: 10, manaQuantity: 40 }, // Uncommon Binding Shot
                { type: 'spell', id: 63, weight: 10, manaQuantity: 40 }, // Uncommon Earthquake
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': [
            { type: 'spell', id: 6, weight: 10, manaQuantity: 40 }, // Uncommon Wind Shot
            // { type: 'spell', id: 11, weight: 10, manaQuantity: 40 }, // Uncommon Fire Shot
            // { type: 'spell', id: 201, weight: 10, manaQuantity: 40 }, // Uncommon Sword
            // { type: 'spell', id: 93, weight: 10, manaQuantity: 40 }, // Uncommon Bubbles
            // { type: 'spell', id: 214, weight: 5 }, // Uncommon Dagger
            // { type: 'spell', id: 85, weight: 10, manaQuantity: 40 }, // Uncommon Heal Self
            // { type: 'spell', id: 301, weight: 10, manaQuantity: 40 }, // Uncommon Dash,
            // { type: 'spell', id: 301, weight: 10, manaQuantity: 40 }, // Uncommon Dash,
            // { type: 'spell', id: 67, weight: 10, manaQuantity: 40 }, // Uncommon Binding Shot
            // { type: 'spell', id: 63, weight: 10, manaQuantity: 40 }, // Uncommon Earthquake
        ]
    },
    {
        'enemyID': 102,
        'name': 'chestRare',
        'type': null,
        'spine': false,
        'shootingRange': 0,
        'radius': 6,
        'spriteName': 'chestRare',
        'tiledName': 'chestRare',
        'imgName': 'chest_rare.png',
        'isChest': true,
        'respawnCooldown': 300,
        'scoreOnKill': 0,
        'speed': 0,
        'hp': 50,
        'proximityRadius': null,
        'noHealthBar': true,
        'spells': null,
        'resizeRadius': true,
        'noGravity': true,
        'noKnockback': true,
        'ai': { canMove: false, followTarget: false, lookAtTarget: false },
        'dropTable': {
            starter: [
                { type: 'spell', id: 1, weight: 10, manaQuantity: 50 }, // Rare Fireball
                { type: 'spell', id: 5, weight: 10, manaQuantity: 50 }, // Rare Ice Bolt
                { type: 'spell', id: 7, weight: 10, manaQuantity: 50 }, // Rare Implode
                { type: 'spell', id: 10, weight: 10, manaQuantity: 50 }, // Rare Wind Bolt
                { type: 'spell', id: 26, weight: 10, manaQuantity: 50 }, // Rare Triple Fireball
                { type: 'spell', id: 28, weight: 10, manaQuantity: 50 }, // Rare Gravity Bolt
                { type: 'spell', id: 33, weight: 20, manaQuantity: 50 }, // Rare Healing Circle
                { type: 'spell', id: 86, weight: 10, manaQuantity: 40 }, // Rare Heal Self
                { type: 'spell', id: 39, weight: 10, manaQuantity: 50 }, // Rare Triple Wind Shot
                { type: 'spell', id: 94, weight: 10, manaQuantity: 50 }, // Rare Bubbles
                { type: 'spell', id: 208, weight: 20, manaQuantity: 50 }, // Rare Sword
                { type: 'spell', id: 215, weight: 5, manaQuantity: 50 }, // Rare Dagger
                { type: 'spell', id: 302, weight: 10, manaQuantity: 40 }, // Rare Dash
                { type: 'spell', id: 308, weight: 10, manaQuantity: 40 }, // Rare Charge
                { type: 'spell', id: 312, weight: 10, manaQuantity: 40 }, // Rare Stealth
                { type: 'spell', id: 43, weight: 10, manaQuantity: 40 }, // Rare Double Fireball
                { type: 'spell', id: 46, weight: 10, manaQuantity: 40 }, // Rare Double Ice Bolt
                { type: 'spell', id: 55, weight: 10, manaQuantity: 60 }, // Rare Double Wind Bolt
                { type: 'spell', id: 68, weight: 10, manaQuantity: 40 }, // Rare Binding Shot
                { type: 'spell', id: 64, weight: 10, manaQuantity: 40 }, // Rare Earthquake
                { type: 'spell', id: 71, weight: 10, manaQuantity: 40 }, // Rare Dark Hole
                { type: 'spell', id: 75, weight: 10, manaQuantity: 40 }, // Rare Explode
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': [
            { type: 'spell', id: 1, weight: 10, manaQuantity: 50 }, // Rare Fireball
            // { type: 'spell', id: 5, weight: 10, manaQuantity: 50 }, // Rare Ice Bolt
            // { type: 'spell', id: 7, weight: 10, manaQuantity: 50 }, // Rare Implode
            // { type: 'spell', id: 10, weight: 10, manaQuantity: 50 }, // Rare Wind Bolt
            // { type: 'spell', id: 26, weight: 10, manaQuantity: 50 }, // Rare Triple Fireball
            // { type: 'spell', id: 28, weight: 10, manaQuantity: 50 }, // Rare Gravity Bolt
            // { type: 'spell', id: 33, weight: 20, manaQuantity: 50 }, // Rare Healing Circle
            // { type: 'spell', id: 86, weight: 10, manaQuantity: 40 }, // Rare Heal Self
            // { type: 'spell', id: 39, weight: 10, manaQuantity: 50 }, // Rare Triple Wind Shot
            // { type: 'spell', id: 94, weight: 10, manaQuantity: 50 }, // Rare Bubbles
            // { type: 'spell', id: 208, weight: 20, manaQuantity: 50 }, // Rare Sword
            // { type: 'spell', id: 215, weight: 5, manaQuantity: 50 }, // Rare Dagger
            // { type: 'spell', id: 302, weight: 10, manaQuantity: 40 }, // Rare Dash
            // { type: 'spell', id: 308, weight: 10, manaQuantity: 40 }, // Rare Charge
            // { type: 'spell', id: 312, weight: 10, manaQuantity: 40 }, // Rare Stealth
            // { type: 'spell', id: 43, weight: 10, manaQuantity: 40 }, // Rare Double Fireball
            // { type: 'spell', id: 46, weight: 10, manaQuantity: 40 }, // Rare Double Ice Bolt
            // { type: 'spell', id: 55, weight: 10, manaQuantity: 60 }, // Rare Double Wind Bolt
            // { type: 'spell', id: 68, weight: 10, manaQuantity: 40 }, // Rare Binding Shot
            // { type: 'spell', id: 64, weight: 10, manaQuantity: 40 }, // Rare Earthquake
            // { type: 'spell', id: 71, weight: 10, manaQuantity: 40 }, // Rare Dark Hole
            // { type: 'spell', id: 75, weight: 10, manaQuantity: 40 }, // Rare Explode
        ]
    },
    {
        'enemyID': 103,
        'name': 'chestEpic',
        'type': null,
        'spine': false,
        'shootingRange': 0,
        'radius': 6,
        'spriteName': 'chestEpic',
        'tiledName': 'chestEpic',
        'imgName': 'chest_epic.png',
        'isChest': true,
        'respawnCooldown': 300,
        'scoreOnKill': 0,
        'speed': 0,
        'hp': 50,
        'proximityRadius': null,
        'noHealthBar': true,
        'spells': null,
        'resizeRadius': true,
        'noGravity': true,
        'noKnockback': true,
        'ai': { canMove: false, followTarget: false, lookAtTarget: false },
        'dropTable': {
            starter: [
                { type: 'spell', id: 2, weight: 10, manaQuantity: 60 }, // Epic Meteor
                { type: 'spell', id: 3, weight: 10, manaQuantity: 60 }, // Epic Explode
                { type: 'spell', id: 9, weight: 40, manaQuantity: 60 }, // Epic Ice Nova
                { type: 'spell', id: 202, weight: 20, manaQuantity: 60 }, // Epic Ice Katana
                { type: 'spell', id: 203, weight: 20, manaQuantity: 60 }, // Epic Wind Katana
                { type: 'spell', id: 216, weight: 5 }, // Epic Dagger
                { type: 'spell', id: 95, weight: 40, manaQuantity: 60 }, // Epic Bubbles
                { type: 'spell', id: 29, weight: 10, manaQuantity: 60 }, // Epic Triple Gravity Bolt
                { type: 'spell', id: 30, weight: 10, manaQuantity: 60 }, // Epic Triple Ice Bolt
                { type: 'spell', id: 34, weight: 10, manaQuantity: 60 }, // Epic Healing Circle
                { type: 'spell', id: 87, weight: 10, manaQuantity: 60 }, // Epic Heal Self
                { type: 'spell', id: 37, weight: 10, manaQuantity: 60 }, // Epic Triple Wind Bolt
                { type: 'spell', id: 303, weight: 10, manaQuantity: 60 }, // Epic Dash
                { type: 'spell', id: 309, weight: 10, manaQuantity: 60 }, // Epic Charge
                { type: 'spell', id: 313, weight: 10, manaQuantity: 60 }, // Epic Stealth
                { type: 'spell', id: 44, weight: 10, manaQuantity: 60 }, // Epic Double Fireball
                { type: 'spell', id: 47, weight: 10, manaQuantity: 60 }, // Epic Double Ice Bolt
                { type: 'spell', id: 49, weight: 10, manaQuantity: 60 }, // Epic Ice Bolt
                { type: 'spell', id: 51, weight: 10, manaQuantity: 60 }, // Epic Fireball,
                { type: 'spell', id: 56, weight: 10, manaQuantity: 60 }, // Epic Double Wind Bolt
                { type: 'spell', id: 53, weight: 10, manaQuantity: 60 }, // Epic Wind Bolt
                { type: 'spell', id: 69, weight: 10, manaQuantity: 60 }, // Epic Binding Shot
                { type: 'spell', id: 65, weight: 10, manaQuantity: 60 }, // Epic Earthquake
                { type: 'spell', id: 72, weight: 10, manaQuantity: 60 }, // Epic Dark Hole
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': [
            { type: 'spell', id: 2, weight: 10, manaQuantity: 60 }, // Epic Meteor
            // { type: 'spell', id: 3, weight: 10, manaQuantity: 60 }, // Epic Explode
            // { type: 'spell', id: 9, weight: 40, manaQuantity: 60 }, // Epic Ice Nova
            // { type: 'spell', id: 202, weight: 20, manaQuantity: 60 }, // Epic Ice Katana
            // { type: 'spell', id: 203, weight: 20, manaQuantity: 60 }, // Epic Wind Katana
            // { type: 'spell', id: 216, weight: 5 }, // Epic Dagger
            // { type: 'spell', id: 95, weight: 40, manaQuantity: 60 }, // Epic Bubbles
            // { type: 'spell', id: 29, weight: 10, manaQuantity: 60 }, // Epic Triple Gravity Bolt
            // { type: 'spell', id: 30, weight: 10, manaQuantity: 60 }, // Epic Triple Ice Bolt
            // { type: 'spell', id: 34, weight: 10, manaQuantity: 60 }, // Epic Healing Circle
            // { type: 'spell', id: 87, weight: 10, manaQuantity: 60 }, // Epic Heal Self
            // { type: 'spell', id: 37, weight: 10, manaQuantity: 60 }, // Epic Triple Wind Bolt
            // { type: 'spell', id: 303, weight: 10, manaQuantity: 60 }, // Epic Dash
            // { type: 'spell', id: 309, weight: 10, manaQuantity: 60 }, // Epic Charge
            // { type: 'spell', id: 313, weight: 10, manaQuantity: 60 }, // Epic Stealth
            // { type: 'spell', id: 44, weight: 10, manaQuantity: 60 }, // Epic Double Fireball
            // { type: 'spell', id: 47, weight: 10, manaQuantity: 60 }, // Epic Double Ice Bolt
            // { type: 'spell', id: 49, weight: 10, manaQuantity: 60 }, // Epic Ice Bolt
            // { type: 'spell', id: 51, weight: 10, manaQuantity: 60 }, // Epic Fireball,
            // { type: 'spell', id: 56, weight: 10, manaQuantity: 60 }, // Epic Double Wind Bolt
            // { type: 'spell', id: 53, weight: 10, manaQuantity: 60 }, // Epic Wind Bolt
            // { type: 'spell', id: 69, weight: 10, manaQuantity: 60 }, // Epic Binding Shot
            // { type: 'spell', id: 65, weight: 10, manaQuantity: 60 }, // Epic Earthquake
            // { type: 'spell', id: 72, weight: 10, manaQuantity: 60 }, // Epic Dark Hole
        ]
    },
    {
        'enemyID': 104,
        'name': 'chestLegendary',
        'type': null,
        'spine': false,
        'shootingRange': 0,
        'radius': 6,
        'spriteName': 'chestLegendary',
        'tiledName': 'chestLegendary',
        'imgName': 'chest_legendary.png',
        'isChest': true,
        'respawnCooldown': 300,
        'scoreOnKill': 0,
        'speed': 0,
        'hp': 50,
        'proximityRadius': null,
        'noHealthBar': true,
        'spells': null,
        'resizeRadius': true,
        'noGravity': true,
        'noKnockback': true,
        'ai': { canMove: false, followTarget: false, lookAtTarget: false },
        'dropTable': {
            starter: [
                { type: 'spell', id: 8, weight: 10, manaQuantity: 70 }, // Legendary Gravity Ball
                { type: 'spell', id: 31, weight: 10, manaQuantity: 70 }, // Legendary Quintuple Ice Bolt
                { type: 'spell', id: 35, weight: 10, manaQuantity: 70 }, // Legendary Healing Circle
                { type: 'spell', id: 38, weight: 10, manaQuantity: 70 }, // Quintuple Wind Bolt
                { type: 'spell', id: 88, weight: 10, manaQuantity: 70 }, // Legendary Heal Self
                { type: 'spell', id: 42, weight: 10, manaQuantity: 70 }, // Legenadary Ice Nova
                { type: 'spell', id: 96, weight: 40, manaQuantity: 70 }, // Legendary Bubbles
                { type: 'spell', id: 217, weight: 5 }, // Legendary Dagger
                { type: 'spell', id: 304, weight: 10, manaQuantity: 70 }, // Legendary Dash
                { type: 'spell', id: 306, weight: 10, manaQuantity: 70 }, // Legendary Teleport
                { type: 'spell', id: 310, weight: 10, manaQuantity: 70 }, // Legendary Charge
                { type: 'spell', id: 314, weight: 10, manaQuantity: 70 }, // Legendary Stealth
                { type: 'spell', id: 45, weight: 10, manaQuantity: 70 }, // Legendary Double Fireball
                { type: 'spell', id: 48, weight: 10, manaQuantity: 70 }, // Legendary Double Ice Bolt
                { type: 'spell', id: 50, weight: 10, manaQuantity: 70 }, // Legendary Ice Bolt
                { type: 'spell', id: 52, weight: 10, manaQuantity: 70 }, // Legendary Fireball
                { type: 'spell', id: 54, weight: 10, manaQuantity: 70 }, // Legendary Wind Bolt
                { type: 'spell', id: 57, weight: 10, manaQuantity: 70 }, // Legendary Double Wind Bolt
                { type: 'spell', id: 70, weight: 10, manaQuantity: 70 }, // Legendary Binding Shot
                { type: 'spell', id: 66, weight: 10, manaQuantity: 70 }, // Legendary Earthquake
                { type: 'spell', id: 73, weight: 10, manaQuantity: 70 }, // Legendary Dark Hole
                { type: 'spell', id: 76, weight: 10, manaQuantity: 70 }, // Legendary Explode
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': [
            { type: 'spell', id: 8, weight: 10, manaQuantity: 70 }, // Legendary Gravity Ball
            // { type: 'spell', id: 31, weight: 10, manaQuantity: 70 }, // Legendary Quintuple Ice Bolt
            // { type: 'spell', id: 35, weight: 10, manaQuantity: 70 }, // Legendary Healing Circle
            // { type: 'spell', id: 38, weight: 10, manaQuantity: 70 }, // Quintuple Wind Bolt
            // { type: 'spell', id: 88, weight: 10, manaQuantity: 70 }, // Legendary Heal Self
            // { type: 'spell', id: 42, weight: 10, manaQuantity: 70 }, // Legenadary Ice Nova
            // { type: 'spell', id: 96, weight: 40, manaQuantity: 70 }, // Legendary Bubbles
            // { type: 'spell', id: 217, weight: 5 }, // Legendary Dagger
            // { type: 'spell', id: 304, weight: 10, manaQuantity: 70 }, // Legendary Dash
            // { type: 'spell', id: 306, weight: 10, manaQuantity: 70 }, // Legendary Teleport
            // { type: 'spell', id: 310, weight: 10, manaQuantity: 70 }, // Legendary Charge
            // { type: 'spell', id: 314, weight: 10, manaQuantity: 70 }, // Legendary Stealth
            // { type: 'spell', id: 45, weight: 10, manaQuantity: 70 }, // Legendary Double Fireball
            // { type: 'spell', id: 48, weight: 10, manaQuantity: 70 }, // Legendary Double Ice Bolt
            // { type: 'spell', id: 50, weight: 10, manaQuantity: 70 }, // Legendary Ice Bolt
            // { type: 'spell', id: 52, weight: 10, manaQuantity: 70 }, // Legendary Fireball
            // { type: 'spell', id: 54, weight: 10, manaQuantity: 70 }, // Legendary Wind Bolt
            // { type: 'spell', id: 57, weight: 10, manaQuantity: 70 }, // Legendary Double Wind Bolt
            // { type: 'spell', id: 70, weight: 10, manaQuantity: 70 }, // Legendary Binding Shot
            // { type: 'spell', id: 66, weight: 10, manaQuantity: 70 }, // Legendary Earthquake
            // { type: 'spell', id: 73, weight: 10, manaQuantity: 70 }, // Legendary Dark Hole
            // { type: 'spell', id: 76, weight: 10, manaQuantity: 70 }, // Legendary Explode
        ]
    },
    {
        'enemyID': 105, // PUMPKIN
        'name': 'chestPumpkin',
        'type': null,
        'spine': false,
        'shootingRange': 0,
        'radius': 10,
        'spriteName': 'chest_currency_pumpkin',
        'tiledName': 'chestCurrencyPumpkin',
        'imgName': 'chest_currency_christmass.png',
        'isChest': true,
        'respawnCooldown': 2,
        'scoreOnKill': 0,
        'speed': 0,
        'hp': 10,
        'proximityRadius': null,
        noRespawn: true,
        'noHealthBar': true,
        'spells': null,
        'resizeRadius': true,
        'noGravity': true,
        'noKnockback': true,
        'ai': { canMove: false, followTarget: false, lookAtTarget: false },
        'dropTable': {
            starter: [
                { type: 'currency', id: 1, weight: 20, quantity: 1 }, // Runes
                { type: 'currency', id: 1, weight: 4, quantity: 2 }, // Runes
                { type: 'currency', id: 1, weight: 1, quantity: 3 }, // Runes
                { type: 'currency', id: 1, weight: 1, quantity: 5 }, // Runes
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': [
            { type: 'currency', id: 1, weight: 20, quantity: 1 }, // Runes
        ]
    },
    {
        'enemyID': 106, // CHRISTMAS
        'name': 'chestPumpkin',
        'type': null,
        'spine': false,
        'shootingRange': 0,
        'radius': 10,
        'spriteName': 'chest_currency_pumpkin',
        'tiledName': 'chestCurrencyPumpkin',
        'imgName': 'chest_currency_christmass.png',
        'isChest': true,
        'respawnCooldown': 2,
        'scoreOnKill': 0,
        'speed': 0,
        'hp': 10,
        'proximityRadius': null,
        noRespawn: true,
        'noHealthBar': true,
        'spells': null,
        'resizeRadius': true,
        'noGravity': true,
        'noKnockback': true,
        'ai': { canMove: false, followTarget: false, lookAtTarget: false },
        'dropTable': {
            starter: [
                { type: 'currency', id: 1, weight: 20, quantity: 1 }, // Runes
                { type: 'currency', id: 1, weight: 4, quantity: 2 }, // Runes
                { type: 'currency', id: 1, weight: 1, quantity: 3 }, // Runes
                { type: 'currency', id: 1, weight: 1, quantity: 5 }, // Runes
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': [
            { type: 'currency', id: 1, weight: 20, quantity: 1 }, // Runes
        ]
    },
    {
        'enemyID': 107, // NORMAL
        'name': 'chestPumpkin',
        'type': null,
        'spine': false,
        'shootingRange': 0,
        'radius': 10,
        'spriteName': 'chest_currency_pumpkin',
        'tiledName': 'chestCurrencyPumpkin',
        'imgName': 'chest_currency.png',
        'isChest': true,
        'respawnCooldown': 2,
        'scoreOnKill': 0,
        'speed': 0,
        'hp': 100,
        // 'hp': 10,
        'proximityRadius': null,
        noRespawn: true,
        'noHealthBar': true,
        'spells': null,
        'resizeRadius': true,
        'noGravity': true,
        'noKnockback': true,
        'ai': { canMove: false, followTarget: false, lookAtTarget: false },
        'dropTable': {
            starter: [
                { type: 'currency', id: 1, weight: 20, quantity: 1 }, // Runes
                { type: 'currency', id: 1, weight: 4, quantity: 2 }, // Runes
                { type: 'currency', id: 1, weight: 1, quantity: 3 }, // Runes
                { type: 'currency', id: 1, weight: 1, quantity: 5 }, // Runes
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': [
            { type: 'currency', id: 1, weight: 20, quantity: 1 }, // Runes
        ]
    },
    {
        'enemyID': 199,
        'name': 'chestTest',
        'type': null,
        'spine': false,
        'shootingRange': 0,
        'radius': 6,
        'spriteName': 'chestTest',
        'tiledName': 'chestTest',
        'imgName': 'chest_test.png',
        'isChest': true,
        'respawnCooldown': 2,
        'scoreOnKill': 10000,
        'speed': 0,
        'hp': 1,
        'proximityRadius': null,
        'noHealthBar': true,
        'spells': null,
        'resizeRadius': true,
        'noGravity': true,
        'noKnockback': true,
        'numberOfDrops': 5,
        'ai': { canMove: false, followTarget: false, lookAtTarget: false },
        'dropTable': {
            starter: [
                { type: 'spell', id: 8, weight: 10, manaQuantity: 70 }, // Legendary Gravity Ball
                { type: 'spell', id: 31, weight: 10, manaQuantity: 70 }, // Legendary Quintuple Ice Bolt
                { type: 'spell', id: 35, weight: 10, manaQuantity: 70 }, // Legendary Healing Circle
                { type: 'spell', id: 38, weight: 10, manaQuantity: 70 }, // Legendary Quintuple Wind Bolt
                { type: 'spell', id: 88, weight: 10, manaQuantity: 70 }, // Legendary Heal Self
                { type: 'spell', id: 42, weight: 10, manaQuantity: 70 }, // Legendary Ice Nova
                { type: 'spell', id: 96, weight: 40, manaQuantity: 70 }, // Legendary Bubbles
                { type: 'spell', id: 217, weight: 5 }, // Legendary Dagger,
                { type: 'spell', id: 304, weight: 10, manaQuantity: 70 }, // Legendary Dash
                { type: 'spell', id: 306, weight: 10, manaQuantity: 70 }, // Legendary Teleport
                { type: 'spell', id: 310, weight: 10, manaQuantity: 70 }, // Legendary Charge
                { type: 'spell', id: 314, weight: 10, manaQuantity: 70 }, // Legendary Stealth
                { type: 'spell', id: 45, weight: 10, manaQuantity: 70 }, // Epic Double Fireball
                { type: 'spell', id: 48, weight: 10, manaQuantity: 70 }, // Legendary Ice Bolt
                { type: 'spell', id: 50, weight: 10, manaQuantity: 70 }, // Legendary Ice Bolt
                { type: 'spell', id: 52, weight: 10, manaQuantity: 70 }, // Legendary Fireball
                { type: 'spell', id: 54, weight: 10, manaQuantity: 70 }, // Legendary Wind Bolt
                { type: 'spell', id: 57, weight: 10, manaQuantity: 70 }, // Legendary Double Wind Bolt
                { type: 'spell', id: 70, weight: 10, manaQuantity: 70 }, // Legendary Binding Shot
                { type: 'spell', id: 66, weight: 10, manaQuantity: 70 }, // Legendary Earthquake
                { type: 'spell', id: 73, weight: 10, manaQuantity: 70 }, // Legendary Dark Hole
                { type: 'spell', id: 76, weight: 10, manaQuantity: 70 }, // Legendary Explode
                { type: 'spell', id: 36, weight: 10, manaQuantity: 50 }, // Legendary Meteor
                { type: 'spell', id: 27, weight: 10, manaQuantity: 50 }, // Legendary Quintuple Fireball
                { type: 'spell', id: 306, weight: 10, manaQuantity: 50 }, // Legendary Teleport
                { type: 'spell', id: 45, weight: 10, manaQuantity: 50 }, // Legendary Double Fireball
                { type: 'spell', id: 52, weight: 10, manaQuantity: 50 }, // Legendary Fireball
                { type: 'spell', id: 207, weight: 10, manaQuantity: 50 }, // Legendary Moonblade
                { type: 'spell', id: 216, weight: 5 }, // Epic Dagger,
                { type: 'spell', id: 217, weight: 2 }, // Legendary Dagger
                { type: 'spell', id: 202, weight: 5 }, // Epic Ice Katana
                { type: 'spell', id: 223, weight: 2 }, // Epic Ice Dagger
                { type: 'spell', id: 224, weight: 5 }, // Legendary Ice Dagger
                { type: 'spell', id: 90, weight: 30, manaQuantity: 40 }, // Epic Fire Stream
                { type: 'spell', id: 91, weight: 30, manaQuantity: 40 }, // Legendary Fire Stream
                { type: 'spell', id: 212, weight: 10 }, // Legendary Flame
                { type: 'spell', id: 309, weight: 20 }, // Epic Charge
                { type: 'spell', id: 310, weight: 20 }, // Legendary Charge
                { type: 'spell', id: 2, weight: 20, manaQuantity: 60 }, // Epic Meteor
                { type: 'spell', id: 26, weight: 20, manaQuantity: 60 }, // Rare Triple Fireball
                { type: 'spell', id: 27, weight: 20, manaQuantity: 60 }, // Legendary Quintuple Fireball
                { type: 'body', id: 11, weight: 10 }, // Legendary Wizard Body
                { type: 'body', id: 12, weight: 10 }, // Legendary Rogue Body
                { type: 'body', id: 13, weight: 10 }, // Legendary Knight Body
                { type: 'top', id: 11, weight: 10 }, // Legendary Wizard Hat
                { type: 'top', id: 12, weight: 10 }, // Legendary Rogue Hat
                { type: 'top', id: 13, weight: 10 }, // Legendary Knight Hat
                { type: 'hand', id: 11, weight: 10 }, // Legendary Wizard Gloves
                { type: 'hand', id: 12, weight: 10 }, // Legendary Rogue Gloves
                { type: 'hand', id: 13, weight: 10 }, // Legendary Knight Gauntlets
                { type: 'body', id: 16, weight: 10 }, // Legendary Slime Body
                { type: 'top', id: 16, weight: 10 }, // Legendary Slime Hat
                { type: 'hand', id: 16, weight: 10 }, // Legendary Slime Gauntlets
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': [
            { type: 'spell', id: 8, weight: 10, manaQuantity: 70 }, // Legendary Gravity Ball
        ]
    },
    {
        'enemyID': 900,
        'name': 'trialPillar',
        'type': null,
        'spine': false,
        'shootingRange': 0,
        'radius': 5,
        'spriteName': 'trial_pillar',
        'tiledName': 'trial_pillar',
        'imgName': 'trial_pillar.png',
        'respawnCooldown': 2,
        'scoreOnKill': 300,
        'speed': 0,
        'hp': 10000000,
        'proximityRadius': null,
        'noRespawn': true,
        'noHealthBar': true,
        'spells': null,
        'resizeRadius': true,
        'noGravity': true,
        'noKnockback': true,
        'ai': { canMove: false, followTarget: false, lookAtTarget: false },
        'dropTable': {
            starter: [
                { type: 'currency', id: 1, weight: 20, quantity: 1 }, // Runes
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': [
            { type: 'currency', id: 1, weight: 20, quantity: 1 }, // Runes
        ]
    },
    {
        'enemyID': 10203,
        'name': 'chestLegendary',
        'type': null,
        'spine': false,
        'shootingRange': 0,
        'radius': 6,
        'spriteName': 'chestLegendary',
        'tiledName': 'chestLegendaryTown',
        'imgName': 'chest_legendary.png',
        'isChest': true,
        'respawnCooldown': 300,
        'scoreOnKill': 300,
        'speed': 0,
        'hp': 999999,
        'proximityRadius': null,
        'noHealthBar': true,
        'spells': null,
        'resizeRadius': true,
        'noGravity': true,
        'noKnockback': true,
        'ai': { canMove: false, followTarget: false, lookAtTarget: false },
        'dropTable': {
            starter: [
                { type: 'spell', id: 8, weight: 10, manaQuantity: 70 }, // Legendary Gravity Ball
            ],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': []
    },
    {
        'enemyID': 10204,
        'name': 'chestTest',
        'type': null,
        'spine': false,
        'shootingRange': 0,
        'radius': 6,
        'spriteName': 'chestTest',
        'tiledName': 'chestTestTown',
        'imgName': 'chest_test.png',
        'isChest': true,
        'respawnCooldown': 2,
        'scoreOnKill': 0,
        'speed': 0,
        'hp': 999999,
        'proximityRadius': null,
        'noHealthBar': true,
        'spells': null,
        'resizeRadius': true,
        'noGravity': true,
        'noKnockback': true,
        'numberOfDrops': 5,
        'ai': { canMove: false, followTarget: false, lookAtTarget: false },
        'dropTable': {
            starter: [],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': []
    },
    {
        'enemyID': 10205,
        'name': 'chestUncommon',
        'type': null,
        'spine': false,
        'shootingRange': 0,
        'radius': 6,
        'spriteName': 'chestUncommon',
        'tiledName': 'chestUncommonTown',
        'imgName': 'chest_uncommon.png',
        'isChest': true,
        'respawnCooldown': 300,
        'scoreOnKill': 100,
        'speed': 0,
        'hp': 999999,
        'proximityRadius': null,
        'noHealthBar': true,
        'spells': null,
        'resizeRadius': true,
        'noGravity': true,
        'noKnockback': true,
        'ai': { canMove: false, followTarget: false, lookAtTarget: false },
        'dropTable': {
            starter: [],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': []
    },
    {
        'enemyID': 10206,
        'name': 'chestEpic',
        'type': null,
        'spine': false,
        'shootingRange': 0,
        'radius': 6,
        'spriteName': 'chestEpic',
        'tiledName': 'chestEpicTown',
        'imgName': 'chest_epic.png',
        'isChest': true,
        'respawnCooldown': 300,
        'scoreOnKill': 200,
        'speed': 0,
        'hp': 999999,
        'proximityRadius': null,
        'noHealthBar': true,
        'spells': null,
        'resizeRadius': true,
        'noGravity': true,
        'noKnockback': true,
        'ai': { canMove: false, followTarget: false, lookAtTarget: false },
        'dropTable': {
            starter: [],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': []
    },
    {
        'enemyID': 10207,
        'name': 'chestRare',
        'type': null,
        'spine': false,
        'shootingRange': 0,
        'radius': 6,
        'spriteName': 'chestRare',
        'tiledName': 'chestRareTown',
        'imgName': 'chest_rare.png',
        'isChest': true,
        'respawnCooldown': 300,
        'scoreOnKill': 150,
        'speed': 0,
        'hp': 999999,
        'proximityRadius': null,
        'noHealthBar': true,
        'spells': null,
        'resizeRadius': true,
        'noGravity': true,
        'noKnockback': true,
        'ai': { canMove: false, followTarget: false, lookAtTarget: false },
        'dropTable': {
            starter: [],
            bronze: [],
            silver: [],
            gold: [],
            platinum: []
        },
        'drops': []
    },
];
function generateNPCAndPetList() {
    const PetNPCConstantsBase = [];
    exports.EnemyConstantsBase.map(enemy => JSON.parse(JSON.stringify(enemy))).forEach(enemy => {
        if (enemy.type !== null) {
            let newNPC = enemy;
            const NPCAi = { canMove: false, followTarget: false, lookAtTarget: true };
            newNPC.enemyID = 8000 + newNPC.enemyID;
            newNPC.name = newNPC.name + 'NPC';
            newNPC.tiledName = newNPC.tiledName + 'NPC';
            newNPC.labelColor = config_1.labelColors.npc,
                newNPC.shootingRange = 1;
            newNPC.label = newNPC.label + ' NPC';
            newNPC.iconColor = '#70767F';
            newNPC.respawnCooldown = 3; //gameOptions.defaultOptions.defaultRespawnCooldown
            Object.assign(newNPC, { ai: NPCAi });
            PetNPCConstantsBase.push(newNPC);
        }
    });
    exports.EnemyConstantsBase.map(enemy => JSON.parse(JSON.stringify(enemy))).forEach(enemy => {
        if (enemy.type !== null) {
            let newPet = enemy;
            newPet.enemyID = 9000 + newPet.enemyID;
            for (const pet in config_1.petData) {
                if (config_1.petData[pet].petId === newPet.enemyID) {
                    newPet.spells = config_1.petData[pet].spells.filter((spell, index) => { if (index === 0) {
                        return spell;
                    } });
                    if (config_1.petData[pet].tier === 1) {
                        newPet.iconColor = config_1.tierIconColors.t1;
                    }
                    else if (config_1.petData[pet].tier === 2) {
                        newPet.iconColor = config_1.tierIconColors.t2;
                    }
                    else if (config_1.petData[pet].tier === 3) {
                        newPet.iconColor = config_1.tierIconColors.t3;
                    }
                    else if (config_1.petData[pet].tier === 4) {
                        newPet.iconColor = config_1.tierIconColors.t4;
                    }
                    else {
                        newPet.iconColor = config_1.tierIconColors.t1;
                    }
                    for (const type in config_1.iconByType) {
                        if (type === config_1.petData[pet].type) {
                            newPet.icon = config_1.iconByType[type];
                            break;
                        }
                    }
                    break;
                }
            }
            newPet.name = newPet.name + 'Pet';
            newPet.tiledName = newPet.tiledName + 'Pet';
            // newPet.speed = gameOptions.defaultOptions.defaultSpeed + 5
            newPet.speed = 110 + 5;
            // newPet.proximityRadius = gameOptions.defaultPlayerOptions.maxPetDistanceFromOwner*
            newPet.proximityRadius = 200 * 2;
            if (newPet.radius > 15) {
                newPet.radius = 15;
            }
            newPet.respawnCooldown = 30; //gameOptions.defaultOptions.defaultRespawnCooldown
            newPet.labelColor = config_1.labelColors.activePets;
            let sizeToRangeScale = 1 + Math.floor(newPet.radius / 10);
            let oldToNewRangeScale = 1 + Math.floor(newPet.shootingRange / 100);
            let maxPetRange = 70 + 10 * sizeToRangeScale + 10 * oldToNewRangeScale;
            newPet.shootingRange = Math.min(newPet.shootingRange, maxPetRange);
            PetNPCConstantsBase.push(newPet);
        }
    });
    return PetNPCConstantsBase;
}
const PetNPCConstants = generateNPCAndPetList();
PetNPCConstants.forEach(enemy => { exports.EnemyConstantsBase.push(enemy); });
exports.EnemyConstantsBase.forEach(enemy => {
    // enemy.drops = [{type: 'nothing', id: 0, weight: 32},]
    let tier = 0;
    for (const pet in config_1.petData) {
        if (config_1.petData[pet].petId === enemy.enemyID + 9000) {
            tier = config_1.petData[pet].tier;
            enemy.spells = config_1.petData[pet].spells;
            break;
        }
    }
    if (!(enemy.enemyID === 107 || enemy.enemyID === 105 || enemy.enemyID === 106)) {
        enemy.drops = (0, drops_1.dropsByTier)(tier);
    }
    if (enemy.enemyID < 1000) {
        enemy.respawnCooldown = 30;
    }
    if (enemy.trialDropChance) {
        enemy.trialDropChance.baseChance = -1;
    }
    enemy.ai.aggroMode = true;
    if (enemy.boss) {
        enemy.boss = false;
    }
});
// dropGroupsByTier.forEach(dropGroup => {
//     dropGroup.enemies.forEach(enemyName => {
//         const enemyIndex = EnemyConstantsBase.findIndex(e => e.name === enemyName)
//         EnemyConstantsBase[enemyIndex].drops = dropGroup.drops
//     })
// })
// const charlies = []
// for (let i = 0; i < 30; i++) {
//     charlies.push( {
//         'enemyID': 9000 + i,
//         'name': 'tinycreaturepet',
//         'type': 'dark',
//         'radius': 9,
//         'spriteName': 'miniCharlie',
//         'imgName': 'enemy_evilCreature.png',
//         'tiledName': 'TinyCreaturePet',
//         'spine': true,
//         'spineTwoAttacks': true,
//         'respawnCooldown': 90,
//         // 'respawnCooldown': 5,
//         'scoreOnKill': 35,
//         'speed': 55,
//         label: 'Lvl 1 Charlie',
//         labelColor: '#00ff00',
//         icon: 'enemy_evilCreature.png',
//         iconColor: '#00ff00',
//         'hp': 15 + (i * 5),
//         'proximityRadius': 300,
//         'shootingRange': 1,
//         'spells': [417],
//         'ai': { canMove: false, followTarget: false, lookAtTarget: true },
//         'trialDropChance': {
//             baseChance: 0.01,
//             dropTable: [
//                 {id: 11, weight: 50},
//             ]
//         },
//         'dropTable': {
//             starter: [
//                 { type: 'spell', id: 8, weight: 10, manaQuantity: 30 }, // Legendary Gravity Bolt
//                 { type: 'spell', id: 33, weight: 10, manaQuantity: 30 }, // Rare Healing Circle
//                 { type: 'spell', id: 302, weight: 10, manaQuantity: 60 }, // Rare Dash
//                 { type: 'spell', id: 307, weight: 10, manaQuantity: 60 }, // Uncommon Charge
//                 { type: 'spell', id: 312, weight: 10, manaQuantity: 60 }, // Rare Stealth
//                 { type: 'mana', id: 1, weight: 600, manaQuantity: 15 },
//                 { type: 'mana', id: 2, weight: 600, manaQuantity: 15 },
//                 { type: 'mana', id: 3, weight: 600, manaQuantity: 15 },
//                 { type: 'body', id: 5, weight: 10 }, // Rare Wizard Body,
//                 { type: 'body', id: 6, weight: 10 }, // Rare Rogue Body,
//                 { type: 'body', id: 7, weight: 10 }, // Rare Knight Body
//                 { type: 'body', id: 8, weight: 2 }, // Rare Wizard Body,
//                 { type: 'body', id: 9, weight: 2 }, // Rare Rogue Body,
//                 { type: 'body', id: 10, weight: 2 }, // Rare Knight Body,
//                 { type: 'top', id: 5, weight: 10 }, // Rare Wizard Body,
//                 { type: 'top', id: 6, weight: 10 }, // Rare Rogue Body,
//                 { type: 'top', id: 7, weight: 10 }, // Rare Knight Armor,
//                 { type: 'hand', id: 5, weight: 10 }, // Rare Wizard Gloves
//                 { type: 'hand', id: 6, weight: 10 }, // Rare Rogue Gauntlets,
//                 { type: 'hand', id: 7, weight: 10 },  // Rare Knight Gauntlets
//             ],
//             bronze: [
//                 { type: 'hand', id: 8, weight: 2 }, // Epic Wizard Gloves,
//                 { type: 'hand', id: 9, weight: 2 }, // Epic Rogue Gloves,
//                 { type: 'hand', id: 10, weight: 2 }, // Epic Knight Gauntlets,
//             ],
//             silver: [
//                 { type: 'top', id: 8, weight: 2 }, // Epic Wizard Body,
//                 { type: 'top', id: 9, weight: 2 }, // Epic Rogue Body
//                 { type: 'top', id: 10, weight: 2 }, // Epic Knight Body
//             ],
//             gold: [
//                 { type: 'spell', id: 203, weight: 10, manaQuantity: 30 }, // Epic Wind Katana
//             ],
//             platinum: [
//                 { type: 'spell', id: 202, weight: 10, manaQuantity: 30 }, // Epic Ice Katana
//             ]
//         },
//         'drops': [
//             { type: 'mana', id: 1, weight: 600, manaQuantity: 15 },
//         ]
//     },)
// }
// EnemyConstantsBase = EnemyConstantsBase.concat(charlies)
exports.EnemyConstants = exports.EnemyConstantsBase;
// export const EnemyConstants: EnemyInfo[] = EnemyConstantsBase.map(enemy =>
//     Object.assign(enemy, {
//         hp: enemy.hp/3,
//         // drops: []
//     })
// )
