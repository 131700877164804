// export var username;
// export var loaded;

// username = 'testUser'
export function createUsername(username) {

    var randomUsernames = [
        'Aered',
        'Arryn',
        'Weone',
        'Hula',
        'Sige',
        'Trege',
        'Alew',
        'Narder',
        'Sabert',
        'Cyne',
        'Milindor',
        'Alaran',
        'Hileborn',
        'Niserie',
        'Ariel',
        'Findalye',
        'Irilan',
        'Edhellas',
        'Argen',
        'Heswe',
    ]

    if ((username === null) | (username === '')) {
        username = randomUsernames[Math.floor(Math.random() * randomUsernames.length)]
    }
    return username
}
// export function createUsername() {

//     if (localStorage.hasOwnProperty("username") && (localStorage.getItem("username") !== 'null') && (localStorage.getItem("username") !== '')) {
//         username = localStorage.getItem("username");
//         loaded = true;;
//     }

//     else {
//         username = prompt("Enter Username");
//         localStorage.setItem("username", username);
//         loaded = true;
//     }

//     var randomUsernames = ['chetsfordsensitize', 'suitcasearabella', 'coldcitable', 'planetspatience', 'elfsmops', 'potatoclunky',
//         'hipporitalin', 'parisinform', 'glitchmode', 'luminousgrettle', 'bundledictate',
//         'speckledpulp', 'parkourpands', 'regionalwestern', 'rakesapsea', 'secondhandbarrack', 'pottleurchin',
//         'playboyswindale', 'magneticused', 'bimbotempt', 'crapulousprompting']

//     if ((username === null) | (username === '')) {
//         username = randomUsernames[Math.floor(Math.random() * randomUsernames.length)];
//     }

// }