"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrialsData = void 0;
exports.TrialsData = [
    {
        id: 41,
        name: 'Group Challenge - Lvl 30',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 3,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        dynamic: true
    },
    {
        id: 40,
        name: 'Group Challenge - Lvl 27',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 3,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        dynamic: true
    },
    {
        id: 39,
        name: 'Group Challenge - Lvl 24',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 3,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        dynamic: true
    },
    {
        id: 38,
        name: 'Group Challenge - Lvl 21',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 3,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        dynamic: true
    },
    {
        id: 37,
        name: 'Group Challenge - Lvl 18',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 3,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        dynamic: true
    },
    {
        id: 36,
        name: 'Group Challenge - Lvl 15',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 3,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        dynamic: true
    },
    {
        id: 35,
        name: 'Group Challenge - Lvl 12',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 3,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        dynamic: true
    },
    {
        id: 34,
        name: 'Group Challenge - Lvl 9',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 3,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        dynamic: true
    },
    {
        id: 33,
        name: 'Group Challenge - Lvl 6',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 3,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        dynamic: true
    },
    {
        id: 32,
        name: 'Group Challenge - Lvl 3',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 3,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        dynamic: true
    },
    {
        id: 31,
        name: 'Rank Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 30,
        name: 'Rank Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 29,
        name: 'Rank Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 28,
        name: 'Rank Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 27,
        name: 'Rank Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 26,
        name: 'Rank Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 25,
        name: 'Rank Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 24,
        name: 'Rank Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 23,
        name: 'Rank Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 22,
        name: 'Rank Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 21,
        name: 'Monster Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 20,
        name: 'Monster Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 19,
        name: 'Monster Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 18,
        name: 'Monster Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 17,
        name: 'Monster Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 16,
        name: 'Monster Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 15,
        name: 'Monster Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 14,
        name: 'Monster Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 13,
        name: 'Monster Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 12,
        name: 'Monster Challenge',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 1,
        dynamic: true
    },
    {
        id: 1,
        name: 'Charlie Challenge',
        zone: 4,
        scoreModifier: 1.1,
        expModifier: 1.1,
        maxPlayers: 2,
        minimumLevel: 23,
        score: 10000,
        coinsEarned: 2,
        difficulty: 'Medium',
        type: 'wave',
        totalWaves: 3,
        cost: {
            mana: {
                dark: 250,
            }
        }
    },
    {
        id: 2,
        name: 'Dual Dragons',
        zone: 4,
        scoreModifier: 1.3,
        expModifier: 1.3,
        maxPlayers: 3,
        minimumLevel: 25,
        coinsEarned: 5,
        difficulty: 'very hard',
        score: 25000,
        cost: {
            mana: {
                fire: 500,
            }
        },
        type: 'wave',
        totalWaves: 3,
    },
    {
        id: 3,
        name: 'Trap Maze',
        zone: 4,
        cost: {
            mana: {
                dark: 250,
            }
        },
        score: 10000,
        maxPlayers: 2,
        difficulty: 'Hard',
        coinsEarned: 5,
        type: 'boss',
    },
    {
        id: 4,
        name: 'Ice Mayhem',
        zone: 4,
        cost: {
            mana: {
                water: 400,
                // dark: 25000,
            }
        },
        score: 15000,
        difficulty: 'Hard',
        maxPlayers: 2,
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 4,
    },
    {
        id: 5,
        name: 'Slime Treasure Lands',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 1000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        waveCd: 0.25,
    },
    {
        id: 6,
        name: 'Slime Valley',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'boss',
    },
    {
        id: 7,
        name: 'Forest Treasure Lands',
        zone: 2,
        cost: {
            mana: {}
        },
        score: 1000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        waveCd: 0.25,
    },
    {
        id: 8,
        name: 'Maze Treasure Lands',
        zone: 3,
        cost: {
            mana: {}
        },
        score: 1000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        waveCd: 0.25,
    },
    {
        id: 9,
        name: 'Lava Treasure Lands',
        zone: 4,
        cost: {
            mana: {}
        },
        score: 1000,
        maxPlayers: 5,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        waveCd: 0.25,
    },
    {
        id: 10,
        name: 'Forest Forray',
        zone: 2,
        cost: {
            mana: {}
        },
        score: 5000,
        maxPlayers: 5,
        difficulty: 'Medium',
        coinsEarned: 5,
        type: 'boss',
    },
    {
        id: 11,
        name: 'Mini Mayhem',
        zone: 3,
        cost: {
            mana: {}
        },
        score: 7000,
        maxPlayers: 5,
        difficulty: 'Medium',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
    },
];
