import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Grid, Paper} from '@mui/material'
import './ui.css'
import {calculateScaleFactor, loadedServers} from '../game'
import Constants from "../../shared/constants";
import {bigScreenBorderProperties, colorPalette} from "./uiStyles";
import ButtonBase from "@mui/material/ButtonBase";
import {setGame, setGameMode, updateSelectedHighscoreGameMode, updateSelectedServer} from "../actions/actions";
import {resetGameObjects, resetPortalCameraPosition} from "./../create";
import {currentGame, updateGameConstants, updatePickups} from "../index";
import { min } from 'lodash'
let gameList = [
    {
        displayName: 'Grix',
        name: 'grix'
    },
    {
        displayName: 'Monster Master',
        name: 'monstermaster'
    },
    {
        displayName: 'Classic (PvP)',
        name: 'mageclash'
    }
]
class PortalGameGrid extends Component {
    constructor() {
        super()
        this.state = {}
    }
    render() {
        let {  width, height, serverData } = this.props
        let scaleFactor = 1
        let mainMenuScaleFactor = 1
        let topBarScaleFactor = 1
        let overallUIScaleFactor = 1
        scaleFactor = calculateScaleFactor(window.innerWidth, window.innerHeight)
        scaleFactor += 0.13
        topBarScaleFactor = scaleFactor
        let vw = 15
        mainMenuScaleFactor = scaleFactor
        if (width < 600) {
            vw = 35
            // vw = 25
            mainMenuScaleFactor += 0.2
        }
        if (width < 900) {
            // vw = 25
            vw = 15
        }
        let fontSize =  'max(1.5vh, 19px)'
        if (width < 1000) {
            fontSize = 'max(1.5vh, 16px)'
        }
        mainMenuScaleFactor = Math.min(1, mainMenuScaleFactor)
        var gameGrid = gameList.map((game, index) => {
            return (
                <Grid item
                            xs={3}
                            //  xs={6}
                            //  md={3}
                             // direction={'row'}
                             style={{
                    marginBottom: '5%', width: '100%', height: '100%',
                    // minWidth: '120px',
                                 margin: '1%',
                }}>
                    <Paper
                        style={Object.assign({
                            background: colorPalette.primary,
                            // margin: '5%',
                            // minWidth: '200px',
                            // maxWidth: '200px',
                            minWidth: `${vw}vw`,
                            maxWidth: `${vw}vw`,
                            // minHeight: `${vw}vw`,
                            // maxHeight: `${vw}vw`,
                            borderColor: colorPalette.primaryBorder,
                        }, bigScreenBorderProperties)}
                    >
                        <Grid container align='center' justifyContent="center" alignItems="center"  xs={12}>
                            <Grid xs={12}>
                                <div style={{
                                    fontWeight: 900,
                                    fontSize: fontSize,
                                    maxHeight: '31px',
                                    paddingBottom: '3%'
                                }}>  {game.displayName} </div>
                            </Grid>
                            <Grid xs={12}>
                                {/* video here */}
                                <ButtonBase
                                    onClick={(e) => {
                                        setGame(game.name)
                                        currentGame.value = game.name
                                        if (loadedServers.value) {
                                            console.log('loaded servers value is true', )
                                            if (serverData.beta) {
                                                updateSelectedServer(serverData.beta.selectedServer.url)
                                            } else {
                                                if (serverData[game.name]) {
                                                    console.log('updating selected server', serverData[game.name].selectedServer.url )
                                                    updateSelectedServer(serverData[game.name].selectedServer.url)
                                                } else {
                                                    updateSelectedServer(serverData.default[0].url)
                                                }
                                            }
                                        } else {
                                            console.log('loaded servers value is false')
                                            }
                                        resetPortalCameraPosition(game.name)
                                        updateGameConstants(game.name)
                                        // resetGameObjects(game.name, true)
                                    }}
                                >
                                    <img style={{
                                        position: 'relative', pointerEvents: 'none',
                                        // width: '180px',
                                        // height: '180px',
                                        width: '100%',
                                        margin: 'auto',
                                        borderRadius: '8px',

                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        borderColor: '#fcd7a6',

                                    }}
                                         src={`assets/games/${game.name}/thumbnail.jpg`}
                                    />
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            );
        })
        return (
            <Grid item container
                  align="center" justifyContent="center" alignItems="center"
                  xs={12}>
                {gameGrid}
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        width: state.stuff.width,
        height: state.stuff.height,
        serverData: state.stuff.serverData,
    }
}

export default connect(
    mapStateToProps,
)(PortalGameGrid)