import React, { Component } from 'react'
import ColorChangingIcon from './ColorChangingIcon.js'

class DoubleColorChangingIcon extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    componentDidMount() {
        let { config } = this.props
        // this.setState({
        //     config: config,
        //     fillColor: config.idle.fillColor,
        //     outerBorderColor: config.idle.outerBorderColor,
        //     innerBorderColor: config.idle.innerBorderColor
        // })
    }

    render() {
        let { bool, buttonFunction, buttonFunction2, buttonStyling, textColor, IconImage, IconImage2, config } = this.props


        return (
            <>
           {bool &&
            <ColorChangingIcon
                buttonFunction={buttonFunction}
                config={config}
                buttonStyling={buttonStyling}
                textColor={textColor}
                IconImage={IconImage}
            />
            }
        {!bool &&
                    <ColorChangingIcon
                        buttonFunction={buttonFunction2}
                        config={config}
                    buttonStyling={buttonStyling}
                        textColor={textColor}
                        IconImage={IconImage2}
                    />
        
        }
        </>

        )
    }
}

export default (DoubleColorChangingIcon)