import React, { Component } from 'react'
import Constants from '../../shared/constants'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import { IconButton, Box } from '@mui/material'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import ColorChangingButton from './ColorChangingButton.js'
import ColorChangingSignInButton from './ColorChangingSignInButton.js'
import { updatedReferrer } from '../sdkIntegration'
import {toggleSignInMenu} from "../actions/actions";
import {baseUrl, domainName} from "../index";
import {colorPalette} from "./uiStyles";

function handleLoginWindow(url, token) {
    // window.open(url, "_self")
    // disabling until fix bug
    let referrer = document.referrer
    if (typeof referrer == 'string' && (referrer.includes('localhost') || referrer.includes(domainName))) {
        referrer = ''
    }
    if (updatedReferrer.value) {
        referrer = updatedReferrer.value
    }

    console.log('REFERRER', referrer)
    try {
        if (typeof referrer == 'string' && referrer != '') {
            let dictToSave = JSON.stringify({
                referrer: referrer,
                date: new Date()
            })
            localStorage.setItem('referrer',
                dictToSave
            )
        } else {
            console.log('not setting ref storage', referrer)
        }
    } catch (err) {
        console.log('crashed loc storage', err)
    }


    let popup = window.open(url, 'popUpWindow', 'height=600, width=500, left=100, top=100, resizable=yes,popup=true')
    let interval = window.setInterval(function () {
        try {
            if (popup == null || popup.closed) {
                window.clearInterval(interval)
                location.reload()
            }
        }
        catch (e) {
        }
    }, 100)
}

class SignInButton extends Component {
    constructor() {
        super()
        this.state = {
            dialog: false
        }
    }

    componentDidMount() {

    }

    render() {

        let { authenticated, token, signInDialogActive, referrer } = this.props

        // console.log('REFERRER READ', referrer)
        let path
        let buttonText
        // if (!authenticated) {
        //     // path = '/auth/google'
        //     buttonText = 'Login'
        // } else {
        //     // path = '/auth/logout'
        //     buttonText = 'Logout'
        // }
        const handleClickOpen = async () => {
            if (referrer === 'crazygames') {
                console.log('crazygames auth')
                try {
                    const user = await window.CrazyGames.SDK.user.showAuthPrompt();
                    console.log("Auth prompt result", user);

                    try {
                        const cgToken = await window.CrazyGames.SDK.user.getUserToken();
                        let cgUrl = `${baseUrl}/auth/crazygames?token=${encodeURIComponent(token)}&cgToken=${encodeURIComponent(cgToken)}`

                        // Use the `cgUrl` for the HTTP request here, with await
                        const response = await fetch(cgUrl);
                        const data = await response.json(); // Assuming the server responds with JSON
                        console.log(data);
                      } catch (e) {
                        console.log("Error:", e);
                      }
                  } catch (e) {
                    console.log("Error:", e);
                  }
            } else {
                toggleSignInMenu(true)
            }
        }
        const handleClose = () => {
            toggleSignInMenu(false)
        }

        let { dialog } = this.state
        return <>
            {/* {authenticated &&
                <Button style={{ minWidth: '200px', marginTop: '10px' }} variant="contained" onClick={() => { window.open(baseUrl + '/auth/logout', "_self")}}> <b> Log out </b></Button>
            } */}
            {/* {!authenticated && */}

            <>
                {/* <Grid container>
                <Grid xs={6}> */}
                <Grid container xs={12} style={{
                    maxWidth: '250px',
                }}>
                    <Grid xs={6}
                    // style={{maxWidth: '125px'}}
                    >
                        <Grid xs={11}>
                            {/* <Button style={Object.assign({
                                width: '100%',
                                marginTop: '10px',
                                background: '#ffd522',
                            }, mainBorderProperties
                            )} variant="contained" onClick={handleClickOpen}> <b> Register </b> </Button>
*/}

                            <ColorChangingButton buttonFunction={handleClickOpen}
                                config={
                                    {
                                        idle: {
                                            fillColor: '#FFD522',
                                            outerBorderColor: '#362D44',
                                            innerBorderColor: '#F79E3C',
                                        },
                                        hover: {
                                            fillColor: '#FEFF69',
                                            outerBorderColor: '#362D44',
                                            innerBorderColor: '#FFD522',
                                        },
                                        down: {
                                            fillColor: '#F49540',
                                            outerBorderColor: '#362D44',
                                            innerBorderColor: '#D66C16',
                                        },
                                    }}
                                title='Register'
                                buttonStyling={{
                                    width: '100%',
                                    marginTop: '10px',
                                    fontSize: '2vh',
                                }}
                                textColor='black'
                            />

                        </Grid>
                        <Grid xs={1} style={{ width: '100%' }}></Grid>
                    </Grid>

                    <Grid xs={6}
                    // style={{ maxWidth: '125px' }}
                    >
                        <Grid xs={1} style={{ width: '100%' }}></Grid>
                        <Grid xs={11}>
                            {/* <Button style={Object.assign({
                                width: '100%',
                                marginTop: '10px',
                                background: '#f7a74f',
                            }, mainBorderProperties
                            )} variant="contained" onClick={handleClickOpen}> <b> Login </b> </Button>
*/}

                            <ColorChangingButton buttonFunction={handleClickOpen}
                                config={
                                    {
                                        idle: {
                                            fillColor: '#F7A74F',
                                            outerBorderColor: '#362D44',
                                            innerBorderColor: '#CE782C',
                                        },
                                        hover: {
                                            fillColor: '#FFD16C',
                                            outerBorderColor: '#362D44',
                                            innerBorderColor: '#FFB66E',
                                        },
                                        down: {
                                            fillColor: '#F79B37',
                                            outerBorderColor: '#362D44',
                                            innerBorderColor: '#E08634',
                                        },
                                    }}
                                title='Login'
                                buttonStyling={{
                                    width: '100%',
                                    marginTop: '10px',
                                    fontSize: '2vh',
                                }}
                                textColor='black'
                            />


                        </Grid>
                    </Grid>
                </Grid>
                {/* </Grid> */}
                <Dialog
                    style={{
                        padding: '10px',
                        zIndex: 100001,
                    }}
                    onClose={handleClose} aria-labelledby="simple-dialog-title"
                    // open={dialog}
                    open={signInDialogActive}
                >

                    {/* <Paper 
                        // style={{padding: '20%'}}
                        > */}
                    <DialogTitle style={{ textAlign: 'center', backgroundColor: colorPalette.primary, padding: '12px 24px' }} id="simple-dialog-title"> <b>SELECT A LOGIN METHOD </b></DialogTitle>
                    {/* <Grid xs={12} style={{ textAlign: 'center', backgroundColor: colorPalette.primary, width: '100%', minHeight: '45px' }}><b style={{fontSize: '30px'}}>SELECT A LOGIN METHOD </b> */}

                    {/* </Grid> */}
                    <IconButton
                        style={{
                            alignSelf: 'flex-end',
                            marginTop: -12,
                            position: 'absolute'
                        }}
                        onClick={handleClose}
                        size="large">
                        <CancelPresentationIcon style={{ fontSize: '3rem' }} />
                    </IconButton>
                    <Grid container style={{ width: '100%', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px', backgroundColor: colorPalette.primary }}>
                        <Grid row xs={12} style={{ paddingBottom: '10px' }}>
                            <ColorChangingSignInButton
                                buttonFunction={() => {
                                    // handleLoginWindow(baseUrl + '/auth/google', referrer)
                                    console.log('calling handle login', baseUrl + '/auth/google' + '?token=' + token)
                                    // handleLoginWindow(url + '/auth/google' + '?token=' + token)
                                    handleLoginWindow(baseUrl + '/auth/google' + '?token=' + token)
                                    // if (referrer == '') {
                                    //     window.open(baseUrl + '/auth/google', "_self")
                                    // } else {
                                    //     let popup = window.open(baseUrl + '/auth/google', 'popUpWindow', 'height=600, width=500, left=100, top=100, resizable=yes')
                                    //     let interval = window.setInterval(function () {
                                    //         try {
                                    //             console.log('popup', popup)
                                    //             if (popup == null || popup.closed) {
                                    //                 window.clearInterval(interval);
                                    //                 location.reload()
                                    //             }
                                    //         }
                                    //         catch (e) {
                                    //         }
                                    //     }, 100)
                                    // }
                                }}
                                title='Log in With Google'
                                buttonStyling={{ height: '120px', width: '100%', }}
                                config={
                                    {
                                        idle: {
                                            fillColor: '#5185EC',
                                            outerBorderColor: '#362D44',
                                            innerBorderColor: '#415499',
                                        },
                                        hover: {
                                            fillColor: '#24B1FF',
                                            outerBorderColor: '#362D44',
                                            innerBorderColor: '#2C96E5',
                                        },
                                        down: {
                                            fillColor: '#1771DD',
                                            outerBorderColor: '#362D44',
                                            innerBorderColor: '#1249A0',
                                        },
                                    }}
                                imgPath='assets/google-white.svg'

                            />
                        </Grid>
                        <Grid container style={{ width: '100%', }}>
                            <ColorChangingSignInButton
                                buttonFunction={() => {
                                    // window.open(baseUrl + '/auth/facebook', "_self")
                                    // handleLoginWindow(baseUrl + '/auth/facebook', referrer)
                                    handleLoginWindow(baseUrl + '/auth/facebook')
                                    // window.open(baseUrl + '/auth/facebook')
                                    // window.open(baseUrl + '/auth/facebook', '_blank', 'toolbar=0,location=0,menubar=0')
                                }}
                                title='Log in With Facebook'
                                buttonStyling={{ height: '120px', width: '100%', }}
                                config={
                                    {
                                        idle: {
                                            fillColor: '#415993',
                                            outerBorderColor: '#362D44',
                                            innerBorderColor: '#253D6D',
                                        },
                                        hover: {
                                            fillColor: '#7294D6',
                                            outerBorderColor: '#362D44',
                                            innerBorderColor: '#567DBA',
                                        },
                                        down: {
                                            fillColor: '#305D9E',
                                            outerBorderColor: '#362D44',
                                            innerBorderColor: '#235091',
                                        },
                                    }}
                                imgPath='assets/fb-white.svg'

                            />
                        </Grid>




                    </Grid>
                    {/* </Paper> */}
                </Dialog>


            </>
            {/* } */}

        </>;
    }
}


function mapStateToProps(state) {
    return {
        authenticated: state.stuff.authenticated,
        referrer: state.stuff.referrer,
        token: state.stuff.token,
        signInDialogActive: state.stuff.signInDialogActive,
    }
}

export default connect(
    mapStateToProps,
)(SignInButton) 