"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnemyEquipmentDrop = exports.tierChances = exports.dropsByTier = exports.dropGroups = exports.secondarySpellColors = exports.primarySpellColors = exports.gloveSpellColors = exports.gearDropColors = exports.secondarySpells = exports.primarySpells = void 0;
const spells_1 = require("./shared/pickups/spells");
exports.primarySpells = ['staff', 'wand', 'dagger', 'sword'];
exports.secondarySpells = ['spell', 'tome', 'cloak', 'dash'];
exports.gearDropColors = {
    't0': { 'customCircleColor': '#70767F' }, // base common
    't1': { 'customCircleColor': '#2FAD25' }, // base uncommon
    't2': { 'customCircleColor': '#2C8F24' },
    't3': { 'customCircleColor': '#0F7F06' },
    't4': { 'customCircleColor': '#0E7006' },
    't5': { 'customCircleColor': '#1A82FF' },
    't6': { 'customCircleColor': '#0B6FE7' }, // base rare
    't7': { 'customCircleColor': '#085EC6' },
    't8': { 'customCircleColor': '#0353B4' },
    't9': { 'customCircleColor': '#8948EF' }, // base epic
    't10': { 'customCircleColor': '#6E37C4' },
    't11': { 'customCircleColor': '#552C94' },
    't12': { 'customCircleColor': '#F47C40' }, // base legendary
};
exports.gloveSpellColors = {
    't0': { 'customCircleColor': '#70767F' }, // base common
    't1': { 'customCircleColor': '#2FAD25' }, // base uncommon
    't2': { 'customCircleColor': '#2C8F24' },
    't3': { 'customCircleColor': '#0F7F06' },
    't4': { 'customCircleColor': '#0E7006' },
    't5': { 'customCircleColor': '#1A82FF' },
    't6': { 'customCircleColor': '#0B6FE7' }, // base rare
    't7': { 'customCircleColor': '#085EC6' },
    't8': { 'customCircleColor': '#0353B4' },
    't9': { 'customCircleColor': '#8948EF' }, // base epic
    't10': { 'customCircleColor': '#6E37C4' },
    't11': { 'customCircleColor': '#552C94' },
    't12': { 'customCircleColor': '#F47C40' }, // base legendary
    // 't0': {'customCircleColor': '#70767F'},
    // 't1': {'customCircleColor': '#2C8F24'},// base uncommon
    // 't2': {'customCircleColor': '#1A82FF'},
    // 't3': {'customCircleColor': '#1A82FF'},// base rare
    // 't4': {'customCircleColor': '#0353B4'},
    // 't5': {'customCircleColor': '#8948EF'}, // base epic
    // 't6': {'customCircleColor': '#F47C40'}, // base legendary
};
exports.primarySpellColors = {
    't0': { 'customCircleColor': '#70767F' }, // base common
    't1': { 'customCircleColor': '#2FAD25' }, // base uncommon
    't2': { 'customCircleColor': '#2C8F24' },
    't3': { 'customCircleColor': '#0F7F06' },
    't4': { 'customCircleColor': '#0E7006' },
    't5': { 'customCircleColor': '#1A82FF' }, // base rare
    't6': { 'customCircleColor': '#085EC6' },
    't7': { 'customCircleColor': '#0353B4' },
    't8': { 'customCircleColor': '#8948EF' }, // base epic
    't9': { 'customCircleColor': '#6E37C4' },
};
exports.secondarySpellColors = {
    't0': { 'customCircleColor': '#70767F' },
    't1': { 'customCircleColor': '#2C8F24' }, // base uncommon
    't2': { 'customCircleColor': '#1A82FF' },
    't3': { 'customCircleColor': '#1A82FF' }, // base rare
    't4': { 'customCircleColor': '#0353B4' },
    't5': { 'customCircleColor': '#8948EF' }, // base epic
    't6': { 'customCircleColor': '#F47C40' }, // base legendary
};
function createGearArray(tier, weight) {
    return [
        { type: 'top', id: 100 + tier, weight: weight },
        { type: 'top', id: 200 + tier, weight: weight },
        { type: 'top', id: 300 + tier, weight: weight },
        { type: 'body', id: 100 + tier, weight: weight },
        { type: 'body', id: 200 + tier, weight: weight },
        { type: 'body', id: 300 + tier, weight: weight },
    ];
}
function createGlovesArray(tier, weight) {
    return [
        { type: 'hand', id: 110 + tier, weight: weight },
        { type: 'hand', id: 120 + tier, weight: weight },
        { type: 'hand', id: 130 + tier, weight: weight },
        { type: 'hand', id: 140 + tier, weight: weight },
        { type: 'hand', id: 150 + tier, weight: weight },
        { type: 'hand', id: 160 + tier, weight: weight },
        { type: 'hand', id: 170 + tier, weight: weight },
        { type: 'hand', id: 180 + tier, weight: weight },
    ];
}
function createWeaponsArray(tier, weight) {
    const staffTier = 248 + tier;
    const wandTier = 231 + tier;
    const swordTier = 900 + tier;
    const daggerTier = 930 + tier;
    return [
        { type: 'spell', id: staffTier, weight: weight },
        { type: 'spell', id: wandTier, weight: weight },
        { type: 'spell', id: swordTier, weight: weight },
        { type: 'spell', id: daggerTier, weight: weight },
    ];
}
function createSecondaryWeaponsArray(tier, weight) {
    const spellTier = 800 + tier;
    const tomeTier = 850 + tier;
    const stealthTier = 750 + tier;
    const dashTier = 650 + tier;
    return [
        { type: 'spell', id: spellTier, weight: weight },
        { type: 'spell', id: tomeTier, weight: weight },
        { type: 'spell', id: stealthTier, weight: weight },
        { type: 'spell', id: dashTier, weight: weight },
    ];
}
exports.dropGroups = [
    {
        enemies: ['tinycreature'],
        drops: [
            { type: 'nothing', id: 0, weight: 1 }
        ]
    },
    {
        enemies: ['slime', 'meleeSlime', 'flowerCreature'],
        drops: [
            { type: 'nothing', id: 0, weight: 32 },
        ].concat(createGearArray(2, 8)).concat(createGearArray(3, 3)).concat(createWeaponsArray(1, 8))
    },
    {
        enemies: ['leopard', 'woodlandWhelp', 'crabNormal', 'crabSuperior', 'starfishNormal', 'slimeBoss', 'meleeSlimeBoss'],
        drops: [
            { type: 'nothing', id: 0, weight: 32 },
        ].concat(createGearArray(3, 8).concat(createGearArray(4, 4)).concat(createGlovesArray(1, 2))).concat(createWeaponsArray(2, 8)).concat(createSecondaryWeaponsArray(1, 4))
    },
    {
        enemies: ['creature', 'seahorse', 'cannon', 'piranha', 'seahorseSuperior', 'kingLeopard', 'crabBoss'],
        drops: [
            { type: 'nothing', id: 0, weight: 32 },
        ].concat(createGearArray(4, 8).concat(createGearArray(5, 1)).concat(createGlovesArray(2, 2))).concat(createWeaponsArray(3, 8)).concat(createSecondaryWeaponsArray(2, 4))
    },
    {
        enemies: ['iceGolem', 'babyDragonWhelp', 'darkWiz', 'piranhaBoss', 'cannonBoss', 'creatureBoss'],
        drops: [
            { type: 'nothing', id: 0, weight: 32 },
        ].concat(createGearArray(5, 8)).concat(createGearArray(6, 2)).concat(createGlovesArray(2, 2).concat(createWeaponsArray(4, 8)).concat(createSecondaryWeaponsArray(2, 4)))
    },
    {
        enemies: ['Mammoth', 'MammothSuperior', 'dragonWhelp', 'hellhound', 'piranhaBoss', 'cannonBoss', 'creatureBoss'],
        drops: [
            { type: 'nothing', id: 0, weight: 32 },
        ].concat(createGearArray(6, 8)).concat(createGearArray(7, 2)).concat(createGlovesArray(3, 2).concat(createWeaponsArray(5, 8)).concat(createSecondaryWeaponsArray(3, 4)))
    },
    {
        enemies: ['slimeIceBoss', 'darkWizBoss', 'slimeLavaBoss', 'hellhoundBoss'],
        drops: [
            { type: 'nothing', id: 0, weight: 32 },
        ].concat(createGearArray(7, 8)).concat(createGearArray(8, 2)).concat(createGlovesArray(3, 2).concat(createWeaponsArray(6, 8)).concat(createSecondaryWeaponsArray(3, 4)))
    },
    {
        enemies: ['dragonBig', 'iceGolemBoss'],
        drops: [
            { type: 'nothing', id: 0, weight: 32 },
        ].concat(createGearArray(8, 8)).concat(createGearArray(9, 2)).concat(createGlovesArray(4, 2).concat(createWeaponsArray(7, 8)).concat(createSecondaryWeaponsArray(4, 4)))
    }
];
console.log('dropgroups', exports.dropGroups);
function createGoldArray(tier) {
    return [
        { type: 'gold', id: 0, weight: 5, quantity: (60 * tier * 1000 + 60) },
        { type: 'gold', id: 0, weight: 10, quantity: (40 * tier * 1000 + 40) },
        { type: 'gold', id: 0, weight: 40, quantity: (20 * tier * 1000) },
        // test
        // {type: 'gold', id:0, weight: 1000, quantity: 20*tier*1000},
    ];
}
function dropsByTier(tier = 0) {
    return tier === 0 ? [{ type: 'nothing', id: 0, weight: 32 },] : [{ type: 'nothing', id: 0, weight: 32 },].concat(createGoldArray(tier));
}
exports.dropsByTier = dropsByTier;
exports.tierChances = {
    // below: 0.03,
    // current: 0.045,
    // above: 0.015
    // below: 0.06,
    // current: 0.09,
    // above: 0.03
    below: 0.09,
    current: 0.175,
    above: 0.09
    // below: 0.3,
    // current: 0.45,
    // above: 0.15
};
const possibleDrops = [
    { gearType: 'bodyWear', startId: 101, name: 'Leather Armor' },
    { gearType: 'bodyWear', startId: 201, name: 'Wizard Robe' },
    { gearType: 'bodyWear', startId: 301, name: 'Knight Armor' },
    { gearType: 'handWear', startId: 111, name: 'Plate Gauntlet' },
    { gearType: 'handWear', startId: 141, name: 'Leather Glove' },
    { gearType: 'handWear', startId: 171, name: 'Cloth Glove' },
    { gearType: 'weapon', startId: spells_1.gunStartingLevels.minigun, name: 'Minigun' },
    { gearType: 'weapon', startId: spells_1.gunStartingLevels.shotgun, name: 'Shotgun' },
    { gearType: 'weapon', startId: spells_1.gunStartingLevels.burstRifle, name: 'Burst Rifle' },
    { gearType: 'weapon', startId: spells_1.gunStartingLevels.sniper, name: 'Sniper Rifle' },
];
const maxEquipmentTier = 9;
function determineDropTier(level, dropType) {
    if (dropType.gearType !== 'weapon') {
        let dropTier = Math.floor(level / 3);
        return Math.min(dropTier, maxEquipmentTier);
    }
    else {
        return level;
    }
}
function calculateTierProbability(tier) {
    const total = exports.tierChances.below + exports.tierChances.current + exports.tierChances.above;
    if (tier === 'above')
        return exports.tierChances.above / total;
    if (tier === 'current')
        return (exports.tierChances.above + exports.tierChances.current) / total;
    return 1;
}
function determineRarity(dropType) {
    const roll = Math.random();
    /*
    0: Normal
    1: Superior
    2: Ice
    3: Binding
    4: Enhanced
    */
    if (roll < 0.05) {
        return 4;
    }
    else if (roll < 0.1) {
        return 3;
    }
    else if (roll < 0.15) {
        return 2;
    }
    else if (roll < 0.25) {
        return 1;
    }
    else {
        return 0;
    }
    // if (roll < 0.025) {
    //     return 4
    // } else if ( roll < 0.075) {
    //     return 3
    // } else if ( roll < 0.125) {
    //     return 2
    // } else if ( roll < 0.175) {
    //     return 1
    // } else {
    //     return 0
    // }
    // if (roll < 0.25) {
    //     return 1
    // } else if ( roll < 0.5) {
    //     return 2
    // } else {
    //     return 0
    // }
}
function getEnemyEquipmentDrop(level = 0, playerLevel) {
    if (level > playerLevel + 1) {
        level = playerLevel + 1;
    }
    const dropType = possibleDrops[Math.floor(Math.random() * possibleDrops.length)];
    const dropTier = determineDropTier(level, dropType);
    const roll = Math.random();
    let tierAdjustment;
    if (roll < exports.tierChances.above) {
        tierAdjustment = 1; // 'above' tier
    }
    else if (roll < exports.tierChances.above + exports.tierChances.current) {
        tierAdjustment = 0; // 'current' tier
    }
    else if (roll < exports.tierChances.above + exports.tierChances.current + exports.tierChances.below) {
        tierAdjustment = -1; // 'below' tier
    }
    else {
        return; // No drop if the roll is outside the specified chances
    }
    const adjustedTier = Math.max(0, dropTier + tierAdjustment);
    const rarity = determineRarity(dropType);
    // console.log('drop data', {
    //     drop: dropType,
    //     tier: dropTier,
    //     adjustedTier: adjustedTier,
    //     rarity: rarity,
    // })
    let dropId = dropType.startId + adjustedTier;
    if (dropType.gearType === 'weapon') {
        dropId = dropType.startId + adjustedTier + (spells_1.gunLevels * rarity);
    }
    // console.log('rarity', rarity)
    return {
        type: dropType.gearType,
        id: dropId,
        name: dropType.name,
    };
}
exports.getEnemyEquipmentDrop = getEnemyEquipmentDrop;
