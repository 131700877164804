import React, { Component } from 'react'
import Button from '@mui/material/Button'


// const useStyles = makeStyles(theme => ({
//     animatedItem: {
//         animation: `$myEffect 3000ms ${theme.transitions.easing.easeInOut}`,
//         animation: 'shake 0.82s cubic- bezier(.36, .07, .19, .97) both',
//         transform: 'translate3d(0, 0, 0)',
//         backfaceVisibility: 'hidden',
//         perspective: '1000px',
//         animationIterationCount: 'infinite',
//     },

//     "@keyframes shake": {
//     '10%, 90%': {
//         transform: 'translate3d(-1px, 0, 0)'
//     },

//     '20%, 80%': {
//         transform: 'translate3d(2px, 0, 0)'
//     },

//     '30%, 50%, 7 %': {
//         transform: 'translate3d(-4px, 0, 0)'
//     },

//     '40%, 60%': {
//         transform: 'translate3d(4px, 0, 0)'
//     }
// },

// }));


class ColorChangingChallengeButton extends Component {
    constructor() {
        super()
        this.state = {
            fillColor: '#E7CAB2',
            outerBorderColor: '#362D44',
            innerBorderColor: '#FFF1D2',
            config: {
                idle: {
                    fillColor: '#E7CAB2',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFF1D2',
                },
                hover: {
                    fillColor: '#E7CAB2',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFF1D2',
                },
                down: {
                    fillColor: '#E7CAB2',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFF1D2',
                }
            }
        }
    }

    componentDidMount() {
        let { config } = this.props
        this.setState({ config: config,
        fillColor: config.idle.fillColor,
        outerBorderColor: config.idle.outerBorderColor,
        innerBorderColor: config.idle.innerBorderColor
        })
    }

    render() {
        let { fillColor, innerBorderColor, outerBorderColor, config } = this.state
        let { buttonFunction, title, buttonStyling, textColor, challengeWaiting } = this.props

        // console.log("CHOSEN FILL COLOR", fillColor)
        if (!textColor) {textColor = 'white'}
        let className
        let iconPath
        if (!challengeWaiting) {
            iconPath = 'assets/ui/icon_challenges.png'
            className = 'noSwing'
        } else {
            iconPath = 'assets/ui/icon_challenges_glow.png'
            className = 'swing'
        }
        return (
            <Button
                style={
                    Object.assign({
                        fontSize: '2vh',
                        // color: textColor,
                        borderRadius: '6px',
                        borderWidth: '2px', borderStyle: 'solid',
                        background: fillColor,
                        borderColor: innerBorderColor,
                        boxShadow: `0 0 0 2px ${outerBorderColor}`,
                        textTransform: 'none'
                    },
                    buttonStyling)
                }

                variant="contained"

                onMouseOver={() => {
                    this.setState({
                        fillColor: config.hover.fillColor,
                        outerBorderColor: config.hover.outerBorderColor,
                        innerBorderColor: config.hover.innerBorderColor
                    })
                }}
                onMouseOut={() => {
                    this.setState({
                        fillColor: config.idle.fillColor,
                        outerBorderColor: config.idle.outerBorderColor,
                        innerBorderColor: config.idle.innerBorderColor
                    })
                }
                }
                onMouseDown={(e) => {
                    this.setState({
                        fillColor: config.down.fillColor,
                        outerBorderColor: config.down.outerBorderColor,
                        innerBorderColor: config.down.innerBorderColor
                    })
                }}

                onClick={(e) => {
                    e.nativeEvent.stopImmediatePropagation()
                    e.preventDefault()
                    e.stopPropagation()

                    buttonFunction()
                }}
            >
                <b  style={{
                        fontSize: 'max(1.5vh, 19px)', color: textColor,
                    }}> 
                    {title} </b> 

                <img
                    class={className}
                    style={{
                        pointerEvents: 'none',
                        position: 'absolute',
                        // left: '-8.5px',
                        // bottom: '6px'

                        // left: '-8.5px',
                        // bottom: '4px',
                        left: '-14.5px',
                        bottom: '-1px',
                        transform: 'scale(0.78)'
                    }}
                    src={iconPath}
                                />
            </Button >
        )
    }
}

export default (ColorChangingChallengeButton)