import { phaserObject } from './create'
import * as Actions from './actions/actions'
import { initiateLoginProcess } from './auth'
import { baseUrl } from '.'
import { detectAdblock } from './components/mainUIScreens/UI'

export let updatedReferrer = {
    value: null
    // value: 'crazygames'
}

export let gameplayValue = { value: false}


export let cmds = {
    // playPreroll: (onEnd, placementName) => {
    playPreroll: (actions) => {
        if (actions) {
            actions()
        }
        // onEnd()
    },
    playRewarded: (onComplete, onError) => {
        console.error('Shouldn\'t be playing unloaded rewarded ad')
        // onError()
    },
    requestNewBanners: (bannerInfos) => { },
    gameplayStart: () => {
        if (!gameplayValue.value) {
            gameplayValue.value = true
            cmds.gameplayStartCmds()
            console.log('called gameplay start')
        }
  
     },
    gameplayStop: () => { 
        if (gameplayValue.value) {
            gameplayValue.value = false
            cmds.gameplayStopCmds()
            console.log('called gameplay stop')

        }
    },
    gameplayStartCmds: () => {
    },
    gameplayStopCmds: () => {
    }
}

let actionsHolder = () => { }

function pauseGame() {
    console.log('pausing game')
    if (phaserObject) {
        phaserObject.sound.game.scene.scenes[0].sound.mute = true
    }
}

function resumeGame() {
    console.log('resuming game')
    if (phaserObject) {
        let gameMuted = JSON.parse(localStorage.getItem('mute'))
        if (!gameMuted) {
            phaserObject.sound.game.scene.scenes[0].sound.mute = false
        }
    }
}

export function loadScript(src) {
    return new Promise(function (resolve, reject) {
        // Don't load ad scripts till the core things needed to play the game are ready
        var s
        s = document.createElement('script')
        s.src = src
        s.onload = resolve
        s.onerror = reject
        document.head.appendChild(s)
    })
}


export async function runSDK(referrer) {
    // let referrer = document.referrer
    // if (referrer.includes('crazygames') || referrer.includes('1001juegos')) {
    //     referrer = 'crazygames'
    //     updatedReferrer.value = 'crazygames'
    // }
    // let referrer = 'crazygames'

    console.log('base referrer', referrer)
    if (localStorage.hasOwnProperty('referrer')) {

        let referrerData = JSON.parse(localStorage.getItem('referrer'))
        referrerData.date = Date.parse(referrerData.date)

        const TIME_DELTA = 1000 * 15 // 15 seconds
        const timeDeltaValue = Date.now() - TIME_DELTA
        if (referrerData.date > timeDeltaValue) {
            referrer = referrerData.referrer
            if (referrer === '1001juegos') {
                referrer = 'crazygames'
            }
            updatedReferrer.value = referrer
        } else {
        }
    } else {
        // console.log('no storage ref')
    }

    console.log('READ REFERRER', referrer)
    if (typeof referrer == 'string') {
        console.log('read referrer 2', referrer)
        // if (referrer.includes('crazygames') || true) {
        if (referrer.includes('crazygames')) {
            Actions.hideCrossLinks(true)
            await loadScript('https://sdk.crazygames.com/crazygames-sdk-v3.js')

            console.log('Loaded crazygames sdk')
            await window.CrazyGames.SDK.init();

            console.log('updating crazygames ref after loadscript', referrer)
            Actions.updateReferrer('crazygames')

            detectAdblock('crazygames')

            cmds.playPreroll = (actions) => {
                console.log('-- main play preroll called')
                // if (hasAdblock) {
                //     console.log("user has adblock, not playing ad")
                //     onEnd()
                //     return
                // }

                // if (!shouldShowPrerollNow({ isPortal: true })) {
                //     onEnd()
                //     return
                // }


                const callbacks = {
                    adFinished: () => adDone(),
                    adError: (error) => adDone(),
                    adStarted: () => adStarted(),
                  };
                  window.CrazyGames.SDK.ad.requestAd("midgame", callbacks);

                console.log('Requested ad, pausing game')
                // window.adStartSoPause(null, { preventMovement: true })

                function adStarted() {
                    console.log('ad started')
                    pauseGame()
                }
                // function adDone(actions) {
                function adDone() {
                    // remove event listeners
                    resumeGame()

                    if (actions) {
                        console.log('playing preroll actions')
                        actions()
                    } else {
                        console.log('no actions')
                    }
                }
            }

            cmds.playRewarded = (actions) => {

                const callbacks = {
                    adFinished: () => adDone(),
                    adError: (error) => adDone(),
                    adStarted: () => adStarted(),
                  };
                  window.CrazyGames.SDK.ad.requestAd("rewarded", callbacks);

                // request ad
                // crazysdk.requestAd('rewarded')

                // console.log('Requested rewarded ad, pausing game')
                // window.adStartSoPause(null, { preventMovement: true })

                function adStarted() {
                    console.log('rewarded ad started')
                    pauseGame()
                }
                function adSuccess() {
                    console.log('rewarded ad finished')
                    onComplete()
                    resumeGame()
                }
                function adError(error) {
                    console.log('rewarded ad error', error)
                    onComplete()
                    resumeGame()
                }

                function adDone() {
                    // remove event listeners
                    resumeGame()

                    if (actions) {
                        console.log('playing preroll actions')
                        actions()
                    } else {
                        console.log('no actions')
                    }
                }
            }

            cmds.requestNewBanners = async (bannerInfos) => {
                // if (hasAdblock) {
                //     console.log("user has adblock, not playing ad")
                //     return
                // }
                const bannersToReq = []

                console.log(bannerInfos)

                for (const { divId, dims, width, height } of bannerInfos) {
                    if (!shouldRefreshBanner(divId)) {
                        continue
                    }
                    console.log('requesting banner', document.getElementById(divId))

                    try {
                        // await is not mandatory when requesting banners, but it will allow you to catch errors
                        await window.CrazyGames.SDK.banner.requestBanner({
                            id: divId,
                            width: width,
                            height: height
                        });
                      } catch (e) {
                        console.log("Banner request error", e);
                      }



                    // window.CrazyGames.SDK.banner.requestBanner({
                    //     id: divId,
                    //     width: width,
                    //     height: height
                    // })
                }
            }


            cmds.gameplayStartCmds = () => {
                console.log('called gameplay start')
                window.CrazyGames.SDK.game.gameplayStart()
            }

            cmds.gameplayStopCmds = () => {
                console.log('called gameplay stop')
                window.CrazyGames.SDK.game.gameplayStop()
            }

            const cgAuthListener = (user) => {
                console.log("User changed", user)
                initiateLoginProcess('crazygames', baseUrl, referrer)
            }
            // add listener
            window.CrazyGames.SDK.user.addAuthListener(cgAuthListener);

            // })
        } else {
            console.log('loaded AIP sdk')
            loadScript('//api.adinplay.com/libs/aiptag/pub/MGC/mageclash.io/tag.min.js')

            if (referrer.includes('gamemonetize')) {
                window.SDK_OPTIONS = {
                    gameId: 'skb8oc3ntlp16me3kz1n328u7eze3bor',
                    onEvent: function (a) {
                        switch (a.name) {
                            case 'SDK_GAME_PAUSE':
                                () => { pauseGame() }
                                // pause game logic / mute audio
                                break
                            case 'SDK_GAME_START':
                                // advertisement done, resume game logic and unmute audio
                                () => { resumeGame() }
                                break
                            case 'SDK_READY':
                                // when sdk is ready
                                break
                        }
                    }
                };
                (function (a, b, c) {
                    var d = a.getElementsByTagName(b)[0]
                    a.getElementById(c) || (a = a.createElement(b), a.id = c, a.src = 'https://api.gamemonetize.com/sdk.js', d.parentNode.insertBefore(a, d))
                })(document, 'script', 'gamemonetize-sdk')

                cmds.playPreroll = (actions) => {
                    if (typeof sdk !== 'undefined' && sdk.showBanner !== 'undefined') {
                        sdk.showBanner()
                    }
                    if (actions) {
                        actions()
                    }
                    console.log('Requested ad, pausing game')
                    // window.adStartSoPause(null, { preventMovement: true })
                }
            } else {
                // if (window.aiptag) {
                //     console.log('window aiptag exists')
                //     window.aiptag.cmd.player.push(function () {
                //         window.aiptag.adplayer = new aipPlayer({
                //             AD_WIDTH: 960,
                //             AD_HEIGHT: 540,
                //             // AD_FULLSCREEN: false,
                //             AD_FULLSCREEN: true,
                //             AD_CENTERPLAYER: false,
                //             LOADING_TEXT: 'loading advertisement',
                //             PREROLL_ELEM: function () { return document.getElementById('preroll') },
                //             AIP_COMPLETE: function (evt) {
                //                 actionsHolder()
                //                 /*******************
                //                  ***** WARNING *****
                //                  *******************
                //                  Please do not remove the PREROLL_ELEM
                //                  from the page, it will be hidden automaticly.
                //                  If you do want to remove it use the AIP_REMOVE callback.
                //                 */
                //                 // alert("Preroll Ad Completed: " + evt);
                //             },
                //             AIP_REMOVE: function () {
                //                 // Here it's save to remove the PREROLL_ELEM from the page if you want. But it's not recommend.
                //             }
                //         });
                //     });

                //     console.log('added player', window.aiptag.cmd.player)

                // }

                // put aip preroll
                // cmds.playPreroll = (actions) => {
                //     console.log('playing aip ad', window.aiptag.adplayer)
                //     if (typeof window.aiptag.adplayer !== "undefined") {
                //         // ad lib is loaded
                //         if (actions) {
                //             actionsHolder = () => {
                //                 console.log('playing actions after player')
                //                 actions()
                //             }
                //             console.log('set actions', window.aiptag.adplayer)
                //         }
                //         window.aiptag.cmd.player.push(function () {
                //             window.aiptag.adplayer.startPreRoll()
                //         })
                //     } else {
                //         if (actions) {
                //             actions()
                //         }
                //         console.log("AIP ad lib not loaded, no ad playing")
                //     }

                // }

            }
        }
    }
    Actions.sdkLoaded(true)
}


const _lastRefreshedBanners = {}
function shouldRefreshBanner(divId) {
    // initialise to 0 if first request
    if (_lastRefreshedBanners[divId] === undefined) {
        _lastRefreshedBanners[divId] = 0
    }

    if (Date.now() - _lastRefreshedBanners[divId] < 60000) {
    // if (Date.now() - _lastRefreshedBanners[divId] < 10000) {
    // if (Date.now() - _lastRefreshedBanners[divId] < 1000) {
        console.log(
            `Not refreshing ${divId}, only ${(Date.now() - _lastRefreshedBanners[divId]) / 1000
            }s since ${divId} was last refreshed`
        )
        return false
    }
    _lastRefreshedBanners[divId] = Date.now()
    return true
}