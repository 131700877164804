export const mainStyle = {
    primary: '#fff1d2',
    secondary: '#eddca0',
    highscoreGrid: '#e7cab2',
    primaryBorder: '#FFFFFF',
    secondaryBorder: '#FFFFFF',
}
export let colorPalette = {
    primary: '#fff1d2',
    secondary: '#eddca0',
    highscoreGrid: '#e7cab2',
    primaryBorder: '#FFFFFF',
    secondaryBorder: '#FFFFFF',
}

export let mainBorderProperties = {
    borderRadius: '4px',
    borderWidth: '1.5px', borderStyle: 'solid',
    borderColor: '#FFFFFF',
    boxShadow: '0 0 0 2px #362D44',
}

export let bigScreenBorderProperties = {
    borderRadius: '6px',
    borderWidth: '2px',
    borderStyle: 'solid',
    boxShadow: '0 0 0 2px #362D44',
    // borderColor: colorPalette.primaryBorder,
};

export let innerScreenBorderProperties = {
    borderRadius: '3px',
    borderWidth: '2px', borderStyle: 'solid',
    borderColor: '#FFFFFF',
    boxShadow: '0 0 0 2px #362D44',
}


export let invertedInnerScreenBorderProperties = {
    borderRadius: '3px',
    borderWidth: '2px', borderStyle: 'solid',
    borderColor: '#362D44',
    // boxShadow: `0 0 0 2px ${colorPalette.secondaryBorder}`,
}

export let dropDownMenuProperties = Object.assign({
    background: colorPalette.secondary,
    boxShadow: `0 0 0 2px ${colorPalette.secondaryBorder}`,
    // fontWeight: 'bold'
}, invertedInnerScreenBorderProperties)

export let smallBorderProperties = {
    borderRadius: '2px',
    borderWidth: '1px', borderStyle: 'solid',
    borderColor: 'rgba(0,0,0,0.25)',
    boxShadow: '0 0 0 1px hsl(0, 0%, 2.5%)',
}

export let secondaryBackgroundColor = colorPalette.secondary

export let iconStyle = {
    fontSize: '3.5vw',
    minWidth: '50px',
    minHeight: '50px',
    maxHeight: '50px',
    maxWidth: '50px',

    borderRadius: '4px',
    borderWidth: '1.5px', borderStyle: 'solid',
    borderColor: 'rgba(0,0,0,0.25)',
    boxShadow: '0 0 0 2px hsl(0, 0%, 10%)'
}

export let iconStyleSmall = {
    fontSize: '3.5vw',

    minWidth: '40px',
    minHeight: '40px',
    maxHeight: '40px',
    maxWidth: '40px',

    borderRadius: '4px',
    borderWidth: '1.5px', borderStyle: 'solid',
    borderColor: 'rgba(0,0,0,0.25)',
    boxShadow: '0 0 0 2px hsl(0, 0%, 10%)'
}