import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import Constants from '../../shared/constants'
import { IconButton } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import GlyphButton from './GlyphButton.js'
import ChestButton from './ChestButton'
import PromoCode from './PromoCode'
import {
    updateSelectedHead,
    updateEmoteDict,
    toggleShopMenu,
    togglePaymentMenu,
    updateShopTab,
    toggleSignInMenu
} from '../actions/actions'
import {baseUrl, domainName, emoteDict as clientsideEmoteDict} from '../index.js'
import Dialog from '@mui/material/Dialog'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {bigScreenBorderProperties, colorPalette} from './uiStyles.js'

import XsollaIframe from './XsollaIframe.js'
import {CosmeticsData} from "../../shared/cosmetics/cosmetics";
import {HalloweenShop} from "./chestShops/HalloweenShop";
import {ChristmasShop} from "./chestShops/ChristmasShop";
import {NormalShop} from "./chestShops/NormalShop";

const styles = {
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
}


class Shop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // maxHeadsPerPage: 9,
            // maxEmotesPerPage: 9,
            maxHeadsPerPage: 12,
            maxEmotesPerPage: 12,
            dialog: false,
            page: 0,
            tab: 0,

            clickedHead: {
                id: 3,
                path: 'assets/player/heads/head_human_m.png',
                imgPath: 'head_human_m.png',
                name: 'Human Male',
                rarity: 'common'
            },
            clickedEmote: {
                id: 1,
                name: 'Thumbs Up',
                imgPath: 'thumb_up.png',
                rarity: 'common',
                unlockRequirement: null,
                free: true,
            },
            selectedEmoteSlot: 0,

            iconIndex: {
                0: 'idle',
                1: 'idle',
                2: 'idle',
                3: 'idle',
                4: 'idle',
                5: 'idle',
                6: 'idle',
                7: 'idle',
                8: 'idle',
            },
            tabIndex: {
                0: 'selected',
                1: 'idle',
                2: 'idle',
            },
            emoteSlotIndex: {
                0: 'selected',
                1: 'idle',
                2: 'idle',
                3: 'idle'
            },


            mtxClicked: false,
            xsollaToken: null
        }

    }

    // componentDidUpdate(prevProps, prevState) {
    //     const { accountKillStats } = this.props;
    //     if (prevProps.accountKillStats != accountKillStats) {
    //         this.componentDidMount()
    //     }
    // }


    processMtxPurchase(price, accountId, accountUsername, accountEmail, guestAccount) {
        if (guestAccount) {
            toggleSignInMenu(true)
            return
        }
        this.setState({
            mtxClicked: true,
        })
        let url = `${baseUrl}/account/userbuygems`
        fetch(url, {

            //     fetch(`https://store.xsolla.com/api/v2/project/${Constants.PROJECT_ID}/admin/payment`, {
            method: 'POST',
            // credentials: "include",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // "Access-Control-Allow-Credentials": true,
                // 'Access-Control-Allow-Origin': 'http://localhost:3000/'
            },
            body: JSON.stringify({
                user: {
                    id: accountId,
                    name: accountUsername,
                    email: accountEmail

                },
                price: price

            }
            )
        })
            .then(response => {

                // console.log('setting mtxclicked true', response)

                togglePaymentMenu(true)

                // console.log("RECEIVED RESPONSE", response)

                if (response.status === 200) {
                    return response.json().then(response => {
                        // console.log("RESPONSE JSON", response)
                        let token = response.token
                        this.setState({
                            mtxClicked: false,
                            // xsollaToken: "https://sandbox-secure.xsolla.com/paystation2/?access_token=svnx0mbE1qQox4Id6LGq5e4q6miXE25X"
                            xsollaToken: `https://secure.xsolla.com/paystation2/?access_token=${token}`
                        })


                    })
                }
                else {
                    console.log('WRONG GUEST RESPONSE')
                    this.setState({
                        mtxClicked: false,
                    })
                }

            })
    }

    componentDidMount() {

    }
    increasePage() {

        let { page, maxHeadsPerPage, maxEmotesPerPage, tab, } = this.state

        let table
        let maxPerPage

        if (tab == 0) {
            table = CosmeticsData.heads
            maxPerPage = maxHeadsPerPage
        } else if (tab == 1) {
            table = CosmeticsData.emotes
            maxPerPage = maxEmotesPerPage
        }

        if ((page + 1) * maxPerPage < table.length) {
            console.log('increasing page')
            this.setState({
                page: page + 1
            })
        } else {
            this.setState({
                page: 0
            })
        }
    }
    decreasePage() {
        let { page, maxHeadsPerPage, maxEmotesPerPage, tab } = this.state

        let table
        let maxPerPage

        if (tab == 0) {
            table = CosmeticsData.heads
            maxPerPage = maxHeadsPerPage
        } else if (tab == 1) {
            table = CosmeticsData.emotes
            maxPerPage = maxEmotesPerPage
        }

        if (page - 1 >= 0) {
            this.setState({
                page: page - 1
            })
        } else {
            this.setState({
                page: (Math.floor(table.length / maxPerPage))
            })
        }
    }


    render() {
        let { token, guestAccount, selectedHead, joinedDiscord, accountItems, emoteDict, loadoutDialog, shopDialog, accountUsername, accountId, accountEmail, paymentDialog, height, shopTab, referrer } = this.props
        let { dialog, page, clickedHead, maxHeadsPerPage,
            // tab,
            clickedEmote, selectedEmoteSlot, iconIndex, tabIndex, emoteSlotIndex, mtxClicked, xsollaToken } = this.state

        let tab = shopTab
        let tab0Color
        let tab1Color
        let tab2Color

        if (tab == 0) { tab0Color = '#FFD522' }
        else if (tabIndex[0] == 'hover') { tab0Color = '#FFEE77' } else { tab0Color = '#FFEFD2' }
        if (tab == 1) { tab1Color = '#FFD522' } else if (tabIndex[1] == 'hover') { tab1Color = '#FFEE77' } else { tab1Color = '#FFEFD2' }

        if (tab == 2) { tab2Color = '#FFD522' } else if (tabIndex[2] == 'hover') { tab2Color = '#FFEE77' } else { tab2Color = '#FFEFD2' }
            // console.log("ACCOUNT ITEMS", accountItems)
        

        let clickedItem
        if (tab == 0) {
            clickedItem = clickedHead
        } else if (tab == 1) {
            clickedItem = clickedEmote
        }
        // } else if (tab == 2 )


        const handleClickOpen = () => {
            toggleShopMenu(true)
        }
        const handleClose = () => {
            toggleShopMenu(false)
        }
        const handleChange = (event, newValue) => {
            this.setState({
                // tab: newValue,
                page: 0
            })
            updateShopTab(newValue)
        }


        let dialogHeight
        let fontSize
        let imageSize
        let imagePaddingHor
        let imagePaddingVert
        let showSpecialText
        let priceLocation
        let iconSize


        if (height < 585) {
            dialogHeight = '400px'
            fontSize = '10px'
            imagePaddingVert = '8px'
            imagePaddingHor = '10px'
            imageSize = '80px'
            showSpecialText = false
            priceLocation = '86%'
            iconSize = '28px'
        } else if (height < 800) {
            dialogHeight = '500px'
            fontSize = '18px'
            imagePaddingVert = '18px'
            imagePaddingHor = '15px'
            imageSize = '115px'
            showSpecialText = false
            priceLocation = '86%'
            iconSize = '31px'

        } else if (height < 1100) {
            dialogHeight = '700px'
            fontSize = '19px'
            imagePaddingVert = '35px'
            imagePaddingHor = '20px'
            imageSize = '160px'
            showSpecialText = true
            priceLocation = '89%'
            iconSize = '30px'
        } else {
            dialogHeight = '860px'
            fontSize = '23px'
            imagePaddingVert = '35px'
            imagePaddingHor = '30px'
            imageSize = '256px'
            showSpecialText = true
            priceLocation = '89%'
            iconSize = '33px'
        }


        return <>

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                style={{
                    padding: '10px',
                    zIndex: 100000,
                }}
                classes={{
                    paper: {
                    }
                }}
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={shopDialog}
                TransitionProps={{
                    onExited: () => {
                        let currHead = CosmeticsData.heads.find(head => head.id == selectedHead)
                        let currEmoteID = clientsideEmoteDict[selectedEmoteSlot]
                        let currEmote = CosmeticsData.emotes.find(emote => emote.id == currEmoteID)
                        // console.log("FOUND CURR EMOTE", currEmote)
                        this.setState(
                            {
                                clickedHead: currHead,
                                clickedEmote: currEmote,
                            })
                    }
                }}>
                <IconButton
                    style={{
                        alignSelf: 'flex-end',
                        marginTop: -5,
                        position: 'absolute'
                    }}
                    onClick={handleClose}
                    size="large">

                    <CancelPresentationIcon style={{ fontSize: '3rem' }} />
                </IconButton>

                <Grid container xs={12}
                    style={{
                        // minHeight: '860px',
                        // maxHeight: '860px'
                        minHeight: dialogHeight,
                        maxHeight: dialogHeight
                    }}

                >
                    {/* <iframe src="https://sandbox-secure.xsolla.com/paystation2/?access_token=svnxTu73Ur7vVV4ls2IJbtQ726OX6ecY"/> */}

                    <XsollaIframe
                        // dialog={mtxClicked}
                        dialog={paymentDialog}
                        xsollaToken={xsollaToken}

                    />
                    <Paper align='center' justify="center" alignItems="center" direction="row"
                        style={Object.assign({
                            background: colorPalette.primary,
                            // minHeight: '410px',
                            width: '100%',
                            position: 'relative',
                            borderColor: colorPalette.primaryBorder,
                        }, bigScreenBorderProperties)}>

                        {mtxClicked && <CircularProgress
                            style={{
                                position: 'absolute',
                                width: '100px',
                                height: '100px',
                                left: '50%',
                                bottom: '50%',
                                zIndex: 10000,
                            }} />
                        }


                        <Grid container
                            align='center' justifyContent="center" alignItems="center" direction="row"
                            style={{
                                marginBottom: '2.5%'
                            }}>

                            <Grid
                                item xs={12} style={{
                                    paddingBottom: '2px',
                                    padding: '13px'
                                }}>
                                {/* <h1><b> Loadout (Coming soon!)</b></h1> */}
                                <b style={{ fontSize: '22px' }}> Shop</b>
                            </Grid>
                            <Grid item xs={12}
                            // style={{ paddingBottom: '1.5%' }}
                            >
                                <Paper square>
                                    <Tabs
                                        value={tab}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        onChange={handleChange}
                                        centered

                                        style={{
                                            background: colorPalette.primary,
                                            marginBottom: '5px'


                                        }}
                                    >
                                        <Tab style={{
                                            background: tab0Color,
                                            color: 'black',
                                            fontSize: '20px',
                                            textTransform: 'none',

                                            marginRight: '10px',
                                            borderTopLeftRadius: '8px',
                                            borderTopRightRadius: '8px',

                                            borderStyle: 'solid',
                                            borderColor: '#362D44',
                                            borderWidth: '3px',
                                            boxShadow: '0 0 0 1px #FFFFFF',

                                            padding: '3px',
                                        }}

                                            onMouseOver={() => {
                                                tabIndex[0] = 'hover'
                                                this.setState({ tabIndex: tabIndex })
                                            }}
                                            onMouseOut={() => {
                                                tabIndex[0] = 'idle'
                                                this.setState({ tabIndex: tabIndex })
                                            }}

                                            label="Glyphs"

                                        />

                                        <Tab
                                            style={{
                                                background: tab1Color,

                                                color: 'black',
                                                fontSize: '20px',
                                                textTransform: 'none',

                                                marginRight: '10px',
                                                borderTopLeftRadius: '8px',
                                                borderTopRightRadius: '8px',

                                                borderStyle: 'solid',
                                                borderColor: '#362D44',
                                                borderWidth: '3px',
                                                boxShadow: '0 0 0 1px #FFFFFF',

                                                padding: '3px',
                                            }}
                                            onMouseOver={() => {
                                                tabIndex[1] = 'hover'
                                                this.setState({ tabIndex: tabIndex })
                                            }}
                                            onMouseOut={() => {
                                                tabIndex[1] = 'idle'
                                                this.setState({ tabIndex: tabIndex })
                                            }}
                                            label="Chests" />

                                        <Tab
                                            style={{
                                                background: tab2Color,

                                                color: 'black',
                                                fontSize: '20px',
                                                textTransform: 'none',

                                                marginRight: '10px',
                                                borderTopLeftRadius: '8px',
                                                borderTopRightRadius: '8px',

                                                borderStyle: 'solid',
                                                borderColor: '#362D44',
                                                borderWidth: '3px',
                                                boxShadow: '0 0 0 1px #FFFFFF',

                                                padding: '3px',
                                            }}
                                            onMouseOver={() => {
                                                tabIndex[2] = 'hover'
                                                this.setState({ tabIndex: tabIndex })
                                            }}
                                            onMouseOut={() => {
                                                tabIndex[2] = 'idle'
                                                this.setState({ tabIndex: tabIndex })
                                            }}
                                            label="Promo" />

                                        {/* <Tab
                                            style={{
                                                background: '#E1D0CF',
                                                marginRight: '10px',
                                                borderTopLeftRadius: '8px',
                                                borderTopRightRadius: '8px',

                                                fontSize: '20px',
                                                textTransform: 'none',

                                                borderStyle: 'solid',
                                                borderColor: '#362D44',
                                                borderWidth: '3px',
                                                boxShadow: '0 0 0 1px #FFFFFF',

                                                padding: '3px',
                                            }}
                                        label="Coming Soon" disabled
                                        /> */}

                                    </Tabs>




                                </Paper>

                            </Grid>
                            {tab === 0 && referrer === 'crazygames' && (
  <Grid
    container
    justifyContent="center" 
    alignItems="center" 
  >
    <h1 style={{
      fontSize: '2.5rem', 
      color: '#FFFFFF', 
      padding: '20px', 
      backgroundColor: 'rgba(0,0,0,0.7)',
    borderRadius: '2px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'rgba(0,0,0,0.25)',
    boxShadow: '0 0 0 2px #362D44',
    }}>
      Coming Soon!
    </h1>
  </Grid>
)}
{tab == 0 && referrer !== 'crazygames' &&
                        
                                <Grid container xs={12}
                                    style={{
                                        background: 'rgba(0,0,0,0)'
                                    }}
                                >


                                    <Grid container xs={12}>
                                        <Grid xs={4}>
                                            <GlyphButton
                                                Text1='100 Glyphs'
                                                Text2='$0.99'
                                                ImgSource='assets/ui/icon_glyph_shop_1_256.png'
                                                Background='#362d44'
                                                buttonFunction={() => this.processMtxPurchase(0.99, accountId, accountUsername, accountEmail, guestAccount)}
                                                imagePaddingVert={imagePaddingVert}
                                                imagePaddingHor={imagePaddingHor}
                                                imageSize={imageSize}
                                                fontSize={fontSize}
                                                showSpecialText={showSpecialText}
                                                priceLocation={priceLocation}
                                            />
                                        </Grid>
                                        <Grid xs={4}>
                                            <GlyphButton
                                                Text1='210 Glyphs'
                                                Text2='$1.99'
                                                ImgSource='assets/ui/icon_glyph_shop_2_256.png'
                                                Background='#362d44'
                                                buttonFunction={() => this.processMtxPurchase(1.99, accountId, accountUsername, accountEmail, guestAccount)}
                                                imagePaddingVert={imagePaddingVert}
                                                imagePaddingHor={imagePaddingHor}
                                                imageSize={imageSize}
                                                fontSize={fontSize}
                                                showSpecialText={showSpecialText}
                                                priceLocation={priceLocation}
                                            />
                                        </Grid>
                                        <Grid xs={4}>
                                            <GlyphButton
                                                Text1='875 Glyphs'
                                                Text2='$7.99'
                                                ImgSource='assets/ui/icon_glyph_shop_3_256.png'
                                                Background='#ff3b53'
                                                SpecialText='Most Popular!'
                                                buttonFunction={() => this.processMtxPurchase(7.99, accountId, accountUsername, accountEmail, guestAccount)}
                                                imagePaddingVert={imagePaddingVert}
                                                imagePaddingHor={imagePaddingHor}
                                                imageSize={imageSize}
                                                fontSize={fontSize}
                                                showSpecialText={showSpecialText}
                                                priceLocation={priceLocation}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12}>
                                        <Grid xs={4}>
                                            <GlyphButton
                                                Text1='1800 Glyphs'
                                                Text2='$14.99'
                                                ImgSource='assets/ui/icon_glyph_shop_4_256.png'
                                                Background='#362d44'
                                                buttonFunction={() => this.processMtxPurchase(14.99, accountId, accountUsername, accountEmail, guestAccount)}
                                                imagePaddingVert={imagePaddingVert}
                                                imagePaddingHor={imagePaddingHor}
                                                imageSize={imageSize}
                                                fontSize={fontSize}
                                                showSpecialText={showSpecialText}
                                                priceLocation={priceLocation}
                                            />
                                        </Grid>
                                        <Grid xs={4}>
                                            <GlyphButton
                                                Text1='5000 Glyphs'
                                                Text2='$34.99'
                                                ImgSource='assets/ui/icon_glyph_shop_5_256.png'
                                                Background='#362d44'
                                                buttonFunction={() => this.processMtxPurchase(34.99, accountId, accountUsername, accountEmail, guestAccount)}
                                                imagePaddingVert={imagePaddingVert}
                                                imagePaddingHor={imagePaddingHor}
                                                imageSize={imageSize}
                                                fontSize={fontSize}
                                                showSpecialText={showSpecialText}
                                                priceLocation={priceLocation}
                                            />
                                        </Grid>
                                        <Grid xs={4}>
                                            <GlyphButton
                                                Text1='15000 Glyphs'
                                                Text2='$99.99'
                                                ImgSource='assets/ui/icon_glyph_shop_6_256.png'
                                                Background='#267fff'
                                                SpecialText='Best Value!'
                                                buttonFunction={() => this.processMtxPurchase(99.99, accountId, accountUsername, accountEmail, guestAccount)}
                                                imagePaddingVert={imagePaddingVert}
                                                imagePaddingHor={imagePaddingHor}
                                                imageSize={imageSize}
                                                fontSize={fontSize}
                                                showSpecialText={showSpecialText}
                                                priceLocation={priceLocation}
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>
                            }
             
                            {tab == 1 &&
                                <Grid container
                                    align="center" justifyContent="center" alignItems="center"
                                    xs={12}
                                    style={{
                                        background: 'rgba(0,0,0,0)'
                                    }}
                                >
                                    {/*<Grid container*/}
                                    {/*    align="center" justify="center" alignItems="center"*/}
                                    {/*    xs={12}*/}
                                    {/*    style={{*/}
                                    {/*        paddingBottom: '1%'*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <HalloweenShop*/}
                                    {/*        iconSize={iconSize}*/}
                                    {/*        imagePaddingVert={imagePaddingVert}*/}
                                    {/*        imagePaddingHor={imagePaddingHor}*/}
                                    {/*        imageSize={imageSize}*/}
                                    {/*        fontSize={fontSize}*/}
                                    {/*        showSpecialText={showSpecialText}*/}
                                    {/*        priceLocation={priceLocation}*/}
                                    {/*        accountId={accountId}*/}
                                    {/*        accountUsername={accountUsername}*/}
                                    {/*        accountEmail={accountEmail}*/}
                                    {/*        token={token}*/}
                                    {/*        guestAccount={guestAccount}*/}
                                    {/*        accountItems={accountItems}*/}
                                    {/*        processMtxPurchase={this.processMtxPurchase}*/}

                                    {/*    />*/}
                                    {/*<ChristmasShop*/}
                                    {/*    iconSize={iconSize}*/}
                                    {/*    imagePaddingVert={imagePaddingVert}*/}
                                    {/*    imagePaddingHor={imagePaddingHor}*/}
                                    {/*    imageSize={imageSize}*/}
                                    {/*    fontSize={fontSize}*/}
                                    {/*    showSpecialText={showSpecialText}*/}
                                    {/*    priceLocation={priceLocation}*/}
                                    {/*    accountId={accountId}*/}
                                    {/*    accountUsername={accountUsername}*/}
                                    {/*    accountEmail={accountEmail}*/}
                                    {/*    token={token}*/}
                                    {/*    guestAccount={guestAccount}*/}
                                    {/*    accountItems={accountItems}*/}
                                    {/*    processMtxPurchase={this.processMtxPurchase}*/}
                                    {/*/>*/}
                                    <NormalShop
                                        iconSize={iconSize}
                                        imagePaddingVert={imagePaddingVert}
                                        imagePaddingHor={imagePaddingHor}
                                        imageSize={imageSize}
                                        fontSize={fontSize}
                                        showSpecialText={showSpecialText}
                                        priceLocation={priceLocation}
                                        accountId={accountId}
                                        accountUsername={accountUsername}
                                        accountEmail={accountEmail}
                                        token={token}
                                        guestAccount={guestAccount}
                                        accountItems={accountItems}
                                        processMtxPurchase={this.processMtxPurchase}
                                    />
                                    {/*</Grid>*/}
                                </Grid>
                            }
                            {tab == 2 &&
                                <PromoCode />
                            }
                        </Grid>
                    </Paper>
                </Grid>
            </Dialog>
        </>;
    }
}


function mapStateToProps(state) {
    return {
        selectedHead: state.stuff.selectedHead,
        joinedDiscord: state.stuff.joinedDiscord,
        accountItems: state.stuff.accountItems,
        accountUsername: state.stuff.accountUsername,
        emoteDict: state.stuff.emoteDict,
        accountId: state.stuff.accountId,
        accountEmail: state.stuff.accountEmail,
        paymentDialog: state.stuff.paymentDialog,
        height: state.stuff.height,

        shopDialog: state.stuff.shopDialog,
        shopTab: state.stuff.shopTab,

        token: state.stuff.token,
        guestAccount: state.stuff.guestAccount,
        referrer : state.stuff.referrer

    }
}

export default connect(
    mapStateToProps,
)(Shop)