import Constants from '../shared/constants'
import * as Actions from './actions/actions'
import {currentGame, domainInfo, gameanalytics, updateGameConstants} from './index'
import {minimapManager} from "./uiScene";
import {setGame, setSingleGame, setTitleLoaded} from "./actions/actions";

export function preload() {
    let gameName = domainInfo.gameName
    if (gameName !== 'mainportal') {
        updateGameConstants(gameName)
        setGame(gameName)
        currentGame.value = gameName
        setSingleGame(gameName)
    }
    setTitleLoaded(true)
    // this.plugins.get('rexwebfontloaderplugin').addToScene(this);

    // var config = {
    //     google: {
    //         families: ['Rubik']
    //         // families: ['Bangers']
    //     }
    // };
    // this.load.rexWebFont(config);


    // scene.load.plugin('rexvirtualjoystickplugin', 'https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexvirtualjoystickplugin.min.js', true);

    // var width = this.cameras.main.width;
    // var height = this.cameras.main.height;
    // var progressBar = this.add.graphics();
    // var progressBox = this.add.graphics();
    // let baseHeight = height/8
    // let baseX = (width/2) - 320/2


    // progressBox.fillStyle(0x222222, 0.8);
    // progressBox.fillRect(baseX, baseHeight, 320, 50);
    
    
    // var loadingText = this.make.text({
    //     x: baseX + (320/2),
    //     y: baseHeight + 50,
    //     text: 'Loading...',
    //     style: {
    //         font: '20px monospace',
    //         fill: '#ffffff'
    //     }
    // });

    this.load.atlas('flares', 'assets/particles/flares.png', 'assets/particles/flares.json');
    this.load.image('dirt', 'assets/particles/smoke_03.png');

    // loadingText.setOrigin(0.5, 0);
    this.load.spritesheet('hands_starter', 'assets/player/hands_starter.png', { frameWidth: 64, frameHeight: 64 })


    this.load.bitmapFont('rubikOutlined', 'assets/fonts/rubikBitmap.png', 'assets/fonts/rubikBitmap.xml')
    this.load.bitmapFont('basicRubik', 'assets/fonts/basicRubik.png', 'assets/fonts/basicRubik.xml')



    // var percentText = this.make.text({
    //     x: baseX + (320/2),
    //     y: baseHeight + 25,
    //     text: '0%',
    //     style: {
    //         font: '18px monospace',
    //         fill: '#ffffff'
    //     }
    // });
    // percentText.setOrigin(0.5, 0.5);

    let highestValueReached = 0



    this.load.on('progress', function (value) {
        if (value > highestValueReached) {
            highestValueReached = value
        }
        // progressBar.clear();
        // progressBar.fillStyle(0xffffff, 1);
        // progressBar.fillRect(baseX+10, baseHeight+10, 300 * value, 30);
        // percentText.setText(parseInt(value * 100) + '%');
        // console.log('progress', value)
        Actions.updateLoadProgress(Math.floor(highestValueReached*100))
    })

    this.load.once('complete', function () {
        gameanalytics.GameAnalytics.addDesignEvent('design:sessionTracker:gameLoaded')
        setTimeout(() => {
            Actions.gameLoadComplete(true)
            Actions.mapLoadComplete(true)
        }, 200)
    })
    // this.load.on('progress', function (value) {
    //     console.log(value);
    // });

   
    // Non-Map Assets



    // Map Assets

    this.load.spritesheet('spikes', 'assets/traps/spikeTileset.png', { frameWidth: 128, frameHeight: 128 })
    this.load.spritesheet('lavaPits', 'assets/traps/lavaPitTileset.png', { frameWidth: 128, frameHeight: 128 })

    this.load.image('floorSpritesheet', 'assets/floor.png', { frameWidth: 22, frameHeight: 22 })

    // Map for portal
    let map_path_portal = `assets/mainPortal/map/mainportal${Constants.MAP_JSON_NAME}`
    this.load.tilemapTiledJSON('portalmapmainportal', map_path_portal)

    // load portal maps for all games
    // Constants.GAME_MODE_MAP.forEach((gameMode) => {
    //     if (gameMode.gameName !== 'mageclash') {
    //         // TODO: add classic to this list soon?
    //         let path = `assets/games/mainportal/map/${gameMode.gameName}/${Constants.MAP_JSON_NAME}`
    //         this.load.tilemapTiledJSON(`portalmap${gameMode.gameName}`, path)
    //         // this.load.image(`minimap${gameMode.gameName}`,`assets/games/${gameMode.gameName}/map/map.jpg`)
    //     }
    // })
    // console.log('loaded portal map templates')

    // this.load.multiatlas('mainAssets', 'assets/mainGameAssetsAtlas.json', 'assets')

    let path = 'assets/mainAtlas'
    this.load.texture(
        // LoaderFileKeys.ui,
        'mainAssets',
        {
            // 'PVRTC': {
            //     type: 'PVR',
            //     multiAtlasURL: `${path}/mainGameAssetAtlasPVR.json`,
            //     multiPath: `${path}`
            // },
            // 'S3TC': {
            //     type: 'PVR',
            //     multiAtlasURL: `${path}/ui-dxt5.json`,
            //     multiPath: `${path}`
            // },
            'IMG': {
                multiAtlasURL: `${path}/mainGameAssetAtlas.json`,
                multiPath: `${path}`
            }
        }
    );
    // // Audio
    // // audiosprite --output sfx --export mp3,ogg,m4a --bitrate 64 --samplerate 44100 --channels 1 * on windows on .wav folder
    // // then transfer files to prev folder
    // // must delete sfx files in wav folder if exists already
    // this.load.audioSprite('sfx', 'assets/sound/sfx.json', ['assets/sound/sfx.mp3', 'assets/sound/sfx.ogg', 'assets/sound/sfx.m4a']);

    // this.load.image('map_bg', 'assets/other/map_bg.png')

}

export function loadRemainingAssets() {
    // LOAD AFTER MENU 

    // // Audio
    // // audiosprite --output sfx --export mp3,ogg,m4a --bitrate 64 --samplerate 44100 --channels 1 * on windows on .wav folder
    // // then transfer files to prev folder
    // // must delete sfx files in wav folder if exists already
    this.load.audioSprite('sfx', 'assets/sound/sfx.json', ['assets/sound/sfx.mp3', 'assets/sound/sfx.ogg', 'assets/sound/sfx.m4a'])

    // Spells singleLoad
    this.load.spritesheet('projectile15', 'assets/spells/projectile15.png', { frameWidth: 512, frameHeight: 256 })
    this.load.spritesheet('projectile15white', 'assets/spells/projectile15white.png', { frameWidth: 512, frameHeight: 256 })
    this.load.spritesheet('projectile17', 'assets/spells/projectile17.png', { frameWidth: 512, frameHeight: 256 })
    this.load.spritesheet('explosion4', 'assets/spells/explosion4.png', { frameWidth: 256, frameHeight: 256 })
    this.load.spritesheet('projectile19', 'assets/spells/projectile19.png', { frameWidth: 512, frameHeight: 256 })
    this.load.spritesheet('projectile24', 'assets/spells/projectile24.png', { frameWidth: 512, frameHeight: 256 })
    this.load.spritesheet('projectile25', 'assets/spells/projectile25.png', { frameWidth: 512, frameHeight: 256 })
    this.load.spritesheet('explosion3', 'assets/spells/explosion3-dark.png', { frameWidth: 256, frameHeight: 256 })
    this.load.spritesheet('explosion3black', 'assets/spells/explosion3black.png', { frameWidth: 256, frameHeight: 256 })
    this.load.spritesheet('explosion3air', 'assets/spells/explosion3air.png', { frameWidth: 256, frameHeight: 256 })
    this.load.spritesheet('explosion4red', 'assets/spells/explosion4red.png', { frameWidth: 256, frameHeight: 256 })
    this.load.spritesheet('explosion9', 'assets/spells/explosion9.png', { frameWidth: 256, frameHeight: 256 })
    this.load.spritesheet('explosion5', 'assets/spells/explosion5-ice.png', { frameWidth: 256, frameHeight: 256 })
    this.load.spritesheet('explosion2', 'assets/spells/explosion2.png', { frameWidth: 256, frameHeight: 256 })
    this.load.spritesheet('explosion8', 'assets/spells/explosion8.png', { frameWidth: 256, frameHeight: 256 })
    this.load.spritesheet('projectile9green', 'assets/spells/projectile9green.png', { frameWidth: 512, frameHeight: 256 })


    this.load.spritesheet('projectile8', 'assets/spells/projectile8.png', { frameWidth: 512, frameHeight: 256 })

    this.load.spritesheet('waterProjectile', 'assets/spells/waterProjectile.png', { frameWidth: 150, frameHeight: 150 })

    // Weapons
    // this.load.image('ice-staff', 'assets/equips/Staff_512_02.png');
    // this.load.image('fire-staff', 'assets/equips/Staff_512_08.png');
    // this.load.image('air-staff', 'assets/equips/Staff_512_03.png');
    this.load.unityAtlas('swords', 'assets/equips/Sword_sheet.png', 'assets/Sword_sheet.png.meta')

    // Hands

    this.load.spritesheet('hands_wizard_uncommon', 'assets/player/hands_wizard_uncommon.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_wizard_rare', 'assets/player/hands_wizard_rare.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_wizard_epic', 'assets/player/hands_wizard_epic.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_wizard_legendary', 'assets/player/hands_wizard_legendary.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_rogue_uncommon', 'assets/player/hands_rogue_uncommon.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_rogue_rare', 'assets/player/hands_rogue_rare.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_rogue_epic', 'assets/player/hands_rogue_epic.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_rogue_legendary', 'assets/player/hands_rogue_legendary.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_knight_uncommon', 'assets/player/hands_knight_uncommon.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_knight_rare', 'assets/player/hands_knight_rare.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_knight_epic', 'assets/player/hands_knight_epic.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_knight_legendary', 'assets/player/hands_knight_legendary.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_slime_rare', 'assets/player/hands_slime_rare.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_slime_epic', 'assets/player/hands_slime_epic.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_slime_legendary', 'assets/player/hands_slime_legendary.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_ice_legendary', 'assets/player/hands_ice_legendary.png', { frameWidth: 64, frameHeight: 64 })
    this.load.spritesheet('hands_crab', 'assets/player/hands_crab.png', { frameWidth: 64, frameHeight: 64 })

    // Slime
    this.load.spineJson('slime', 'assets/enemies/spine/Slime_256.json')
    // this.load.spineAtlas('slime_atlas', 'assets/enemies/spine/Slime_256.atlas.txt', true)
    this.load.spineAtlas('slime_atlas', 'assets/enemies/spine/Slime_256.atlas.txt', true)

    // Boss Slime
    this.load.spineJson('bossSlime', 'assets/enemies/spine/Slime_256.json')
    this.load.spineAtlas('bossSlime_atlas', 'assets/enemies/spine/Boss_slime.atlas.txt', true)

    // Slime Melee
    this.load.spineJson('slimeMelee', 'assets/enemies/spine/Slime_256.json')
    this.load.spineAtlas('slimeMelee_atlas', 'assets/enemies/spine/Slime_red.atlas.txt', true)

    // Anubis
    this.load.spineJson('anubis', 'assets/enemies/spine/Anubis_rig.json')
    this.load.spineAtlas('anubis_atlas', 'assets/enemies/spine/Anubis_rig.atlas.txt', true)

    // Scorpion
    this.load.spineJson('scorpion', 'assets/enemies/spine/Scorpion.json')
    this.load.spineAtlas('scorpion_atlas', 'assets/enemies/spine/Scorpion.atlas.txt', true)

    // Mummy
    this.load.spineJson('mummy', 'assets/enemies/spine/Mummy.json')
    this.load.spineAtlas('mummy_atlas', 'assets/enemies/spine/Mummy.atlas', true)

    // Boss Lava Slime
    this.load.spineJson('bossLavaSlime', 'assets/enemies/spine/Slime_256.json')
    this.load.spineAtlas('bossLavaSlime_atlas', 'assets/enemies/spine/Boss_Lava_Slime.atlas.txt', true)

    // Lava Slime
    this.load.spineJson('lavaSlime', 'assets/enemies/spine/Slime_256.json')
    this.load.spineAtlas('lavaSlime_atlas', 'assets/enemies/spine/Lava_slime.atlas.txt', true)

    // Ice Slime
    this.load.spineJson('iceSlime', 'assets/enemies/spine/Slime_256.json')
    this.load.spineAtlas('iceSlime_atlas', 'assets/enemies/spine/Ice_slime.atlas.txt', true)

    // Ice Slime Boss
    this.load.spineJson('iceSlimeBoss', 'assets/enemies/spine/Slime_256.json')
    this.load.spineAtlas('iceSlimeBoss_atlas', 'assets/enemies/spine/Ice_slime_boss.atlas.txt', true)

    // Leopard
    this.load.spineJson('leopard', 'assets/enemies/spine/Leopard_256.json')
    this.load.spineAtlas('leopard_atlas', 'assets/enemies/spine/Leopard_256.atlas.txt', true)

    // Boss Leopard
    this.load.spineJson('bossLeopard', 'assets/enemies/spine/Leopard_256.json')
    this.load.spineAtlas('bossLeopard_atlas', 'assets/enemies/spine/Boss_leopard.atlas.txt', true)

    // Dark Wizard
    this.load.spineJson('darkWiz', 'assets/enemies/spine/Dark_Wizard_128.json')
    this.load.spineAtlas('darkWiz_atlas', 'assets/enemies/spine/Dark_Wizard_128.atlas.txt', true)

    // Dark Wizard Boss
    this.load.spineJson('darkWizBoss', 'assets/enemies/spine/Dark_Wizard_128.json')
    this.load.spineAtlas('darkWizBoss_atlas', 'assets/enemies/spine/Dark_Wizard_Boss.atlas.txt', true)

    // Dragon Big
    this.load.spineJson('dragonBig', 'assets/enemies/spine/Dragon_Boss.json')
    this.load.spineAtlas('dragonBig_atlas', 'assets/enemies/spine/Dragon_Boss.atlas.txt', true)

    // Evil Creature
    this.load.spineJson('evilCreature', 'assets/enemies/spine/Evil_Creature_256.json')
    this.load.spineAtlas('evilCreature_atlas', 'assets/enemies/spine/Evil_Creature_256.atlas.txt', true)

    // Boss Charlie
    this.load.spineJson('bossCharlie', 'assets/enemies/spine/Evil_Creature_256.json')
    this.load.spineAtlas('bossCharlie_atlas', 'assets/enemies/spine/Charlie_boss.atlas.txt', true)

    // Boss Charlie Halloween
    this.load.spineJson('bossCharlieHalloween', 'assets/enemies/spine/Evil_Creature_256.json')
    this.load.spineAtlas('bossCharlieHalloween_atlas', 'assets/enemies/spine/Charlie_boss_halloween.atlas.txt', true)

    // Mini Charlie
    this.load.spineJson('miniCharlie', 'assets/enemies/spine/Evil_Creature_256.json')
    this.load.spineAtlas('miniCharlie_atlas', 'assets/enemies/spine/Mini_charlie.atlas.txt', true)

    // Woodland Whelp
    this.load.spineJson('woodlandWhelp', 'assets/enemies/spine/Woodland_creature_256.json')
    this.load.spineAtlas('woodlandWhelp_atlas', 'assets/enemies/spine/Woodland_creature_256.atlas.txt', true)

    // Boss Woodland
    this.load.spineJson('bossWoodland', 'assets/enemies/spine/Woodland_creature_256.json')
    this.load.spineAtlas('bossWoodland_atlas', 'assets/enemies/spine/Boss_woodland.atlas.txt', true)

    // Cannon
    this.load.spineJson('cannon', 'assets/enemies/spine/Cannon.json')
    this.load.spineAtlas('cannon_atlas', 'assets/enemies/spine/Cannon.atlas.txt', true)

    // Cannon Boss
    this.load.spineJson('cannonBoss', 'assets/enemies/spine/Cannon.json')
    this.load.spineAtlas('cannonBoss_atlas', 'assets/enemies/spine/Boss_cannon.atlas.txt', true)

    // Dragon Whelp
    this.load.spineJson('dragonWhelp', 'assets/enemies/spine/Dragon_Whelp_256.json')
    this.load.spineAtlas('dragonWhelp_atlas', 'assets/enemies/spine/Dragon_Whelp_256.atlas.txt', true)

    // Baby Dragon Whelp
    this.load.spineJson('babyDragonWhelp', 'assets/enemies/spine/Baby_Dragon_Whelp_256.json')
    this.load.spineAtlas('babyDragonWhelp_atlas', 'assets/enemies/spine/Baby_Dragon_Whelp_256.atlas.txt', true)

    // Crab Normal
    this.load.spineJson('crabNormal', 'assets/enemies/spine/crab_normal_256.json')
    this.load.spineAtlas('crabNormal_atlas', 'assets/enemies/spine/crab_normal_256.atlas.txt', true)

    // Crab Superior
    this.load.spineJson('crabSuperior', 'assets/enemies/spine/crab_normal_256.json')
    this.load.spineAtlas('crabSuperior_atlas', 'assets/enemies/spine/crab_superior_256.atlas.txt', true)

    // Crab Boss
    this.load.spineJson('crabBoss', 'assets/enemies/spine/crab_normal_256.json')
    this.load.spineAtlas('crabBoss_atlas', 'assets/enemies/spine/crab_boss_256.atlas.txt', true)

    // Starfish Normal
    this.load.spineJson('starfishNormal', 'assets/enemies/spine/starfish_normal.json')
    this.load.spineAtlas('starfishNormal_atlas', 'assets/enemies/spine/starfish_normal.atlas.txt', true)

    // Starfish Normal 2
    this.load.spineJson('starfishNormal2', 'assets/enemies/spine/starfish_normal.json')
    this.load.spineAtlas('starfishNormal2_atlas', 'assets/enemies/spine/starfish_normal2.atlas.txt', true)

    // Starfish Superior
    this.load.spineJson('starfishSuperior', 'assets/enemies/spine/starfish_normal.json')
    this.load.spineAtlas('starfishSuperior_atlas', 'assets/enemies/spine/starfish_superior.atlas.txt', true)

    // Flower Creature
    this.load.spineJson('flowerCreature', 'assets/enemies/spine/flower_creature_256.json')
    this.load.spineAtlas('flowerCreature_atlas', 'assets/enemies/spine/flower_creature_256.atlas.txt', true)

    // Melee Boss
    this.load.spineJson('meleeBoss', 'assets/enemies/spine/melee_boss_256.json')
    this.load.spineAtlas('meleeBoss_atlas', 'assets/enemies/spine/melee_boss_256.atlas.txt', true)

    // Hellhound
    this.load.spineJson('hellhound', 'assets/enemies/spine/hellhound_256.json')
    this.load.spineAtlas('hellhound_atlas', 'assets/enemies/spine/hellhound_256.atlas.txt', true)

    // Hellhound Boss
    this.load.spineJson('hellhoundBoss', 'assets/enemies/spine/hellhound_boss.json')
    this.load.spineAtlas('hellhoundBoss_atlas', 'assets/enemies/spine/hellhound_boss.atlas.txt', true)

    // Enemy Piranha
    this.load.spineJson('enemy_piranha', 'assets/enemies/spine/piranha_256.json')
    this.load.spineAtlas('enemy_piranha_atlas', 'assets/enemies/spine/piranha_256.atlas.txt', true)

    // Enemy Piranha Boss
    this.load.spineJson('enemy_piranha_boss', 'assets/enemies/spine/piranha_boss.json')
    this.load.spineAtlas('enemy_piranha_boss_atlas', 'assets/enemies/spine/piranha_boss.atlas.txt', true)

    // Enemy Seahorse
    this.load.spineJson('enemy_seahorse', 'assets/enemies/spine/sea_horse_normal_256.json')
    this.load.spineAtlas('enemy_seahorse_atlas', 'assets/enemies/spine/sea_horse_normal_256.atlas.txt', true)

    // Enemy Seahorse Superior
    this.load.spineJson('enemy_seahorse_superior', 'assets/enemies/spine/sea_horse_superior.json')
    this.load.spineAtlas('enemy_seahorse_superior_atlas', 'assets/enemies/spine/sea_horse_superior.atlas.txt', true)

    // Enemy Seahorse Boss
    this.load.spineJson('enemy_seahorse_boss', 'assets/enemies/spine/sea_horse_boss.json')
    this.load.spineAtlas('enemy_seahorse_boss_atlas', 'assets/enemies/spine/sea_horse_boss.atlas.txt', true)

    // Enemy Mammoth Normal
    this.load.spineJson('enemy_mammoth_normal', 'assets/enemies/spine/mammoth_normal_256.json')
    this.load.spineAtlas('enemy_mammoth_normal_atlas', 'assets/enemies/spine/mammoth_normal_256.atlas.txt', true)

    // Enemy Mammoth Superior
    this.load.spineJson('enemy_mammoth_superior', 'assets/enemies/spine/mammoth_superior.json')
    this.load.spineAtlas('enemy_mammoth_superior_atlas', 'assets/enemies/spine/mammoth_superior.atlas.txt', true)

    // Enemy Mammoth Boss
    this.load.spineJson('enemy_mammoth_boss', 'assets/enemies/spine/mammoth_boss.json')
    this.load.spineAtlas('enemy_mammoth_boss_atlas', 'assets/enemies/spine/mammoth_boss.atlas.txt', true)

    // Enemy Ice Golem
    this.load.spineJson('enemy_ice_golem', 'assets/enemies/spine/ice_golem_normal_256.json')
    this.load.spineAtlas('enemy_ice_golem_atlas', 'assets/enemies/spine/ice_golem_normal_256.atlas.txt', true)

    // Enemy Ice Golem Boss
    this.load.spineJson('enemy_ice_golem_boss', 'assets/enemies/spine/ice_golem_boss.json')
    this.load.spineAtlas('enemy_ice_golem_boss_atlas', 'assets/enemies/spine/ice_golem_boss.atlas.txt', true)

    // Ent Normal
    this.load.spineJson('ent_normal', 'assets/enemies/spine/ent_normal_256.json')
    this.load.spineAtlas('ent_normal_atlas', 'assets/enemies/spine/ent_normal_256.atlas.txt', true)

    // Ent Boss 1
    this.load.spineJson('ent_boss_1', 'assets/enemies/spine/ent_boss_1.json')
    this.load.spineAtlas('ent_boss_1_atlas', 'assets/enemies/spine/ent_boss_1.atlas.txt', true)

    // Ent Boss 2
    this.load.spineJson('ent_boss_2', 'assets/enemies/spine/ent_boss_2.json')
    this.load.spineAtlas('ent_boss_2_atlas', 'assets/enemies/spine/ent_boss_2.atlas.txt', true)


    this.load.start()
    this.load.once('complete', () => {

        // PLAY BGM
        this.sfx = this.sound.addAudioSprite('sfx', { volume: Constants.GAME_OVERALL_VOLUME })
        // this.sfx = this.sound.addAudioSprite('sfx', { volume: Constants.GAME_OVERALL_VOLUME})
        this.sound.volume = Constants.GAME_OVERALL_VOLUME

        this.sfx.play('bgm', { loop: true })
        // AFTER MENU
        this.anims.create({
            key: 'projectile15',
            frames: this.anims.generateFrameNumbers('projectile15', {
                frames: [0, 1, 2, 3, 4, 5, 6, 7]
            }),
            repeat: -1
        })
        this.anims.create({
            key: 'projectile15white',
            frames: this.anims.generateFrameNumbers('projectile15white', {
                frames: [0, 1, 2, 3, 4, 5, 6, 7]
            }),
            repeat: -1
        })
        this.anims.create({
            key: 'projectile17',
            frames: this.anims.generateFrameNumbers('projectile17', {
                frames: [0, 1, 2, 3, 4, 5]
            }),
            repeat: -1
        })
        this.anims.create({
            key: 'explosion4',
            frames: this.anims.generateFrameNumbers('explosion4', {
                frames: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27]
            }),
        })
        this.anims.create({
            key: 'explosion4red',
            frames: this.anims.generateFrameNumbers('explosion4red', {
                frames: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27]
            }),
        })
        this.anims.create({
            key: 'projectile19',
            frames: this.anims.generateFrameNumbers('projectile19', {
                frames: [0, 1, 2, 3, 4, 5]
            }),
            repeat: -1
        })
        this.anims.create({
            key: 'projectile24',
            frames: this.anims.generateFrameNumbers('projectile24', {
                frames: [0, 1, 2, 3, 4, 5]
            }),
            repeat: -1
        })
        this.anims.create({
            key: 'projectile25',
            frames: this.anims.generateFrameNumbers('projectile25', {
                frames: [0, 1, 2, 3, 4]
            }),
            repeat: -1
        })
        this.anims.create({
            key: 'explosion3',
            frames: this.anims.generateFrameNumbers('explosion3', {
                frames: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
            }),
        })
        this.anims.create({
            key: 'explosion3air',
            frames: this.anims.generateFrameNumbers('explosion3air', {
                frames: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
            }),
        })
        this.anims.create({
            key: 'explosion3black',
            frames: this.anims.generateFrameNumbers('explosion3black', {
                frames: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
            }),
        })

        this.anims.create({
            key: 'explosion9',
            frames: this.anims.generateFrameNumbers('explosion9', {
                frames: [0, 1, 2, 3, 4, 5, 6, 7]
            }),
            repeat: -1
        })
        this.anims.create({
            key: 'explosion5',
            frames: this.anims.generateFrameNumbers('explosion5', {
                frames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
            }),
        })
        this.anims.create({
            key: 'explosion2',
            frames: this.anims.generateFrameNumbers('explosion2', {
                frames: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27]
            }),
        })
        this.anims.create({
            key: 'explosion8',
            frames: this.anims.generateFrameNumbers('explosion8', {
                frames: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27]
            }),
        })

        this.anims.create({
            key: 'projectile9green',
            frames: this.anims.generateFrameNumbers('projectile9green', {
                frames: [0, 1, 2, 3, 4, 5]
            }),
            repeat: -1
        })
        

        this.anims.create({
            key: 'waterProjectile',
            frames: this.anims.generateFrameNumbers('waterProjectile', {
                // frames: [0, 1, 2, 3, 4]
                // frames: [2]
                frames: [1,2, 3]
            }),
            repeat: -1,
            timeScale: 0.2,
        })

        this.anims.create({
            key: 'projectile8',
            frames: this.anims.generateFrameNumbers('projectile8', {
                frames: [0, 1, 2, 3, 4, 5, 6, 7]
            }),
            repeat: -1
        })

        // setTimeout(
        //     () => {
        Actions.loadedRemainingFiles(true)
        // }, 700
        // )
        this.load.off('progress')

    })
  

  
    /////////////////////////////////////////////////////////////////////////////////////////////////////
}