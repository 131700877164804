import React, { Component } from 'react'
import { Grid, Box } from '@mui/material'
import { Paper } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { connect } from 'react-redux'
import MageClashTitle from './MageClashTitle.js'
import Profile from './Profile.js'
import SignInButton from './SignInButton.js'

class SignInPanel extends Component {
    constructor() {
        super()
        this.state = {

        }
    }

    render() {
        // let { loadProgress } = this.props
        let {authenticated, guestAccount} = this.props

        return (
            <Grid container xs={12} 
            style={{ 
                width: '100%',
                // paddingleft: '0.5%'
            }}
            >
                <Grid item xs={12} >      
                        {authenticated && <Profile />}
                </Grid>
                <Grid item xs={12}>
                    {(!authenticated || (authenticated && guestAccount)) && <SignInButton />}
                </Grid>
            </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        // loadProgress: state.stuff.loadProgress,
        authenticated: state.stuff.authenticated,
        guestAccount: state.stuff.guestAccount
    }
}

export default connect(
    mapStateToProps,
)(SignInPanel)
