"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DailyQuestRewardsData = exports.DailyQuestsData = void 0;
exports.DailyQuestsData = [
    {
        id: 1,
        title: 'Kill King Slime',
        difficulty: 'easy',
        type: 'kill',
        enemyId: 4,
        quantity: 1,
    },
    {
        id: 2,
        title: 'Kill Slimes',
        difficulty: 'easy',
        type: 'kill',
        enemyId: 1,
        quantity: 25,
    },
    {
        id: 3,
        title: 'Kill Chimeras',
        difficulty: 'easy',
        type: 'kill',
        enemyId: 6,
        quantity: 15,
    },
    {
        id: 4,
        title: 'Kill Woodland Critters',
        difficulty: 'easy',
        type: 'kill',
        enemyId: 7,
        quantity: 15,
    },
    {
        id: 5,
        title: 'Kill Cannons',
        difficulty: 'medium',
        type: 'kill',
        enemyId: 9,
        quantity: 20,
    },
    {
        id: 6,
        title: 'Kill Charlies',
        difficulty: 'medium',
        type: 'kill',
        enemyId: 5,
        quantity: 15,
    },
    {
        id: 7,
        title: 'Kill Dark Wizards',
        difficulty: 'hard',
        type: 'kill',
        enemyId: 2,
        quantity: 15,
    },
    {
        id: 8,
        title: 'Kill King Chimera',
        difficulty: 'hard',
        type: 'kill',
        enemyId: 12,
        quantity: 1,
    },
    {
        id: 9,
        title: 'Kill Woodland Beast',
        difficulty: 'hard',
        type: 'kill',
        enemyId: 13,
        quantity: 1,
    },
    {
        id: 10,
        title: 'Kill Crabs',
        difficulty: 'easy',
        type: 'kill',
        enemyId: 20,
        quantity: 15,
    },
    {
        id: 11,
        title: 'Kill Sea Horses',
        difficulty: 'medium',
        type: 'kill',
        enemyId: 35,
        quantity: 15,
    },
    {
        id: 12,
        title: 'Kill Superior Crabs',
        difficulty: 'medium',
        type: 'kill',
        enemyId: 31,
        quantity: 8,
    },
    {
        id: 13,
        title: 'Kill Mammoths',
        difficulty: 'hard',
        type: 'kill',
        enemyId: 38,
        quantity: 15,
    },
    {
        id: 14,
        title: 'Kill Ice Golems',
        difficulty: 'hard',
        type: 'kill',
        enemyId: 41,
        quantity: 15,
    },
];
exports.DailyQuestRewardsData = {
    easy: { type: 'coins', quantity: 15 },
    medium: { type: 'coins', quantity: 15 },
    hard: { type: 'coins', quantity: 15 },
};
