import React, { Component } from 'react'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

class ColorChangingIcon extends Component {
    constructor() {
        super()
        this.state = {
            fillColor: '#E7CAB2',
            outerBorderColor: '#362D44',
            innerBorderColor: '#FFF1D2',
            config: {
                idle: {
                    fillColor: '#E7CAB2',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFF1D2',
                },
                hover: {
                    fillColor: '#E7CAB2',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFF1D2',
                },
                down: {
                    fillColor: '#E7CAB2',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFF1D2',
                }
            }
        }
    }

    componentDidMount() {
        let { config } = this.props
        this.setState({
            config: config,
            fillColor: config.idle.fillColor,
            outerBorderColor: config.idle.outerBorderColor,
            innerBorderColor: config.idle.innerBorderColor
        })
    }

    render() {
        let { fillColor, innerBorderColor, outerBorderColor, config } = this.state
        let { buttonFunction, buttonStyling, textColor, IconImage, imgUrl, hotkey } = this.props
        return (
            <Box component="span">
                <Box component="span">
                    <Box component="span">
                <IconButton
                    style={{ color: '#e9b699' }}
                    variant="contained"
                    onMouseOver={() => {
                        this.setState({
                            fillColor: config.hover.fillColor,
                            outerBorderColor: config.hover.outerBorderColor,
                            innerBorderColor: config.hover.innerBorderColor
                        })
                    }}
                    onMouseOut={() => {
                        this.setState({
                            fillColor: config.idle.fillColor,
                            outerBorderColor: config.idle.outerBorderColor,
                            innerBorderColor: config.idle.innerBorderColor
                        })
                    }
                    }
                    onMouseDown={(e) => {
                        e.nativeEvent.stopImmediatePropagation()
                        e.preventDefault()
                        e.stopPropagation()

                        this.setState({
                            fillColor: config.down.fillColor,
                            outerBorderColor: config.down.outerBorderColor,
                            innerBorderColor: config.down.innerBorderColor
                        })
                    }}
                    onClick={(e) => {
                        e.nativeEvent.stopImmediatePropagation()
                        e.preventDefault()
                        e.stopPropagation()
                        buttonFunction()
                    }}
                    size="large">
                    <>
                    { imgUrl &&
                        <img style=
                        
                        {Object.assign({ pointerEvents: 'none',
                            background: fillColor,
                            borderColor: innerBorderColor,
                            boxShadow: `0 0 0 2px ${outerBorderColor}`, },
                        buttonStyling
                        )}
                        src={imgUrl} />
                    } 
                    { !imgUrl &&
                    <IconImage
                        style={Object.assign({
                            background: fillColor,
                            borderColor: innerBorderColor,
                            boxShadow: `0 0 0 2px ${outerBorderColor}`,
                        }, buttonStyling)} />
                    }
                        {hotkey &&
                            <Box component="span" style={{ color: 'white', fontSize: '0.8em', position: 'absolute',
                                bottom: '0.5em', right: '0.7em',
                                textShadow: '1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000'
                            }}>
                                {hotkey}
                            </Box>
                        }
                    </>
                </IconButton >
            </Box>
            </Box>
             </Box >
        );
    }
}

export default (ColorChangingIcon)