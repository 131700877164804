import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'
import { Button } from '@mui/material'
import { ButtonBase } from '@mui/material'
import {startGame, updatePickups} from '../index.js'
import { IS_TOUCH } from '../game.js'
import { updateUsernameInput, updateCodeInput, updateSelectedHead, toggleInitGame, updateGamePlayedOnce } from '../actions/actions'
import * as Actions from '../actions/actions'
import Constants from '../../shared/constants'
import MonsterJournal from './MonsterJournal.js'
import InventoryPanel from './InventoryPanel.js'
import ColorChangingButton from './ColorChangingButton'
import Shop from './Shop.js'
import { resetGameObjects } from '../create.js'
import {bigScreenBorderProperties, colorPalette} from './uiStyles.js'
import LinearProgress from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles';
``

import { cmds } from '../sdkIntegration'

const StyledLinearProgress = styled(LinearProgress)({
    backgroundColor: '#362D44', // Color for the background
    '& .MuiLinearProgress-bar': {
        backgroundColor: '#0A83D4', // Color for the bar
    },
});

class StartMenu extends Component {
    constructor() {
        super()
        this.state = {
            // buttonPath: 'btn_play_idle.png'
            buttonPath: 'btn_loading.png',
            imageLoaded: false
            // buttonPath: btnPath
        }

        try {
            let joinedDiscord
            if (localStorage.hasOwnProperty('joinedDiscord') && (localStorage.getItem('joinedDiscord') !== 'null') && (localStorage.getItem('joinedDiscord') !== '')) {
                joinedDiscord = JSON.parse(localStorage.getItem('joinedDiscord'))
            }
            else {
                localStorage.setItem('joinedDiscord', JSON.stringify(false))
                joinedDiscord = false
            }
            Actions.updateJoinedDiscord(joinedDiscord)
        } catch (err) { }
    }

    componentDidMount() {
        try {
            if (localStorage.hasOwnProperty('username') && (localStorage.getItem('username') !== 'null') && (localStorage.getItem('username') !== '')) {
                let username = localStorage.getItem('username')
                updateUsernameInput(username)
                // loaded = true;;
            }
        } catch (err) { }
        // let { loadedRemainingFiles } = this.props
        // let btnPath
        // if (loadedRemainingFiles) { btnPath = 'btn_play_idle.png' } else { btnPath = 'btn_loading.png' }
        if (this.props.loadedRemainingFiles && this.props.authenticated) {
                this.setState({
                    buttonPath: 'btn_play_idle.png'
                })
        }

 


    }

    componentDidUpdate(prevProps) {
        if (!prevProps.loadedRemainingFiles && this.props.loadedRemainingFiles) {
            if (this.props.authenticated) {
                this.setState({
                    buttonPath: 'btn_play_idle.png'
                })
            }
        }
        if (!prevProps.authenticated && this.props.authenticated) {
            if (this.props.loadedRemainingFiles) {
                this.setState({
                    buttonPath: 'btn_play_idle.png'
                })
            }
        }
    }
    render() {

        let { currentGame, pickupUIItem, usernameInput, codeInput, selectedServer, selectedHead, gameLoaded, accountLevel, accountExp, joinedDiscord, initGame, adblock, token, guestAccount, loadedRemainingFiles, gamePlayedOnce } = this.props
        let { buttonPath, imageLoaded } = this.state

        const handleChange = (event) => {
            if (event.target.value.length <= Constants.USERNAME_CHAR_LIMIT) {
                let value = event.target.value
                // value = value.replace(/[^A-Za-z]/ig, '')
                value = value.replace(/[^A-Za-z0-9]/ig, '')
                updateUsernameInput(value)
                // updateUsernameInput(event.target.value);
            }
        }
        const handleSelectChange = (event) => {
            updateSelectedHead(event.target.value)
        };
        const handleCodeChange = (event) => {
            updateCodeInput(event.target.value)
        };




        return <>
            <Grid container xs={12}
                justifyContent="center"
                style={{
                    minHeight: '315px',
                    maxHeight: '315px',
                    alignItems: 'center',
                }}>
                <Grid container xs={12}
                    justifyContent="center"

                    style={{
                        minHeight: '100%',
                        maxHeight: '100%',
                        height: '100%',
                        alignItems: 'center',
                        minHeight: '90px',
                        maxHeight: '90px',
                    }}>
                    <Paper
                        justify="center"
                        style={{
                            width: '100%',
                            background: colorPalette.primary,
                            marginBottom: '2%',
                            minHeight: '90px',
                            maxHeight: '90px',
                            borderColor: colorPalette.primaryBorder,
                         ...bigScreenBorderProperties}}>
                        <Grid container align='center' justifyContent="center" alignItems="center" xs={12}>
                            {/* <h4> */}
                            {/* <b > ENTER USERNAME </b> */}
                            <div style={{
                                fontWeight: 900,
                                // fontSize: 'max(1.5vh, 16px)', 
                                fontSize: 'max(1.5vh, 19px)',
                                maxHeight: '31px',
                                paddingBottom: '3%'
                            }}> ENTER USERNAME </div>

                            {/* </h4> */}
                        </Grid>
                        <Grid container align='center' justifyContent="center" alignItems="center" xs={12}
                        >

                            <input
                                style={{
                                    marginBottom: '3%',
                                    borderRadius: '4px',
                                    borderWidth: '1.5px', borderStyle: 'solid',
                                    borderColor: 'rgba(0,0,0,0.25)',
                                    boxShadow: '0 0 0 2px hsl(0, 0%, 10%)',
                                    background: colorPalette.secondary,
                                    width: '95%',
                                    outline: 'none',
                                    height: '30px',
                                    textAlign: 'center',
                                    fontSize: '20px',
                                    cursor: 'url(assets/ui/cursor_text.png), text'
                                }}
                                value={usernameInput}
                                onChange={handleChange}
                            />
                        </Grid>

                    </Paper>
                </Grid>

                <Grid xs={12} style={{
                    minHeight: '5px', maxHeight: '5px',
                }}></Grid>




                <Grid container xs={12}
                    justifyContent="center"
                    style={{
                        minHeight: '100%',
                        maxHeight: '100%',
                        height: '100%',
                        alignItems: 'center',
                        marginTop: '3%',
                        minHeight: '205px',
                        maxHeight: '205px',
                    }}>
                    <Paper style={{
                        userSelect: 'none', textAlign: 'center',
                        backgroundColor: 'white',
                        width: '100%',

                        background: colorPalette.primary,
                        borderWidth: '1px',
                        // borderColor: 'black',

                        borderStyle: 'solid',
                        minHeight: '220px',
                        maxHeight: '220px',
                        borderColor: colorPalette.primaryBorder,
                        ...bigScreenBorderProperties}}>

                        <Shop />
                        {/* <Grid item xs={12}>
                            <InventoryPanel />
                        </Grid> */}
                        <Grid container xs={12}>


                            <Grid xs={1}></Grid>
                            <Grid container justifyContent="flex-end" 
                            style={{
                                paddingRight: '5px'
                            }}
                            xs={5}>
                                <InventoryPanel />
                            </Grid>
                            <Grid container justifyContent="flex-start"
                                style={{
                                    paddingLeft: '5px'
                                }}
                            xs={5}>
                                <ColorChangingButton buttonFunction={
                                    () => {

                                        Actions.updateShopTab(1)
                                        Actions.toggleShopMenu(true)

                                    }
                                }
                                    config={
                                        {
                                            idle: {
                                                fillColor: '#7C4E4D',
                                                outerBorderColor: '#362D44',
                                                innerBorderColor: '#543131',
                                            },
                                            hover: {
                                                fillColor: '#CC7A82',
                                                outerBorderColor: '#362D44',
                                                innerBorderColor: '#AA5561',
                                            },
                                            down: {
                                                fillColor: '#CC7A82',
                                                outerBorderColor: '#362D44',
                                                innerBorderColor: '#AA5561',
                                            },
                                        }
                                        // {
                                        //     idle: {
                                        //         fillColor: '#FFD522',
                                        //         outerBorderColor: '#362D44',
                                        //         innerBorderColor: '#F79E3C',
                                        //     },
                                        //     hover: {
                                        //         fillColor: '#FEFF69',
                                        //         outerBorderColor: '#362D44',
                                        //         innerBorderColor: '#FFD522',
                                        //     },
                                        //     down: {
                                        //         fillColor: '#F49540',
                                        //         outerBorderColor: '#362D44',
                                        //         innerBorderColor: '#D66C16',
                                        //     },
                                        // }
                                    }
                                    title='Shop'
                                    textColor='white'
                                    buttonStyling={{
                                        marginTop: '10px',
                                        //  marginRight: '5px',
                                        color: 'white',
                                        width: '100%',
                                        // width: '70%',
                                        // padding: '1%'
                                        paddingTop: '1%', paddingBottom: '1%'
                                    }}
                                />
                            </Grid>
                            <Grid xs={1}></Grid>
                         

                            {/* <Grid item xs={2}></Grid>
                            <Grid container alignItems="flex-start"  xs={5}>
                                <InventoryPanel />
                            </Grid>
                            <Grid item xs={3}>
                                <ColorChangingButton buttonFunction={
                                    () => {

                                        Actions.updateShopTab(1)
                                        Actions.toggleShopMenu(true)
                                    
                                    }
                                }
                                    config={
                                        {
                                            idle: {
                                                fillColor: '#7C4E4D',
                                                outerBorderColor: '#362D44',
                                                innerBorderColor: '#543131',
                                            },
                                            hover: {
                                                fillColor: '#CC7A82',
                                                outerBorderColor: '#362D44',
                                                innerBorderColor: '#AA5561',
                                            },
                                            down: {
                                                fillColor: '#CC7A82',
                                                outerBorderColor: '#362D44',
                                                innerBorderColor: '#AA5561',
                                            },
                                        }

                                    }
                                    title='Shop'
                                    buttonStyling={{
                                        marginTop: '10px',
                                        //  marginRight: '5px',
                                        color: 'white',
                                        // width: '70%',
                                        // padding: '1%'
                                        paddingTop: '1%', paddingBottom: '1%'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}></Grid> */}

                        </Grid>
                        
                        <Grid container xs={12}>
                            <Grid xs={1}></Grid>
                            <Grid container align='center' justifyContent="center" alignItems="center" xs={10}>
                            <MonsterJournal />
                            </Grid>
                            <Grid xs={1}></Grid>
                        </Grid>

                        <>

                            <Grid item xs={12} style={{
                                // minHeight: '20px',
                                // paddingTop: '5%'
                                position: 'relative'
                            }}>
                                {!imageLoaded &&
                                    <StyledLinearProgress
                                        style={{
                                            position: 'absolute',
                                            left: '50%',
                                            // top: "50%",
                                            transform: 'translate(-50%, -0%)',
                                            width: '130px',
                                            height: '40px',
                                            margin: '2%',
                                            borderRadius: '6px',
                                            // marginBottom: '2.5%',
                                            borderWidth: '3px', borderStyle: 'solid', borderColor: 'black',
                                            boxShadow: '0 0 0 2px #FCD5A2',
                                        }}
                                    />

                                }


                                <ButtonBase
                                    // <button
                                    style={{
                                        margin: '3%',
                                    }}
                                    // onMouseOver={() => this.src = 'assets/ui/btn_play_hover.png'}
                                    // onMouseOut={() => this.src = 'assets/ui/btn_play_idle.png'}


                                    onMouseOver={() => {
                                        if (buttonPath != 'btn_connecting.png' && buttonPath != 'btn_loading.png') {
                                            this.setState({ buttonPath: 'btn_play_hover.png' })
                                        }
                                    }}
                                    onMouseOut={() => {
                                        if (buttonPath != 'btn_connecting.png' && buttonPath != 'btn_loading.png') {
                                            this.setState({ buttonPath: 'btn_play_idle.png' })
                                        }
                                    }
                                    }
                                    onMouseDown={() => {
                                        if (buttonPath != 'btn_connecting.png' && buttonPath != 'btn_loading.png') {
                                            this.setState({ buttonPath: 'btn_play_down.png' })
                                        }
                                    }
                                    }

                                    onMouseUp={() => {
                                        if (buttonPath != 'btn_connecting.png' && buttonPath != 'btn_loading.png') {
                                            this.setState({ buttonPath: 'btn_play_up.png' })
                                        }
                                    }
                                    }
                                    onClick={() => {
                                        if (loadedRemainingFiles && buttonPath == 'btn_play_up.png') {
                                            Actions.updateLoadProgress(0)
                                            Actions.gameLoadComplete(false)
                                            resetGameObjects(this.props.currentGame)
                                            // updatePickups(this.props.currentGame)


                                            window.onbeforeunload = function (e) {
                                                return 'Do you want to exit this page?'
                                            }


                                            this.setState({ buttonPath: 'btn_connecting.png' })

                                            try {
                                                localStorage.setItem('username', usernameInput)
                                            } catch (err) { }
                                            // startGame(usernameInput, selectedHead, selectedServer, codeInput)

                                            // cmds.gameplayStart()
                                            let actions = () => {
                                                startGame(usernameInput, selectedHead, selectedServer, adblock, token, guestAccount, IS_TOUCH)
                                                toggleInitGame(true)
                                               
                                                updateGamePlayedOnce(true)
                                                setTimeout(() => {
                                                    toggleInitGame(false)
                                                    this.setState({ buttonPath: 'btn_play_up.png' })
                                                }, 5000)
                                            }

                                            if (gamePlayedOnce) {
                                                cmds.playPreroll(actions)
                                             } else {
                                                 actions()
                                             }

                                        }
                                    }}
                                >

                                    <img
                                        style={{
                                            width: '90%',
                                            pointerEvents: 'none',
                                            pointerEvents: 'none',
                                        }}
                                        src={`assets/ui/${buttonPath}`}
                                        onLoad={
                                            () => {
                                                this.setState({ imageLoaded: true })
                                            }
                                        }
                                    />


                                </ButtonBase>
                                {/* </button> */}

                            </Grid>
                        </>

                        {/* } */}
                        {/* {gameLoaded == true && initGame &&
                    <Grid item xs={12} >
                        <Button size='large' variant="contained"
                            style={{ padding: '5%', margin: '2%', fontSize: 'large' }}
                        > CONNECTING </Button>
                    </Grid>
                } */}
                        {gameLoaded == false &&
                            <Grid item xs={12} >
                                <Button size='large' variant="contained"
                                    style={{ padding: '5%', margin: '2%', fontSize: 'large' }}
                                > Loading... </Button>
                            </Grid>
                        }
                        {/* <Grid item xs={12} >
                    <TextField
                        style={{
                            padding: '3%',
                        }}
                        label="Alpha Code"
                        value={codeInput}
                        onChange={handleCodeChange}
                    />
                </Grid> */}
                    </Paper>
                </Grid>
            </Grid>
        </>;
    }
}

function mapStateToProps(state) {
    return {
        currentGame: state.stuff.currentGame,
        pickupUIItem: state.stuff.pickupUIItem,
        usernameInput: state.stuff.usernameInput,
        selectedServer: state.stuff.selectedServer,
        selectedHead: state.stuff.selectedHead,
        gameLoaded: state.stuff.gameLoaded,
        accountLevel: state.stuff.accountLevel,
        accountExp: state.stuff.accountExp,
        accountExpRatio: state.stuff.accountExpRatio,
        codeInput: state.stuff.codeInput,
        joinedDiscord: state.stuff.joinedDiscord,
        initGame: state.stuff.initGame,
        adblock: state.stuff.adblock,
        token: state.stuff.token,
        guestAccount: state.stuff.guestAccount,
        loadedRemainingFiles: state.stuff.loadedRemainingFiles,
        gamePlayedOnce: state.stuff.gamePlayedOnce,
        authenticated: state.stuff.authenticated,
    }
}

export default connect(
    mapStateToProps,
)(StartMenu)
