"use strict";
const ElementsData = [
    {
        'name': 'Fire Mana',
        'refName': 'fire',
        'id': 1,
    },
    {
        'name': 'Water Mana',
        'refName': 'water',
        'id': 2,
    },
    {
        'name': 'Air Mana',
        'refName': 'air',
        'id': 3,
    },
    {
        'name': 'Earth Mana',
        'refName': 'earth',
        'id': 4,
    },
    {
        'name': 'Dark Mana',
        'refName': 'dark',
        'id': 5,
    },
    {
        'name': 'Light Mana',
        'refName': 'light',
        'id': 6,
    },
];
module.exports = ElementsData;
