import Constants from '../../shared/constants'
export class HealthBar {

    constructor(scene, x, y) {
      this.bar = new Phaser.GameObjects.Graphics(scene)
      this.bar.setDepth(Constants.DEPTHS.entityLabels)
      this.x = x
      this.y = y
      this.value = 100
      this.p = 76 / 100

      this.draw()
  
      this.addedBar = scene.add.existing(this.bar)

    }
  
    decrease(amount) {
      this.value -= amount
  
      if (this.value < 0) {
        this.value = 0
      }
  
      this.draw()
  
      return (this.value === 0)
    }
  
    draw() {
      this.bar.clear()
  
      if (this.value != 100) {
      //  BG
      this.bar.fillStyle(0x000000)
        let rectHeight = 16/3
        if (this.barType === 'mana') {rectHeight /= 2}
      this.bar.fillRect(this.x, this.y, 80 / 3, rectHeight)
  
      //  Health
  
      this.bar.fillStyle(0xffffff)
        let fillHeight = 12/3
        if (this.barType === 'mana') {fillHeight /= 2}
        let yAdd = 2/3
        if (this.barType === 'mana') {yAdd = 1/3}
      this.bar.fillRect(this.x + 2 / 3, this.y + yAdd, 76 / 3, fillHeight)

      if (this.barType === 'mana') {
        this.bar.fillStyle(0x0000FF)
      } else {
        if (this.value < 30) {
          this.bar.fillStyle(0xff0000)
        }
        else {
          this.bar.fillStyle(0x00ff00)
        }
      }

      if (this.value < 0) {
        this.value = 0
      }
      var d = Math.floor(this.p * this.value)
  
      this.bar.fillRect(this.x + 2 / 3, this.y + yAdd, d / 3, fillHeight)


    }
    }
  
  }