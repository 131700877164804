// Learn more about this file at:
// https://victorzhou.com/blog/build-an-io-game-part-1/#6-client-input-%EF%B8%8F
import {
  updateDirection,
  updateAngle,
  shootBullet,
  pickupObject,
  sheatheWeapon,
  upgradeStats,
  restartGameRequest,
  sendEmote,
  sendChat,
  updateCPosition, takeDamage, giveDamage
} from './networking'


function onMouseInput(e) {
  handleInput(e.clientX, e.clientY)
}

function onTouchInput(e) {
  const touch = e.touches[0]
  handleInput(touch.clientX, touch.clientY)
}



export function handleShot(dir, spellID, spellSlot=0, clickData=undefined) {
  let dirVal
  if (dir === null || typeof dir === "undefined") {
  }
  else if (typeof dir == 'number') {
    dirVal = Number.parseFloat(dir.toPrecision(3))
  }
  else  {
    dirVal = [Math.round(dir[0]), Math.round(dir[1])]
  }
  let bulletData = [dirVal, spellID, spellSlot]
  if (clickData) {
    let clickDataOptim = Object.assign({...clickData}, {objectRef: undefined})
    bulletData.push(clickDataOptim)
  }
  shootBullet(bulletData)
}

export function handleInput(dir, angle) {
  let dirVal
  if (dir === null) {
  }
  else  {
    dirVal = dir
  }
  // updateDirection([dirVal, Math.round(angle)])
  updateDirection(dirVal)
  updateAngle(Math.round(angle))

}
export function handleAngleUpdate(angle) {
    updateAngle(Math.round(angle))
}
export function handleCMovement(position) {
  updateCPosition(position)
}

export function handleTakeDamage(damage, spellId, friendly) {
  takeDamage([damage, spellId, friendly])
}

export function handleGiveDamage(damage, spellId, enemyId) {
  giveDamage([damage, spellId, enemyId])
}

export function handlePickupObject(currentlyPressed, assignedDict, actionKey) {

  let spellID
  if (assignedDict[currentlyPressed].spellID) {
    spellID = assignedDict[currentlyPressed]['spellID']
  }
  else {spellID = null}
  // pickupObject(spellID)
  if (actionKey === undefined) {
    actionKey = 1
  }
  console.log('calling handle pickup object', [+currentlyPressed, actionKey])
  pickupObject([+currentlyPressed, actionKey])
}

export function handleSheatheWeapon(currentlyPressed) {
  let weapon = 0
  if (currentlyPressed == 1) {
    weapon = 1
  }
  sheatheWeapon(weapon)
}

export function handleUpgradeStat(stat){
  upgradeStats(stat)
}

export function handleRestartGame() {
  restartGameRequest()
}

export function handleEmote(emote) {
  sendEmote(emote)
}

export function handleChat(input) {
  sendChat(input)
}