import React, { Component } from 'react'
import { Grid, Paper } from '@mui/material'
import { connect } from 'react-redux'
import { TableBody, TableCell, TableContainer, TableRow } from '@mui/material'


class Leaderboard extends Component {
    constructor() {
        super()
        this.state = {
            'leaderboard': []
        }
    }

    render() {
        let { leaderboard } = this.props

        let playerCount = leaderboard.length
        let leaderboardBox = []
        // leaderboardBox.push(<Grid container>
        //     <Grid xs={12}> <h4 style={{ padding: '0', margin: '5%' }}> Leaderboard </h4> </Grid>
        // </Grid>)
        // leaderboardBox.push(
        // <Grid container>
        //     <Grid xs={6}> <h5 style={{padding: '0', margin: '0', marginBottom: '10%'}}>  Username </h5> </Grid>
        //     <Grid xs={6}> <h5 style={{padding: '0', margin: '0', marginBottom: '10%'}}>  Score  </h5> </Grid>
        // </Grid>)

        for (let i = 0; i < 5; i++) {
            let backgroundColor
            if ((i % 2) == 0){ backgroundColor = '#efd3bd'} else {backgroundColor = '#d8bca7'}
            if (i < playerCount) {
                let player = leaderboard[i]
               
                leaderboardBox.push(
                    // <Grid container>
                    //     <Grid xs={6}> <div> {player.username} </div> </Grid>
                    //     <Grid xs={6}> <div> {player.score} </div> </Grid>
                    // </Grid>
                  
                    <TableRow align='center' hover
                    style={{
                        backgroundColor: backgroundColor,
                        width: '100%'
                    }}
                    >
                        <TableCell align='center'
                            style={{
                                // padding: '1px',
                                padding: '0px',
                                borderBottom: 'none',
                                width: '50%'
                            }}>
                            {player.username}
                        </TableCell>
                        <TableCell align='center'
                            style={{
                                // padding: '1px',
                                padding: '0px',
                                borderBottom: 'none',
                                width: '50%'
                            }}>
                            {player.score}
                        </TableCell>
                    </TableRow>
                )
            }
            else {
                leaderboardBox.push(
                    //     <Grid container>
                    //     <Grid xs={6}> -------- </Grid>
                    //     <Grid xs={6}> --------  </Grid>
                    // </Grid>
                    <TableRow align='center' hover    
                    style={{
                        backgroundColor: backgroundColor,
                        width: '100%'
                    }}
                    >
                        <TableCell align='center'
                            style={{
                                // padding: '1px',
                                padding: '0px',
                                borderBottom: 'none',
                                width: '50%'
                            }}>
                            --------
                </TableCell>
                        <TableCell align='center'
                            style={{
                                // padding: '1px',
                                padding: '0px',
                                borderBottom: 'none',
                                width: '50%'
                            }}>
                            ------
                </TableCell>
                    </TableRow>
                )
            }
        }


        return (
            <Grid container xs={12} style={{
                marginBottom: '5%', width: '100%', height: '100%',
                // minWidth: '120px'
            }}>
                <Paper style={{
                    width: '100%', userSelect: 'none', textAlign: 'center',
                    paddingBottom: '3px',
                    paddingRight: '3px',
                    paddingLeft: '3px',

                    borderRadius: '4px',
                    borderWidth: '1px', borderStyle: 'solid',
                    borderColor: 'white',
                    boxShadow: '0 0 0 1px #000000',
                }}>
                    <h4 style={{ padding: '0', margin: '5%' }}> Leaderboard </h4>
                <Paper style={{
                    background: '#e6cab2',
                    borderRadius: '4px',
                    borderWidth: '1px', borderStyle: 'solid',
                    borderColor: 'black',
                    boxShadow: '0 0 0 1px #ffffff',
                    padding: '2px'
                }}>
                    <TableContainer align='center' style={{
                        height: '100%',
                        width: '100%'
                    }} >
                        <TableBody style={{width: '100%'}}>
                            {leaderboardBox}
                        </TableBody>
                    </TableContainer>

                </Paper>
                </Paper>
            </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        leaderboard: state.stuff.leaderboard
    }
}


export default connect(
    mapStateToProps,
)(Leaderboard)
