import React, { Component } from 'react'
import SpellBox from './SpellBox.js'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'
import ButtonBase from '@mui/material/ButtonBase'
import { updateSpellSelection } from '../game.js'
import Constants from "../../shared/constants";
import {serverSettings} from "../equippedSpells";
class SpellContainer extends Component {
    constructor() {
        super()

        let possibleNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9']
        let numbersToAdd = serverSettings.fullObjTest.maxSpells - 1
        let availableKeys = []
        for (let i = 0; i < numbersToAdd; i++) {
            availableKeys.push(possibleNumbers[i])
        }
        availableKeys.push('shift')
        this.state = {
            // selectedKey: currentlyPressed,
            // spells: equippedSpells
            availableSpellKeys: availableKeys,
        }
    }

    componentDidMount() {

    }

    shoudlComponentUpdate(nextProps, nextState) {
        return false
    }
    render() {
        let { availableSpellKeys } = this.state
        let { selectedKey, shiftActive, spellsList, availableMana, spellCooldowns, availableResources,
            spellReloads, spellPointers, gameFocusOverlay
        } = this.props

        let spellBoxes = availableSpellKeys.map((spellSlot) => {
            // Determine if the spell is selected without mutating the original spellsList
            // turn possible int to string to var parsedSelectedKey
            let parsedSelectedKey = selectedKey
            if (typeof selectedKey == 'number') {
                parsedSelectedKey = selectedKey.toString()
            }


            let isSelected = parsedSelectedKey === spellSlot;
            if (!serverSettings.fullObjTest.selectableKeys) {
                isSelected = false
            }

            // Get spell details
            const spell = spellsList[spellSlot];
            const spellCooldown = spellCooldowns[spell.assignedKey];

            // Determine margin right value
            const marginRightValue = spellSlot === 'shift' ? '0%' : '0.8%';

            // Define spell label
            let spellLabel = spellSlot === 'shift' ? 'R Click' : spellSlot;

            let spellPointerActive = spellPointers[spellSlot]

            return (
                <Grid
                    xs={2} 
                    md={1}
                    style={{ marginRight: marginRightValue }}
                >
                    <ButtonBase
                        onMouseDown={(e) => {
                            e.nativeEvent.stopImmediatePropagation();
                            e.preventDefault();
                            e.stopPropagation();
                            updateSpellSelection(spellSlot);
                        }}
                        style={{ height: '100%', width: '100%' }}
                    >
                        <SpellBox
                            spell={spell}
                            selected={isSelected}
                            availableMana={availableMana}
                            availableResources={availableResources}
                            assignedKey={spellSlot}
                            label={spellLabel}
                            spellCooldown={spellCooldown}
                            triggerReload={spellReloads[spellSlot]}
                            spellPointerActive={spellPointerActive}
                            gameFocusOverlay={gameFocusOverlay}
                        />
                    </ButtonBase>
                </Grid>
            );
        });


        return (
            <Grid container
                justifyContent="flex-end" direction="row" alignItems="flex-start"
                style={{
                    marginRight: '0.5%',
                    height: '100%', width: '100%',
                }}>
                <Grid xs={5}></Grid>
                <Grid container justifyContent="flex-end" xs={7}>{spellBoxes}</Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedKey: state.stuff.selectedKey,
        shiftActive: state.stuff.shiftActive,
        spellsList: state.stuff.spellsList,
        availableMana: state.stuff.availableMana,
        availableResources: state.stuff.availableResources,
        spellCooldowns: state.stuff.slotCooldowns,
        spellReloads: state.stuff.spellReloads,
        spellPointers: state.stuff.spellPointers,
        gameFocusOverlay: state.stuff.gameFocusOverlay,
    }
}

//   function mapDispatchToProps(dispatch) {
//     return {
//       stuffActions: bindActionCreators(stuffActions, dispatch)
//     };
// }
export default connect(
    mapStateToProps,
    // mapDispatchToProps
)(SpellContainer)
// export default SpellContainer;