import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Paper, Box, ButtonBase } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'


import {styled} from "@mui/material/styles";
import { clickGenericBar } from '../networking'

export const defaultGenericBarOptionsValues = {
  color: 'white',
  clickable: false,
  inBarName: '',
  inBarNameColor: 'white'
}
const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    '&.MuiLinearProgress-colorPrimary': {
        backgroundColor: '#443f54',
    },
    '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#14cfed',
    }
}));

class GenericBar extends Component {
  constructor() {
    super()
    this.state = {
      selected: false,
      timer: 100,
    }
  }

  componentDidMount() {

  }
  render() {
    const { id, name, value, settings } = this.props
    const { color, clickable, inBarName, inBarNameColor} = settings

    const ProgressBar = (<><StyledLinearProgress variant="determinate"
    value={value} style={{ 
       minHeight: '15px',
       borderRadius: '2px',
      boxShadow: '0 0 0 1px #362D44',
      minWidth: '100%',
       }}
       />
       <Typography variant="body2" color="textSecondary" style={{
        position: 'absolute',
        color: inBarNameColor,
        left: '50%',
        transform: 'translateX(-50%)',
    }}>
        {inBarName}
    </Typography></>
       )

    const onClickFunc = clickable ? () => {
      console.log('clicked', name)
    clickGenericBar(id)
  } : () => {}

    return (
      <Grid container spacing={0} justifyContent="center" style={{ marginBottom: '5%', marginLeft: '5%' , height: '100%', width: '100%', }}>
        <Paper style={{
            width: '20vw',
            maxWidth: '200px',
        height: '100%',
          borderRadius: '6px',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'rgba(0,0,0,0.25)',
        boxShadow: '0 0 0 2px #362D44',
          borderWidth: '1px', borderStyle: 'solid',
        paddingBottom: '3px',
        paddingRight: '3px',
        paddingLeft: '3px',
        background: color
        }} >
        <Grid xs={12} style={{ 'textAlign': 'center', height: '100%', width: '100%', marginBottom: '0.5%' }}>
            <div style={{height: '100%',
            //  fontSize: '12px',
            width: '100%'
            }}><strong>
                {name}
                </strong></div>
        </Grid>
        
        <Grid xs={12} style={{ 'textAlign': 'center', height: '100%', width: '100%',
      }}>
      <ButtonBase
        onClick={onClickFunc}
        style={{ width: '100%', height: '100%', pointerEvents: 'auto' }}
        > 
        {ProgressBar} 
        </ButtonBase>
        </Grid>
        </Paper>
      </Grid>
    );
  }
}

export default GenericBar