import React, { Component } from 'react'
import { connect } from 'react-redux'
import { player } from '../create.js'
import { playEmote } from '../playerScripts.js'
import { phaserObject } from '../create.js'
import {CosmeticsData} from "../../shared/cosmetics/cosmetics";
import { Menu, MenuItem } from '@spaceymonk/react-radial-menu';
import {updateEmoteBarCenter} from "../keyBinds";

class EmoteBar extends Component {
    constructor() {
        super()
        this.state = {
            selected: null,
        }
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState) {
        const { emoteDict} = this.props
        let {selected} = this.state
        if (prevProps.emoteBarData.showMenu != this.props.emoteBarData.showMenu) {
            if (selected !== null) {
                playEmote(player, selected, emoteDict, phaserObject)
                this.setState({ selected: null })
            }

        }
    }

    render() {
        let { emoteDict, emoteBarData} = this.props
        let { xPos, yPos, showMenu } = emoteBarData
        let backgroundColor = '#33a4ff'
        let emotePaths = [
            CosmeticsData.emotes.find(emote => emote.id == emoteDict[0]),
            CosmeticsData.emotes.find(emote => emote.id == emoteDict[1]),
            CosmeticsData.emotes.find(emote => emote.id == emoteDict[2]),
            CosmeticsData.emotes.find(emote => emote.id == emoteDict[3])
        ]
        const menuItems = emotePaths.map((emote,index) => (
            <MenuItem
                key={index}
                onItemClick={(e) => {
                    updateEmoteBarCenter(false)
                    playEmote(player, index, emoteDict, phaserObject);
                }}
                onMouseEnter={(e) => {
                    this.setState({ selected: index})
                }}
                onMouseLeave={(e) => {
                    this.setState({ selected: null})
                }}
                data={index}
            >
                <img
                    src={`assets/emotes/${emote.imgPath}`}
                    alt={`Emote ${index + 1}`}
                    style={{ width: '100%' }} // Adjust the width as needed
                />
            </MenuItem>
        ));
        return <Menu
                            centerX={xPos}
                            centerY={yPos}
                            innerRadius={75}
                            outerRadius={150}
                            show={showMenu}
                            animation={['fade']}
                        >
                            {menuItems}
                        </Menu>
    }
}

function mapStateToProps(state) {
    return {
        emoteDict: state.stuff.emoteDict,
        emoteBarData: state.stuff.emoteBarData
    }
}

export default connect(mapStateToProps)(EmoteBar)
