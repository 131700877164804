"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.topWear = void 0;
const drops_1 = require("../../../../drops");
exports.topWear = [{
        id: 1,
        name: '',
        refName: null,
        rarity: 'common',
        stats: {},
        imgPath: 'empty-icon-3.png',
    },
    {
        id: 2,
        name: 'Wizard Hat',
        refName: 'top_wizard_uncommon.png',
        rarity: 'uncommon',
        stats: {
            damage: 1
        },
        imgPath: 'pointy-hat.png',
    },
    {
        id: 3,
        name: 'Rogue Hood',
        refName: 'top_rogue_uncommon.png',
        rarity: 'uncommon',
        stats: {
            agility: 1
        },
        imgPath: 'rogue-hood.png',
    },
    {
        id: 4,
        name: 'Knight Helm',
        refName: 'top_knight_uncommon.png',
        rarity: 'uncommon',
        stats: {
            health: 1,
        },
        imgPath: 'knight-helm.png',
    },
    {
        id: 5,
        name: 'Wizard Hat',
        refName: 'top_wizard_rare.png',
        rarity: 'rare',
        stats: {
            damage: 2,
            agility: 1,
        },
        imgPath: 'pointy-hat.png',
    },
    {
        id: 6,
        name: 'Rogue Hood',
        refName: 'top_rogue_rare.png',
        rarity: 'rare',
        stats: {
            agility: 2,
            speed: 1,
        },
        imgPath: 'rogue-hood.png',
    },
    {
        id: 7,
        name: 'Knight Helm',
        refName: 'top_knight_rare.png',
        rarity: 'rare',
        stats: {
            health: 2,
            damage: 1,
        },
        imgPath: 'knight-helm.png',
    },
    {
        id: 8,
        name: 'Wizard Hat',
        refName: 'top_wizard_epic.png',
        rarity: 'epic',
        stats: {
            damage: 2,
            agility: 1,
        },
        imgPath: 'pointy-hat.png',
    },
    {
        id: 9,
        name: 'Rogue Hood',
        refName: 'top_rogue_epic.png',
        rarity: 'epic',
        stats: {
            agility: 2,
            speed: 2,
        },
        imgPath: 'rogue-hood.png',
    },
    {
        id: 10,
        name: 'Knight Helm',
        refName: 'top_knight_epic.png',
        rarity: 'epic',
        stats: {
            health: 2,
            damage: 2,
        },
        imgPath: 'knight-helm.png',
    },
    {
        id: 11,
        name: 'Wizard Hat',
        refName: 'top_wizard_legendary.png',
        rarity: 'legendary',
        stats: {
            damage: 2,
            agility: 2,
        },
        imgPath: 'pointy-hat.png',
    },
    {
        id: 12,
        name: 'Rogue Hood',
        refName: 'top_rogue_legendary.png',
        rarity: 'legendary',
        stats: {
            agility: 2,
            speed: 3,
        },
        imgPath: 'rogue-hood.png',
    },
    {
        id: 13,
        name: 'Knight Helm',
        refName: 'top_knight_legendary.png',
        rarity: 'legendary',
        stats: {
            health: 2,
            damage: 3,
        },
        imgPath: 'knight-helm.png',
    },
    {
        id: 14,
        name: 'Slime Helm',
        refName: 'top_slime_rare.png',
        rarity: 'rare',
        stats: {
            health: 1,
            speed: 1,
            damage: 1,
            agility: 1
        },
        imgPath: 'slime-helmet.png',
    },
    {
        id: 16,
        name: 'Slime Helm',
        refName: 'top_slime_legendary.png',
        rarity: 'legendary',
        stats: {
            health: 2,
            speed: 1,
            damage: 1,
            agility: 1,
        },
        imgPath: 'slime-helmet.png',
    },
    {
        id: 18,
        name: 'Ice Helm',
        refName: 'top_ice_legendary.png',
        rarity: 'legendary',
        stats: {
            health: 5,
        },
        imgPath: 'icon_ice_armour_helmet.png',
    },
    {
        id: 19,
        name: 'Crab Helmet',
        refName: 'top_crab.png',
        rarity: 'rare',
        stats: {
            damage: 2,
            agility: 1,
        },
        traits: {
            spiky: 0.1
        },
        imgPath: 'icon_crab_armour_helmet.png',
    },
    {
        id: 20,
        name: 'Piranha Helmet',
        refName: 'top_piranha.png',
        rarity: 'epic',
        stats: {
            damage: 2,
            agility: 2,
        },
        traits: {
            swimming: 0.35
        },
        imgPath: 'icon_piranha_helmet.png',
    },
    {
        id: 101,
        name: 'T1 Leather Cowl',
        rarity: 'common',
        stats: {
            defense: 2
        },
        refName: 'top_rogue_uncommon.png',
        imgPath: 'rogue-hood.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t1.customCircleColor
    },
    {
        id: 102,
        name: 'T2 Leather Cowl',
        rarity: 'uncommon',
        stats: {
            defense: 3
        },
        refName: 'top_rogue_uncommon.png',
        imgPath: 'rogue-hood.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t2.customCircleColor
    },
    {
        id: 103,
        name: 'T3 Leather Cowl',
        rarity: 'uncommon',
        stats: {
            defense: 3
        },
        refName: 'top_rogue_uncommon.png',
        imgPath: 'rogue-hood.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t3.customCircleColor
    },
    {
        id: 104,
        name: 'T4 Leather Cowl',
        rarity: 'uncommon',
        stats: {
            defense: 4
        },
        refName: 'top_rogue_uncommon.png',
        imgPath: 'rogue-hood.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t4.customCircleColor
    },
    {
        id: 105,
        name: 'T5 Leather Cowl',
        rarity: 'rare',
        stats: {
            defense: 4,
            agility: 1
        },
        refName: 'top_rogue_rare.png',
        imgPath: 'rogue-hood.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t5.customCircleColor
    },
    {
        id: 106,
        name: 'T6 Leather Cowl',
        rarity: 'rare',
        stats: {
            defense: 5,
            agility: 1
        },
        refName: 'top_rogue_rare.png',
        imgPath: 'rogue-hood.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t6.customCircleColor
    },
    {
        id: 107,
        name: 'T7 Leather Cowl',
        rarity: 'rare',
        stats: {
            defense: 6,
            agility: 1
        },
        refName: 'top_rogue_rare.png',
        imgPath: 'rogue-hood.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t7.customCircleColor
    },
    {
        id: 108,
        name: 'T8 Leather Cowl',
        rarity: 'rare',
        stats: {
            defense: 6,
            agility: 2
        },
        refName: 'top_rogue_rare.png',
        imgPath: 'rogue-hood.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t8.customCircleColor
    },
    {
        id: 109,
        name: 'T9 Leather Cowl',
        rarity: 'epic',
        stats: {
            defense: 7,
            agility: 2
        },
        refName: 'top_rogue_epic.png',
        imgPath: 'rogue-hood.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t9.customCircleColor
    },
    {
        id: 110,
        name: 'T10 Leather Cowl',
        rarity: 'epic',
        stats: {
            defense: 8,
            agility: 2
        },
        refName: 'top_rogue_epic.png',
        imgPath: 'rogue-hood.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t10.customCircleColor
    },
    {
        id: 111,
        name: 'T11 Leather Cowl',
        rarity: 'epic',
        stats: {
            defense: 9,
            agility: 2
        },
        refName: 'top_rogue_epic.png',
        imgPath: 'rogue-hood.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t11.customCircleColor
    },
    {
        id: 112,
        name: 'T12 Leather Cowl',
        rarity: 'legendary',
        stats: {
            defense: 10,
            agility: 2
        },
        refName: 'top_rogue_epic.png',
        imgPath: 'rogue-hood.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t12.customCircleColor
    },
    {
        id: 113,
        name: 'T13 Leather Cowl',
        rarity: 'legendary',
        stats: {
            defense: 11,
            agility: 3
        },
        refName: 'top_rogue_epic.png',
        imgPath: 'rogue-hood.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t13.customCircleColor
    },
    {
        id: 114,
        name: 'T14 Leather Cowl',
        rarity: 'legendary',
        stats: {
            defense: 12,
            agility: 3
        },
        refName: 'top_rogue_epic.png',
        imgPath: 'rogue-hood.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t14.customCircleColor
    },
    {
        id: 115,
        name: 'T15 Leather Cowl',
        rarity: 'legendary',
        stats: {
            defense: 13,
            agility: 3
        },
        refName: 'top_rogue_epic.png',
        imgPath: 'rogue-hood.png',
        gearType: 'leatherArmor',
        customCircleColor: drops_1.gearDropColors.t15.customCircleColor
    },
    {
        id: 201,
        name: 'T1 Wizard Hat',
        refName: 'top_wizard_uncommon.png',
        rarity: 'common',
        stats: {
            defense: 1
        },
        imgPath: 'pointy-hat.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t1.customCircleColor
    },
    {
        id: 202,
        name: 'T2 Wizard Hat',
        refName: 'top_wizard_uncommon.png',
        rarity: 'uncommon',
        stats: {
            defense: 1,
            spirit: 1
        },
        imgPath: 'pointy-hat.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t2.customCircleColor
    },
    {
        id: 203,
        name: 'T3 Wizard Hat',
        refName: 'top_wizard_uncommon.png',
        rarity: 'uncommon',
        stats: {
            defense: 1,
            spirit: 1,
            mana: 10
        },
        imgPath: 'pointy-hat.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t3.customCircleColor
    },
    {
        id: 204,
        name: 'T4 Wizard Hat',
        refName: 'top_wizard_uncommon.png',
        rarity: 'uncommon',
        stats: {
            defense: 1,
            spirit: 1,
            mana: 15
        },
        imgPath: 'pointy-hat.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t4.customCircleColor
    },
    {
        id: 205,
        name: 'T5 Wizard Hat',
        refName: 'top_wizard_rare.png',
        rarity: 'rare',
        stats: {
            defense: 2,
            spirit: 1,
            mana: 15
        },
        imgPath: 'pointy-hat.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t5.customCircleColor
    },
    {
        id: 206,
        name: 'T6 Wizard Hat',
        refName: 'top_wizard_rare.png',
        rarity: 'rare',
        stats: {
            defense: 3,
            spirit: 1,
            mana: 15
        },
        imgPath: 'pointy-hat.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t6.customCircleColor
    },
    {
        id: 207,
        name: 'T7 Wizard Hat',
        refName: 'top_wizard_rare.png',
        rarity: 'rare',
        stats: {
            defense: 4,
            spirit: 1,
            mana: 15
        },
        imgPath: 'pointy-hat.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t7.customCircleColor
    },
    {
        id: 208,
        name: 'T8 Wizard Hat',
        refName: 'top_wizard_rare.png',
        rarity: 'rare',
        stats: {
            defense: 4,
            spirit: 1,
            mana: 20
        },
        imgPath: 'pointy-hat.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t8.customCircleColor
    },
    {
        id: 209,
        name: 'T9 Wizard Hat',
        refName: 'top_wizard_epic.png',
        rarity: 'epic',
        stats: {
            defense: 4,
            spirit: 2,
            mana: 20
        },
        imgPath: 'pointy-hat.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t9.customCircleColor
    },
    {
        id: 210,
        name: 'T10 Wizard Hat',
        refName: 'top_wizard_epic.png',
        rarity: 'epic',
        stats: {
            defense: 4,
            spirit: 2,
            mana: 25
        },
        imgPath: 'pointy-hat.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t10.customCircleColor
    },
    {
        id: 211,
        name: 'T11 Wizard Hat',
        refName: 'top_wizard_epic.png',
        rarity: 'epic',
        stats: {
            defense: 4,
            spirit: 3,
            mana: 25
        },
        imgPath: 'pointy-hat.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t11.customCircleColor
    },
    {
        id: 212,
        name: 'T12 Wizard Hat',
        refName: 'top_wizard_epic.png',
        rarity: 'legendary',
        stats: {
            defense: 5,
            spirit: 3,
            mana: 25
        },
        imgPath: 'pointy-hat.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t12.customCircleColor
    },
    {
        id: 213,
        name: 'T13 Wizard Hat',
        refName: 'top_wizard_epic.png',
        rarity: 'legendary',
        stats: {
            defense: 6,
            spirit: 3,
            mana: 25
        },
        imgPath: 'pointy-hat.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t13.customCircleColor
    },
    {
        id: 214,
        name: 'T14 Wizard Hat',
        refName: 'top_wizard_epic.png',
        rarity: 'legendary',
        stats: {
            defense: 7,
            spirit: 3,
            mana: 30
        },
        imgPath: 'pointy-hat.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t14.customCircleColor
    },
    {
        id: 215,
        name: 'T15 Wizard Hat',
        refName: 'top_wizard_epic.png',
        rarity: 'legendary',
        stats: {
            defense: 8,
            spirit: 4,
            mana: 35
        },
        imgPath: 'pointy-hat.png',
        gearType: 'clothArmor',
        customCircleColor: drops_1.gearDropColors.t15.customCircleColor
    },
    {
        id: 301,
        name: 'T1 Knight Helmet',
        refName: 'top_knight_uncommon.png',
        rarity: 'common',
        stats: {
            defense: 2
        },
        imgPath: 'knight-helm.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t1.customCircleColor
    },
    {
        id: 302,
        name: 'T2 Knight Helmet',
        refName: 'top_knight_uncommon.png',
        rarity: 'uncommon',
        stats: {
            defense: 3
        },
        imgPath: 'knight-helm.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t2.customCircleColor
    },
    {
        id: 303,
        name: 'T3 Knight Helmet',
        refName: 'top_knight_uncommon.png',
        rarity: 'uncommon',
        stats: {
            defense: 4
        },
        imgPath: 'knight-helm.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t3.customCircleColor
    },
    {
        id: 304,
        name: 'T4 Knight Helmet',
        refName: 'top_knight_uncommon.png',
        rarity: 'uncommon',
        stats: {
            defense: 4
        },
        imgPath: 'knight-helm.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t4.customCircleColor
    },
    {
        id: 305,
        name: 'T5 Knight Helmet',
        refName: 'top_knight_rare.png',
        rarity: 'rare',
        stats: {
            defense: 4,
            health: 5
        },
        imgPath: 'knight-helm.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t5.customCircleColor
    },
    {
        id: 306,
        name: 'T6 Knight Helmet',
        refName: 'top_knight_rare.png',
        rarity: 'rare',
        stats: {
            defense: 5,
            health: 5
        },
        imgPath: 'knight-helm.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t6.customCircleColor
    },
    {
        id: 307,
        name: 'T7 Knight Helmet',
        refName: 'top_knight_rare.png',
        rarity: 'rare',
        stats: {
            defense: 6,
            health: 5
        },
        imgPath: 'knight-helm.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t7.customCircleColor
    },
    {
        id: 308,
        name: 'T8 Knight Helmet',
        refName: 'top_knight_rare.png',
        rarity: 'rare',
        stats: {
            defense: 8,
            health: 5
        },
        imgPath: 'knight-helm.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t8.customCircleColor
    },
    {
        id: 309,
        name: 'T9 Knight Helmet',
        refName: 'top_knight_epic.png',
        rarity: 'epic',
        stats: {
            defense: 8,
            health: 10
        },
        imgPath: 'knight-helm.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t9.customCircleColor
    },
    {
        id: 310,
        name: 'T10 Knight Helmet',
        refName: 'top_knight_epic.png',
        rarity: 'epic',
        stats: {
            defense: 9,
            health: 10
        },
        imgPath: 'knight-helm.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t10.customCircleColor
    },
    {
        id: 311,
        name: 'T11 Knight Helmet',
        refName: 'top_knight_epic.png',
        rarity: 'epic',
        stats: {
            defense: 10,
            health: 10
        },
        imgPath: 'knight-helm.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t11.customCircleColor
    },
    {
        id: 312,
        name: 'T12 Knight Helmet',
        refName: 'top_knight_epic.png',
        rarity: 'legendary',
        stats: {
            defense: 11,
            health: 10
        },
        imgPath: 'knight-helm.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t12.customCircleColor
    },
    {
        id: 313,
        name: 'T13 Knight Helmet',
        refName: 'top_knight_epic.png',
        rarity: 'legendary',
        stats: {
            defense: 12,
            health: 10
        },
        imgPath: 'knight-helm.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t13.customCircleColor
    },
    {
        id: 314,
        name: 'T14 Knight Helmet',
        refName: 'top_knight_epic.png',
        rarity: 'legendary',
        stats: {
            defense: 13,
            health: 10
        },
        imgPath: 'knight-helm.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t14.customCircleColor
    },
    {
        id: 315,
        name: 'T15 Knight Helmet',
        refName: 'top_knight_epic.png',
        rarity: 'legendary',
        stats: {
            defense: 14,
            health: 10
        },
        imgPath: 'knight-helm.png',
        broad: true,
        gearType: 'heavyArmor',
        customCircleColor: drops_1.gearDropColors.t15.customCircleColor
    },
];
