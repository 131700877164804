"use strict";
const ColorTable = {
    'element': {
        'light': 0xFFFF00,
        'fire': 0xC63232,
        'water': 0x76CADD,
        'air': 0x9098A5,
        'earth': 0x9B5B2F,
        'dark': 0x362D44,
        'none': 0xC0C0C0,
    },
    elementHex: {
        'light': '#FFFF00',
        'fire': '#C63232',
        'water': '#76CADD',
        'air': '#9098A5',
        'earth': '#9B5B2F',
        'dark': '#362D44',
        'none': '#C0C0C0',
    },
    rarityInfo: {
        none: {
            rarityColor: '#C0C0C0',
        },
        common: {
            rarityColor: '#70767F',
            pickupRarity: 'Common',
            pickupColor: '#808080',
            popoverBackgroundColor: 'grey',
            starCount: 1,
            innerBorder: 'rgb(79,79,79,0.8)',
            textColor: '#C7CED3',
            loadoutBorder: '#808080'
        },
        uncommon: {
            rarityColor: '#2FAD25',
            pickupRarity: 'Uncommon',
            pickupColor: '#00FF00',
            popoverBackgroundColor: '#32CD32',
            starCount: 2,
            innerBorder: 'rgb(20,101,3,0.8)',
            textColor: '#00FF00',
            loadoutBorder: '#2FAD25'
        },
        rare: {
            rarityColor: '#1A82FF',
            pickupRarity: 'Rare',
            pickupColor: '#0000FF',
            popoverBackgroundColor: '#0080ff',
            starCount: 3,
            innerBorder: 'rgb(10,108,196,0.8)',
            textColor: '#00368C',
            loadoutBorder: '#1A82FF'
        },
        epic: {
            rarityColor: '#8948EF',
            pickupRarity: 'Epic',
            pickupColor: '#000080',
            popoverBackgroundColor: 'purple',
            starCount: 4,
            innerBorder: 'rgb(79,59,127,0.8)',
            textColor: '#471FB5',
            loadoutBorder: '#8948EF'
        },
        legendary: {
            rarityColor: '#F47C40',
            pickupRarity: 'Legendary',
            pickupColor: '#FF8C00',
            popoverBackgroundColor: 'orange',
            starCount: 5,
            innerBorder: 'rgb(173,75,64,0.8)',
            textColor: '#913B14',
            loadoutBorder: '#F47C40'
        },
    },
    'rarity': {
        'common': 0x70767F,
        'uncommon': 0x2FAD25,
        'rare': 0x1A82FF,
        'epic': 0x8948EF,
        'legendary': 0xF47C40,
    },
    'rarityText': {
        'common': 'white',
        'uncommon': 'white',
        'rare': 'white',
        'epic': 'white',
        'legendary': 'white',
    },
    'rarityBackground': {
        'common': '#808080',
        'uncommon': '#00FF00',
        'rare': '#0000FF',
        'epic': '#000080',
        'legendary': '#FF8C00',
    }
};
module.exports = ColorTable;
