import React, { Component } from 'react'
import { connect } from 'react-redux'
import SpellContainer from '../SpellContainer.js'
import GearContainer from '../GearContainer.js'
import EXPBarMin from '../EXPBarMin.js'
import PickupBox from '../PickupBox.js'
import Leaderboard from '../Leaderboard.js'
import ChatPanel from '../ChatPanel'
import Stats from '../Stats.js'
import OverleveledIndicator from '../OverleveledIndicator.js'
import ResourceContainer from '../ResourceContainer.js'
import { Grid } from '@mui/material'
import KillCounter from '../KillCounter.js'
import '../ui.css'
import EmoteBar from '../EmoteBar.js'
import ChatBox from '../ChatBox.js'

import PickupButton from '../PickupButton.js'
import { calculateScaleFactor } from '../../game'

import IconMute from '../icons/IconMute.js'
import IconFullScreen from '../icons/IconFullScreen'
import IconDiscord from '../icons/IconDiscord'

import MiddleLowerText from "../MiddleLowerText";
import IngameShop from "../IngameShop";
import IconIngameMenu from "../icons/IconIngameMenu";
import IconEmotes from "../icons/IconEmotes";
import ManaHealthBar from "../ManaHealthBar";
import IngameSettings from "../settings/IngameSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import ColorChangingIcon from "../ColorChangingIcon";
import IconSettingsIngame from "../icons/IconSettingsIngame";
import {toggleIngameSettingsMenu} from "../../actions/actions";
import GenericBar from '../GenericBar.js'

class InGameUI extends Component {
    constructor() {
        super()
        this.state = {
        }
    }
    componentDidMount() {}
    render() {
        let {ingameShopActive, mute, fullscreen, gameFocusOverlay,  width, height,  chatboxActive, referrer,
             genericBar1Name, genericBar1Value, genericBar1Settings,
             genericBar2Name, genericBar2Value, genericBar2Settings,
             genericBar3Name, genericBar3Value, genericBar3Settings,
             genericBar4Name, genericBar4Value, genericBar4Settings,
            
            } = this.props

        let discordMsg = 'Join us on Discord!'
        let scaleFactor = 1
        let minimapScaleFactor = 1
        scaleFactor = calculateScaleFactor(window.innerWidth, window.innerHeight)
        minimapScaleFactor = scaleFactor
        scaleFactor += 0.13
        let spellScaleFactor = scaleFactor
        if (width < 1050) {
            spellScaleFactor *= 1.75
        } else if (width < 1200) {
            spellScaleFactor *= 1.5
        } else if (width < 1400) {
            spellScaleFactor *= 1.25
        }
        let activeGenericSlots = 0
        if (genericBar1Name !== '') {
            activeGenericSlots++
        }
        if (genericBar2Name !== '') {
            activeGenericSlots++
        }
        if (genericBar3Name !== '') {
            activeGenericSlots++
        }
        if (genericBar4Name !== '') {
            activeGenericSlots++
        }
        return (
                    <>
                    {gameFocusOverlay && 
                              <div
                              style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100vw',
                                  height: '100vh',
                                  background: `rgba(0, 0, 0, 0.8)`,
                                  zIndex: 1,
                                  pointerEvents: 'none',
                              }}
                          />
                    }
                   
                        <IngameSettings />
                        <EmoteBar />
                        {chatboxActive &&
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '60%',
                                    opacity: 0.9,
                                    left: '50%',
                                    // pointerEvents: 'none',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 100
                                }}
                            >
                                <ChatBox />
                            </div>
                        }
                        {/*<div style={{*/}
                        {/*    position: 'absolute',*/}
                        {/*    opacity: 0.9,*/}
                        {/*    bottom: '0%',*/}
                        {/*    transform: `scale(${scaleFactor * 0.5})`,*/}
                        {/*    transformOrigin: 'bottom left',*/}
                        {/*    zIndex: 999,*/}
                        {/*    marginLeft: '1%'*/}
                        {/*}}>*/}
                        {/*    <div*/}
                        {/*        style={{ pointerEvents: 'auto' }}*/}
                        {/*    >*/}
                        {/*        <IconDiscord />*/}
                        {/*        <IconFullScreen fullScreen={fullscreen} />*/}
                        {/*        <IconMute mute={mute} />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div style={{
                            position: 'absolute',
                            left: 0,
                            opacity: 0.9,
                            transform: `scale(${scaleFactor})`,
                            transformOrigin: 'top left',
                            maxWidth: '25%',
                            minWidth: '300px',
                            height: '15%'
                        }}>
                            <ChatPanel />
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                opacity: 0.8,
                                // pointerEvents: 'none',
                                bottom: '28%',
                                left: '80%',
                                width: '100%',
                            }}
                        >
                            <PickupButton />
                        </div>
                        {/*<div style={{*/}
                        {/*  position: 'absolute',*/}
                        {/*  top: '0.5%',*/}
                        {/*  opacity: 0.9,*/}
                        {/*  left: '50%',*/}
                        {/*  pointerEvents: 'none',*/}
                        {/*  transform: 'translate(-50%, 0%)',*/}
                        {/*}}>*/}
                        {/*  <DangerIndicator></DangerIndicator>*/}
                        {/*  /!* <EventPointer></EventPointer> *!/*/}
                        {/*</div>*/}
                        <div style={{
                            position: 'absolute',
                            top: '20%',
                            opacity: 0.8,
                            left: '50%',
                            pointerEvents: 'none',
                            transform: 'translate(-50%, 0%)',
                        }}>
                            <OverleveledIndicator></OverleveledIndicator>
                        </div>
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            opacity: 0.9,
                            // maxWidth: '160px',
                            marginRight: '0.5%',
                            marginTop: '0.5%',
                            minWidth: '120px',
                            pointerEvents: 'none',
                            maxHeight: '8vh',

                            transform: `scale(${scaleFactor})`,
                            transformOrigin: 'top right'
                        }}>
                            <div
                                style={{ pointerEvents: 'auto',
                                }}
                            >
                                <IconDiscord />
                                <IconMute mute={mute} />
                                <IconSettingsIngame buttonFunction={toggleIngameSettingsMenu}/>
                                { referrer !== 'crazygames' && <IconFullScreen fullScreen={fullscreen}/> }
                            </div>
                        </div>
                        <div style={{
                            position: 'absolute',
                            top: '8vh',
                            right: 0,
                            opacity: 0.9,
                            maxWidth: '160px',
                            marginRight: '0.5%',
                            marginTop: '0.5%',
                            minWidth: '120px',
                            pointerEvents: 'none',

                            transform: `scale(${scaleFactor})`,
                            transformOrigin: 'top right'
                        }}>
                            <Leaderboard style={{ minWidth: '100%', height: '100%', maxWidth: '180px' }}></Leaderboard>
                            {/*<KillCounter style={{ height: '100%', maxWidth: '140px' }}></KillCounter>*/}
                            <GearContainer style={{ maxWidth: '140px' }}></GearContainer>
                        </div>
                        <div style={{
                            position: 'absolute',
                            left: 0,
                            opacity: 0.9,
                            pointerEvents: 'none',
                            top: '42%',
                            transform: 'translate(0%, -50%)',
                        }}>
                            <div
                                style={{
                                    transform: `scale(${scaleFactor})`,
                                    transformOrigin: 'left'
                                }}
                            >
                                <EXPBarMin></EXPBarMin>
                                {genericBar1Name !== '' && <GenericBar id={1} name={genericBar1Name} value={genericBar1Value} settings={genericBar1Settings}/> }
                                {genericBar2Name !== '' && <GenericBar id={2} name={genericBar2Name} value={genericBar2Value} settings={genericBar2Settings} /> }
                                {genericBar3Name !== '' && <GenericBar id={3} name={genericBar3Name} value={genericBar3Value} settings={genericBar3Settings}/> }
                                {genericBar4Name !== '' && <GenericBar id={4} name={genericBar4Name} value={genericBar4Value} settings={genericBar4Settings}/> }
                                <Stats activeGenericSlots={activeGenericSlots}></Stats>
            
                            </div>
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                opacity: 0.9,
                                pointerEvents: 'none',
                                bottom: `${210 * minimapScaleFactor}px`,
                                transform: `scale(0.8)`,
                                transformOrigin: 'bottom left',
                                // bottom: `${160 * minimapScaleFactor}px`,
                                width: '100%',
                                marginLeft: '0.25%'
                            }}>
                            <IconIngameMenu
                                buttonSize='small' />
                            <IconEmotes
                                buttonSize='small' />
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                opacity: 0.9,
                                pointerEvents: 'none',
                                bottom: '0%',
                                width: '100%',
                                marginLeft: '0.25%'
                            }}>
                            <div style={{
                            }}>
                                <img style={{
                                    pointerEvents: 'none',
                                    width: '210px',
                                    height: '210px',
                                    transform: `scale(${minimapScaleFactor})`,
                                    transformOrigin: 'bottom left'
                                }} src='assets/other/map_bg.png' />
                            </div>
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                opacity: 0.8,
                                pointerEvents: 'none',
                                bottom: '35%',
                                width: '100%',
                            }}>
                            <Grid container style={{ height: '100%', width: '100%', maxHeight: '100%',  pointerEvents: 'none', }}>
                                <Grid item xs={12} style={{
                                    transform: 'translateX(-50%)',
                                    marginLeft: '50%',
                                    marginBottom: '1%',
                                    pointerEvents: 'none'
                                }}>
                                    {ingameShopActive &&
                                        <div style={{
                                            zIndex: 999,
                                            pointerEvents: 'none',
                                        }}>
                                            <IngameShop style={{
                                                pointerEvents: 'none',
                                                // pointerEvents: 'all'
                                            }}></IngameShop>
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                opacity: 0.8,
                                pointerEvents: 'none',
                                bottom: '35%',
                                width: '100%',
                                zIndex: 99,
                            }}>
                            <Grid container style={{ height: '100%', width: '100%', maxHeight: '100%' , pointerEvents: 'none'}}>
                                <Grid item xs={12} style={{
                                    transform: 'translateX(-50%)',
                                    marginLeft: '50%',
                                    minWidth: '500px',
                                    maxWidth: '700px',
                                    marginBottom: '1%'
                                }}>
                        
                                    <PickupBox ></PickupBox>
                                    {/* <MiddleLowerText */}
                    
                     
                                </Grid>
                            </Grid>
                        </div>
                         <div
                            style={{
                                position: 'absolute',
                                opacity: 0.8,
                                pointerEvents: 'none',
                                bottom: '15%',
                                width: '100%',
                                zIndex: 999,
                            }}>
                            <Grid container style={{ height: '100%', width: '100%', maxHeight: '100%' , pointerEvents: 'none'}}>
                                <Grid item xs={12} style={{
                                    transform: 'translateX(-50%)',
                                    marginLeft: '50%',
                                    minWidth: '500px',
                                    maxWidth: '700px',
                                    marginBottom: '1%',
                                    zIndex: 999,
                                }}>
                        
                                    <MiddleLowerText/>
                                </Grid>
                            </Grid>
                        </div> 
                        <div
                            style={{
                                position: 'absolute',
                                opacity: 0.9,
                                pointerEvents: 'none',
                                bottom: '0%',
                                width: '100%',
                                marginBottom: '0.5%',
                                // transform: `scale(${scaleFactor})`,
                                transform: `scale(${spellScaleFactor})`,
                                transformOrigin: 'bottom right',
                                zIndex: 2,
                            }}>
                            <Grid container style={{
                                height: '100%', width: '100%',
                            }}>
                                <Grid item xs={12} 
                                       alignItems="center"
                                       justifyContent="center"
                                style={{
                                    transform: 'translateX(-50%)',
                                    marginLeft: '50%',
                                    minWidth: '150px',
                                    maxWidth: '350px',
                                    marginBottom: '1%',

                                }}>
                                </Grid>
                                {/* <KillCounter style={{ height: '100%', maxWidth: '140px' }}></KillCounter> */}
                                {!gameFocusOverlay && <ResourceContainer style={{ height: '100%', width: '100%',   }}></ResourceContainer> }
                                <SpellContainer style={{ width: '100%', height: '100%' }}></SpellContainer>
                            </Grid>
                        </div>
                    </>
        )
    }
}

function mapStateToProps(state) {
    return {
        gameFocusOverlay: state.stuff.gameFocusOverlay,
        mute: state.stuff.mute,
        fullscreen: state.stuff.fullscreen,
        adblock: state.stuff.adblock,
        width: state.stuff.width,
        height: state.stuff.height,
        guestAccount: state.stuff.guestAccount,
        emoteDict: state.stuff.emoteDict,
        chatboxActive: state.stuff.chatboxActive,
        ingameShopActive: state.stuff.ingameShopActive,
        referrer: state.stuff.referrer,
        genericBar1Name: state.stuff.genericBar1Name,
        genericBar1Value: state.stuff.genericBar1Value,
        genericBar1Settings: state.stuff.genericBar1Settings,
        genericBar2Name: state.stuff.genericBar2Name,
        genericBar2Value: state.stuff.genericBar2Value,
        genericBar2Settings: state.stuff.genericBar2Settings,
        genericBar3Name: state.stuff.genericBar3Name,
        genericBar3Value: state.stuff.genericBar3Value,
        genericBar3Settings: state.stuff.genericBar3Settings,
        genericBar4Name: state.stuff.genericBar4Name,
        genericBar4Value: state.stuff.genericBar4Value,
        genericBar4Settings: state.stuff.genericBar4Settings,
    }
}

export default connect(
    mapStateToProps,
)(InGameUI)