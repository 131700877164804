import React, { Component } from 'react'
import { Grid, Box } from '@mui/material'
import { Paper } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { connect } from 'react-redux'
import MageClashTitle from './MageClashTitle.js'
import Typography from '@mui/material/Typography'

import Constants from '../../shared/constants'
import {styled} from "@mui/material/styles";
import {colorPalette} from "./uiStyles";

const StyledLinearProgress = styled(LinearProgress)({
    backgroundColor: '#362D44', // Color for the background
    '& .MuiLinearProgress-bar': {
        backgroundColor: '#0A83D4', // Color for the bar
    },
});

class LoadingScreen extends Component {
    constructor() {
        super()
        this.state = {

        }
    }

    render() {
        let { loadProgress, titleLoaded, currentGame , singleGame } = this.props
        let imgPath
        let hideTitle
        if (titleLoaded) {
            imgPath= 'assets/loading_image.jpg'
            let gameInfo
            let gameToSearch = currentGame ? currentGame : singleGame

            if (!gameToSearch) {
                gameToSearch = 'mageclash'
            }

            if (gameToSearch) {
                gameInfo = Constants.GAME_MODE_MAP.find((game) => {
                    return  game.gameName === gameToSearch
                })
                if (gameInfo && gameInfo.hideTitleWhenLoading) {
                    hideTitle = true
                }
            }
            imgPath = 'assets/games/' + gameToSearch + '/loading_image.jpg'
        }

        return <>
            {
            titleLoaded &&  <img src={imgPath}
                style={{
                    // objectFit: 'fill',
                    objectFit: 'cover',
                    height: '100%', width: '100%',
                    minHeight: '100%', minWidth: '100%',
                    position: 'absolute'
                }}

            />
            }

            <div
                style={{
                    position: 'absolute',
                    top: '0%',
                    opacity: 1,
                    height: '100%',
                    width: '100%'
                }}>
                <Grid container align='center' justifyContent="center" alignItems="center">
                    <Grid xs={12} style={{ marginTop: '1.5%' }} >
                        <Grid item
                            xs={2}
                            sm={3}
                        >
                            {/* <SignInButton /> */}
                        </Grid>
                        <Grid item

                            xs={8}
                            sm={6}
                        >
                            {
                                titleLoaded && !hideTitle && <MageClashTitle
                                    align="center"
                                />
                            }
                        </Grid>
                        <Grid item
                            style={{
                                pointerEvents: 'none'
                            }}
                            xs={2}
                            sm={3}
                        ></Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} style={{ minHeight: '60%', maxHeight: '60%' }}></Grid>
                <Grid xs={12}>
                    {/* <Paper
                        style={{
                            marginLeft: '20%', marginRight: '20%',
                            borderWidth: '3px', borderStyle: 'solid', borderColor: 'black',
                            background: colorPalette.primary
                        }}
                    >
                        <Grid xs={12}>

                            <div style={{ textAlign: 'center', marginTop: '2%' }}> <b style={{
                                // fontSize: '1.5em'
                                fontSize: '2.5vw'

                            }}> LOADING... </b> </div>
                        </Grid>
                        <Grid container align='center' justify="center" alignItems="center" direction="column"
                        >
                            <LinearProgress style={{
                                // paddingLeft: '20%', paddingRight: '20%',
                                width: '90%',
                                height: '50px',
                                margin: '2%',
                                borderRadius: '10px',
                                marginBottom: '2.5%',
                                borderWidth: '3px', borderStyle: 'solid', borderColor: 'black'

                            }} variant="determinate" value={loadProgress} />
                        </Grid>
                    </Paper> */}
                </Grid>

            </div>
            <Grid xs={12}
                style={{
                    position: 'absolute',
                    top: '100%',
                    // top: '0%',
                    left: '100%',
                    transform: 'translate(-100%, -100%)',
                    opacity: 1,
                    // height: '100%',
                    width: '100%',
                    paddingBottom: '1%'
                }}>
                <Paper
                    style={{
                        marginLeft: '20%', marginRight: '20%',
                        borderWidth: '3px', borderStyle: 'solid', borderColor: 'black',
                        background: colorPalette.primary
                    }}
                >
                    <Grid xs={12}>

                        <div style={{ textAlign: 'center', marginTop: '2%' }}> <b style={{
                            // fontSize: '1.5em'
                            fontSize: '2.5vw'

                        }}> LOADING... </b> </div>
                    </Grid>
                    <Grid container align='center' justifyContent="center" alignItems="center" direction="column"
                    >
                        <StyledLinearProgress 
                        color="primary"
                        style={{
                            
                            // paddingLeft: '20%', paddingRight: '20%',
                            width: '90%',
                            height: '50px',
                            margin: '2%',
                            borderRadius: '6px',
                            marginBottom: '2.5%',
                            borderWidth: '3px', borderStyle: 'solid', borderColor: 'black',
                            boxShadow: '0 0 0 2px #FCD5A2',

                        }} 
                        variant="determinate"
                        //     variant='indeterminate'
                        value={loadProgress}
                        
                        />
                        <Typography variant="body2" color="textSecondary" style={{
                            position: 'absolute',
                            color: 'white',
                            marginTop: '-7px',
                            // top: '50%',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontSize: '25px'

                        }}>{loadProgress}%</Typography>

                    </Grid>
                </Paper>

        </Grid>


        </>;
    }
}

function mapStateToProps(state) {
    return {
        loadProgress: state.stuff.loadProgress,
        currentGame: state.stuff.currentGame,
        titleLoaded: state.stuff.titleLoaded,
        singleGame: state.stuff.singleGame
    }
}

export default connect(
    mapStateToProps,
)(LoadingScreen)
