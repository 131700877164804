export function calculateOffset(width, height, scaleFactor, currentGame, playerDied, trialVictory, adblock, referrer, showLoadingScreen) {
  
    let bottomBannerOffset = 0
// adding makes it go up, subtracting makes it go down
    // bottomBannerOffset -= ((90 * scaleFactor) / height) * 100

    // console.log('bottomBannerOffset', bottomBannerOffset)

    // bottomBannerOffset = 45 * scaleFactor
    bottomBannerOffset = 55 * scaleFactor

    bottomBannerOffset += width / 100 * 1

    // if (width > 1700) {
    //   console.log('width > 1700', scaleFactor)
    //   // bottomBannerOffset -= (width - 1700) / 100 * 5
    // }
    if (width < 1300) {
      // console.log('width < 1300', scaleFactor)
      bottomBannerOffset += (1300 - width) / 100 * 2.5
    
      // let heightChangeVal = 2

      // if (width > 1000) {
      //   heightChangeVal = 4
      // }
      // if (height < 700) {
      //   bottomBannerOffset -= (700 - height) / 100 * heightChangeVal
      // }
      // if (height > 500) {
      //   bottomBannerOffset -= (height - 500) / 100 * heightChangeVal/2
      // }

    }
    // if (height > 600) {
      if (width < 900 && height > 600) {
        bottomBannerOffset += (height - 600 ) / 100 * 1.7
      } 
      else if (width < 1400) {
        let multiplier = 1
      //   if (height < 800) {
      //     multiplier = 2.5
      //   } 
      if (height < 550 
        // && width < 1200
        ) {
        multiplier = 1.5
      }
      //   bottomBannerOffset -= (height - 600) / 100 * (2.5 * multiplier)
        bottomBannerOffset -= (height ) / 100 * (1.1 * multiplier)
      } else {
        // bottomBannerOffset -= (height - 600) / 100 * 1\
                let multiplier = 1
                if (height < 550) {
                  multiplier = 1.5
                }
        // if (height < 800) {
        //   multiplier = 4.5
        // } 
        bottomBannerOffset -= (height ) / 100 * (2.5 * multiplier)
      }
   
    // }
    // if (referrer !== 'crazyGames' || adblock) {
      // if (width > 800 &&  width < 1200) {
      //   bottomBannerOffset *= 0.4
      // } else {
      //   bottomBannerOffset *= 0.6
      // }
      // if (height > 800) {
      //   const heightAfter800 = height - 800
      //   bottomBannerOffset += heightAfter800 / 100 * 5
      // }
    // }
    if (currentGame ) {
      if (width / height  > 2.5) {
        bottomBannerOffset *= 0.8
      } else {
        bottomBannerOffset *= 0.7
      }
    
    }
    if (playerDied || trialVictory) {
      bottomBannerOffset *= 1.5
  
    }
    if (bottomBannerOffset > 90) {
      bottomBannerOffset = 90
    }
    if (bottomBannerOffset < 0) {
      bottomBannerOffset = 0
    }
    if (showLoadingScreen) {
      console.log('showLoadingScreen', showLoadingScreen)
      // bottomBannerOffset = 0
    }
    return bottomBannerOffset

}
export function calculateOffset2(width, height, scaleFactor, currentGame, playerDied, trialVictory, adblock, referrer) {
    let bottomBannerOffset = 0
  // if (height > 400) {
    //   bottomBannerOffset = (Math.min(height, 900) - 400) / 100 * 13
    // }
    // if (height > 900) {
    //   bottomBannerOffset += (Math.min(height, 1200) - 900) / 100 * 5
    // }


    // console.log('scale factor', scaleFactor)
    // console.log('width/height', width/height)
    // console.log('portal page ref', this.portalPageRef)

    // let pixelSize = 450
    // if (currentGame && !playerDied && !trialVictory) {
    //   pixelSize += 75
    //   if (currentGame === 'grix') {
    //     pixelSize += 50
    //   }
    // }
    // if (width < 1200) {
    //   pixelSize += 150
    //   if (currentGame && width < 1000) {
    //     pixelSize += 50
    //     if (adblock || referrer !== 'crazyGames') {
    //       pixelSize += 100
    //     }
    //   }
    
    // }
    // const screenRatio = width / height
    // if (screenRatio > 2.2) {
    //   const diff = screenRatio - 2.2
    //   pixelSize += (100 * (diff * 5))
    // }
    // pixelSize += 100 // cg banner
    // if (adblock || referrer !== 'crazyGames') {
    //   pixelSize += 200
    // }
    // console.log('logging', {
    //   pixelSize: pixelSize,
    //   scaleFactor: scaleFactor,
    //   height: height,
    // })

    // bottomBannerOffset = ((1-(pixelSize * scaleFactor ) / height)) * 100

    // if ((adblock || referrer !== 'crazyGames') && width > 1300 && width < 1900) { 
    //   bottomBannerOffset -= (width - 1300) / 100 * 2
    // }


}