import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import './ui.css'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import {colorPalette, mainBorderProperties} from './uiStyles'
import Constants from '../../shared/constants'
import { calculateScaleFactor } from '../game'

class LinkBar extends Component {
    constructor() {
        super()
        this.state = {
        }
    }
    render() {
        let { hideCrossLinks } = this.props
        let scaleFactor = 1
        let topBarScaleFactor = 1
        let bottomBarFontSize = '10px'
        scaleFactor = calculateScaleFactor(window.innerWidth, window.innerHeight)
        scaleFactor += 0.13
        topBarScaleFactor = scaleFactor
        return <>
            <Grid container justifyContent="flex-end" direction="row" alignItems="center"
                  style={{
                      position: 'absolute',
                      top: '100%',
                      left: '100%',
                      transform: 'translate(-100%, -100%)',
                      opacity: 1,
                      padding: 2,
                  }}>
                <Grid
                    style={{
                        transform: `scale(${topBarScaleFactor})`,
                        transformOrigin: 'bottom right'
                    }}
                >
                    {!hideCrossLinks &&

                        <Box component="span" style={{ paddingRight: '2px' }}>
                            <ButtonBase
                                style={Object.assign({
                                    textAlign: 'center', 'padding': '5px', margin: '5px',
                                    background: 'green',
                                }, mainBorderProperties)}
                                onClick={() => {
                                    window.open('https://iogames.space/')
                                }}
                            >
                                <div style={{ textDecoration: 'none', textAlign: 'center', fontSize: { bottomBarFontSize } }}> IO Games </div>
                            </ButtonBase>
                        </Box>
                    }
                    {!hideCrossLinks &&
                        <Box component="span" style={{ paddingRight: '2px' }}>
                            <ButtonBase

                                style={Object.assign({ textAlign: 'center', 'padding': '5px', margin: '5px', background: colorPalette.primary }, mainBorderProperties)}

                                onClick={() => {
                                    window.open('/partners.html')
                                }}
                            >
                                <div style={{ textDecoration: 'none', textAlign: 'center', fontSize: { bottomBarFontSize } }}> Partners </div>
                            </ButtonBase>
                        </Box>
                    }
                    <Box component="span" style={{ paddingRight: '2px' }}>
                        <ButtonBase

                            style={Object.assign({ textAlign: 'center', 'padding': '5px', margin: '5px', background: colorPalette.primary }, mainBorderProperties)}

                            onClick={() => {
                                window.open('/privacy.html')
                            }}
                        >
                            <div style={{ textDecoration: 'none', textAlign: 'center', fontSize: { bottomBarFontSize } }}> Privacy </div>
                        </ButtonBase>
                    </Box>

                    <Box component="span" style={{ paddingRight: '2px' }}>
                        <ButtonBase

                            style={Object.assign({ textAlign: 'center', 'padding': '5px', margin: '5px', background: colorPalette.primary }, mainBorderProperties)}

                            onClick={() => {
                                window.open('/termsofservice.html')
                            }}
                        >
                            <div style={{ textDecoration: 'none', textAlign: 'center', fontSize: { bottomBarFontSize } }}> Terms </div>
                        </ButtonBase>
                    </Box>
                    <Box component="span" style={{ paddingRight: '2px' }}>
                        <ButtonBase

                            style={Object.assign({ textAlign: 'center', 'padding': '5px', margin: '5px', background: colorPalette.primary }, mainBorderProperties)}

                            onClick={() => {
                                window.open('/credits.txt')
                            }}
                        >
                            <div style={{ textDecoration: 'none', textAlign: 'center', fontSize: { bottomBarFontSize } }}> Credits </div>
                        </ButtonBase>
                    </Box>
                </Grid>
            </Grid>
        </>;
    }
}
function mapStateToProps(state) {
    return {
        hideCrossLinks: state.stuff.hideCrossLinks,
    }
}
export default connect(
    mapStateToProps,
)(LinkBar)