import React, { Component } from 'react'
import { Grid, Popover } from '@mui/material'
import { IS_TOUCH } from '../game.js'

class ResourceBox extends Component {
    constructor() {
        super()
        this.state = {
            popoverOpen: false,
            anchorEl: null,
        }
        this.togglePopoverClose = this.togglePopoverClose.bind(this)
        this.togglePopoverOpen = this.togglePopoverOpen.bind(this)
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.resourceQuantity != nextProps.resourceQuantity || (this.state.popoverOpen != nextState.popoverOpen)) {
            return true
        } else {
            return false
        }
    }
    componentDidMount() {
    }
    togglePopoverOpen = event => {
        if (!IS_TOUCH) {
            console.log('toggling pop open')
            this.setState({ popoverOpen: true, anchorEl: event.currentTarget })
        }
    }
    togglePopoverClose = event => {
        if (!IS_TOUCH) {
            this.setState({ popoverOpen: false, anchorEl: null })
        }
    }
    render() {
        let { resourceData, resourceQuantity } = this.props
        let { popoverOpen, anchorEl } = this.state
        return (
            <Grid item>
                {!resourceData.disableBox && (
                    <div
                        onMouseEnter={this.togglePopoverOpen}
                        onMouseLeave={this.togglePopoverClose}
                        style={{
                            pointerEvents: 'auto',
                            marginRight: 2, marginLeft: 2,
                            userSelect: 'none', textAlign: 'center', fontWeight: 'bold', color: 'white',
                            backgroundColor: resourceData.color.background,
                            borderRadius: '2px',
                            borderWidth: '2px', borderStyle: 'solid',
                            borderColor: '#362D44',
                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                        {resourceData.icon && (
                            <img src={resourceData.icon} alt="Resource Icon" style={{ width: '5vh', height: '5vh', marginRight: '5px' }} />
                        )}
                        <div>
                            <b> {resourceQuantity} </b>
                        </div>
                    </div>
                )}
                {resourceData.disableBox && (
                    <div
                        onMouseEnter={this.togglePopoverOpen}
                        onMouseLeave={this.togglePopoverClose}
                        style={{
                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                        {resourceData.icon && (
                            <img src={resourceData.icon} alt="Resource Icon" style={{ width: '5vh', height: '5vh', marginRight: '5px' }} />
                        )}
                        <div>
                            <b> {resourceQuantity} </b>
                        </div>
                    </div>
                )}
                <Popover
                    style={{ pointerEvents: 'none', background: 'rgb(0,0,0,0)' }}
                    open={popoverOpen}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    anchorEl={anchorEl}
                >
                    <h4 style={{
                        textAlign: 'center', marginBottom: 3, marginTop: 1,
                    }}><span>
                            {resourceData.popover.text}
                        </span></h4>
                </Popover>
            </Grid>
        )
    }
}

export default ResourceBox