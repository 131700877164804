"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emotes = void 0;
exports.emotes = [
    {
        id: 1,
        name: 'Thumbs Up',
        imgPath: 'thumb_up.png',
        rarity: 'common',
        unlockRequirement: null,
        free: true,
    },
    {
        id: 2,
        name: 'Thumbs Down',
        imgPath: 'thumb_down.png',
        rarity: 'common',
        unlockRequirement: null,
        free: true,
    },
    {
        id: 3,
        name: 'Happy Face',
        imgPath: 'happy_face.png',
        rarity: 'common',
        unlockRequirement: null,
        free: true,
    },
    {
        id: 4,
        name: 'Sad Face',
        imgPath: 'sad_face.png',
        rarity: 'common',
        unlockRequirement: null,
        free: true,
    },
    {
        id: 5,
        name: 'Egg',
        imgPath: 'egg2.png',
        rarity: 'uncommon',
        unlockRequirement: null,
        free: true,
    },
    {
        id: 6,
        name: 'Smile',
        imgPath: 'smile.png',
        rarity: 'uncommon',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 75
        // free: true,
    },
    {
        id: 7,
        name: 'Sadness',
        imgPath: 'sadness.png',
        rarity: 'uncommon',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 75
        // free: true,
    },
    {
        id: 8,
        name: 'Neutral',
        imgPath: 'neutral.png',
        rarity: 'rare',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 150
        // free: true,
    },
    {
        id: 9,
        name: 'Amazed',
        imgPath: 'amazed.png',
        rarity: 'rare',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 150
        // free: true,
    },
    {
        id: 10,
        name: 'Grinning',
        imgPath: 'grinning.png',
        rarity: 'epic',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 275
        // free: true,
    },
    {
        id: 11,
        name: 'Sweating',
        imgPath: 'sweating.png',
        rarity: 'epic',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 275
        // free: true,
    },
    {
        id: 14,
        name: 'heart_eyes',
        imgPath: 'heart_eyes.png',
        rarity: 'legendary',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
        // free: true,
    },
    {
        id: 15,
        name: 'Slime',
        imgPath: 'enemy_slime.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
        // free: true,
    },
    {
        id: 16,
        name: 'Woodland Critter',
        imgPath: 'enemy_woodlandWhelp.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
        // free: true,
    },
    {
        id: 17,
        name: 'Chimera',
        imgPath: 'enemy_leopard.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
        // free: true,
    },
    {
        id: 18,
        name: 'Cannon',
        imgPath: 'enemy_cannon.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
        // free: true,
    },
    {
        id: 19,
        name: 'Charlie',
        imgPath: 'enemy_evilCreature.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
        // free: true,
    },
    {
        id: 20,
        name: 'Dark Wizard',
        imgPath: 'enemy_darkWiz.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
        // free: true,
    },
    {
        id: 21,
        name: 'Green Dragon Whelp',
        imgPath: 'enemy_greenDragonWhelp.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
        // free: true,
    },
    {
        id: 22,
        name: 'Dragon Whelp',
        imgPath: 'enemy_dragonWhelp.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
        // free: true,
    },
    {
        id: 12,
        name: 'lol',
        imgPath: 'lol.png',
        rarity: 'legendary',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
        // free: true,
    },
    {
        id: 13,
        name: 'Starred',
        imgPath: 'starred.png',
        rarity: 'legendary',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
        // free: true,
    },
    {
        id: 23,
        name: 'Dragon Lord',
        imgPath: 'enemy_dragonBoss.png',
        rarity: 'legendary',
        unlockRequirement: 'challenge',
        // free: true,
    },
    {
        id: 24,
        name: 'Giant Slime',
        imgPath: 'enemy_lavaBossSlime.png',
        rarity: 'legendary',
        unlockRequirement: 'challenge',
    },
    {
        id: 25,
        name: 'Hellhound Boss',
        imgPath: 'hellhound_boss.png',
        rarity: 'legendary',
        unlockRequirement: 'challenge',
    },
    {
        id: 26,
        name: 'Mega Cannon',
        imgPath: 'enemy_cannon_boss.png',
        rarity: 'legendary',
        unlockRequirement: 'challenge',
    },
    {
        id: 27,
        name: 'Head Dark Wizard',
        imgPath: 'enemy_darkWizBoss.png',
        rarity: 'legendary',
        unlockRequirement: 'challenge',
    },
    {
        id: 28,
        name: 'Crab',
        imgPath: 'enemy_crabNormal.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
    },
    {
        id: 29,
        name: 'Superior Crab',
        imgPath: 'crab_superior.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
    },
    {
        id: 30,
        name: 'King Crab',
        imgPath: 'crab_boss.png',
        rarity: 'legendary',
        unlockRequirement: 'challenge',
    },
    {
        id: 31,
        name: 'Piranha',
        imgPath: 'enemy_piranha.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
    },
    {
        id: 32,
        name: 'King Piranha',
        imgPath: 'enemy_piranha_boss.png',
        rarity: 'legendary',
        unlockRequirement: 'challenge',
    },
    {
        id: 33,
        name: 'Seahorse',
        imgPath: 'enemy_seahorse.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
    },
    {
        id: 34,
        name: 'Superior Seahorse',
        imgPath: 'enemy_seaHorse_superior.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
    },
    // {
    // 	id: 35,
    // 	name: 'King  Seahorse',
    // 	imgPath: 'enemy_seaHorse_boss.png',
    // 	rarity: 'legendary',
    // 	unlockRequirement: 'challenge',
    // },
    {
        id: 36,
        name: 'Ice Slime',
        imgPath: 'enemy_iceSlime.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
    },
    {
        id: 37,
        name: 'Giant Ice Slime',
        imgPath: 'enemy_iceSlimeBoss.png',
        rarity: 'legendary',
        unlockRequirement: 'challenge',
    },
    {
        id: 38,
        name: 'Mammoth',
        imgPath: 'enemy_mammoth.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
    },
    {
        id: 39,
        name: 'Superior Mammoth',
        imgPath: 'enemy_mammoth_superior.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
    },
    {
        id: 40,
        name: 'King Mammoth',
        imgPath: 'enemy_mammoth_boss.png',
        rarity: 'legendary',
        unlockRequirement: 'challenge',
    },
    {
        id: 41,
        name: 'Ice Golem',
        imgPath: 'enemy_ice_golem.png',
        rarity: 'epic',
        unlockRequirement: 'challenge',
    },
    {
        id: 42,
        name: 'Bat',
        imgPath: 'bat.png',
        rarity: 'rare',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 150,
        event: 'halloween'
    },
    {
        id: 43,
        name: 'Ghost',
        imgPath: 'ghost.png',
        rarity: 'rare',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 150,
        event: 'halloween'
    },
    {
        id: 44,
        name: 'Cute Ghost',
        imgPath: 'ghost_cute.png',
        rarity: 'rare',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 150,
        event: 'halloween'
    },
    {
        id: 45,
        name: 'Pumpkin 1',
        imgPath: 'pumpkin_happy.png',
        rarity: 'rare',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 150,
        event: 'halloween'
    },
    {
        id: 46,
        name: 'Pumpkin 2',
        imgPath: 'pumpkin_2.png',
        rarity: 'rare',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 150,
        event: 'halloween'
    },
    {
        id: 47,
        name: 'Cheeky Ghost',
        imgPath: 'ghost_cheeky.png',
        rarity: 'rare',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 150,
        event: 'halloween'
    },
    {
        id: 48,
        name: 'Sad Ghost',
        imgPath: 'ghost_sad.png',
        rarity: 'rare',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 150,
        event: 'halloween'
    },
    {
        id: 49,
        name: 'Happy Bat',
        imgPath: 'bat_happy.png',
        rarity: 'rare',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 150,
        event: 'halloween'
    },
    {
        id: 50,
        name: 'Spooky Balloons',
        imgPath: 'spooky_balloons.png',
        rarity: 'legendary',
        unlockRequirement: 'challenge',
        // currencyType: 'coins',
        // price: 150,
        event: 'halloween'
    },
    {
        id: 51,
        name: 'Jack-O-Charlie',
        imgPath: 'enemy_charlieSpooky.png',
        rarity: 'legendary',
        unlockRequirement: 'challenge',
        // currencyType: 'coins',
        // price: 150,
        event: 'halloween'
    },
    {
        id: 52,
        name: 'Wealthy Runes',
        imgPath: 'wealthy_runes.png',
        rarity: 'legendary',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 53,
        name: 'Wealthy Glyphs',
        imgPath: 'wealthy_glyphs.png',
        rarity: 'legendary',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 54,
        name: 'Dragon GG',
        imgPath: 'dragon_gg.png',
        rarity: 'legendary',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500
    },
    {
        id: 55,
        name: 'Reindeer',
        imgPath: 'reindeer.png',
        rarity: 'uncommon',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 75,
    },
    {
        id: 56,
        name: 'Candy Cane',
        imgPath: 'candy-cane.png',
        rarity: 'rare',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 150,
    },
    {
        id: 57,
        name: 'Gingerbread',
        imgPath: 'gingerbread.png',
        rarity: 'rare',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 75,
    },
    {
        id: 58,
        name: 'Christmas Tree',
        imgPath: 'christmas-tree.png',
        rarity: 'rare',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 150,
    },
    {
        id: 59,
        name: 'Gift Box',
        imgPath: 'gift-box.png',
        rarity: 'uncommon',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 75,
    },
    {
        id: 60,
        name: 'Snowflake',
        imgPath: 'snowflake.png',
        rarity: 'rare',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 75,
    },
    {
        id: 61,
        name: 'Snowman',
        imgPath: 'snowman.png',
        rarity: 'rare',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 150,
    },
];
