import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { Card } from '@mui/material'
import { Popover } from '@mui/material'
import { IS_TOUCH } from '../game.js'
import ColorTable from "../../shared/colorTable";

class GearBox extends Component {
    constructor() {
        super()
        this.state = {
            popoverOpen: false,
            anchorEl: null,
            rarityInfo: ColorTable.rarityInfo,
        }
    }

    componentDidMount() {
    }

    togglePopoverOpen = event => {
        if (!IS_TOUCH) {
          this.setState({ popoverOpen: true, anchorEl: event.currentTarget })
        }
      }
     
      togglePopoverClose = event => {
        if (!IS_TOUCH) {
          this.setState({ popoverOpen: false, anchorEl: null })
        }
      }

    render() {
        let { gear, gearType, statsData } = this.props
        let { rarityInfo } = this.state

        this.togglePopoverClose = this.togglePopoverClose.bind(this)
        this.togglePopoverOpen = this.togglePopoverOpen.bind(this)
        let { popoverOpen, anchorEl } = this.state


        let { rarityColor, pickupRarity, pickupColor, backgroundColor, starCount, innerBorder } = rarityInfo[gear.rarity]

        if (gear.customCircleColor) {
            rarityColor = gear.customCircleColor
        }
        let statsBox = []

        let traitsBox = []
        let traitDict = {
            'spiky': 'Spiky',
            'swimming': 'Swimming'
        }
        if (statsData && Object.keys(gear.stats).length > 0) {
            for (let [key, value] of Object.entries(gear.stats)) {
                let statDisplayName = statsData.properties.find(stat => stat.name == key).displayName
                statsBox.push(
                    <h4 style={{ textAlign: 'center', marginBottom: 0, marginTop: 0.1 }}> +{value} {statDisplayName} </h4>
                )
            }
        }

        if (gear.traits && Object.keys(gear.traits).length > 0) {
            for (let [key, value] of Object.entries(gear.traits)) {
                traitsBox.push(
                    <h4 style={{ textAlign: 'center', marginBottom: 0, marginTop: 0.1 }}> +{Math.round(value*100)}% {traitDict[key]} </h4>
                )
            }
        }

        if (gear.imgPath) {
            return (
                <Card
                    onMouseEnter={this.togglePopoverOpen}
                    onMouseLeave={this.togglePopoverClose}
                    style={{
                        marginBottom: '10%', position: 'relative', maxHeight: '100%', width: '100%', pointerEvents: 'auto', alpha: '100%', userSelect: 'none', backgroundColor: rarityColor, textAlign: 'center', fontWeight: 'bold',

                        borderRadius: '8px',
                        borderWidth: '2px', borderStyle: 'solid',
                        borderColor: innerBorder,
                        boxShadow: '0 0 0 2px #362D44',
                        maxWidth: '75px',
                        maxHeight: '75px',

                    }}>
                        <img 
                        src={`assets/icons/gear-icons/${gear.imgPath}`}
                        style={{
                            backgroundColor: rarityColor, textAlign: 'right',
                            objectFit: 'scale-down',
                            maxWidth: '75px',
                            maxHeight: '75px',
                            pointerEvents: 'none',
                        }}
                        />
                    <Popover
                        // https://gitmemory.com/issue/mui-org/material-ui/7680/512825747
                        style={{
                            pointerEvents: 'none', minHeight: '120px', minWidth: '90px',
                        }}
                        open={popoverOpen}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        anchorEl={anchorEl}
                    >
                        <Card
                            style={{
                                userSelect: 'none', textAlign: 'center', backgroundColor: rarityColor, minHeight: '120px', minWidth: '200px',
                                margin: '2px',
                                borderRadius: '2px',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                borderColor: 'rgba(0,0,0,0.25)',
                                boxShadow: '0 0 0 2px #362D44',
                            }}>

                            <Grid container xs={12}>
                                <Grid xs={12}>
                                    <h4 style={{ textAlign: 'center', marginBottom: 3, marginTop: 1 }}><span style={{ color: pickupColor }} > {pickupRarity} </span>  </h4>
                                    <h3 style={{ textAlign: 'center', marginBottom: 3, marginTop: 3, fontWeight: 'bold', color: 'white' }}>  {gear.name} </h3>
                                </Grid>
                                <Grid xs={12}>
                                    <Paper style={{
                                        background: 'rgb(255,255,255, 0.3)',
                                        borderTop: '2px solid black',
                                        borderTopLeftRadius: '0px',
                                        borderTopRightRadius: '0px',
                                        height: '100%', color: 'black'
                                    }}>
                                        <p>
                                        {statsBox.length > 0 ? statsBox : null}
                                        </p>
                                        <p>
                                        {traitsBox.length > 0 && traitsBox}
                                        </p>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Card>
                    </Popover>
                </Card>
            )
        }
    }
}

export default GearBox
