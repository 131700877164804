import React, { Component } from 'react'
import { connect } from 'react-redux'
import RespawnMenu from '../RespawnMenu.js'
import TrialVictoryMenu from '../TrialVictoryMenu'
import '../ui.css'
import AccountUsernameInputBox from '../AccountUsernameInputBox.js'
import LoadingScreen from '../LoadingScreen.js'
import BetaPanel from '../BetaPanel.js'

import * as Actions from '../../actions/actions'
import Constants from '../../../shared/constants'
import BottomBannerAd from "../ads/BottomBannerAd";

import { calculateScaleFactor } from '../../game'

import GamePage from "./GamePage";
import InGameUI from "./InGameUI";
import PlayerDied from "./PlayerDied";
import PortalPage from "./PortalUI";
import AlertMessage from '../AlertMessage.js'
import { current } from '@reduxjs/toolkit'
import { calculateOffset } from './hackyoffsetCode.js'

// export let cgAdblockDetected = {value: false}
export async function detectAdblock(referrer) {
  console.log("Detecting adblocker ...");
  if (referrer === 'crazygames') {
    const result = await window.CrazyGames.SDK.ad.hasAdblock();
    console.log('cg sdk adblock', result)
    Actions.toggleAdblock(result)
  } else {
    return Promise.race([
      new Promise(resolve => {
        // If the request wasn't blocked within a very short period of time, this means
        // the adblocker is not active and the request was actually made -> ignore it then
  
        setTimeout(() => {
          // console.log('no adblock at start')
          // Actions.toggleAdblock(false)
          resolve(false)
        }, 100)
      }),
      new Promise(resolve => {
        fetch('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js', {
          method: 'GET',
        })
          .then(res => {
            if (res?.url.includes('text/plain')) {
              // console.log("adblocker tried to fool us with plaintext response");
              Actions.toggleAdblock(true)
              resolve(true)
            }
            // console.log('no adblock')
            // Actions.toggleAdblock(false)
            resolve(false)
          })
          .catch(() => {
            // console.log('adblock detected')
            Actions.toggleAdblock(true)
            resolve(true)
          })
      }),
    ])
  }
  
}

export function getShouldShowGamePage(sdkLoaded, gameLoaded, authenticated, accountUsername, betaActive, alphaActive) {
  return sdkLoaded && gameLoaded && authenticated && accountUsername !== '' &&
    ((betaActive && Constants.RELEASE_STATUS === 'BETA') ||
      (alphaActive && Constants.RELEASE_STATUS === 'ALPHA') ||
      Constants.RELEASE_STATUS === 'PRODUCTION');
}

class UI extends Component {
  constructor() {
    super()
    this.state = {
      componentHeight: 0,
    }
  }
  onLoad() {
    console.log('UI LOADED')
  }
  componentDidMount() {

    detectAdblock()

    let adblock = this.props.adblock
    if (!adblock) {
      // if (typeof sdk !== 'undefined' && sdk.showBanner !== 'undefined') {
      //     sdk.showBanner();
      // }
    }
  }

  waitForAip() {
    let count = 0
    return new Promise((resolve, reject) => {
      (function loop() {
        setTimeout(() => {
          const { aiptag, aipDisplayTag } = window
          if (aiptag && aipDisplayTag) {
            resolve({ aiptag, aipDisplayTag })
          } else {
            loop()
          }
        }, 20)
      })()
    })
  }

  render() {
    let { currentGame, adblock, singleGame, trialVictory, referrer, sdkLoaded, adId,  playerDied, startedGame, gameLoaded, mapLoaded, width, height, accountUsername, authenticated, betaActive, alphaActive, alertMessage} = this.props


    console.log("AD ID", adId)
    // console.log('component height', componentHeight)
    let discordMsg = 'Join us on Discord!'
    let scaleFactor = 1
    let mainMenuScaleFactor = 1
    scaleFactor = calculateScaleFactor(window.innerWidth, window.innerHeight)
    scaleFactor += 0.13
    mainMenuScaleFactor = scaleFactor
    if (width < 600) {
      mainMenuScaleFactor += 0.2
    }
    mainMenuScaleFactor = Math.min(1, mainMenuScaleFactor)

    // console.log({
    //   mapLoaded: mapLoaded,
    //     gameLoaded: gameLoaded,
    //   currentGame: currentGame,
    //   accountUsername: accountUsername,
    //   authenticated: authenticated,
    // })

    // console.log('ratio', width / height)

    const shouldShowGamePage = getShouldShowGamePage(sdkLoaded, gameLoaded, authenticated, accountUsername, betaActive, alphaActive)

     console.log(" SHOULD SHOW GAME PAGE", shouldShowGamePage)
     console.log('map loaded', mapLoaded)

     let showLoadingScreen
     if ((!mapLoaded || !shouldShowGamePage)) {
       showLoadingScreen = true
     }

   
    let bottomBannerOffset = calculateOffset(width, height, scaleFactor, currentGame, playerDied, trialVictory, adblock, referrer, showLoadingScreen)

    console.log('showLoadingScreen', showLoadingScreen)
    console.log('banner offset', bottomBannerOffset)


    let newOffset =  `${scaleFactor * 50}px`
    if (showLoadingScreen) {
      newOffset = '200%'
    }
    let useOldAdSystem = true
    if (referrer === 'crazygames' && sdkLoaded) {
      useOldAdSystem = false
    }

   

    // console.log('bottomBannerOffset', 100-bottomBannerOffset)
    let adName = `bannerAd_${adId}`
   const bottomBannerAd = <BottomBannerAd adName={adName} width={width}/>
    return (
      <div style={{ 'height': '100%', width: '100%' }}>
        <div
          style={{ zIndex: 10, width: '100%', height: '100%' }}
          id='preroll'></div>

        {alertMessage &&
         <div style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: 1,
          width: '70%', 
          height: '50%',
          zIndex:9999999999
        }}>
          <div style={{
            // transform: `scale(${mainMenuScaleFactor})`,
          }}>
                <AlertMessage/>
          </div>
        </div>

        }
        { showLoadingScreen &&
          <LoadingScreen />
        }




        {
            !startedGame && shouldShowGamePage
                ? currentGame ? < GamePage renderBottomAdBanner={useOldAdSystem}/> : <PortalPage renderBottomAdBanner={useOldAdSystem}/>
                : null
        }
     

        {/* {!startedGame && gameLoaded
        && !currentGame &&
        !(accountUsername == '' && authenticated) && 
        sdkLoaded &&
        ((betaActive && Constants.RELEASE_STATUS == 'BETA') || (alphaActive && Constants.RELEASE_STATUS == 'ALPHA') || Constants.RELEASE_STATUS == 'PRODUCTION')
            ?
            <PortalPage/>
            : null}
        {!startedGame && gameLoaded
          && currentGame &&
        !(accountUsername == '' && authenticated) &&
          ((betaActive && Constants.RELEASE_STATUS == 'BETA') || (alphaActive && Constants.RELEASE_STATUS == 'ALPHA') || Constants.RELEASE_STATUS == 'PRODUCTION')
          ?
            <GamePage/>
          : null} */}

        {trialVictory &&
          <div style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            opacity: 1,
            width: '100%',
          }}>
            <div style={{
              transform: `scale(${mainMenuScaleFactor})`,
            }}>
              <TrialVictoryMenu renderBottomAdBanner={useOldAdSystem}/>
            </div>
          </div>
        }
        {playerDied ?
         <PlayerDied renderBottomAdBanner={useOldAdSystem}/>
          : null
        }

         
          { !useOldAdSystem  && (height > 450 && (adblock && height > 600 || !adblock && height > 440) && ((!startedGame && shouldShowGamePage) || playerDied || trialVictory 
          || (showLoadingScreen && adId !== 1)
          )) ?
               <div style={{ position: 'absolute', 
              //  bottom: `${bottomBannerOffset}%`,
              //  top: `${100-bottomBannerOffset}%`,
              bottom: newOffset,
               width: '100%' }}>
                  {bottomBannerAd}
               </div>
              : null
          }


        {!playerDied && startedGame && !trialVictory && gameLoaded && mapLoaded ?
          <InGameUI/>
          : null
        }
        {
          ((!betaActive && Constants.RELEASE_STATUS == 'BETA') || (!alphaActive && Constants.RELEASE_STATUS == 'ALPHA')) && accountUsername &&
          <div
            style={{
              position: 'absolute',
              opacity: 1,
              height: '100%',
              width: '100%',
            }}>
            <BetaPanel
              style={{ height: '100%', width: '100%' }}
            />
          </div>
        }
        {
          !startedGame && !accountUsername && authenticated ?
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -25%)',
                opacity: 1,
                height: '100%',
                width: '100%'
              }}>
              <AccountUsernameInputBox />
            </div>
            : null
        }
      </div >
    )
  }
}

function mapStateToProps(state) {
  return {
    currentGame: state.stuff.currentGame,
    singleGame: state.stuff.singleGame,
    playerDied: state.stuff.playerDied,
    startedGame: state.stuff.startedGame,
    gameLoaded: state.stuff.gameLoaded,
    mapLoaded: state.stuff.mapLoaded,
    adblock: state.stuff.adblock,
    width: state.stuff.width,
    height: state.stuff.height,
    authenticated: state.stuff.authenticated,
    accountUsername: state.stuff.accountUsername,
    guestAccount: state.stuff.guestAccount,
    betaActive: state.stuff.betaActive,
    alphaActive: state.stuff.alphaActive,
    trialVictory: state.stuff.trialVictory,
    alertMessage: state.stuff.alertMessage,
    sdkLoaded: state.stuff.sdkLoaded,
    adId: state.stuff.adId,
    referrer: state.stuff.referrer,
  }
}

export default connect(
  mapStateToProps,
)(UI)