import React, { Component } from 'react'
import { Grid, Box } from '@mui/material'
import { ButtonBase } from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import Constants from '../../shared/constants'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Button } from '@mui/material'
import { IconButton } from '@mui/material'

import { updateSelectedHead, updateEmoteDict, toggleLoadoutMenu, toggleChallengeMenu, updateAccountItems, updateCoins, updateGems } from '../actions/actions'
import Dialog from '@mui/material/Dialog'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { emoteDict as clientsideEmoteDict } from '../index.js'
import {bigScreenBorderProperties, colorPalette, secondaryBackgroundColor} from './uiStyles.js'
import ColorChangingButton from './ColorChangingButton.js'
import ColorChangingUnlockWithChallengesButton from './ColorChangingUnlockWithChallengesButton.js'

import ItemReward from './ItemReward.js'
import {CosmeticsData} from "../../shared/cosmetics/cosmetics";
import ColorTable from "../../shared/colorTable";

const styles = {
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
}

class InventoryPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            maxHeadsPerPage: 15,
            maxEmotesPerPage: 15,
            dialog: false,
            page: 0,
            tab: 0,

            clickedHead: {
                id: 1,
                path: 'assets/player/heads/ch_og_man_1_brown_128.png',
                imgPath: 'ch_og_man_1_brown_128.png',
                name: 'Human Male',
                rarity: 'common'
            },
            clickedEmote: {
                id: 1,
                name: 'Thumbs Up',
                imgPath: 'thumb_up.png',
                rarity: 'common',
                unlockRequirement: null,
                free: true,
            },
            selectedEmoteSlot: 0,
            emoteSlotIndex: {
                0: 'selected',
                1: 'idle',
                2: 'idle',
                3: 'idle'
            },
            iconIndex: {
                0: 'idle',
                1: 'idle',
                2: 'idle',
                3: 'idle',
                4: 'idle',
                5: 'idle',
                6: 'idle',
                7: 'idle',
                8: 'idle',
                9: 'idle',
                10: 'idle',
                11: 'idle',
                12: 'idle',
                13: 'idle',
                14: 'idle',
            },
            tabIndex: {
                0: 'selected',
                1: 'idle',
            },

            dialogNewItem: false,
            itemEarnedPath: 'assets/emotes/smile.png',
            itemEarned: {
                id: 1,
                name: 'Thumbs Up',
                imgPath: 'thumb_up.png',
                rarity: 'common',
                unlockRequirement: null,
                free: true,
            },
            itemEarnedMessage: 'Legendary Emote',

            showBuyButton: true,


        }


        // try {
        //     // if (localStorage.hasOwnProperty('selectedHead') && (localStorage.getItem('selectedHead') !== 'null') && (localStorage.getItem('selectedHead') !== '')
        //     // ) {
        //     //     updateSelectedHead(parseInt(localStorage.getItem('selectedHead')))
        //     // }
        //     // else {
        //     //     localStorage.setItem('selectedHead', 1)
        //     // }
        //     if (localStorage.hasOwnProperty('emoteDict')) {
        //         let emoteData = JSON.parse(localStorage.getItem('emoteDict'))
        //         updateEmoteDict(0, emoteData[0])
        //         updateEmoteDict(1, emoteData[1])
        //         updateEmoteDict(2, emoteData[2])
        //         updateEmoteDict(3, emoteData[3])
        //     } else {
        //         localStorage.setItem('emoteDict', JSON.stringify({
        //             0: 1,
        //             1: 2,
        //             2: 3,
        //             3: 4,
        //         }))
        //     }
        // } catch (err) { }

    }

    componentDidMount() {
        const {selectedHead} = this.props
        const {clickedHead} = this.state
        // const headData = CosmeticsData.heads.find(head => head.id == selectedHead)
        // this.setState({clickedHead: headData})
         // const {selectedHead} = this.props
        if (clickedHead.id !== selectedHead) {
            const headData = CosmeticsData.heads.find(head => head.id == selectedHead)
            this.setState({clickedHead: headData})
        }

        const {emoteDict} = this.props
        const {selectedEmoteSlot} = this.state
        const emoteData = CosmeticsData.emotes.find(emote => emote.id == emoteDict[selectedEmoteSlot])
        this.setState({clickedEmote: emoteData})
        

    }
    increasePage() {

        let { page, maxHeadsPerPage, maxEmotesPerPage, tab, } = this.state

        let table
        let maxPerPage

        if (tab == 0) {
            table = CosmeticsData.heads
            maxPerPage = maxHeadsPerPage
        } else if (tab == 1) {
            table = CosmeticsData.emotes
            maxPerPage = maxEmotesPerPage
        }

        if ((page + 1) * maxPerPage < table.length) {
            console.log('increasing page')
            this.setState({
                page: page + 1
            })
        } else {
            this.setState({
                page: 0
            })
        }
    }
    componentDidUpdate(prevProps, ) {
    }
    decreasePage() {
        let { page, maxHeadsPerPage, maxEmotesPerPage, tab } = this.state

        let table
        let maxPerPage

        if (tab == 0) {
            table = CosmeticsData.heads
            maxPerPage = maxHeadsPerPage
        } else if (tab == 1) {
            table = CosmeticsData.emotes
            maxPerPage = maxEmotesPerPage
        }

        if (page - 1 >= 0) {
            this.setState({
                page: page - 1
            })
        } else {
            this.setState({
                page: (Math.floor(table.length / maxPerPage))
            })
        }
    }

    render() {
        let { selectedHead, joinedDiscord, accountItems, emoteDict, loadoutDialog, token, guestAccount, height } = this.props
        let { dialog, page, clickedHead, maxHeadsPerPage, tab, clickedEmote, selectedEmoteSlot, iconIndex, tabIndex, emoteSlotIndex,
            dialogNewItem, itemEarnedPath, itemEarned, itemEarnedMessage,
            showBuyButton
            // clickedItemCurrencyPath

        } = this.state

        if (height < 560) {
            if (maxHeadsPerPage != 9) {
                this.setState({ maxHeadsPerPage: 9, maxEmotesPerPage: 9, page: 0 })
            }

        } else if (height < 630) {
            if (maxHeadsPerPage != 12) {
                this.setState({ maxHeadsPerPage: 12, maxEmotesPerPage: 12, page: 0 })
            }
        } else {
            if (maxHeadsPerPage != 15) {
                this.setState({ maxHeadsPerPage: 15, maxEmotesPerPage: 15, page: 0 })
            }
        }

        let tab0Color
        let tab1Color

        if (tab == 0) { tab0Color = '#FFD522' }
        else if (tabIndex[0] == 'hover') { tab0Color = '#FFEE77' } else { tab0Color = '#FFEFD2' }
        if (tab == 1) { tab1Color = '#FFD522' } else if (tabIndex[1] == 'hover') { tab1Color = '#FFEE77' } else { tab1Color = '#FFEFD2' }

        let clickedItem

        if (tab == 0) {
            clickedItem = clickedHead
            clickedItem.type = 'head'
        } else if (tab == 1) {
            clickedItem = clickedEmote
            clickedItem.type = 'emote'
        }
        if (clickedItem.currencyType == 'gems') {
            clickedItem.currencyPath = 'assets/ui/glyph.png'
        }
        else if (clickedItem.currencyType == 'coins') {
            clickedItem.currencyPath = 'assets/ui/rune.png'
        }
        let colorTable = ColorTable

        const handleClickOpen = () => {
            toggleLoadoutMenu(true)
        }
        const handleClose = () => {
            toggleLoadoutMenu(false)
        }
        const handleChange = (event, newValue) => {
            this.setState({
                tab: newValue,
                page: 0
            })
        }

        const handleCloseNewItem = () => { this.setState({ 'dialogNewItem': false }) }

        if (tab == 0) {
            let fullHeadTable = JSON.parse(JSON.stringify(CosmeticsData.heads))
            const unlockedHeads = []
            const lockedHeads = []
            for (var i = 0; i < fullHeadTable.length; i++) {
                if (fullHeadTable[i].exclusive === true && !accountItems.heads.includes(fullHeadTable[i].id)) {
                    fullHeadTable.splice(i, 1)
                    i--// decrement index if item is removed
                }
                if (!accountItems.heads.includes(fullHeadTable[i].id) && !fullHeadTable[i].free) {
                    // console.log('head is locked', headTable[i].id)
                    fullHeadTable[i].locked = true
                    unlockedHeads.push(fullHeadTable[i])
                }
                else {
                    fullHeadTable[i].locked = false
                    lockedHeads.push(fullHeadTable[i])
                }
            }
            fullHeadTable = lockedHeads.concat(unlockedHeads)
            var headTable = fullHeadTable.slice(page * maxHeadsPerPage, maxHeadsPerPage * (page + 1))
            while (headTable.length < maxHeadsPerPage) {
                headTable.push({ 'filler': true })
            }

            var itemGrid = headTable.map((head, index) => {
                let backgroundColor
                if (iconIndex[index] == 'idle') { backgroundColor = '#363C47' } else if (iconIndex[index] == 'hover') {
                    backgroundColor = '#E7CAB2'
                } else if (iconIndex[index] == 'down') {
                }
                if (clickedHead.id == head.id) { backgroundColor = '#FFD522' }
                return <Grid item

                    xs={4}
                    style={{
                        marginBottom: '3%',
                        marginTop: '3%',
                    }}
                >
                    {head.filler &&
                        <Box style={{ minWidth: '80px', minHeight: '80px' }}>
                        </Box>
                    }
                    {!head.filler &&
                        <Box component="loadoutbutton">
                            <ButtonBase
                                style={{
                                    background: backgroundColor,
                                    borderRadius: '15px',


                                    borderWidth: '2px',
                                    borderStyle: 'solid',
                                    borderColor: ColorTable.rarityInfo[head.rarity].loadoutBorder,
                                    boxShadow: '0 0 0 2px #C79B7B',

                                    width: '67px',
                                    height: '67px',
                                }}
                                onMouseOver={() => {

                                    iconIndex[index] = 'hover'
                                    this.setState({ iconIndex: iconIndex })

                                }}
                                onMouseOut={() => {
                                    iconIndex[index] = 'idle'
                                    this.setState({ iconIndex: iconIndex })
                                }
                                }
                                onClick={() => {
                                    this.setState({
                                        clickedHead: head,
                                    })
                                    if (!head.locked) {
                                        updateSelectedHead(head.id)
                                        localStorage.setItem('selectedHead', head.id)
                                    }
                                }}
                            >
                                {!head.locked &&
                                    <img style={{
                                        position: 'relative', pointerEvents: 'none',
                                        top: '-10px',
                                        width: '88px'
                                    }}
                                        src={`assets/player/heads/${head.imgPath}`} />
                                }
                                {head.locked &&
                                    <>
                                        <img style={{
                                            position: 'relative',
                                            pointerEvents: 'none',
                                            top: '-10px',
                                            width: '88px'
                                        }}
                                            src={`assets/player/heads/${head.imgPath}`} />
                                        <img style={{
                                            position: 'absolute',
                                            width: '44px',
                                            top: '-15px',
                                            right: '-15px',
                                        }}
                                            src="assets/ui/icon_lock.png" />
                                        {head.price &&
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    right: '5%',
                                                    bottom: '1%',
                                                    pointerEvents: 'none',
                                                    zIndex: 10,
                                                    color: 'white',
                                                    fontSize: '20px',
                                                    textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
                                                }}
                                            >
                                                {/* {head.price} */}
                                            </div>

                                        }
                                    </>
                                }
                            </ButtonBase>
                        </Box>
                    }
                </Grid>
            })

        }
        else if (tab == 1) {
            let fullEmotesTable = JSON.parse(JSON.stringify(CosmeticsData.emotes))
            const unlockedEmotes = []
            const lockedEmotes = []
            // for (var i = 0; i < fullEmotesTable.length; i++) {
            //     if (!accountItems.emotes.includes(fullEmotesTable[i].id) && !fullEmotesTable[i].free) {
            //         fullEmotesTable[i].locked = true
            //         unlockedEmotes.push(fullEmotesTable[i])
            //     }
            //     else {
            //         fullEmotesTable[i].locked = false
            //         lockedEmotes.push(fullEmotesTable[i])
            //     }
            // }
            // fullEmotesTable = lockedEmotes.concat(unlockedEmotes)

            var emoteTable = fullEmotesTable.slice(page * maxHeadsPerPage, maxHeadsPerPage * (page + 1))
            while (emoteTable.length < maxHeadsPerPage) {
                emoteTable.push({ 'filler': true })
            }
            for (let i = 0; i < emoteTable.length; i++) {
                if (!accountItems.emotes.includes(emoteTable[i].id) && !emoteTable[i].free) {
                    emoteTable[i].locked = true
                }
                else {
                    emoteTable[i].locked = false
                }
            }
            var itemGrid = emoteTable.map((emote, index) => {
                let backgroundColor
                if (iconIndex[index] == 'idle') { backgroundColor = '#363C47' } else if (iconIndex[index] == 'hover') {
                    backgroundColor = '#E7CAB2'
                } else if (iconIndex[index] == 'down') {
                }
                if (clickedEmote.id == emote.id) { backgroundColor = '#FFD522' }
                return <Grid item xs={4} style={{
                    marginBottom: '3%',
                    marginTop: '3%',
                }}
                >
                    {emote.filler &&
                        <Box style={{ minWidth: '80px', minHeight: '80px' }}>

                        </Box>
                    }
                    {!emote.filler &&
                        <ButtonBase style={{
                            background: backgroundColor,
                            borderRadius: '15px',
                            borderWidth: '2px',
                            borderStyle: 'solid',
                            borderColor: ColorTable.rarityInfo[emote.rarity].loadoutBorder,
                            boxShadow: '0 0 0 2px #C79B7B',
                            width: '67px',
                            height: '67px',
                        }}
                            onMouseOver={() => {
                                iconIndex[index] = 'hover'
                                this.setState({ iconIndex: iconIndex })

                            }}
                            onMouseOut={() => {
                                iconIndex[index] = 'idle'
                                this.setState({ iconIndex: iconIndex })
                            }
                            }
                            onClick={() => {
                                this.setState({
                                    clickedEmote: emote
                                })
                                if (!emote.locked) {
                                    updateEmoteDict(selectedEmoteSlot, emote.id)
                                    clientsideEmoteDict[selectedEmoteSlot] = emote.id
                                    try {
                                        console.log('setting 562 emote dict', clientsideEmoteDict)
                                        localStorage.setItem('emoteDict', JSON.stringify(clientsideEmoteDict))
                                    } catch (err) { }
                                }
                            }}
                        >
                            {!emote.locked &&
                                <img style={{
                                    position: 'relative', pointerEvents: 'none',
                                    width: '67px'
                                }}
                                    src={`assets/emotes/${emote.imgPath}`} />
                            }
                            {emote.locked &&
                                <>
                                    <img style={{
                                        position: 'relative', filter: 'grayscale(100%)', pointerEvents: 'none',
                                        width: '67px'
                                    }}
                                        src={`assets/emotes/${emote.imgPath}`} />
                                    <img style={{
                                        position: 'absolute',
                                        width: '44px',
                                        top: '-15px',
                                        right: '-15px',
                                    }}
                                        src="assets/ui/icon_lock.png" />
                                </>
                            }
                        </ButtonBase>
                    }
                </Grid>
            })
            var emotePaths = [
                CosmeticsData.emotes.find(emote => emote.id == emoteDict[0]),
                CosmeticsData.emotes.find(emote => emote.id == emoteDict[1]),
                CosmeticsData.emotes.find(emote => emote.id == emoteDict[2]),
                CosmeticsData.emotes.find(emote => emote.id == emoteDict[3])
            ]
            var emoteSlotGrid = emotePaths.map((emote, index,) => {
                let backgroundColor
                console.log('index', emoteSlotIndex)
                console.log('emote', emote)
                if (emoteSlotIndex[index] == 'idle') { backgroundColor = '#363C47' } else if (emoteSlotIndex[index] == 'hover') {
                    backgroundColor = '#E7CAB2'
                } else if (emoteSlotIndex[index] == 'down') {
                }
                if (selectedEmoteSlot == index) {
                    backgroundColor = '#FFD522'
                    if (clickedEmote.id) {
                        emote = clickedEmote
                    } else {
                        if (!clickedEmote.rarity) {
                            this.setState({ clickedEmote: { rarity: emote.rarity } })
                        }
                    }
                }
                return <Grid xs={6}
                    style={{
                        marginBottom: '1%',
                        minHeight: '89px'
                    }}
                >
                    <ButtonBase style={{
                        background: backgroundColor,
                        borderRadius: '15px',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: ColorTable.rarityInfo[emote.rarity].loadoutBorder,
                        boxShadow: '0 0 0 2px #C79B7B',
                    }}
                        onMouseOver={() => {
                            emoteSlotIndex[index] = 'hover'
                            this.setState({ emoteSlotIndex: emoteSlotIndex })
                        }}
                        onMouseOut={() => {
                            emoteSlotIndex[index] = 'idle'
                            this.setState({ emoteSlotIndex: emoteSlotIndex })
                        }
                        }
                        onClick={() => {
                            let chosenEmote
                            if (emoteTable.includes(emote)) {
                                chosenEmote = emote
                            } else {
                                chosenEmote = {}
                            }
                            for (const [key, value] of Object.entries(emoteSlotIndex)) {
                                if (key != index) {
                                    emoteSlotIndex[key] = 'idle'
                                } else {
                                    emoteSlotIndex[key] = 'selected'
                                }
                            }
                            this.setState({
                                selectedEmoteSlot: index,
                                clickedEmote: chosenEmote,
                                emoteSlotIndex: emoteSlotIndex
                            })
                        }}
                    >
                        <img style={{
                            position: 'relative', pointerEvents: 'none',
                            width: '80px',
                        }}
                            src={`assets/emotes/${emote.imgPath}`} />
                    </ButtonBase>
                </Grid>
            })
        }
        return <>
            <ColorChangingButton buttonFunction={handleClickOpen}
                config={
                    {
                        idle: {
                            fillColor: '#7C4E4D',
                            outerBorderColor: '#362D44',
                            innerBorderColor: '#543131',
                        },
                        hover: {
                            fillColor: '#CC7A82',
                            outerBorderColor: '#362D44',
                            innerBorderColor: '#AA5561',
                        },
                        down: {
                            fillColor: '#CC7A82',
                            outerBorderColor: '#362D44',
                            innerBorderColor: '#AA5561',
                        },
                    }}
                title='Loadout'
                buttonStyling={{
                    marginTop: '10px',
                    color: 'white',
                    width: '100%',
                    paddingTop: '1%', paddingBottom: '1%'
                }}
            />

            <Dialog
                fullWidth={true}
                style={{
                    padding: '10px',
                    cursor: 'url(assets/ui/cursor.png),pointer',
                }}
                classes={{
                    paper: {
                    }
                }}
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={loadoutDialog}
                TransitionProps={{
                    onExited: () => {
                        let currHead = CosmeticsData.heads.find(head => head.id == selectedHead)
                        let currEmoteID = clientsideEmoteDict[selectedEmoteSlot]
                        let currEmote = CosmeticsData.emotes.find(emote => emote.id == currEmoteID)
                        this.setState(
                            {
                                clickedHead: currHead,
                                clickedEmote: currEmote,
                            })
                    }
                }}>
                <IconButton
                    style={{
                        alignSelf: 'flex-end',
                        marginTop: -5,
                        position: 'absolute'
                    }}
                    onClick={handleClose}
                    size="large">
                    <CancelPresentationIcon style={{ fontSize: '3rem' }} />
                </IconButton>

                <Grid container xs={12}>
                    <Paper align='center' justify="center" alignItems="center" direction="row"
                        style={Object.assign({
                            background: colorPalette.primary,
                            borderColor: colorPalette.primaryBorder,
                        }, bigScreenBorderProperties)}>
                        <Grid container
                            align='center' justifyContent="center" alignItems="center" direction="row"
                            style={{
                                marginBottom: '1%'
                            }}>
                            <Grid
                                item xs={12} style={{
                                    paddingBottom: '2px',
                                    padding: '13px'
                                }}>
                                {/* <h1><b> Loadout (Coming soon!)</b></h1> */}
                                <b style={{ fontSize: '22px' }}> Loadout</b>
                            </Grid>
                            <Grid item xs={12} style={{ paddingBottom: '1.5%' }}>
                                <Paper square>
                                    <Tabs
                                        value={tab}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        onChange={handleChange}
                                        centered

                                        style={{
                                            background: colorPalette.primary,
                                        }}
                                    >
                                        <Tab style={{
                                            background: tab0Color,
                                            color: 'black',
                                            fontSize: '20px',
                                            textTransform: 'none',
                                            marginRight: '10px',
                                            borderTopLeftRadius: '8px',
                                            borderTopRightRadius: '8px',
                                            borderStyle: 'solid',
                                            borderColor: '#362D44',
                                            borderWidth: '3px',
                                            boxShadow: '0 0 0 1px #FFFFFF',
                                            padding: '3px',
                                        }}
                                            onMouseOver={() => {
                                                tabIndex[0] = 'hover'
                                                this.setState({ tabIndex: tabIndex })
                                            }}
                                            onMouseOut={() => {
                                                tabIndex[0] = 'idle'
                                                this.setState({ tabIndex: tabIndex })
                                            }}
                                            label="Skins"
                                        />
                                        <Tab
                                            style={{
                                                background: tab1Color,
                                                color: 'black',
                                                fontSize: '20px',
                                                textTransform: 'none',
                                                marginRight: '10px',
                                                borderTopLeftRadius: '8px',
                                                borderTopRightRadius: '8px',
                                                borderStyle: 'solid',
                                                borderColor: '#362D44',
                                                borderWidth: '3px',
                                                boxShadow: '0 0 0 1px #FFFFFF',
                                                padding: '3px',
                                            }}
                                            onMouseOver={() => {
                                                tabIndex[1] = 'hover'
                                                this.setState({ tabIndex: tabIndex })
                                            }}
                                            onMouseOut={() => {
                                                tabIndex[1] = 'idle'
                                                this.setState({ tabIndex: tabIndex })
                                            }}
                                            label="Emotes" />
                                        <Tab
                                            style={{
                                                background: '#E1D0CF',
                                                marginRight: '10px',
                                                borderTopLeftRadius: '8px',
                                                borderTopRightRadius: '8px',
                                                fontSize: '20px',
                                                textTransform: 'none',
                                                borderStyle: 'solid',
                                                borderColor: '#362D44',
                                                borderWidth: '3px',
                                                boxShadow: '0 0 0 1px #FFFFFF',
                                                padding: '3px',
                                            }}
                                            label="Coming Soon" disabled />
                                    </Tabs>
                                </Paper>
                            </Grid>

                            <Grid xs={7}>
                                <Grid container xs={12}>
                                    <Grid xs={1}>
                                        <IconButton
                                            style={{
                                                position: 'absolute',
                                                top: '60%',
                                                transform: 'translateY(-50%)',
                                                left: '-13px',
                                            }}
                                            onClick={this.decreasePage.bind(this)}
                                            size="large">
                                            <KeyboardArrowLeftIcon
                                                style={{ fontSize: '3rem' }}
                                            />
                                        </IconButton>
                                    </Grid>
                                    <Grid
                                        container
                                        xs={10}>
                                        <Grid container direction='row' xs={12}
                                            alignItems='flex-start' justifyContent='flex-start'
                                        >
                                            {itemGrid}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        xs={1}>
                                        <IconButton
                                            style={{
                                                position: 'absolute',
                                                top: '60%',
                                                transform: 'translateY(-50%)',
                                                left: '296px'
                                            }}
                                            onClick={this.increasePage.bind(this)}
                                            size="large">
                                            <KeyboardArrowRightIcon style={{ fontSize: '3rem' }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container
                                direction='row'
                                alignItems='flex-start'
                                style={{ height: '100%' }}
                                xs={5}>
                                <Grid xs={3}></Grid>
                                <Grid xs={6}
                                    style={{
                                        background: colorTable.rarityBackground[clickedItem.rarity],
                                        borderRadius: '15px',
                                        marginBottom: '2.5%',
                                        borderWidth: '2px', borderStyle: 'solid',
                                        borderColor: '#362D44',
                                        boxShadow: '0 0 0 2px #FFFFFF',
                                    }}>
                                    <b style={{
                                        textTransform: 'capitalize',
                                        color: colorTable.rarityText[clickedItem.rarity]
                                    }}> {clickedItem.rarity}</b>
                                </Grid>
                                <Grid xs={3}></Grid>
                                <Grid container
                                    direction='row'
                                    justifyContent='center'
                                    xs={12}
                                    style={{
                                    }}
                                >
                                    {
                                        tab == 0 &&
                                        <div style={{
                                            position: 'relative', left: 0, top: 0,
                                            borderStyle: 'solid', borderWidth: '3px',
                                            borderColor: ColorTable.rarityInfo[clickedHead.rarity].loadoutBorder,
                                            borderRadius: '3px',
                                            boxShadow: '0 0 0 2px #C79B7B',
                                            width: '174px', height: '174px',
                                        }}>
                                            {(clickedHead.rarity == 'common' || clickedHead.rarity == 'uncommon') &&
                                                <img style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    pointerEvents: 'none',
                                                }}
                                                    src="assets/player/background2.png" />
                                            }
                                            {
                                                clickedHead.rarity == 'rare' &&
                                                <img style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    pointerEvents: 'none',
                                                }}
                                                    src="assets/player/forest_background.png" />
                                            }
                                            {
                                                clickedHead.rarity == 'epic' &&
                                                <img style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    pointerEvents: 'none',
                                                }}
                                                    src="assets/player/dungeon_background.png" />
                                            }
                                            {
                                                clickedHead.rarity == 'legendary' &&
                                                <img style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    pointerEvents: 'none',
                                                }}
                                                    src="assets/player/lava_background.png" />
                                            }
                                            <img style={{
                                                top: '43%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%) rotate(90deg)',
                                                position: 'absolute',
                                                pointerEvents: 'none',
                                                width: '100px'
                                            }}
                                                src="assets/player/body_starter.png"
                                            />
                                            <img style={{
                                                width: '39px',
                                                position: 'absolute',
                                                pointerEvents: 'none',
                                                top: '71%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%) rotate(90deg)',
                                            }}
                                                src="assets/player/hands_menu.png"
                                            />

                                            <img style={{
                                                position: 'absolute',
                                                top: '43%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                pointerEvents: 'none',
                                                width: '100px'
                                            }}
                                                src={`assets/player/heads/${clickedHead.imgPath}`}

                                            />
                                        </div>
                                    }
                                    {tab == 1 &&
                                        <>
                                            {emoteSlotGrid}
                                        </>

                                    }
                                </Grid>
                                {clickedItem.locked && clickedItem.unlockRequirement == 'challenge' &&
                                    <Grid xs={12}
                                        style={{ minHeight: '46px', maxHeight: '46px' }}
                                    >
                                        <ColorChangingUnlockWithChallengesButton buttonFunction={
                                            () => {
                                                toggleLoadoutMenu(false)
                                                toggleChallengeMenu(true)
                                            }
                                        }
                                            config={
                                                {
                                                    idle: {
                                                        fillColor: '#7C4E4D',
                                                        outerBorderColor: '#362D44',
                                                        innerBorderColor: '#543131',
                                                    },
                                                    hover: {
                                                        fillColor: '#CC7A82',
                                                        outerBorderColor: '#362D44',
                                                        innerBorderColor: '#AA5561',
                                                    },
                                                    down: {
                                                        fillColor: '#CC7A82',
                                                        outerBorderColor: '#362D44',
                                                        innerBorderColor: '#AA5561',
                                                    },
                                                }

                                            }
                                            title='Unlock with Challenges'
                                            buttonStyling={{
                                                marginTop: '10px', marginRight: '5px',
                                                color: 'white',
                                                padding: '1%',
                                                maxWidth: '200px',
                                                width: '100%',
                                                fontSize: '10px'
                                            }}
                                        />
                                    </Grid>
                                }
                                {clickedItem.locked && clickedItem.unlockRequirement == 'shop' && showBuyButton &&
                                    <Grid container xs={12}>
                                        <Grid xs={12}
                                            style={{ minHeight: '46px', maxHeight: '46px' }}
                                        >
                                            <Button style={{
                                                minWidth: '200px', marginTop: '10px', marginRight: '5px',
                                                background: secondaryBackgroundColor,
                                                borderWidth: '2px', borderStyle: 'solid', borderColor: 'black',
                                                position: 'relative',
                                            }} variant="contained"
                                                onClick={() => {
                                                    this.setState({ showBuyButton: false })
                                                    setTimeout(
                                                        () => { this.setState({ showBuyButton: true }) }, 2000
                                                    )

                                                    console.log('SENDING BUY REQUEST')
                                                    let url

                                                    if (window.location.host == 'localhost:3000') {
                                                        url = `http://${window.location.host}/account/buyItem`
                                                    }
                                                    else { url = `https://${window.location.host}/account/buyItem` }
                                                    fetch(url, {
                                                        method: 'POST',
                                                        credentials: 'include',
                                                        headers: {
                                                            Accept: 'application/json',
                                                            'Content-Type': 'application/json',
                                                            'Access-Control-Allow-Credentials': true
                                                        },
                                                        body: JSON.stringify({
                                                            token: token,
                                                            guest: guestAccount,
                                                            itemId: clickedItem.id,
                                                            itemType: clickedItem.type,
                                                        })
                                                    })
                                                        .then(response => {
                                                            if (response.status === 200) {
                                                                console.log('RESPONSE', response)
                                                                return response.json().then(user => {
                                                                    console.log('USER RESPONSE', user)
                                                                    let accountItems = user.accountItems
                                                                    let rewardEarned = user.itemEarned
                                                                    updateAccountItems(accountItems)
                                                                    updateCoins(user.coins)
                                                                    updateGems(user.gems)
                                                                    let reward
                                                                    let rewardImgPath
                                                                    let itemMessage

                                                                    if (rewardEarned.type == 'head') {
                                                                        reward = CosmeticsData.heads.find(head => head.id == rewardEarned.id)
                                                                        rewardImgPath = `assets/player/heads/${reward.imgPath}`
                                                                        itemMessage = `${reward.rarity} Skin`
                                                                        updateSelectedHead(rewardEarned.id)
                                                                    }
                                                                    else if (rewardEarned.type == 'emote') {
                                                                        reward = CosmeticsData.emotes.find(emote => emote.id == rewardEarned.id)
                                                                        rewardImgPath = `assets/emotes/${reward.imgPath}`
                                                                        itemMessage = `${reward.rarity} Emote`
                                                                        updateEmoteDict(selectedEmoteSlot, rewardEarned.id)
                                                                    }
                                                                    this.setState({
                                                                        itemEarnedPath: rewardImgPath,
                                                                        itemEarned: reward,
                                                                        itemEarnedMessage: itemMessage,
                                                                        dialogNewItem: true
                                                                    })
                                                                })
                                                            }
                                                        })
                                                }}
                                            >
                                                <b
                                                    style={{ position: 'relative' }}
                                                > Buy for {clickedItem.price}
                                                    <img style={{
                                                        position: 'absolute',
                                                        width: '33px',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        pointerEvents: 'none',
                                                        margin: 'auto',
                                                        zIndex: 10,
                                                    }}
                                                        src={clickedItem.currencyPath}
                                                    />
                                                </b>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                }
                                {clickedItem.locked && clickedItem.unlockRequirement == 'shop' && !showBuyButton &&
                                    <Grid container xs={12}>
                                        <Grid xs={12}
                                            style={{ minHeight: '46px', maxHeight: '46px' }}
                                        >
                                            <Button style={{
                                                minWidth: '200px', marginTop: '10px', marginRight: '5px',
                                                background: secondaryBackgroundColor,
                                                borderWidth: '2px', borderStyle: 'solid', borderColor: 'black',
                                                position: 'relative',
                                            }} variant="contained"
                                            >
                                                <b
                                                    style={{ position: 'relative' }}
                                                > Processing Purchase...
                                                </b>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                }
                                {!clickedItem.locked &&
                                    <Grid xs={12}
                                        style={{ minHeight: '46px', maxHeight: '46px' }}
                                    >
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Dialog>

            <ItemReward
                dialogNewItem={dialogNewItem}
                handleCloseNewItem={handleCloseNewItem}
                itemEarned={itemEarned}
                itemEarnedMessage={itemEarnedMessage}
                itemEarnedPath={itemEarnedPath}
            />
        </>;
    }
}


function mapStateToProps(state) {
    return {
        selectedHead: state.stuff.selectedHead,
        joinedDiscord: state.stuff.joinedDiscord,
        accountItems: state.stuff.accountItems,
        accountUsername: state.stuff.accountUsername,
        emoteDict: state.stuff.emoteDict,
        loadoutDialog: state.stuff.loadoutDialog,
        token: state.stuff.token,
        guestAccount: state.stuff.guestAccount,
        height: state.stuff.height,
    }
}

export default connect(
    mapStateToProps,
)(InventoryPanel)