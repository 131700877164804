import React, { Component } from "react"
import Button from '@mui/material/Button'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'

class ColorChangingSigninButton extends Component {
    constructor() {
        super()
        this.state = {
            fillColor: '#E7CAB2',
            outerBorderColor: '#362D44',
            innerBorderColor: '#FFF1D2',
            config: {
                idle: {
                    fillColor: '#E7CAB2',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFF1D2',
                },
                hover: {
                    fillColor: '#E7CAB2',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFF1D2',
                },
                down: {
                    fillColor: '#E7CAB2',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFF1D2',
                }
            }
        }
    }

    componentDidMount() {
        let { config } = this.props
        this.setState({
            config: config,
            fillColor: config.idle.fillColor,
            outerBorderColor: config.idle.outerBorderColor,
            innerBorderColor: config.idle.innerBorderColor
        })
    }

    render() {
        let { fillColor, innerBorderColor, outerBorderColor, config } = this.state
        let { buttonFunction, title, buttonStyling, textColor, imgPath } = this.props

        // console.log("CHOSEN FILL COLOR", fillColor)
        if (!textColor) { textColor = 'white' }
        return (
            <Button
                style={
                    Object.assign({
                        fontSize: '2vh',
                        // color: textColor,
                        borderRadius: '6px',
                        borderWidth: '2px', borderStyle: 'solid',
                        background: fillColor,
                        borderColor: innerBorderColor,
                        boxShadow: `0 0 0 2px ${outerBorderColor}`,
                        textTransform: 'none'
                    },
                        buttonStyling)
                }

                variant="contained"

                onMouseOver={() => {
                    this.setState({
                        fillColor: config.hover.fillColor,
                        outerBorderColor: config.hover.outerBorderColor,
                        innerBorderColor: config.hover.innerBorderColor
                    })
                }}
                onMouseOut={() => {
                    this.setState({
                        fillColor: config.idle.fillColor,
                        outerBorderColor: config.idle.outerBorderColor,
                        innerBorderColor: config.idle.innerBorderColor
                    })
                }
                }
                onMouseDown={(e) => {
                    this.setState({
                        fillColor: config.down.fillColor,
                        outerBorderColor: config.down.outerBorderColor,
                        innerBorderColor: config.down.innerBorderColor
                    })
                }}

                onClick={(e) => {
                    e.nativeEvent.stopImmediatePropagation()
                    e.preventDefault()
                    e.stopPropagation()

                    buttonFunction()
                }}
            >

            <Grid container xs={5} justifyContent="flex-end" direction="row" alignItems="flex-start">
                <img style={{
                    pointerEvents: 'none',
                    width: '64px', height: '65.5x', paddingRight: '20px'
                }} src={imgPath} />  </Grid>
            <Grid container justifyContent="flex-start" xs={7}>
                <b style={{ color: 'white', fontSize: '1.2rem' }}>{title}</b></Grid>



{/*                     
                <b style={{
                    fontSize: 'max(1.5vh, 19px)', color: textColor,
                }}>
                    {title} </b> */}
            </Button >
        );
    }
}

export default (ColorChangingSigninButton)