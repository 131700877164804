import React from 'react'
import ColorChangingIcon from '../ColorChangingIcon.js'
import { iconStyle, iconStyleSmall } from '../uiStyles.js'
import {toggleShop, updateJoinedDiscord} from '../../actions/actions.js'
import { updateEmoteBarCenter } from '../../keyBinds'
import { emoteWheelActive } from '../../game.js'

function IconEmotes(props) {

    let chosenIconStyle
    if (props.buttonSize == 'small') {
        chosenIconStyle = iconStyleSmall
    } else {
        chosenIconStyle = iconStyle
    }

    return <ColorChangingIcon
        buttonFunction={() => {
            let showMenu = !emoteWheelActive.value
            updateEmoteBarCenter(showMenu)
        }}
        config={{
            idle: {
                fillColor: '#7689D4',
                outerBorderColor: '#362D44',
                innerBorderColor: '#4E65AF',
            },
            hover: {
                fillColor: '#9BB2F9',
                outerBorderColor: '#362D44',
                innerBorderColor: '#5C96EA',
            },
            down: {
                fillColor: '#5D7AC1',
                outerBorderColor: '#362D44',
                innerBorderColor: '#445EB2',
            }
        }}
        buttonStyling={chosenIconStyle}
        imgUrl='assets/ui/icon_emote.png'
        hotkey='Q'
    />
}

export default (IconEmotes)