"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.heads = void 0;
exports.heads = [
    {
        id: 1,
        name: 'Human Man',
        rarity: 'common',
        imgPath: 'ch_og_man_1_brown_128.png',
        free: true,
    },
    {
        id: 5,
        name: 'Human Man',
        rarity: 'common',
        imgPath: 'ch_og_man_1_dark_grey_blue_128.png',
        free: true,
    },
    {
        id: 2,
        name: 'Human Man',
        rarity: 'common',
        imgPath: 'ch_og_man_1_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 3,
        name: 'Human Man',
        rarity: 'common',
        imgPath: 'ch_og_man_1_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 4,
        name: 'Human Man',
        rarity: 'common',
        imgPath: 'ch_og_man_1_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 6,
        name: 'Human Man',
        rarity: 'common',
        imgPath: 'ch_og_man_1_dark_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 7,
        name: 'Human Man',
        rarity: 'common',
        imgPath: 'ch_og_man_1_golden_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 100,
        free: true,
    },
    {
        id: 8,
        name: 'Human Man',
        rarity: 'common',
        imgPath: 'ch_og_man_1_light_blue_128.png',
        unlockRequirement: 'challenge',
    },
    {
        id: 9,
        name: 'Human Man',
        rarity: 'common',
        imgPath: 'ch_og_man_1_mid_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    ////// woman 1
    {
        id: 13,
        name: 'Human Woman',
        rarity: 'common',
        imgPath: 'ch_og_woman_1_brown_128.png',
        free: true,
    },
    {
        id: 17,
        name: 'Human Woman',
        rarity: 'common',
        imgPath: 'ch_og_woman_1_dark_grey_blue_128.png',
        free: true,
    },
    {
        id: 10,
        name: 'Human Woman',
        rarity: 'common',
        imgPath: 'ch_og_woman_1_ash_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 11,
        name: 'Human Woman',
        rarity: 'common',
        imgPath: 'ch_og_woman_1_bright_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 12,
        name: 'Human Woman',
        rarity: 'common',
        imgPath: 'ch_og_woman_1_bright_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 14,
        name: 'Human Woman',
        rarity: 'common',
        imgPath: 'ch_og_woman_1_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 15,
        name: 'Human Woman',
        rarity: 'common',
        imgPath: 'ch_og_woman_1_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 16,
        name: 'Human Woman',
        rarity: 'common',
        imgPath: 'ch_og_woman_1_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 18,
        name: 'Human Woman',
        rarity: 'common',
        imgPath: 'ch_og_woman_1_dark_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 19,
        name: 'Human Woman',
        rarity: 'common',
        imgPath: 'ch_og_woman_1_golden_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 100
        free: true,
    },
    {
        id: 20,
        name: 'Human Woman',
        rarity: 'common',
        imgPath: 'ch_og_woman_1_light_blue_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 50
    },
    {
        id: 21,
        name: 'Human Woman',
        rarity: 'common',
        imgPath: 'ch_og_woman_1_mid_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    // common man 3
    {
        id: 22,
        name: 'Man 3',
        rarity: 'common',
        imgPath: 'ch_common_man_3_bright_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 23,
        name: 'Man 3',
        rarity: 'common',
        imgPath: 'ch_common_man_3_crimson_pink_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 24,
        name: 'Man 3',
        rarity: 'common',
        imgPath: 'ch_common_man_3_dark_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 25,
        name: 'Man 3',
        rarity: 'common',
        imgPath: 'ch_common_man_3_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 26,
        name: 'Man 3',
        rarity: 'common',
        imgPath: 'ch_common_man_3_dark_grey_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 50
        free: true,
    },
    {
        id: 27,
        name: 'Man 3',
        rarity: 'common',
        imgPath: 'ch_common_man_3_dark_grey_blue_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 50
        free: true,
    },
    {
        id: 28,
        name: 'Man 3',
        rarity: 'common',
        imgPath: 'ch_common_man_3_green_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 29,
        name: 'Man 3',
        rarity: 'common',
        imgPath: 'ch_common_man_3_light_navy_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 30,
        name: 'Man 3',
        rarity: 'common',
        imgPath: 'ch_common_man_3_merida_orange_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 31,
        name: 'Man 3',
        rarity: 'common',
        imgPath: 'ch_common_man_3_mid_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 32,
        name: 'Man 3',
        rarity: 'common',
        imgPath: 'ch_common_man_3_mid_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 33,
        name: 'Man 3',
        rarity: 'common',
        imgPath: 'ch_common_man_3_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    // Common Woman 1
    {
        id: 34,
        name: 'Woman 1',
        rarity: 'common',
        imgPath: 'ch_common_woman_1_bright_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 35,
        name: 'Woman 1',
        rarity: 'common',
        imgPath: 'ch_common_woman_1_crimson_pink_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 36,
        name: 'Woman 1',
        rarity: 'common',
        imgPath: 'ch_common_woman_1_dark_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 37,
        name: 'Woman 1',
        rarity: 'common',
        imgPath: 'ch_common_woman_1_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 38,
        name: 'Woman 1',
        rarity: 'common',
        imgPath: 'ch_common_woman_1_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 39,
        name: 'Woman 1',
        rarity: 'common',
        imgPath: 'ch_common_woman_1_dark_grey_blue_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 100
        free: true
    },
    {
        id: 40,
        name: 'Woman 1',
        rarity: 'common',
        imgPath: 'ch_common_woman_1_green_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 41,
        name: 'Woman 1',
        rarity: 'common',
        imgPath: 'ch_common_woman_1_light_navy_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 42,
        name: 'Woman 1',
        rarity: 'common',
        imgPath: 'ch_common_woman_1_merida_orange_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 43,
        name: 'Woman 1',
        rarity: 'common',
        imgPath: 'ch_common_woman_1_mid_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 44,
        name: 'Woman 1',
        rarity: 'common',
        imgPath: 'ch_common_woman_1_mid_green_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 100
        free: true,
    },
    {
        id: 45,
        name: 'Woman 1',
        rarity: 'common',
        imgPath: 'ch_common_woman_1_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    /// man 1
    {
        id: 46,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_1_bright_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 47,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_1_crimson_pink_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 48,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_1_dark_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 49,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_1_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 50,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_1_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 51,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_1_dark_grey_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 52,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_1_green_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 53,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_1_light_navy_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 54,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_1_merida_orange_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 55,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_1_mid_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 56,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_1_mid_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    {
        id: 57,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_1_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 50
    },
    // common man 2
    {
        id: 58,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_2_bright_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 59,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_2_crimson_pink_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 60,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_2_dark_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 61,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_2_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 62,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_2_dark_grey_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 100
        free: true,
    },
    {
        id: 63,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_2_dark_grey_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 64,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_2_green_blue_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 100
        free: true
    },
    {
        id: 65,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_2_light_navy_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 66,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_2_merida_orange_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 67,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_2_mid_cyan_128.png',
        unlockRequirement: 'challenge',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 100
    },
    {
        id: 68,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_2_mid_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 69,
        name: 'Man 1',
        rarity: 'common',
        imgPath: 'ch_common_man_2_bright_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    // common woman 3
    {
        id: 70,
        name: 'Woman 3',
        rarity: 'common',
        imgPath: 'ch_common_woman_3_bright_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 71,
        name: 'Woman 3',
        rarity: 'common',
        imgPath: 'ch_common_woman_3_crimson_pink_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 72,
        name: 'Woman 3',
        rarity: 'common',
        imgPath: 'ch_common_woman_3_dark_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 73,
        name: 'Woman 3',
        rarity: 'common',
        imgPath: 'ch_common_woman_3_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 74,
        name: 'Woman 3',
        rarity: 'common',
        imgPath: 'ch_common_woman_3_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 75,
        name: 'Woman 3',
        rarity: 'common',
        imgPath: 'ch_common_woman_3_dark_grey_blue_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 100
        free: true
    },
    {
        id: 76,
        name: 'Woman 3',
        rarity: 'common',
        imgPath: 'ch_common_woman_3_green_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 77,
        name: 'Woman 3',
        rarity: 'common',
        imgPath: 'ch_common_woman_3_light_navy_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 78,
        name: 'Woman 3',
        rarity: 'common',
        imgPath: 'ch_common_woman_3_merida_orange_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 100
    },
    {
        id: 79,
        name: 'Woman 3',
        rarity: 'common',
        imgPath: 'ch_common_woman_3_mid_cyan_128.png',
        unlockRequirement: 'challenge',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 100
    },
    {
        id: 80,
        name: 'Woman 3',
        rarity: 'common',
        imgPath: 'ch_common_woman_3_mid_green_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'coins',
        // price: 100
        free: true
    },
    /// woman 2
    {
        id: 81,
        name: 'Woman 2',
        rarity: 'uncommon',
        imgPath: 'ch_common_woman_2_bright_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125
    },
    {
        id: 82,
        name: 'Woman 2',
        rarity: 'uncommon',
        imgPath: 'ch_common_woman_2_crimson_pink_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125
    },
    {
        id: 83,
        name: 'Woman 2',
        rarity: 'uncommon',
        imgPath: 'ch_common_woman_2_dark_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125
    },
    {
        id: 84,
        name: 'Woman 2',
        rarity: 'uncommon',
        imgPath: 'ch_common_woman_2_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125
    },
    {
        id: 85,
        name: 'Woman 2',
        rarity: 'uncommon',
        imgPath: 'ch_common_woman_2_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125
    },
    {
        id: 86,
        name: 'Woman 2',
        rarity: 'uncommon',
        imgPath: 'ch_common_woman_2_dark_grey_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125
    },
    {
        id: 87,
        name: 'Woman 2',
        rarity: 'uncommon',
        imgPath: 'ch_common_woman_2_green_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125
    },
    {
        id: 88,
        name: 'Woman 2',
        rarity: 'uncommon',
        imgPath: 'ch_common_woman_2_light_navy_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125
    },
    {
        id: 89,
        name: 'Woman 2',
        rarity: 'uncommon',
        imgPath: 'ch_common_woman_2_merida_orange_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125
    },
    {
        id: 90,
        name: 'Woman 2',
        rarity: 'uncommon',
        imgPath: 'ch_common_woman_2_mid_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125
    },
    {
        id: 91,
        name: 'Woman 2',
        rarity: 'uncommon',
        imgPath: 'ch_common_woman_2_mid_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125
    },
    {
        id: 92,
        name: 'Woman 2',
        rarity: 'uncommon',
        imgPath: 'ch_common_woman_2_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125
    },
    // u man 2
    {
        id: 93,
        name: 'U Man 2',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_man_2_bright_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 94,
        name: 'U Man 2',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_man_2_bright_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 95,
        name: 'U Man 2',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_man_2_crimson_pink_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 96,
        name: 'U Man 2',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_man_2_dark_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 97,
        name: 'U Man 2',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_man_2_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 98,
        name: 'U Man 2',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_man_2_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 99,
        name: 'U Man 2',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_man_2_dark_grey_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 100,
        name: 'U Man 2',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_man_2_light_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 101,
        name: 'U Man 2',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_man_2_light_navy_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 102,
        name: 'U Man 2',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_man_2_light_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 103,
        name: 'U Man 2',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_man_2_mid_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 104,
        name: 'U Man 2',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_man_2_mid_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    // u woman 1
    {
        id: 105,
        name: 'U Woman 1',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_woman_1_bright_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 106,
        name: 'U Woman 1',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_woman_1_bright_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 107,
        name: 'U Woman 1',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_woman_1_crimson_pink_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 108,
        name: 'U Woman 1',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_woman_1_dark_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 109,
        name: 'U Woman 1',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_woman_1_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 110,
        name: 'U Woman 1',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_woman_1_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 111,
        name: 'U Woman 1',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_woman_1_dark_grey_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 112,
        name: 'U Woman 1',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_woman_1_light_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 113,
        name: 'U Woman 1',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_woman_1_light_navy_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 114,
        name: 'U Woman 1',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_woman_1_light_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 115,
        name: 'U Woman 1',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_woman_1_mid_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    {
        id: 116,
        name: 'U Woman 1',
        rarity: 'uncommon',
        imgPath: 'ch_uncommon_woman_1_mid_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 250
    },
    // u man 1
    {
        id: 117,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_uncommon_man_1_bright_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 118,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_uncommon_man_1_bright_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 119,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_uncommon_man_1_crimson_pink_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 120,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_uncommon_man_1_dark_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 121,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_uncommon_man_1_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 122,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_uncommon_man_1_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 123,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_uncommon_man_1_dark_grey_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 124,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_uncommon_man_1_light_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 125,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_uncommon_man_1_light_navy_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 126,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_uncommon_man_1_light_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 127,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_uncommon_man_1_mid_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 128,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_uncommon_man_1_mid_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    // rare w 1
    {
        id: 129,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_rare_woman_1_ash_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 130,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_rare_woman_1_bright_red_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 131,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_rare_woman_1_brown_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 132,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_rare_woman_1_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 133,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_rare_woman_1_dark_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 134,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_rare_woman_1_golden_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 135,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_rare_woman_1_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 136,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_rare_woman_1_light_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 137,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_rare_woman_1_light_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 138,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_rare_woman_1_merida_orange_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 139,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_rare_woman_1_mid_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 140,
        name: 'U Man 1',
        rarity: 'rare',
        imgPath: 'ch_rare_woman_1_mid_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    /// U Woman 2 
    {
        id: 141,
        name: 'U Woman 2',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_2_bright_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 142,
        name: 'U Woman 2',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_2_bright_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 143,
        name: 'U Woman 2',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_2_crimson_pink_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 144,
        name: 'U Woman 2',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_2_dark_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 145,
        name: 'U Woman 2',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_2_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 146,
        name: 'U Woman 2',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_2_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 147,
        name: 'U Woman 2',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_2_dark_grey_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 148,
        name: 'U Woman 2',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_2_light_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 149,
        name: 'U Woman 2',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_2_light_navy_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 150,
        name: 'U Woman 2',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_2_light_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 151,
        name: 'U Woman 2',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_2_mid_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    {
        id: 152,
        name: 'U Woman 2',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_2_mid_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 250
    },
    // u woman 3
    {
        id: 153,
        name: 'U Woman 3',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_3_bright_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 154,
        name: 'U Woman 3',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_3_bright_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 155,
        name: 'U Woman 3',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_3_crimson_pink_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 156,
        name: 'U Woman 3',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_3_dark_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 157,
        name: 'U Woman 3',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_3_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 158,
        name: 'U Woman 3',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_3_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 159,
        name: 'U Woman 3',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_3_dark_grey_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 160,
        name: 'U Woman 3',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_3_light_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 161,
        name: 'U Woman 3',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_3_light_navy_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 162,
        name: 'U Woman 3',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_3_light_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 163,
        name: 'U Woman 3',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_3_mid_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    {
        id: 164,
        name: 'U Woman 3',
        rarity: 'rare',
        imgPath: 'ch_uncommon_woman_3_mid_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500
    },
    // R man 2
    {
        id: 165,
        name: 'R Man 2',
        rarity: 'epic',
        imgPath: 'ch_rare_man_2_ash_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 166,
        name: 'R Man 2',
        rarity: 'epic',
        imgPath: 'ch_rare_man_2_bright_red_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 167,
        name: 'R Man 2',
        rarity: 'epic',
        imgPath: 'ch_rare_man_2_brown_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 168,
        name: 'R Man 2',
        rarity: 'epic',
        imgPath: 'ch_rare_man_2_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 169,
        name: 'R Man 2',
        rarity: 'epic',
        imgPath: 'ch_rare_man_2_dark_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 170,
        name: 'R Man 2',
        rarity: 'epic',
        imgPath: 'ch_rare_man_2_golden_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 171,
        name: 'R Man 2',
        rarity: 'epic',
        imgPath: 'ch_rare_man_2_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 172,
        name: 'R Man 2',
        rarity: 'epic',
        imgPath: 'ch_rare_man_2_light_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 173,
        name: 'R Man 2',
        rarity: 'epic',
        imgPath: 'ch_rare_man_2_light_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 174,
        name: 'R Man 2',
        rarity: 'epic',
        imgPath: 'ch_rare_man_2_merida_orange_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 175,
        name: 'R Man 2',
        rarity: 'epic',
        imgPath: 'ch_rare_man_2_mid_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 176,
        name: 'R Man 2',
        rarity: 'epic',
        imgPath: 'ch_rare_man_2_mid_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    /// R Woman 2
    {
        id: 177,
        name: 'R Woman 2',
        rarity: 'epic',
        imgPath: 'ch_rare_woman_2_ash_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 178,
        name: 'R Woman 2',
        rarity: 'epic',
        imgPath: 'ch_rare_woman_2_bright_red_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 179,
        name: 'R Woman 2',
        rarity: 'epic',
        imgPath: 'ch_rare_woman_2_brown_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 180,
        name: 'R Woman 2',
        rarity: 'epic',
        imgPath: 'ch_rare_woman_2_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 181,
        name: 'R Woman 2',
        rarity: 'epic',
        imgPath: 'ch_rare_woman_2_dark_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 182,
        name: 'R Woman 2',
        rarity: 'epic',
        imgPath: 'ch_rare_woman_2_golden_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 183,
        name: 'R Woman 2',
        rarity: 'epic',
        imgPath: 'ch_rare_woman_2_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 184,
        name: 'R Woman 2',
        rarity: 'epic',
        imgPath: 'ch_rare_woman_2_light_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 185,
        name: 'R Woman 2',
        rarity: 'epic',
        imgPath: 'ch_rare_woman_2_light_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 186,
        name: 'R Woman 2',
        rarity: 'epic',
        imgPath: 'ch_rare_woman_2_merida_orange_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 187,
        name: 'R Woman 2',
        rarity: 'epic',
        imgPath: 'ch_rare_woman_2_mid_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    {
        id: 188,
        name: 'R Woman 2',
        rarity: 'epic',
        imgPath: 'ch_rare_woman_2_mid_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000
    },
    // R Man 1
    {
        id: 189,
        name: 'R Man 1',
        rarity: 'epic',
        imgPath: 'ch_rare_man_1_ash_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500
    },
    {
        id: 190,
        name: 'R Man 1',
        rarity: 'epic',
        imgPath: 'ch_rare_man_1_bright_red_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500
    },
    {
        id: 191,
        name: 'R Man 1',
        rarity: 'epic',
        imgPath: 'ch_rare_man_1_brown_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500
    },
    {
        id: 192,
        name: 'R Man 1',
        rarity: 'epic',
        imgPath: 'ch_rare_man_1_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500
    },
    {
        id: 193,
        name: 'R Man 1',
        rarity: 'epic',
        imgPath: 'ch_rare_man_1_dark_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500
    },
    {
        id: 194,
        name: 'R Man 1',
        rarity: 'epic',
        imgPath: 'ch_rare_man_1_golden_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500
    },
    {
        id: 195,
        name: 'R Man 1',
        rarity: 'epic',
        imgPath: 'ch_rare_man_1_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500
    },
    {
        id: 196,
        name: 'R Man 1',
        rarity: 'epic',
        imgPath: 'ch_rare_man_1_light_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500
    },
    {
        id: 197,
        name: 'R Man 1',
        rarity: 'epic',
        imgPath: 'ch_rare_man_1_light_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500
    },
    {
        id: 198,
        name: 'R Man 1',
        rarity: 'epic',
        imgPath: 'ch_rare_man_1_merida_orange_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500
    },
    {
        id: 199,
        name: 'R Man 1',
        rarity: 'epic',
        imgPath: 'ch_rare_man_1_mid_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500
    },
    {
        id: 200,
        name: 'R Man 1',
        rarity: 'epic',
        imgPath: 'ch_rare_man_1_mid_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500
    },
    ////
    {
        id: 201,
        name: 'E Man 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_3_ash_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 2000
    },
    {
        id: 202,
        name: 'E Man 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_3_bright_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 2000
    },
    {
        id: 203,
        name: 'E Man 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_3_bright_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 2000
    },
    {
        id: 204,
        name: 'E Man 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_3_bright_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 2000
    },
    {
        id: 205,
        name: 'E Man 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_3_crimson_pink_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 2000
    },
    {
        id: 206,
        name: 'E Man 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_3_dark_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 2000
    },
    {
        id: 207,
        name: 'E Man 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_3_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 2000
    },
    {
        id: 208,
        name: 'E Man 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_3_dark_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 2000
    },
    {
        id: 209,
        name: 'E Man 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_3_golden_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 2000
    },
    {
        id: 210,
        name: 'E Man 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_3_green_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 2000
    },
    {
        id: 211,
        name: 'E Man 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_3_light_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 2000
    },
    {
        id: 212,
        name: 'E Man 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_3_pale_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 2000
    },
    // E man 2
    {
        id: 213,
        name: 'E Man 2',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_2_ash_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000
    },
    {
        id: 214,
        name: 'E Man 2',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_2_bright_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000
    },
    {
        id: 215,
        name: 'E Man 2',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_2_bright_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000
    },
    {
        id: 216,
        name: 'E Man 2',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_2_bright_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000
    },
    {
        id: 217,
        name: 'E Man 2',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_2_crimson_pink_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000
    },
    {
        id: 218,
        name: 'E Man 2',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_2_dark_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000
    },
    {
        id: 219,
        name: 'E Man 2',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_2_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000
    },
    {
        id: 220,
        name: 'E Man 2',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_2_dark_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000
    },
    {
        id: 221,
        name: 'E Man 2',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_2_golden_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000
    },
    {
        id: 222,
        name: 'E Man 2',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_2_green_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000
    },
    {
        id: 223,
        name: 'E Man 2',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_2_light_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000
    },
    {
        id: 224,
        name: 'E Man 2',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_2_pale_green_128.png',
        unlockRequirement: 'challenge'
    },
    {
        id: 225,
        name: 'Beta 1',
        rarity: 'legendary',
        imgPath: 'ch_uncommon_man_3_dark_crimson_128.png',
        exclusive: true,
        // unlockRequirement: 'challenge'
    },
    {
        id: 226,
        name: 'Beta 2',
        rarity: 'legendary',
        imgPath: 'ch_uncommon_man_3_bright_crimson_128.png',
        exclusive: true,
        // unlockRequirement: 'challenge'
    },
    {
        id: 227,
        name: 'Beta 3',
        rarity: 'legendary',
        imgPath: 'ch_uncommon_man_3_mid_cyan_128.png',
        exclusive: true,
        // unlockRequirement: 'challenge'
    },
    {
        id: 228,
        name: 'Skull Man',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_1_blue_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 229,
        name: 'Skull Man 2',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_1_bright_green_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 230,
        name: 'Skull Man 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_1_crimson_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 231,
        name: 'Skull Man 4',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_1_dull_green_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 232,
        name: 'Skull Man 5',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_1_grey_green_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 233,
        name: 'Skull Man 6',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_1_grey_yellow_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 234,
        name: 'Skull Man 7',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_1_mid_green_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 235,
        name: 'Skull Man 8',
        rarity: 'legendary',
        imgPath: 'ch_epic_man_1_red_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 236,
        name: 'Skull Woman 1',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_1_blue_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 237,
        name: 'Skull Woman 2',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_1_bright_green_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 238,
        name: 'Skull Woman 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_1_crimson_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 239,
        name: 'Skull Woman 4',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_1_dull_green_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 240,
        name: 'Skull Woman 5',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_1_grey_blue_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 241,
        name: 'Skull Woman 6',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_1_grey_green_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 242,
        name: 'Skull Woman 7',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_1_mid_green_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 243,
        name: 'Skull Woman 8',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_1_pale_yellow_128.png',
        // unlockRequirement: 'shop',
        // currencyType: 'gems',
        // price: 1000,
        event: 'halloween'
    },
    {
        id: 244,
        name: 'Elf Woman',
        rarity: 'rare',
        imgPath: 'ch_og_elf_woman_1_dark_ash_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500,
    },
    {
        id: 245,
        name: 'Elf Woman',
        rarity: 'rare',
        imgPath: 'ch_og_elf_woman_1_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500,
    },
    {
        id: 246,
        name: 'Elf Woman',
        rarity: 'rare',
        imgPath: 'ch_og_elf_woman_1_dark_red_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500,
    },
    {
        id: 247,
        name: 'Elf Woman',
        rarity: 'rare',
        imgPath: 'ch_og_elf_woman_1_golden_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500,
    },
    {
        id: 248,
        name: 'Elf Woman',
        rarity: 'rare',
        imgPath: 'ch_og_elf_woman_1_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500,
    },
    {
        id: 249,
        name: 'Elf Woman',
        rarity: 'rare',
        imgPath: 'ch_og_elf_woman_1_light_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500,
    },
    {
        id: 250,
        name: 'Elf Woman',
        rarity: 'rare',
        imgPath: 'ch_og_elf_woman_1_light_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500,
    },
    {
        id: 251,
        name: 'Elf Woman',
        rarity: 'rare',
        imgPath: 'ch_og_elf_woman_1_light_navy_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500,
    },
    {
        id: 252,
        name: 'Elf Woman',
        rarity: 'rare',
        imgPath: 'ch_og_elf_woman_1_mid_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500,
    },
    {
        id: 253,
        name: 'Elf Woman',
        rarity: 'rare',
        imgPath: 'ch_og_elf_woman_1_mid_grey_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500,
    },
    {
        id: 254,
        name: 'Elf Woman',
        rarity: 'rare',
        imgPath: 'ch_og_elf_woman_1_pale_yellow_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500,
    },
    {
        id: 255,
        name: 'Elf Woman',
        rarity: 'rare',
        imgPath: 'ch_og_elf_woman_1_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 500,
    },
    {
        id: 256,
        name: 'Elf Man',
        rarity: 'uncommon',
        imgPath: 'ch_og_elf_man_1_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125,
    },
    {
        id: 257,
        name: 'Elf Man',
        rarity: 'uncommon',
        imgPath: 'ch_og_elf_man_1_dark_red_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125,
    },
    {
        id: 258,
        name: 'Elf Man',
        rarity: 'uncommon',
        imgPath: 'ch_og_elf_man_1_golden_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125,
    },
    {
        id: 259,
        name: 'Elf Man',
        rarity: 'uncommon',
        imgPath: 'ch_og_elf_man_1_light_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125,
    },
    {
        id: 260,
        name: 'Elf Man',
        rarity: 'uncommon',
        imgPath: 'ch_og_elf_man_1_light_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125,
    },
    {
        id: 261,
        name: 'Elf Man',
        rarity: 'uncommon',
        imgPath: 'ch_og_elf_man_1_light_navy_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125,
    },
    {
        id: 262,
        name: 'Elf Man',
        rarity: 'uncommon',
        imgPath: 'ch_og_elf_man_1_mid_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125,
    },
    {
        id: 263,
        name: 'Elf Man',
        rarity: 'uncommon',
        imgPath: 'ch_og_elf_man_1_mid_grey_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125,
    },
    {
        id: 264,
        name: 'Elf Man',
        rarity: 'uncommon',
        imgPath: 'ch_og_elf_man_1_pale_yellow_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125,
    },
    {
        id: 265,
        name: 'Elf Man',
        rarity: 'uncommon',
        imgPath: 'ch_og_elf_man_1_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 125,
    },
    {
        id: 266,
        name: 'Elf Woman',
        rarity: 'epic',
        imgPath: 'ch_elf_woman_1_dark_ash_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500,
    },
    {
        id: 267,
        name: 'Elf Woman',
        rarity: 'epic',
        imgPath: 'ch_elf_woman_1_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500,
    },
    {
        id: 268,
        name: 'Elf Woman',
        rarity: 'epic',
        imgPath: 'ch_elf_woman_1_dark_red_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500,
    },
    {
        id: 269,
        name: 'Elf Woman',
        rarity: 'epic',
        imgPath: 'ch_elf_woman_1_golden_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500,
    },
    {
        id: 270,
        name: 'Elf Woman',
        rarity: 'epic',
        imgPath: 'ch_elf_woman_1_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500,
    },
    {
        id: 271,
        name: 'Elf Woman',
        rarity: 'epic',
        imgPath: 'ch_elf_woman_1_light_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500,
    },
    {
        id: 272,
        name: 'Elf Woman',
        rarity: 'epic',
        imgPath: 'ch_elf_woman_1_light_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500,
    },
    {
        id: 273,
        name: 'Elf Woman',
        rarity: 'epic',
        imgPath: 'ch_elf_woman_1_light_navy_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500,
    },
    {
        id: 274,
        name: 'Elf Woman',
        rarity: 'epic',
        imgPath: 'ch_elf_woman_1_mid_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500,
    },
    {
        id: 275,
        name: 'Elf Woman',
        rarity: 'epic',
        imgPath: 'ch_elf_woman_1_mid_grey_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500,
    },
    {
        id: 276,
        name: 'Elf Woman',
        rarity: 'epic',
        imgPath: 'ch_elf_woman_1_pale_yellow_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500,
    },
    {
        id: 277,
        name: 'Elf Woman',
        rarity: 'epic',
        imgPath: 'ch_elf_woman_1_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 500,
    },
    {
        id: 278,
        name: 'Elf Man 2',
        rarity: 'epic',
        imgPath: 'ch_elf_man_1_dark_ash_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000,
    },
    {
        id: 279,
        name: 'Elf Man 2',
        rarity: 'epic',
        imgPath: 'ch_elf_man_1_dark_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000,
    },
    {
        id: 280,
        name: 'Elf Man 2',
        rarity: 'epic',
        imgPath: 'ch_elf_man_1_dark_red_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000,
    },
    {
        id: 281,
        name: 'Elf Man 2',
        rarity: 'epic',
        imgPath: 'ch_elf_man_1_golden_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000,
    },
    {
        id: 282,
        name: 'Elf Man 2',
        rarity: 'epic',
        imgPath: 'ch_elf_man_1_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000,
    },
    {
        id: 283,
        name: 'Elf Man 2',
        rarity: 'epic',
        imgPath: 'ch_elf_man_1_light_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000,
    },
    {
        id: 284,
        name: 'Elf Man 2',
        rarity: 'epic',
        imgPath: 'ch_elf_man_1_light_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000,
    },
    {
        id: 285,
        name: 'Elf Man 2',
        rarity: 'epic',
        imgPath: 'ch_elf_man_1_light_navy_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000,
    },
    {
        id: 286,
        name: 'Elf Man 2',
        rarity: 'epic',
        imgPath: 'ch_elf_man_1_mid_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000,
    },
    {
        id: 287,
        name: 'Elf Man 2',
        rarity: 'epic',
        imgPath: 'ch_elf_man_1_mid_grey_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000,
    },
    {
        id: 288,
        name: 'Elf Man 2',
        rarity: 'epic',
        imgPath: 'ch_elf_man_1_pale_yellow_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000,
    },
    {
        id: 289,
        name: 'Elf Man 2',
        rarity: 'epic',
        imgPath: 'ch_elf_man_1_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'coins',
        price: 1000,
    },
    {
        id: 290,
        name: 'Elf Woman 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_2_ash_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 291,
        name: 'Elf Woman 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_2_bright_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 292,
        name: 'Elf Woman 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_2_bright_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 293,
        name: 'Elf Woman 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_2_bright_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 294,
        name: 'Elf Woman 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_2_crimson_pink_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 295,
        name: 'Elf Woman 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_2_dark_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 296,
        name: 'Elf Woman 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_2_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 297,
        name: 'Elf Woman 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_2_dark_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 298,
        name: 'Elf Woman 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_2_golden_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 299,
        name: 'Elf Woman 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_2_green_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 300,
        name: 'Elf Woman 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_2_light_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 301,
        name: 'Elf Woman 3',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_2_pale_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 302,
        name: 'Elf Woman 4',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_3_ash_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 303,
        name: 'Elf Woman 4',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_3_bright_cyan_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 304,
        name: 'Elf Woman 4',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_3_bright_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 305,
        name: 'Elf Woman 4',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_3_bright_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 306,
        name: 'Elf Woman 4',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_3_crimson_pink_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 307,
        name: 'Elf Woman 4',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_3_dark_crimson_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 308,
        name: 'Elf Woman 4',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_3_dark_grey_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 309,
        name: 'Elf Woman 4',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_3_dark_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 310,
        name: 'Elf Woman 4',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_3_golden_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 311,
        name: 'Elf Woman 4',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_3_green_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 312,
        name: 'Elf Woman 4',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_3_light_purple_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 313,
        name: 'Elf Woman 4',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_3_pale_green_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    {
        id: 314,
        name: 'Elf Woman 4',
        rarity: 'legendary',
        imgPath: 'ch_epic_woman_3_ash_blue_128.png',
        unlockRequirement: 'shop',
        currencyType: 'gems',
        price: 1000,
    },
    // {
    // 	id: 244,
    // 	name: 'Elf  Woman',
    // 	rarity: 'rare',
    // 	imgPath: 'ch_elf_woman_1_dark_ash_blue_128.png',
    // 	unlockRequirement: 'shop',
    // 	currencyType: 'gems',
    // 	price: 1000,
    // 	event: 'halloween'
    // },
    // {
    //     id: 3,
    //     name: 'Human Man',
    //     refName: 'tiefling_head_man_blue_256.png',
    //     rarity: 'common',
    //     imgPath: 'tiefling_head_man_blue_256.png',
    // },
    // {
    //     id: 4,
    //     name: 'Human Man',
    //     refName: 'tiefling_head_man_blue_128.png',
    //     rarity: 'common',
    //     imgPath: 'tiefling_head_man_blue_128.png',
    // },
    // {
    //     id: 4,
    //     name: 'Human Woman',
    //     refName: 'head_human_f.png',
    //     rarity: 'common',
    //     imgPath: 'head_human_f.png',
    // },
    // {
    //     id: 3,
    //     name: 'Human Man',
    //     refName: 'head_human_m.png',
    //     rarity: 'common',
    //     imgPath: 'head_human_m.png',
    // },
    // {
    //     id: 4,
    //     name: 'Human Woman',
    //     refName: 'head_human_f.png',
    //     rarity: 'common',
    //     imgPath: 'head_human_f.png',
    // },
    // {
    //     id: 5,
    //     name: 'Elf Man',
    //     refName: 'head_elf_m.png',
    //     rarity: 'epic',
    //     imgPath: 'head_elf_m.png',
    //     unlockRequirement: 'shop'
    // },
    // {
    //     id: 6,
    //     name: 'Elf Woman',
    //     refName: 'head_elf_f.png',
    //     rarity: 'epic',
    //     imgPath: 'head_elf_f.png',
    //     unlockRequirement: 'challenge'
    // },
    // {
    //     id: 1,
    //     name: 'Old Man',
    //     refName: 'head_oldman.png',
    //     rarity: 'legendary',
    //     imgPath: 'head_oldman.png',
    //     unlockRequirement: 'challenge'
    // },
    // {
    //     id: 2,
    //     name: 'Rogue',
    //     refName: 'head_rogue.png',
    //     rarity: 'legendary',
    //     imgPath: 'head_rogue.png',
    //     unlockRequirement: 'challenge'
    // },
    // {
    //     id: 7,
    //     name: 'Rogue',
    //     refName: 'ch_rare_man_2_1_128.png',
    //     rarity: 'rare',
    //     imgPath: 'ch_rare_man_2_1_128.png',
    //     unlockRequirement: 'shop',
    //     currencyType: 'coins',
    //     price: 500
    // },
    // {
    //     id: 8,
    //     name: 'Rogue',
    //     refName: 'ch_rare_man_2_2_128.png',
    //     rarity: 'rare',
    //     imgPath: 'ch_rare_man_2_2_128.png',
    //     unlockRequirement: 'shop',
    //     currencyType: 'coins',
    //     price: 500
    // },
    // {
    //     id: 9,
    //     name: 'Rogue',
    //     refName: 'ch_rare_man_2_3_128.png',
    //     rarity: 'rare',
    //     imgPath: 'ch_rare_man_2_3_128.png',
    //     unlockRequirement: 'shop',
    //     currencyType: 'coins',
    //     price: 500
    // },
    // {
    //     id: 10,
    //     name: 'Rogue',
    //     refName: 'ch_rare_man_2_4_128.png',
    //     rarity: 'rare',
    //     imgPath: 'ch_rare_man_2_4_128.png',
    //     unlockRequirement: 'shop',
    //     currencyType: 'coins',
    //     price: 500
    // },
    // {
    //     id: 11,
    //     name: 'Rogue',
    //     refName: 'ch_rare_man_2_5_128.png',
    //     rarity: 'rare',
    //     imgPath: 'ch_rare_man_2_5_128.png',
    //     unlockRequirement: 'shop',
    //     currencyType: 'coins',
    //     price: 500
    // },
    // {
    //     id: 12,
    //     name: 'Rogue',
    //     refName: 'ch_rare_man_2_6_128.png',
    //     rarity: 'rare',
    //     imgPath: 'ch_rare_man_2_6_128.png',
    //     unlockRequirement: 'shop',
    //     currencyType: 'coins',
    //     price: 500
    // },
    // {
    //     id: 13,
    //     name: 'Rogue',
    //     refName: 'ch_rare_man_2_7_128.png',
    //     rarity: 'rare',
    //     imgPath: 'ch_rare_man_2_7_128.png',
    //     unlockRequirement: 'shop',
    //     currencyType: 'coins',
    //     price: 500
    // },
    // {
    //     id: 14,
    //     name: 'Rogue',
    //     refName: 'ch_rare_man_2_8_128.png',
    //     rarity: 'rare',
    //     imgPath: 'ch_rare_man_2_8_128.png',
    //     unlockRequirement: 'shop',
    //     currencyType: 'coins',
    //     price: 500
    // },
    // {
    //     id: 15,
    //     name: 'Rogue',
    //     refName: 'ch_uncommon_woman_3_1_128.png',
    //     rarity: 'uncommon',
    //     imgPath: 'ch_uncommon_woman_3_1_128.png',
    //     unlockRequirement: 'shop',
    //     currencyType: 'coins',
    //     price: 250,
    // },
];
