import React, { Component } from 'react'
import { ButtonBase } from '@mui/material'
import { Grid } from '@mui/material'
import { ChestsData } from '../../shared/chests'
import ChestPopup from './ChestPopup.js'

class ChestButton extends Component {
    constructor() {
        super()
        this.state = {
            fillColor: '#E7CAB2',
            outerBorderColor: '#362D44',
            innerBorderColor: '#FFF1D2',
            imgScale: 'scale(1)',
            config: {
                idle: {
                    fillColor: '#FFD522',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#F79E3C',
                    imgScale: 'scale(.9)'
                },
                hover: {
                    fillColor: '#FEFF69',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#FFD522',
                    imgScale: 'scale(1)'
                },
                down: {
                    fillColor: '#F49540',
                    outerBorderColor: '#362D44',
                    innerBorderColor: '#D66C16',
                    imgScale: 'scale(1)'
                },
            },
            dialogAction: 0
        }
    }

    componentDidMount() {
        // let { config } = this.state
        let config
        if (this.props.config) {
            config = this.props.config
        } else {
            config = this.state.config
        }
        this.setState({
            config: config,
            fillColor: config.idle.fillColor,
            outerBorderColor: config.idle.outerBorderColor,
            innerBorderColor: config.idle.innerBorderColor
        })
    }

    render() {
        let { fillColor, innerBorderColor, outerBorderColor, imgScale, config, dialogAction } = this.state
        let { token, guestAccount, ChestId, buttonFunction, title, textColor, Text1, iconSize, ImgSource, Background, SpecialText, imagePaddingHor, imagePaddingVert, imageSize, fontSize, showSpecialText, priceLocation, SpecialTextBackground, accountItems } = this.props

        let chestData = ChestsData.find(chest => chest.id == ChestId)
        let currencyPath
        if (chestData.currencyType == 'coins') {
            currencyPath = 'assets/ui/rune.png'
        } else if (chestData.currencyType == 'gems') {
            currencyPath = 'assets/ui/glyph.png'
        }

        if (!textColor) { textColor = 'white' }
        return <>
            <ChestPopup
                dialogAction={dialogAction}
                ChestName={Text1}
                // Price={Text2}
                // CurrencyType={CurrencyType}
                ImgSource={ImgSource}
                ChestId={ChestId}
                token={token}
                guestAccount={guestAccount}
                accountItems={accountItems}
            />
            <Grid container
                align="center" justifyContent="center" alignItems="center"
                xs={12}>
                <ButtonBase
                    style={
                        {
                            fontSize: '2vh',
                            borderRadius: '6px',
                            borderWidth: '2px', borderStyle: 'solid',
                            // background: Background,

                            background: fillColor,

                            borderColor: innerBorderColor,
                            boxShadow: `0 0 0 2px ${outerBorderColor}`,
                            textTransform: 'none',

                            marginTop: '10px',
                            fontSize: '2vh',
                            // margin: '5%',



                            position: 'relative',
                            // width: '260px'
                            // width: '300px'
                            width: '275px'
                        }
                    }

                    variant="contained"

                    onMouseOver={() => {
                        this.setState({
                            fillColor: config.hover.fillColor,
                            outerBorderColor: config.hover.outerBorderColor,
                            innerBorderColor: config.hover.innerBorderColor,
                            imgScale: config.hover.imgScale
                        })
                    }}
                    onMouseOut={() => {
                        this.setState({
                            fillColor: config.idle.fillColor,
                            outerBorderColor: config.idle.outerBorderColor,
                            innerBorderColor: config.idle.innerBorderColor,
                            imgScale: config.idle.imgScale
                        })
                    }
                    }
                    onMouseDown={(e) => {
                        this.setState({
                            fillColor: config.down.fillColor,
                            outerBorderColor: config.down.outerBorderColor,
                            innerBorderColor: config.down.innerBorderColor,
                            imgScale: config.down.imgScale
                        })
                    }}

                    onClick={(e) => {
                        e.nativeEvent.stopImmediatePropagation()
                        e.preventDefault()
                        e.stopPropagation()


                        this.setState({ dialogAction: dialogAction + 1 })

                        // buttonFunction()
                    }}
                >
               
                    <img
                        style={{
                            // padding: '35px',
                            // padding: imagePadding,
                            paddingLeft: imagePaddingHor,
                            paddingRight: imagePaddingHor,
                            paddingTop: imagePaddingVert,
                            paddingBottom: imagePaddingVert,

                            transform: imgScale,
                            pointerEvents: 'none',
                            height: imageSize,
                            width: imageSize,

                        }}
                        src={ImgSource}
                    />

                    <b
                        style={{
                            // fontSize: '25px',
                            fontSize: fontSize,
                            position: 'absolute',
                            top: '3%',
                            color: 'white',
                        }}
                    >
                        {Text1}
                    </b>
                    {SpecialText && showSpecialText &&
                        // <Grid xs={12}>
                        <div
                            style={{
                                // fontSize: '23px',
                                fontSize: fontSize,
                                position: 'absolute',
                                // top: '76%',
                                // top: '73%',
                                top: '72%',
                                color: 'white',
                                borderRadius: '6px',
                                borderWidth: '2px', borderStyle: 'solid',
                                borderColor: 'black',
                                // backgroundColor: SpecialTextBackground,
                                backgroundColor: fillColor,
                                // width: '110%',
                                width: '80%',
                                paddingBottom: '4px',
                                paddingTop: '4px'
                            }}
                        >
                            {SpecialText}
                        </div>
                        // </Grid>
                    }
                    {/* <div
                        style={{
                            fontSize: fontSize,
                            position: 'absolute',
                            top: priceLocation,
                            color: 'white'
                        }}
                    >
                        {Text2}
                    </div> */}
                    <b
                        style={{
                            //  position: 'relative',
                            fontSize: fontSize,
                            position: 'absolute',
                            top: priceLocation,
                            color: 'white',
                            transform: 'translate(-50%)'


                        }}
                    > {chestData.price}
                        <img style={{
                            position: 'absolute',
                            // width: '33px',
                            width: iconSize,
                            height: iconSize,
                            // left: '4.5%',
                            // right: '-63%',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            pointerEvents: 'none',
                            margin: 'auto',
                            zIndex: 10,
                        }}
                            src={currencyPath}

                        />
                    </b>
                </ButtonBase >
            </Grid>
        </>;
    }
}

export default (ChestButton)