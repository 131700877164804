import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'
import {updateTrialVictory } from '../actions/actions'

import { claimTrial } from '../networking.js'
import Constants from '../../shared/constants'
import {bigScreenBorderProperties, colorPalette} from './uiStyles.js'
import ColorChangingButton from './ColorChangingButton.js'


import BannerAd from './BannerAd.js'
import ColorTable from "../../shared/colorTable";
import { cmds } from '../sdkIntegration'
import BottomBannerAd from './ads/BottomBannerAd.js'

function timeUnits(ms) {
    if (!Number.isInteger(ms)) {
        return null
    }
    /**
     * Takes as many whole units from the time pool (ms) as possible
     * @param {int} msUnit - Size of a single unit in milliseconds
     * @return {int} Number of units taken from the time pool
     */
    const allocate = msUnit => {
        const units = Math.trunc(ms / msUnit)
        ms -= units * msUnit
        return units
    }
    // Property order is important here.
    // These arguments are the respective units in ms.
    return {
        // weeks: (604800000), // Uncomment for weeks
        days: allocate(86400000),
        hours: allocate(3600000),
        minutes: allocate(60000),
        seconds: allocate(1000),
        ms: ms // remainder
    }
}


class TrialVictoryMenu extends Component {
    constructor() {
        super()
        this.state = {
            buttonPath: 'btn_play_again_idle.png',
            rarityInfo: ColorTable.rarityInfo,
            manaColorTable: ColorTable.elementHex,
        }
    }


    componentDidMount() {
        if (window.aiptag && !window.aiptag.adplayer) {
            console.log('LOADING AD PLAYER')
            window.aiptag.cmd.player.push(function () {
                aiptag.adplayer = new aipPlayer({
                    AD_WIDTH: 960,
                    AD_HEIGHT: 540,
                    AD_FULLSCREEN: false,
                    AD_CENTERPLAYER: false,
                    LOADING_TEXT: 'loading advertisement',
                    PREROLL_ELEM: function () { return document.getElementById('preroll') },
                    AIP_COMPLETE: function (evt) {
                        /*******************
                         ***** WARNING *****
                         *******************
                         Please do not remove the PREROLL_ELEM
                         from the page, it will be hidden automaticly.
                         If you do want to remove it use the AIP_REMOVE callback.
                        */
                        console.log('Preroll Ad Completed: ' + evt)
                    },
                    AIP_REMOVE: function () {
                        // Here it's safe to remove the PREROLL_ELEM from the page if you want. But it's not recommend.
                    },
                    AIP_REWARDEDCOMPLETE: function (evt) {
                        //evt can be: timeout, empty or closed
                        console.log('Rewarded Ad Completed: ' + evt)
                    },
                    AIP_REWARDEDGRANTED: function () {
                        console.log('Reward Granted')
                    }
                })
            })
        }


        // let { token, guestAccount } = this.props
        // setTimeout(() => { updateAccountData(token, guestAccount) }, 500)

        // if (typeof sdk !== 'undefined' && sdk.showBanner !== 'undefined') {
        //     sdk.showBanner();
        // }
    }

    componentDidUpdate(prevProps) {
        const { playerDied } = this.props
        if (prevProps.playerDied != playerDied) {
            console.log('PLAYER DIED DETECTED')
            // setTimeout(updateAccountData, 2000)

            // this.componentDidMount()
        }
    }

    render() {
        let { trialVictory, token, adblock, width, height, guestAccount, renderBottomAdBanner } = this.props
        let { buttonPath, rarityInfo, manaColorTable } = this.state

        // let trialData = TrialsData.find(trial => trial.id == trialVictory.id)
        console.log('trialVictory data', trialVictory)

      
        let trialData = trialVictory.data
        let rewardButtonMessage = trialVictory.rewardButtonMessage
        let buttonSize = 12
        if (rewardButtonMessage) {
            buttonSize = 6
        }

        let victoryMessage =  `Congratulations on completing ${trialData.name}!` 
        if (trialVictory.customMessage) {
            victoryMessage = trialVictory.customMessage
        }
        let timeDict = timeUnits(trialVictory.completionTime)

        let timeDisplay

        if (timeDict.hours) {
            timeDisplay = `${timeDict.hours}'${timeDict.minutes}'${timeDict.seconds}'${timeDict.ms}`
        } else if (timeDict.minutes) {
            timeDisplay = `${timeDict.minutes}'${timeDict.seconds}'${timeDict.ms}`
        } else if (timeDict.seconds) {
            timeDisplay = `${timeDict.seconds}'${timeDict.ms}`
        } else {
            timeDisplay = `0'${timeDict.ms}`
        }
        

        return (
            <div>
                <Grid container spacing={0} justifyContent="center" style={{ width: '100', margin: 'auto' }}>

                    <Grid container align='flex-end' justifyContent="center" alignItems="flex-end" direction="column"
                        // xs={12}
                        // lg={2}
                        xs={4}

                        // xs={3}
                        style={{
                            minHeight: '100%',
                            paddingRight: '20px'
                                                    }}>
                        {/* <Hidden only={['xs', 'sm', 'md']}> */}
                        <BannerAd size='small' adId='mageclash-io_300x250_1' width={300} height={250} adIdCrazyGames='trial1' bannerWidth='300px' bannerHeight='250px' />
                        {/* </Hidden> */}
                        {width >= 1280 &&

                            <></>

                        }
                    </Grid>

                    <Grid item
                        xs={4}
                    // xs={6}
                    // lg={4}
                    // xs={6}
                    >
                        <Paper style={Object.assign({
                            userSelect: 'none', textAlign: 'center',
                            background: colorPalette.primary,
                            marginLeft: '1%', marginRight: '1%',
                            paddingLeft: '10%', paddingRight: '10%',
                                borderColor: colorPalette.primaryBorder,
                        },
                            bigScreenBorderProperties
                        )}>
                            <Grid container>

                                <Grid xs={12}>
                                    {/* {!trialVictory &&
                                        <b style={{ color: '#FF3B53', fontSize: '6vh' }}>YOU DIED!</b>
                                    } */}
                                    {/* {trialVictory && */}
                                    <b style={{ color: '#FF3B53', fontSize: '6vh' }}>TRIAL COMPLETE!</b>
                                    {/* } */}

                                </Grid>


                                <Grid xs={12} >
                                    <Paper style={{
                                        background: '#E7CAB2',

                                        borderRadius: '8px',
                                        borderWidth: '2px', borderStyle: 'solid',
                                        borderColor: '£362D44',
                                        boxShadow: '0 0 0 2px #FFFFFF',
                                        marginBottom: '2.5%'

                                    }}>
                                            <Grid xs={12}>
                                                {/* <h3 style={{ textAlign: 'center' }}> Congratulations on completing {trialData.name}! </h3> */}
                                                <h3 style={{ textAlign: 'center' }}> {victoryMessage} </h3>
                                              
                                            </Grid>
                                    </Paper>
                                </Grid>

                                <Grid xs={12} >
                                    <Paper style={{
                                        background: '#E7CAB2',

                                        borderRadius: '8px',
                                        borderWidth: '2px', borderStyle: 'solid',
                                        borderColor: '£362D44',
                                        boxShadow: '0 0 0 2px #FFFFFF',
                                        marginBottom: '2.5%'

                                    }}>
                                            <Grid xs={12}>
                                                <h3 style={{ textAlign: 'center' }}> Score Earned: {trialData.score.toLocaleString()} </h3>
                                            <h3 style={{ textAlign: 'center' }}> Completion Time: {timeDisplay} </h3>

                                            </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                          

                            <Grid container align='center' justifyContent="center" alignItems="center" 
                            // direction="column"
                            direction="row"
                                xs={12}
                                // lg={3}
                                // xs={3}
                                style={{
                                    minHeight: '100%',
                                    //  padding: '1%'
                                }}
                            >

                                <Grid container 
                                align='center' justifyContent="center" alignItems="center" 
                                style={{
                                    paddingBottom: '20px',
                                    // paddingTop: '20px' 
                                }} xs={buttonSize}>


                                    <ColorChangingButton buttonFunction={() => { 
                                         let actions = () => {
                                            claimTrial(1)
                                            setTimeout(
                                                () => {updateTrialVictory(null)},
                                                100)
                                         }
                                         cmds.playPreroll(actions)
                                    }}

                                    countdown={3}
                                        config={
                                            {
                                                idle: {
                                                    fillColor: '#E7CAB2',
                                                    outerBorderColor: '#362D44',
                                                    innerBorderColor: '#FFF1D2',
                                                },
                                                hover: {
                                                    fillColor: '#FFC897',
                                                    outerBorderColor: '#362D44',
                                                    innerBorderColor: '#FFF1D2',
                                                },
                                                down: {
                                                    fillColor: '#C19272',
                                                    outerBorderColor: '#362D44',
                                                    innerBorderColor: '#9B7871',
                                                },
                                            }}
                                        // title={`Collect ${trialData.score.toLocaleString()} score`}
                                        // title={`Collect Reward`}
                                        title={'Continue'}
                                        buttonStyling={{
                                            color: '#b06c66',
                                            // width: '35%',
                                            fontSize: '2vh',
                                        }}
                                        textColor='#b06c66'
                                    />
                                </Grid>
                                {rewardButtonMessage && typeof window.CrazyGames !== 'undefined' &&
                                <Grid container 
                                align='center' justifyContent="center" alignItems="center" 
                                style={{
                                    paddingBottom: '20px',
                                    // paddingTop: '20px' 
                                }} xs={buttonSize}>


                                    <ColorChangingButton buttonFunction={() => { 
                                         let actions = () => {
                                            claimTrial(2)
                                            setTimeout(
                                                () => {updateTrialVictory(null)},
                                                100)
                                         }
                                         cmds.playRewarded(actions)
                                    }}

                                    countdown={0}
                                        config={
                                            {
                                                idle: {
                                                    fillColor: '#E7CAB2',
                                                    outerBorderColor: '#362D44',
                                                    innerBorderColor: '#FFF1D2',
                                                },
                                                hover: {
                                                    fillColor: '#FFC897',
                                                    outerBorderColor: '#362D44',
                                                    innerBorderColor: '#FFF1D2',
                                                },
                                                down: {
                                                    fillColor: '#C19272',
                                                    outerBorderColor: '#362D44',
                                                    innerBorderColor: '#9B7871',
                                                },
                                            }}
                                        // title={`Collect ${trialData.score.toLocaleString()} score`}
                                        // title={`Collect Reward`}
                                        title={rewardButtonMessage}
                                        buttonStyling={{
                                            color: '#b06c66',
                                            // width: '35%',
                                            fontSize: '2vh',
                                        }}
                                        textColor='#b06c66'
                                        image='assets/icons/play-button.png'
                                    />
                                </Grid>
                                    }

{/* 
                                <Grid style={{
                                    paddingBottom: '20px',
                                }} xs={12}>
                                    <ColorChangingButton buttonFunction={() => { 
                                        if (typeof window.aiptag.adplayer !== 'undefined') {
                                            window.aiptag.cmd.player.push(function () { window.aiptag.adplayer.startRewardedAd(); });
                                        } else {
                                            //Adlib didnt load this could be due to an adblocker, timeout etc.
                                            //Please add your script here that starts the content, this usually is the same script as added in AIP_REWARDEDCOMPLETE.
                                            console.log("Rewarded Ad Could not be loaded, load your content here");
                                        }
                                    }
                                    }
                                        config={
                                            {
                                                idle: {
                                                    fillColor: '#E7CAB2',
                                                    outerBorderColor: '#362D44',
                                                    innerBorderColor: '#FFF1D2',
                                                },
                                                hover: {
                                                    fillColor: '#FFC897',
                                                    outerBorderColor: '#362D44',
                                                    innerBorderColor: '#FFF1D2',
                                                },
                                                down: {
                                                    fillColor: '#C19272',
                                                    outerBorderColor: '#362D44',
                                                    innerBorderColor: '#9B7871',
                                                },
                                            }}
                                        title="Double your reward!"
                                        buttonStyling={{
                                            color: '#b06c66',
                                            // width: '35%',
                                            fontSize: '2vh',
                                        }}
                                        textColor='#b06c66'
                                    />
                                </Grid> */}

                            </Grid>
                        </Paper>
                    </Grid>


                    <Grid xs={4}></Grid>

                </Grid >

            



                <Grid item align='center' justifyContent="center" alignItems="center" direction="column" xs={12} style={{
                    //  padding: '2%',
                    paddingTop: '0.35%',
                    minHeight: '100%'
                }}>

                    {/* <BannerAd size='large' adId='mageclash-io_970x250' width={728} height={90} adIdCrazyGames='trial2' bannerWidth='970px' bannerHeight='250px'/> */}
                    <BottomBannerAd adName='gamesPage' width={width} fake={!renderBottomAdBanner}/>
                    {width >= 1280 &&

                        <></>
                    }
                </Grid>



            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        adblock: state.stuff.adblock,
        width: state.stuff.width,
        height: state.stuff.height,
        token: state.stuff.token,
        guestAccount: state.stuff.guestAccount,
        trialVictory: state.stuff.trialVictory
    }
}

export default connect(
    mapStateToProps,
)(TrialVictoryMenu)