import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import { cmds } from '../sdkIntegration'
class BannerAd extends Component {

    componentDidMount() {
        console.log('mounting ad banner', this.props)
        if (!this.props.sdkLoaded) {
            console.log('mounted ad banner but no sdk loaded')
            return
        }
        let bannerType
        if (typeof window.CrazyGames !== 'undefined') {
            if (!this.props.adblock) {
                bannerType = 'crazyGames'
                this.requestCGBanner()
                this.cgTimeoutFunc()
            } else {
                console.log('adblock detected, not requesting cg banner')
            }
        } else {
            bannerType = 'aip'
            if (!window.aiptag) {
                console.log('no window aip tag')
                return
            }
            window.aiptag.cmd.display.push(() => {
                window.aipDisplayTag.display(this.props.adId)
            })
        }
    }
    cgTimeoutFunc() {
        this.timeoutId = setTimeout(() => {
            if (!this.unmounted) {
                console.log('requesting new banner after timeout')
                this.requestCGBanner();
                this.cgTimeoutFunc()
            }
            },
             60000
            // 1000
             ); 

    }
    requestCGBanner() {
        console.log('requesting crazy games banner')
        cmds.requestNewBanners([
            {
                divId: this.props.adIdCrazyGames,
                width: this.props.width,
                height: this.props.height
            },
        ])
    }

    componentWillUnmount() {
        this.unmounted = true;
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        if (typeof window.CrazyGames !== 'undefined' && !this.props.adblock) {
            // window.CrazyGames.SDK.banner.clearAllBanners();
            window.CrazyGames.SDK.banner.clearBanner(this.props.adIdCrazyGames);
        }
    }

    render() {
        let { adblock, referrer, sdkLoaded, 
            // fowardedRef
         } = this.props
        let bg = ''

        let adblockUrl
        if (this.props.size == 'small') {
            adblockUrl = 'assets/ad.png'
        } else if (this.props.size == 'large') {
            adblockUrl = 'assets/adblock_970_250.jpg'
        }

        let style

        let bannerType
        if (typeof window.CrazyGames !== 'undefined') {
            bannerType = 'crazyGames'
            style = `width: ${this.props.width}; height: ${this.props.height}`
        } else {
            bannerType = 'aip'
        }

        return (
            <>
                {!adblock && bannerType == 'aip' && referrer != 'crazygames' &&
                    <div
                    // ref={fowardedRef}
                        style={{ zIndex: 10 }}
                        id={this.props.adId} className={`overflow-hidden banner-ad-${this.props.size} ${bg}`}></div>
                }
                {adblock &&
                    <img style={{ pointerEvents: 'none', }} src={adblockUrl} />
                }
                {/* {!adblock && bannerType == 'crazyGames' && */}

                {!adblock && referrer == 'crazygames' &&
                    <div id={this.props.adIdCrazyGames} 
                    // ref={fowardedRef}
                    // style={{ style }}
                    
                    ></div>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        adblock: state.stuff.adblock,
        referrer: state.stuff.referrer,
        sdkLoaded: state.stuff.sdkLoaded,
    }
}

export default connect(mapStateToProps)(BannerAd)