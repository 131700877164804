"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquipmentData = void 0;
const bodyWear_1 = require("./data/bodyWear");
const topWear_1 = require("./data/topWear");
const handWear_1 = require("./data/handWear");
exports.EquipmentData = {
    bodyWear: bodyWear_1.bodyWear,
    handWear: handWear_1.handWear,
    topWear: topWear_1.topWear,
};
