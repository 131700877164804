"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bodyWear = void 0;
exports.bodyWear = [
    {
        id: 1,
        name: 'Adventurer Garments',
        refName: 'body_starter.png',
        rarity: 'common',
        stats: {},
        imgPath: 'shirt.png',
    },
    {
        id: 2,
        name: 'Wizard Robe',
        refName: 'body_wizard_uncommon.png',
        rarity: 'uncommon',
        stats: {
            spellDamage: 1,
            spellSpeed: 1,
            spellFirerate: 1,
        },
        imgPath: 'robe.png',
    },
    {
        id: 3,
        name: 'Rogue Garments',
        refName: 'body_rogue_uncommon.png',
        rarity: 'uncommon',
        stats: {
            meleeAttackSpeed: 1,
            movementSpeed: 2,
        },
        imgPath: 'rogue-garments.png',
    },
    {
        id: 4,
        name: 'Knight Armor',
        refName: 'body_knight_uncommon.png',
        rarity: 'uncommon',
        stats: {
            health: 2,
            meleeDamage: 1,
        },
        imgPath: 'knight-armor.png',
        broad: true,
    },
    {
        id: 5,
        name: 'Wizard Robe',
        refName: 'body_wizard_rare.png',
        rarity: 'rare',
        stats: {
            spellDamage: 1,
            spellSpeed: 2,
            spellFirerate: 1,
        },
        imgPath: 'robe.png',
    },
    {
        id: 6,
        name: 'Rogue Garments',
        refName: 'body_rogue_rare.png',
        rarity: 'rare',
        stats: {
            meleeAttackSpeed: 2,
            movementSpeed: 2,
        },
        imgPath: 'rogue-garments.png',
    },
    {
        id: 7,
        name: 'Knight Armor',
        refName: 'body_knight_rare.png',
        rarity: 'rare',
        stats: {
            health: 2,
            meleeDamage: 2,
        },
        imgPath: 'knight-armor.png',
        broad: true,
    },
    {
        id: 8,
        name: 'Wizard Robe',
        refName: 'body_wizard_epic.png',
        rarity: 'epic',
        stats: {
            spellDamage: 2,
            spellSpeed: 2,
            spellFirerate: 1,
        },
        imgPath: 'robe.png',
    },
    {
        id: 9,
        name: 'Rogue Garments',
        refName: 'body_rogue_epic.png',
        rarity: 'epic',
        stats: {
            meleeAttackSpeed: 3,
            movementSpeed: 2,
        },
        imgPath: 'rogue-garments.png',
    },
    {
        id: 10,
        name: 'Knight Armor',
        refName: 'body_knight_epic.png',
        rarity: 'epic',
        stats: {
            health: 3,
            meleeDamage: 2,
        },
        imgPath: 'knight-armor.png',
        broad: true,
    },
    {
        id: 11,
        name: 'Wizard Robe',
        refName: 'body_wizard_legendary.png',
        rarity: 'legendary',
        stats: {
            spellDamage: 2,
            spellSpeed: 2,
            spellFirerate: 2,
        },
        imgPath: 'robe.png',
    },
    {
        id: 12,
        name: 'Rogue Garments',
        refName: 'body_rogue_legendary.png',
        rarity: 'legendary',
        stats: {
            meleeAttackSpeed: 3,
            movementSpeed: 3,
        },
        imgPath: 'rogue-garments.png',
    },
    {
        id: 13,
        name: 'Knight Armor',
        refName: 'body_knight_legendary.png',
        rarity: 'legendary',
        stats: {
            health: 3,
            meleeDamage: 3,
        },
        imgPath: 'knight-armor.png',
        broad: true,
    },
    {
        id: 14,
        name: 'Slime Armor',
        refName: 'body_slime_rare.png',
        rarity: 'rare',
        stats: {
            health: 1,
            movementSpeed: 1,
            meleeDamage: 1,
            meleeAttackSpeed: 1,
            spellDamage: 1,
            spellSpeed: 1,
            spellFirerate: 1
        },
        imgPath: 'slime-armor.png',
    },
    {
        id: 16,
        name: 'Slime Armor',
        refName: 'body_slime_legendary.png',
        rarity: 'legendary',
        stats: {
            health: 2,
            movementSpeed: 1,
            meleeDamage: 2,
            meleeAttackSpeed: 1,
            spellDamage: 2,
            spellSpeed: 1,
            spellFirerate: 1
        },
        imgPath: 'slime-armor.png',
    },
    {
        id: 17,
        name: 'Dark Armor',
        refName: 'dark_armor.png',
        rarity: 'legendary',
        stats: {
            health: 1,
            meleeDamage: 4,
        },
        imgPath: 'spiked-armor.png',
        broad: true,
    },
    // {
    // id: 5,
    //     name: 'Fire Wizard Robe',
    //     refName: 'wizardRobe',
    //     rarity: 'Rare',
    //     stats: {
    //         fireDamage: 2,
    //         spellSpeed: 1,
    //         spellFirerate: 1,
    //     }
    // },
    {
        id: 18,
        name: 'Ice Armor',
        refName: 'body_ice_legendary.png',
        rarity: 'legendary',
        stats: {
            health: 6,
        },
        imgPath: 'icon_ice_armour_body.png',
        broad: true,
    },
    {
        id: 19,
        name: 'Crab Armor',
        refName: 'body_crab.png',
        rarity: 'rare',
        stats: {
            meleeDamage: 2,
            meleeAttackSpeed: 1,
        },
        traits: {
            spiky: 0.1
        },
        imgPath: 'icon_crab_armour_body.png',
        broad: true,
    },
];
