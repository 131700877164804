import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'
import { Paper } from '@mui/material'
import Switch from '@mui/material/Switch'

import Constants from '../../../shared/constants'
import { updateSettings } from '../../actions/actions'
import { bigScreenBorderProperties } from '../uiStyles.js'

class GraphicsSettings extends Component {
    constructor() {
        super()
        this.state = {
            dialog: false,

        }
    }

    componentDidUpdate(prevProps, prevState) {

    }


    componentDidMount() {
        let { accountKillStats } = this.props

    }

    updateSettings(key, settingsType) {
        let {settings, width} = this.props

        try{
        let localStorageSettings = JSON.parse(localStorage.getItem('settings'))

        localStorageSettings[settingsType][key] = !settings[settingsType][key]

        // let localStorageVideoSettings = localStorageSettings.settings
        // localStorageVideoSettings[key] = !settings.videoSettings[key]
        // localStorage.setItem("videoSettings", JSON.stringify(localStorageVideoSettings))

       
        localStorage.setItem('settings', JSON.stringify(localStorageSettings))

        updateSettings({settingType: settingsType, key: key, value: !settings[settingsType][key] }) } catch(err){}
    }

    render() {
        const handleClickOpen = () => { this.setState({ 'dialog': true }) }
        const handleClose = () => { this.setState({ 'dialog': false }) }


        let gridStyle = {
            marginBottom: '5px',
            // whiteSpace: 'pre-wrap' 
        }
        let { dialog } = this.state

        let {settings, width} = this.props


        let buttonSize
        if (width < 1280) {buttonSize = 'small'}

        return (
            // </Paper>
            <Grid container
                align='center' justifyContent="center" alignItems="center" direction="row"
                style={{
                    marginBottom: '2.5%'
                }}>
                <Grid
                    item xs={12} style={{
                    }}>
                    <b>    Note: Webpage must be refreshed for changes to take place. </b>
                </Grid>


                <Grid
                    item xs={12} style={{
                    marginBottom: '12px'
                }}>

                    <b>    Rendering </b>
                </Grid>

                <Grid container> 
                    <Grid container xs={12}>
                        <Grid xs={6}> 
                        Shadows
                        </Grid>
                        <Grid xs={6}>
                            <Switch
                                checked={settings.videoSettings.shadows}
                                onChange={() => this.updateSettings('shadows', 'videoSettings')}
                                name="checkedA"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12}>
                        {/* <Grid xs={2}> </Grid> */}
                        <Grid xs={6}>
                            Decorations
                        </Grid>
                        <Grid xs={6}>
                            <Switch
                                checked={settings.videoSettings.smallDecorations}
                                onChange={() => this.updateSettings('smallDecorations', 'videoSettings')}
                                name="checkedB"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </Grid>
                            {/* <Grid xs={2}> </Grid> */}
                    </Grid>
                    <Grid container xs={12}>
                        <Grid xs={6}>
                            Ground Marks
                        </Grid>
                        <Grid xs={6}>
                            {/* Yes */}
                            <Switch
                                checked={settings.videoSettings.smudges}
                                onChange={() => this.updateSettings('smudges', 'videoSettings')}
                                name="checkedC"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </Grid>
                    </Grid>
                 
                </Grid>
            </Grid>
        );
    }
}


function mapStateToProps(state) {
    return {
        // videoSettings: state.stuff.videoSettings
        settings: state.stuff.settings,
        width: state.stuff.width
    }
}

export default connect(
    mapStateToProps,
)(GraphicsSettings)