"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pdTrialsData = exports.fullTrialsData = exports.trialMainTomeDrop = void 0;
exports.trialMainTomeDrop = {
    12: 'damageTome', // dragon valley
    4: 'speedTome', // ice mayhem 1
    14: 'spiritTome', // ice mayhem 2
    13: 'agilityTome', // Ice Valley
    3: 'damageTome', // Trap Maze
    16: 'defenseTome', // Haunted Graveyard
    19: 'healthOrManaTome', // Grix Fortress
};
exports.fullTrialsData = [
    {
        id: 1,
        name: 'Charlie Challenge',
        zone: 4,
        scoreModifier: 1.1,
        expModifier: 1.1,
        maxPlayers: 150,
        minimumLevel: 23,
        score: 10000,
        coinsEarned: 2,
        difficulty: 'Medium',
        type: 'wave',
        totalWaves: 3,
        cost: {
            mana: {
                dark: 250,
            }
        },
        otherData: {
            minLevel: 10,
            maxLevel: 19,
            icon: 'emotes/enemy_evilCreature'
        }
    },
    {
        id: 2,
        name: 'Dual Dragons',
        zone: 4,
        scoreModifier: 1.3,
        expModifier: 1.3,
        maxPlayers: 150,
        minimumLevel: 25,
        coinsEarned: 5,
        difficulty: 'very hard',
        score: 25000,
        cost: {
            mana: {
                fire: 500,
            }
        },
        type: 'wave',
        totalWaves: 3,
        otherData: {
            minLevel: 15,
            maxLevel: 20,
            icon: 'emotes/enemy_dragonBoss'
        }
    },
    {
        id: 3,
        name: 'Trap Maze',
        zone: 4,
        cost: {
            mana: {
                dark: 250,
            }
        },
        score: 10000,
        maxPlayers: 150,
        difficulty: 'Hard',
        coinsEarned: 5,
        type: 'boss',
        otherData: {
            minLevel: 15,
            maxLevel: 20,
            icon: 'emotes/enemy_darkWizBoss'
        }
    },
    {
        id: 4,
        name: 'Ice Mayhem',
        zone: 4,
        cost: {
            mana: {
                water: 400,
                // dark: 25000,
            }
        },
        score: 15000,
        difficulty: 'Hard',
        maxPlayers: 150,
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 4,
        otherData: {
            minLevel: 15,
            maxLevel: 20,
            icon: 'emotes/enemy_iceSlimeBoss'
        }
    },
    {
        id: 5,
        name: 'Slime Treasure Lands',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 1000,
        maxPlayers: 150,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        waveCd: 0.25,
        otherData: {
            minLevel: 20,
            maxLevel: 20,
            icon: 'emotes/enemy_slime'
        }
    },
    {
        id: 6,
        name: 'Slime Valley',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 150,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'boss',
        otherData: {
            minLevel: 1,
            maxLevel: 10,
            icon: 'emotes/enemy_slime'
        }
    },
    {
        id: 7,
        name: 'Forest Treasure Lands',
        zone: 2,
        cost: {
            mana: {}
        },
        score: 1000,
        maxPlayers: 150,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        waveCd: 0.25,
        otherData: {
            minLevel: 20,
            maxLevel: 20,
            icon: 'emotes/ghost'
        }
    },
    {
        id: 8,
        name: 'Maze Treasure Lands',
        zone: 3,
        cost: {
            mana: {}
        },
        score: 1000,
        maxPlayers: 150,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        waveCd: 0.25,
        otherData: {
            minLevel: 20,
            maxLevel: 20,
            icon: 'emotes/ghost'
        }
    },
    {
        id: 9,
        name: 'Lava Treasure Lands',
        zone: 4,
        cost: {
            mana: {}
        },
        score: 1000,
        maxPlayers: 150,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        waveCd: 0.25,
        otherData: {
            minLevel: 20,
            maxLevel: 20,
            icon: 'emotes/ghost'
        }
    },
    {
        id: 10,
        name: 'Forest Forray',
        zone: 2,
        cost: {
            mana: {}
        },
        score: 5000,
        maxPlayers: 150,
        difficulty: 'Medium',
        coinsEarned: 5,
        type: 'boss',
        otherData: {
            minLevel: 3,
            maxLevel: 15,
            icon: 'emotes/enemy_woodlandWhelp'
        }
    },
    {
        id: 11,
        name: 'Mini Mayhem',
        zone: 3,
        cost: {
            mana: {}
        },
        score: 7000,
        maxPlayers: 150,
        difficulty: 'Medium',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 3,
        otherData: {
            minLevel: 10,
            maxLevel: 19,
            icon: 'emotes/enemy_evilCreature'
        }
    },
    {
        id: 12,
        name: 'Dragon Valley',
        zone: 4,
        cost: {
            mana: {}
        },
        score: 10000,
        maxPlayers: 150,
        difficulty: 'Hard',
        coinsEarned: 5,
        type: 'boss',
        otherData: {
            minLevel: 15,
            maxLevel: 20,
            icon: 'emotes/enemy_dragonBoss'
        }
    },
    {
        id: 13,
        name: 'Ice Valley',
        zone: 4,
        cost: {
            mana: {}
        },
        score: 10000,
        maxPlayers: 150,
        difficulty: 'Hard',
        coinsEarned: 5,
        type: 'boss',
        otherData: {
            minLevel: 15,
            maxLevel: 20,
            icon: 'emotes/enemy_ice_golem'
        }
    },
    {
        id: 14,
        name: 'Ice Mayhem',
        zone: 4,
        cost: {
            mana: {
                water: 400,
                // dark: 25000,
            }
        },
        score: 15000,
        difficulty: 'Hard',
        maxPlayers: 150,
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 4,
        otherData: {
            minLevel: 15,
            maxLevel: 20,
            icon: 'emotes/enemy_iceSlimeBoss'
        }
    },
    {
        id: 15,
        name: 'Hellhound',
        zone: 4,
        cost: {
            mana: {}
        },
        score: 10000,
        maxPlayers: 150,
        difficulty: 'Hard',
        coinsEarned: 5,
        type: 'boss',
        otherData: {
            minLevel: 15,
            maxLevel: 20,
            icon: 'emotes/hellhound_boss'
        }
    },
    {
        id: 16,
        name: 'Haunted Graveyard',
        zone: 4,
        cost: {
            mana: {}
        },
        score: 10000,
        maxPlayers: 150,
        difficulty: 'Hard',
        coinsEarned: 5,
        type: 'wave',
        totalWaves: 2,
        otherData: {
            minLevel: 15,
            maxLevel: 20,
            icon: 'emotes/ghost'
        }
    },
    {
        id: 17,
        name: 'Crab Island',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 150,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'boss',
        otherData: {
            minLevel: 1,
            maxLevel: 10,
            icon: 'emotes/enemy_slime'
        }
    },
    {
        id: 18,
        name: 'Flower Kingdom',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 150,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'boss',
        otherData: {
            minLevel: 1,
            maxLevel: 10,
            icon: 'emotes/enemy_slime'
        }
    },
    {
        id: 19,
        name: 'Grix Fortress',
        zone: 1,
        cost: {
            mana: {}
        },
        score: 2000,
        maxPlayers: 150,
        difficulty: 'Easy',
        coinsEarned: 5,
        type: 'boss',
        otherData: {
            minLevel: 1,
            maxLevel: 20,
            icon: 'emotes/enemy_slime'
        }
    },
];
const trialsDataFilter = JSON.parse(JSON.stringify(exports.fullTrialsData));
for (let i = 0; i < trialsDataFilter.length; i++) {
    delete trialsDataFilter[i].otherData;
}
exports.pdTrialsData = trialsDataFilter;
