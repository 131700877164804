import React, { Component } from "react"
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import { IconButton } from '@mui/material'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import Constants from '../../shared/constants'
import { RankingData } from '../../shared/ranking'
import {bigScreenBorderProperties, colorPalette, mainBorderProperties} from './uiStyles.js'
import ColorChangingButton from './ColorChangingButton.js'

class RankDialog extends Component {
    constructor() {
        super()
        this.state = {
            dialog: false
        }
    }

    componentDidMount() {


    }



    render() {

        const handleClickOpen = () => { this.setState({ 'dialog': true }) }
        const handleClose = () => { this.setState({ 'dialog': false }) }

        let { rankId, rankingScore, height} = this.props

        let dialogHeight
        let fontSize = '19px'
        let imgSize = '45px'
        let gridWidth
        let titlePadding = '16px'

        if (height < 560) {
            // if (maxChallengesPerPage != 3) {
            //     this.setState({ maxChallengesPerPage: 3 })
            // }
            // dialogHeight = '396px'
            dialogHeight = '350px'
            fontSize = '10px'
            imgSize = '29px'
            gridWidth = 2
            titlePadding = '5px'

        } else if (height < 630) {
            // if (maxChallengesPerPage != 4) {
            //     this.setState({ maxChallengesPerPage: 4 })
            // }
            dialogHeight = '471px'
            fontSize = '15px'
            imgSize = '37px'
            gridWidth = 2
            titlePadding = '5px'
        } else {
            // if (maxChallengesPerPage != 5) {
            //     this.setState({ maxChallengesPerPage: 5 })
            // }
            fontSize = '18px'
            imgSize = '45px'
            dialogHeight = '547px'
            gridWidth = 3
            titlePadding = '16px'
        }

      
        let rankingTable = RankingData.map((rankData) => {

            let backgroundColor = 'white'


            if (rankData.id == rankId) {
                backgroundColor = 'orange'
            }

            let borderColor
            let fillingColor

            switch (rankData.type) {
                case 'bronze':
                    borderColor = '#d68a56'
                    fillingColor = '#D68A56'
                    break
                case 'silver':
                    borderColor = '#e3f5ff'
                    fillingColor = '#E3F5FF'
                    break
                case 'gold':
                    borderColor = '#ffd522'
                    fillingColor = '#FFD522'
                    break
                // case 'platinum':
                //     borderColor = '#FFCB27'
                //     fillingColor = 'linear-gradient(to bottom, #E5E4E2 0%, #CBCFD6 100%)'
                //     break;
                // default:
                //     borderColor = 'black'
                //     fillingColor = 'grey'
                //     break;
            }

            // let spaceToUse = 3
            let spaceToUse = gridWidth

            if (rankData.id == 12) { spaceToUse = 12 }


            return (
                <Grid xs={spaceToUse}
                    style={{
                    }}
                >
                    <div
                        style={{
                            backgroundColor: backgroundColor,
                            margin: '5px',
                            borderRadius: '8px',
                            borderWidth: '3px', borderStyle: 'solid',
                            borderColor: borderColor,
                            boxShadow: '0 0 0 2px #FFFFFF',
                        }}
                    >
                        <Grid container
                            align='center' justifyContent="center" alignItems="center" direction="row"
                            xs={12}>

                            <Grid item xs={12}>
                                <b style={{fontSize: fontSize}}> {rankData.name} </b>
                            </Grid>

                            <Grid item xs={12}>
                                {/* image */}
                       
                                <img
                                    style={{
                                        // width: '45px',
                                        // height: '45px'
                                        width: imgSize,
                                        height: imgSize,
                                        pointerEvents: 'none'
                                    }}
                                    src={`assets/emotes/${rankData.imgPath}`}
                                >
                                </img>
                            </Grid>
                            <Grid item xs={12}>
                                <b style={{fontSize: fontSize}}>{rankData.score.toLocaleString('en', { useGrouping: true })}</b>
                            </Grid>

                        </Grid>
                    </div>
                </Grid>
            );
        })

        let { dialog } = this.state

        let buttonConfig = {
            idle: {
                fillColor: '#F7A74F',
                outerBorderColor: '#362D44',
                innerBorderColor: '#CE782C',
            },
            hover: {
                fillColor: '#FFD16C',
                outerBorderColor: '#362D44',
                innerBorderColor: '#FFB66E',
            },
            down: {
                fillColor: '#F79B37',
                outerBorderColor: '#362D44',
                innerBorderColor: '#E08634',
            },
        }
        let gridStyle = { marginBottom: '5px' }

        let currentRankData = RankingData.find(rankData => rankId == rankData.id)


        return <>
            <Grid container xs={12}
                tyle={{
                    position: 'relative',
                    maxWidth: '250px',
                    zIndex: 10,

                }}>
                <Grid container xs={12}
                    style={{
                        marginTop: '20px',
                        marginBottom: '5px',
                        maxWidth: '250px',
                    }}
                >
                    <Grid container xs={2}
                        style={{ zIndex: 5}}
                    >
                        <img
                            style={{
                                backgroundColor: 'grey',
                                borderRadius: '15px',
                                padding: '3px',
                                borderWidth: '3px', borderStyle: 'solid', borderColor: 'black',
                                width: '40px',
                                height: '40px',
                                fontSize: '2vh',
                                position: 'relative',
                                marginTop: '-9px',
                                marginBottom: '-5px',

                                zIndex: 5,
                            }}
                            src={`assets/emotes/${currentRankData.imgPath}`}
                            onClick={handleClickOpen}
                        />
                    </Grid>
                    <Grid container xs={10}>
                        <ColorChangingButton 
                        style={{zIndex: 4}}
                        buttonFunction={handleClickOpen}
                            config={buttonConfig}
                            title='Rank'
                            buttonStyling={{
                                minWidth: '110px',
                                maxWidth: '110px',

                                height: '30px'
                            }}
                            textColor='black'
                        />
                    </Grid>
                    <Grid xs={6}></Grid>
                </Grid>
            </Grid>

            <Dialog
                fullWidth={true}
                style={{
                    padding: '10px',
                    width: '100%'
                }}
                onClose={handleClose} aria-labelledby="simple-dialog-title" open={dialog}>

                <IconButton
                    style={{
                        alignSelf: 'flex-end',
                        marginTop: -5,
                        position: 'absolute'
                    }}
                    onClick={handleClose}
                    size="large">
                    <CancelPresentationIcon style={{ fontSize: '3rem' }} />
                </IconButton>
                <Paper style={Object.assign({
                    background: colorPalette.primary,
                    padding: '2%',
                    borderColor: colorPalette.primaryBorder,
                }, bigScreenBorderProperties)}>

                    <DialogTitle
                        style=
                        {Object.assign({
                            textAlign: 'center',
                            padding: titlePadding
                        },
                        )}
                        id="simple-dialog-title"> <b> RANKING </b></DialogTitle>
                    <div
                        style=
                        {Object.assign({
                            textAlign: 'center',
                            background: colorPalette.secondary
                        },
                            mainBorderProperties
                        )}
                        id="simple-dialog-title">
                        <Grid container xs={12}>
                            <Grid item xs={12} >
                                <div style={{ fontSize:  fontSize  }}> Ranking is calculated by your total score in the last 30 days.</div>
                            </Grid>
                            <Grid item xs={12} >
                                <b style={{ fontSize:  fontSize  }}> Current Score: {rankingScore}</b>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container justifyContent="center"
                        style={{ paddingTop: '1%' }}
                        alignItems="center" xs={12}>
                        <Paper style={Object.assign({
                            background: '#e7cab2',
                            marginTop: '2%',
                            padding: '1%',
                            width: '100%',
                            borderColor: colorPalette.primaryBorder,
                        }, bigScreenBorderProperties)}>
                            <Grid container>
                                {rankingTable}
                            </Grid>
                        </Paper>
                    </Grid>
                </Paper>
            </Dialog>

        </>;
    }
}


function mapStateToProps(state) {
    return {
        rankId: state.stuff.rankId,
        rankingScore: state.stuff.rankingScore,
        height: state.stuff.height
    }
}

export default connect(
    mapStateToProps,
)(RankDialog)