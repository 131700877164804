import React, { Component } from 'react'
import { Grid, Paper } from '@mui/material'
import { connect } from 'react-redux'
import * as Actions from '../actions/actions'
import { ButtonBase } from '@mui/material'
import ItemReward from './ItemReward.js'
import Constants from '../../shared/constants'
import {CosmeticsData} from "../../shared/cosmetics/cosmetics";
import {domainName} from "../index";
import {colorPalette} from "./uiStyles";

class PromoCode extends Component {
    constructor() {
        super()
        this.state = {
            dialogNewItem: false,
            itemEarnedPath: 'assets/emotes/smile.png',
            itemEarned: {
                id: 1,
                name: 'Thumbs Up',
                imgPath: 'thumb_up.png',
                rarity: 'common',
                unlockRequirement: null,
                free: true,
                type: 'emote'
            },
            itemEarnedMessage: 'Legendary Emote',
            codeMessage: '',
        }
    }

    render() {
        let { promoCodeInput, token, guestAccount } = this.props
        let { dialogNewItem, itemEarnedPath, itemEarned, itemEarnedMessage, codeMessage } = this.state
        const handleCloseNewItem = () => { this.setState({ 'dialogNewItem': false }) }
        const handleChange = (event) => {
            if (event.target.value.length <= 100) {

                let value = event.target.value

                value = value.replace(/[^A-Za-z0-9]/ig, '')
                Actions.updatePromoCodeInput(value)

            }
        }

        return (
            <Grid container xs={12} style={{
                marginBottom: '5%', width: '100%', height: '100%',

            }}>
                <Grid item xs={12}>
                    <h2> Enter code </h2>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={6}>
                    <input
                        style={{
                            marginBottom: '3%',
                            borderRadius: '4px',
                            borderWidth: '1.5px', borderStyle: 'solid',
                            borderColor: 'rgba(0,0,0,0.25)',
                            boxShadow: '0 0 0 2px hsl(0, 0%, 10%)',
                            background: colorPalette.secondary,
                            width: '95%',
                            outline: 'none',
                            height: '30px',
                            textAlign: 'center',
                            fontSize: '20px',
                            cursor: 'url(assets/ui/cursor_text.png), text'
                        }}
                        value={promoCodeInput}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={2}>

                    <div
                        style={{
                            width: '85%',
                            // height: '70%',
                            pointerEvents: 'auto'
                        }}
                        onClick={() => {
                            let url
                            if (domainName == 'localhost:3000') {
                                url = `http://${domainName}/account/claimPromoCode`
                            }
                            else {
                                url = `https://${domainName}/account/claimPromoCode`
                            }
                            fetch(url, {
                                method: 'POST',
                                credentials: 'include',
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/json',
                                    'Access-Control-Allow-Credentials': true
                                },
                                body: JSON.stringify({
                                    promoCode: promoCodeInput,
                                    token: token,
                                    guest: guestAccount
                                })
                            })
                                .then(response => {
                                    if (response.status === 200) {
                                        // console.log("RESPONSE", response)
                                        return response.json().then(user => {

                                            if (user.message == 'success') {
                                            Actions.updatePromoCodeInput('')
                                            console.log('USER RESPONSE', user)
                                            let accountItems = user.accountItems
                                            // let challenges = user.accountChallenges
                                            let rewardEarned = user.itemEarned
                                            Actions.updateAccountItems(accountItems)
                                            // Actions.updateAccountChallenges(challenges)

                                            Actions.updateCoins(user.coins)
                                            Actions.updateGems(user.gems)

                                            let itemEarned = user.itemEarned

                                            let reward
                                            let rewardImgPath
                                            let itemMessage


                                            if (itemEarned.type == 'head') {
                                                reward = CosmeticsData.heads.find(head => head.id == challenge.reward.id)
                                                reward.type = 'head'
                                                rewardImgPath = `assets/player/heads/${reward.imgPath}`
                                                itemMessage = `${reward.rarity} Skin`
                                            }
                                            else if (itemEarned.type == 'emote') {
                                                reward = CosmeticsData.emotes.find(emote => emote.id == challenge.reward.id)
                                                reward.type = 'emote'
                                                rewardImgPath = `assets/emotes/${reward.imgPath}`
                                                itemMessage = `${reward.rarity} Emote`
                                            }

                                            else if (itemEarned.type == 'coins') {
                                                reward = { rarity: 'rare', quantity: rewardEarned.quantity, type: 'coins' }
                                                rewardImgPath = 'assets/ui/rune.png'
                                                itemMessage = `${reward.quantity} Runes`
                                            }
                                            else if (itemEarned.type == 'gems') {
                                                reward = { rarity: 'legendary', quantity: rewardEarned.quantity, type: 'gems' }
                                                rewardImgPath = 'assets/ui/glyph.png'
                                                itemMessage = `${reward.quantity} Glyphs`
                                            }

                                            this.setState({
                                                itemEarnedPath: rewardImgPath,
                                                itemEarned: reward,
                                                itemEarnedMessage: itemMessage,
                                                dialogNewItem: true,
                                                codeMessage: ''
                                            })
                                            // if (itemEarned.type) == 'emote' {

                                            // }
                                        } else if (user.message == 'alreadyRedeemed') {
                                            this.setState({
                                                codeMessage: 'Code already redeemed'
                                            })

                                        } else if (user.message == 'invalidCode') {
                                            this.setState({
                                                codeMessage: 'Invalid code'
                                            })
                                        }
                                        else if (user.message == 'codeExpired') {
                                            this.setState({
                                                codeMessage: 'Code expired'
                                            })
                                        }
                                    }
                                        
                                        )
                                    }
                                })
                        }}
                        class='shineIcon'>
                        <ButtonBase
                            // class='shineIcon'
                            style={{

                                // width: '100%',
                                // height: '100%',

                                width: '90%',
                                height: '90%',
                                margin: '1.8px',
                                padding: '5px',

                                borderRadius: '7px',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                boxShadow: '0 0 0 2px #C79B7B',
                                // width: '85%',
                                // height: '70%',
                                background: '#50c96a',
                                // top: '10px',
                            }}
                            onMouseOver={() => {
                            }}
                            onMouseOut={() => {
                            }
                            }

                        >
                            Claim
                        </ButtonBase>
                    </div>

                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={12}>
                    {codeMessage}
                </Grid>
                <ItemReward
                    dialogNewItem={dialogNewItem}
                    handleCloseNewItem={handleCloseNewItem}
                    itemEarned={itemEarned}
                    itemEarnedMessage={itemEarnedMessage}
                    itemEarnedPath={itemEarnedPath}
                />
            </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        promoCodeInput: state.stuff.promoCodeInput,
        token: state.stuff.token,
        guestAccount: state.stuff.guestAccount,
    }
}


export default connect(
    mapStateToProps,
)(PromoCode)
