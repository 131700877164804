import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper, Box } from '@mui/material'
import { connect } from 'react-redux'
import Constants from '../../shared/constants'
// import { aiptag } from '././html/index.html';
import MageClashTitle from './MageClashTitle.js'
import { ButtonBase } from '@mui/material'
import SignInButton from './SignInButton.js'
import Profile from './Profile.js'
import {colorPalette, mainBorderProperties} from './uiStyles.js'
class BetaPanel extends Component {
    constructor() {
        super()
        this.state = {
        }

    }

    componentDidMount() {
        // if (localStorage.hasOwnProperty("username") && (localStorage.getItem("username") !== 'null') && (localStorage.getItem("username") !== '')) {
        //     let username = localStorage.getItem("username");
        //     updateUsernameInput(username)
        //     // loaded = true;;
        // }
    }
    render() {

        let { betaActive, accountUsername, authenticated, guestAccount } = this.props


        return (
            <Grid container
                justifyContent="center"
                style={{
                    minHeight: '100%',
                    height: '100%',
                    minWidth: '100%',
                    minHeight: '100%',
                    alignItems: 'center',
                    // margin: '10%'
                }}>
                <Paper style={{
                    userSelect: 'none', textAlign: 'center',
                    backgroundColor: 'white',
                    width: '100%', height: '100%',
                    minWidth: '100%', minHeight: '100%',
                    background: Constants.BACKGROUND_GRADIENT,
                    borderWidth: '1px',
                    borderColor: 'black',
                    borderStyle: 'solid',
                    minWidth: '100%',
                    minHeight: '100%',
                    // backgroundImage: `url(${Image})`
                    // backgroundImage: `url(${Image})`,
                    // backgroundSize: 'cover',
                    // backgroundSize: 'contain',
                    // backgroundRepeat: 'no-repeat',
                    // overflow: 'hidden',
                }}>
                    <>
                        <Grid container xs={12}
                        // style={{paddingTop: '2.5%'}}
                        >

                            {/* {!startedGame && gameLoaded
                                &&
                                !(accountUsername == '' && authenticated) &&
                                ((!betaActive && Constants.RELEASE_STATUS == 'BETA') || (!alphaActive && Constants.RELEASE_STATUS == 'ALPHA') || Constants.RELEASE_STATUS == 'PRODUCTION')
                                ? */}


                            <Grid container xs={12} direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                style={{ maxWidth: '100%', paddingLeft: '2%', paddingBottom: '1.5%' }}>
                                <>
                                    <Grid container xs={12}>
                                    {authenticated && <Profile />}
                                    </Grid>

                                    <Grid xs={12}>
                                    {(!authenticated || (authenticated && guestAccount)) && <SignInButton />}
                                    {/* <Grid container xs={4}></Grid> */}
                                    {/* <SignInPanel/> */}
                                    </Grid>
                                </>
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start"
                                sm={1}
                                md={3}
                                style={{marginLeft: '0.5%'}}
                            >
                                {/* <SignInPanel /> */}
                            </Grid>
                            <Grid item

                                sm={10}
                                md={6}
                            >

                                <ButtonBase
                                    onClick={() => {
                                        window.open('https://discord.gg/fkFFzP47Bv')
                                    }}>
                                    <MageClashTitle
                                        align="center"
                                    />
                                </ButtonBase>
                            </Grid>
                            <Grid item
                                style={{
                                    pointerEvents: 'none'
                                }}
                                sm={1}
                                md={3}
                            ></Grid>

                            <Grid xs={12} style={{ marginBottom: '1%' }}>
                                <b>Mageclash.io is currently in Beta! Join the Discord server to learn about beta times. </b>

                            </Grid>
                            {/* <Grid xs={12}>
                           <b> To get into the first Beta round, join the Discord and invite 3 friends.</b>
                            </Grid> */}
                            <Grid xs={12} style={{ marginBottom: '1%' }}>
                                <b> All Beta testers will get an exclusive Beta skin! </b>


                            </Grid>
                            <Grid xs={12} style={{ marginBottom: '1%' }}>
                                <b> Click on any image to join the Discord and stay updated </b>
                            </Grid>

                            {/* MAIN LOGO END */}
                            {accountUsername &&
                                <Grid xs={12} style={{ marginBottom: '1.5%', marginTop: '1.5%' }}>
                                    <b> Your account ID:  </b> <b style={{ pointerEvents: 'all', userSelect: 'all' }}> {accountUsername.replace('Guest #', '')}</b>
                                </Grid>
                            }


                            {betaActive &&
                                <Grid xs={12} style={{ marginBottom: '1.5%' }}>
                                    <b> Congrats, you're in the Beta! </b>
                                </Grid>
                            }


                            <Grid xs={2} md={4}></Grid>
                            <Grid xs={8} md={4}>
                                <ButtonBase
                                    onClick={() => {
                                        window.open('https://discord.gg/fkFFzP47Bv')
                                    }}

                                    style={{
                                        borderWidth: '4px',
                                        borderColor: 'white',
                                        // borderColor: 'linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C)',
                                        borderStyle: 'solid',
                                        // background: backgroundColor,

                                        borderRadius: '15px',
                                        margin: '5%',

                                    }}>
                                    <img style={{
                                        pointerEvents: 'none', objectFit: 'contain', maxWidth: '100%', maxHeight: '100%',
                                        borderRadius: '15px'
                                    }} src='assets/thumbnail_5.png' />
                                </ButtonBase>
                                {/* <Paper style={{
                                    backgroundImage: `url(${Image})`,
                                    backgroundSize: 'contain',
                                }}>
                                    <Paper style={{ opacity: '85%', padding: '5%' }}>

                                    </Paper>
                                </Paper> */}
                            </Grid>
                            <Grid xs={2} md={4}></Grid>

                            {/* 
                            <Grid xs={12}>
                            <img style={{ pointerEvents: 'none', }} src='assets/thumbnail_1.png' />
                            </Grid> */}

                            <Grid xs={12}>
                                <ButtonBase style={{
                                    borderWidth: '4px',
                                    borderColor: 'white',
                                    // borderColor: 'linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C)',
                                    borderStyle: 'solid',
                                    // background: backgroundColor,

                                    borderRadius: '15px',
                                    margin: '1.5%'
                                }}
                                    onClick={() => {
                                        window.open('https://discord.gg/fkFFzP47Bv')
                                    }}
                                >
                                    <Grid xs={8}>
                                        <h1> Join the Beta!</h1>
                                    </Grid>
                                    <Grid xs={4}>
                                        <img style={{ pointerEvents: 'none', }} src='assets/Discord-Logo-Black.svg' />
                                    </Grid>
                                </ButtonBase>
                            </Grid>
                            {/* <Grid xs={12}>
                                <h1> Join the Beta!</h1>
                            </Grid>
                            <Grid xs={12}>
                                <IconButton href="https://discord.gg/fkFFzP47Bv" target="_blank" onClick={() => {
                                    localStorage.setItem("joinedDiscord", JSON.stringify(true));
                                    Actions.updateJoinedDiscord(true);
                                }}>
                                    <Icon style={{ fontSize: '10rem' }}  >
                                        <img style={{ pointerEvents: 'none', }} src='assets/Discord-Logo-Black.svg' />
                                    </Icon>
                                </IconButton>

                            </Grid> */}
                        </Grid>

                        <Grid container justifyContent="flex-end" direction="row" alignItems="center"
                            style={{
                                position: 'absolute',
                                top: '100%',
                                left: '100%',
                                transform: 'translate(-100%, -100%)',
                                opacity: 1,
                                padding: 2,
                            }}
                        >

                            {/* <Box component="span" style={{ paddingRight: '2px' }}>
                                <Paper style={{ textAlign: 'center', 'padding': '5px', margin: '5px' }}>
                                    <a href='https://iogames.space/' target="_blank" style={{ textDecoration: 'none', textAlign: 'center' }}> IO Games </a>
                                </Paper>
                            </Box> */}
                            <Box component="span" style={{ paddingRight: '2px' }}>
                                <Paper style={Object.assign({ textAlign: 'center', 'padding': '5px', margin: '5px', background: colorPalette.primary }, mainBorderProperties)}>
                                    <a href='/privacy.html' target="_blank" style={{ textDecoration: 'none', textAlign: 'center' }}> Privacy Policy </a>
                                </Paper>
                            </Box>
                            <Box component="span" style={{ paddingRight: '2px', }}>
                                <Paper style={Object.assign({ textAlign: 'center', 'padding': '5px', margin: '5px', background: colorPalette.primary }, mainBorderProperties)}>
                                    <a href='/termsofservice.html' target="_blank" style={{ textDecoration: 'none', textAlign: 'center' }}> Terms </a>
                                </Paper>
                            </Box>
                            {/* <Box component="span" style={{ paddingRight: '2px' }}>
                                <Paper style={{ textAlign: 'center', 'padding': '5px', margin: '5px' }}>
                                    <a href='/credits.txt' target="_blank" style={{ textDecoration: 'none', textAlign: 'center' }}> Credits </a>
                                </Paper>
                            </Box> */}

                        </Grid>

                    </>

                </Paper>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        betaActive: state.stuff.betaActive,
        accountUsername: state.stuff.accountUsername,
        authenticated: state.stuff.authenticated,
        guestAccount: state.stuff.guestAccount

    }
}

export default connect(
    mapStateToProps,
)(BetaPanel)
