import Constants from '../../shared/constants'
import {generateInitialHighscores} from "../../shared/utils";
import { defaultGenericBarOptionsValues } from '../components/GenericBar';
// import {singleGameName} from "../../shared/singleGame";
// console.log('imported single game name', singleGameName)

export default {
  stuff: {
    currentGame: undefined,
    singleGame: undefined,
    titleLoaded: false,
    alertMessage: '',
    gameFocusOverlay: false,
    // alertMessage: 'test',
    accountLevel: 1,
    accountExp: 0,
    accountExpRatio: 0,
    prevAccountLevel: 1,
    prevAccountExp: 0,
    prevAccountExpRatio: 0,
    selectedKey: 1,
    shiftActive: false,
    spellsList: JSON.parse(JSON.stringify(Constants.ASSIGNED_SPELLS_TEMPLATE)),
    spellReloads: JSON.parse(JSON.stringify(Constants.ASSIGNED_SPELLS_TEMPLATE)),
    spellPointers: JSON.parse(JSON.stringify(Constants.POINTER_ACTIVE_TEMPLATE)),
    gameLoaded: false,
    mapLoaded: false,
    sdkLoaded: false,
    adId: 1,
    loadedRemainingFiles: false,
    selectedScore: 'score',
    selectedTimeValue: 'weekly',
    highscoreSelectedGameMode:  'grix', // 'mageclash'
    // highscoreSelectedGameMode:  'mageclash', // 'mageclash',
    // highscoreSelectedGameMode:  'monstermaster',
    rankId: 0,
    rankingScore: 0,
    settings: {
      videoSettings: {
        shadows: true,
        smallDecorations: true,
        smudges: true,
      },
    },
    pickupUIItem: null,
    usernameInput: '',
    promoCodeInput: '',
    // selectedHead: 3,
    selectedHead: 1,
    emoteDict: {
      0: 1,
      1: 2,
      2: 3,
      3: 4,
    },
    genericBar1Name: '', 
    genericBar1Value: 0,
    genericBar1Settings: defaultGenericBarOptionsValues,
    genericBar2Name: '',
    genericBar2Value: 0,
    genericBar2Settings: defaultGenericBarOptionsValues,
    genericBar3Name: '',
    genericBar3Value: 0,
    genericBar3Settings: defaultGenericBarOptionsValues,
    genericBar4Name: '',
    genericBar4Value: 0,
    genericBar4Settings: defaultGenericBarOptionsValues,
  

    // testing
    // genericBar1Name: 'Lvl 5 Slime', 
    // genericBar1Value: 45,
    // genericBar2Name: 'Lvl 7 Charlie',
    // genericBar2Value: 60,
    // genericBar3Name: 'GOAL: Catch 5 monsters',
    // genericBar3Value: 40,
    // genericBar3Settings: {color: '#ADD8E6', clicklable: true},
    // genericBar4Name: 'GOAL: Reach Portal',
    // genericBar4Value: 0,

    
    codeInput: '',
    mute: false,
    fullscreen: false,
    adblock: false,
    joinedDiscord: false,
    initGame: false,
    loadProgress: 0,
    authenticated: false,
    accountUsername: '',
    accountUsernameInput: '',
    accountId: 0,
    accountEmail: null,
    // accountItems: {
    //   heads: [3,4]
    // },
    accountItems: Constants.BASE_ITEMS,
    accountStats: {
      bossKills: 0,
      miniBossKills: 0,
      creatureKills: 0,
      chestKills: 0,
      playerKills: 0,
      totalDeaths: 0,
      totalScore: 0,
      timePlayed: 0,
    },
    accountKillStats: {

    },
    dailyQuests: [
      { 'id': 1, 'progress': 0, 'turnedIn': false, 'completed': false },
      { 'id': 2, 'progress': 0, 'turnedIn': false, 'completed': false },
      { 'id': 3, 'progress': 0, 'turnedIn': false, 'completed': false }
    ],
    dailyQuestsTimeLeft: 45797634,
    accountChallenges: [],
    coins: 0,
    gems: 0,
    token: null,
    guestAccount: true,
    betaActive: false,
    alphaActive: false,
    // width: 600,
    // height: 600,
    width: window.innerWidth,
    height: window.innerHeight,
    serverData: {
        default: [
      {
        name: 'Loading...',
        url: 'https://www.mageclash.io',
        // players: 10,
        players: 'low',
        // selected: true,
      },
            ]
  },
    // highscores: [
    //   {
    //     username: 'Heswe',
    //     score: '100',
    //     date: '2021-02-17 08:31:42',
    //   }
    // ],
    hideCrossLinks: false,
    referrer: '',
    highscoresReceived: false,
    highscores: generateInitialHighscores(),
    selectedServer: 'https://www.mageclash.io',
    // selectedServer: 'Loading...',
    // selectedServer: 1,

    availableResources: {},
    resourceProperties: {global: {}, properties: []},

    slotCooldowns: { '1': 0, '2': 0, '3': 0, '4': 0, 'shift': 0 },
    BRCircleTime: null,
    zoneCountdown: 0,
    minZone: 1,
    leaderboard: [],
    playerKills: 0,
    playerDied: false,
    startedGame: false,
    thingsKept: {
      level: 1,
      manaToKeep: {
        air: 0, dark: 0, earth: 0, fire: 0, light: 0, water: 0
      },
      spellsToKeep: [],
      gearToKeep: [],
    },
    level: 1,
    expRatio: 0,
    health: 100,
    maxHealth: 100,
    mana: 100,
    maxMana: 100,
    currentCircle: 1,
    statsBarOpen: false,
    stats: {
    },
    statsUpdate: 1,
    statsTopWear: {
    },
    statsBodyWear: {
    },
    statsHandWear: {
    },
    customBaseStatCaps: {},
    bodyWear: {
      id: 1,
      name: 'Adventurer Garments',
      refName: 'adventurerGarments',
      rarity: 'common',
      stats: {
      },
      imgPath: 'shirt.png',
    },
    topWear: {
      id: 1,
      name: 'Adventurer Hood',
      refName: 'adventurerHood',
      rarity: 'common',
      stats: {
      },
      imgPath: 'empty-icon-3.png',
    },
    handWear: {
      id: 1,
      name: 'Adventurer Gauntlets',
      refName: 'playerHands',
      rarity: 'common',
      stats: {
      },
      imgPath: 'hand.png',
    },
    statPoints: 0,
    loadoutDialog: false,
    challengeDialog: false,
    shopDialog: false,
    shopTab: 0,
    paymentDialog: false,
    touchControls: false,
    chatInput: '',
    chatboxActive: false,
    disableAdblockPopupActive: false,
    trialVictory: null,
    newUpdateDialog: true,
    gamePlayedOnce: false,
    globalChat: [],
    middleLowerText: '',
    signInDialogActive: false,
    ingameSettingsActive: false,
    inGameMenuTab: 0,
    ingameShopActive: false,
    ingameShopData: {
      title: 'Menu',
      categories: [
        {name: 'Tab1', items: [{name: 'Item1', description: 'item1 description', imgPath: {type: 'local', path: 'equips/Staff_512_02.png'}},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
            {name: 'Item21', description: 'item21 description'},
          ]},
        {name: 'Tab2', items:  [{name: 'Item2', description: 'item2 description'}]},
        {name: 'Tab3', items:  [{name: 'Item3', description: 'item3 description'}]},

      ]
    },
    emoteBarData: {
      showMenu: false,
      xPos: '0px',
      yPos: '0px'
    },
    statsData: {
      global: {
        maxUpgradableValue: 8, // max number BASE stat is upgradable to
        allUpgradable: true // all the stats are upgradable when player has a stat point
      },
      properties: [
        {
          displayName: 'Health',
          name: 'health',
          increase: [{name: 'health', value: 0.1, type: 'multiplicative'}],
          upgradable: true,
        },]
    }
  }
}