import React, { Component } from 'react'
import { connect } from 'react-redux'
import Currency from '../Currency.js'
import FeaturedVideo from '../FeaturedVideo'
import NewUpdate from '../NewUpdate.js'
import ServerDropdown from '../ServerDropdown.js'
import {Grid, Paper, ButtonBase} from '@mui/material'
import StartMenu from '../StartMenu.js'
import SettingsMenu from '../SettingsMenu.js'
import MageClashTitle from '../MageClashTitle.js'
import Highscores from '../Highscores.js'
import Hidden from '@mui/material/Hidden'
import '../ui.css'
import RankDialog from '../RankDialog'
import SignInPanel from '../SignInPanel.js'
import { calculateScaleFactor } from '../../game'
import IconMute from '../icons/IconMute.js'
import IconFullScreen from '../icons/IconFullScreen'
import IconDiscord from '../icons/IconDiscord'
import DailyQuests from '../DailyQuests'
import BannerAd from '../BannerAd'
import LinkBar from "../LinkBar";
import BottomBannerAd from "../ads/BottomBannerAd";
import ColorChangingButton from "../ColorChangingButton";
import {setGame} from "../../actions/actions";
import Constants from "../../../shared/constants";
import {bigScreenBorderProperties, colorPalette} from "../uiStyles";
import { resetGameObjects, resetPortalCameraPosition } from '../../create.js'
import {updateStyles} from "../../index";


class GamePage extends Component {
    constructor() {
        super()
        this.state = {}
    }
    render() {
        let {  currentGame, singleGame, mute, fullscreen,  width, height, ad, referrer, renderBottomAdBanner } = this.props
        let scaleFactor = 1
        let mainMenuScaleFactor = 1
        let topBarScaleFactor = 1
        let overallUIScaleFactor = 1
        scaleFactor = calculateScaleFactor(window.innerWidth, window.innerHeight)
        scaleFactor += 0.13
        topBarScaleFactor = scaleFactor
        mainMenuScaleFactor = scaleFactor
        if (width < 600) {
            mainMenuScaleFactor += 0.2
        }
        mainMenuScaleFactor = Math.min(1, mainMenuScaleFactor)

        let bannerAlign
        if (width < 1280) {
            // bannerAlign = 'center'
            bannerAlign = 'n'
        } else {
            bannerAlign = 'flex-end'
        }
        return (
            <div style={{ 'height': '100%', width: '100%' }}>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        background: `rgba(0, 0, 0, ${Constants.INGAME_MENU_TRANSPARENCY})`
                    }}
                />
            <span
                style={{
                    position: 'absolute',
                    transform: `scale(${mainMenuScaleFactor + 0.15})`,
                    transformOrigin: 'right',
                    top: '53%',
                    right: '0',
                    paddingRight: '13px',
                    zIndex: 100
                }}
            >
              {/*<Hidden only={['xs', 'sm', 'md']}>*/}
              {/*  {width > 1500 &&*/}
              {/*      <FeaturedVideo />*/}
              {/*  }*/}
              {/*</Hidden>*/}
              {/*<Hidden only={['lg', 'xl']}>*/}
              {/*  {height < 680 && width > 900 &&*/}
              {/*      <FeaturedVideo />*/}
              {/*  }*/}
              {/*</Hidden>*/}
            </span>
                        <div
                            style={{
                                position: 'absolute',
                                opacity: 1,
                                height: '100%',
                                width: '100%'
                            }}>

                            <Grid container style={{
                                maxWidth: '100%', height: '100%',
                                transform: `scale(${overallUIScaleFactor})`,
                                transformOrigin: 'top'
                            }}>

                                {/* MAGECLASH LOGO ROW */}
                                <Grid item
                                      xs={12}
                                      lg={3}
                                      style={{
                                          paddingLeft: '0.5%',
                                          maxHeight: `${120 * topBarScaleFactor}px`,
                                      }}
                                >
                                    <Grid container xs={12}
                                          style={{
                                              maxHeight: `${60 * topBarScaleFactor}px`,
                                          }}
                                    >
                                        <Hidden only={['lg', 'xl']}>
                                            <Grid xs={4} lg={0}>
                                                <div
                                                    style={{
                                                        transform: `scale(${topBarScaleFactor})`,
                                                        transformOrigin: 'top left'
                                                    }}
                                                >
                                                    <SignInPanel style={{
                                                        width: '100%',
                                                        maxWidth: '250px',
                                                    }} /><Grid xs={12}
                                                >
                                                    <Grid xs={10}>
                                                        <RankDialog />
                                                    </Grid>
                                                    <Grid xs={2}></Grid>
                                                </Grid>
                                                </div>
                                            </Grid>
                                        </Hidden>
                                        <Hidden only={['lg', 'xl']}>
                                            <Grid xs={4} lg={6}
                                                  style={{
                                                      transform: `scale(${topBarScaleFactor})`,
                                                      transformOrigin: 'top center'
                                                  }}
                                            >
                                                <Currency></Currency>
                                            </Grid>
                                        </Hidden>
                                        <Hidden only={['xs', 'sm', 'md']}>
                                            <Grid xs={4} lg={6}
                                                  style={{
                                                      transform: `scale(${topBarScaleFactor})`,
                                                      transformOrigin: 'top left'
                                                  }}
                                            >
                                                <Currency></Currency>
                                            </Grid>
                                        </Hidden>
                                        <Grid container
                                              alignItems='flex-end'
                                              direction="column"
                                              xs={4}>
                                            <Hidden only={['lg', 'xl']}>
                                                <div
                                                    style={{
                                                        transform: `scale(${topBarScaleFactor})`,
                                                        transformOrigin: 'top right',
                                                    }}
                                                >
                                                    <Grid xs={12} >
                                                        <IconDiscord buttonSize='small' />
                                                        <SettingsMenu buttonSize='small' />
                                                        <IconMute mute={mute} buttonSize='small' />
                                                        { referrer !== 'crazygames' && <IconFullScreen fullScreen={fullscreen} buttonSize='small'/> }
                                                    </Grid>
                                                    <Grid xs={12}>
                                                        <ServerDropdown />
                                                    </Grid>
                                                </div>
                                            </Hidden>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        style={{
                                            transform: `scale(${topBarScaleFactor}})`,
                                            transformOrigin: 'top left',
                                        }}>
                                        <Hidden only={['xs', 'sm', 'md']}>
                                            <SignInPanel style={{
                                                width: '100%',
                                                maxWidth: '250px',
                                            }} />
                                            <Grid xs={12}>
                                                <Grid xs={10}>
                                                    <RankDialog />
                                                </Grid>
                                                <Grid xs={2}></Grid>
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                                <Grid container
                                      align="center" justifyContent="center" alignItems="center"
                                      xs={0}
                                      sm={6}
                                >
                                    <Hidden only={['xs', 'sm', 'md']}>
                                        <MageClashTitle
                                            align="center"
                                        />
                                    </Hidden>
                                </Grid>
                                <Grid item
                                      xs={6}
                                      sm={3}
                                >
                                    <Grid item container
                                          xs={6}
                                          sm={12}
                                          justifyContent="flex-end" direction="row" alignItems="flex-start">
                                        <Hidden only={['xs', 'sm', 'md']}>
                                            <IconDiscord />
                                            <SettingsMenu />
                                            <IconMute mute={mute} />
                                            { referrer !== 'crazygames' && <IconFullScreen fullScreen={fullscreen}/> }
                                        </Hidden>
                                    </Grid>
                                    <Grid container
                                          xs={6}
                                          sm={12}
                                          justifyContent="flex-end" direction="row" alignItems="flex-end"
                                          style={{
                                              paddingRight: '10px', minWidth: '50%', width: '100%',
                                          }}>
                                        <Hidden only={['xs', 'sm', 'md']}>
                                            <ServerDropdown
                                            />
                                        </Hidden>
                                    </Grid>
                                </Grid>
                                <Grid xs={12}>
                                    <Hidden only={['lg', 'xl']}>
                                        <Grid container xs={12}>
                                            <Grid xs={3}></Grid>
                                            <Grid xs={6}>
                                                <MageClashTitle
                                                    align="center"
                                                />
                                            </Grid>
                                            <Grid xs={3}>
                                            </Grid>
                                        </Grid>
                                    </Hidden>
                                </Grid>

                                {/* MAIN ROW */}
                                <Grid container xs={12} style={{
                                    paddingBottom: '1%',
                                    paddingTop: '2%',
                                    marginBottom: '1%',
                                    transform: `scale(${mainMenuScaleFactor})`,
                                    transformOrigin: 'top center',
                                    maxHeight: `${355 * mainMenuScaleFactor}px`,
                                }}>
                                    {/*<NewUpdate/>*/}
                                    <Grid container
                                          align={bannerAlign} justifyContent="center"
                                          // alignItems={bannerAlign}
                                          // alignItems='center'
                                        //   alignItems='flex-end'

                                          alignItems="center"
                                        //   direction="column"
                                        direction="row"
                                          xs={1}
                                          sm={4}
                                          md={4}
                                          lg={3}
                                          // xl={4}
                                          style={{
                                              minHeight: '100%',
                                              paddingLeft: '1%',
                                              paddingRight: '1%'
                                          }}>

                                            {/* <Hidden only={['xs', 'sm', 'md']}>
                        {!(width < 1718 && height > 700) &&
                          <BannerAd size='small' adId='mageclash-io_300x250_1' dimensions='300x250' bannerWidth='300px' bannerHeight='250px' adIdCrazyGames='start1'/> }
                      </Hidden>
                      <Hidden only={['xs', 'lg', 'xl']}>
                        {width > 880 &&
                          <Highscores />
                        }
                      </Hidden> */}
                                        { 
                                        // !singleGame &&
                                            <Hidden only={['xs']}>

                                                {/* <Paper
                                                    justify="center"
                                                    style={Object.assign({
                                                        width: '100%',
                                                        background: colorPalette.primary,
                                                        padding: '2%',
                                                        minHeight: '120px',
                                                        maxHeight: '120px',
                                                        maxWidth: '120px',
                                                        minWidth: '120px',
                                                        borderColor: colorPalette.primaryBorder,
                                                    }, bigScreenBorderProperties)}
                                                > */}
                                                <Grid container xs={5}></Grid>
                                                <Grid container xs={7}
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                >
                                                                                                     <ColorChangingButton buttonFunction={() =>{
                                                        setGame(undefined)
                                                        resetPortalCameraPosition('mainportal')
                                                        updateStyles('mainportal')
                                                        // resetGameObjects('mainportal', true)
                                                    }}
                                                                         config={
                                                                             {
                                                                                 idle: {
                                                                                     fillColor: '#F7A74F',
                                                                                     outerBorderColor: '#362D44',
                                                                                     innerBorderColor: '#CE782C',
                                                                                 },
                                                                                 hover: {
                                                                                     fillColor: '#FFD16C',
                                                                                     outerBorderColor: '#362D44',
                                                                                     innerBorderColor: '#FFB66E',
                                                                                 },
                                                                                 down: {
                                                                                     fillColor: '#F79B37',
                                                                                     outerBorderColor: '#362D44',
                                                                                     innerBorderColor: '#E08634',
                                                                                 },
                                                                             }

                                                                         }
                                                                         title='More Games!'
                                                                         buttonStyling={{
                                                                             color: 'white',
                                                                             width: '100%',
                                                                         }}
                                                                         image='assets/gifs/dragon_boss_idle.gif'
                                                    />

                                                </Grid>

                                                {/* </Paper> */}
                                                {/*<BannerAd size='small' adId='mageclash-io_300x250_1' dimensions='300x250' bannerWidth='300px' bannerHeight='250px' adIdCrazyGames='start1' />*/}

                                            </Hidden>

                                        }


                                    </Grid>

                                    <Grid item
                                          xs={10}
                                          sm={4}
                                          md={4}
                                          lg={3}
                                          // xl={2}
                                          style={{
                                              minHeight: '100%', height: '100%', maxHeight: '100%',
                                              paddingRight: '10px'
                                          }}>
                                        <StartMenu />
                                    </Grid>
                                    <Hidden only={['xs']}>
                                        <Grid item
                                              sm={4}
                                              md={4}
                                              lg={3}
                                              // xl={2}
                                              style={{
                                                  minHeight: '100%', height: '100%', maxHeight: '100%',
                                                  paddingLeft: '0.5%',
                                                  paddingRight: '1%'
                                              }}>
                                            <Hidden only={['xs', 'sm', 'md']}>
                                                <Highscores/>
                                            </Hidden>
                                            <Hidden only={['lg', 'xl']}>
                                                <Grid container
                                                      justifyContent='flex-emd'
                                                >
                                                    <DailyQuests/>
                                                </Grid>
                                            </Hidden>
                                        </Grid>
                                    </Hidden>
                                    <Hidden only={['sm', 'md']}>
                                    <Grid item alignItems='flex-start' justifyContent="flex-start" flexDirection="column"
                                          xs={1}
                                          sm={0}
                                          md={0}
                                          lg={3}
                                          // xl={4}
                                          style={{
                                              minHeight: '100%',
                                              paddingRight: '0.5%'
                                          }}>
                                        <Hidden only={['xs', 'sm', 'md']}>
                                            <Grid container
                                                  justifyContent='flex-start'
                                            >
                                                <DailyQuests />
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                    </Hidden>
                                </Grid>
                                <BottomBannerAd adName='gamesPage' width={width}  fake={!renderBottomAdBanner}/>
                            </Grid>
                            <div>
                            </div>
                        </div>
                        <LinkBar/>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        gameMode: state.stuff.currentGame,
        singleGame: state.stuff.singleGame,
        mute: state.stuff.mute,
        fullscreen: state.stuff.fullscreen,
        adblock: state.stuff.adblock,
        width: state.stuff.width,
        height: state.stuff.height,
        guestAccount: state.stuff.guestAccount,
        referrer: state.stuff.referrer,
    }
}

export default connect(
    mapStateToProps,
)(GamePage)