import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { Paper } from '@mui/material'
import { connect } from 'react-redux'
import { TextField } from '@mui/material'
import { Button } from '@mui/material'
import * as Actions from '../actions/actions'
import Constants from '../../shared/constants'
import {baseUrl} from "../index";
// import { aiptag } from '././html/index.html';



class AccountUsernameInputBox extends Component {
    constructor() {
        super()
        this.state = {
        }

    }


    componentDidMount() {
        try {
            if (localStorage.hasOwnProperty('username') && (localStorage.getItem('username') !== 'null') && (localStorage.getItem('username') !== '')) {
                let username = localStorage.getItem('username')
                Actions.updateAccountUsernameInput(username)
                // loaded = true;;
            }
        } catch (err) { }
    }
    render() {


        const handleChange = (event) => {
            if (event.target.value.length <= Constants.USERNAME_CHAR_LIMIT) {
                let value = event.target.value
                // value = value.replace(/[^A-Za-z]/ig, '')
                value = value.replace(/[^A-Za-z0-9]/ig, '')
                Actions.updateAccountUsernameInput(value)
                // updateUsernameInput(event.target.value);
            }
        }

        let { accountUsernameInput, token } = this.props
        let usernameCharLimit = Constants.USERNAME_CHAR_LIMIT
        let guestToken
        try {
            if (localStorage.hasOwnProperty('guestToken')) {
                guestToken = localStorage.getItem('guestToken')
                // console.log("GUEST USER EXISTS", guestToken)
            } else { guestToken = null }
        } catch (err) { }
        return <>
            <Grid container
                justifyContent="center"
                style={{
                    // minHeight: '100%',
                    // height: '100%',
                    // height: '20%',
                    alignItems: 'center',
                }}>

                <Paper style={{
                    userSelect: 'none', textAlign: 'center',
                    backgroundColor: 'white',
                    padding: '10%'
                    // margin: '20%'
                    // minHeight: '100%',
                    //  width: "20%", 

                    // width: "100%", height: '100%'
                }}>
                    <Grid item xs={12} >
                        {/* <h1>Register your account username</h1> */}
                        <h1>Choose your username!</h1>
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            style={{
                                padding: '3%',
                            }}
                            // label="Enter Account Username"
                            value={accountUsernameInput}
                            onChange={handleChange}
                            helperText={`2-${usernameCharLimit} characters - only letters and numbers :)`}
                        />
                    </Grid>
                    {/* <Grid>
                        Character Limit: {usernameCharLimit}
                        Only letters and numbers

                    </Grid> */}

                    <Button size='large'
                        variant="contained"
                        style={{ padding: '5%', margin: '2%', fontSize: 'large' }}
                        onClick={() => {
                            // startGame(usernameInput, selectedHead, selectedServer, adblock, token)
                            // toggleInitGame(true)
                            // setTimeout(() => {
                            //     toggleInitGame(false)
                            // }, 5000);
                            if (accountUsernameInput.length >= 2) {
                                console.log('SUBMITTING ACCOUNT USERNAME INPUT', {
                                    requestedUsername: accountUsernameInput,
                                    token: token,
                                    guestToken: guestToken
                                })
                                fetch(`${baseUrl}/account/submitUsername`, {
                                    method: 'POST',
                                    credentials: 'include',
                                    headers: {
                                        Accept: 'application/json',
                                        'Content-Type': 'application/json',
                                        'Access-Control-Allow-Credentials': true
                                    },
                                    body: JSON.stringify({ requestedUsername: accountUsernameInput, token: token, guestToken: guestToken })
                                })
                                    .then(response => {
                                        if (response.status === 200) {
                                            return response.json().then(user => {
                                                // Actions.updateAccountUsername(response.username)
                                                Actions.toggleAuthenticated(true)
                                                Actions.updateAccountItems(user.items)
                                                Actions.updateToken(user.token)
                                                Actions.updateAccountUsername(user.username)
                                                Actions.updateAccountStats(user.accountStats)
                                                Actions.updateAccountKillStats(user.stats.creatureKills)
                                                Actions.updateGuestAccount(false)
                                                if (user.deleteGuest) {
                                                    localStorage.removeItem('guestToken')
                                                }

                                                if (user.betaActive) {
                                                    console.log('TOGGLING BETA ACTIVE')
                                                    Actions.toggleBetaActive(true)
                                                }
                                                if (user.alphaActive) {
                                                    console.log('TOGGLING ALPHA ACTIVE')
                                                    Actions.toggleAlphaActive(true)
                                                }
                                            })
                                        }
                                    })
                            }
                        }}> Submit Username </Button>
                </Paper>
            </Grid>
        </>;
    }
}

function mapStateToProps(state) {
    return {
        accountUsernameInput: state.stuff.accountUsernameInput,
        token: state.stuff.token,
    }
}

export default connect(
    mapStateToProps,
)(AccountUsernameInputBox)
