import React, { Component } from 'react'
import { connect } from 'react-redux'
import Currency from '../Currency.js'
import FeaturedVideo from '../FeaturedVideo'
import NewUpdate from '../NewUpdate.js'
import {Grid, Paper} from '@mui/material'
import StartMenu from '../StartMenu.js'
import SettingsMenu from '../SettingsMenu.js'
import MageClashTitle from '../MageClashTitle.js'
import Highscores from '../Highscores.js'
import Hidden from '@mui/material/Hidden'
import '../ui.css'
import RankDialog from '../RankDialog'
import SignInPanel from '../SignInPanel.js'
import { calculateScaleFactor } from '../../game'
import IconMute from '../icons/IconMute.js'
import IconFullScreen from '../icons/IconFullScreen'
import IconDiscord from '../icons/IconDiscord'
import DailyQuests from '../DailyQuests'
import BannerAd from '../BannerAd'
import LinkBar from "../LinkBar";
import BottomBannerAd from "../ads/BottomBannerAd";
import Constants from "../../../shared/constants";
import {bigScreenBorderProperties} from "../uiStyles";
import ButtonBase from "@mui/material/ButtonBase";
import PortalGameGrid from "../PortalGameGrid";
import Shop from "../Shop";


class PortalPage extends Component {
    constructor() {
        super()
        this.state = {
            
        }
    }
    componentDidMount() {
    }

    componentDidUpdate() {
    }
 

    render() {
        let {  mute, fullscreen,  width, height, ad, referrer, renderBottomAdBanner} = this.props
        let scaleFactor = 1
        let mainMenuScaleFactor = 1
        let topBarScaleFactor = 1
        let overallUIScaleFactor = 1
        scaleFactor = calculateScaleFactor(window.innerWidth, window.innerHeight)
        scaleFactor += 0.13
        topBarScaleFactor = scaleFactor
        mainMenuScaleFactor = scaleFactor
        if (width < 600) {
            mainMenuScaleFactor += 0.2
        }
        mainMenuScaleFactor = Math.min(1, mainMenuScaleFactor)

        let bannerAlign
        if (width < 1280) {
            // bannerAlign = 'center'
            bannerAlign = 'n'
        } else {
            bannerAlign = 'flex-end'
        }
        return (
            <div style={{ 'height': '100%', width: '100%' }}>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        background: `rgba(0, 0, 0, ${Constants.INGAME_MENU_TRANSPARENCY})`
                        }}
                />


                <span
                style={{
                    position: 'absolute',
                    transform: `scale(${mainMenuScaleFactor + 0.15})`,
                    transformOrigin: 'right',
                    top: '53%',
                    right: '0',
                    paddingRight: '13px',
                    zIndex: 100
                }}
            >
            </span>
                <div ref={this.mainContainerRef} 
                    style={{
                        position: 'absolute',
                        opacity: 1,
                        height: '100%',
                        width: '100%'
                    }}>

                    <Grid container style={{
                        maxWidth: '100%', height: '100%',
                        transform: `scale(${overallUIScaleFactor})`,
                        transformOrigin: 'top'
                    }}>

                        {/* MAGECLASH LOGO ROW */}
                        <Grid item
                              xs={12}
                              lg={3}
                              style={{
                                  paddingLeft: '0.5%',
                                  maxHeight: `${120 * topBarScaleFactor}px`,
                                  zIndex: 1010,
                              }}
                        >
                            <Grid container xs={12}
                                  style={{
                                      maxHeight: `${60 * topBarScaleFactor}px`,

                                  }}
                            >
                                <Hidden only={['lg', 'xl']}>
                                    <Grid xs={4} lg={0}>
                                        <div
                                            style={{
                                                transform: `scale(${topBarScaleFactor})`,
                                                transformOrigin: 'top left',

                                            }}
                                        >
                                            <SignInPanel style={{
                                                width: '100%',
                                                maxWidth: '250px',
                                            }} /><Grid xs={12}
                                        >
                                            <Grid xs={10}>
                                                <RankDialog />
                                            </Grid>
                                            <Grid xs={2}></Grid>
                                        </Grid>
                                        </div>
                                    </Grid>
                                </Hidden>
                                <Hidden only={['lg', 'xl']}>
                                    <Grid xs={4} lg={6}
                                          style={{
                                              transform: `scale(${topBarScaleFactor})`,
                                              transformOrigin: 'top center'
                                          }}
                                    >
                                        <Currency></Currency>
                                    </Grid>
                                </Hidden>
                                <Hidden only={['xs', 'sm', 'md']}>
                                    <Grid xs={4} lg={6}
                                          style={{
                                              transform: `scale(${topBarScaleFactor})`,
                                              transformOrigin: 'top left'
                                          }}
                                    >
                                        <Currency></Currency>
                                    </Grid>
                                </Hidden>
                                <Grid container
                                      alignItems='flex-end'
                                      direction="column"
                                      xs={4}>
                                    <Hidden only={['lg', 'xl']}>
                                        <div
                                            style={{
                                                transform: `scale(${topBarScaleFactor})`,
                                                transformOrigin: 'top right',
                                            }}
                                        >
                                            <Grid xs={12} >
                                                <IconDiscord buttonSize='small' />
                                                <SettingsMenu buttonSize='small' />
                                                <IconMute mute={mute} buttonSize='small' />
                                                { referrer !== 'crazygames' && <IconFullScreen fullScreen={fullscreen} buttonSize='small' /> }
                                            </Grid>
                                        </div>
                                    </Hidden>
                                </Grid>
                            </Grid>
                            <Grid
                                style={{
                                    transform: `scale(${topBarScaleFactor}})`,
                                    transformOrigin: 'top left',
                                }}>
                                <Hidden only={['xs', 'sm', 'md']}>
                                    <SignInPanel style={{
                                        width: '100%',
                                        maxWidth: '250px',
                                    }} />
                                    <Grid xs={12}>
                                        <Grid xs={10}>
                                            <RankDialog />
                                        </Grid>
                                        <Grid xs={2}></Grid>
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </Grid>
                        <Grid container
                              align="center" justifyContent="center" alignItems="center"
                              xs={0}
                              sm={6}
                        >
                            <Hidden only={['xs', 'sm', 'md']}>
                                <MageClashTitle
                                    align="center"
                                />
                            </Hidden>
                        </Grid>
                        <Grid item
                              xs={6}
                              sm={3}
                        >
                            <Grid item container
                                  xs={6}
                                  sm={12}
                                  justifyContent="flex-end" direction="row" alignItems="flex-start">
                                <Hidden only={['xs', 'sm', 'md']}>
                                    <IconDiscord />
                                    <SettingsMenu />
                                    <IconMute mute={mute} />
                                    { referrer !== 'crazygames' && <IconFullScreen fullScreen={fullscreen}/> }
                                </Hidden>
                            </Grid>
                        </Grid>
                        <Grid xs={12}>
                            <Hidden only={['lg', 'xl']}>
                                <Grid container xs={12}>
                                    <Grid xs={3}></Grid>
                                    <Grid xs={6}>
                                        <MageClashTitle
                                            align="center"
                                        />
                                    </Grid>
                                    <Grid xs={3}>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>

                        {/* MAIN ROW */}
                        <Grid container xs={12} style={{
                            paddingBottom: '1%',
                            paddingTop: '2%',
                            marginBottom: '1%',
                            // transform: `scale(${mainMenuScaleFactor})`,
                            transformOrigin: 'top center',
                            maxHeight: `${355 * mainMenuScaleFactor}px`,
                            zIndex: 1000
                        }}>
                            <Shop/>
                            {/*<NewUpdate/>*/}

                      {/*      <Grid container*/}
                      {/*            align={bannerAlign} justify="center" alignItems={bannerAlign} direction="column"*/}
                      {/*            // xs={1}*/}
                      {/*            // sm={4}*/}
                      {/*            // md={4}*/}
                      {/*            // lg={3}*/}
                      {/*            // xl={4}*/}
                      {/*            // xs={2}*/}

                      {/*          xs={2}*/}
                      {/*            style={{*/}
                      {/*                minHeight: '100%',*/}
                      {/*                // paddingLeft: '1%',*/}
                      {/*                // paddingRight: '1%'*/}
                      {/*            }}>*/}
                      {/*          <div>*/}
                      {/*              /!* <Hidden only={['xs', 'sm', 'md']}>*/}
                      {/*  {!(width < 1718 && height > 700) &&*/}
                      {/*    <BannerAd size='small' adId='mageclash-io_300x250_1' dimensions='300x250' bannerWidth='300px' bannerHeight='250px' adIdCrazyGames='start1'/> }*/}
                      {/*</Hidden>*/}
                      {/*<Hidden only={['xs', 'lg', 'xl']}>*/}
                      {/*  {width > 880 &&*/}
                      {/*    <Highscores />*/}
                      {/*  }*/}
                      {/*</Hidden> *!/*/}

                      {/*              /!* uncomment for left banner add *!/*/}
                      {/*              /!*<BannerAd size='small' adId='mageclash-io_300x250_1' dimensions='300x250' bannerWidth='300px' bannerHeight='250px' adIdCrazyGames='start1' />*!/*/}
                      {/*          </div>*/}
                      {/*      </Grid>*/}



                            {/* GAMES GO HERE*/}
                            <Grid container xs={2}/>
                            <Grid container
                                  align="center" justifyContent="center" alignItems="center"
                                  direction="row"
                                  xs={8}
                                  style={{
                                      minHeight: '100%', height: '100%', maxHeight: '100%',
                                      paddingRight: '10px'
                                  }}>
                                <PortalGameGrid/>
                            </Grid>
                        </Grid>
                        <Grid container xs={2}/>
                        {/* <BottomBannerAd adName='gamesPage' width={width} fake={true}/> */}
                        {/* <Grid container xs={12}> */}
                        {/* <div ref={this.bottomBannerAdRef}> */}
                            <BottomBannerAd   adName='gamesPage' width={width} fake={!renderBottomAdBanner} />
                        {/* </div> */}
                        {/* </Grid> */}
                        {/* {ad} */}
                    </Grid>
                    <div>
                    </div>
                </div>
                <LinkBar/>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        mute: state.stuff.mute,
        fullscreen: state.stuff.fullscreen,
        adblock: state.stuff.adblock,
        width: state.stuff.width,
        height: state.stuff.height,
        referrer: state.stuff.referrer,
    }
}

export default connect(
    mapStateToProps,
)(PortalPage)