import React from 'react'
import ColorChangingIcon from '../ColorChangingIcon.js'
import AddIcon from '@mui/icons-material/Add'

function IconGems() {
    return <ColorChangingIcon
        buttonFunction={() => { 
        
        }}
        config={{
            idle: {
                fillColor: '#7689D4',
                outerBorderColor: '#362D44',
                innerBorderColor: '#4E65AF',
            },
            hover: {
                fillColor: '#9BB2F9',
                outerBorderColor: '#362D44',
                innerBorderColor: '#5C96EA',
            },
            down: {
                fillColor: '#5D7AC1',
                outerBorderColor: '#362D44',
                innerBorderColor: '#445EB2',
            }
        }}
        // buttonStyling={iconStyle}
        buttonStyling={{
            position: 'relative',
            right: '-148px',
            top: '-43px'
        
        }}
        IconImage={AddIcon}
    />
}

export default (IconGems)