
import { HealthBar } from './prefabs/HealthBar.js'
import { Pickups } from './index';
import { RankingData } from '../shared/ranking'
import { updateGearData } from './actions/actions'
import Constants from '../shared/constants'
import { getMouseAngle } from './phaserUtils'
import { handleEmote } from './input.js'
let topWearToEquip = 'top_wizard_uncommon.png'
let headToEquip = 'head_oldman.png'
let bodyWearToEquip = 'body_starter.png'

let handWearToEquip = 'hands_starter'

import { equipmentStats } from './equippedSpells'
import {minimapManager} from "./uiScene";
import {player, playerSpeed, playerStats, entityPoolManager, phaserObject} from "./create";
import {CosmeticsData} from "../shared/cosmetics/cosmetics";

export function createPlayer(player, username, phaserData, startGame) {
    // TODO: Constants.GAME to game dependent name
    const xCoord = Constants.GAME_MODE_MAP.find(mode => mode.gameName === Constants.GAME).startMenuCameraCoordinates[0]
    const yCoord = Constants.GAME_MODE_MAP.find(mode => mode.gameName === Constants.GAME).startMenuCameraCoordinates[1]

    player = phaserData.add.container(xCoord, yCoord)

    player.baseContainer = phaserData.add.container(xCoord, yCoord)

    let playerBody = phaserData.physics.add.sprite(0, 0, 'mainAssets', bodyWearToEquip)
    let playerHandsLeft = phaserData.physics.add.sprite(0, 0, handWearToEquip).setFrame(0)
    let playerHandsRight = phaserData.physics.add.sprite(0, 0, handWearToEquip).setFrame(1)
    let playerTop
    topWearToEquip ? playerTop = phaserData.physics.add.sprite(0, 0, 'mainAssets', topWearToEquip) : playerTop = phaserData.physics.add.sprite(0, 0)
    let playerHead = phaserData.physics.add.sprite(0, 0, 'mainAssets', headToEquip)


    let playerShadow = phaserData.add.circle(0, 0, 29, 0x000000)
    playerShadow.tint = Constants.SHADOW_TINT
    playerShadow.alpha = Constants.SHADOW_ALPHA

    playerShadow.x = playerShadow.x + 2
    playerShadow.y = playerShadow.y + 2
    playerShadow.setScale(0.25)
    player.shadow = playerShadow


    player.ripple = phaserData.add.circle(0, 0, 10)
    player.ripple.setStrokeStyle(0.3, 0xadd8e6)
    player.ripple.visible = false
    player.baseContainer.add(player.ripple)

    player.lavaRipple = phaserData.add.circle(0, 0, 10)
    player.lavaRipple.setStrokeStyle(0.3, 0xf3b704)
    player.lavaRipple.visible = false
    player.baseContainer.add(player.lavaRipple)

    // player.submergedWater = phaserData.add.circle(0, 0, 24, '0x0a6cc4')
    // player.submergedWater.alpha = 0.5
    // player.submergedWater.visible = false



    // player.shadow = playerShadow.setDepth(1)

    // let HLShadow = phaserData.physics.add.sprite(0, 0, handWearToEquip);
    // HLShadow.tint = 0x000000;
    // HLShadow.alpha = 0.6;
    // HLShadow.x = HLShadow.x + 4
    // HLShadow.y = HLShadow.y - 3
    // HLShadow.setScale(0.8)
    // player.add(HLShadow)

    // let HRShadow = phaserData.physics.add.sprite(0, 0, handWearToEquip).setFrame(1);
    // HRShadow.tint = 0x000000;
    // HRShadow.alpha = 0.6;
    // HRShadow.x = HRShadow.x + 4
    // HRShadow.y = HRShadow.y - 3
    // HRShadow.setScale(0.8)
    // player.add(HRShadow)

    let levelUpAnim = phaserData.physics.add.sprite(0, 0, 'explosion2')
    player.baseContainer.add(levelUpAnim)
    player.levelUpAnim = levelUpAnim
    player.levelUpAnim.setScale(0.1, 0.1)


    player.baseContainer.add(playerBody)
    player.baseContainer.add(playerHandsLeft)
    player.baseContainer.add(playerHandsRight)

    player.baseContainer.add(playerHead)
    player.baseContainer.add(playerTop)

    player.playerTop = playerTop
    player.playerHead = playerHead
    player.playerBody = playerBody
    player.playerHandsLeft = playerHandsLeft
    player.playerHandsRight = playerHandsRight


    player.playerHead.displayWidth = Constants.PLAYER_SIZE.default
    player.playerHead.displayHeight = Constants.PLAYER_SIZE.default

    player.playerTop.displayWidth = Constants.PLAYER_SIZE.top.default
    player.playerTop.displayHeight = Constants.PLAYER_SIZE.top.default

    player.playerHead.displayWidth = Constants.PLAYER_SIZE.head.default
    player.playerHead.displayHeight = Constants.PLAYER_SIZE.head.default

    player.playerHandsLeft.displayWidth = Constants.PLAYER_SIZE.hands
    player.playerHandsLeft.displayHeight = Constants.PLAYER_SIZE.hands

    player.playerHandsRight.displayWidth = Constants.PLAYER_SIZE.hands
    player.playerHandsRight.displayHeight = Constants.PLAYER_SIZE.hands

    player.playerHandsLeft.x = Constants.PLAYER_HAND_POSITIONS.default.left[0]
    player.playerHandsLeft.y = Constants.PLAYER_HAND_POSITIONS.default.left[1]

    player.playerHandsRight.x = Constants.PLAYER_HAND_POSITIONS.default.right[0]
    player.playerHandsRight.y = Constants.PLAYER_HAND_POSITIONS.default.right[1]

    player.setScale(0.45)

    player.setDepth(Constants.DEPTHS.player)

    player.healthBar = new HealthBar(phaserData, 0, 0)
    player.manaBar = new HealthBar(phaserData, 0, 0)
    player.manaBar.barType = 'mana'
    player.manaVal = 100
    player.maxMana = 100
    player.username = username
    // player.usernameText = phaserData.add.text(0, 0, username, { font: '7px Arial', fill: '#ffffff' });
    // player.usernameText = phaserData.add.text(0, 0, username,
    //     { fontFamily: 'Rubik, Verdana, Arial, Tahoma, Serif', fontSize: '7px', fill: '#ffffff',});
    // player.usernameText.setStroke('#000000', 2)

    player.usernameText = phaserData.add.bitmapText(400, 50, 'rubikOutlined', username, 8)


    // player.usernameText = phaserData.add.text(0, 0, username, { fontFamily: 'Bangers', fontSize: '7px', fill: '#ffffff' });

    player.usernameTextXTransformation = (((64 * player.scaleX) / 2) - 16)
    player.usernameTextYTransformation = 26
    player.usernameText.setOrigin(0.41, 0)
    player.usernameText.setDepth(Constants.DEPTHS.entityLabels)

    setRank(player, 0)

    player.rank.setScale(0.1)

    // phaserData.minimap.ignore(player.rank)
    // phaserData.minimap.ignore(player.rankCircle)
    minimapManager.ignoreEntity(player.rank)
    minimapManager.ignoreEntity(player.rankCircle)

    player.chatRectangle = phaserData.add.rectangle(0, 0, 30, 10, 0xeeda9f)
    // player.chatRectangle.setStrokeStyle(0.5, 0x000000)
    player.chatRectangle.setOrigin(0.41, 0)
    player.chatRectangle.setDepth(Constants.DEPTHS.entityLabels)


    player.chatText = phaserData.add.bitmapText(400, 50, 'basicRubik', '', 7)

    // player.chatText.setResolution(10)
    player.chatTextXTransformation = (((64 * player.scaleX) / 2) - 16)
    // player.chatTextYTransformation = 34
    player.chatTextYTransformation = 36
    player.chatText.setOrigin(0.41, 0)
    player.chatText.setDepth(Constants.DEPTHS.chatText)

    player.chatText.visible = false
    player.chatRectangle.visible = false

    player.angle = 0
    player.prevAngle = 0

    player.emoteXTransformation = (((64 * player.scaleX) / 2) - 14)
    player.emoteYTransformation = 33


    let emoteCircleOutline = phaserData.add.circle(0, 0, 10)
    emoteCircleOutline.setStrokeStyle(0.5, 0x000000)
    player.emoteCircleOutline = emoteCircleOutline
    player.emoteCircleOutline.setDepth(Constants.DEPTHS.emoteCircle)
    player.emoteCircleOutline.visible = false

    let circle = phaserData.add.circle(0, 0, 10, 0x808080)

    player.emoteCircle = circle
    player.emoteCircle.setDepth(Constants.DEPTHS.emoteCircle)
    player.emoteCircle.setAlpha(0.3)
    player.emoteCircle.visible = false

    player.emote = phaserData.physics.add.sprite(0, 0, 'mainAssets', 'thumb_up.png')

    player.emote.setScale(0.25 / 2)
    player.emote.visible = false
    player.emote.setDepth(Constants.DEPTHS.emote)

    minimapManager.ignoreEntity(player.emote)
    minimapManager.ignoreEntity(player.emoteCircle)
    minimapManager.ignoreEntity(player.emoteCircleOutline)

    if (startGame) { player.visible = false }
    player.add(player.baseContainer)
    player.baseContainer.x = -7
    player.baseContainer.y = 0

    player.livePets = 0
    return player
}

export function punch(player, phaserData) {
    let punchTween = []
    let hand
    if (Math.random() > 0.5) {
        hand = player.playerHandsRight
        punchTween.push({
            duration: 50,
            x: Constants.PLAYER_HAND_POSITIONS['rightPunch'].right[0],
            y: Constants.PLAYER_HAND_POSITIONS['rightPunch'].right[1],
            yoyo: true,
        })
    } else {
        hand = player.playerHandsLeft
        punchTween.push({
            duration: 50,
            x: Constants.PLAYER_HAND_POSITIONS['leftPunch'].left[0],
            y: Constants.PLAYER_HAND_POSITIONS['leftPunch'].left[1],
            yoyo: true,
        })
    }

    phaserData.tweens.chain({
        targets: hand,
        tweens: punchTween,
        onComplete: function () {
            player.playerHandsLeft.x = Constants.PLAYER_HAND_POSITIONS['default'].left[0]
            player.playerHandsLeft.y = Constants.PLAYER_HAND_POSITIONS['default'].left[1]
            player.playerHandsRight.x = Constants.PLAYER_HAND_POSITIONS['default'].right[0]
            player.playerHandsRight.y = Constants.PLAYER_HAND_POSITIONS['default'].right[1]
        }
    })
}

export function castSpellAnim(player, phaserData) {
    let spellCastTween = []
    let hand
    if (Math.random() > 0.5) {
        hand = player.playerHandsRight
        spellCastTween.push({
            duration: 50,
            x: Constants.PLAYER_HAND_POSITIONS['rightSpell'].right[0],
            y: Constants.PLAYER_HAND_POSITIONS['rightSpell'].right[1],
            yoyo: true,
        })
    } else {
        hand = player.playerHandsLeft
        spellCastTween.push({
            duration: 50,
            x: Constants.PLAYER_HAND_POSITIONS['leftSpell'].left[0],
            y: Constants.PLAYER_HAND_POSITIONS['leftSpell'].left[1],
            yoyo: true,
        })
    }

    phaserData.tweens.chain({
        targets: hand,
        tweens: spellCastTween,
        onComplete: function() {
            player.playerHandsLeft.x = Constants.PLAYER_HAND_POSITIONS['default'].left[0]
            player.playerHandsLeft.y = Constants.PLAYER_HAND_POSITIONS['default'].left[1]
            player.playerHandsRight.x = Constants.PLAYER_HAND_POSITIONS['default'].right[0]
            player.playerHandsRight.y = Constants.PLAYER_HAND_POSITIONS['default'].right[1]
        }
    })
}
export function updateHandPosition(player, position) {
        if (!position){
            position = 'default'
        }
        if (position != 'rightPunch') {
            player.playerHandsLeft.x = Constants.PLAYER_HAND_POSITIONS[position].left[0]
            player.playerHandsLeft.y = Constants.PLAYER_HAND_POSITIONS[position].left[1]
        }
    
        if (position != 'leftPunch') {
            player.playerHandsRight.x = Constants.PLAYER_HAND_POSITIONS[position].right[0]
            player.playerHandsRight.y = Constants.PLAYER_HAND_POSITIONS[position].right[1]
        }

}

export function tweenDaggerWeapon(player, weaponData, phaserData) {
    let selectedWeapon
    let xHand
    let yHand
    let handTarget
    let chosenHand
    let weaponXOffset
    let weaponYOffset
    let tweenXOffset
    let tweenYOffset
    Math.random() > 0.5 ?
    (
        selectedWeapon = 'playerWeapon',
        handTarget = player.playerHandsLeft,
        xHand = Constants.PLAYER_HAND_POSITIONS['leftPunch'].left[0],
        yHand = Constants.PLAYER_HAND_POSITIONS['leftPunch'].left[1],
        chosenHand = 'left',
        weaponXOffset = weaponData.xOffset,
        weaponYOffset = weaponData.yOffset,
        tweenXOffset = weaponData.tweenXOffset,
        tweenYOffset = weaponData.tweenYOffset
    )
    :
    (
        selectedWeapon = 'playerWeapon2',
        handTarget = player.playerHandsRight,
        xHand = Constants.PLAYER_HAND_POSITIONS['rightPunch'].right[0],
        yHand = Constants.PLAYER_HAND_POSITIONS['rightPunch'].right[1],
        chosenHand = 'right',
        weaponXOffset = weaponData.xOffset2,
        weaponYOffset = weaponData.yOffset2,
        tweenXOffset = weaponData.tweenXOffset2,
        tweenYOffset = weaponData.tweenYOffset2
    )

    let handTweens = []
    handTweens.push({
        duration: weaponData.tweenForwardDuration,
        angle: weaponData.tweenHandRotation,
        x: xHand,
        y: yHand
    })
    handTweens.push({
        duration: weaponData.tweenBackwardDuration,
        angle: weaponData.startingHandAngle,
        x: Constants.PLAYER_HAND_POSITIONS[weaponData.handsFrame][chosenHand][0],
        y: Constants.PLAYER_HAND_POSITIONS[weaponData.handsFrame][chosenHand][1],
    })

    let weaponTweens = []
    weaponTweens.push({
        duration: weaponData.tweenForwardDuration,
        x: tweenXOffset,
        y: tweenYOffset,
        angle: weaponData.tweenWeaponRotation,
    })
    weaponTweens.push({
        duration: weaponData.tweenBackwardDuration,
        x: weaponXOffset,
        y: weaponYOffset,
        angle: weaponData.startingWeaponAngle,
    })

    phaserData.tweens.chain({
        targets: handTarget,
        tweens: handTweens,
    })
    phaserData.tweens.chain({
        targets:player[selectedWeapon],
        tweens: weaponTweens,
        onComplete: function () {

            if (player.weaponDrawn == false) {
                player.playerWeapon.x = weaponData.sheathedXOffset
                player.playerWeapon.y = weaponData.sheathedYOffset
                player.playerWeapon.angle = weaponData.sheathedRotation

                player.playerWeapon2.x = weaponData.sheathedXOffset2
                player.playerWeapon2.y = weaponData.sheathedYOffset2
                player.playerWeapon2.angle = weaponData.sheathedRotation2
                updateHandPosition(player, 'default')
            }
            else {
                updateHandPosition(player, weaponData.handsFrame)
            }

        },
    })
}

export function tweenWeapon(player, weaponData, phaserData) {
    let leftHandTweens = []

    if (weaponData.preTweenDuration) {
        leftHandTweens.push({
            duration: weaponData.preTweenDuration,
            angle: weaponData.tweenHandRotation,
            x: weaponData.preTweenLeft.x,
            y: weaponData.preTweenLeft.y
        })
    }
    leftHandTweens.push({
        duration: weaponData.tweenForwardDuration,
        angle: weaponData.tweenHandRotation,
        x: weaponData.handsTweenLeft.x,
        y: weaponData.handsTweenLeft.y
    })
    leftHandTweens.push({
        duration: weaponData.tweenBackwardDuration,
        angle: weaponData.startingHandAngle,
        x: Constants.PLAYER_HAND_POSITIONS[weaponData.handsFrame].left[0],
        y: Constants.PLAYER_HAND_POSITIONS[weaponData.handsFrame].left[1]
    })

    let rightHandTweens = []
    if (weaponData.preTweenDuration) {
        rightHandTweens.push({
            duration: weaponData.preTweenDuration,
            angle: weaponData.tweenHandRotation,
            x: weaponData.preTweenLeft.x,
            y: weaponData.preTweenLeft.y
        })
    }
    rightHandTweens.push({
        duration: weaponData.tweenForwardDuration,
        angle: weaponData.tweenHandRotation,
        x: weaponData.handsTweenRight.x,
        y: weaponData.handsTweenRight.y
    })
    rightHandTweens.push({
        duration: weaponData.tweenBackwardDuration,
        angle: weaponData.startingHandAngle,
        x: Constants.PLAYER_HAND_POSITIONS[weaponData.handsFrame].right[0],
        y: Constants.PLAYER_HAND_POSITIONS[weaponData.handsFrame].right[1]
    })

    let weaponTweens = []
    if (weaponData.preTweenDuration) {
        weaponTweens.push({
            duration: weaponData.preTweenDuration,
            angle: weaponData.tweenHandRotation,
            x: weaponData.preTweenXOffset,
            y: weaponData.preTweenYOffset,
        })
    }
    weaponTweens.push({
        duration: weaponData.tweenForwardDuration,
        x: weaponData.tweenXOffset,
        y: weaponData.tweenYOffset,
        angle: weaponData.tweenWeaponRotation,
    })
    weaponTweens.push({
        duration: weaponData.tweenBackwardDuration,
        x: weaponData.xOffset,
        y: weaponData.yOffset,
        angle: weaponData.startingWeaponAngle,
    })

    phaserData.tweens.chain({
        targets: player.playerHandsLeft,
        tweens: leftHandTweens
    })
    phaserData.tweens.chain({
        targets: player.playerHandsRight,
        tweens: rightHandTweens
    })
    phaserData.tweens.chain({
        targets: player.playerWeapon,
        tweens: weaponTweens,
        onComplete: function () {
            if (player.weaponDrawn == false) {
                player.playerWeapon.x = weaponData.sheathedXOffset
                player.playerWeapon.y = weaponData.sheathedYOffset
                player.playerWeapon.angle = weaponData.sheathedRotation

                updateHandPosition(player, 'default')
            }
            else {
                updateHandPosition(player, weaponData.handsFrame)
            }
        },
    })
}

export function tweenRotation(phaserData, sprite, duration) {
    console.log('duration for tween rotation', duration)
    phaserData.tweens.add({
        targets: sprite,
        rotation: Math.PI,
        duration: duration,
        repeat: -1,
    })
}
export function tweenScale(phaserData, sprite, input, totalScale) {
    console.log('scaling sprite', input)
    console.log('total scale', totalScale)
    phaserData.tweens.add({
        targets: sprite,
        scaleX: input.scaleX * totalScale,
        scaleY: input.scaleY * totalScale,
        duration: input.duration,
        repeat: input.repeat,
        yoyo: input.yoyo,
        // ease: 'linear'
        ease: 'quadratic'
    })
}

export function updateSpellCooldowns(equippedSpells, globalSpellCooldown, dtCalc, dt) {
    equippedSpells.forEach(function (spell) {
        if (spell['cooldownCounter'] > 0 && dt) {
            spell['cooldownCounter'] -= dtCalc
        }
    })
    if (globalSpellCooldown.value >= 0 && dt) {
        globalSpellCooldown.value -= dtCalc
    }
}

export function updatePlayerAngle(player) {
    player.prevAngle = player.angle
    player.angle = getMouseAngle(this, player) * Phaser.Math.RAD_TO_DEG

}

export function updateMinimapPosition(playerMinimapCircle, me) {
    minimapManager.updateMinimap(me)
}

export function playChat(player, input, phaserObject) {
    player.chatText.visible = true

    player.chatRectangle.destroy()
    player.chatText.text = input

    player.chatRectangle = phaserObject.add.rectangle(0, 0, player.chatText.width + 2, 10, 0xeeda9f)

    player.chatRectangle.setOrigin(0.41, 0)
    player.chatRectangle.setDepth(Constants.DEPTHS.chatRectangle)
    player.chatRectangle.setStrokeStyle(0.5, 0x000000)

    if (player.chatTimeout) {
        clearTimeout(player.chatTimeout)
    }
    player.chatTimeout = setTimeout(
        function () {
            player.chatRectangle.visible = false
            player.chatText.text = ''
            player.chatText.visible = false
        }
        , Constants.CHAT_DURATION)
}

export function playEmote(player, emoteSlot, emoteDict, phaserObject) {
    if (!player.emotePressed) {
        if (phaserObject.game.hasFocus) {
            phaserObject.sfx.play('emote_sound')
        }
        let emoteToUse = CosmeticsData.emotes.find(emote => emote.id == emoteDict[emoteSlot])
        player.emote.setTexture('mainAssets', emoteToUse.imgPath)
        player.emote.visible = true
        player.emoteCircle.visible = true
        player.emotePressed = true
        player.emoteCircleOutline.visible = true
        handleEmote(emoteToUse.id)
        setTimeout(
            function () {
                player.emote.visible = false
                player.emoteCircle.visible = false
                player.emotePressed = false
                player.emoteCircleOutline.visible = false
            }
            , Constants.EMOTE_COOLDOWN)
    }
}

export function playEmoteOther(player, emoteId, phaserObject) {
    if (!player.emotePressed) {
        if (phaserObject.game.hasFocus) {
            phaserObject.sfx.play('emote_sound')
        }
        let emoteToUse = CosmeticsData.emotes.find(emote => emote.id == emoteId)
        player.emote.setTexture('mainAssets', emoteToUse.imgPath)
        player.emote.visible = true
        player.emoteCircle.visible = true
        player.emotePressed = true
        player.emoteCircleOutline.visible = true
        setTimeout(
            function () {
                player.emote.visible = false
                player.emoteCircle.visible = false
                player.emotePressed = false
                player.emoteCircleOutline.visible = false
            }
            , Constants.EMOTE_COOLDOWN)
    }
}

export function initialiseFloatingNumber(config, textObj, phaserObject) {
    phaserObject.tweens.add({
        targets: textObj,
        y: textObj.y - config.animationDistance,
        ease: config.animationEase,
        duration: config.timeToLive,
        yoyo: false,
        callbackScope: phaserObject,
        onComplete: function () {
            phaserObject.tweens.add({
                targets: textObj,
                alpha: 0,
                ease: config.animationEase,
                duration: config.timeToLive,
                yoyo: false,
                callbackScope: phaserObject,
                onComplete: function () {
                    // once complete tween is removed automatically from scene I think, so can be pooled
                    // textObj.destroy()
                    textObj.setActive(false)
                    textObj.setVisible(false)
                    entityPoolManager.addObjectToPool(config.poolType, config.text, textObj, config.maxCacheTimer)
                }
            })
        }
    })
}
export function createExpFloatingNumber(player, value, phaserObject) {
    if (phaserObject.game.loop.actualFps < Constants.FPS_THRESHOLDS.LOW) {
        return
    }
    console.log('creating exp floating number', value)
    let config = {
        poolType: 'expText',
        maxCacheTimer: 300,
        textOptions: {
            fontFamily: 'rubikOutlined',
            fontSize: 6,
            color: '#0000FF',
            strokeThickness: 2,
            fontWeight: 'bold',
            stroke: '#000000',

            shadow: {
                offsetX: 0,
                offsetY: 0,
                color: '#000',
                stroke: true,
                fill: false
            }
        },
        offsetY: 10,
        timeToLive: 450,
        animationDistance: 50,
        animation: 'up',
        text: `+${value} exp`,
        align: 'top-center',
        parentObject: player,
        animationEase: 'Sine.easeOut'
    }
    let textObj = {}
    textObj = entityPoolManager.getObjectFromPool(config.poolType, config.text)
    let validTextFetched = false
    if (textObj) {
        validTextFetched = true
    } else {
        textObj = phaserObject.add.bitmapText(0, 0, 'rubikOutlined', config.text,  config.textOptions.fontSize)
    }
    textObj.tint = parseInt(config.textOptions.color.slice(1), 16)
    textObj.setDepth(Constants.DEPTHS.text)
    let offset = Math.random() * 10
    Math.random() > 0.5 ? offset *= -1 : offset *= 1
    textObj.x = player.x - (textObj.displayWidth/2) + offset
    textObj.y = (player.y - (player.displayHeight/2))
    if (validTextFetched) {
        // setting active & visible after fixing coordinates
        textObj.clearAlpha()
        textObj.setActive(true)
        textObj.setVisible(true)
    }
    // phaserObject.minimap.ignore(textObj)

    initialiseFloatingNumber(config, textObj, phaserObject)
}
export function createFloatingNumber(hpChange, player, phaserObject) {
    if (phaserObject.game.loop.actualFps < Constants.FPS_THRESHOLDS.LOW) {
        return
    }
    if (hpChange != 0) {
        let textColor
        let fontSize = 10
        let upDistance = 50
        if (hpChange < 0
            // && hpChange > -2
            // && hpChange > -80
        ) {
            fontSize = 3
            upDistance = 10
        }
        hpChange = Math.ceil(hpChange)

        if (hpChange > 0) {
            textColor = '#ff0000'
        } else {
            textColor = '#ffff00'
            // hpChange = '+'
            // if (hpChange > -80) {
            //     hpChange *= -1
            // } else {
                hpChange = '+'
            // }
        }

        let config = {
            poolType: 'floatingNumberText',
            maxCacheTimer: 300,
            textOptions: {
                fontFamily: 'rubikOutlined',
                fontSize: fontSize,
                color: textColor,
                strokeThickness: 2,
                fontWeight: 'bold',
                stroke: '#000000',

                shadow: {
                    offsetX: 0,
                    offsetY: 0,
                    color: '#000',
                    stroke: true,
                    fill: false
                }
            },
            offsetY: 10,
            timeToLive: 250,
            animationDistance: upDistance,
            animation: 'up',
            text: hpChange,
            align: 'top-center',
            parentObject: player,
            animationEase: 'Sine.easeOut'

        }
        if (hpChange === '+') {
            config.poolType = 'floatingPlusText'
            config.maxCacheTimer = -1
        }
        let textObj = {}
        textObj = entityPoolManager.getObjectFromPool(config.poolType, config.text)
        let validTextFetched = false
        if (textObj) {
            validTextFetched = true
        } else {
            textObj = phaserObject.add.bitmapText(0, 0, 'rubikOutlined', config.text,  config.textOptions.fontSize)
        }
        textObj.tint = parseInt(textColor.slice(1), 16)
        textObj.setDepth(Constants.DEPTHS.text)
        let offset = Math.random() * 10
        Math.random() > 0.5 ? offset *= -1 : offset *= 1
        textObj.x = player.x - (textObj.displayWidth/2) + offset
        textObj.y = (player.y - (player.displayHeight/2))

        if (validTextFetched) {
            // setting active & visible after fixing coordinates
            textObj.clearAlpha()
            textObj.setActive(true)
            textObj.setVisible(true)
        }
        // phaserObject.minimap.ignore(textObj)

        initialiseFloatingNumber(config, textObj, phaserObject)
    }
}

export function setPosition(input) {
    let x = input[0]
    let y = input[1]
    player.x = x
    player.y = y
    player.collisionBody.setPosition(x, y)
}
export function setSpeed(input) {
    playerSpeed.value = input
}

export function setPlayerStats(input) {
    playerStats.value = input
}

export function updatePlayerLabels(player) {
    player.healthBar.x = player.x - 13.5

    if (player.healthBarOffset) {
        player.healthBar.y = player.y - 15 - player.healthBarOffset
    }
    else {
        player.healthBar.y = player.y - 15
    }

    player.healthBar.draw()

    if (player.manaBar) {
        player.manaBar.x = player.x - 13.5
        player.manaBar.y = player.healthBar.y + 5
      
        player.manaBar.draw()
    }

    
    if (player.usernameText) {
        player.usernameText.setX(player.x + player.usernameTextXTransformation)
        player.usernameText.setY(player.y - player.usernameTextYTransformation)
    }
    
    if (player.rank) {

        player.rank.setX(player.usernameText.x - (player.usernameText.width / 2) - 7)
        player.rank.setY(player.usernameText.y + 1 + 1
        )

        player.rankCircle.setX(player.usernameText.x - (player.usernameText.width / 2) - 8)
        player.rankCircle.setY(player.usernameText.y + 1)
    }

    if (player.chatText) {
        player.chatText.setX(player.x + player.chatTextXTransformation)
        player.chatText.setY(player.y - player.chatTextYTransformation - 0.8)
        player.chatRectangle.setX(player.x + player.chatTextXTransformation)
        player.chatRectangle.setY(player.y - player.chatTextYTransformation - 1)
    }
    if (player.emote) {
        player.emote.setX(player.x + player.emoteXTransformation)
        player.emote.setY(player.y - player.emoteYTransformation)

        player.emoteCircle.setX(player.x + player.emoteXTransformation)
        player.emoteCircle.setY(player.y - player.emoteYTransformation)

        player.emoteCircleOutline.setX(player.x + player.emoteXTransformation)
        player.emoteCircleOutline.setY(player.y - player.emoteYTransformation)
    }
}
export function updateValues(entity, entityUpdate, phaserObject) {
    entity.x = entityUpdate.x
    entity.y = entityUpdate.y

    if (entity.shadow) {
        entity.shadow.x = entity.x + 1
        entity.shadow.y = entity.y + 2
    }
    if ((entity.healthBar.value != entityUpdate.hp)) {
        let hpChange = entityUpdate.hpChangeAmount
        if (hpChange) {
            createFloatingNumber(hpChange, entity, phaserObject)
            if (entity.clientPlayer) {
                entity.healthBar.value = Math.floor((entityUpdate.hp / playerStats.value.health) * 100)
            } else {
                entity.healthBar.value = entityUpdate.hp
            }
            entity.prevHp = entity.healthBar.value
        }
    }

    updatePlayerLabels(entity)

    if (entityUpdate.manaVal){
        entity.manaVal = entityUpdate.manaVal
    }
    if (entityUpdate.maxMana) {
        entity.maxMana = entityUpdate.maxMana
    }
    if (entity.manaBar) {
        if (entityUpdate.mana) {
            entity.manaBar.value = entityUpdate.mana
        }
    }
}

export function equipWeapon(player, spellID, weaponDrawn, phaserData) {
    if (player.playerWeapon) {
        player.playerWeapon.destroy()
    }
    if (player.playerWeapon2) {
        player.playerWeapon2.destroy()
    }
    console.log('equipping weapon', spellID)
    let weaponData = Pickups.spells.find(spell => spell.spellID == spellID)
    player.weaponData = weaponData
    console.log("WEAPON DATA 1: ", weaponData)

    if (spellID == 12) {
    }
    else {

        let playerWeapon
        let playerWeapon2
        if (!weaponData.xOffset || !weaponData.yOffset){
            console.log('equip weapon failed. weapon offset missing')
            return
        }
        if (weaponData.atlasTexture) {
            console.log("WEAPON DATA: ", weaponData)
            playerWeapon = phaserData.physics.add.sprite(weaponData.xOffset, weaponData.yOffset, weaponData.spriteName, weaponData.atlasTexture)
        }
        else {
            // playerWeapon = phaserData.physics.add.sprite(weaponData.xOffset, weaponData.yOffset, weaponData.spriteName);
            playerWeapon = phaserData.physics.add.sprite(weaponData.xOffset, weaponData.yOffset, 'mainAssets', weaponData.spriteName)
        }
        playerWeapon.flipX = weaponData.flipX

        if (weaponData.dualWeapon) {
            if (weaponData.atlasTexture) {
                playerWeapon2 = phaserData.physics.add.sprite(weaponData.xOffset2, weaponData.yOffset2, weaponData.spriteName, weaponData.atlasTexture)
            }
            else {
                playerWeapon2 = phaserData.physics.add.sprite(weaponData.xOffset2, weaponData.yOffset2, 'mainAssets', weaponData.spriteName)
            }
            playerWeapon2.flipX = weaponData.flipX
        }

        playerWeapon.setScale(weaponData.weaponScale)
        player.baseContainer.add(playerWeapon)
        player.playerWeapon = playerWeapon
        player.playerWeapon.angle = weaponData.startingWeaponAngle

        if (weaponData.dualWeapon) {
            playerWeapon2.setScale(weaponData.weaponScale)
            player.baseContainer.add(playerWeapon2)
            player.playerWeapon2 = playerWeapon2
            player.baseContainer.sendToBack(player.playerWeapon2)

            player.playerWeapon2.angle = weaponData.startingWeaponAngle

        }

        // player.playerHands.setFrame(weaponData.handsFrame)
        updateHandPosition(player, weaponData.handsFrame)
        if (weaponDrawn == false) {
            sheatheWeapon(player)
        }

        player.baseContainer.sendToBack(player.playerWeapon)
        player.weaponDrawn = weaponDrawn

        player.playerHandsRight.angle = weaponData.startingHandAngle
        player.playerHandsLeft.angle = weaponData.startingHandAngle
    }
}

export function drawWeapon(player) {
    player.playerWeapon.x = player.weaponData.xOffset
    player.playerWeapon.y = player.weaponData.yOffset
    player.playerWeapon.angle = player.weaponData.startingWeaponAngle

    if (player.playerWeapon2) {
        player.playerWeapon2.x = player.weaponData.xOffset2
        player.playerWeapon2.y = player.weaponData.yOffset2
        player.playerWeapon2.angle = player.weaponData.startingWeaponAngle
    }

    player.weaponDrawn = true

    updateHandPosition(player, player.weaponData.handsFrame)
}

export function sheatheWeapon(player) {
    // player.playerWeapon.visible = false

    // console.log("X OFFSET", player.bodyXOffset)
    // player.playerWeapon.x = player.weaponData.sheathedXOffset - player.bodyXOffset
    player.playerWeapon.x = player.weaponData.sheathedXOffset
    player.playerWeapon.y = player.weaponData.sheathedYOffset
    player.playerWeapon.angle = player.weaponData.sheathedRotation


    if (player.playerWeapon2) {
        player.playerWeapon2.x = player.weaponData.sheathedXOffset2
        player.playerWeapon2.y = player.weaponData.sheathedYOffset2
        player.playerWeapon2.angle = player.weaponData.sheathedRotation2
    }
    // player.playerHands.setFrame(0)
    updateHandPosition(player, 'default')
    player.weaponDrawn = false
}

export function toggleWeapon(player, phaserData) {
    if (player.weaponDrawn == false) {
        drawWeapon(player, phaserData)
    }
    else {
        sheatheWeapon(player)
    }
}

export function setRank(player, rankId) {
    if (!rankId) { rankId = 0 }
    let rankData = RankingData.find(rank => rank.id == rankId)
    setIcon(player, rankData.imgPath, rankData.circleColor)
}
export function setIcon(entity, iconPath, fillColor ='#d68a56') {
    if (!entity.rankCircle) {
        entity.rankCircle = phaserObject.add.circle(0, 0, 5, 0xd68a56)
        entity.rankCircle.setDepth(Constants.DEPTHS.entityLabelBackground)
        entity.rankCircle.setOrigin(0, 0)
        entity.rankCircle.setStrokeStyle(0.5, 0x000000)
    }
    if (entity.rankCircle) {
        // check ranked circle color
        if (typeof fillColor === 'string' && entity.rankCircle.fillColor !== parseInt(fillColor.replace('#',''),16)) {
            entity.rankCircle.fillColor = parseInt(fillColor.replace('#',''),16)
        } else if (typeof fillColor === 'number' && entity.rankCircle.fillColor !== fillColor) {
            entity.rankCircle.fillColor = fillColor
        }
    }
    if (!entity.rank) {
        // create one
        // check pool
        let rankIcon = entityPoolManager.getObjectFromPool('rankIcon', iconPath)
        if (rankIcon) {
            entity.rank = rankIcon
            entity.rank.setActive(true)
            entity.rank.setVisible(true)
        } else {
            entity.rank = phaserObject.physics.add.sprite(0, 0, 'mainAssets', iconPath)
        }
    } else {
        // check if same as current rank, and replace or update
        if (entity.rank.texture && entity.rank.texture.key === 'mainAssets' && entity.rank.frame && entity.rank.frame.name === iconPath) {
            // same icon. check for other things to update
        } else {
            // different icon. replace

            // pool prev icon
            let prevIcon = entity.rank
            entity.rank.setActive(false)
            entity.rank.setVisible(false)
            entityPoolManager.addObjectToPool('rankIcon', prevIcon.frame.name, prevIcon)
            delete entity.rank

            // create new icon
            let newIcon = entityPoolManager.getObjectFromPool('rankIcon', iconPath)
            if (newIcon) {
                entity.rank = newIcon
                entity.rank.setActive(true)
                entity.rank.setVisible(true)
            } else {
                entity.rank = phaserObject.physics.add.sprite(0, 0, 'mainAssets', iconPath)
            }
        }
    }
    // uses emotes
    entity.rank.displayWidth = 8
    entity.rank.displayHeight = 8
    entity.rank.setOrigin(0, 0)
    entity.rank.setDepth(Constants.DEPTHS.entityLabels)
}

export function equipBodyWear(player, id, primaryPlayer) {

    let gearData = Pickups.bodyWear.find(gear => gear.id == id)

    if (player) {
        player.playerBody.setTexture('mainAssets', gearData.refName)
        player.bodyWearData = gearData
        player.playerBody.displayWidth = 47
        player.playerBody.displayHeight = 47
        if (gearData.broad) {
            player.bodyXOffset = 0
        }
        else {
            player.bodyXOffset = -8
        }
    } else {
        bodyWearToEquip = gearData.refName
    }

    if (primaryPlayer) {
        equipmentStats.body = gearData.stats
        if (Object.keys(gearData.stats).length !== 0) {
            // processCooldownStats(stats, equipmentStats)
            // processStats({}, equipmentStats, stats, false)
        }
        updateGearData('statsBodyWear', 'bodyWear', gearData)

    }
}

export function equipTopWear(player, id, primaryPlayer) {
    let gearData = Pickups.topWear.find(gear => gear.id == id)

    if (player) {
        if (gearData.refName) { player.playerTop.setTexture('mainAssets', gearData.refName) } else { player.playerTop.setTexture() }
        player.topWearData = gearData

        let topWearSize
        if (id in Constants.PLAYER_SIZE.top) {
            topWearSize = Constants.PLAYER_SIZE.top[id]
        }
        else {
            topWearSize = Constants.PLAYER_SIZE.top['default']
        }
        player.playerTop.displayWidth = topWearSize
        player.playerTop.displayHeight = topWearSize
    } else {
        topWearToEquip = gearData.refName
    }
    if (primaryPlayer) {
        equipmentStats.top = gearData.stats
        if (Object.keys(gearData.stats).length !== 0) {
            // processCooldownStats(stats, equipmentStats)
            // processStats({}, equipmentStats, stats, false)
        }
        updateGearData('statsTopWear', 'topWear', gearData)
    }
}

export function equipHandWear(player, id, primaryPlayer) {
    let gearData = Pickups.handWear.find(gear => gear.id == id)
    if (player) {
        player.playerHandsLeft.setTexture(gearData.refName)
        player.playerHandsLeft.setFrame(0)
        player.playerHandsRight.setTexture(gearData.refName)
        player.playerHandsRight.setFrame(1)
        player.handWearData = gearData
    } else {
        handWearToEquip = gearData.refName
    }

    if (primaryPlayer) {
        equipmentStats.hand = gearData.stats
        if (Object.keys(gearData.stats).length !== 0) {
            // processCooldownStats(stats, equipmentStats)
            // processStats({}, equipmentStats, stats, false)
        }
        updateGearData('statsHandWear', 'handWear', gearData)
    }
}

export function equipHead(player, id, primaryPlayer) {
    let headData = CosmeticsData.heads.find(gear => gear.id == id)
    if (player) {

        // hotfix people seem to be crashing
        try {
            player.playerHead.setTexture('mainAssets', `heads/${headData.imgPath}`)
        }
        catch (err) {
            console.log('head err')
            player.playerHead.setTexture('mainAssets', 'heads/ch_og_man_1_brown_128.png')
        }
        player.playerHead.angle = -90
        let headSize
        if (id in Constants.PLAYER_SIZE.head) {
            headSize = Constants.PLAYER_SIZE.head[id]
        }
        else {
            headSize = Constants.PLAYER_SIZE.head['default']
        }
        player.playerHead.displayWidth = headSize
        player.playerHead.displayHeight = headSize
    } else {
        headToEquip = headData.imgPath
    }
}

export function rippleAnimation(player, phaserData) {
    player.ripple.visible = true
    // player.shadow.visible = false
    // player.submergedWater.visible = true

    let anim = phaserData.tweens.chain({
        targets: player.ripple,
        tweens: [{
            ease: 'Cubic',
            duration: 1000,
            scaleX: 4,
            scaleY: 4,
            repeat: -1
        }],
    })

    player.rippleAnim = anim
}


export function lavaRippleAnimation(player, phaserData) {
    player.lavaRipple.visible = true

    let anim = phaserData.tweens.chain({
        targets: player.lavaRipple,
        tweens: [{
            ease: 'Cubic',
            duration: 1000,
            scaleX: 4,
            scaleY: 4,
            repeat: -1
        }],
    })

    player.lavaRippleAnim = anim


}

export function levelUpAnimation(player, phaserData) {
    player.levelUpAnim.visible = true
    let anim = phaserData.tweens.chain({
        targets: player.levelUpAnim,
        tweens: [{
            ease: 'Cubic',
            duration: 200,
            scaleX: 1.5,
            scaleY: 1.5,
            yoyo: true,
        }],
        onComplete: function() {
            player.levelUpAnim.visible = false
        },
    })

    player.levelUpAnim.anims.play('explosion2', true)
    if (phaserData.game.hasFocus) {
        phaserData.sfx.play('level-up')
    }

}