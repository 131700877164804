"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinimapManager = void 0;
const game_1 = require("./game");
class MinimapManager {
    constructor(phaserObject) {
        // this.mapScale = 0.15
        this.mapScale = 1.3;
        // this.mapScale = 1.7
        this.baseMinimapSize = 200;
        this.minimapMargin = 0.005;
        this.phaserObject = phaserObject;
        // this.resToImageScale = 13.33
        this.resToImageScale = 10;
    }
    createMinimap(gameName = 'mainportal') {
        if (gameName === 'mainportal') {
            return;
        }
        this.minimap = this.phaserObject.add.sprite(0, 0, `minimap${gameName}`);
        this.minimap.setOrigin(0, 0);
        this.playerMinimapCircle = this.phaserObject.add.circle(this.minimapMarginX + (this.minimapWidth / 2), (window.innerHeight - this.minimapMarginY - (this.minimapHeight / 2)), 5, 0xFFFF00);
        this.playerMinimapCircle.setStrokeStyle(2, 0x000000);
        this.playerMinimapCircle.setDepth(5);
        const newMapJSON = require(`../../public/assets/games/${gameName}/map/map.json`);
        this.tiledMapProperties = {
            tileWidth: newMapJSON.width,
            tileHeight: newMapJSON.height,
            width: newMapJSON.width * newMapJSON.tilewidth,
            height: newMapJSON.height * newMapJSON.tileheight,
            imageWidth: (newMapJSON.width * newMapJSON.tilewidth) / this.resToImageScale,
            imageHeight: (newMapJSON.height * newMapJSON.tileheight) / this.resToImageScale
        };
        this.updateMinimapZoom();
    }
    changeMinimap(gameName) {
        if (this.playerMinimapCircle) {
            this.playerMinimapCircle.destroy();
        }
        if (this.minimap) {
            this.minimap.destroy();
        }
        this.playerMinimapCircle = undefined;
        this.minimap = undefined;
        if (gameName === 'mainportal') {
            return;
        }
        this.createMinimap(gameName);
    }
    hideMinimap() {
        if (!this.minimap || !this.playerMinimapCircle) {
            console.log('no minimap to hide');
            return;
        }
        this.minimap.visible = false;
        this.playerMinimapCircle.visible = false;
    }
    showMinimap() {
        if (!this.minimap || !this.playerMinimapCircle) {
            console.log('no minimap to show');
            return;
        }
        this.minimap.visible = true;
        this.playerMinimapCircle.visible = true;
    }
    updateMinimap(player) {
        if (!this.minimap || !this.playerMinimapCircle) {
            console.log('no minimap to update');
            return;
        }
        const xOffset = this.minimapMarginX + (this.minimapWidth / 2);
        const yOffset = (window.innerHeight - this.minimapMarginY - (this.minimapHeight / 2));
        this.minimap.x = xOffset;
        this.minimap.y = yOffset;
        let originX = player.x / this.tiledMapProperties.width;
        let originY = player.y / this.tiledMapProperties.height;
        this.minimap.setOrigin(originX, originY);
        const correction = (this.minimapWidth / 2) / this.mapScale;
        let cropX = (this.tiledMapProperties.imageWidth * originX) - correction;
        let cropY = (this.tiledMapProperties.imageHeight * originY) - correction;
        this.minimap.setCrop(cropX, cropY, correction * 2, correction * 2);
    }
    updateMinimapZoom() {
        if (!this.minimap || !this.playerMinimapCircle) {
            // console.log('no minimap to update zoom') // portal calls this too often
            return;
        }
        this.mapToWindowWidthRatio = (this.tiledMapProperties.width / window.innerWidth);
        this.mapToWindowHeightRatio = (this.tiledMapProperties.height / window.innerHeight);
        this.scaleFactor = (0, game_1.calculateScaleFactor)(window.innerWidth, window.innerHeight);
        this.minimapMarginX = window.innerWidth * this.minimapMargin;
        this.minimapMarginY = window.innerHeight * this.minimapMargin;
        this.minimapHeight = this.baseMinimapSize * this.scaleFactor;
        this.minimapWidth = this.baseMinimapSize * this.scaleFactor;
        this.minimap.setScale(this.mapScale);
        this.playerMinimapCircle.setPosition(this.minimapMarginX + (this.minimapWidth / 2), (window.innerHeight - this.minimapMarginY - (this.minimapHeight / 2)));
        this.playerMinimapCircle.setScale(this.scaleFactor);
    }
    ignoreEntity(entity) {
    }
    startMinimap(player) {
    }
}
exports.MinimapManager = MinimapManager;
