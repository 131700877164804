"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questRewards = exports.dailyQuests = exports.AchievementsData = void 0;
exports.AchievementsData = [
    {
        id: 0,
        title: 'Join Discord',
        type: 'pass',
        type2: 'discord',
        reward: {
            type: 'head',
            id: 8
        }
    },
    // {
    // 	id: 425,
    // 	title: 'Open 1 Christmas Gift',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'specific',
    // 	type4: 1,
    // 	enemyId: 106,
    // 	reward: {
    // 		type: 'coins',
    // 		quantity: 25,
    // 	}
    // },
    // {
    // 	id: 426,
    // 	title: 'Open 25 Christmas Gifts',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'specific',
    // 	type4: 25,
    // 	enemyId: 106,
    // 	reward: {
    // 		type: 'coins',
    // 		quantity: 50,
    // 	}
    // },
    // {
    // 	id: 427,
    // 	title: 'Open 50 Christmas Gifts',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'specific',
    // 	type4: 50,
    // 	enemyId: 106,
    // 	reward: {
    // 		type: 'emote',
    // 		id: 57,
    // 	}
    // },
    // {
    // 	id: 428,
    // 	title: 'Open 100 Christmas Gifts',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'specific',
    // 	type4: 100,
    // 	enemyId: 106,
    // 	reward: {
    // 		type: 'coins',
    // 		quantity: 100,
    // 	}
    // },
    {
        id: 4,
        title: 'Get Bronze Level on 10 enemies',
        type: 'monsterJournal',
        type2: 'multi',
        type3: 'bronze',
        type4: 10,
        enemyId: 10,
        reward: {
            type: 'gems',
            quantity: 50,
        }
    },
    {
        id: 5,
        title: 'Get Silver Level on 10 enemies',
        type: 'monsterJournal',
        type2: 'multi',
        type3: 'silver',
        type4: 10,
        enemyId: 10,
        reward: {
            type: 'gems',
            quantity: 100,
        }
    },
    {
        id: 6,
        title: 'Get Gold Level on 10 enemies',
        type: 'monsterJournal',
        type2: 'multi',
        type3: 'gold',
        type4: 10,
        enemyId: 10,
        reward: {
            type: 'gems',
            quantity: 150,
        }
    },
    {
        id: 7,
        title: 'Get Platinum Level on 10 enemies',
        type: 'monsterJournal',
        type2: 'multi',
        type3: 'platinum',
        type4: 10,
        enemyId: 10,
        reward: {
            type: 'head',
            id: 224
        }
    },
    {
        id: 44,
        title: 'Obtain 100 creature kills',
        type: 'stats',
        type2: 'creatureKills',
        type3: 1000,
        reward: {
            type: 'coins',
            quantity: 25,
        }
    },
    {
        id: 45,
        title: 'Break 100 chests',
        type: 'stats',
        type2: 'chestKills',
        type3: 100,
        reward: {
            type: 'coins',
            quantity: 25,
        }
    },
    {
        id: 46,
        title: 'Obtain 100,000 total score',
        type: 'stats',
        type2: 'totalScore',
        type3: 100000,
        reward: {
            type: 'coins',
            quantity: 25,
        }
    },
    {
        id: 1,
        title: 'Obtain 1000 creature kills',
        type: 'stats',
        type2: 'creatureKills',
        type3: 1000,
        reward: {
            type: 'coins',
            quantity: 50,
        }
    },
    {
        id: 2,
        title: 'Break 1000 chests',
        type: 'stats',
        type2: 'chestKills',
        type3: 1000,
        reward: {
            type: 'coins',
            quantity: 50,
        }
    },
    {
        id: 3,
        title: 'Obtain 1,000,000 total score',
        type: 'stats',
        type2: 'totalScore',
        type3: 1000000,
        reward: {
            type: 'coins',
            quantity: 50,
        }
    },
    {
        id: 47,
        title: 'Obtain 10,000 creature kills',
        type: 'stats',
        type2: 'creatureKills',
        type3: 10000,
        reward: {
            type: 'head',
            id: 67,
        }
    },
    {
        id: 48,
        title: 'Break 10,000 chests',
        type: 'stats',
        type2: 'chestKills',
        type3: 10000,
        reward: {
            type: 'head',
            id: 116,
        }
    },
    {
        id: 49,
        title: 'Obtain 10,000,000 total score',
        type: 'stats',
        type2: 'totalScore',
        type3: 10000000,
        reward: {
            type: 'head',
            id: 79,
        }
    },
    {
        id: 50,
        title: 'Obtain 100,000 creature kills',
        type: 'stats',
        type2: 'creatureKills',
        type3: 100000,
        reward: {
            type: 'gems',
            quantity: 50,
        }
    },
    {
        id: 51,
        title: 'Break 100,000 chests',
        type: 'stats',
        type2: 'chestKills',
        type3: 100000,
        reward: {
            type: 'gems',
            quantity: 50,
        }
    },
    {
        id: 52,
        title: 'Obtain 100,000,000 total score',
        type: 'stats',
        type2: 'totalScore',
        type3: 100000000,
        reward: {
            type: 'gems',
            quantity: 50,
        }
    },
    {
        id: 8,
        title: 'Get Bronze Level on Slimes',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 1,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 9,
        title: 'Get Bronze Level on Woodland Critters',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 7,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 10,
        title: 'Get Bronze Level on Chimeras',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 6,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 11,
        title: 'Get Bronze Level on Cannons',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 9,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 12,
        title: 'Get Bronze Level on Charlies',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 5,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 13,
        title: 'Get Bronze Level on Green Dragon Whelps',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 15,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 14,
        title: 'Get Bronze Level on Dragon Whelps',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 8,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 15,
        title: 'Get Bronze Level on Dark Wizards',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 2,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 16,
        title: 'Get Bronze Level on the Dragon Lord',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 10,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    // {
    // 	id: 100,
    // 	title: 'Get Bronze Level on Lava Slime',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'bronze',
    // 	enemyId: 20,
    // 	reward: {
    // 		type: 'coins',
    // 		quantity: 25
    // 	}
    // },
    {
        id: 101,
        title: 'Get Bronze Level on Giant Lava Slime',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 28,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 102,
        title: 'Get Bronze Level on Hellhound Pack Leader',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 25,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 103,
        title: 'Get Bronze Level on Mega Cannon',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 30,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 104,
        title: 'Get Bronze Level on Head Dark Wizard',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 24,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 105,
        title: 'Get Bronze Level on Crabs',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 20,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 106,
        title: 'Get Bronze Level on Massive Crabs',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 31,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 107,
        title: 'Get Bronze Level on King Crab',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 32,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 108,
        title: 'Get Bronze Level on Piranha',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 33,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 109,
        title: 'Get Bronze Level on King Piranha',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 34,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 110,
        title: 'Get Bronze Level on Seahorse',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 35,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 111,
        title: 'Get Bronze Level on Superior Seahorse',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 36,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    // {
    // 	id: 112,
    // 	title: 'Get Bronze Level on King Seahorse',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'bronze',
    // 	enemyId: 36,
    // 	reward: {
    // 		type: 'coins',
    // 		quantity: 25
    // 	}
    // },
    {
        id: 113,
        title: 'Get Bronze Level on Ice Slimes',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 43,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 114,
        title: 'Get Bronze Level on Giant Ice Slimes',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 44,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 115,
        title: 'Get Bronze Level on Mammoths',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 38,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 116,
        title: 'Get Bronze Level on Superior Mammoths',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 39,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 117,
        title: 'Get Bronze Level on King Mammoth',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 40,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    {
        id: 118,
        title: 'Get Bronze Level on Ice Golem',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'bronze',
        enemyId: 41,
        reward: {
            type: 'coins',
            quantity: 25
        }
    },
    // {
    // 	id: 119,
    // 	title: 'Get Bronze Level on Ice Golem King',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'bronze',
    // 	enemyId: 40,
    // 	reward: {
    // 		type: 'coins',
    // 		quantity: 25
    // 	}
    // },
    {
        id: 17,
        title: 'Get Silver Level on Slimes',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 1,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 18,
        title: 'Get Silver Level on Woodland Critters',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 7,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 19,
        title: 'Get Silver Level on Chimeras',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 6,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 20,
        title: 'Get Silver Level on Cannons',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 9,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 21,
        title: 'Get Silver Level on Charlies',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 5,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 22,
        title: 'Get Silver Level on Green Dragon Whelps',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 15,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 23,
        title: 'Get Silver Level on Dragon Whelps',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 8,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 24,
        title: 'Get Silver Level on Dark Wizards',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 2,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 25,
        title: 'Get Silver Level on the Dragon Lord',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 10,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    // {
    // 	id: 200,
    // 	title: 'Get Silver Level on Lava Slime',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'silver',
    // 	enemyId: 20,
    // 	reward: {
    // 		type: 'coins',
    // 		quantity: 50
    // 	}
    // },
    {
        id: 201,
        title: 'Get Silver Level on Giant Lava Slime',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 28,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 202,
        title: 'Get Silver Level on Hellhound Pack Leader',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 25,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 203,
        title: 'Get Silver Level on Mega Cannon',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 30,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 204,
        title: 'Get Silver Level on Head Dark Wizard',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 24,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 205,
        title: 'Get Silver Level on Crabs',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 20,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 206,
        title: 'Get Silver Level on Massive Crabs',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 31,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 207,
        title: 'Get Silver Level on King Crab',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 32,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 208,
        title: 'Get Silver Level on Piranha',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 33,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 209,
        title: 'Get Silver Level on King Piranha',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 34,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 210,
        title: 'Get Silver Level on Seahorse',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 35,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 211,
        title: 'Get Silver Level on Superior Seahorse',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 36,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    // {
    // 	id: 212,
    // 	title: 'Get Silver Level on King Seahorse',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'silver',
    // 	enemyId: 36,
    // 	reward: {
    // 		type: 'coins',
    // 		quantity: 50
    // 	}
    // },
    {
        id: 213,
        title: 'Get Silver Level on Ice Slimes',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 43,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 214,
        title: 'Get Silver Level on Giant Ice Slimes',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 44,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 215,
        title: 'Get Silver Level on Mammoths',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 38,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 216,
        title: 'Get Silver Level on Superior Mammoths',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 39,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 217,
        title: 'Get Silver Level on King Mammoth',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 40,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    {
        id: 218,
        title: 'Get Silver Level on Ice Golem',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'silver',
        enemyId: 41,
        reward: {
            type: 'coins',
            quantity: 50
        }
    },
    // {
    // 	id: 219,
    // 	title: 'Get Silver Level on Ice Golem King',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'silver',
    // 	enemyId: 40,
    // 	reward: {
    // 		type: 'coins',
    // 		quantity: 50
    // 	}
    // },
    {
        id: 26,
        title: 'Get Gold Level on Slimes',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 1,
        reward: {
            type: 'emote',
            id: 15
        }
    },
    {
        id: 27,
        title: 'Get Gold Level on Woodland Critters',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 7,
        reward: {
            type: 'emote',
            id: 16
        }
    },
    {
        id: 28,
        title: 'Get Gold Level on Chimeras',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 6,
        reward: {
            type: 'emote',
            id: 17
        }
    },
    {
        id: 29,
        title: 'Get Gold Level on Cannons',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 9,
        reward: {
            type: 'emote',
            id: 18
        }
    },
    {
        id: 30,
        title: 'Get Gold Level on Charlies',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 5,
        reward: {
            type: 'emote',
            id: 19
        }
    },
    {
        id: 31,
        title: 'Get Gold Level on Green Dragon Whelps',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 15,
        reward: {
            type: 'emote',
            id: 21
        }
    },
    {
        id: 32,
        title: 'Get Gold Level on Dragon Whelps',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 8,
        reward: {
            type: 'emote',
            id: 22
        }
    },
    {
        id: 33,
        title: 'Get Gold Level on Dark Wizards',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 2,
        reward: {
            type: 'emote',
            id: 20
        }
    },
    {
        id: 34,
        title: 'Get Gold Level on the Dragon Lord',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 10,
        reward: {
            type: 'emote',
            id: 23
        }
    },
    // {
    // 	id: 300,
    // 	title: 'Get Gold Level on Lava Slime',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'gold',
    // 	enemyId: 20,
    // 		reward: {
    // type: 'emote',
    // id: 23
    // 	}
    // },
    {
        id: 301,
        title: 'Get Gold Level on Giant Lava Slime',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 28,
        reward: {
            type: 'emote',
            id: 24
        }
    },
    {
        id: 302,
        title: 'Get Gold Level on Hellhound Pack Leader',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 25,
        reward: {
            type: 'emote',
            id: 25
        }
    },
    {
        id: 303,
        title: 'Get Gold Level on Mega Cannon',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 30,
        reward: {
            type: 'emote',
            id: 26
        }
    },
    {
        id: 304,
        title: 'Get Gold Level on Head Dark Wizard',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 24,
        reward: {
            type: 'emote',
            id: 27
        }
    },
    {
        id: 305,
        title: 'Get Gold Level on Crabs',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 20,
        reward: {
            type: 'emote',
            id: 28
        }
    },
    {
        id: 306,
        title: 'Get Gold Level on Massive Crabs',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 31,
        reward: {
            type: 'emote',
            id: 29
        }
    },
    {
        id: 307,
        title: 'Get Gold Level on King Crab',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 32,
        reward: {
            type: 'emote',
            id: 30
        }
    },
    {
        id: 308,
        title: 'Get Gold Level on Piranha',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 33,
        reward: {
            type: 'emote',
            id: 31
        }
    },
    {
        id: 309,
        title: 'Get Gold Level on King Piranha',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 34,
        reward: {
            type: 'emote',
            id: 32
        }
    },
    {
        id: 310,
        title: 'Get Gold Level on Seahorse',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 35,
        reward: {
            type: 'emote',
            id: 33
        }
    },
    {
        id: 311,
        title: 'Get Gold Level on Superior Seahorse',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 36,
        reward: {
            type: 'emote',
            id: 34
        }
    },
    // {
    // 	id: 312,
    // 	title: 'Get Gold Level on King Seahorse',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'gold',
    // 	enemyId: 36,
    // 		reward: {
    // type: 'emote',
    // id: 23
    // 	}
    // },
    {
        id: 313,
        title: 'Get Gold Level on Ice Slimes',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 43,
        reward: {
            type: 'emote',
            id: 36
        }
    },
    {
        id: 314,
        title: 'Get Gold Level on Giant Ice Slimes',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 44,
        reward: {
            type: 'emote',
            id: 37
        }
    },
    {
        id: 315,
        title: 'Get Gold Level on Mammoths',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 38,
        reward: {
            type: 'emote',
            id: 38
        }
    },
    {
        id: 316,
        title: 'Get Gold Level on Superior Mammoths',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 39,
        reward: {
            type: 'emote',
            id: 39
        }
    },
    {
        id: 317,
        title: 'Get Gold Level on King Mammoth',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 40,
        reward: {
            type: 'emote',
            id: 40
        }
    },
    {
        id: 318,
        title: 'Get Gold Level on Ice Golem',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'gold',
        enemyId: 41,
        reward: {
            type: 'emote',
            id: 41
        }
    },
    // {
    // 	id: 319,
    // 	title: 'Get Gold Level on Ice Golem King',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'gold',
    // 	enemyId: 40,
    // 		reward: {
    // type: 'emote',
    // id: 23
    // 	}
    // },
    {
        id: 35,
        title: 'Get Platinum Level on Slimes',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 1,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 36,
        title: 'Get Platinum Level on Woodland Critters',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 7,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 37,
        title: 'Get Platinum Level on Chimeras',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 6,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 38,
        title: 'Get Platinum Level on Cannons',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 9,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 39,
        title: 'Get Platinum Level on Charlies',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 5,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 40,
        title: 'Get Platinum Level on Green Dragon Whelps',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 15,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 41,
        title: 'Get Platinum Level on Dragon Whelps',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 8,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 42,
        title: 'Get Platinum Level on Dark Wizards',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 2,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 43,
        title: 'Get Platinum Level on the Dragon Lord',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 10,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    // {
    // 	id: 400,
    // 	title: 'Get Platinum Level on Lava Slime',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'platinum',
    // 	enemyId: 20,
    // reward: {
    // 	type: 'gems',
    // 	quantity: 25,
    // }
    // },
    {
        id: 401,
        title: 'Get Platinum Level on Giant Lava Slime',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 28,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 402,
        title: 'Get Platinum Level on Hellhound Pack Leader',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 25,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 403,
        title: 'Get Platinum Level on Mega Cannon',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 30,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 404,
        title: 'Get Platinum Level on Head Dark Wizard',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 24,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 405,
        title: 'Get Platinum Level on Crabs',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 20,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 406,
        title: 'Get Platinum Level on Massive Crabs',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 31,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 407,
        title: 'Get Platinum Level on King Crab',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 32,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 408,
        title: 'Get Platinum Level on Piranha',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 33,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 409,
        title: 'Get Platinum Level on King Piranha',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 34,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 410,
        title: 'Get Platinum Level on Seahorse',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 35,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 411,
        title: 'Get Platinum Level on Superior Seahorse',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 36,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    // {
    // 	id: 412,
    // 	title: 'Get Platinum Level on King Seahorse',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'platinum',
    // 	enemyId: 36,
    // reward: {
    // 	type: 'gems',
    // 	quantity: 25,
    // }
    // },
    {
        id: 413,
        title: 'Get Platinum Level on Ice Slimes',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 43,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 414,
        title: 'Get Platinum Level on Giant Ice Slimes',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 44,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 415,
        title: 'Get Platinum Level on Mammoths',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 38,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 416,
        title: 'Get Platinum Level on Superior Mammoths',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 39,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 417,
        title: 'Get Platinum Level on King Mammoth',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 40,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 418,
        title: 'Get Platinum Level on Ice Golem',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'platinum',
        enemyId: 41,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 419,
        title: 'Open 1 Pumpkin Chest',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'specific',
        type4: 1,
        enemyId: 105,
        reward: {
            type: 'coins',
            quantity: 25,
        }
    },
    {
        id: 420,
        title: 'Open 25 Pumpkin Chests',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'specific',
        type4: 25,
        enemyId: 105,
        reward: {
            type: 'coins',
            quantity: 50,
        }
    },
    {
        id: 421,
        title: 'Open 50 Pumpkin Chests',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'specific',
        type4: 50,
        enemyId: 105,
        reward: {
            type: 'emote',
            id: 50,
        }
    },
    {
        id: 422,
        title: 'Kill Jack-O-Charlie',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'specific',
        type4: 1,
        enemyId: 45,
        reward: {
            type: 'gems',
            quantity: 25,
        }
    },
    {
        id: 423,
        title: 'Kill Jack-O-Charlie 10 times',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'specific',
        type4: 10,
        enemyId: 45,
        reward: {
            type: 'coins',
            quantity: 50,
        }
    },
    {
        id: 424,
        title: 'Kill Jack-O-Charlie 25 times',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'specific',
        type4: 25,
        enemyId: 45,
        reward: {
            type: 'emote',
            id: 51,
        }
    },
    {
        id: 429,
        title: 'Open 1 Currency Chest',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'specific',
        type4: 1,
        enemyId: 107,
        reward: {
            type: 'coins',
            quantity: 25,
        }
    },
    {
        id: 430,
        title: 'Open 25 Currency Chests',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'specific',
        type4: 25,
        enemyId: 107,
        reward: {
            type: 'coins',
            quantity: 50,
        }
    },
    {
        id: 431,
        title: 'Open 50 Currency Chests',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'specific',
        type4: 50,
        enemyId: 107,
        reward: {
            type: 'coins',
            quantity: 75,
        }
    },
    {
        id: 432,
        title: 'Open 100 Currency CHests',
        type: 'monsterJournal',
        type2: 'single',
        type3: 'specific',
        type4: 100,
        enemyId: 107,
        reward: {
            type: 'coins',
            quantity: 100,
        }
    },
    // {
    // 	id: 419,
    // 	title: 'Get Platinum Level on Ice Golem King',
    // 	type: 'monsterJournal',
    // 	type2: 'single',
    // 	type3: 'platinum',
    // 	enemyId: 40,
    // reward: {
    // 	type: 'gems',
    // 	quantity: 25,
    // }
    // },
    // {
    //     id: 53,
    //     title: 'Reach level 5',
    //     type: 'accountLevel',
    //     levelNeeded: 5,
    //     reward: {
    //         type: 'coins',
    //         quantity: 25
    //     }
    // },
    // {
    //     id: 54,
    //     title: 'Reach level 10',
    //     type: 'accountLevel',
    //     levelNeeded: 10,
    //     reward: {
    //         type: 'coins',
    //         quantity: 25
    //     }
    // },
    // {
    //     id: 55,
    //     title: 'Reach level 15',
    //     type: 'accountLevel',
    //     levelNeeded: 15,
    //     reward: {
    //         type: 'coins',
    //         quantity: 25
    //     }
    // },
    // {
    //     id: 56,
    //     title: 'Reach level 20',
    //     type: 'accountLevel',
    //     levelNeeded: 20,
    //     reward: {
    //         type: 'coins',
    //         quantity: 25
    //     }
    // },
];
exports.dailyQuests = [
    {
        id: 1,
        title: 'Kill King Slime',
        difficulty: 'easy',
        type: 'kill',
        enemyId: 4,
        quantity: 1,
    },
    {
        id: 2,
        title: 'Kill Slimes',
        difficulty: 'easy',
        type: 'kill',
        enemyId: 1,
        quantity: 25,
    },
    {
        id: 3,
        title: 'Kill Chimeras',
        difficulty: 'easy',
        type: 'kill',
        enemyId: 6,
        quantity: 15,
    },
    {
        id: 4,
        title: 'Kill Woodland Critters',
        difficulty: 'easy',
        type: 'kill',
        enemyId: 7,
        quantity: 15,
    },
    {
        id: 5,
        title: 'Kill Cannons',
        difficulty: 'medium',
        type: 'kill',
        enemyId: 9,
        quantity: 20,
    },
    {
        id: 6,
        title: 'Kill Charlies',
        difficulty: 'medium',
        type: 'kill',
        enemyId: 5,
        quantity: 15,
    },
    {
        id: 7,
        title: 'Kill Dark Wizards',
        difficulty: 'hard',
        type: 'kill',
        enemyId: 2,
        quantity: 15,
    },
    {
        id: 8,
        title: 'Kill King Chimera',
        difficulty: 'hard',
        type: 'kill',
        enemyId: 12,
        quantity: 1,
    },
    {
        id: 9,
        title: 'Kill Woodland Beast',
        difficulty: 'hard',
        type: 'kill',
        enemyId: 13,
        quantity: 1,
    },
    {
        id: 10,
        title: 'Kill Crabs',
        difficulty: 'easy',
        type: 'kill',
        enemyId: 20,
        quantity: 15,
    },
    {
        id: 11,
        title: 'Kill Sea Horses',
        difficulty: 'medium',
        type: 'kill',
        enemyId: 35,
        quantity: 15,
    },
    {
        id: 12,
        title: 'Kill Superior Crabs',
        difficulty: 'medium',
        type: 'kill',
        enemyId: 31,
        quantity: 8,
    },
    {
        id: 13,
        title: 'Kill Mammoths',
        difficulty: 'hard',
        type: 'kill',
        enemyId: 38,
        quantity: 15,
    },
    {
        id: 14,
        title: 'Kill Ice Golems',
        difficulty: 'hard',
        type: 'kill',
        enemyId: 41,
        quantity: 15,
    },
];
exports.questRewards = {
    easy: 15,
    medium: 20,
    hard: 25,
};
// module.exports = Object.freeze({
// 	challenges: [
// 		{
// 			id: 0,
// 			title: 'Join Discord',
// 			type: 'pass',
// 			type2: 'discord',
// 			// type3: 'platinum',
// 			// type4: 10,
// 			// enemyId: 10,
// 			reward: {
// 				// type: 'emote',
// 				// id: 23
// 				type: 'head',
// 				id: 8
// 			}
// 		},
// 		{
// 			id: 4,
// 			title: 'Get Bronze Level on 10 enemies',
// 			type: 'monsterJournal',
// 			type2: 'multi',
// 			type3: 'bronze',
// 			type4: 10,
// 			enemyId: 10,
// 			reward: {
// 				type: 'gems',
// 				quantity: 50,
// 			}
// 		},
// 		{
// 			id: 5,
// 			title: 'Get Silver Level on 10 enemies',
// 			type: 'monsterJournal',
// 			type2: 'multi',
// 			type3: 'silver',
// 			type4: 10,
// 			enemyId: 10,
// 			reward: {
// 				type: 'gems',
// 				quantity: 100,
// 			}
// 		},
// 		{
// 			id: 6,
// 			title: 'Get Gold Level on 10 enemies',
// 			type: 'monsterJournal',
// 			type2: 'multi',
// 			type3: 'gold',
// 			type4: 10,
// 			enemyId: 10,
// 			reward: {
// 				type: 'gems',
// 				quantity: 150,
// 			}
// 		},
// 		{
// 			id: 7,
// 			title: 'Get Platinum Level on 10 enemies',
// 			type: 'monsterJournal',
// 			type2: 'multi',
// 			type3: 'platinum',
// 			type4: 10,
// 			enemyId: 10,
// 			reward: {
// 				type: 'head',
// 				id: 224
// 			}
// 		},
// 		{
// 			id: 44,
// 			title: 'Obtain 100 creature kills',
// 			type: 'stats',
// 			type2: 'creatureKills',
// 			type3: 1000,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 45,
// 			title: 'Break 100 chests',
// 			type: 'stats',
// 			type2: 'chestKills',
// 			type3: 100,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 46,
// 			title: 'Obtain 100,000 total score',
// 			type: 'stats',
// 			type2: 'totalScore',
// 			type3: 100000,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 1,
// 			title: 'Obtain 1000 creature kills',
// 			type: 'stats',
// 			type2: 'creatureKills',
// 			type3: 1000,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50,
// 			}
// 		},
// 		{
// 			id: 2,
// 			title: 'Break 1000 chests',
// 			type: 'stats',
// 			type2: 'chestKills',
// 			type3: 1000,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50,
// 			}
// 		},
// 		{
// 			id: 3,
// 			title: 'Obtain 1,000,000 total score',
// 			type: 'stats',
// 			type2: 'totalScore',
// 			type3: 1000000,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50,
// 			}
// 		},
// 		{
// 			id: 47,
// 			title: 'Obtain 10,000 creature kills',
// 			type: 'stats',
// 			type2: 'creatureKills',
// 			type3: 10000,
// 			reward: {
// 				type: 'head',
// 				id: 67,
// 			}
// 		},
// 		{
// 			id: 48,
// 			title: 'Break 10,000 chests',
// 			type: 'stats',
// 			type2: 'chestKills',
// 			type3: 10000,
// 			reward: {
// 				type: 'head',
// 				id: 116,
// 			}
// 		},
// 		{
// 			id: 49,
// 			title: 'Obtain 10,000,000 total score',
// 			type: 'stats',
// 			type2: 'totalScore',
// 			type3: 10000000,
// 			reward: {
// 				type: 'head',
// 				id: 79,
// 			}
// 		},
// 		{
// 			id: 50,
// 			title: 'Obtain 100,000 creature kills',
// 			type: 'stats',
// 			type2: 'creatureKills',
// 			type3: 100000,
// 			reward: {
// 				type: 'gems',
// 				quantity: 50,
// 			}
// 		},
// 		{
// 			id: 51,
// 			title: 'Break 100,000 chests',
// 			type: 'stats',
// 			type2: 'chestKills',
// 			type3: 100000,
// 			reward: {
// 				type: 'gems',
// 				quantity: 50,
// 			}
// 		},
// 		{
// 			id: 52,
// 			title: 'Obtain 100,000,000 total score',
// 			type: 'stats',
// 			type2: 'totalScore',
// 			type3: 100000000,
// 			reward: {
// 				type: 'gems',
// 				quantity: 50,
// 			}
// 		},
// 		{
// 			id: 8,
// 			title: 'Get Bronze Level on Slimes',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 1,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 9,
// 			title: 'Get Bronze Level on Woodland Critters',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 7,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 10,
// 			title: 'Get Bronze Level on Chimeras',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 6,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 11,
// 			title: 'Get Bronze Level on Cannons',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 9,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 12,
// 			title: 'Get Bronze Level on Charlies',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 5,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 13,
// 			title: 'Get Bronze Level on Green Dragon Whelps',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 15,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 14,
// 			title: 'Get Bronze Level on Dragon Whelps',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 8,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 15,
// 			title: 'Get Bronze Level on Dark Wizards',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 2,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 16,
// 			title: 'Get Bronze Level on the Dragon Lord',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 10,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		// {
// 		// 	id: 100,
// 		// 	title: 'Get Bronze Level on Lava Slime',
// 		// 	type: 'monsterJournal',
// 		// 	type2: 'single',
// 		// 	type3: 'bronze',
// 		// 	enemyId: 20,
// 		// 	reward: {
// 		// 		type: 'coins',
// 		// 		quantity: 25
// 		// 	}
// 		// },
// 		{
// 			id: 101,
// 			title: 'Get Bronze Level on Giant Lava Slime',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 28,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 102,
// 			title: 'Get Bronze Level on Hellhound Pack Leader',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 25,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 103,
// 			title: 'Get Bronze Level on Mega Cannon',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 30,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 104,
// 			title: 'Get Bronze Level on Head Dark Wizard',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 24,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 105,
// 			title: 'Get Bronze Level on Crabs',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 20,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 106,
// 			title: 'Get Bronze Level on Massive Crabs',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 31,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 107,
// 			title: 'Get Bronze Level on King Crab',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 32,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 108,
// 			title: 'Get Bronze Level on Piranha',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 33,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 109,
// 			title: 'Get Bronze Level on King Piranha',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 34,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 110,
// 			title: 'Get Bronze Level on Seahorse',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 35,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 111,
// 			title: 'Get Bronze Level on Superior Seahorse',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 36,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		// {
// 		// 	id: 112,
// 		// 	title: 'Get Bronze Level on King Seahorse',
// 		// 	type: 'monsterJournal',
// 		// 	type2: 'single',
// 		// 	type3: 'bronze',
// 		// 	enemyId: 36,
// 		// 	reward: {
// 		// 		type: 'coins',
// 		// 		quantity: 25
// 		// 	}
// 		// },
// 		{
// 			id: 113,
// 			title: 'Get Bronze Level on Ice Slimes',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 43,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 114,
// 			title: 'Get Bronze Level on Giant Ice Slimes',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 44,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 115,
// 			title: 'Get Bronze Level on Mammoths',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 38,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 116,
// 			title: 'Get Bronze Level on Superior Mammoths',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 39,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 117,
// 			title: 'Get Bronze Level on King Mammoth',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 40,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		{
// 			id: 118,
// 			title: 'Get Bronze Level on Ice Golem',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'bronze',
// 			enemyId: 41,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25
// 			}
// 		},
// 		// {
// 		// 	id: 119,
// 		// 	title: 'Get Bronze Level on Ice Golem King',
// 		// 	type: 'monsterJournal',
// 		// 	type2: 'single',
// 		// 	type3: 'bronze',
// 		// 	enemyId: 40,
// 		// 	reward: {
// 		// 		type: 'coins',
// 		// 		quantity: 25
// 		// 	}
// 		// },
// 		{
// 			id: 17,
// 			title: 'Get Silver Level on Slimes',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 1,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 18,
// 			title: 'Get Silver Level on Woodland Critters',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 7,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 19,
// 			title: 'Get Silver Level on Chimeras',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 6,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 20,
// 			title: 'Get Silver Level on Cannons',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 9,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 21,
// 			title: 'Get Silver Level on Charlies',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 5,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 22,
// 			title: 'Get Silver Level on Green Dragon Whelps',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 15,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 23,
// 			title: 'Get Silver Level on Dragon Whelps',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 8,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 24,
// 			title: 'Get Silver Level on Dark Wizards',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 2,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 25,
// 			title: 'Get Silver Level on the Dragon Lord',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 10,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		// {
// 		// 	id: 200,
// 		// 	title: 'Get Silver Level on Lava Slime',
// 		// 	type: 'monsterJournal',
// 		// 	type2: 'single',
// 		// 	type3: 'silver',
// 		// 	enemyId: 20,
// 		// 	reward: {
// 		// 		type: 'coins',
// 		// 		quantity: 50
// 		// 	}
// 		// },
// 		{
// 			id: 201,
// 			title: 'Get Silver Level on Giant Lava Slime',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 28,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 202,
// 			title: 'Get Silver Level on Hellhound Pack Leader',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 25,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 203,
// 			title: 'Get Silver Level on Mega Cannon',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 30,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 204,
// 			title: 'Get Silver Level on Head Dark Wizard',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 24,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 205,
// 			title: 'Get Silver Level on Crabs',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 20,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 206,
// 			title: 'Get Silver Level on Massive Crabs',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 31,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 207,
// 			title: 'Get Silver Level on King Crab',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 32,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 208,
// 			title: 'Get Silver Level on Piranha',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 33,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 209,
// 			title: 'Get Silver Level on King Piranha',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 34,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 210,
// 			title: 'Get Silver Level on Seahorse',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 35,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 211,
// 			title: 'Get Silver Level on Superior Seahorse',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 36,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		// {
// 		// 	id: 212,
// 		// 	title: 'Get Silver Level on King Seahorse',
// 		// 	type: 'monsterJournal',
// 		// 	type2: 'single',
// 		// 	type3: 'silver',
// 		// 	enemyId: 36,
// 		// 	reward: {
// 		// 		type: 'coins',
// 		// 		quantity: 50
// 		// 	}
// 		// },
// 		{
// 			id: 213,
// 			title: 'Get Silver Level on Ice Slimes',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 43,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 214,
// 			title: 'Get Silver Level on Giant Ice Slimes',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 44,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 215,
// 			title: 'Get Silver Level on Mammoths',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 38,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 216,
// 			title: 'Get Silver Level on Superior Mammoths',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 39,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 217,
// 			title: 'Get Silver Level on King Mammoth',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 40,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		{
// 			id: 218,
// 			title: 'Get Silver Level on Ice Golem',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'silver',
// 			enemyId: 41,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50
// 			}
// 		},
// 		// {
// 		// 	id: 219,
// 		// 	title: 'Get Silver Level on Ice Golem King',
// 		// 	type: 'monsterJournal',
// 		// 	type2: 'single',
// 		// 	type3: 'silver',
// 		// 	enemyId: 40,
// 		// 	reward: {
// 		// 		type: 'coins',
// 		// 		quantity: 50
// 		// 	}
// 		// },
// 		{
// 			id: 26,
// 			title: 'Get Gold Level on Slimes',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 1,
// 			reward: {
// 				type: 'emote',
// 				id: 15
// 			}
// 		},
// 		{
// 			id: 27,
// 			title: 'Get Gold Level on Woodland Critters',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 7,
// 			reward: {
// 				type: 'emote',
// 				id: 16
// 			}
// 		},
// 		{
// 			id: 28,
// 			title: 'Get Gold Level on Chimeras',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 6,
// 			reward: {
// 				type: 'emote',
// 				id: 17
// 			}
// 		},
// 		{
// 			id: 29,
// 			title: 'Get Gold Level on Cannons',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 9,
// 			reward: {
// 				type: 'emote',
// 				id: 18
// 			}
// 		},
// 		{
// 			id: 30,
// 			title: 'Get Gold Level on Charlies',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 5,
// 			reward: {
// 				type: 'emote',
// 				id: 19
// 			}
// 		},
// 		{
// 			id: 31,
// 			title: 'Get Gold Level on Green Dragon Whelps',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 15,
// 			reward: {
// 				type: 'emote',
// 				id: 21
// 			}
// 		},
// 		{
// 			id: 32,
// 			title: 'Get Gold Level on Dragon Whelps',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 8,
// 			reward: {
// 				type: 'emote',
// 				id: 22
// 			}
// 		},
// 		{
// 			id: 33,
// 			title: 'Get Gold Level on Dark Wizards',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 2,
// 			reward: {
// 				type: 'emote',
// 				id: 20
// 			}
// 		},
// 		{
// 			id: 34,
// 			title: 'Get Gold Level on the Dragon Lord',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 10,
// 			reward: {
// 				type: 'emote',
// 				id: 23
// 			}
// 		},
// 		// {
// 		// 	id: 300,
// 		// 	title: 'Get Gold Level on Lava Slime',
// 		// 	type: 'monsterJournal',
// 		// 	type2: 'single',
// 		// 	type3: 'gold',
// 		// 	enemyId: 20,
// 		// 		reward: {
// 		// type: 'emote',
// 		// id: 23
// 		// 	}
// 		// },
// 		{
// 			id: 301,
// 			title: 'Get Gold Level on Giant Lava Slime',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 28,
// 			reward: {
// 				type: 'emote',
// 				id: 24
// 			}
// 		},
// 		{
// 			id: 302,
// 			title: 'Get Gold Level on Hellhound Pack Leader',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 25,
// 			reward: {
// 				type: 'emote',
// 				id: 25
// 			}
// 		},
// 		{
// 			id: 303,
// 			title: 'Get Gold Level on Mega Cannon',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 30,
// 			reward: {
// 				type: 'emote',
// 				id: 26
// 			}
// 		},
// 		{
// 			id: 304,
// 			title: 'Get Gold Level on Head Dark Wizard',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 24,
// 			reward: {
// 				type: 'emote',
// 				id: 27
// 			}
// 		},
// 		{
// 			id: 305,
// 			title: 'Get Gold Level on Crabs',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 20,
// 			reward: {
// 				type: 'emote',
// 				id: 28
// 			}
// 		},
// 		{
// 			id: 306,
// 			title: 'Get Gold Level on Massive Crabs',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 31,
// 			reward: {
// 				type: 'emote',
// 				id: 29
// 			}
// 		},
// 		{
// 			id: 307,
// 			title: 'Get Gold Level on King Crab',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 32,
// 			reward: {
// 				type: 'emote',
// 				id: 30
// 			}
// 		},
// 		{
// 			id: 308,
// 			title: 'Get Gold Level on Piranha',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 33,
// 			reward: {
// 				type: 'emote',
// 				id: 31
// 			}
// 		},
// 		{
// 			id: 309,
// 			title: 'Get Gold Level on King Piranha',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 34,
// 			reward: {
// 				type: 'emote',
// 				id: 32
// 			}
// 		},
// 		{
// 			id: 310,
// 			title: 'Get Gold Level on Seahorse',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 35,
// 			reward: {
// 				type: 'emote',
// 				id: 33
// 			}
// 		},
// 		{
// 			id: 311,
// 			title: 'Get Gold Level on Superior Seahorse',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 36,
// 			reward: {
// 				type: 'emote',
// 				id: 34
// 			}
// 		},
// 		// {
// 		// 	id: 312,
// 		// 	title: 'Get Gold Level on King Seahorse',
// 		// 	type: 'monsterJournal',
// 		// 	type2: 'single',
// 		// 	type3: 'gold',
// 		// 	enemyId: 36,
// 		// 		reward: {
// 		// type: 'emote',
// 		// id: 23
// 		// 	}
// 		// },
// 		{
// 			id: 313,
// 			title: 'Get Gold Level on Ice Slimes',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 43,
// 			reward: {
// 				type: 'emote',
// 				id: 36
// 			}
// 		},
// 		{
// 			id: 314,
// 			title: 'Get Gold Level on Giant Ice Slimes',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 44,
// 			reward: {
// 				type: 'emote',
// 				id: 37
// 			}
// 		},
// 		{
// 			id: 315,
// 			title: 'Get Gold Level on Mammoths',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 38,
// 			reward: {
// 				type: 'emote',
// 				id: 38
// 			}
// 		},
// 		{
// 			id: 316,
// 			title: 'Get Gold Level on Superior Mammoths',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 39,
// 			reward: {
// 				type: 'emote',
// 				id: 39
// 			}
// 		},
// 		{
// 			id: 317,
// 			title: 'Get Gold Level on King Mammoth',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 40,
// 			reward: {
// 				type: 'emote',
// 				id: 40
// 			}
// 		},
// 		{
// 			id: 318,
// 			title: 'Get Gold Level on Ice Golem',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'gold',
// 			enemyId: 41,
// 			reward: {
// 				type: 'emote',
// 				id: 41
// 			}
// 		},
// 		// {
// 		// 	id: 319,
// 		// 	title: 'Get Gold Level on Ice Golem King',
// 		// 	type: 'monsterJournal',
// 		// 	type2: 'single',
// 		// 	type3: 'gold',
// 		// 	enemyId: 40,
// 		// 		reward: {
// 		// type: 'emote',
// 		// id: 23
// 		// 	}
// 		// },
// 		{
// 			id: 35,
// 			title: 'Get Platinum Level on Slimes',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 1,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 36,
// 			title: 'Get Platinum Level on Woodland Critters',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 7,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 37,
// 			title: 'Get Platinum Level on Chimeras',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 6,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 38,
// 			title: 'Get Platinum Level on Cannons',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 9,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 39,
// 			title: 'Get Platinum Level on Charlies',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 5,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 40,
// 			title: 'Get Platinum Level on Green Dragon Whelps',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 15,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 41,
// 			title: 'Get Platinum Level on Dragon Whelps',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 8,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 42,
// 			title: 'Get Platinum Level on Dark Wizards',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 2,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 43,
// 			title: 'Get Platinum Level on the Dragon Lord',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 10,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		// {
// 		// 	id: 400,
// 		// 	title: 'Get Platinum Level on Lava Slime',
// 		// 	type: 'monsterJournal',
// 		// 	type2: 'single',
// 		// 	type3: 'platinum',
// 		// 	enemyId: 20,
// 		// reward: {
// 		// 	type: 'gems',
// 		// 	quantity: 25,
// 		// }
// 		// },
// 		{
// 			id: 401,
// 			title: 'Get Platinum Level on Giant Lava Slime',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 28,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 402,
// 			title: 'Get Platinum Level on Hellhound Pack Leader',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 25,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 403,
// 			title: 'Get Platinum Level on Mega Cannon',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 30,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 404,
// 			title: 'Get Platinum Level on Head Dark Wizard',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 24,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 405,
// 			title: 'Get Platinum Level on Crabs',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 20,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 406,
// 			title: 'Get Platinum Level on Massive Crabs',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 31,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 407,
// 			title: 'Get Platinum Level on King Crab',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 32,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 408,
// 			title: 'Get Platinum Level on Piranha',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 33,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 409,
// 			title: 'Get Platinum Level on King Piranha',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 34,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 410,
// 			title: 'Get Platinum Level on Seahorse',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 35,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 411,
// 			title: 'Get Platinum Level on Superior Seahorse',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 36,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		// {
// 		// 	id: 412,
// 		// 	title: 'Get Platinum Level on King Seahorse',
// 		// 	type: 'monsterJournal',
// 		// 	type2: 'single',
// 		// 	type3: 'platinum',
// 		// 	enemyId: 36,
// 		// reward: {
// 		// 	type: 'gems',
// 		// 	quantity: 25,
// 		// }
// 		// },
// 		{
// 			id: 413,
// 			title: 'Get Platinum Level on Ice Slimes',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 43,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 414,
// 			title: 'Get Platinum Level on Giant Ice Slimes',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 44,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 415,
// 			title: 'Get Platinum Level on Mammoths',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 38,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 416,
// 			title: 'Get Platinum Level on Superior Mammoths',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 39,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 417,
// 			title: 'Get Platinum Level on King Mammoth',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 40,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 418,
// 			title: 'Get Platinum Level on Ice Golem',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'platinum',
// 			enemyId: 41,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 419,
// 			title: 'Open 1 Pumpkin Chest',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'specific',
// 			type4: 1,
// 			enemyId: 105,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 420,
// 			title: 'Open 25 Pumpkin Chests',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'specific',
// 			type4: 25,
// 			enemyId: 105,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50,
// 			}
// 		},
// 		{
// 			id: 421,
// 			title: 'Open 50 Pumpkin Chests',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'specific',
// 			type4: 50,
// 			enemyId: 105,
// 			reward: {
// 				type: 'emote',
// 				id: 50,
// 				// type: 'coins',
// 				// quantity: 100,
// 			}
// 		},
// 		{
// 			id: 422,
// 			title: 'Kill Jack-O-Charlie',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'specific',
// 			type4: 1,
// 			enemyId: 45,
// 			reward: {
// 				type: 'gems',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 423,
// 			title: 'Kill Jack-O-Charlie 10 times',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'specific',
// 			type4: 10,
// 			enemyId: 45,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50,
// 			}
// 		},
// 		{
// 			id: 424,
// 			title: 'Kill Jack-O-Charlie 25 times',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'specific',
// 			type4: 25,
// 			enemyId: 45,
// 			reward: {
// 				// type: 'coins',
// 				type: 'emote',
// 				id: 51,
// 				// quantity: 100,
// 			}
// 		},
// 		{
// 			id: 425,
// 			title: 'Open 1 Christmas Gift',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'specific',
// 			type4: 1,
// 			enemyId: 106,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 426,
// 			title: 'Open 25 Christmas Gifts',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'specific',
// 			type4: 25,
// 			enemyId: 106,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50,
// 			}
// 		},
// 		{
// 			id: 427,
// 			title: 'Open 50 Christmas Gifts',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'specific',
// 			type4: 50,
// 			enemyId: 106,
// 			reward: {
// 				type: 'emote',
// 				id: 57,
// 				// type: 'coins',
// 				// quantity: 100,
// 			}
// 		},
// 		{
// 			id: 428,
// 			title: 'Open 100 Christmas Gifts',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'specific',
// 			type4: 100,
// 			enemyId: 106,
// 			reward: {
// 				type: 'coins',
// 				quantity: 100,
// 			}
// 		},
// 		{
// 			id: 429,
// 			title: 'Open 1 Currency Chest',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'specific',
// 			type4: 1,
// 			enemyId: 107,
// 			reward: {
// 				type: 'coins',
// 				quantity: 25,
// 			}
// 		},
// 		{
// 			id: 430,
// 			title: 'Open 25 Currency Chests',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'specific',
// 			type4: 25,
// 			enemyId: 107,
// 			reward: {
// 				type: 'coins',
// 				quantity: 50,
// 			}
// 		},
// 		{
// 			id: 431,
// 			title: 'Open 50 Currency Chests',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'specific',
// 			type4: 50,
// 			enemyId: 107,
// 			reward: {
// 				type: 'coins',
// 				quantity: 75,
// 			}
// 		},
// 		{
// 			id: 432,
// 			title: 'Open 100 Currency CHests',
// 			type: 'monsterJournal',
// 			type2: 'single',
// 			type3: 'specific',
// 			type4: 100,
// 			enemyId: 107,
// 			reward: {
// 				type: 'coins',
// 				quantity: 100,
// 			}
// 		},
// 		// {
// 		// 	id: 419,
// 		// 	title: 'Get Platinum Level on Ice Golem King',
// 		// 	type: 'monsterJournal',
// 		// 	type2: 'single',
// 		// 	type3: 'platinum',
// 		// 	enemyId: 40,
// 		// reward: {
// 		// 	type: 'gems',
// 		// 	quantity: 25,
// 		// }
// 		// },
// 		// {
// 		//     id: 53,
// 		//     title: 'Reach level 5',
// 		//     type: 'accountLevel',
// 		//     levelNeeded: 5,
// 		//     reward: {
// 		//         type: 'coins',
// 		//         quantity: 25
// 		//     }
// 		// },
// 		// {
// 		//     id: 54,
// 		//     title: 'Reach level 10',
// 		//     type: 'accountLevel',
// 		//     levelNeeded: 10,
// 		//     reward: {
// 		//         type: 'coins',
// 		//         quantity: 25
// 		//     }
// 		// },
// 		// {
// 		//     id: 55,
// 		//     title: 'Reach level 15',
// 		//     type: 'accountLevel',
// 		//     levelNeeded: 15,
// 		//     reward: {
// 		//         type: 'coins',
// 		//         quantity: 25
// 		//     }
// 		// },
// 		// {
// 		//     id: 56,
// 		//     title: 'Reach level 20',
// 		//     type: 'accountLevel',
// 		//     levelNeeded: 20,
// 		//     reward: {
// 		//         type: 'coins',
// 		//         quantity: 25
// 		//     }
// 		// },
// 	],
// 	dailyQuests: [
// 		{
// 			id: 1,
// 			title: 'Kill King Slime',
// 			difficulty: 'easy',
// 			type: 'kill',
// 			enemyId: 4,
// 			quantity: 1,
// 		},
// 		{
// 			id: 2,
// 			title: 'Kill Slimes',
// 			difficulty: 'easy',
// 			type: 'kill',
// 			enemyId: 1,
// 			quantity: 25,
// 		},
// 		{
// 			id: 3,
// 			title: 'Kill Chimeras',
// 			difficulty: 'easy',
// 			type: 'kill',
// 			enemyId: 6,
// 			quantity: 15,
// 		},
// 		{
// 			id: 4,
// 			title: 'Kill Woodland Critters',
// 			difficulty: 'easy',
// 			type: 'kill',
// 			enemyId: 7,
// 			quantity: 15,
// 		},
// 		{
// 			id: 5,
// 			title: 'Kill Cannons',
// 			difficulty: 'medium',
// 			type: 'kill',
// 			enemyId: 9,
// 			quantity: 20,
// 		},
// 		{
// 			id: 6,
// 			title: 'Kill Charlies',
// 			difficulty: 'medium',
// 			type: 'kill',
// 			enemyId: 5,
// 			quantity: 15,
// 		},
// 		{
// 			id: 7,
// 			title: 'Kill Dark Wizards',
// 			difficulty: 'hard',
// 			type: 'kill',
// 			enemyId: 2,
// 			quantity: 15,
// 		},
// 		{
// 			id: 8,
// 			title: 'Kill King Chimera',
// 			difficulty: 'hard',
// 			type: 'kill',
// 			enemyId: 12,
// 			quantity: 1,
// 		},
// 		{
// 			id: 9,
// 			title: 'Kill Woodland Beast',
// 			difficulty: 'hard',
// 			type: 'kill',
// 			enemyId: 13,
// 			quantity: 1,
// 		},
// 		{
// 			id: 10,
// 			title: 'Kill Crabs',
// 			difficulty: 'easy',
// 			type: 'kill',
// 			enemyId: 20,
// 			quantity: 15,
// 		},
// 		{
// 			id: 11,
// 			title: 'Kill Sea Horses',
// 			difficulty: 'medium',
// 			type: 'kill',
// 			enemyId: 35,
// 			quantity: 15,
// 		},
// 		{
// 			id: 12,
// 			title: 'Kill Superior Crabs',
// 			difficulty: 'medium',
// 			type: 'kill',
// 			enemyId: 31,
// 			quantity: 8,
// 		},
// 		{
// 			id: 13,
// 			title: 'Kill Mammoths',
// 			difficulty: 'hard',
// 			type: 'kill',
// 			enemyId: 38,
// 			quantity: 15,
// 		},
// 		{
// 			id: 14,
// 			title: 'Kill Ice Golems',
// 			difficulty: 'hard',
// 			type: 'kill',
// 			enemyId: 41,
// 			quantity: 15,
// 		},
// 	],
// 	questRewards: {
// 		easy: 15,
// 		medium: 20,
// 		hard: 25,
// 	},
// })
