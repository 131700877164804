export function getMouseAngle(phaserData, playerInfo) {
    var xPlayer = playerInfo.x
    var yPlayer = playerInfo.y

    var xMouse = phaserData.input.mousePointer.x + phaserData.cameras.main.scrollX
    var yMouse = phaserData.input.mousePointer.y + phaserData.cameras.main.scrollY
    var dir = Phaser.Math.Angle.Between(xPlayer, yPlayer, xMouse, yMouse)
    // conver atan2 output to 2pi  https://stackoverflow.com/questions/10343448/javascript-atan2-function-not-giving-expected-results
    if (dir < 0) {
      dir = dir + (2 * Math.PI)
    }
    return dir
  }
export function relDiff(a, b) {
    return  100 * Math.abs( ( a - b ) / ( (a+b)/2 ) );
}
  export function angleToShot(angle) {
    let dir = angle * Phaser.Math.DEG_TO_RAD
    if (dir < 0) {
      dir = dir + (2 * Math.PI)
    }
    return dir
  }
  
export function getMousePos(phaserData) {
    var xMouse = phaserData.input.mousePointer.worldX
    var yMouse = phaserData.input.mousePointer.worldY
    return [xMouse, yMouse]
  }

export function distance(coords1, coords2) {
  const dx = coords1.x - coords2.x
  const dy = coords1.y - coords2.y
  return Math.sqrt(dx * dx + dy * dy)
}
export function hexTo0x(str) {
    return parseInt(str.replace('#',''),16)
}
export function getRandomItem(array) {
  let randomItem = array[Math.floor(Math.random() * array.length)]
  return randomItem
}